import Joyride, { CallBackProps, Step } from 'react-joyride';

import { token } from '@agentero/styles/tokens';

type UserGuideProps = {
	run: boolean;
	steps: Step[];
	handleJoyrideCallback: (data: CallBackProps) => void;
};

export const UserGuide = ({ run, steps, handleJoyrideCallback }: UserGuideProps) => {
	const onCallback = (data: CallBackProps) => {
		handleJoyrideCallback(data);
	};

	return (
		<Joyride
			run={run}
			steps={steps}
			callback={onCallback}
			hideBackButton
			continuous
			showProgress
			styles={{
				buttonNext: {
					backgroundColor: token('colors.neutral')
				},
				tooltip: {
					padding: '0.5rem'
				},
				tooltipContent: {
					padding: '0.25rem',
					paddingRight: '1.75rem'
				},
				options: {
					zIndex: 1006,
					primaryColor: token('colors.brand.40')
				}
			}}
			locale={{
				last: 'Done'
			}}
		/>
	);
};
