import { PropsWithChildren } from 'react';

import { CacheProvider, EmotionCache, ThemeProvider } from '@emotion/react';

import { AgenteroGlobalStyles, theme } from '@agentero/styles/emotion';

type EmotionProviderProps = {
	emotionCache?: EmotionCache;
};

const EmotionProvider = ({ emotionCache, children }: PropsWithChildren<EmotionProviderProps>) => {
	return (
		<CacheProvider value={emotionCache}>
			<AgenteroGlobalStyles />
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</CacheProvider>
	);
};

export { EmotionProvider };
