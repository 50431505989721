import { forwardRef } from 'react';

import InputMask from 'react-input-mask';

import { Input } from './Input';
import { FieldStatus } from './fieldStatus';

type InputTelProps = {
	status?: FieldStatus;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const InputTel = forwardRef<HTMLInputElement, InputTelProps>(
	({ status, onChange, disabled, value, onBlur, autoFocus, ...props }, ref) => (
		<InputMask
			mask={'(999) 999-9999'}
			disabled={disabled}
			onChange={onChange}
			onBlur={onBlur}
			value={value}
			autoComplete="off">
			<Input
				ref={ref}
				status={status}
				disabled={disabled}
				{...props}
				autoFocus={autoFocus}
				autoComplete="off"
			/>
		</InputMask>
	)
);
