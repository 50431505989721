import {
	AnchorHTMLAttributes,
	ButtonHTMLAttributes,
	PropsWithChildren,
	Ref,
	forwardRef
} from 'react';

import { cva, cx } from '@agentero/styles/css';
import { styled } from '@agentero/styles/jsx';
import { RecipeVariantProps } from '@agentero/styles/types';

// TODO: create a slot recipe for the Card component and export as a compound component
export const Card = styled('article', {
	base: {
		'--paddingInline': 'token(spacing.32)',
		'--paddingBlock': 'token(spacing.32)',
		'--border-radius': 'token(radii.md)',
		'--border-width': '0.0625rem',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		paddingInline: 'var(--paddingInline)',
		paddingBlock: 'var(--paddingBlock)',
		borderRadius: 'var(--border-radius)',
		borderWidth: 'var(--border-width)',
		borderStyle: 'solid',
		borderColor: 'border.default.base.primary'
	},
	variants: {
		highlighted: {
			true: {
				boxShadow: 'inset 0 0 0 2px token(colors.border)'
			}
		},
		size: {
			sm: {
				'--paddingBlock': 'token(spacing.16)',
				'--paddingInline': 'token(spacing.16)'
			},
			md: {
				'--paddingBlock': 'token(spacing.24)',
				'--paddingInline': 'token(spacing.24)'
			},
			lg: {
				'--paddingBlock': 'token(spacing.32)',
				'--paddingInline': 'token(spacing.32)'
			}
		},
		asCover: {
			true: {
				'--width': 'token(spacing.8)',
				position: 'relative',
				borderTopLeftRadius: 0,
				borderBottomLeftRadius: 0,
				marginInlineEnd: 'var(--width)',
				_before: {
					content: '""',
					position: 'absolute',
					top: '2',
					right: 'calc(-1 * var(--width))',
					bottom: '2',
					width: 'var(--width)',
					backgroundColor: 'bg',
					border: 'inherit',
					borderRadius: 'md',
					borderTopLeftRadius: 0,
					borderBottomLeftRadius: 0
				}
			}
		}
	},
	defaultVariants: {
		size: 'lg'
	}
});

const cardActionAreaRecipe = cva({
	base: {
		marginBlock: 'calc(-1 * var(--paddingBlock))',
		marginInline: 'calc(-1 * var(--paddingInline))',
		paddingInline: 'var(--paddingInline)',
		paddingBlock: 'var(--paddingBlock)',
		height: 'calc(100% + var(--paddingBlock) * 2)',
		cursor: 'pointer',
		textAlign: 'unset',
		textDecoration: 'none',
		transition: 'background 0.2s',
		borderRadius: 'calc(var(--border-radius) - var(--border-width))',
		'&:hover': {
			backgroundColor: 'button.bg.secondary.hover'
		},
		'&:active': {
			backgroundColor: 'button.bg.secondary.active'
		}
	}
});

type CardActionAreaVariants = RecipeVariantProps<typeof cardActionAreaRecipe>;

type CardActionAreaBaseProps = {
	as?: 'button' | 'a';
	className?: string;
} & CardActionAreaVariants;

type CardActionAreaAnchor = CardActionAreaBaseProps &
	AnchorHTMLAttributes<HTMLAnchorElement> & { as?: 'a' };
type CardActionAreaButton = CardActionAreaBaseProps &
	ButtonHTMLAttributes<HTMLButtonElement> & { as?: 'button' };

export type CardActionAreaProps = CardActionAreaAnchor | CardActionAreaButton;

export const CardActionArea = forwardRef<
	HTMLButtonElement | HTMLAnchorElement,
	CardActionAreaProps
>(
	(
		{ as = 'button', children, className, ...props }: PropsWithChildren<CardActionAreaProps>,
		ref
	) => {
		const styles = cx(cardActionAreaRecipe({}), className);

		if (as === 'a') {
			return (
				<a
					{...(props as CardActionAreaAnchor)}
					className={styles}
					ref={ref as Ref<HTMLAnchorElement>}>
					{children}
				</a>
			);
		}

		return (
			<button
				{...(props as CardActionAreaButton)}
				className={styles}
				ref={ref as Ref<HTMLButtonElement>}>
				{children}
			</button>
		);
	}
);
