import React, { useContext } from 'react';

import styled from '@emotion/styled';

import { DropdownMenuContex } from '../DropdownMenu';
import { Truncate } from '../Truncate';
import { Radio, RadioButton } from '../form/Radio';

type DropdownItemRadioProps = Omit<
	React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
	'id'
> & {
	id: string;
};

const StyledDropdownItemRadio = styled.li`
	height: 32px;

	&:hover {
		background: ${props => props.theme.colors.gray.lighten95};
	}

	${RadioButton} {
		+ label {
			padding-left: 40px;
			padding-right: 16px;

			:before {
				left: 16px;
			}
		}
	}
`;

export const DropdownItemRadio: React.FC<DropdownItemRadioProps> = props => {
	const { onClose } = useContext(DropdownMenuContex);
	const ref = props.ref as React.Ref<HTMLInputElement>;
	const { children } = props;

	return (
		<StyledDropdownItemRadio>
			<Radio {...props} ref={ref} onClick={onClose}>
				<Truncate>{children}</Truncate>
			</Radio>
		</StyledDropdownItemRadio>
	);
};
