import { AgentRole } from '@agentero/service-clients/portal/session';

export enum Role {
	Agent,
	AgencyAdmin,
	SuperAdmin
}

export const parseRole = (role?: AgentRole) => {
	if (role === AgentRole.SUPER_ADMIN) return Role.SuperAdmin;
	if (role === AgentRole.AGENCY_ADMIN) return Role.AgencyAdmin;

	return Role.Agent;
};

export const isSuperAdmin = (role: Role): boolean => role === Role.SuperAdmin;
export const isAgencyAdmin = (role: Role): boolean => role === Role.AgencyAdmin;
