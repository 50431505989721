import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import USAMap from 'react-usa-map';

import { getStatesString } from '@agentero/models/shared';

import { InfoText } from './InfoText';

const states = getStatesString();

type StatesConfigArgs = {
	agentCodesStates: string[];
	disabledAppointedCodesStates: string[];
	agenteroCodesStates: string[];
	disabledBwaCodesStates: string[];
};

const statesConfig = ({
	agentCodesStates,
	disabledAppointedCodesStates,
	agenteroCodesStates,
	disabledBwaCodesStates
}: StatesConfigArgs) => {
	const otherStates = states.filter(
		(state: string) =>
			!agentCodesStates.includes(state) &&
			!agenteroCodesStates.includes(state) &&
			!disabledBwaCodesStates.includes(state) &&
			!disabledAppointedCodesStates.includes(state)
	);

	const agentCodesResult = agentCodesStates.reduce((acc: {}, state: string) => {
		return {
			...acc,
			[state]: {
				fill: '#4c5261'
			}
		};
	}, {});

	const disabledAppointedCodesStatesResult = disabledAppointedCodesStates.reduce(
		(acc: {}, state: string) => {
			return {
				...acc,
				[state]: {
					fill: '#8d93a5'
				}
			};
		},
		{}
	);

	const agenteroCodesResult = agenteroCodesStates.reduce((acc: {}, state: string) => {
		return {
			...acc,
			[state]: {
				fill: '#2DBC90'
			}
		};
	}, {});

	const disabledBwaCodesResult = disabledBwaCodesStates.reduce((acc: {}, state: string) => {
		return {
			...acc,
			[state]: {
				fill: '#d2f4e9'
			}
		};
	}, {});

	const otherStatesResult = otherStates.reduce((acc: {}, state: string) => {
		return {
			...acc,
			[state]: {
				fill: '#E2E4E9'
			}
		};
	}, {});

	return {
		...agentCodesResult,
		...disabledAppointedCodesStatesResult,
		...agenteroCodesResult,
		...disabledBwaCodesResult,
		...otherStatesResult
	};
};

type MapProps = {
	agentCodesStates: string[];
	disabledAppointedCodesStates: string[];
	agenteroCodesStates: string[];
	disabledBwaCodesStates: string[];
};

const LegendContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;
	margin-left: 16px;
	margin-bottom: 8px;
`;

const LegendItem = styled.div<{ color: string }>`
	height: 16px;
	width: 16px;
	background-color: ${({ color }) => color};
`;

export const Map = ({
	agentCodesStates,
	disabledAppointedCodesStates,
	agenteroCodesStates,
	disabledBwaCodesStates
}: MapProps) => {
	const { colors } = useTheme();
	const { t } = useTranslation('rater');

	return (
		<>
			<USAMap
				width="100%"
				height="100%"
				customize={statesConfig({
					agentCodesStates,
					disabledAppointedCodesStates,
					agenteroCodesStates,
					disabledBwaCodesStates
				})}
			/>
			<LegendContainer style={{ marginTop: 16 }}>
				<LegendItem color={colors.gray.lighten20} />
				<InfoText tooltip={t('status-tracker-content.yourCodes.tooltip')} as="p">
					{t('status-tracker-content.yourCodes.label')}
				</InfoText>
			</LegendContainer>
			<LegendContainer>
				<LegendItem color={colors.gray.lighten40} />
				{t('status-tracker-content.disabledYourCodes.label')}
			</LegendContainer>
			<LegendContainer>
				<LegendItem color={colors.primary.base} />
				{t('status-tracker-content.agenteroCodes.label')}
			</LegendContainer>
			<LegendContainer>
				<LegendItem color={colors.primary.lighten80} />
				{t('status-tracker-content.disabledBwaCodes.label')}
			</LegendContainer>
		</>
	);
};
