import { useState } from 'react';

export const useMenu = () => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const closePanel = () => setIsOpen(false);
	const onMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		event.stopPropagation();
		setIsOpen(!isOpen);
	};

	return { isOpen, closePanel, onMenuClick };
};
