import { AppointmentStatus } from '@agentero/grpc-clients/grpc/carrier-fe';

export enum AppointmentRequestStatus {
	NotRequested = 'not-requested',
	InProgress = 'in-progress',
	Approved = 'approved',
	NotApproved = 'not-approved',
	NotProcessed = 'not-processed',
	TerminationInProgress = 'termination-in-progress',
	Terminated = 'terminated'
}

const appointmentStatusFromProtoMapping: {
	[key in AppointmentStatus]: AppointmentRequestStatus | undefined;
} = {
	[AppointmentStatus.APPOINTMENT_STATUS_NOT_REQUESTED]: AppointmentRequestStatus.NotRequested,
	[AppointmentStatus.APPOINTMENT_STATUS_IN_PROGRESS]: AppointmentRequestStatus.InProgress,
	[AppointmentStatus.APPOINTMENT_STATUS_NOT_APPROVED]: AppointmentRequestStatus.NotApproved,
	[AppointmentStatus.APPOINTMENT_STATUS_APPROVED]: AppointmentRequestStatus.Approved,
	[AppointmentStatus.APPOINTMENT_STATUS_UNSPECIFIED]: undefined
};

export const parseAppointmentRequestStatus = (
	status: AppointmentStatus
): AppointmentRequestStatus | undefined => appointmentStatusFromProtoMapping[status];

const appointmentStatusMappingToProto: {
	[key in AppointmentRequestStatus]: AppointmentStatus;
} = {
	[AppointmentRequestStatus.NotRequested]: AppointmentStatus.APPOINTMENT_STATUS_NOT_REQUESTED,
	[AppointmentRequestStatus.InProgress]: AppointmentStatus.APPOINTMENT_STATUS_IN_PROGRESS,
	[AppointmentRequestStatus.NotApproved]: AppointmentStatus.APPOINTMENT_STATUS_NOT_APPROVED,
	[AppointmentRequestStatus.Approved]: AppointmentStatus.APPOINTMENT_STATUS_APPROVED,
	[AppointmentRequestStatus.NotProcessed]: AppointmentStatus.APPOINTMENT_STATUS_UNSPECIFIED,
	[AppointmentRequestStatus.TerminationInProgress]:
		AppointmentStatus.APPOINTMENT_STATUS_UNSPECIFIED,
	[AppointmentRequestStatus.Terminated]: AppointmentStatus.APPOINTMENT_STATUS_UNSPECIFIED
};

export const parseCarrierAppointmentRequestStatusToProto = (
	status: AppointmentRequestStatus | undefined
): AppointmentStatus =>
	status
		? appointmentStatusMappingToProto[status]
		: AppointmentStatus.APPOINTMENT_STATUS_UNSPECIFIED;
