import React, { PropsWithChildren, ReactNode } from 'react';

import { Path } from 'react-hook-form';

import { styled } from '@agentero/styles/jsx';
import { stringToText } from '@agentero/utils';

import { useAgFormContext } from '../Form';
import { FieldHelp } from './FieldHelp';
import { FormError } from './field/FormError';
import { FormItem, NestedFormItem } from './field/FormItem';
import { Label } from './field/Label';
import { ReadonlyField } from './field/ReadonlyField';
import { getFieldError, getFieldErrorId, removeAllDots } from './formGroupUtils';

const HelpContainer = styled('div', {
	base: {
		marginTop: '8',
		'&:not(:last-child)': {
			marginBottom: '16'
		}
	}
});

type FieldProps<T> = {
	id?: string;
	label: string;
	name: Path<T>;
	description?: ReactNode;
	optional?: boolean;
	nested?: boolean;
	nestedChildren?: ReactNode;
	asFieldset?: boolean;
	displayErrorMessage?: boolean;
	disabled?: boolean;
	help?: string;
	getReadonlyValue?: (value: string | boolean | unknown) => string;
};

export type FieldGenericProps<T> = Omit<FieldProps<T>, 'nestedChildren'>;

const Legend = ({ children }: PropsWithChildren) => {
	return <legend>{children}</legend>;
};

export const Field = <T extends {}>({
	id,
	name,
	label,
	description,
	optional,
	nested = false,
	children,
	nestedChildren,
	asFieldset = false,
	displayErrorMessage = true,
	help,
	getReadonlyValue
}: PropsWithChildren<FieldProps<T>>) => {
	const {
		getValues,
		formState: { errors },
		isReadonly
	} = useAgFormContext<T>();

	const FieldContainer = asFieldset && !isReadonly ? 'fieldset' : isReadonly ? 'div' : FormItem;

	const NestedContainer = nested ? NestedFormItem : React.Fragment;

	const LegendInFieldset = asFieldset ? Legend : React.Fragment;

	return (
		<FieldContainer>
			<NestedContainer>
				{isReadonly ? (
					<>
						<ReadonlyField
							name={name}
							label={label}
							value={
								getReadonlyValue ? getReadonlyValue(getValues(name)) : stringToText(getValues(name))
							}
							description={description}
							optional={optional}
						/>
						{nestedChildren}
					</>
				) : (
					<>
						<LegendInFieldset>
							<Label id={id} htmlFor={removeAllDots(name)} optional={optional} info={description}>
								{label}
							</Label>
						</LegendInFieldset>
						{children}
						{displayErrorMessage && (
							<FormError
								id={getFieldErrorId(name)}
								error={getFieldError(errors, name)?.message as string}
							/>
						)}

						{help && (
							<HelpContainer>
								<FieldHelp text={help} />
							</HelpContainer>
						)}

						{nestedChildren}
					</>
				)}
			</NestedContainer>
		</FieldContainer>
	);
};
