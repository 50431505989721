//@ts-ignore
import ReactMarkdown from 'react-markdown';

import { MarkdownStyles } from './MarkdownStyles';

type MarkdownViewProps = {
	value: string;
};

export const MarkdownView = ({ value }: MarkdownViewProps) => {
	return (
		<>
			<MarkdownStyles />
			<div className="markdown-body">
				<ReactMarkdown linkTarget="_blank">{value}</ReactMarkdown>
			</div>
		</>
	);
};
