import { PropsWithChildren } from 'react';

import styled from '@emotion/styled';

/**
 * @deprecated Use `Tabs.Root` from `@agentero/ui` Tabs instead.
 */
export const Tabs = styled.div`
	flex: 1;
	min-height: 0;
	display: flex;
	flex-direction: column;
`;

/**
 * @deprecated Use `Tabs.List` from `@agentero/ui` Tabs instead.
 */
export const TabsNav = styled.nav`
	display: flex;
	margin: 0 -16px;
	padding: 0 16px;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
`;

/**
 * @deprecated Np longer needed.
 */
export const TabsNavItem = styled.div`
	margin-right: 24px;

	&:last-of-type {
		margin-right: 0;
	}
`;

type TabsButtonProps = {
	isActive: boolean;
	as?: string;
};

/**
 * @deprecated Use `Tabs.Trigger` from `@agentero/ui` Tabs instead.
 */
export const TabsButton = styled.button<TabsButtonProps>`
	position: relative;
	display: inline-block;
	letter-spacing: 0.14px;
	padding: 8px 0;
	${({ theme }) => theme.fontWeight.medium};
	${({ theme }) => theme.textSettings.S};
	text-decoration: none;
	color: ${({ theme, isActive }) =>
		isActive ? theme.colors.gray.base : theme.colors.gray.lighten40};
	background: transparent;
	border: none;
	border-radius: 0;
	cursor: pointer;
	outline: none;
	text-align: center;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	transition: color 0.15s;
	white-space: nowrap;

	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0px;
		right: 0px;
		height: 4px;
		background: ${({ theme }) => theme.colors.gray.lighten20};
		transform: ${({ isActive }) => (isActive ? 'scaleY(1)' : 'scaleY(0)')};
		transform-origin: center bottom;
		transition: 0.2s;
		will-change: transform;
	}

	@media (hover: hover) and (pointer: fine) {
		&:hover,
		&:focus {
			color: ${({ theme }) => theme.colors.gray.base};
			text-decoration: none;

			&:before {
				transform: ${({ isActive }) => (isActive ? 'scaleY(1)' : 'scaleY(0)')};
			}
		}
	}

	&:active {
		opacity: 0.7;
		color: ${({ theme, isActive }) =>
			isActive ? theme.colors.gray.lighten40 : theme.colors.gray.lighten40};
	}
`;

/**
 * @deprecated No longer needed.
 */
export const TabsMain = styled.div`
	margin: 0 -16px;
`;

const TabsContentContainer = styled.div`
	position: relative;
	padding: 16px;
`;

type TabsContentProps = {
	isActive?: boolean;
	className?: string;
};

/**
 * @deprecated Use `Tabs.Content` from `@agentero/ui` Tabs instead.
 */
export const TabsContent = ({
	isActive = true,
	className,
	children
}: PropsWithChildren<TabsContentProps>) => {
	return isActive ? (
		<TabsContentContainer className={className} role="tabpanel">
			{children}
		</TabsContentContainer>
	) : null;
};

export const ContentTabs = styled(Tabs)`
	${TabsNav} {
		border-bottom: 1px solid ${({ theme }) => theme.colors.gray.lighten90};
	}

	${TabsMain} {
		flex: 1;
		min-height: 0;
		display: flex;
		flex-direction: column;
		margin-bottom: -16px;
	}

	${TabsContentContainer} {
		flex: 1;
		min-height: 0;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
`;
