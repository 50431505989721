import { ChangeEvent, HTMLInputTypeAttribute, PropsWithChildren } from 'react';

import { getInputStatusFromError } from '@agentero/utils';

import { useAgFormContext } from '../Form';
import { Field, FieldGenericProps } from '../shared/Field';
import { getFieldError, getFieldErrorId, removeAllDots } from '../shared/formGroupUtils';
import { Input, InputVariants } from './fieldText/Input';

type FieldTextProps<T> = {
	type?: HTMLInputTypeAttribute;
	placeholder?: string;
	minValue?: number;
	maxValue?: number;
	autoFocus?: boolean;
	maxLength?: number;
	disabled?: boolean;
	onChange?: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined;
	defaultValue?: string | number | undefined;
	valueAsNumber?: boolean;
	help?: string;
} & FieldGenericProps<T> &
	InputVariants;

export const FieldText = <T extends {}>({
	name,
	type = 'text',
	placeholder,
	minValue,
	maxValue,
	autoFocus,
	maxLength,
	disabled,
	onChange,
	children,
	defaultValue,
	valueAsNumber,
	size,
	help,
	...formGroupProps
}: PropsWithChildren<FieldTextProps<T>>) => {
	const {
		register,
		formState: { errors }
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	return (
		<Field name={name} help={help} nestedChildren={children} {...formGroupProps}>
			<Input
				id={removeAllDots(name)}
				{...register(name, { onChange, valueAsNumber })}
				type={type}
				status={getInputStatusFromError(fieldError?.message as string)}
				placeholder={placeholder}
				aria-invalid={!!fieldError?.message}
				aria-errormessage={getFieldErrorId(name)}
				min={minValue}
				max={maxValue}
				autoFocus={autoFocus}
				maxLength={maxLength}
				disabled={disabled}
				defaultValue={defaultValue}
				size={size}
			/>
		</Field>
	);
};
