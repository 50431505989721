import { useRef } from 'react';

import { createCalendar } from '@internationalized/date';
import { CalendarProps as CalendarPropsAria, DateValue, useCalendar } from '@react-aria/calendar';
import { useCalendarState } from '@react-stately/calendar';

import { IconKeyboardArrowLeft, IconKeyboardArrowRight } from '@agentero/icons';
import { HStack, Stack, VisuallyHidden } from '@agentero/styles/jsx';

import { Text } from './Text';
import { CalendarGrid } from './calendar/CalendarGrid';
import { ButtonAria } from './shared/ButtonAria';

type CalendarProps = CalendarPropsAria<DateValue>;

export const Calendar = (props: CalendarProps) => {
	const state = useCalendarState({
		...props,
		locale: 'en-US',
		createCalendar
	});

	const ref = useRef<HTMLDivElement>(null);
	const { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(props, state);

	return (
		<Stack
			{...calendarProps}
			ref={ref}
			width="fit-content"
			border="0.0625rem solid token(colors.border)"
			padding={16}
			borderRadius="lg"
			background="white">
			<HStack justifyContent="space-between">
				<VisuallyHidden>
					<h2>{calendarProps['aria-label']}</h2>
				</VisuallyHidden>
				<ButtonAria variant="ghost" {...prevButtonProps} aria-hidden>
					<IconKeyboardArrowLeft />
				</ButtonAria>
				<Text as="h2">
					<b>{title}</b>
				</Text>
				<ButtonAria variant="ghost" {...nextButtonProps} aria-hidden>
					<IconKeyboardArrowRight />
				</ButtonAria>
			</HStack>
			<CalendarGrid state={state} />
		</Stack>
	);
};
