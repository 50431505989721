import React from 'react';

import styled from '@emotion/styled';

const StyledModalFooter = styled.div`
	display: flex;
	align-items: center;
	padding: 32px;
	display: flex;
	justify-content: space-between;
`;

const StyledActionsContainer = styled.div`
	display: flex;

	> * {
		margin: 0 4px;
	}
`;

interface IModalFooter {
	leftSide?: React.ReactNode;
	children: React.ReactNode;
}

export const ModalFooter: React.FC<IModalFooter> = ({ children, leftSide }) => {
	return (
		<StyledModalFooter>
			<StyledActionsContainer>{leftSide}</StyledActionsContainer>
			<StyledActionsContainer>{children}</StyledActionsContainer>
		</StyledModalFooter>
	);
};
