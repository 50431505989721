import { Children, FC, ReactElement, useState } from 'react';

import styled from '@emotion/styled';

import { IconExpandMore } from '@agentero/icons';

const ExpandGridToggle = styled.a`
	display: flex;
	justify-content: center;
	width: 100%;
	margin: 0 8px;
	padding: 12px 0;
	${({ theme }) => theme.textSettings.S};
	border-top: 1px solid ${({ theme }) => theme.colors.gray.lighten90};

	svg {
		margin-left: 8px;
	}
`;

type ExpandGridProps = {
	toggleLabel: {
		more: string;
		less: string;
	};
	size?: number;
	children: ReactElement<{ isVisible?: boolean }>[];
};

export const ExpandGrid: FC<ExpandGridProps> = ({
	children,
	toggleLabel: { more, less },
	size = 6
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const visibleChildren = Children.toArray(children)
		//@ts-ignore
		.filter(({ props: { isVisible = true } }) => isVisible);

	if (visibleChildren.length <= size) return <>{children}</>;

	return isOpen ? (
		<>
			{children}
			<ExpandGridToggle onClick={() => setIsOpen(false)}>
				{less}
				<IconExpandMore css={{ transform: 'scaleY(-1)' }} />
			</ExpandGridToggle>
		</>
	) : (
		<>
			{visibleChildren.slice(0, size)}
			<ExpandGridToggle onClick={() => setIsOpen(true)}>
				{more}
				<IconExpandMore />
			</ExpandGridToggle>
		</>
	);
};
