import { ReactNode } from 'react';

import { IconCheck } from '@agentero/icons';
import { css } from '@agentero/styles/css';
import { styled } from '@agentero/styles/jsx';
import { Text } from '@agentero/ui';

const HistoryContainer = styled('div', {
	base: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column'
	}
});

const HistoryBox = styled('div', {
	base: {
		alignItems: 'flex-start',
		alignSelf: 'start',
		display: 'flex',
		width: '235px',
		maxWidth: '100%',
		flexDirection: 'column'
	}
});

const HistoryItem = styled('div', {
	base: {
		alignItems: 'flex-start',
		alignSelf: 'start',
		display: 'flex',
		width: '100%',
		gap: '16px'
	}
});

const HistoryLine = styled('div', {
	base: {
		borderRadius: '8px',
		backgroundColor: 'var(--Default, #C6C9D2)',
		alignSelf: 'start',
		display: 'flex',
		marginLeft: '11px',
		width: '1px',
		height: '40px',
		flexDirection: 'column'
	}
});

const HistoryIcon = styled('div', {
	base: {
		aspectRatio: '1',
		objectFit: 'cover',
		objectPosition: 'center',
		width: '24px',
		overflow: 'hidden',
		alignSelf: 'center',
		maxWidth: '100%',
		margin: 'auto 0'
	}
});

const HistoryBody = styled('div', {
	base: {
		alignItems: 'flex-start',
		alignSelf: 'stretch',
		display: 'flex',
		flexDirection: 'column'
	}
});

const ActionRequiredLabel = styled('span', {
	base: {
		backgroundColor: '#fdf1ce',
		borderRadius: '4px',
		display: 'inline-block',
		fontSize: '0.625rem',
		lineHeight: '1rem',
		fontWeight: '600',
		letterSpacing: '0.4px',
		padding: '2px 6px',
		textTransform: 'uppercase',
		verticalAlign: 'top',
		whiteSpace: 'nowrap',
		color: '#624b04'
	}
});

type HistoryProps = {
	historySteps: ActivityItem[];
};

export type ActivityItem = {
	icon: ReactNode;
	title: string;
	actionRequired?: boolean;
	date?: string;
	current?: boolean;
};

export const History = ({ historySteps }: HistoryProps) => {
	const currentStep = historySteps.findIndex(step => step.current);

	return (
		<HistoryContainer>
			{historySteps.map(({ title, date, actionRequired = false, icon }, index) => {
				const disable = index > currentStep;
				const StepIcon = icon as ReactNode;

				return (
					<HistoryBox key={title}>
						<HistoryItem>
							<HistoryIcon>
								{index === currentStep ? (
									//@ts-ignore JSX element type 'StepIcon' does not have any construct or call signatures
									<StepIcon />
								) : disable ? (
									//@ts-ignore JSX element type 'StepIcon' does not have any construct or call signatures
									<StepIcon className={css({ '& > path': { fill: 'neutral.80' } })} />
								) : (
									<IconCheck />
								)}
							</HistoryIcon>
							<HistoryBody>
								<Text color={disable ? 'weak' : 'default'}>
									<b>{title}</b>
								</Text>

								{actionRequired ? (
									<ActionRequiredLabel>{'Needs action'}</ActionRequiredLabel>
								) : (
									date && (
										<Text color={disable ? 'weak' : 'default'} size="caption">
											{date}
										</Text>
									)
								)}
							</HistoryBody>
						</HistoryItem>
						{index !== historySteps.length - 1 && <HistoryLine />}
					</HistoryBox>
				);
			})}
		</HistoryContainer>
	);
};
