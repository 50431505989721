import { RequestStatusError } from '../RequestStatusError';

// TODO: remove LogLevel enum from javascript/portal-ui/packages/utilities/logger.ts logger and fix imports using this one instead
export enum LogLevel {
	Info = 'info',
	Warn = 'warn',
	Error = 'error'
}

export type RequestErrorArgs = {
	statusError: RequestStatusError;
	messageError: string;
	logMetadata: LogMetadata;
};

export type LogError = string | number | boolean | Date | unknown | Record<string, string>;

export type AddLog = <T extends Record<string, LogError> & Record<'message', string>>(
	log: T,
	logLevel?: LogLevel,
	logInServer?: boolean
) => void;

export type LogMetadata = Record<string, string | number | boolean | Date>;

export class RequestError extends Error {
	public statusError: RequestStatusError;
	public messageError: string;
	public logMetadata: LogMetadata;

	constructor({ statusError, messageError, logMetadata }: RequestErrorArgs) {
		super(messageError);
		this.statusError = statusError;
		this.messageError = messageError;
		this.logMetadata = logMetadata;
	}

	logError(log: AddLog, stack: string) {
		log(
			{
				statusError: this.statusError,
				message: this.messageError,
				logMetadata: this.logMetadata,
				stack
			},
			LogLevel.Error,
			false
		);
	}
}
