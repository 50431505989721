import { useEffect, useRef } from 'react';

type CombinedRef =
	| React.ForwardedRef<HTMLInputElement | null>
	| React.MutableRefObject<HTMLInputElement | null>;

export const useCombinedRefs = (...refs: CombinedRef[]) => {
	const targetRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		// TODO: we could change forEach if not confortable with it
		refs.forEach(ref => {
			if (!ref) return;

			if (typeof ref === 'function') {
				ref(targetRef.current);
			} else {
				ref.current = targetRef.current;
			}
		});
	}, [refs]);

	return targetRef;
};
