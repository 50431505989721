import { Item } from '@radix-ui/react-accordion';

import { styled } from '@agentero/styles/jsx';

export const AccordionItem = styled(Item, {
	base: {
		borderBottomWidth: '0.0625rem',
		borderBottomColor: 'border'
	}
});
