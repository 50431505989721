import { removeAllDots } from '@agentero/ui';

import { InputRadioButtonGroupProps } from '../InputRadioButtonGroup';
import { Radio } from './Radio';

export const InputRadioGroup = ({
	values,
	value,
	onChange,
	inputProps
}: InputRadioButtonGroupProps) => {
	return (
		<div>
			<ul>
				{values.map(({ label, value: valueProp, onChange: onChangeProp }) => (
					<li key={label}>
						<Radio
							id={`${removeAllDots(inputProps?.name ?? '')}${label}`}
							checked={value !== undefined ? value === valueProp : undefined}
							value={String(valueProp)}
							aria-label={label}
							{...inputProps}
							onChange={event => {
								onChangeProp?.(valueProp);
								onChange?.(valueProp);
								inputProps?.onChange?.(event);
							}}>
							{label}
						</Radio>
					</li>
				))}
			</ul>
		</div>
	);
};
