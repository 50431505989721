'use client';

import { PropsWithChildren, createContext, useContext, useRef } from 'react';

import {
	AriaCheckboxGroupItemProps,
	useCheckboxGroup,
	useCheckboxGroupItem
} from '@react-aria/checkbox';
import {
	CheckboxGroupProps as AriaCheckboxGroupProps,
	CheckboxGroupState,
	useCheckboxGroupState
} from '@react-stately/checkbox';

import { Stack } from '@agentero/styles/jsx';

import { Text } from '../Text';
import { CheckboxComponent } from './checkbox/CheckboxComponent';
import { Label } from './shared/Label';

// @ts-ignore
const CheckboxGroupContext = createContext<CheckboxGroupState>(null);

type CheckboxGroupProps = AriaCheckboxGroupProps & {
	label: string;
};

const CheckboxGroupRoot = (props: PropsWithChildren<CheckboxGroupProps>) => {
	const { label, children, description } = props;

	const state = useCheckboxGroupState(props);
	const { groupProps, labelProps, descriptionProps } = useCheckboxGroup(props, state);

	const isHeader = label || description;

	return (
		<Stack gap={8} {...groupProps}>
			{isHeader && (
				<Stack gap={2}>
					{label && <Label {...labelProps}>{label}</Label>}
					{description && (
						<Text {...descriptionProps} size="body.small" color="weak">
							{description}
						</Text>
					)}
				</Stack>
			)}
			<CheckboxGroupContext.Provider value={state}>
				<Stack gap={4}>{children}</Stack>
			</CheckboxGroupContext.Provider>
		</Stack>
	);
};

type CheckboxGroupItemProps = AriaCheckboxGroupItemProps;

const CheckboxGroupItem = (props: CheckboxGroupItemProps) => {
	const { children } = props;
	const state = useContext(CheckboxGroupContext);
	const ref = useRef(null);
	const { inputProps } = useCheckboxGroupItem(props, state, ref);

	const isDisabled = state.isDisabled || props.isDisabled;
	const isSelected = state.isSelected(props.value);

	return (
		<CheckboxComponent
			{...props}
			inputProps={inputProps}
			isDisabled={isDisabled}
			isSelected={isSelected}>
			{children}
		</CheckboxComponent>
	);
};

export const CheckboxGroup = {
	Root: CheckboxGroupRoot,
	Item: CheckboxGroupItem
};
