import styled from '@emotion/styled';

type GridItemProps = {
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
	xlg?: number;
	xxlg?: number;
	xxxlg?: number;
};

const columns: { [key: number]: string } = {
	1: '8.33333333%',
	2: '16.66666667%',
	3: '25%',
	4: '33.33333333%',
	5: '41.66666667%',
	6: '50%',
	7: '58.33333333%',
	8: '66.66666667%',
	9: '75%',
	10: '83.33333333%',
	11: '91.66666667%',
	12: '100%'
};

export const getColumnsPropsFromSize = (size: number) => `
	flex-basis: ${columns[size]};
	max-width: ${columns[size]};
`;

/**
 * @deprecated Use **'@agentero/styles/jsx'** *GridItem* component instead.
 */
export const GridItem = styled.div<GridItemProps>`
	display: flex;
	flex-direction: column;
	flex: 0 0 auto;

	${({ xs = 12 }) => getColumnsPropsFromSize(xs)}

	${({ theme }) => theme.mediaqueries.S} {
		${({ sm }) => sm && getColumnsPropsFromSize(sm)}
	}

	${({ theme }) => theme.mediaqueries.M} {
		${({ md }) => md && getColumnsPropsFromSize(md)}
	}

	${({ theme }) => theme.mediaqueries.L} {
		${({ lg }) => lg && getColumnsPropsFromSize(lg)}
	}

	${({ theme }) => theme.mediaqueries.XL} {
		${({ xlg }) => xlg && getColumnsPropsFromSize(xlg)}
	}

	${({ theme }) => theme.mediaqueries.XXL} {
		${({ xxlg }) => xxlg && getColumnsPropsFromSize(xxlg)}
	}

	${({ theme }) => theme.mediaqueries.XXXL} {
		${({ xxxlg }) => xxxlg && getColumnsPropsFromSize(xxxlg)}
	}
`;
