import { styled } from '@agentero/styles/jsx';

export const Badge = styled('span', {
	base: {
		alignItems: 'center',
		borderRadius: 'full',
		display: 'inline-flex',
		userSelect: 'none',
		whiteSpace: 'nowrap'
	},
	defaultVariants: {
		variant: 'subtle',
		size: 'md'
	},
	variants: {
		variant: {
			solid: {
				background: 'neutral.10',
				color: 'white',
				'& svg': {
					fill: 'white'
				}
			},
			subtle: {
				background: 'neutral.95',
				borderColor: 'neutral.80',
				borderWidth: '1px',
				color: 'neutral'
			},
			outline: {
				borderWidth: '1px',
				borderColor: 'neutral.10'
			}
		},
		size: {
			sm: {
				textStyle: 'tiny.base',
				px: '6',
				h: '20',
				gap: '4',
				'& svg': {
					width: '8',
					height: '8'
				}
			},
			md: {
				textStyle: 'caption.base',
				px: '8',
				h: '25',
				gap: '4',
				'& svg': {
					width: '12',
					height: '12'
				}
			},
			lg: {
				textStyle: 'body.small',
				px: '12',
				h: '28',
				gap: '6',
				'& svg': {
					width: '16',
					height: '16'
				}
			}
		}
	}
});
