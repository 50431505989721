import { PropsWithChildren, useId } from 'react';

import { CalendarDate } from '@internationalized/date';
import { Controller, Path, PathValue } from 'react-hook-form';

import { getFieldError, getFieldErrorId, removeAllDots, useAgFormContext } from '@agentero/ui';

import { DatePicker } from '../../inputs/DatePicker';
import { Field, FieldGenericProps } from '../shared/Field';
import { InputVariants } from './fieldText/Input';

type FieldDateProps<T> = FieldGenericProps<T> & {
	defaultValue?: PathValue<T, Path<T>>;
} & InputVariants;

const parseUsaDateToISO8601 = (date: string) => {
	const [month, day, year] = date.split('/');
	return new CalendarDate(Number(year), Number(month), Number(day));
};

export const FieldDate = <T extends {}>({
	name,
	defaultValue,
	children,
	size,
	disabled,
	...formGroupProps
}: PropsWithChildren<FieldDateProps<T>>) => {
	const {
		control,
		formState: { errors }
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	const isInvalid = !!fieldError?.message;

	const id = useId();

	return (
		<Field id={id} name={name} nestedChildren={children} {...formGroupProps}>
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				render={({ field: { onChange, value = '', onBlur } }) => (
					<DatePicker
						id={removeAllDots(name)}
						aria-invalid={isInvalid}
						aria-errormessage={getFieldErrorId(name)}
						aria-labelledby={id}
						isInvalid={isInvalid}
						onChange={value => {
							if (!value) {
								onChange('');
								return;
							}
							const newValue = `${String(value.month).padStart(2, '0')}/${String(
								value.day
							).padStart(2, '0')}/${value.year}`;
							onChange(newValue);
						}}
						onBlur={onBlur}
						value={value ? parseUsaDateToISO8601(value) : null}
						size={size}
						isDisabled={disabled}
					/>
				)}
			/>
		</Field>
	);
};
