import React, { InputHTMLAttributes } from 'react';

import styled from '@emotion/styled';

import { FileUpload } from '@agentero/models/shared';

import { FieldStatus } from './fieldStatus';
import { EmptyDroppable } from './fileDrop/EmptyDroppable';
import { PreviewFile } from './fileDrop/PreviewFile';

export enum FileDropError {
	NotValidFileType
}

const FileDropContainer = styled.div<{ height?: number }>`
	--min-height: ${props => props.height ?? 400}px;
`;

type FileDropProps = InputHTMLAttributes<HTMLInputElement> & {
	onError: (error: FileDropError) => void;
	onValueChange: (value: FileUpload) => void;
	availableFilesText: string;
	availableFileTypesError: string;
	valueFile?: FileUpload;
	status?: FieldStatus;
	height?: number;
	previewMode?: boolean;
};

export const FileDrop = (props: FileDropProps) => {
	const { valueFile, availableFileTypesError, onValueChange, accept, previewMode } = props;
	const status = props.status || FieldStatus.None;

	return (
		<FileDropContainer height={props.height}>
			{valueFile?.file || valueFile?.url ? (
				<PreviewFile
					file={valueFile?.file}
					fileName={valueFile?.name ?? ''}
					url={valueFile?.url}
					accept={accept}
					availableFileTypesError={availableFileTypesError}
					onValueChange={onValueChange}
					hasError={status === FieldStatus.Error}
					previewMode={previewMode}
				/>
			) : previewMode ? null : (
				<EmptyDroppable {...props} status={status} />
			)}
		</FileDropContainer>
	);
};
