import { css } from '@emotion/react';

import { HEXtoRGBA } from '../utils';

export const variables = css`
	@layer emotionVariables {
		:root {
			/* This avoids @reach-ui dialog warning */
			--reach-dialog: 1;

			/* Colors */
			--color-primary-base: #2dbc90;
			--color-primary-base-alpha: ${HEXtoRGBA('2dbc90')};
			--color-primary-lighten-80: #d2f4e9;
			--color-primary-lighten-80-alpha: ${HEXtoRGBA('d2f4e9')};
			--color-primary-lighten-50: #8fe4ca;
			--color-primary-lighten-50-alpha: ${HEXtoRGBA('8fe4ca')};
			--color-primary-darken-10: #28a981;
			--color-primary-darken-10-apha: ${HEXtoRGBA('28A981')};
			--color-primary-darken-30: #1f8364;
			--color-primary-darken-30-alpha: ${HEXtoRGBA('1f8364')};
			--color-primary-darken-50: #165e48;
			--color-primary-darken-50-alpha: ${HEXtoRGBA('165e48')};

			--color-gray-base: #282b33;
			--color-gray-base-alpha: ${HEXtoRGBA('282b33')};
			--color-gray-lighten-95: #f4f4f6;
			--color-gray-lighten-95-alpha: ${HEXtoRGBA('f4f4f6')};
			--color-gray-lighten-90: #e8e9ed;
			--color-gray-lighten-90-alpha: ${HEXtoRGBA('e8e9ed')};
			--color-gray-lighten-80: #d1d4db;
			--color-gray-lighten-80-alpha: ${HEXtoRGBA('d1d4db')};
			--color-gray-lighten-40: #8d93a5;
			--color-gray-lighten-40-alpha: ${HEXtoRGBA('8D93A5')};
			--color-gray-lighten-20: #4c5261;
			--color-gray-lighten-20-alpha: ${HEXtoRGBA('4c5261')};

			--color-blue-base: #4ba4f6;
			--color-blue-base-alpha: ${HEXtoRGBA('4ba4f6')};
			--color-blue-lighten-80: #dbecfd;
			--color-blue-lighten-80-alpha: ${HEXtoRGBA('dbecfd')};
			--color-blue-lighten-40: #90c4f9;
			--color-blue-lighten-40-alpha: ${HEXtoRGBA('90c4f9')};
			--color-blue-darken-30: #0a74d6;
			--color-blue-darken-30-alpha: ${HEXtoRGBA('0a74d6')};
			--color-blue-darken-50: #075398;
			--color-blue-darken-50-alpha: ${HEXtoRGBA('075398')};

			--color-orange-base: #ffb766;
			--color-orange-base-alpha: ${HEXtoRGBA('ffb766')};
			--color-orange-lighten-80: #fff0e0;
			--color-orange-lighten-80-alpha: ${HEXtoRGBA('fff0e0')};
			--color-orange-lighten-40: #fed3a3;
			--color-orange-lighten-40-alpha: ${HEXtoRGBA('fed3a3')};
			--color-orange-darken-30: #f98400;
			--color-orange-darken-30-alpha: ${HEXtoRGBA('f98400')};
			--color-orange-darken-50: #b25e00;
			--color-orange-darken-50-alpha: ${HEXtoRGBA('b25e00')};

			--color-red-base: #f44336;
			--color-red-base-alpha: ${HEXtoRGBA('f44336')};
			--color-red-lighten-80: #fcd7d4;
			--color-red-lighten-80-alpha: ${HEXtoRGBA('fcd7d4')};
			--color-red-lighten-40: #f88e86;
			--color-red-lighten-40-alpha: ${HEXtoRGBA('f88e86')};
			--color-red-darken-30: #c5170a;
			--color-red-darken-30-alpha: ${HEXtoRGBA('c5170a')};
			--color-red-darken-50: #8d1007;
			--color-red-darken-50-alpha: ${HEXtoRGBA('8d1007')};

			--color-violet-base: #673ab7;
			--color-violet-base-alpha: ${HEXtoRGBA('673ab7')};
			--color-violet-lighten-80: #dfd8f3;
			--color-violet-lighten-80-alpha: ${HEXtoRGBA('dfd8f3')};
			--color-violet-lighten-40: #a08bda;
			--color-violet-lighten-40-alpha: ${HEXtoRGBA('a08bda')};
			--color-violet-darken-30: #4d319b;
			--color-violet-darken-30-alpha: ${HEXtoRGBA('4d319b')};
			--color-violet-darken-50: #3a2574;
			--color-violet-darken-50-alpha: ${HEXtoRGBA('3a2574')};

			--color-pink-base: #e8175d;
			--color-pink-base-alpha: ${HEXtoRGBA('e8175d')};
			--color-pink-lighten-80: #fad1df;
			--color-pink-lighten-80-alpha: ${HEXtoRGBA('fad1df')};
			--color-pink-lighten-40: #f28cae;
			--color-pink-lighten-40-alpha: ${HEXtoRGBA('f28cae')};
			--color-pink-darken-30: #b8144b;
			--color-pink-darken-30-alpha: ${HEXtoRGBA('b8144b')};
			--color-pink-darken-50: #8a0f38;
			--color-pink-darken-50-alpha: ${HEXtoRGBA('8a0f38')};

			--color-oranged-base: #ffb766;
			--color-oranged-base-alpha: ${HEXtoRGBA('ffb766')};
			--color-oranged-lighten-80: #fee1cd;
			--color-oranged-lighten-80-alpha: ${HEXtoRGBA('fee1cd')};
			--color-oranged-darken-30: #632b03;
			--color-oranged-darken-30-alpha: ${HEXtoRGBA('632b03')};

			--color-yellow-base: #f9c440;
			--color-yellow-base-alpha: ${HEXtoRGBA('f9c440')};
			--color-yellow-lighten-80: #fdf1ce;
			--color-yellow-lighten-80-alpha: ${HEXtoRGBA('fdf1ce')};
			--color-yellow-darken-30: #624b04;
			--color-yellow-darken-30-alpha: ${HEXtoRGBA('624b04')};

			/* Sizes */
			--size-unit: 0.5rem; // 8px
			--size-sidebar-width: 340px;

			/* Easings */
			--easing-ease-in-out-quint: cubic-bezier(0.83, 0, 0.17, 1);
			--easing-ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);

			--font-family: 'Inter', sans-serif;
		}
	}
`;
