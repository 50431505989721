import { GetAgencyComplianceInformationResponse } from '@agentero/grpc-clients/grpc/agency-fe';
import { GetAgentComplianceInformationResponse } from '@agentero/grpc-clients/grpc/agent-fe';

import { State } from '../shared/State';
import {
	ComplianceLicenseGlobalStatus,
	parseComplianceGlobalStatus
} from './complianceLicense/ComplianceGlobalStatus';
import {
	ComplianceLicenseError,
	parseComplianceLicenseError
} from './complianceLicense/ComplianceLicenseError';
import {
	ComplianceStatesByStatus,
	parseComplianceStatesByStatus
} from './complianceLicense/ComplianceStatesByStatus';
import {
	ComplianceStatesLicenses,
	parseComplianceStatesLicenses
} from './complianceLicense/ComplianceStatesLicenses';
import {
	LineOfAuthority,
	parseLinesOfAuthorityListFromProto
} from './complianceLicense/LineOfAuthority';

export type ComplianceLicense = {
	npn: string;
	licenseState: State;
	licenseNumber: string;
	linesOfAuthority: LineOfAuthority[];
	licenses: ComplianceStatesLicenses[];
	statesByStatus: ComplianceStatesByStatus[];
	globalStatus: ComplianceLicenseGlobalStatus;
	//TODO: Remove when dashboard alerts are replaced by widget
	complianceError?: ComplianceLicenseError;
};

export const parseComplianceLicense = (
	license:
		| GetAgencyComplianceInformationResponse.AsObject
		| GetAgentComplianceInformationResponse.AsObject
): ComplianceLicense => ({
	npn: license.npn,
	licenseState: license.licenseState as State,
	licenseNumber: license.licenseNumber,
	linesOfAuthority: parseLinesOfAuthorityListFromProto(license.linesOfAuthorityList),
	licenses: parseComplianceStatesLicenses(license.licensesList),
	statesByStatus: parseComplianceStatesByStatus(license.licensesList),
	globalStatus: parseComplianceGlobalStatus(license.licenseGlobalStatus),
	complianceError: parseComplianceLicenseError(license.complianceError)
});

export const NPN_HELP_HREF = 'https://nipr.com/help/look-up-your-npn';

export const isLicenseMissing = (globalStatus: ComplianceLicenseGlobalStatus) =>
	globalStatus === ComplianceLicenseGlobalStatus.NotFound ||
	globalStatus === ComplianceLicenseGlobalStatus.None;

export const isLicenseValid = (globalStatus: ComplianceLicenseGlobalStatus) =>
	globalStatus === ComplianceLicenseGlobalStatus.Valid;
