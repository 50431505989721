import React, { forwardRef } from 'react';

import styled from '@emotion/styled';
import { NumericFormat } from 'react-number-format';

import { IconAttachMoney } from '@agentero/icons';

import { Input, getStatusVariant } from './Input';
import { FieldStatus } from './fieldStatus';

type InputCurrencyProps = {
	status?: FieldStatus;
	isBig?: boolean;
	maxNumber?: number;
	allowNegative?: boolean;
	decimalScale?: number;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const InputCurrencyIcon = styled.div`
	position: absolute;
	top: 50%;
	left: 0;
	width: 40px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-right: 1px solid ${({ theme }) => theme.colors.gray.lighten80};
	translate: 0 -50%;

	svg {
		width: 20px;
		height: 20px;

		path {
			fill: ${({ theme }) => theme.colors.gray.lighten40};
		}
	}
`;

const InputCurrencyContainer = styled.div<{ isBig?: boolean; status?: FieldStatus }>`
	position: relative;

	${Input} {
		padding-left: ${({ isBig }) => (isBig ? '60px' : '44px')};
		${getStatusVariant}
	}

	${InputCurrencyIcon} {
		width: ${({ isBig }) => (isBig ? '48px' : '32px')};
	}
`;

export const InputCurrency = forwardRef<HTMLInputElement, InputCurrencyProps>(
	(
		{
			status,
			isBig = false,
			placeholder,
			onChange,
			value,
			maxNumber,
			allowNegative = false,
			decimalScale = 2,
			autoFocus,
			name,
			id,
			'aria-invalid': ariaInvalid,
			'aria-errormessage': ariaErrormessage
		},
		ref
	) => {
		return (
			<InputCurrencyContainer data-cy="currency-input" isBig={isBig} status={status}>
				<InputCurrencyIcon>
					<IconAttachMoney />
				</InputCurrencyIcon>

				<NumericFormat
					id={id}
					name={name}
					value={value as string | number}
					placeholder={placeholder}
					thousandSeparator={true}
					decimalScale={decimalScale}
					fixedDecimalScale={true}
					onChange={onChange}
					customInput={Input}
					allowNegative={allowNegative}
					isAllowed={values => {
						const { floatValue } = values;
						return maxNumber && floatValue ? floatValue <= maxNumber : true;
					}}
					autoFocus={autoFocus}
					getInputRef={ref}
					aria-invalid={ariaInvalid}
					aria-errormessage={ariaErrormessage}
				/>
			</InputCurrencyContainer>
		);
	}
);
