import { useRef } from 'react';

import { CalendarDate, isSameDay, isSameMonth } from '@internationalized/date';
import { useCalendarCell } from '@react-aria/calendar';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { CalendarState, RangeCalendarState } from '@react-stately/calendar';

import { styled } from '@agentero/styles/jsx';
import { StyledVariantProps } from '@agentero/styles/types';

const CalendarCellTd = styled('td', {
	base: {
		position: 'relative',
		textAlign: 'center',
		width: '2rem'
	},
	variants: {
		isFocusVisible: {
			true: {
				zIndex: 10
			},
			false: {
				zIndex: 0
			}
		}
	}
});

const CellButton = styled('button', {
	base: {
		position: 'relative',
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 'md',
		gap: '8',
		textStyle: 'body.small',
		height: '2rem',
		width: '2rem',
		paddingInline: '16',
		fontWeight: '600',
		cursor: 'pointer',
		transition: 'background 0.2s',
		textDecoration: 'none',
		whiteSpace: 'nowrap',
		WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
		'&:disabled': {
			pointerEvents: 'none',
			opacity: '0.3'
		},
		outline: 'none'
	},
	variants: {
		variant: {
			selected: {
				backgroundColor: 'button.bg.primary',
				color: 'button.text.primary',
				borderColor: 'button.border.primary',
				'&:hover': {
					backgroundColor: 'button.bg.primary.hover'
				},
				'&:active': {
					backgroundColor: 'button.bg.primary.active'
				}
			},
			inRange: {
				backgroundColor: 'neutral.95',
				color: 'neutral',
				borderRadius: 'none',
				'&:hover': {
					backgroundColor: 'neutral.90'
				},
				'&:active': {
					backgroundColor: 'neutral.80'
				}
			}
		},
		placed: {
			start: {
				borderTopRightRadius: 'unset',
				borderBottomRightRadius: 'unset'
			},
			end: {
				borderTopLeftRadius: 'unset',
				borderBottomLeftRadius: 'unset'
			}
		},
		isFocusVisible: {
			true: {
				boxShadow: '0 0 0 0.125rem white, 0 0 0 4px token(colors.neutral)'
			}
		}
	}
});

type CellButtonVariants = StyledVariantProps<typeof CellButton>;

type CalendarCellProps = {
	state: CalendarState | RangeCalendarState;
	currentMonth: CalendarDate;
	date: CalendarDate;
};

export const CalendarCell = ({ state, date, currentMonth }: CalendarCellProps) => {
	const ref = useRef(null);
	const { cellProps, buttonProps, isSelected, isOutsideVisibleRange, isDisabled, formattedDate } =
		useCalendarCell({ date }, state, ref);

	const { focusProps, isFocusVisible } = useFocusRing();

	const isOutsideMonth = !isSameMonth(currentMonth, date);

	let variants: CellButtonVariants;

	if ('highlightedRange' in state) {
		variants = getRangeCellButtonVariants({ isSelected, state, date });
	} else {
		variants = getSingleCellButtonVariants({ isSelected });
	}

	return (
		<CalendarCellTd {...cellProps} isFocusVisible={isFocusVisible}>
			<CellButton
				{...mergeProps(buttonProps, focusProps)}
				{...variants}
				ref={ref}
				disabled={isDisabled || isOutsideMonth}
				hidden={isOutsideVisibleRange}
				isFocusVisible={isFocusVisible}>
				{formattedDate}
			</CellButton>
		</CalendarCellTd>
	);
};

type GetSingleCellButtonVariants = {
	isSelected: boolean;
};

const getSingleCellButtonVariants = ({
	isSelected
}: GetSingleCellButtonVariants): CellButtonVariants => {
	return {
		variant: isSelected ? 'selected' : undefined
	};
};

type GetRangeCellButtonVariants = {
	isSelected: boolean;
	state: RangeCalendarState;
	date: CalendarDate;
};

const getRangeCellButtonVariants = ({
	isSelected,
	state,
	date
}: GetRangeCellButtonVariants): CellButtonVariants => {
	const isSelectionStart = state.highlightedRange
		? isSameDay(date, state.highlightedRange.start)
		: isSelected;
	const isSelectionEnd = state.highlightedRange
		? isSameDay(date, state.highlightedRange.end)
		: isSelected;

	const rangeVariant = isSelectionStart || isSelectionEnd ? 'selected' : 'inRange';
	const variant = isSelected ? rangeVariant : undefined;
	const placedVariant = isSelectionStart ? 'start' : isSelectionEnd ? 'end' : undefined;

	return {
		variant,
		placed: placedVariant
	};
};
