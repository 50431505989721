import { HTMLInputTypeAttribute, PropsWithChildren } from 'react';

import { RegisterOptions } from 'react-hook-form';

import { getFieldError, getFieldErrorId, removeAllDots, useAgFormContext } from '@agentero/ui';
import { getInputStatusFromError } from '@agentero/utils';

import { InputInteger } from '../InputInteger';
import { FormGroup, FormGroupGenericProps } from './FormGroup';

type FormGroupInputTextProps<T> = {
	type?: HTMLInputTypeAttribute;
	placeholder?: string;
	minValue?: number;
	maxValue?: number;
	autoFocus?: boolean;
	registerOptions?: RegisterOptions;
	displayErrorMessage?: boolean;
	defaultValue?: number;
} & FormGroupGenericProps<T>;

export const FormGroupInputInteger = <T extends {}>({
	name,
	registerOptions,
	displayErrorMessage,
	placeholder,
	minValue,
	maxValue,
	autoFocus,
	children,
	disabled,
	defaultValue,
	...formGroupProps
}: PropsWithChildren<FormGroupInputTextProps<T>>) => {
	const {
		register,
		formState: { errors }
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	return (
		<FormGroup
			name={name}
			nestedChildren={children}
			{...formGroupProps}
			displayErrorMessage={displayErrorMessage}>
			<InputInteger
				id={removeAllDots(name)}
				{...register(name, registerOptions)}
				status={getInputStatusFromError(fieldError?.message as string)}
				placeholder={placeholder}
				aria-invalid={!!fieldError?.message}
				aria-errormessage={getFieldErrorId(name)}
				min={minValue}
				max={maxValue}
				autoFocus={autoFocus}
				disabled={disabled}
				defaultValue={defaultValue}
			/>
		</FormGroup>
	);
};
