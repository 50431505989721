import { styled } from '@agentero/styles/jsx';
import { StyledVariantProps } from '@agentero/styles/types';

export const LoadingRoot = styled('div', {
	base: {
		display: 'grid',
		placeItems: 'center',
		backgroundColor: 'inherit',
		opacity: '0',
		animation: 'fadeIn .3s forwards',
		'&:before': {
			content: '""',
			display: 'block',
			height: '1em',
			width: '1em',
			borderWidth: '0.125em',
			borderStyle: 'solid',
			borderRadius: 'full',
			borderColor: 'inherit',
			borderTopColor: 'transparent',
			animation: 'spinAround 1s infinite linear'
		}
	},
	variants: {
		size: {
			sm: {
				'&:before': {
					height: '1em',
					width: '1em'
				}
			},
			md: {
				'&:before': {
					height: '1.5em',
					width: '1.5em'
				}
			},
			lg: {
				'&:before': {
					height: '2em',
					width: '2em'
				}
			}
		}
	}
});

type LoadingProps = StyledVariantProps<typeof LoadingRoot>;

export const Loading = ({ size }: LoadingProps) => <LoadingRoot size={size} role="status" />;
