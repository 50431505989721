import { PropsWithChildren } from 'react';

import styled from '@emotion/styled';

const ListTableContainer = styled.div`
	flex: 1;
	position: relative;
	z-index: 1;
	${({ theme }) => theme.textSettings.S};

	${({ theme }) => theme.mediaqueries.M} {
		display: flex;
		flex-direction: column;
		min-height: 0;
	}
`;

export const ListTableScroller = styled.div`
	flex: 1;
	min-height: 0;
	overflow: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	&:before {
		content: '';
		position: sticky;
		display: block;
		z-index: 1;
		top: 0;
		left: 0;
		margin-bottom: -1px;
		width: 100%;
		height: 1px;
		background: ${({ theme }) => theme.colors.gray.lighten90};
	}
`;

export const ListTableScrollerContent = styled.div`
	flex: 1;
	min-height: 100%;
	display: flex;
	flex-direction: column;
`;

ListTableScrollerContent.defaultProps = {
	role: 'table'
};

interface IListTableProps {
	className?: string;
}

export const ListTable = ({ children, className }: PropsWithChildren<IListTableProps>) => {
	return (
		// TODO: Handle cutting the render if state change
		<ListTableContainer data-cy="list-table" className={className}>
			<ListTableScroller>
				<ListTableScrollerContent>{children}</ListTableScrollerContent>
			</ListTableScroller>
		</ListTableContainer>
	);
};
