import { LineOfAuthority as LineOfAuthorityProto } from '@agentero/grpc-clients/grpc/shared/license';

export enum LineOfAuthority {
	Life = 'life',
	Health = 'health',
	Commercial = 'commercial',
	LimitedCommercial = 'limited_commercial',
	PropertyAndCasualty = 'property_and_casualty',
	Personal = 'personal',
	PersonalAuto = 'personal_auto',
	SurplusLines = 'surplus_lines',
	Other = 'other',
	Principal = 'principal'
}

export const loaLicense = Object.values(LineOfAuthority).filter(
	value => value !== LineOfAuthority.LimitedCommercial
);

export const loaEO = [
	LineOfAuthority.Personal,
	LineOfAuthority.Commercial,
	LineOfAuthority.LimitedCommercial,
	LineOfAuthority.Life
];

const lineOfAuthorityFromProtoMapping: {
	[key in LineOfAuthorityProto]: LineOfAuthority | undefined;
} = {
	[LineOfAuthorityProto.LINE_OF_AUTHORITY_UNDEFINED]: undefined,
	[LineOfAuthorityProto.LINE_OF_AUTHORITY_COMMERCIAL]: LineOfAuthority.Commercial,
	[LineOfAuthorityProto.LINE_OF_AUTHORITY_HEALTH]: LineOfAuthority.Health,
	[LineOfAuthorityProto.LINE_OF_AUTHORITY_LIFE]: LineOfAuthority.Life,
	[LineOfAuthorityProto.LINE_OF_AUTHORITY_PROPERTY_AND_CASUALTY]:
		LineOfAuthority.PropertyAndCasualty,
	[LineOfAuthorityProto.LINE_OF_AUTHORITY_PERSONAL]: LineOfAuthority.Personal,
	[LineOfAuthorityProto.LINE_OF_AUTHORITY_PERSONAL_AUTO]: LineOfAuthority.PersonalAuto,
	[LineOfAuthorityProto.LINE_OF_AUTHORITY_LIMITED_COMMERCIAL]: LineOfAuthority.LimitedCommercial,
	[LineOfAuthorityProto.LINE_OF_AUTHORITY_SURPLUS_LINES]: LineOfAuthority.SurplusLines,
	[LineOfAuthorityProto.LINE_OF_AUTHORITY_OTHER]: LineOfAuthority.Other,
	[LineOfAuthorityProto.LINE_OF_AUTHORITY_PRINCIPAL]: LineOfAuthority.Principal
};

const parseLineOfAuthorityFromProto = (
	lineOfAuthority: LineOfAuthorityProto
): LineOfAuthority | undefined => lineOfAuthorityFromProtoMapping[lineOfAuthority];

export const parseLinesOfAuthorityListFromProto = (
	linesOfAuthorityList: LineOfAuthorityProto[]
): LineOfAuthority[] =>
	linesOfAuthorityList.map(parseLineOfAuthorityFromProto).filter(Boolean) as LineOfAuthority[];

const lineOfAuthorityToProtoMapping: {
	[key in LineOfAuthority]: LineOfAuthorityProto;
} = {
	[LineOfAuthority.Commercial]: LineOfAuthorityProto.LINE_OF_AUTHORITY_COMMERCIAL,
	[LineOfAuthority.Health]: LineOfAuthorityProto.LINE_OF_AUTHORITY_HEALTH,
	[LineOfAuthority.Life]: LineOfAuthorityProto.LINE_OF_AUTHORITY_LIFE,
	[LineOfAuthority.PropertyAndCasualty]:
		LineOfAuthorityProto.LINE_OF_AUTHORITY_PROPERTY_AND_CASUALTY,
	[LineOfAuthority.Personal]: LineOfAuthorityProto.LINE_OF_AUTHORITY_PERSONAL,
	[LineOfAuthority.PersonalAuto]: LineOfAuthorityProto.LINE_OF_AUTHORITY_PERSONAL_AUTO,
	[LineOfAuthority.LimitedCommercial]: LineOfAuthorityProto.LINE_OF_AUTHORITY_LIMITED_COMMERCIAL,
	[LineOfAuthority.SurplusLines]: LineOfAuthorityProto.LINE_OF_AUTHORITY_SURPLUS_LINES,
	[LineOfAuthority.Other]: LineOfAuthorityProto.LINE_OF_AUTHORITY_OTHER,
	[LineOfAuthority.Principal]: LineOfAuthorityProto.LINE_OF_AUTHORITY_PRINCIPAL
};

export const parseLineOfAuthorityToProto = (
	lineOfAuthority: LineOfAuthority
): LineOfAuthorityProto => lineOfAuthorityToProtoMapping[lineOfAuthority];
