import { forwardRef } from 'react';

import { IconClose, IconSearch } from '@agentero/icons';
import { styled } from '@agentero/styles/jsx';
import { StyledVariantProps } from '@agentero/styles/types';

import { Input, InputVariants } from './form/fields/fieldText/Input';

// TODO: refactor and restyle the entire component
const InputSearchContainer = styled('div', {
	base: {
		position: 'relative'
	}
});

const IconInputTextSearch = styled('div', {
	base: {
		position: 'absolute',
		top: '50%',
		width: '1.5rem',
		height: '1.5rem',
		transform: 'translate(0, -50%)'
	},
	variants: {
		size: {
			sm: {
				left: '6'
			},
			md: {
				left: '8'
			},
			lg: {
				left: '12'
			}
		}
	},
	defaultVariants: {
		size: 'sm'
	}
});

const CloseIconInputTextSearch = styled('button', {
	base: {
		position: 'absolute',
		top: '50%',
		width: '24px',
		height: '24px',
		transform: 'translate(0, -50%)'
	},
	variants: {
		size: {
			sm: {
				right: '6'
			},
			md: {
				right: '8'
			},
			lg: {
				right: '12'
			}
		}
	},
	defaultVariants: {
		size: 'sm'
	}
});

const InputSearchComponent = styled(Input, {
	base: {
		paddingInline: '32'
	},
	variants: {
		size: {
			md: {
				paddingInline: '40'
			},
			lg: {
				paddingInline: '48'
			}
		}
	}
});

export type InputSearchSize = StyledVariantProps<typeof InputSearchComponent>['size'];

type InputCustomProps = Omit<
	React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
	'value' | 'onChange' | 'size'
> & {
	value: string;
	size?: StyledVariantProps<typeof InputSearchComponent>['size'];
	onChange: (newValue: string) => void;
};

type InputSearchProps = InputVariants & InputCustomProps;

export const InputSearch = forwardRef<HTMLInputElement, Omit<InputSearchProps, 'role' | 'css'>>(
	({ value, onChange, size, ...props }, ref) => {
		const onSearchChange = (search: string) => {
			onChange(search);
		};

		return (
			<InputSearchContainer>
				<IconInputTextSearch size={size}>
					<IconSearch />
				</IconInputTextSearch>
				<InputSearchComponent
					placeholder={'Search'}
					{...props}
					size={size}
					onChange={e => onSearchChange(e.target.value)}
					type="search"
					value={value}
					ref={ref}
				/>
				{value && (
					<CloseIconInputTextSearch onClick={() => onSearchChange('')} size={size}>
						<IconClose />
					</CloseIconInputTextSearch>
				)}
			</InputSearchContainer>
		);
	}
);
