'use client';

import {
	BaseSyntheticEvent,
	DetailedHTMLProps,
	FormHTMLAttributes,
	PropsWithChildren,
	createContext,
	useContext
} from 'react';

import { FormProvider, SubmitHandler, UseFormReturn, useFormContext } from 'react-hook-form';

const AgFormContext = createContext<{ isReadonly: boolean }>({
	isReadonly: false
});

type AgFormProviderProps<T extends {}, TContext = any> = {
	id?: string;
	methods: UseFormReturn<T, TContext>;
	ariaLabel: string;
	isReadonly?: boolean;
	onSubmit?: SubmitHandler<T>;
	onChange?: SubmitHandler<T>;
} & Omit<
	DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
	'onSubmit' | 'onChange'
>;

const useAgFormContext = <T extends {}>() => {
	const methods = useFormContext<T>();

	const formContext = useContext(AgFormContext);

	return { ...methods, ...formContext };
};

const Form = <T extends {}>({
	id,
	methods,
	isReadonly = false,
	onSubmit,
	onChange,
	ariaLabel,
	children,
	...props
}: PropsWithChildren<AgFormProviderProps<T>>) => {
	const onFormSubmit = (data: T, event?: BaseSyntheticEvent) => {
		onSubmit && onSubmit(data, event);
	};

	return (
		<AgFormContext.Provider value={{ isReadonly }}>
			<FormProvider {...methods}>
				{isReadonly ? (
					<dl>{children}</dl>
				) : (
					<form
						id={id}
						aria-label={ariaLabel}
						onSubmit={onSubmit && methods?.handleSubmit(onFormSubmit)}
						onChange={onChange && methods?.handleSubmit(onFormSubmit)}
						noValidate
						{...props}>
						{children}
					</form>
				)}
			</FormProvider>
		</AgFormContext.Provider>
	);
};

export { Form, useAgFormContext };
