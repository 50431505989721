import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import { Trigger } from '@radix-ui/react-accordion';

import { IconKeyboardArrowDown } from '@agentero/icons';
import { styled } from '@agentero/styles/jsx';

import { AccordionHeader } from './Header';

const TriggerComponent = styled(Trigger, {
	base: {
		display: 'flex',
		flex: 1,
		paddingInline: '1rem',
		paddingBlock: '1rem',
		alignItems: 'center',
		justifyContent: 'space-between',
		fontWeight: 'medium',
		transition: 'all',
		cursor: 'pointer',
		textStyle: 'body.base',

		_hover: {
			color: 'neutral.60'
		},

		'& > svg': {
			h: '1rem',
			w: '1rem',
			flexShrink: '0',
			transition: 'transform',
			transitionDuration: '0.2s'
		},

		'&[data-state=open] > svg': {
			transform: 'rotate(180deg)'
		}
	}
});

export const AccordionTrigger = forwardRef<
	ElementRef<typeof Trigger>,
	ComponentPropsWithoutRef<typeof Trigger>
>(({ children, ...props }, ref) => (
	<AccordionHeader>
		<TriggerComponent ref={ref} {...props}>
			{children}
			<IconKeyboardArrowDown />
		</TriggerComponent>
	</AccordionHeader>
));

AccordionTrigger.displayName = Trigger.displayName;
