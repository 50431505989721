import { HTMLInputTypeAttribute, PropsWithChildren } from 'react';

import { Controller } from 'react-hook-form';

import { getFieldError, getFieldErrorId, removeAllDots, useAgFormContext } from '@agentero/ui';
import { getInputStatusFromError } from '@agentero/utils';

import { InputSearchDataList, Option } from '../InputSearchDataList';
import { FormGroup, FormGroupGenericProps } from './FormGroup';

type FormGroupInputTextProps<T> = {
	type?: HTMLInputTypeAttribute;
	placeholder?: string;
	options: Option[];
	inputValueMinLength?: number;
	disabled?: boolean;
} & FormGroupGenericProps<T>;

export const FormGroupInputSearchDatalist = <T extends {}>({
	name,
	children,
	options,
	inputValueMinLength = 1,
	disabled,
	placeholder,
	...formGroupProps
}: PropsWithChildren<FormGroupInputTextProps<T>>) => {
	const {
		control,
		formState: { errors }
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	return (
		<FormGroup
			name={name}
			nestedChildren={children}
			getReadonlyValue={inputValue =>
				options.find(option => option.id === inputValue)?.description ?? '—'
			}
			{...formGroupProps}>
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, onBlur, value, name, ref } }) => {
					return (
						<InputSearchDataList
							id={removeAllDots(name)}
							name={name}
							options={options}
							onItemChange={onChange}
							onBlur={onBlur}
							value={value}
							inputValueMinLength={inputValueMinLength}
							disabled={disabled}
							status={getInputStatusFromError(fieldError?.message as string)}
							placeholder={placeholder}
							aria-invalid={!!fieldError?.message}
							aria-errormessage={getFieldErrorId(name)}
							ref={ref}
						/>
					);
				}}
			/>
		</FormGroup>
	);
};
