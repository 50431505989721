export const moveItemToFirst = <T>(selected: T, arr: T[]) => {
	const index = arr.findIndex(item => item === selected);
	if (index < 1) return arr;
	else return [selected, ...arr.slice(0, index), ...arr.slice(index + 1)];
};

export const moveItemToLast = <T>(selected: T, arr: T[]) => {
	const index = arr.findIndex(item => item === selected);
	if (index == -1 || index === arr.length) return arr;
	else return [...arr.slice(0, index), ...arr.slice(index + 1), selected];
};
