import styled from '@emotion/styled';

type SkeletonProps = {
	width?: number | string;
	height?: number;
	isRounded?: boolean;
	mt?: number;
	mb?: number;
	ml?: number;
	mr?: number;
};

export const Skeleton = styled.div<SkeletonProps>`
	position: relative;
	width: ${({ width }) => (width ? (typeof width == 'number' ? `${width}px` : width) : '100%')};
	height: ${({ height }) => (height ? `${height}px` : '100%')};
	max-width: 100%;
	margin-top: ${({ mt }) => (mt ? mt + 'px' : 'unset')};
	margin-bottom: ${({ mb }) => (mb ? mb + 'px' : 'unset')};
	margin-left: ${({ ml }) => (ml ? ml + 'px' : 'unset')};
	margin-right: ${({ mr }) => (mr ? mr + 'px' : 'unset')};
	overflow: hidden;
	background: ${({ theme }) => theme.colors.gray.lighten80};
	${({ isRounded }) => (isRounded ? `border-radius: 50%` : '')};

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform: translateX(-100%);
		animation: skeleton-shine 1.6s infinite;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0.5) 50%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	@keyframes skeleton-shine {
		0% {
			transform: translateX(-100%);
		}
		100% {
			transform: translateX(100%);
		}
	}
`;
