import { InsuranceType as InsuranceTypeShared } from '@agentero/grpc-clients/grpc/shared/policy';
import { CommercialIcon, HomeOwnersIcon, PersonalAutoIcon, PublicIcon } from '@agentero/icons';

export enum InsuranceType {
	UndefinedInsuranceType = 'public',
	PersonalAuto = 'personal_auto',
	PersonalUmbrella = 'personal_umbrella',
	Homeonwners = 'home_owner',
	Renter = 'renter',
	Condo = 'condo',
	DwellingFire = 'dwelling_fire',
	Landlord = 'landlord',
	Motorcycle = 'motorcycle',
	Flood = 'flood',
	AtvOffRoadVehicle = 'atv_off_road_vehicle',
	MobileHomeowners = 'mobile_homeowners',
	Life = 'life',
	Other = 'other',
	CommercialAuto = 'commercial_auto',
	Commercial = 'commercial',
	InlandMarine = 'inland_marine',
	ProfessionalLiability = 'professional_liability',
	GeneralLiability = 'general_liability',
	BusinessOwnerPolicy = 'business_owner_policy',
	WorkersCompesation = 'workers_compesation',
	CommercialProperty = 'commercial_property',
	CommercialUmbrella = 'commercial_umbrela',
	DirectorsAndOfficers = 'directors_and_officers',
	ErrorsAndOmissions = 'errors_and_omissions',
	ExcessLiability = 'excess_liability',
	CyberLiability = 'cyber_liability',
	RentGuarantee = 'rent_guarantee',
	ExcessAndSurplusCommercialCasualty = 'excess_and_surplus_commercial_casualty',
	SpecialEvents = 'special_events',
	SpecialtyCommercial = 'specialty_commercial',
	CommercialFlood = 'commercial_flood',
	ParametricEarthquake = 'parametric_earthquake',
	HomeAndElectronicProtection = 'home_and_electronic_proyection',
	RecreationalVehicles = 'recreational_vehicles'
}

export type InsuranceTypeFromBackAg = {
	id: string;
	type: InsuranceType;
};

export const raterInsuranceTypeMapping = {
	homeowners: InsuranceType.Homeonwners,
	auto: InsuranceType.PersonalAuto
};

export const insuranceTypeFromServiceProtoMapping: { [key in InsuranceTypeShared]: InsuranceType } =
	{
		[InsuranceTypeShared.UNDEFINED_INSURANCE_TYPE]: InsuranceType.UndefinedInsuranceType,
		[InsuranceTypeShared.ATV_OFF_ROAD_VEHICLE]: InsuranceType.AtvOffRoadVehicle,
		[InsuranceTypeShared.COMMERCIAL_AUTO]: InsuranceType.CommercialAuto,
		[InsuranceTypeShared.CONDO]: InsuranceType.Condo,
		[InsuranceTypeShared.DWELLING_FIRE]: InsuranceType.DwellingFire,
		[InsuranceTypeShared.FLOOD]: InsuranceType.Flood,
		[InsuranceTypeShared.HOMEOWNERS]: InsuranceType.Homeonwners,
		[InsuranceTypeShared.LANDLORD]: InsuranceType.Landlord,
		[InsuranceTypeShared.LIFE]: InsuranceType.Life,
		[InsuranceTypeShared.MOBILE_HOMEOWNERS]: InsuranceType.MobileHomeowners,
		[InsuranceTypeShared.MOTORCYCLE]: InsuranceType.Motorcycle,
		[InsuranceTypeShared.OTHER]: InsuranceType.Other,
		[InsuranceTypeShared.PERSONAL_AUTO]: InsuranceType.PersonalAuto,
		[InsuranceTypeShared.PERSONAL_UMBRELLA]: InsuranceType.PersonalUmbrella,
		[InsuranceTypeShared.RENTERS]: InsuranceType.Renter,
		[InsuranceTypeShared.COMMERCIAL]: InsuranceType.Commercial,
		[InsuranceTypeShared.INLAND_MARINE]: InsuranceType.InlandMarine,
		[InsuranceTypeShared.PROFESSIONAL_LIABILITY]: InsuranceType.ProfessionalLiability,
		[InsuranceTypeShared.GENERAL_LIABILITY]: InsuranceType.GeneralLiability,
		[InsuranceTypeShared.BUSINESS_OWNER_POLICY]: InsuranceType.BusinessOwnerPolicy,
		[InsuranceTypeShared.WORKERS_COMPENSATION]: InsuranceType.WorkersCompesation,
		[InsuranceTypeShared.COMMERCIAL_PROPERTY]: InsuranceType.CommercialProperty,
		[InsuranceTypeShared.COMMERCIAL_UMBRELLA]: InsuranceType.CommercialUmbrella,
		[InsuranceTypeShared.DIRECTORS_AND_OFFICERS]: InsuranceType.DirectorsAndOfficers,
		[InsuranceTypeShared.ERRORS_AND_OMISSIONS]: InsuranceType.ErrorsAndOmissions,
		[InsuranceTypeShared.EXCESS_LIABILITY]: InsuranceType.ExcessLiability,
		[InsuranceTypeShared.CYBER_LIABILITY]: InsuranceType.CyberLiability,
		[InsuranceTypeShared.RENT_GUARANTEE]: InsuranceType.RentGuarantee,
		[InsuranceTypeShared.EXCESS_AND_SURPLUS_COMMERCIAL_CASUALTY]:
			InsuranceType.ExcessAndSurplusCommercialCasualty,
		[InsuranceTypeShared.SPECIAL_EVENTS]: InsuranceType.SpecialEvents,
		[InsuranceTypeShared.SPECIALTY_COMMERCIAL]: InsuranceType.SpecialtyCommercial,
		[InsuranceTypeShared.COMMERCIAL_FLOOD]: InsuranceType.CommercialFlood,
		[InsuranceTypeShared.PARAMETRIC_EARTHQUAKE]: InsuranceType.ParametricEarthquake,
		[InsuranceTypeShared.HOME_AND_ELECTRONICS_PROTECTION]:
			InsuranceType.HomeAndElectronicProtection,
		[InsuranceTypeShared.RECREATIONAL_VEHICLES]: InsuranceType.RecreationalVehicles
	};

export const parseInsuranceTypeFromProto = (type: InsuranceTypeShared) => {
	return insuranceTypeFromServiceProtoMapping[type];
};

const insuranceTypeFromBackAgMapping: { [key: string]: InsuranceType } = {
	['Personal Auto']: InsuranceType.PersonalAuto,
	['Motorcycle']: InsuranceType.Motorcycle,
	['Homeowners']: InsuranceType.Homeonwners,
	['Renters']: InsuranceType.Renter,
	['Condo']: InsuranceType.Condo,
	['ATV/Off-Road Vehicle']: InsuranceType.AtvOffRoadVehicle,
	['Flood']: InsuranceType.Flood,
	['Landlord']: InsuranceType.Landlord,
	['Other']: InsuranceType.Other,
	['Life']: InsuranceType.Life,
	['Dwelling Fire']: InsuranceType.DwellingFire,
	['Commercial Auto']: InsuranceType.CommercialAuto,
	['Mobile Homeowners']: InsuranceType.MobileHomeowners,
	['Personal Umbrella']: InsuranceType.PersonalUmbrella,
	['Commercial']: InsuranceType.Commercial,
	['Inland Marine']: InsuranceType.InlandMarine,
	['Professional Liability']: InsuranceType.ProfessionalLiability,
	['General Liability']: InsuranceType.GeneralLiability,
	['Business Owner Policy']: InsuranceType.BusinessOwnerPolicy,
	['Workers Compensation']: InsuranceType.WorkersCompesation,
	['Commercial Propertu']: InsuranceType.CommercialProperty,
	['Commercial Umbrella']: InsuranceType.CommercialUmbrella,
	['Directors and Offices']: InsuranceType.DirectorsAndOfficers,
	['Errors and Omissions']: InsuranceType.ErrorsAndOmissions,
	['Excess Liability']: InsuranceType.ExcessLiability,
	['Cyber Liability']: InsuranceType.CyberLiability,
	['Rent Guarantee']: InsuranceType.RentGuarantee
};

export const parseInsuranceTypeFromBackAg = (type: string) => {
	return insuranceTypeFromBackAgMapping[type];
};

const insuranceTypeIconMapping: {
	[key in InsuranceType]?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
} = {
	[InsuranceType.Commercial]: CommercialIcon,
	[InsuranceType.Homeonwners]: HomeOwnersIcon,
	[InsuranceType.PersonalAuto]: PersonalAutoIcon
};

export const getInsuranceTypeIcon = (
	type: InsuranceType
): React.ComponentType<React.SVGProps<SVGSVGElement>> =>
	insuranceTypeIconMapping[type] || PublicIcon;

export const insuranceTypeToServiceProtoMapping: { [key in InsuranceType]: InsuranceTypeShared } = {
	[InsuranceType.UndefinedInsuranceType]: InsuranceTypeShared.UNDEFINED_INSURANCE_TYPE,
	[InsuranceType.AtvOffRoadVehicle]: InsuranceTypeShared.ATV_OFF_ROAD_VEHICLE,
	[InsuranceType.CommercialAuto]: InsuranceTypeShared.COMMERCIAL_AUTO,
	[InsuranceType.Condo]: InsuranceTypeShared.CONDO,
	[InsuranceType.DwellingFire]: InsuranceTypeShared.DWELLING_FIRE,
	[InsuranceType.Flood]: InsuranceTypeShared.FLOOD,
	[InsuranceType.Homeonwners]: InsuranceTypeShared.HOMEOWNERS,
	[InsuranceType.Landlord]: InsuranceTypeShared.LANDLORD,
	[InsuranceType.Life]: InsuranceTypeShared.LIFE,
	[InsuranceType.MobileHomeowners]: InsuranceTypeShared.MOBILE_HOMEOWNERS,
	[InsuranceType.Motorcycle]: InsuranceTypeShared.MOTORCYCLE,
	[InsuranceType.Other]: InsuranceTypeShared.OTHER,
	[InsuranceType.PersonalAuto]: InsuranceTypeShared.PERSONAL_AUTO,
	[InsuranceType.PersonalUmbrella]: InsuranceTypeShared.PERSONAL_UMBRELLA,
	[InsuranceType.Renter]: InsuranceTypeShared.RENTERS,
	[InsuranceType.Commercial]: InsuranceTypeShared.COMMERCIAL,
	[InsuranceType.InlandMarine]: InsuranceTypeShared.INLAND_MARINE,
	[InsuranceType.ProfessionalLiability]: InsuranceTypeShared.PROFESSIONAL_LIABILITY,
	[InsuranceType.GeneralLiability]: InsuranceTypeShared.GENERAL_LIABILITY,
	[InsuranceType.BusinessOwnerPolicy]: InsuranceTypeShared.BUSINESS_OWNER_POLICY,
	[InsuranceType.WorkersCompesation]: InsuranceTypeShared.WORKERS_COMPENSATION,
	[InsuranceType.CommercialProperty]: InsuranceTypeShared.COMMERCIAL_PROPERTY,
	[InsuranceType.CommercialUmbrella]: InsuranceTypeShared.COMMERCIAL_UMBRELLA,
	[InsuranceType.DirectorsAndOfficers]: InsuranceTypeShared.DIRECTORS_AND_OFFICERS,
	[InsuranceType.ErrorsAndOmissions]: InsuranceTypeShared.ERRORS_AND_OMISSIONS,
	[InsuranceType.ExcessLiability]: InsuranceTypeShared.EXCESS_LIABILITY,
	[InsuranceType.CyberLiability]: InsuranceTypeShared.CYBER_LIABILITY,
	[InsuranceType.RentGuarantee]: InsuranceTypeShared.RENT_GUARANTEE,
	[InsuranceType.ExcessAndSurplusCommercialCasualty]:
		InsuranceTypeShared.EXCESS_AND_SURPLUS_COMMERCIAL_CASUALTY,
	[InsuranceType.SpecialEvents]: InsuranceTypeShared.SPECIAL_EVENTS,
	[InsuranceType.SpecialtyCommercial]: InsuranceTypeShared.SPECIALTY_COMMERCIAL,
	[InsuranceType.CommercialFlood]: InsuranceTypeShared.COMMERCIAL_FLOOD,
	[InsuranceType.ParametricEarthquake]: InsuranceTypeShared.PARAMETRIC_EARTHQUAKE,
	[InsuranceType.HomeAndElectronicProtection]: InsuranceTypeShared.HOME_AND_ELECTRONICS_PROTECTION,
	[InsuranceType.RecreationalVehicles]: InsuranceTypeShared.RECREATIONAL_VEHICLES
};

export const parseInsuranceTypeToProto = (type: InsuranceType) => {
	return insuranceTypeToServiceProtoMapping[type];
};

export const insuranceTypeFromWorkItemServiceProtoMapping: { [key: string]: InsuranceType } = {
	['ATV_OFF_ROAD_VEHICLE']: InsuranceType.AtvOffRoadVehicle,
	['COMMERCIAL_AUTO']: InsuranceType.CommercialAuto,
	['CONDO']: InsuranceType.Condo,
	['DWELLING_FIRE']: InsuranceType.DwellingFire,
	['FLOOD']: InsuranceType.Flood,
	['HOMEOWNERS']: InsuranceType.Homeonwners,
	['LANDLORD']: InsuranceType.Landlord,
	['LIFE']: InsuranceType.Life,
	['MOBILE_HOMEOWNERS']: InsuranceType.MobileHomeowners,
	['MOTORCYCLE']: InsuranceType.Motorcycle,
	['OTHER']: InsuranceType.Other,
	['PERSONAL_AUTO']: InsuranceType.PersonalAuto,
	['PERSONAL_UMBRELLA']: InsuranceType.PersonalUmbrella,
	['RENTER']: InsuranceType.Renter,
	['COMMERCIAL']: InsuranceType.Commercial,
	['INLAND_MARINE']: InsuranceType.InlandMarine,
	['PROFESSIONAL_LIABILITY']: InsuranceType.ProfessionalLiability,
	['GENERAL_LIABILITY']: InsuranceType.GeneralLiability,
	['BUSINESS_OWNER_POLICY']: InsuranceType.BusinessOwnerPolicy,
	['WORKERS_COMPENSATION']: InsuranceType.WorkersCompesation,
	['COMMERCIAL_PROPERTY']: InsuranceType.CommercialProperty,
	['COMMERCIAL_UMBRELLA']: InsuranceType.CommercialUmbrella,
	['DIRECTORS_AND_OFFICERS']: InsuranceType.DirectorsAndOfficers,
	['ERRORS_AND_OMISSIONS']: InsuranceType.ErrorsAndOmissions,
	['EXCESS_LIABILITY']: InsuranceType.ExcessLiability,
	['CYBER_LIABILITY']: InsuranceType.CyberLiability,
	['RENT_GUARANTEE']: InsuranceType.RentGuarantee
};

export const parseInsuranceTypeFromWorkItemService = (lob: string) =>
	insuranceTypeFromWorkItemServiceProtoMapping[lob];
