import React from 'react';

import styled from '@emotion/styled';

type DatalistItemListSkeletonProps = {
	children: React.ComponentType<{ index: number }>;
};

const DatalistItem = styled.li`
	padding: 0 8px;
`;

const SkeletonContainer = styled.ul`
	position: relative;
`;

export const DatalistItemListSkeleton: React.FC<DatalistItemListSkeletonProps> = ({ children }) => {
	const preventInputFocus = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		event.preventDefault();
		event.stopPropagation();
	};

	return (
		<SkeletonContainer id="datalist-skeleton">
			{Array.from({ length: 5 }).map((_, index) => (
				<DatalistItem key={index} onMouseDown={preventInputFocus}>
					{React.createElement(children, { index })}
				</DatalistItem>
			))}
		</SkeletonContainer>
	);
};
