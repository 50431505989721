import React from 'react';

import InputMask from 'react-input-mask';

import { Input } from './Input';
import { FieldStatus } from './fieldStatus';

type InputDateProps = {
	status?: FieldStatus;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const InputDate = React.forwardRef<HTMLInputElement, InputDateProps>(
	({ status, onChange, disabled, value, onBlur, ...props }, ref) => (
		<InputMask
			mask="99/99/9999"
			disabled={disabled}
			onChange={onChange}
			onBlur={onBlur}
			value={value}>
			<Input status={status} disabled={disabled} ref={ref} {...props} />
		</InputMask>
	)
);
