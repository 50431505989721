import React from 'react';

import styled from '@emotion/styled';

type SpinnerProps = {
	size?: number;
	className?: string;
};

const SpinnerContainer = styled.svg<SpinnerProps>`
	animation: rotate 2s linear infinite;
	width: ${({ size }) => size}px;
	height: ${({ size }) => size}px;

	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}
`;

const SpinnerCircle = styled.circle`
	stroke: ${({ theme }) => theme.colors.primary.base};
	stroke-linecap: square;
	animation: dash 1.5s ease-in-out infinite;

	@keyframes dash {
		0% {
			stroke-dasharray: 1, 150;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -32;
		}
		100% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -124;
		}
	}
`;

const SpinnerCircleBg = styled.circle`
	stroke: ${({ theme }) => theme.colors.gray.lighten95};
	stroke-linecap: square;
`;

export const Spinner: React.FC<SpinnerProps> = ({ size = 64, className }) => (
	<SpinnerContainer
		size={size}
		className={className}
		viewBox="0 0 50 50"
		role="alert"
		aria-busy="true">
		<SpinnerCircleBg cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
		<SpinnerCircle cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
	</SpinnerContainer>
);
