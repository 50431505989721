import { ORDER_DIRECTION } from '@agentero/components';
import { ListCarriersRequest } from '@agentero/grpc-clients/grpc/carrier-fe';

export enum CarriersOrderByField {
	Score = 'score'
}

export type CarriersOrderBy = {
	direction: ORDER_DIRECTION;
	field: CarriersOrderByField;
};

const map = {
	[CarriersOrderByField.Score]: ListCarriersRequest.OrderBy.Field.FIELD_SCORE
};

export const parseCarrierOrderBy = (
	value?: CarriersOrderByField
): ListCarriersRequest.OrderBy.Field => {
	return value ? map[value] : ListCarriersRequest.OrderBy.Field.FIELD_UNSPECIFIED;
};
