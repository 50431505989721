import { Box } from '@agentero/styles/jsx';

import { BaseSearch, Filters } from '../DataTableFilters';
import { TextFilter } from './shared/TextFilter';

type DataTableToolbarFiltersProps<Search extends BaseSearch> = {
	filters: Filters<Search>;
	search: Search;
	onSearchChange: (search: Search) => void;
};

export const DataTableToolbarFilters = <Search extends BaseSearch>({
	search,
	onSearchChange
}: DataTableToolbarFiltersProps<Search>) => {
	return (
		<Box>
			<Box maxWidth={'212px'}>
				<TextFilter
					search={search}
					filter={{
						key: 'searchText',
						type: 'text'
					}}
					onSearchChange={onSearchChange}
				/>
			</Box>
		</Box>
	);
};
