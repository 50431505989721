import { ColumnDef, Row, flexRender } from '@tanstack/react-table';

import { Table, TableRootProps } from '../Table';
import { DataTableEmptyBody } from './shared/DataTableBodyEmpty';

type DataTableBodyProps<TData, TValue> = {
	rows: Row<TData>[];
	columns: ColumnDef<TData, TValue>[];
	size?: NonNullable<TableRootProps>['size'];
};

export const DataTableBody = <TData, TValue = never>({
	rows,
	columns,
	size = 'md'
}: DataTableBodyProps<TData, TValue>) => (
	<Table.Body>
		{rows.length ? (
			rows.map(row => {
				return (
					<Table.Row key={row.id}>
						{row.getVisibleCells().map(cell => (
							<Table.Cell key={cell.id} {...cell.getContext().cell.column.columnDef.meta}>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</Table.Cell>
						))}
					</Table.Row>
				);
			})
		) : (
			<DataTableEmptyBody size={size} colSpan={columns.length} />
		)}
	</Table.Body>
);
