import { License } from '@agentero/grpc-clients/grpc/shared/license';
import { State } from '@agentero/models/shared';
import { getDateFromGrpc, parseDate } from '@agentero/utils';

import {
	ComplianceStatesLicensesStatus,
	parseComplianceStatesLicensesStatus
} from './shared/ComplianceStatesLicensesStatus';

export type ComplianceStatesLicenses = {
	state: State;
	status: ComplianceStatesLicensesStatus | undefined;
	isResident: boolean;
	licenseNumber: string;
	expirationDate: string;
	updatedAt: string;
	issueDate?: string;
};

export const parseComplianceStatesLicenses = (
	licenses: License.AsObject[]
): ComplianceStatesLicenses[] =>
	licenses.map(license => ({
		state: license.licenseState as State,
		status: parseComplianceStatesLicensesStatus(license.status),
		isResident: license.residencyStatus === 'RESIDENT',
		licenseNumber: license.licenseNumber,
		expirationDate: license.expirationDate
			? parseDate({ date: getDateFromGrpc(license.expirationDate), language: 'en-US' })
			: '',
		updatedAt: parseDate({ date: getDateFromGrpc(license.updatedAt), language: 'en-US' }),
		issueDate: license.issueDate
			? parseDate({ date: getDateFromGrpc(license.issueDate), language: 'en-US' })
			: undefined
	}));
