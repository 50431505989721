//@ts-ignore
import ReactMarkdown from 'react-markdown';

import { styled } from '@agentero/styles/jsx';

type MarkdownViewProps = {
	value: string;
};

const Container = styled('div', {
	base: {
		'& .markdown-body': {
			'--base-text-weight-semibold': 600,
			display: 'flex',
			flexDirection: 'column',
			gap: '16',
			'& strong': {
				fontWeight: 'var(--base-text-weight-semibold, 600)'
			},
			'& h2': {
				textStyle: 'body'
			},
			'& h3': {
				textStyle: 'body'
			},
			'& ul': {
				paddingLeft: '2rem !important',
				display: 'flex',
				flexDirection: 'column',
				gap: '0.5rem'
			},
			'& li': {
				'--size': '1.25em',
				position: 'relative',
				listStyleType: 'disc',
				minWidth: 'var(--size)',
				textStyle: 'body',
				'& ul': {
					paddingLeft: '0.5rem !important'
				},
				'& li': {
					listStyleType: '"-"',
					paddingLeft: '0.5rem'
				}
			},
			'& p': {
				textStyle: 'body'
			}
		}
	}
});

export const MarkdownView = ({ value }: MarkdownViewProps) => {
	return (
		<Container>
			<div className="markdown-body">
				<ReactMarkdown linkTarget="_blank">{value}</ReactMarkdown>
			</div>
		</Container>
	);
};
