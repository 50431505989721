import { HStack, Stack, styled } from '@agentero/styles/jsx';
import { StyledVariantProps } from '@agentero/styles/types';

import { Text } from './Text';

const ProgressBarCompleted = styled('div', {
	base: {
		position: 'absolute',
		left: '-100%',
		height: '100%',
		width: '100%',
		marginLeft: -4,
		backgroundColor: 'neutral.10',
		borderEndWidth: 4,
		borderStyle: 'solid',
		borderColor: 'white',
		boxSizing: 'content-box',
		transition: 'translate 0.6s cubic-bezier(0.45, 0, 0.55, 1)'
	},
	variants: {
		color: {
			green: {
				backgroundColor: 'brand.60'
			},
			blackGreen: {
				backgroundColor: 'brand.40'
			},
			purple: {
				backgroundColor: 'purple.60'
			},
			orange: {
				backgroundColor: 'orange.60'
			},
			pink: {
				backgroundColor: 'pink.60'
			},
			blue: {
				backgroundColor: 'blue.60'
			}
		}
	}
});

export type ProgressBarVariant = StyledVariantProps<typeof ProgressBarCompleted>;

type ProgressProps = {
	percentage: number;
	label?: string;
} & ProgressBarVariant;

const ProgressContainer = styled('div', {
	base: {
		position: 'relative',
		height: 8,
		backgroundColor: 'neutral.80',
		borderRadius: 'sm',
		overflow: 'hidden'
	}
});

export const Progress = ({ percentage, color, label }: ProgressProps) => {
	return (
		<Stack gap="8">
			{label && (
				<HStack justifyContent="space-between">
					<Text size="body.small">{label}</Text>
					<Text size="body">{percentage}%</Text>
				</HStack>
			)}
			<ProgressContainer
				aria-valuemax={100}
				aria-valuemin={0}
				aria-valuenow={percentage}
				aria-valuetext={percentage.toString()}>
				<ProgressBarCompleted color={color} style={{ translate: `${percentage}%` }} />
			</ProgressContainer>
		</Stack>
	);
};
