interface IGetPages {
	totalPages: number;
	actualPage: number;
}

export enum PAGE_TYPE {
	NORMAL,
	DOTS
}

interface IPage {
	page: number;
	type: PAGE_TYPE;
}

export const getPages = ({ totalPages, actualPage }: IGetPages): IPage[] => {
	if (totalPages < 7) {
		const necessaryPages = totalPages - 2;

		return necessaryPages <= 0
			? []
			: Array.from({ length: totalPages - 2 }).map((_, index) => ({
					page: index + 2,
					type: PAGE_TYPE.NORMAL
			  }));
	} else if (actualPage < 6) {
		const pages = Array.from({ length: 4 }).map((_, index) => ({
			page: index + 2,
			type: PAGE_TYPE.NORMAL
		}));
		const dots = {
			page: 7,
			type: PAGE_TYPE.DOTS
		};
		return [...pages, dots];
	} else if (totalPages - actualPage < 5) {
		const pages = Array.from({ length: 4 })
			.map((_, index) => ({
				page: totalPages - index - 1,
				type: PAGE_TYPE.NORMAL
			}))
			.reverse();

		const dots = {
			page: totalPages - 6,
			type: PAGE_TYPE.DOTS
		};
		return [dots, ...pages];
	} else {
		const prevDots = {
			page: actualPage - 3,
			type: PAGE_TYPE.DOTS
		};

		const pages = Array.from({ length: 3 })
			.map((_, index) => ({
				page: actualPage - index + 1,
				type: PAGE_TYPE.NORMAL
			}))
			.reverse();

		const nextDots = {
			page: actualPage + 3,
			type: PAGE_TYPE.DOTS
		};

		return [prevDots, ...pages, nextDots];
	}
};
