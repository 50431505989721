import { styled } from '@agentero/styles/jsx';

import { inputRecipe } from '../fieldText/Input';

export const Select = styled(styled('select', inputRecipe), {
	base: {
		backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.825 7.15833L10 10.975L6.175 7.15833L5 8.33333L10 13.3333L15 8.33333L13.825 7.15833Z' fill='%23232937'/%3E%3C/svg%3E%0A")`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '98.5% 50%',
		textOverflow: 'ellipsis',
		_disabled: {
			backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.825 7.15833L10 10.975L6.175 7.15833L5 8.33333L10 13.3333L15 8.33333L13.825 7.15833Z' fill='rgb(112 120 143)'/%3E%3C/svg%3E%0A")`
		}
	},
	variants: {
		size: {
			md: {
				paddingRight: '29px',
				_focus: {
					paddingRight: '28px'
				}
			},
			lg: {
				paddingRight: '37px',
				_focus: {
					paddingRight: '36px'
				}
			}
		}
	}
});
