// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file agentero/portal/compliance.proto (package agentero.portal, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message agentero.portal.RequestRefreshLicenseInfoRequest
 */
export class RequestRefreshLicenseInfoRequest extends Message<RequestRefreshLicenseInfoRequest> {
  /**
   * @generated from oneof agentero.portal.RequestRefreshLicenseInfoRequest.option
   */
  option: {
    /**
     * @generated from field: string agency_id = 1;
     */
    value: string;
    case: "agencyId";
  } | {
    /**
     * @generated from field: string agent_id = 2;
     */
    value: string;
    case: "agentId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<RequestRefreshLicenseInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.RequestRefreshLicenseInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "option" },
    { no: 2, name: "agent_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "option" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequestRefreshLicenseInfoRequest {
    return new RequestRefreshLicenseInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequestRefreshLicenseInfoRequest {
    return new RequestRefreshLicenseInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequestRefreshLicenseInfoRequest {
    return new RequestRefreshLicenseInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RequestRefreshLicenseInfoRequest | PlainMessage<RequestRefreshLicenseInfoRequest> | undefined, b: RequestRefreshLicenseInfoRequest | PlainMessage<RequestRefreshLicenseInfoRequest> | undefined): boolean {
    return proto3.util.equals(RequestRefreshLicenseInfoRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.RunNIPRForAgencyRequest
 */
export class RunNIPRForAgencyRequest extends Message<RunNIPRForAgencyRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<RunNIPRForAgencyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.RunNIPRForAgencyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunNIPRForAgencyRequest {
    return new RunNIPRForAgencyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunNIPRForAgencyRequest {
    return new RunNIPRForAgencyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunNIPRForAgencyRequest {
    return new RunNIPRForAgencyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RunNIPRForAgencyRequest | PlainMessage<RunNIPRForAgencyRequest> | undefined, b: RunNIPRForAgencyRequest | PlainMessage<RunNIPRForAgencyRequest> | undefined): boolean {
    return proto3.util.equals(RunNIPRForAgencyRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.RunNIPRForAgentRequest
 */
export class RunNIPRForAgentRequest extends Message<RunNIPRForAgentRequest> {
  /**
   * @generated from field: string agent_id = 1;
   */
  agentId = "";

  constructor(data?: PartialMessage<RunNIPRForAgentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.RunNIPRForAgentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunNIPRForAgentRequest {
    return new RunNIPRForAgentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunNIPRForAgentRequest {
    return new RunNIPRForAgentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunNIPRForAgentRequest {
    return new RunNIPRForAgentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RunNIPRForAgentRequest | PlainMessage<RunNIPRForAgentRequest> | undefined, b: RunNIPRForAgentRequest | PlainMessage<RunNIPRForAgentRequest> | undefined): boolean {
    return proto3.util.equals(RunNIPRForAgentRequest, a, b);
  }
}

