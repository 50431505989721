import styled from '@emotion/styled';

export const MenuItemIcon = styled.div`
	width: 16px;
	height: 16px;
	margin-right: 8px;
	min-width: 16px;

	svg {
		width: 100%;
		height: auto;
		vertical-align: top;

		path {
			fill: ${props => props.theme.colors.gray.lighten40};
		}
	}
`;
