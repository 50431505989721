import { useEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';

import { FileUpload } from '@agentero/models/shared';
import { Button } from '@agentero/ui';

import { Text } from '../../Typography';
import { FormError } from '../FormError';
import { validateFiles } from './shared/validateFiles';

type PreviewFileProps = {
	file?: File;
	fileName: string;
	url?: string;
	accept?: string;
	availableFileTypesError: string;
	onValueChange: (value: FileUpload) => void;
	hasError: boolean;
	previewMode?: boolean;
};

const FilePreviewContainer = styled.div<{ hasError?: boolean }>`
	border: solid 1px
		${({ theme, hasError }) =>
			hasError ? theme.colors.status.error.base : theme.colors.gray.lighten80};
	border-radius: 2px;
	overflow: hidden;
`;

const FilePreviewHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 16px;
	border-bottom: solid 1px ${({ theme }) => theme.colors.gray.lighten80};
	gap: 16px;
`;

const FilePreviewContent = styled.div`
	position: relative;
`;

const InvalidFileTypeError = styled.div`
	position: absolute;
	width: 100%;
	bottom: 0;
	padding-bottom: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ theme }) => theme.colors.status.error.lighten80};
`;

const InputFile = styled.input`
	opacity: 0;
	position: absolute;
`;

const FilePreviewIframe = styled.iframe`
	width: 100%;
	vertical-align: top;
	min-height: var(--min-height);
`;

export const PreviewFile = ({
	file,
	accept,
	availableFileTypesError,
	url,
	fileName,
	onValueChange,
	hasError,
	previewMode
}: PreviewFileProps) => {
	const [preview, setPreview] = useState<string | undefined>(url);
	const inputFileRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (file) {
			const reader = new FileReader();

			const onLoad = () => setPreview(reader.result as string);

			reader.addEventListener('load', onLoad, { once: true });

			reader.readAsDataURL(file);
		}
	}, [file]);

	const onReplaceClick = () => inputFileRef.current && inputFileRef.current.click();

	const [isInvalidFile, setIsInvalidFile] = useState(false);
	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files as FileList;

		if (accept) {
			const isSomeInvalidType = validateFiles(files, accept);
			if (isSomeInvalidType) {
				setIsInvalidFile(true);
				setTimeout(() => setIsInvalidFile(false), 5000);

				if (inputFileRef.current) {
					inputFileRef.current.value = '';
				}
			} else {
				onValueChange({
					file: files[0],
					isChanged: true,
					name: files[0].name
				});
			}
		}
	};

	return (
		<FilePreviewContainer hasError={hasError}>
			<FilePreviewHeader>
				<Text size="S" colorize={colors => colors.gray.lighten40} truncate css={{ maxWidth: 500 }}>
					{fileName}
				</Text>
				{!previewMode && (
					<Button variant="link" type="button" onClick={onReplaceClick}>
						Replace file
					</Button>
				)}
			</FilePreviewHeader>
			<FilePreviewContent>
				<InputFile
					ref={inputFileRef}
					type="file"
					readOnly
					accept={accept}
					onChange={onInputChange}
					disabled={previewMode}
				/>
				{preview && <FilePreviewIframe key={preview} src={preview} />}
				{isInvalidFile && (
					<InvalidFileTypeError>
						<FormError error={availableFileTypesError} />
					</InvalidFileTypeError>
				)}
			</FilePreviewContent>
		</FilePreviewContainer>
	);
};
