import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { fetchChargeBeePaidPaymentFailedUrl } from 'packages/services/fetch/tier-fe/charge-bee-payment-failed/fetchChargeBeePaidPaymentFailedUrl';

// collect_now is not supported as a Hosted Page in Chargebee 07-03-2024
export const useChargeBeePaymentFailed = () => {
	const {
		data: { token, id }
	} = useAgentResource();

	const onPaidPaymentFailed = async () => {
		const url = await fetchChargeBeePaidPaymentFailedUrl({
			token,
			userId: id,
			redirectUrl: window.location.href
		});
		window.open(url, '_self');
	};

	return { onPaidPaymentFailed };
};
