import React from 'react';

import styled from '@emotion/styled';

import { Card, NarrowWrapper, Spinner } from '@agentero/components';

const LoadingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 600px;
`;

export const FormSkeleton = () => {
	return (
		<NarrowWrapper>
			<Card hasBorder>
				<LoadingContainer>
					<Spinner size={32} />
				</LoadingContainer>
			</Card>
		</NarrowWrapper>
	);
};
