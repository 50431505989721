import styled from '@emotion/styled';

import { ITheme } from '@agentero/styles/emotion';

import { Input } from './Input';
import { FieldStatus } from './fieldStatus';

export const defaultVariant = (theme: ITheme) => `
	border: solid 1px ${theme.colors.gray.lighten80};
`;
export const successVariant = (theme: ITheme) => `
	border: solid 1px ${theme.colors.primary.base};
`;
export const errorVariant = (theme: ITheme) => `
	border: solid 1px ${theme.colors.status.error.base};
`;
export const warningVariant = (theme: ITheme) => `
	border: solid 1px ${theme.colors.status.warning.base};
`;
export const infoVariant = (theme: ITheme) => `
	border: solid 1px ${theme.colors.status.info.base};
	background-color: ${theme.colors.status.info.lighten80};
`;

export const statusVariants: { [key in FieldStatus]: (theme: ITheme) => string } = {
	[FieldStatus.None]: defaultVariant,
	[FieldStatus.Success]: successVariant,
	[FieldStatus.Error]: errorVariant,
	[FieldStatus.Warning]: warningVariant,
	[FieldStatus.Info]: infoVariant
};
type SelectProps = {
	status?: FieldStatus;
	isBig?: boolean;
};
type GetStatusVariant = SelectProps & { theme: ITheme };

const getStatusVariant = ({ status = FieldStatus.None, theme }: GetStatusVariant) => {
	const variant = statusVariants[status];
	return variant(theme);
};

export const Select = styled(Input.withComponent('select'))`
	padding-right: ${({ isBig }) => (isBig ? '37px' : '29px')};
	background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.825 7.15833L10 10.975L6.175 7.15833L5 8.33333L10 13.3333L15 8.33333L13.825 7.15833Z' fill='%23232937'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-position: 98.5% 50%;
	text-overflow: ellipsis;
	${getStatusVariant}

	&:focus {
		padding-right: ${({ isBig }) => (isBig ? '36px' : '28px')};
	}

	&:disabled {
		color: ${({ theme }) => theme.colors.gray.lighten40};
		background-color: ${({ theme }) => theme.colors.gray.lighten90};
		background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.825 7.15833L10 10.975L6.175 7.15833L5 8.33333L10 13.3333L15 8.33333L13.825 7.15833Z' fill='lightgrey'/%3E%3C/svg%3E%0A");
		border: solid 1px ${({ theme }) => theme.colors.gray.lighten80};
		opacity: 1;
	}
`;
