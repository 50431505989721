import styled from '@emotion/styled';

import { NonNumericStep } from './steps/NonNumericStep';
import { Step } from './steps/Step';

export type StepsItem = {
	id: string;
	label: string;
	nonNumeric?: boolean;
};

type StepsProps = {
	items: StepsItem[];
	current: string;
};

const StepsContainer = styled.ol`
	display: flex;
`;

export const Steps = ({ items, current }: StepsProps) => {
	const activeIndex = items.findIndex(value => value.id === current);

	return (
		<StepsContainer>
			{items.map(({ id, label, nonNumeric }, index) => {
				const position = index + 1;
				const isActive = index === activeIndex;
				const isChecked = activeIndex > index;

				return nonNumeric ? (
					<NonNumericStep key={id} />
				) : (
					<Step
						key={id}
						position={position}
						isActive={isActive}
						isChecked={isChecked}
						label={label}
					/>
				);
			})}
		</StepsContainer>
	);
};
