import { PropsWithChildren } from 'react';

import { EmotionCache } from '@emotion/react';
import { DehydratedState } from '@tanstack/react-query';

import { Toast } from '@agentero/ui';

import { EmotionProvider } from './providers/EmotionProvider';
import { I18nextAgProvider } from './providers/I18nextAgProvider';
import { ReactQueryProvider } from './providers/ReactQueryProvider';

type ProvidersProps = {
	emotionCache: EmotionCache;
	dehydratedState?: DehydratedState;
};

export const Providers = ({
	emotionCache,
	dehydratedState,
	children
}: PropsWithChildren<ProvidersProps>) => {
	return (
		<EmotionProvider emotionCache={emotionCache}>
			<I18nextAgProvider>
				<ReactQueryProvider dehydratedState={dehydratedState}>{children}</ReactQueryProvider>
			</I18nextAgProvider>
			<Toast />
		</EmotionProvider>
	);
};
