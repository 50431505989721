import React from 'react';

import styled from '@emotion/styled';

import { ITheme } from '@agentero/styles/emotion';

import { Label } from '../form/Label';
import { FieldStatus } from './fieldStatus';

type RadioProps = Omit<
	React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
	'id'
> & {
	status?: FieldStatus;
	id: string;
};

const defaultVariant = (theme: ITheme) => `
	+ label {
		&:before {
			border-color: ${theme.colors.gray.lighten40};
		}
	}

	&:hover {
		+ label {
			&:before {
				box-shadow: 0 0 0 4px ${theme.colors.gray.lighten95};
				background-color: ${theme.colors.gray.lighten95};
			}
		}
	}

	&:focus,
	&:active {
		+ label {
			&:before {
				box-shadow: 0 0 0 4px ${theme.colors.gray.lighten90};
				background-color: ${theme.colors.gray.lighten90};
			}
		}
	}

	&:checked {
		+ label {
			&:before {
				border-color: ${theme.colors.primary.base};
			}
		}

		&:hover {
			+ label {
				&:before {
					box-shadow: 0 0 0 4px ${theme.colors.primary.lighten80};
					background-color: ${theme.colors.white};
				}
			}
		}

		&:focus,
		&:active {
			+ label {
				&:before {
					box-shadow: 0 0 0 4px ${theme.colors.primary.lighten80};
					background-color: ${theme.colors.white};
				}
			}
		}
	}

	&:disabled {
		+ label {
			&:before {
				border-color: ${theme.colors.gray.lighten80};
			}
		}

		&:checked {
			+ label {
				&:before {
					border-color: ${theme.colors.primary.lighten50};
				}
			}
		}
	}
`;
const successVariant = (theme: ITheme) => `
	+ label {
		&:before {
			border-color: ${theme.colors.primary.base};
		}
	}

	&:hover {
		+ label {
			&:before {
				box-shadow: 0 0 0 4px ${theme.colors.primary.lighten80};
				background-color: ${theme.colors.primary.lighten80};
			}
		}
	}

	&:focus,
	&:active {
		+ label {
			&:before {
				box-shadow: 0 0 0 4px ${theme.colors.primary.lighten80};
				background-color: ${theme.colors.primary.lighten80};
			}
		}
	}

	&:checked {
		+ label {
			&:before {
				border-color: ${theme.colors.primary.base};
			}
		}

		&:hover {
			+ label {
				&:before {
					box-shadow: 0 0 0 4px ${theme.colors.primary.lighten80};
					background-color: ${theme.colors.white};
				}
			}
		}

		&:focus,
		&:active {
			+ label {
				&:before {
					box-shadow: 0 0 0 4px ${theme.colors.primary.lighten80};
					background-color: ${theme.colors.white};
				}
			}
		}
	}

	&:disabled {
		+ label {
			&:before {
				border-color: ${theme.colors.primary.lighten50};
			}
		}

		&:checked {
			+ label {
				&:before {
					border-color: ${theme.colors.primary.lighten50};
				}
			}
		}
	}
`;
const errorVariant = (theme: ITheme) => `
	+ label {
		&:before {
			border-color: ${theme.colors.status.error.base};
		}
	}

	&:hover {
		+ label {
			&:before {
				box-shadow: 0 0 0 4px ${theme.colors.status.error.lighten80};
				background-color: ${theme.colors.status.error.lighten80};
			}
		}
	}

	&:focus,
	&:active {
		+ label {
			&:before {
				box-shadow: 0 0 0 4px ${theme.colors.status.error.lighten40};
				background-color: ${theme.colors.status.error.lighten40};
			}
		}
	}

	&:checked {
		+ label {
			&:before {
				border-color: ${theme.colors.status.error.base};
			}
		}

		&:hover {
			+ label {
				&:before {
					box-shadow: 0 0 0 4px ${theme.colors.status.error.lighten80};
					background-color: ${theme.colors.white};
				}
			}
		}

		&:focus,
		&:active {
			+ label {
				&:before {
					box-shadow: 0 0 0 4px ${theme.colors.status.error.lighten40};
					background-color: ${theme.colors.white};
				}
			}
		}
	}

	&:disabled {
		+ label {
			&:before {
				border-color: ${theme.colors.status.error.lighten40};
			}
		}

		&:checked {
			+ label {
				&:before {
					border-color: ${theme.colors.status.error.lighten40};
				}
			}
		}
	}
`;
const warningVariant = (theme: ITheme) => `
	+ label {
		&:before {
			border-color: ${theme.colors.status.warning.base};
		}
	}

	&:hover {
		+ label {
			&:before {
				box-shadow: 0 0 0 4px ${theme.colors.status.warning.lighten80};
				background-color: ${theme.colors.status.warning.lighten80};
			}
		}
	}

	&:focus,
	&:active {
		+ label {
			&:before {
				box-shadow: 0 0 0 4px ${theme.colors.status.warning.lighten40};
				background-color: ${theme.colors.status.warning.lighten40};
			}
		}
	}

	&:checked {
		+ label {
			&:before {
				border-color: ${theme.colors.status.warning.base};
			}
		}

		&:hover {
			+ label {
				&:before {
					box-shadow: 0 0 0 4px ${theme.colors.status.warning.lighten80};
					background-color: ${theme.colors.white};
				}
			}
		}

		&:focus,
		&:active {
			+ label {
				&:before {
					box-shadow: 0 0 0 4px ${theme.colors.status.warning.lighten40};
					background-color: ${theme.colors.white};
				}
			}
		}
	}

	&:disabled {
		+ label {
			&:before {
				border-color: ${theme.colors.status.warning.lighten40};
			}
		}

		&:checked {
			+ label {
				&:before {
					border-color: ${theme.colors.status.warning.lighten40};
				}
			}
		}
	}
`;
const infoVariant = (theme: ITheme) => `
	+ label {
		&:before {
			border-color: ${theme.colors.status.info.base};
		}
	}

	&:hover {
		+ label {
			&:before {
				box-shadow: 0 0 0 4px ${theme.colors.status.info.lighten80};
				background-color: ${theme.colors.status.info.lighten80};
			}
		}
	}

	&:focus,
	&:active {
		+ label {
			&:before {
				box-shadow: 0 0 0 4px ${theme.colors.status.info.lighten40};
				background-color: ${theme.colors.status.info.lighten40};
			}
		}
	}

	&:checked {
		+ label {
			&:before {
				border-color: ${theme.colors.status.info.base};
			}
		}

		&:hover {
			+ label {
				&:before {
					box-shadow: 0 0 0 4px ${theme.colors.status.info.lighten80};
					background-color: ${theme.colors.white};
				}
			}
		}

		&:focus,
		&:active {
			+ label {
				&:before {
					box-shadow: 0 0 0 4px ${theme.colors.status.info.lighten40};
					background-color: ${theme.colors.white};
				}
			}
		}
	}

	&:disabled {
		+ label {
			&:before {
				border-color: ${theme.colors.status.info.lighten40};
			}
		}

		&:checked {
			+ label {
				&:before {
					border-color: ${theme.colors.status.info.lighten40};
				}
			}
		}
	}
`;

const statusVariants: { [key in FieldStatus]: (theme: ITheme) => string } = {
	[FieldStatus.None]: defaultVariant,
	[FieldStatus.Success]: successVariant,
	[FieldStatus.Error]: errorVariant,
	[FieldStatus.Warning]: warningVariant,
	[FieldStatus.Info]: infoVariant
};

type RadioButtonProps = {
	status?: FieldStatus;
};

type GetStatusVariant = RadioButtonProps & { theme: ITheme };

const getStatusVariant = ({ status = FieldStatus.None, theme }: GetStatusVariant) => {
	const variant = statusVariants[status];
	return variant(theme);
};

// @ts-ignore
export const RadioButton = styled.input<RadioButtonProps>`
	position: absolute;
	left: -9999px;

	+ label {
		position: relative;
		display: flex;
		height: auto;
		min-height: 32px;
		padding: 4px 0 4px 24px;
		margin-bottom: 0;
		${({ theme }) => theme.fontWeight.regular};
		color: ${({ theme }) => theme.colors.gray.base};
		cursor: pointer;

		&:empty {
			padding-left: 16px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			flex: 0;
			display: inline-block;
			min-width: 16px;
			height: 16px;
			margin: 8px 0;
			background: ${({ theme }) => theme.colors.white};
			border-width: 1px;
			border-style: solid;
			border-radius: 50%;
			pointer-events: none;
			box-sizing: border-box;
			transition: 0.15s;
		}

		&:hover {
			color: ${({ theme }) => theme.colors.gray.base};
		}
	}

	&:checked {
		+ label {
			color: ${({ theme }) => theme.colors.gray.base};

			&:before {
				border-width: 4px;
				background-color: ${({ theme }) => theme.colors.white};
			}
		}
	}

	&:disabled {
		cursor: default;

		+ label {
			cursor: default;
			height: 32px;
			pointer-events: none;
			color: ${({ theme }) => theme.colors.gray.lighten40};
		}
	}

	${getStatusVariant}
`;

const RadioContainer = styled.div`
	position: relative;
`;

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
	({ id, children, ...inputProps }, ref) => (
		<RadioContainer>
			<RadioButton type="radio" ref={ref} id={id} {...inputProps} />
			<Label htmlFor={id}>{children}</Label>
		</RadioContainer>
	)
);
