export const copyToClipboard = (value: string) => {
	if (!navigator.clipboard) {
		const el = document.createElement('textarea');
		el.value = value;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
	} else {
		navigator.clipboard.writeText(value);
	}
};

export const capitalizeFirstLetter = (value: string) =>
	value.charAt(0).toUpperCase() + value.slice(1).toLocaleLowerCase();

export const snakeToCamelCase = (value: string) => {
	return value.replace(/_([a-z])/g, value => value[1].toUpperCase());
};

export const pascalToCamelCase = (value: string) => {
	return `${value[0].toLocaleLowerCase()}${value.slice(1)}`;
};

export const removeExtraSpaces = (value: string) => value.replace(/\s{2,}/g, ' ').trim();

export const formatList = (list: string[] = []) => {
	if (list.length === 0) {
		return '';
	}
	if (list.length === 1) {
		return list.toString();
	}

	return `${list.slice(0, -1).join(', ')} and ${list.slice(-1)}`;
};

declare global {
	interface String {
		toCapitalize(): string;
	}
}

String.prototype.toCapitalize = function () {
	return this.toLowerCase().replace(/^.|\s\S/g, function (a) {
		return a.toUpperCase();
	});
};
