import { AddLog } from '@agentero/service-errors';

export const postNewLog: AddLog = (log, logLevel) =>
	new Promise<void>((resolve, reject) => {
		fetch(`/api/add_log`, {
			method: 'POST',
			headers: {
				accept: 'application/json, text/plain, */*',
				'content-type': 'application/json;charset=UTF-8'
			},
			body: JSON.stringify({
				log,
				logLevel
			})
		})
			.then(() => resolve())
			.catch(reject);
	});
