import { styled } from '@agentero/styles/jsx';

export const Label = styled('label', {
	base: {
		display: 'flex',
		color: 'neutral.10',
		textStyle: 'body.small',
		_disabled: {
			color: 'neutral.60'
		}
	},
	variants: {
		optional: {
			true: {
				_after: {
					content: '" (optional)"',
					display: 'contents',
					color: 'neutral.40'
				}
			}
		}
	}
});
