import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Modal, ModalBody, ModalContent } from '@agentero/components';
import { IconWarning } from '@agentero/icons';
import { css } from '@agentero/styles/css';
import { Box, Stack, VStack } from '@agentero/styles/jsx';
import { Button, Text } from '@agentero/ui';

import { useChargeBeePaymentFailed } from 'packages/payments/useChargeBeePaymentFailed';
import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';

export const TierModalPaymentFailed = () => {
	const { t } = useTranslation('tier');

	const {
		data: { subscription }
	} = useAgentResource();

	const [isOpen, setIsOpen] = useState<boolean>(subscription?.isPaymentFailed);

	const { onPaidPaymentFailed } = useChargeBeePaymentFailed();

	return subscription && subscription.isPaymentFailed ? (
		<Modal isOpen={isOpen} onDismiss={() => setIsOpen(false)} size="md">
			<ModalContent>
				<ModalBody>
					<Stack gap="32" marginBlockStart="56" marginBlockEnd="32">
						<VStack gap="16">
							<IconWarning
								className={css({
									width: '4rem',
									height: '4rem'
								})}
							/>
							<Text size="title.screen" align="center">
								{t('payment-failed.modal.title')}
							</Text>
							<Text align="center">{t('payment-failed.modal.body')}</Text>
						</VStack>
						<Box marginBlockStart="auto">
							<Stack gap="8" flexDir="column">
								<Button as="a" variant="primary" onClick={onPaidPaymentFailed}>
									{t('payment-failed.cta')}
								</Button>
							</Stack>
						</Box>
					</Stack>
				</ModalBody>
			</ModalContent>
		</Modal>
	) : null;
};
