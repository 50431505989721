import { VStack } from '@agentero/styles/jsx';

import { Table, TableRootProps } from '../../Table';
import { Text } from '../../Text';

type DataTableEmptyBodyProps = {
	colSpan: number;
	size: Exclude<NonNullable<TableRootProps>['size'], undefined>;
};

export const DataTableEmptyBody = ({ colSpan, size }: DataTableEmptyBodyProps) => (
	<Table.Row>
		<Table.Cell colSpan={colSpan}>
			{['xs', 'sm'].includes(size) ? (
				<Text size="body.small">
					<b>No results.</b>
				</Text>
			) : (
				<VStack gap="4" marginBlock="24">
					<Text size="title.body">
						<b>No results.</b>
					</Text>
					<Text size="body.small">
						We searched far and wide and couldn't find any results for your search.
					</Text>
				</VStack>
			)}
		</Table.Cell>
	</Table.Row>
);
