import { AgentCommunicationPreferences } from '@agentero/grpc-clients/grpc/agent-fe';

export enum AgenteroNotifications {
	Email = 'email',
	Sms = 'sms'
}

export const notifications: AgenteroNotifications[] = Object.values(AgenteroNotifications);

export type AgentNotifications = {
	email: boolean;
	sms: boolean;
};

export const parseAgentNotifications = (
	notifications: AgentCommunicationPreferences.AsObject
): AgentNotifications => ({
	...notifications
});
