import { PlainMessage } from '@bufbuild/protobuf';

import { GetSessionResponse } from '@agentero/service-clients/portal/session';

import { InsuranceType, parseInsuranceTypeFromProto } from '../opportunity';

export type AgentAgency = {
	id: string;
	name: string;
	formStackName?: string;
	quotableLobsList?: InsuranceType[];
};

export const parseAgentAgency = (agentSession: PlainMessage<GetSessionResponse>): AgentAgency => ({
	id: agentSession.agencyId ?? '',
	name: agentSession.agencyName ?? '',
	formStackName: agentSession.agencyFormStackName,
	quotableLobsList: agentSession.quotableLobs.map(parseInsuranceTypeFromProto)
});
