import { styled } from '@agentero/styles/jsx';
import { StyledVariantProps } from '@agentero/styles/types';

export const Tag = styled('span', {
	base: {
		display: 'inline-flex',
		paddingInline: '8',
		paddingBlock: '2',
		borderRadius: 'sm',
		textStyle: 'caption.base',
		color: 'neutral.20',
		border: '0.0625rem solid token(colors.slate.050)',
		backgroundColor: 'slate.050',
		width: 'fit-content',
		minWidth: 'fit-content'
	},
	variants: {
		color: {
			green: {
				color: 'brand.20',
				borderColor: 'brand.90',
				backgroundColor: 'brand.90'
			},
			blue: {
				color: 'blue.20',
				borderColor: 'blue.90',
				backgroundColor: 'blue.90'
			},
			yellow: {
				color: 'yellow.20',
				borderColor: 'yellow.90',
				backgroundColor: 'yellow.90'
			},
			red: {
				color: 'red.20',
				borderColor: 'red.90',
				backgroundColor: 'red.90'
			},
			purple: {
				color: 'purple.20',
				borderColor: 'purple.90',
				backgroundColor: 'purple.90'
			},
			pink: {
				color: 'pink.20',
				borderColor: 'pink.90',
				backgroundColor: 'pink.90'
			},
			orange: {
				color: 'orange.20',
				borderColor: 'orange.90',
				backgroundColor: 'orange.90'
			},
			gray: {
				color: 'neutral',
				borderColor: 'neutral.90',
				backgroundColor: 'neutral.90'
			}
		},
		ghost: {
			true: {
				color: 'neutral.30',
				borderColor: 'neutral.60',
				backgroundColor: 'transparent'
			}
		}
	},
	compoundVariants: [
		{
			color: 'green',
			ghost: true,
			css: {
				color: 'brand.30',
				borderColor: 'brand'
			}
		},
		{
			color: 'blue',
			ghost: true,
			css: {
				color: 'blue.30',
				borderColor: 'blue.60'
			}
		},
		{
			color: 'yellow',
			ghost: true,
			css: {
				color: 'yellow.30',
				borderColor: 'yellow'
			}
		},
		{
			color: 'red',
			ghost: true,
			css: {
				color: 'red.30',
				borderColor: 'red.60'
			}
		},
		{
			color: 'purple',
			ghost: true,
			css: {
				color: 'purple.30',
				borderColor: 'purple.60'
			}
		},
		{
			color: 'pink',
			ghost: true,
			css: {
				color: 'pink.30',
				borderColor: 'pink.60'
			}
		},
		{
			color: 'orange',
			ghost: true,
			css: {
				color: 'orange.30',
				borderColor: 'orange.60'
			}
		}
	]
});

export type TagProps = StyledVariantProps<typeof Tag>;

export type TagColor = StyledVariantProps<typeof Tag>['color'];
