// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file agentero/portal/agency.proto (package agentero.portal, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { License, LicenseGlobalStatus, LineOfAuthority } from "../license/license_pb";
import { Address } from "../contact/contact_pb";
import { CurrentTier, Tier } from "../tier/tier_pb";
import { AppointmentStatus, ApprovalStatus, Carrier, ComparativeRaterType } from "../carrier/carrier_pb";
import { InsuranceType } from "../policy/policy_pb";

/**
 * @generated from enum agentero.portal.Direction
 */
export enum Direction {
  /**
   * @generated from enum value: ASC = 0;
   */
  ASC = 0,

  /**
   * @generated from enum value: DESC = 1;
   */
  DESC = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(Direction)
proto3.util.setEnumType(Direction, "agentero.portal.Direction", [
  { no: 0, name: "ASC" },
  { no: 1, name: "DESC" },
]);

/**
 * @generated from enum agentero.portal.EAndOStatus
 */
export enum EAndOStatus {
  /**
   * @generated from enum value: E_AND_O_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: E_AND_O_STATUS_INVALID = 1;
   */
  INVALID = 1,

  /**
   * @generated from enum value: E_AND_O_STATUS_VALID = 2;
   */
  VALID = 2,

  /**
   * @generated from enum value: E_AND_O_STATUS_IN_REVIEW = 3;
   */
  IN_REVIEW = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(EAndOStatus)
proto3.util.setEnumType(EAndOStatus, "agentero.portal.EAndOStatus", [
  { no: 0, name: "E_AND_O_STATUS_UNSPECIFIED" },
  { no: 1, name: "E_AND_O_STATUS_INVALID" },
  { no: 2, name: "E_AND_O_STATUS_VALID" },
  { no: 3, name: "E_AND_O_STATUS_IN_REVIEW" },
]);

/**
 * @generated from enum agentero.portal.TestingStatus
 */
export enum TestingStatus {
  /**
   * @generated from enum value: TESTING_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TESTING_STATUS_TESTING = 1;
   */
  TESTING = 1,

  /**
   * @generated from enum value: TESTING_STATUS_NOT_TESTING = 2;
   */
  NOT_TESTING = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(TestingStatus)
proto3.util.setEnumType(TestingStatus, "agentero.portal.TestingStatus", [
  { no: 0, name: "TESTING_STATUS_UNSPECIFIED" },
  { no: 1, name: "TESTING_STATUS_TESTING" },
  { no: 2, name: "TESTING_STATUS_NOT_TESTING" },
]);

/**
 * @generated from enum agentero.portal.EAndOInvalidReasons
 */
export enum EAndOInvalidReasons {
  /**
   * @generated from enum value: E_AND_O_INVALID_REASON_UNSPECIFIED = 0;
   */
  E_AND_O_INVALID_REASON_UNSPECIFIED = 0,

  /**
   * @generated from enum value: E_AND_O_INVALID_REASON_WRONG_COVERAGE_TYPE = 1;
   */
  E_AND_O_INVALID_REASON_WRONG_COVERAGE_TYPE = 1,

  /**
   * @generated from enum value: E_AND_O_INVALID_REASON_WRONG_COVERAGE_PROFESSION = 2;
   */
  E_AND_O_INVALID_REASON_WRONG_COVERAGE_PROFESSION = 2,

  /**
   * @generated from enum value: E_AND_O_INVALID_REASON_WRONG_INSURED_NAME = 3;
   */
  E_AND_O_INVALID_REASON_WRONG_INSURED_NAME = 3,

  /**
   * @generated from enum value: E_AND_O_INVALID_REASON_WRONG_DEDUCTIBLE = 4;
   */
  E_AND_O_INVALID_REASON_WRONG_DEDUCTIBLE = 4,

  /**
   * @generated from enum value: E_AND_O_INVALID_REASON_WRONG_DATA = 5;
   */
  E_AND_O_INVALID_REASON_WRONG_DATA = 5,

  /**
   * @generated from enum value: E_AND_O_INVALID_REASON_OTHER = 6;
   */
  E_AND_O_INVALID_REASON_OTHER = 6,

  /**
   * @generated from enum value: E_AND_O_INVALID_REASON_WRONG_LOA = 7;
   */
  E_AND_O_INVALID_REASON_WRONG_LOA = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(EAndOInvalidReasons)
proto3.util.setEnumType(EAndOInvalidReasons, "agentero.portal.EAndOInvalidReasons", [
  { no: 0, name: "E_AND_O_INVALID_REASON_UNSPECIFIED" },
  { no: 1, name: "E_AND_O_INVALID_REASON_WRONG_COVERAGE_TYPE" },
  { no: 2, name: "E_AND_O_INVALID_REASON_WRONG_COVERAGE_PROFESSION" },
  { no: 3, name: "E_AND_O_INVALID_REASON_WRONG_INSURED_NAME" },
  { no: 4, name: "E_AND_O_INVALID_REASON_WRONG_DEDUCTIBLE" },
  { no: 5, name: "E_AND_O_INVALID_REASON_WRONG_DATA" },
  { no: 6, name: "E_AND_O_INVALID_REASON_OTHER" },
  { no: 7, name: "E_AND_O_INVALID_REASON_WRONG_LOA" },
]);

/**
 * @generated from enum agentero.portal.EAndOUploadedPolicyType
 */
export enum EAndOUploadedPolicyType {
  /**
   * @generated from enum value: E_AND_O_UPLOADED_POLICY_UNSPECIFIED = 0;
   */
  E_AND_O_UPLOADED_POLICY_UNSPECIFIED = 0,

  /**
   * @generated from enum value: E_AND_O_UPLOADED_POLICY_PARTIAL = 1;
   */
  E_AND_O_UPLOADED_POLICY_PARTIAL = 1,

  /**
   * @generated from enum value: E_AND_O_UPLOADED_POLICY_FULL = 2;
   */
  E_AND_O_UPLOADED_POLICY_FULL = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(EAndOUploadedPolicyType)
proto3.util.setEnumType(EAndOUploadedPolicyType, "agentero.portal.EAndOUploadedPolicyType", [
  { no: 0, name: "E_AND_O_UPLOADED_POLICY_UNSPECIFIED" },
  { no: 1, name: "E_AND_O_UPLOADED_POLICY_PARTIAL" },
  { no: 2, name: "E_AND_O_UPLOADED_POLICY_FULL" },
]);

/**
 * @generated from enum agentero.portal.QualificationResult
 */
export enum QualificationResult {
  /**
   * @generated from enum value: QUALIFICATION_RESULT_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: QUALIFICATION_RESULT_NOT_QUALIFIED = 1;
   */
  NOT_QUALIFIED = 1,

  /**
   * @generated from enum value: QUALIFICATION_RESULT_QUALIFIED = 2;
   */
  QUALIFIED = 2,

  /**
   * @generated from enum value: QUALIFICATION_RESULT_PENDING_QUALIFIED = 3 [deprecated = true];
   * @deprecated
   */
  PENDING_QUALIFIED = 3,

  /**
   * @generated from enum value: QUALIFICATION_RESULT_PENDING_NOT_QUALIFIED = 4 [deprecated = true];
   * @deprecated
   */
  PENDING_NOT_QUALIFIED = 4,

  /**
   * @generated from enum value: QUALIFICATION_RESULT_PENDING = 5;
   */
  PENDING = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(QualificationResult)
proto3.util.setEnumType(QualificationResult, "agentero.portal.QualificationResult", [
  { no: 0, name: "QUALIFICATION_RESULT_UNSPECIFIED" },
  { no: 1, name: "QUALIFICATION_RESULT_NOT_QUALIFIED" },
  { no: 2, name: "QUALIFICATION_RESULT_QUALIFIED" },
  { no: 3, name: "QUALIFICATION_RESULT_PENDING_QUALIFIED" },
  { no: 4, name: "QUALIFICATION_RESULT_PENDING_NOT_QUALIFIED" },
  { no: 5, name: "QUALIFICATION_RESULT_PENDING" },
]);

/**
 * @generated from enum agentero.portal.UnqualifiedReason
 */
export enum UnqualifiedReason {
  /**
   * @generated from enum value: UNQUALIFIED_REASON_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: UNQUALIFIED_REASON_INVALID_E_AND_O = 1;
   */
  INVALID_E_AND_O = 1,

  /**
   * @generated from enum value: UNQUALIFIED_REASON_SECURITY_QUESTIONS_NOT_PASSED = 2;
   */
  SECURITY_QUESTIONS_NOT_PASSED = 2,

  /**
   * @generated from enum value: UNQUALIFIED_REASON_AGENCY_LICENSE_NOT_FOUND = 3;
   */
  AGENCY_LICENSE_NOT_FOUND = 3,

  /**
   * @generated from enum value: UNQUALIFIED_REASON_AGENCY_LICENSE_NOT_ACTIVE = 4;
   */
  AGENCY_LICENSE_NOT_ACTIVE = 4,

  /**
   * @generated from enum value: UNQUALIFIED_REASON_AGENCY_LICENSE_EXPIRED = 5;
   */
  AGENCY_LICENSE_EXPIRED = 5,

  /**
   * @generated from enum value: UNQUALIFIED_REASON_AGENT_LICENSE_NOT_FOUND = 6;
   */
  AGENT_LICENSE_NOT_FOUND = 6,

  /**
   * @generated from enum value: UNQUALIFIED_REASON_AGENT_LICENSE_NOT_ACTIVE = 7;
   */
  AGENT_LICENSE_NOT_ACTIVE = 7,

  /**
   * @generated from enum value: UNQUALIFIED_REASON_AGENT_LICENSE_EXPIRED = 8;
   */
  AGENT_LICENSE_EXPIRED = 8,

  /**
   * @generated from enum value: UNQUALIFIED_REASON_AGENCY_LICENSE_LOB_NOT_SUPPORTED = 9;
   */
  AGENCY_LICENSE_LOB_NOT_SUPPORTED = 9,

  /**
   * @generated from enum value: UNQUALIFIED_REASON_AGENT_LICENSE_LOB_NOT_SUPPORTED = 10;
   */
  AGENT_LICENSE_LOB_NOT_SUPPORTED = 10,

  /**
   * @generated from enum value: UNQUALIFIED_REASON_AGENT_LICENSE_ISSUED_LESS_THAN_ONE_YEAR = 11;
   */
  AGENT_LICENSE_ISSUED_LESS_THAN_ONE_YEAR = 11,

  /**
   * @generated from enum value: UNQUALIFIED_REASON_AGENT_NONE_RESIDENT_LICENSE = 12;
   */
  AGENT_NONE_RESIDENT_LICENSE = 12,

  /**
   * @generated from enum value: UNQUALIFIED_REASON_AGENCY_NONE_RESIDENT_LICENSE = 13;
   */
  AGENCY_NONE_RESIDENT_LICENSE = 13,
}
// Retrieve enum metadata with: proto3.getEnumType(UnqualifiedReason)
proto3.util.setEnumType(UnqualifiedReason, "agentero.portal.UnqualifiedReason", [
  { no: 0, name: "UNQUALIFIED_REASON_UNSPECIFIED" },
  { no: 1, name: "UNQUALIFIED_REASON_INVALID_E_AND_O" },
  { no: 2, name: "UNQUALIFIED_REASON_SECURITY_QUESTIONS_NOT_PASSED" },
  { no: 3, name: "UNQUALIFIED_REASON_AGENCY_LICENSE_NOT_FOUND" },
  { no: 4, name: "UNQUALIFIED_REASON_AGENCY_LICENSE_NOT_ACTIVE" },
  { no: 5, name: "UNQUALIFIED_REASON_AGENCY_LICENSE_EXPIRED" },
  { no: 6, name: "UNQUALIFIED_REASON_AGENT_LICENSE_NOT_FOUND" },
  { no: 7, name: "UNQUALIFIED_REASON_AGENT_LICENSE_NOT_ACTIVE" },
  { no: 8, name: "UNQUALIFIED_REASON_AGENT_LICENSE_EXPIRED" },
  { no: 9, name: "UNQUALIFIED_REASON_AGENCY_LICENSE_LOB_NOT_SUPPORTED" },
  { no: 10, name: "UNQUALIFIED_REASON_AGENT_LICENSE_LOB_NOT_SUPPORTED" },
  { no: 11, name: "UNQUALIFIED_REASON_AGENT_LICENSE_ISSUED_LESS_THAN_ONE_YEAR" },
  { no: 12, name: "UNQUALIFIED_REASON_AGENT_NONE_RESIDENT_LICENSE" },
  { no: 13, name: "UNQUALIFIED_REASON_AGENCY_NONE_RESIDENT_LICENSE" },
]);

/**
 * @generated from enum agentero.portal.PrimaryColor
 */
export enum PrimaryColor {
  /**
   * @generated from enum value: GREEN = 0;
   */
  GREEN = 0,

  /**
   * @generated from enum value: BLUE = 1;
   */
  BLUE = 1,

  /**
   * @generated from enum value: DARK_BLUE = 2;
   */
  DARK_BLUE = 2,

  /**
   * @generated from enum value: RED = 3;
   */
  RED = 3,

  /**
   * @generated from enum value: ORANGE = 4;
   */
  ORANGE = 4,

  /**
   * @generated from enum value: BROWN = 5;
   */
  BROWN = 5,

  /**
   * @generated from enum value: DARK_GREEN = 6;
   */
  DARK_GREEN = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(PrimaryColor)
proto3.util.setEnumType(PrimaryColor, "agentero.portal.PrimaryColor", [
  { no: 0, name: "GREEN" },
  { no: 1, name: "BLUE" },
  { no: 2, name: "DARK_BLUE" },
  { no: 3, name: "RED" },
  { no: 4, name: "ORANGE" },
  { no: 5, name: "BROWN" },
  { no: 6, name: "DARK_GREEN" },
]);

/**
 * PlanType comes from ChargeBee, we need to keep it in sync.
 *
 * @generated from enum agentero.portal.PlanType
 */
export enum PlanType {
  /**
   * @generated from enum value: STARTUP_AGENCY_50 = 0;
   */
  STARTUP_AGENCY_50 = 0,

  /**
   * @generated from enum value: STARTER_MONTHLY = 1;
   */
  STARTER_MONTHLY = 1,

  /**
   * @generated from enum value: STARTER = 2;
   */
  STARTER = 2,

  /**
   * @generated from enum value: START_UP_79 = 3;
   */
  START_UP_79 = 3,

  /**
   * @generated from enum value: STANDARD_801_1500 = 4;
   */
  STANDARD_801_1500 = 4,

  /**
   * @generated from enum value: STANDARD_7001_10000 = 5;
   */
  STANDARD_7001_10000 = 5,

  /**
   * @generated from enum value: STANDARD_501_800 = 6;
   */
  STANDARD_501_800 = 6,

  /**
   * @generated from enum value: STANDARD_4001_7000 = 7;
   */
  STANDARD_4001_7000 = 7,

  /**
   * @generated from enum value: STANDARD_2501_4000 = 8;
   */
  STANDARD_2501_4000 = 8,

  /**
   * @generated from enum value: STANDARD_2001_2500 = 9;
   */
  STANDARD_2001_2500 = 9,

  /**
   * @generated from enum value: STANDARD_1501_2000 = 10;
   */
  STANDARD_1501_2000 = 10,

  /**
   * @generated from enum value: STANDARD_101_500 = 11;
   */
  STANDARD_101_500 = 11,

  /**
   * @generated from enum value: STANDARD_1_100 = 12;
   */
  STANDARD_1_100 = 12,

  /**
   * @generated from enum value: SIAA_AZ_MONTHLY_PAYMENT = 13;
   */
  SIAA_AZ_MONTHLY_PAYMENT = 13,

  /**
   * @generated from enum value: SEPTEMBER_5_AGENCIES = 14;
   */
  SEPTEMBER_5_AGENCIES = 14,

  /**
   * @generated from enum value: PRO_MONTHLY = 15;
   */
  PRO_MONTHLY = 15,

  /**
   * @generated from enum value: PRO = 16;
   */
  PRO = 16,

  /**
   * @generated from enum value: PREMIUM_MONTHLY = 17;
   */
  PREMIUM_MONTHLY = 17,

  /**
   * @generated from enum value: PREMIUM_BUNDLE = 18;
   */
  PREMIUM_BUNDLE = 18,

  /**
   * @generated from enum value: PREMIUM = 19;
   */
  PREMIUM = 19,

  /**
   * @generated from enum value: MOBILE_OPPORTUNITIES = 20;
   */
  MOBILE_OPPORTUNITIES = 20,

  /**
   * @generated from enum value: MOBILE_ANALYTICS = 21;
   */
  MOBILE_ANALYTICS = 21,

  /**
   * @generated from enum value: FREE_TRIAL = 22;
   */
  FREE_TRIAL = 22,

  /**
   * @generated from enum value: CUSTOM = 23;
   */
  CUSTOM = 23,

  /**
   * @generated from enum value: CLEMENS = 24;
   */
  CLEMENS = 24,

  /**
   * @generated from enum value: ALTUS_ACCESS_AGENCIES = 25;
   */
  ALTUS_ACCESS_AGENCIES = 25,

  /**
   * @generated from enum value: AGENTERO_BASIC = 26;
   */
  AGENTERO_BASIC = 26,

  /**
   * @generated from enum value: AGENT_MONTHLY = 27;
   */
  AGENT_MONTHLY = 27,

  /**
   * @generated from enum value: AGENT = 28;
   */
  AGENT = 28,

  /**
   * @generated from enum value: T_240_MONTHLY = 29;
   */
  T_240_MONTHLY = 29,

  /**
   * @generated from enum value: T_240 = 30;
   */
  T_240 = 30,

  /**
   * @generated from enum value: T_150 = 31;
   */
  T_150 = 31,

  /**
   * @generated from enum value: SMALL = 32;
   */
  SMALL = 32,

  /**
   * @generated from enum value: MEDIUM = 33;
   */
  MEDIUM = 33,

  /**
   * @generated from enum value: LARGE = 34;
   */
  LARGE = 34,
}
// Retrieve enum metadata with: proto3.getEnumType(PlanType)
proto3.util.setEnumType(PlanType, "agentero.portal.PlanType", [
  { no: 0, name: "STARTUP_AGENCY_50" },
  { no: 1, name: "STARTER_MONTHLY" },
  { no: 2, name: "STARTER" },
  { no: 3, name: "START_UP_79" },
  { no: 4, name: "STANDARD_801_1500" },
  { no: 5, name: "STANDARD_7001_10000" },
  { no: 6, name: "STANDARD_501_800" },
  { no: 7, name: "STANDARD_4001_7000" },
  { no: 8, name: "STANDARD_2501_4000" },
  { no: 9, name: "STANDARD_2001_2500" },
  { no: 10, name: "STANDARD_1501_2000" },
  { no: 11, name: "STANDARD_101_500" },
  { no: 12, name: "STANDARD_1_100" },
  { no: 13, name: "SIAA_AZ_MONTHLY_PAYMENT" },
  { no: 14, name: "SEPTEMBER_5_AGENCIES" },
  { no: 15, name: "PRO_MONTHLY" },
  { no: 16, name: "PRO" },
  { no: 17, name: "PREMIUM_MONTHLY" },
  { no: 18, name: "PREMIUM_BUNDLE" },
  { no: 19, name: "PREMIUM" },
  { no: 20, name: "MOBILE_OPPORTUNITIES" },
  { no: 21, name: "MOBILE_ANALYTICS" },
  { no: 22, name: "FREE_TRIAL" },
  { no: 23, name: "CUSTOM" },
  { no: 24, name: "CLEMENS" },
  { no: 25, name: "ALTUS_ACCESS_AGENCIES" },
  { no: 26, name: "AGENTERO_BASIC" },
  { no: 27, name: "AGENT_MONTHLY" },
  { no: 28, name: "AGENT" },
  { no: 29, name: "T_240_MONTHLY" },
  { no: 30, name: "T_240" },
  { no: 31, name: "T_150" },
  { no: 32, name: "SMALL" },
  { no: 33, name: "MEDIUM" },
  { no: 34, name: "LARGE" },
]);

/**
 * @generated from enum agentero.portal.LineOfBusiness
 */
export enum LineOfBusiness {
  /**
   * @generated from enum value: LIFE = 0;
   */
  LIFE = 0,

  /**
   * @generated from enum value: HEALTH = 1;
   */
  HEALTH = 1,

  /**
   * @generated from enum value: COMMERCIAL = 2;
   */
  COMMERCIAL = 2,

  /**
   * @generated from enum value: PROPERTY_AND_CASUALTY = 3;
   */
  PROPERTY_AND_CASUALTY = 3,

  /**
   * @generated from enum value: PERSONAL = 4;
   */
  PERSONAL = 4,

  /**
   * @generated from enum value: PERSONAL_AUTO = 5;
   */
  PERSONAL_AUTO = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(LineOfBusiness)
proto3.util.setEnumType(LineOfBusiness, "agentero.portal.LineOfBusiness", [
  { no: 0, name: "LIFE" },
  { no: 1, name: "HEALTH" },
  { no: 2, name: "COMMERCIAL" },
  { no: 3, name: "PROPERTY_AND_CASUALTY" },
  { no: 4, name: "PERSONAL" },
  { no: 5, name: "PERSONAL_AUTO" },
]);

/**
 * OperateAs defines how the agency is operating.
 *
 * @generated from enum agentero.portal.OperateAs
 */
export enum OperateAs {
  /**
   * @generated from enum value: OPERATE_AS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: OPERATE_AS_AGENCY = 1;
   */
  AGENCY = 1,

  /**
   * @generated from enum value: OPERATE_AS_AGENT = 2;
   */
  AGENT = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(OperateAs)
proto3.util.setEnumType(OperateAs, "agentero.portal.OperateAs", [
  { no: 0, name: "OPERATE_AS_UNSPECIFIED" },
  { no: 1, name: "OPERATE_AS_AGENCY" },
  { no: 2, name: "OPERATE_AS_AGENT" },
]);

/**
 * @generated from enum agentero.portal.CarrierLossRatioID
 */
export enum CarrierLossRatioID {
  /**
   * @generated from enum value: CARRIER_LOSS_RATIO_UNSPECIFIED = 0;
   */
  CARRIER_LOSS_RATIO_UNSPECIFIED = 0,

  /**
   * @generated from enum value: CARRIER_LOSS_RATIO_ID_1 = 1;
   */
  CARRIER_LOSS_RATIO_ID_1 = 1,

  /**
   * @generated from enum value: CARRIER_LOSS_RATIO_ID_2 = 2;
   */
  CARRIER_LOSS_RATIO_ID_2 = 2,

  /**
   * @generated from enum value: CARRIER_LOSS_RATIO_ID_3 = 3;
   */
  CARRIER_LOSS_RATIO_ID_3 = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(CarrierLossRatioID)
proto3.util.setEnumType(CarrierLossRatioID, "agentero.portal.CarrierLossRatioID", [
  { no: 0, name: "CARRIER_LOSS_RATIO_UNSPECIFIED" },
  { no: 1, name: "CARRIER_LOSS_RATIO_ID_1" },
  { no: 2, name: "CARRIER_LOSS_RATIO_ID_2" },
  { no: 3, name: "CARRIER_LOSS_RATIO_ID_3" },
]);

/**
 * @generated from message agentero.portal.GetQualificationSummaryRequest
 */
export class GetQualificationSummaryRequest extends Message<GetQualificationSummaryRequest> {
  constructor(data?: PartialMessage<GetQualificationSummaryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetQualificationSummaryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQualificationSummaryRequest {
    return new GetQualificationSummaryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQualificationSummaryRequest {
    return new GetQualificationSummaryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQualificationSummaryRequest {
    return new GetQualificationSummaryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetQualificationSummaryRequest | PlainMessage<GetQualificationSummaryRequest> | undefined, b: GetQualificationSummaryRequest | PlainMessage<GetQualificationSummaryRequest> | undefined): boolean {
    return proto3.util.equals(GetQualificationSummaryRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetQualificationSummaryResponse
 */
export class GetQualificationSummaryResponse extends Message<GetQualificationSummaryResponse> {
  /**
   * @generated from field: repeated agentero.portal.GetQualificationSummaryResponse.QualificationSummaryItem items = 1;
   */
  items: GetQualificationSummaryResponse_QualificationSummaryItem[] = [];

  constructor(data?: PartialMessage<GetQualificationSummaryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetQualificationSummaryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: GetQualificationSummaryResponse_QualificationSummaryItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQualificationSummaryResponse {
    return new GetQualificationSummaryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQualificationSummaryResponse {
    return new GetQualificationSummaryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQualificationSummaryResponse {
    return new GetQualificationSummaryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetQualificationSummaryResponse | PlainMessage<GetQualificationSummaryResponse> | undefined, b: GetQualificationSummaryResponse | PlainMessage<GetQualificationSummaryResponse> | undefined): boolean {
    return proto3.util.equals(GetQualificationSummaryResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetQualificationSummaryResponse.QualificationSummaryItem
 */
export class GetQualificationSummaryResponse_QualificationSummaryItem extends Message<GetQualificationSummaryResponse_QualificationSummaryItem> {
  /**
   * @generated from field: agentero.portal.QualificationResult result = 1;
   */
  result = QualificationResult.UNSPECIFIED;

  /**
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  constructor(data?: PartialMessage<GetQualificationSummaryResponse_QualificationSummaryItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetQualificationSummaryResponse.QualificationSummaryItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "result", kind: "enum", T: proto3.getEnumType(QualificationResult) },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQualificationSummaryResponse_QualificationSummaryItem {
    return new GetQualificationSummaryResponse_QualificationSummaryItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQualificationSummaryResponse_QualificationSummaryItem {
    return new GetQualificationSummaryResponse_QualificationSummaryItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQualificationSummaryResponse_QualificationSummaryItem {
    return new GetQualificationSummaryResponse_QualificationSummaryItem().fromJsonString(jsonString, options);
  }

  static equals(a: GetQualificationSummaryResponse_QualificationSummaryItem | PlainMessage<GetQualificationSummaryResponse_QualificationSummaryItem> | undefined, b: GetQualificationSummaryResponse_QualificationSummaryItem | PlainMessage<GetQualificationSummaryResponse_QualificationSummaryItem> | undefined): boolean {
    return proto3.util.equals(GetQualificationSummaryResponse_QualificationSummaryItem, a, b);
  }
}

/**
 * @generated from message agentero.portal.Pagination
 */
export class Pagination extends Message<Pagination> {
  /**
   * @generated from field: int64 offset = 1;
   */
  offset = protoInt64.zero;

  /**
   * @generated from field: int64 limit = 2;
   */
  limit = protoInt64.zero;

  constructor(data?: PartialMessage<Pagination>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.Pagination";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Pagination {
    return new Pagination().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Pagination {
    return new Pagination().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Pagination {
    return new Pagination().fromJsonString(jsonString, options);
  }

  static equals(a: Pagination | PlainMessage<Pagination> | undefined, b: Pagination | PlainMessage<Pagination> | undefined): boolean {
    return proto3.util.equals(Pagination, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetEandORequest
 */
export class GetEandORequest extends Message<GetEandORequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<GetEandORequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetEandORequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEandORequest {
    return new GetEandORequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEandORequest {
    return new GetEandORequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEandORequest {
    return new GetEandORequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetEandORequest | PlainMessage<GetEandORequest> | undefined, b: GetEandORequest | PlainMessage<GetEandORequest> | undefined): boolean {
    return proto3.util.equals(GetEandORequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetEandOResponse
 */
export class GetEandOResponse extends Message<GetEandOResponse> {
  /**
   * @generated from field: agentero.portal.EandOLicenseInfo license_info = 1;
   */
  licenseInfo?: EandOLicenseInfo;

  /**
   * @generated from field: agentero.portal.EandOValidation validation = 2;
   */
  validation?: EandOValidation;

  constructor(data?: PartialMessage<GetEandOResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetEandOResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "license_info", kind: "message", T: EandOLicenseInfo },
    { no: 2, name: "validation", kind: "message", T: EandOValidation },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEandOResponse {
    return new GetEandOResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEandOResponse {
    return new GetEandOResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEandOResponse {
    return new GetEandOResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetEandOResponse | PlainMessage<GetEandOResponse> | undefined, b: GetEandOResponse | PlainMessage<GetEandOResponse> | undefined): boolean {
    return proto3.util.equals(GetEandOResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.EandOLicenseInfo
 */
export class EandOLicenseInfo extends Message<EandOLicenseInfo> {
  /**
   * @generated from field: string policy_number = 1;
   */
  policyNumber = "";

  /**
   * @generated from field: google.protobuf.Timestamp expiration_date = 2;
   */
  expirationDate?: Timestamp;

  /**
   * @generated from field: int64 coverage_per_claim_limit = 3;
   */
  coveragePerClaimLimit = protoInt64.zero;

  /**
   * @generated from field: string carrier = 4;
   */
  carrier = "";

  /**
   * @generated from field: string pdf_url = 5;
   */
  pdfUrl = "";

  constructor(data?: PartialMessage<EandOLicenseInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.EandOLicenseInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "expiration_date", kind: "message", T: Timestamp },
    { no: 3, name: "coverage_per_claim_limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "carrier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "pdf_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EandOLicenseInfo {
    return new EandOLicenseInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EandOLicenseInfo {
    return new EandOLicenseInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EandOLicenseInfo {
    return new EandOLicenseInfo().fromJsonString(jsonString, options);
  }

  static equals(a: EandOLicenseInfo | PlainMessage<EandOLicenseInfo> | undefined, b: EandOLicenseInfo | PlainMessage<EandOLicenseInfo> | undefined): boolean {
    return proto3.util.equals(EandOLicenseInfo, a, b);
  }
}

/**
 * @generated from message agentero.portal.EandOValidation
 */
export class EandOValidation extends Message<EandOValidation> {
  /**
   * @generated from field: agentero.portal.EAndOStatus status = 1;
   */
  status = EAndOStatus.UNSPECIFIED;

  /**
   * @generated from field: repeated agentero.portal.EAndOInvalidReasons invalid_reasons = 2;
   */
  invalidReasons: EAndOInvalidReasons[] = [];

  /**
   * @generated from field: string other_invalid_reason = 3;
   */
  otherInvalidReason = "";

  /**
   * @generated from field: repeated agentero.license.LineOfAuthority lines_of_authority = 4;
   */
  linesOfAuthority: LineOfAuthority[] = [];

  /**
   * @generated from field: agentero.portal.EAndOUploadedPolicyType uploaded_policy_type = 5;
   */
  uploadedPolicyType = EAndOUploadedPolicyType.E_AND_O_UPLOADED_POLICY_UNSPECIFIED;

  /**
   * @generated from field: agentero.portal.EandOValidation.EOFullPolicyRequirement full_policy_required = 6;
   */
  fullPolicyRequired = EandOValidation_EOFullPolicyRequirement.EO_FULL_POLICY_UNSPECIFIED;

  constructor(data?: PartialMessage<EandOValidation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.EandOValidation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(EAndOStatus) },
    { no: 2, name: "invalid_reasons", kind: "enum", T: proto3.getEnumType(EAndOInvalidReasons), repeated: true },
    { no: 3, name: "other_invalid_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "lines_of_authority", kind: "enum", T: proto3.getEnumType(LineOfAuthority), repeated: true },
    { no: 5, name: "uploaded_policy_type", kind: "enum", T: proto3.getEnumType(EAndOUploadedPolicyType) },
    { no: 6, name: "full_policy_required", kind: "enum", T: proto3.getEnumType(EandOValidation_EOFullPolicyRequirement) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EandOValidation {
    return new EandOValidation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EandOValidation {
    return new EandOValidation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EandOValidation {
    return new EandOValidation().fromJsonString(jsonString, options);
  }

  static equals(a: EandOValidation | PlainMessage<EandOValidation> | undefined, b: EandOValidation | PlainMessage<EandOValidation> | undefined): boolean {
    return proto3.util.equals(EandOValidation, a, b);
  }
}

/**
 * @generated from enum agentero.portal.EandOValidation.EOFullPolicyRequirement
 */
export enum EandOValidation_EOFullPolicyRequirement {
  /**
   * @generated from enum value: EO_FULL_POLICY_UNSPECIFIED = 0;
   */
  EO_FULL_POLICY_UNSPECIFIED = 0,

  /**
   * @generated from enum value: EO_FULL_POLICY_REQUIRED = 1;
   */
  EO_FULL_POLICY_REQUIRED = 1,

  /**
   * @generated from enum value: EO_FULL_POLICY_NOT_REQUIRED = 2;
   */
  EO_FULL_POLICY_NOT_REQUIRED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(EandOValidation_EOFullPolicyRequirement)
proto3.util.setEnumType(EandOValidation_EOFullPolicyRequirement, "agentero.portal.EandOValidation.EOFullPolicyRequirement", [
  { no: 0, name: "EO_FULL_POLICY_UNSPECIFIED" },
  { no: 1, name: "EO_FULL_POLICY_REQUIRED" },
  { no: 2, name: "EO_FULL_POLICY_NOT_REQUIRED" },
]);

/**
 * @generated from message agentero.portal.SaveEandOLicenseInfoRequest
 */
export class SaveEandOLicenseInfoRequest extends Message<SaveEandOLicenseInfoRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: agentero.portal.EandOLicenseInfo license_info = 2;
   */
  licenseInfo?: EandOLicenseInfo;

  constructor(data?: PartialMessage<SaveEandOLicenseInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.SaveEandOLicenseInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "license_info", kind: "message", T: EandOLicenseInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveEandOLicenseInfoRequest {
    return new SaveEandOLicenseInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveEandOLicenseInfoRequest {
    return new SaveEandOLicenseInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveEandOLicenseInfoRequest {
    return new SaveEandOLicenseInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SaveEandOLicenseInfoRequest | PlainMessage<SaveEandOLicenseInfoRequest> | undefined, b: SaveEandOLicenseInfoRequest | PlainMessage<SaveEandOLicenseInfoRequest> | undefined): boolean {
    return proto3.util.equals(SaveEandOLicenseInfoRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.SaveEandOValidationRequest
 */
export class SaveEandOValidationRequest extends Message<SaveEandOValidationRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: agentero.portal.EandOLicenseInfo license_info = 2;
   */
  licenseInfo?: EandOLicenseInfo;

  /**
   * @generated from field: agentero.portal.EandOValidation validation = 3;
   */
  validation?: EandOValidation;

  /**
   * @generated from field: bool has_file_changed = 4;
   */
  hasFileChanged = false;

  constructor(data?: PartialMessage<SaveEandOValidationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.SaveEandOValidationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "license_info", kind: "message", T: EandOLicenseInfo },
    { no: 3, name: "validation", kind: "message", T: EandOValidation },
    { no: 4, name: "has_file_changed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveEandOValidationRequest {
    return new SaveEandOValidationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveEandOValidationRequest {
    return new SaveEandOValidationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveEandOValidationRequest {
    return new SaveEandOValidationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SaveEandOValidationRequest | PlainMessage<SaveEandOValidationRequest> | undefined, b: SaveEandOValidationRequest | PlainMessage<SaveEandOValidationRequest> | undefined): boolean {
    return proto3.util.equals(SaveEandOValidationRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GenerateEOPolicyUploadURLRequest
 */
export class GenerateEOPolicyUploadURLRequest extends Message<GenerateEOPolicyUploadURLRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<GenerateEOPolicyUploadURLRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GenerateEOPolicyUploadURLRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateEOPolicyUploadURLRequest {
    return new GenerateEOPolicyUploadURLRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateEOPolicyUploadURLRequest {
    return new GenerateEOPolicyUploadURLRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateEOPolicyUploadURLRequest {
    return new GenerateEOPolicyUploadURLRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateEOPolicyUploadURLRequest | PlainMessage<GenerateEOPolicyUploadURLRequest> | undefined, b: GenerateEOPolicyUploadURLRequest | PlainMessage<GenerateEOPolicyUploadURLRequest> | undefined): boolean {
    return proto3.util.equals(GenerateEOPolicyUploadURLRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GenerateEOPolicyUploadURLResponse
 */
export class GenerateEOPolicyUploadURLResponse extends Message<GenerateEOPolicyUploadURLResponse> {
  /**
   * @generated from field: string policy_file_url = 1;
   */
  policyFileUrl = "";

  /**
   * @generated from field: string expected_content_type = 2;
   */
  expectedContentType = "";

  constructor(data?: PartialMessage<GenerateEOPolicyUploadURLResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GenerateEOPolicyUploadURLResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy_file_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "expected_content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateEOPolicyUploadURLResponse {
    return new GenerateEOPolicyUploadURLResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateEOPolicyUploadURLResponse {
    return new GenerateEOPolicyUploadURLResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateEOPolicyUploadURLResponse {
    return new GenerateEOPolicyUploadURLResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateEOPolicyUploadURLResponse | PlainMessage<GenerateEOPolicyUploadURLResponse> | undefined, b: GenerateEOPolicyUploadURLResponse | PlainMessage<GenerateEOPolicyUploadURLResponse> | undefined): boolean {
    return proto3.util.equals(GenerateEOPolicyUploadURLResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetEandOValidationSummaryRequest
 */
export class GetEandOValidationSummaryRequest extends Message<GetEandOValidationSummaryRequest> {
  constructor(data?: PartialMessage<GetEandOValidationSummaryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetEandOValidationSummaryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEandOValidationSummaryRequest {
    return new GetEandOValidationSummaryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEandOValidationSummaryRequest {
    return new GetEandOValidationSummaryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEandOValidationSummaryRequest {
    return new GetEandOValidationSummaryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetEandOValidationSummaryRequest | PlainMessage<GetEandOValidationSummaryRequest> | undefined, b: GetEandOValidationSummaryRequest | PlainMessage<GetEandOValidationSummaryRequest> | undefined): boolean {
    return proto3.util.equals(GetEandOValidationSummaryRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetEandOValidationSummaryResponse
 */
export class GetEandOValidationSummaryResponse extends Message<GetEandOValidationSummaryResponse> {
  /**
   * @generated from field: repeated agentero.portal.GetEandOValidationSummaryResponse.EandOStatusSummaryItem items = 2;
   */
  items: GetEandOValidationSummaryResponse_EandOStatusSummaryItem[] = [];

  constructor(data?: PartialMessage<GetEandOValidationSummaryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetEandOValidationSummaryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "items", kind: "message", T: GetEandOValidationSummaryResponse_EandOStatusSummaryItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEandOValidationSummaryResponse {
    return new GetEandOValidationSummaryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEandOValidationSummaryResponse {
    return new GetEandOValidationSummaryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEandOValidationSummaryResponse {
    return new GetEandOValidationSummaryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetEandOValidationSummaryResponse | PlainMessage<GetEandOValidationSummaryResponse> | undefined, b: GetEandOValidationSummaryResponse | PlainMessage<GetEandOValidationSummaryResponse> | undefined): boolean {
    return proto3.util.equals(GetEandOValidationSummaryResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetEandOValidationSummaryResponse.EandOStatusSummaryItem
 */
export class GetEandOValidationSummaryResponse_EandOStatusSummaryItem extends Message<GetEandOValidationSummaryResponse_EandOStatusSummaryItem> {
  /**
   * @generated from field: agentero.portal.EAndOStatus status = 1;
   */
  status = EAndOStatus.UNSPECIFIED;

  /**
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  constructor(data?: PartialMessage<GetEandOValidationSummaryResponse_EandOStatusSummaryItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetEandOValidationSummaryResponse.EandOStatusSummaryItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(EAndOStatus) },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEandOValidationSummaryResponse_EandOStatusSummaryItem {
    return new GetEandOValidationSummaryResponse_EandOStatusSummaryItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEandOValidationSummaryResponse_EandOStatusSummaryItem {
    return new GetEandOValidationSummaryResponse_EandOStatusSummaryItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEandOValidationSummaryResponse_EandOStatusSummaryItem {
    return new GetEandOValidationSummaryResponse_EandOStatusSummaryItem().fromJsonString(jsonString, options);
  }

  static equals(a: GetEandOValidationSummaryResponse_EandOStatusSummaryItem | PlainMessage<GetEandOValidationSummaryResponse_EandOStatusSummaryItem> | undefined, b: GetEandOValidationSummaryResponse_EandOStatusSummaryItem | PlainMessage<GetEandOValidationSummaryResponse_EandOStatusSummaryItem> | undefined): boolean {
    return proto3.util.equals(GetEandOValidationSummaryResponse_EandOStatusSummaryItem, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateAgencyOperationModeRequest
 */
export class UpdateAgencyOperationModeRequest extends Message<UpdateAgencyOperationModeRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: agentero.portal.OperateAs operate_as = 2;
   */
  operateAs = OperateAs.UNSPECIFIED;

  constructor(data?: PartialMessage<UpdateAgencyOperationModeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateAgencyOperationModeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "operate_as", kind: "enum", T: proto3.getEnumType(OperateAs) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAgencyOperationModeRequest {
    return new UpdateAgencyOperationModeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAgencyOperationModeRequest {
    return new UpdateAgencyOperationModeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAgencyOperationModeRequest {
    return new UpdateAgencyOperationModeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAgencyOperationModeRequest | PlainMessage<UpdateAgencyOperationModeRequest> | undefined, b: UpdateAgencyOperationModeRequest | PlainMessage<UpdateAgencyOperationModeRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAgencyOperationModeRequest, a, b);
  }
}

/**
 * Agency defines agency details.
 *
 * @generated from message agentero.portal.Agency
 */
export class Agency extends Message<Agency> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Actual contact information
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string phone_number = 4;
   */
  phoneNumber = "";

  /**
   * @generated from field: agentero.contact.Address address = 5;
   */
  address?: Address;

  /**
   * @generated from field: string timezone = 6;
   */
  timezone = "";

  /**
   * the logo of the agency
   *
   * @generated from field: string photo_url = 7;
   */
  photoUrl = "";

  /**
   * links for the agency
   *
   * @generated from field: string web_url = 8;
   */
  webUrl = "";

  /**
   * @generated from field: string facebook_url = 9;
   */
  facebookUrl = "";

  /**
   * @generated from field: string linkedin_url = 10;
   */
  linkedinUrl = "";

  /**
   * @generated from field: string twitter_url = 11;
   */
  twitterUrl = "";

  /**
   * @generated from field: string yelp_url = 12;
   */
  yelpUrl = "";

  /**
   * @generated from field: string google_review_url = 13;
   */
  googleReviewUrl = "";

  /**
   * the settings of the agency
   *
   * @generated from field: string formstack_name = 14;
   */
  formstackName = "";

  /**
   * @generated from field: string sales_force_id = 15;
   */
  salesForceId = "";

  /**
   * @generated from field: string referral_page = 16;
   */
  referralPage = "";

  /**
   * @generated from field: agentero.portal.CommercialRater commercial_rater = 31 [deprecated = true];
   * @deprecated
   */
  commercialRater?: CommercialRater;

  /**
   * @generated from field: map<string, bool> products = 32 [deprecated = true];
   * @deprecated
   */
  products: { [key: string]: boolean } = {};

  /**
   * @generated from field: agentero.portal.ComparativeRater comparative_rater = 19;
   */
  comparativeRater?: ComparativeRater;

  /**
   * @generated from field: repeated agentero.portal.LineOfBusiness lines_of_business = 20;
   */
  linesOfBusiness: LineOfBusiness[] = [];

  /**
   * @generated from field: repeated string states = 21 [deprecated = true];
   * @deprecated
   */
  states: string[] = [];

  /**
   * @generated from field: agentero.portal.AgencySubscription subscription = 22;
   */
  subscription?: AgencySubscription;

  /**
   * @generated from field: bool has_branded_app_settings = 23;
   */
  hasBrandedAppSettings = false;

  /**
   * @generated from field: agentero.portal.BrandedAppSetting branded_app_setting = 24;
   */
  brandedAppSetting?: BrandedAppSetting;

  /**
   * @generated from field: agentero.portal.MobileSettings mobile_settings = 25;
   */
  mobileSettings?: MobileSettings;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 27;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: agentero.portal.DefaultAgent default_agent_for_prospects = 28;
   */
  defaultAgentForProspects?: DefaultAgent;

  /**
   * @generated from field: repeated agentero.portal.RaterCarrierEnableStatus rater_carriers_enable_status = 29;
   */
  raterCarriersEnableStatus: RaterCarrierEnableStatus[] = [];

  /**
   * @generated from field: string eo_form_url = 30;
   */
  eoFormUrl = "";

  /**
   * @generated from field: bool appointments_enabled = 33;
   */
  appointmentsEnabled = false;

  /**
   * @generated from field: bool commercial_rater_enabled = 34;
   */
  commercialRaterEnabled = false;

  /**
   * @generated from field: string fein = 35;
   */
  fein = "";

  /**
   * @generated from field: string npn = 36;
   */
  npn = "";

  /**
   * @generated from field: string license_number = 37;
   */
  licenseNumber = "";

  /**
   * @generated from field: string license_state = 38;
   */
  licenseState = "";

  /**
   * @generated from field: agentero.portal.OperateAs operate_as = 39;
   */
  operateAs = OperateAs.UNSPECIFIED;

  /**
   * @generated from field: bool security_check_passed = 40;
   */
  securityCheckPassed = false;

  /**
   * @generated from field: agentero.portal.QualificationInfo qualification_info = 41 [deprecated = true];
   * @deprecated
   */
  qualificationInfo?: QualificationInfo;

  /**
   * @generated from field: bool enabled = 42;
   */
  enabled = false;

  /**
   * @generated from field: bool testing = 43;
   */
  testing = false;

  /**
   * @generated from field: bool tos_accepted = 44;
   */
  tosAccepted = false;

  constructor(data?: PartialMessage<Agency>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.Agency";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "address", kind: "message", T: Address },
    { no: 6, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "photo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "web_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "facebook_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "linkedin_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "twitter_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "yelp_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "google_review_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "formstack_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "sales_force_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "referral_page", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "commercial_rater", kind: "message", T: CommercialRater },
    { no: 32, name: "products", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 8 /* ScalarType.BOOL */} },
    { no: 19, name: "comparative_rater", kind: "message", T: ComparativeRater },
    { no: 20, name: "lines_of_business", kind: "enum", T: proto3.getEnumType(LineOfBusiness), repeated: true },
    { no: 21, name: "states", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 22, name: "subscription", kind: "message", T: AgencySubscription },
    { no: 23, name: "has_branded_app_settings", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 24, name: "branded_app_setting", kind: "message", T: BrandedAppSetting },
    { no: 25, name: "mobile_settings", kind: "message", T: MobileSettings },
    { no: 27, name: "created_at", kind: "message", T: Timestamp },
    { no: 28, name: "default_agent_for_prospects", kind: "message", T: DefaultAgent },
    { no: 29, name: "rater_carriers_enable_status", kind: "message", T: RaterCarrierEnableStatus, repeated: true },
    { no: 30, name: "eo_form_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 33, name: "appointments_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 34, name: "commercial_rater_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 35, name: "fein", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 36, name: "npn", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 37, name: "license_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 38, name: "license_state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 39, name: "operate_as", kind: "enum", T: proto3.getEnumType(OperateAs) },
    { no: 40, name: "security_check_passed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 41, name: "qualification_info", kind: "message", T: QualificationInfo },
    { no: 42, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 43, name: "testing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 44, name: "tos_accepted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Agency {
    return new Agency().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Agency {
    return new Agency().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Agency {
    return new Agency().fromJsonString(jsonString, options);
  }

  static equals(a: Agency | PlainMessage<Agency> | undefined, b: Agency | PlainMessage<Agency> | undefined): boolean {
    return proto3.util.equals(Agency, a, b);
  }
}

/**
 * @generated from message agentero.portal.CommercialRater
 */
export class CommercialRater extends Message<CommercialRater> {
  /**
   * @generated from field: bool available = 1;
   */
  available = false;

  /**
   * @generated from field: bool enabled = 2;
   */
  enabled = false;

  constructor(data?: PartialMessage<CommercialRater>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.CommercialRater";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "available", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CommercialRater {
    return new CommercialRater().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CommercialRater {
    return new CommercialRater().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CommercialRater {
    return new CommercialRater().fromJsonString(jsonString, options);
  }

  static equals(a: CommercialRater | PlainMessage<CommercialRater> | undefined, b: CommercialRater | PlainMessage<CommercialRater> | undefined): boolean {
    return proto3.util.equals(CommercialRater, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetAgencyQualificationInformationRequest
 */
export class GetAgencyQualificationInformationRequest extends Message<GetAgencyQualificationInformationRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<GetAgencyQualificationInformationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetAgencyQualificationInformationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAgencyQualificationInformationRequest {
    return new GetAgencyQualificationInformationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAgencyQualificationInformationRequest {
    return new GetAgencyQualificationInformationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAgencyQualificationInformationRequest {
    return new GetAgencyQualificationInformationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAgencyQualificationInformationRequest | PlainMessage<GetAgencyQualificationInformationRequest> | undefined, b: GetAgencyQualificationInformationRequest | PlainMessage<GetAgencyQualificationInformationRequest> | undefined): boolean {
    return proto3.util.equals(GetAgencyQualificationInformationRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetAgencyQualificationInformationResponse
 */
export class GetAgencyQualificationInformationResponse extends Message<GetAgencyQualificationInformationResponse> {
  /**
   * @generated from field: agentero.portal.QualificationInfo qualification_info = 1;
   */
  qualificationInfo?: QualificationInfo;

  constructor(data?: PartialMessage<GetAgencyQualificationInformationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetAgencyQualificationInformationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "qualification_info", kind: "message", T: QualificationInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAgencyQualificationInformationResponse {
    return new GetAgencyQualificationInformationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAgencyQualificationInformationResponse {
    return new GetAgencyQualificationInformationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAgencyQualificationInformationResponse {
    return new GetAgencyQualificationInformationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAgencyQualificationInformationResponse | PlainMessage<GetAgencyQualificationInformationResponse> | undefined, b: GetAgencyQualificationInformationResponse | PlainMessage<GetAgencyQualificationInformationResponse> | undefined): boolean {
    return proto3.util.equals(GetAgencyQualificationInformationResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.QualificationInfo
 */
export class QualificationInfo extends Message<QualificationInfo> {
  /**
   * @generated from field: agentero.portal.QualificationResult qualified = 1;
   */
  qualified = QualificationResult.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp last_updated_at = 2;
   */
  lastUpdatedAt?: Timestamp;

  /**
   * @generated from field: agentero.portal.QualificationResult calculated = 3;
   */
  calculated = QualificationResult.UNSPECIFIED;

  /**
   * @generated from field: repeated agentero.portal.UnqualifiedReason unqualified_reasons = 4;
   */
  unqualifiedReasons: UnqualifiedReason[] = [];

  constructor(data?: PartialMessage<QualificationInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.QualificationInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "qualified", kind: "enum", T: proto3.getEnumType(QualificationResult) },
    { no: 2, name: "last_updated_at", kind: "message", T: Timestamp },
    { no: 3, name: "calculated", kind: "enum", T: proto3.getEnumType(QualificationResult) },
    { no: 4, name: "unqualified_reasons", kind: "enum", T: proto3.getEnumType(UnqualifiedReason), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QualificationInfo {
    return new QualificationInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QualificationInfo {
    return new QualificationInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QualificationInfo {
    return new QualificationInfo().fromJsonString(jsonString, options);
  }

  static equals(a: QualificationInfo | PlainMessage<QualificationInfo> | undefined, b: QualificationInfo | PlainMessage<QualificationInfo> | undefined): boolean {
    return proto3.util.equals(QualificationInfo, a, b);
  }
}

/**
 * @generated from message agentero.portal.AgencyDetails
 */
export class AgencyDetails extends Message<AgencyDetails> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: map<string, bool> products = 5;
   */
  products: { [key: string]: boolean } = {};

  /**
   * @generated from field: agentero.portal.CommercialRater commercial_rater = 6 [deprecated = true];
   * @deprecated
   */
  commercialRater?: CommercialRater;

  /**
   * @generated from field: bool commercial_rater_enabled = 7;
   */
  commercialRaterEnabled = false;

  /**
   * @generated from field: agentero.portal.AgencyDetails.EAndOInfo e_and_o_info = 8;
   */
  eAndOInfo?: AgencyDetails_EAndOInfo;

  /**
   * @generated from field: agentero.portal.QualificationInfo qualification_info = 9;
   */
  qualificationInfo?: QualificationInfo;

  /**
   * @generated from field: bool is_aggregator_available = 10;
   */
  isAggregatorAvailable = false;

  /**
   * @generated from field: bool enabled = 11;
   */
  enabled = false;

  /**
   * @generated from field: agentero.tier.Tier tier = 12;
   */
  tier = Tier.UNSPECIFIED;

  /**
   * @generated from field: bool testing = 13;
   */
  testing = false;

  constructor(data?: PartialMessage<AgencyDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.AgencyDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
    { no: 5, name: "products", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 8 /* ScalarType.BOOL */} },
    { no: 6, name: "commercial_rater", kind: "message", T: CommercialRater },
    { no: 7, name: "commercial_rater_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "e_and_o_info", kind: "message", T: AgencyDetails_EAndOInfo },
    { no: 9, name: "qualification_info", kind: "message", T: QualificationInfo },
    { no: 10, name: "is_aggregator_available", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "tier", kind: "enum", T: proto3.getEnumType(Tier) },
    { no: 13, name: "testing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AgencyDetails {
    return new AgencyDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AgencyDetails {
    return new AgencyDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AgencyDetails {
    return new AgencyDetails().fromJsonString(jsonString, options);
  }

  static equals(a: AgencyDetails | PlainMessage<AgencyDetails> | undefined, b: AgencyDetails | PlainMessage<AgencyDetails> | undefined): boolean {
    return proto3.util.equals(AgencyDetails, a, b);
  }
}

/**
 * @generated from message agentero.portal.AgencyDetails.EAndOInfo
 */
export class AgencyDetails_EAndOInfo extends Message<AgencyDetails_EAndOInfo> {
  /**
   * @generated from field: agentero.portal.EAndOStatus status = 1;
   */
  status = EAndOStatus.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp last_updated_at = 2;
   */
  lastUpdatedAt?: Timestamp;

  constructor(data?: PartialMessage<AgencyDetails_EAndOInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.AgencyDetails.EAndOInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(EAndOStatus) },
    { no: 2, name: "last_updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AgencyDetails_EAndOInfo {
    return new AgencyDetails_EAndOInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AgencyDetails_EAndOInfo {
    return new AgencyDetails_EAndOInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AgencyDetails_EAndOInfo {
    return new AgencyDetails_EAndOInfo().fromJsonString(jsonString, options);
  }

  static equals(a: AgencyDetails_EAndOInfo | PlainMessage<AgencyDetails_EAndOInfo> | undefined, b: AgencyDetails_EAndOInfo | PlainMessage<AgencyDetails_EAndOInfo> | undefined): boolean {
    return proto3.util.equals(AgencyDetails_EAndOInfo, a, b);
  }
}

/**
 * UpdateRaterCarrierEnableStatusRequest defines the message to enable/disable a rater carrier for an agency.
 *
 * @generated from message agentero.portal.UpdateRaterCarrierEnableStatusRequest
 */
export class UpdateRaterCarrierEnableStatusRequest extends Message<UpdateRaterCarrierEnableStatusRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: agentero.carrier.Carrier carrier = 2;
   */
  carrier = Carrier.UNDEFINED;

  /**
   * @generated from field: bool enabled = 3;
   */
  enabled = false;

  constructor(data?: PartialMessage<UpdateRaterCarrierEnableStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateRaterCarrierEnableStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
    { no: 3, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRaterCarrierEnableStatusRequest {
    return new UpdateRaterCarrierEnableStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRaterCarrierEnableStatusRequest {
    return new UpdateRaterCarrierEnableStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRaterCarrierEnableStatusRequest {
    return new UpdateRaterCarrierEnableStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRaterCarrierEnableStatusRequest | PlainMessage<UpdateRaterCarrierEnableStatusRequest> | undefined, b: UpdateRaterCarrierEnableStatusRequest | PlainMessage<UpdateRaterCarrierEnableStatusRequest> | undefined): boolean {
    return proto3.util.equals(UpdateRaterCarrierEnableStatusRequest, a, b);
  }
}

/**
 * UpdateCarrierEligibilityStatusRequest defines the message to hide/not hide a carrier for an agency.
 *
 * @generated from message agentero.portal.UpdateCarrierEligibilityStatusRequest
 */
export class UpdateCarrierEligibilityStatusRequest extends Message<UpdateCarrierEligibilityStatusRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: agentero.carrier.Carrier carrier = 2;
   */
  carrier = Carrier.UNDEFINED;

  /**
   * @generated from field: bool is_hidden = 3;
   */
  isHidden = false;

  constructor(data?: PartialMessage<UpdateCarrierEligibilityStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateCarrierEligibilityStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
    { no: 3, name: "is_hidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCarrierEligibilityStatusRequest {
    return new UpdateCarrierEligibilityStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCarrierEligibilityStatusRequest {
    return new UpdateCarrierEligibilityStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCarrierEligibilityStatusRequest {
    return new UpdateCarrierEligibilityStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCarrierEligibilityStatusRequest | PlainMessage<UpdateCarrierEligibilityStatusRequest> | undefined, b: UpdateCarrierEligibilityStatusRequest | PlainMessage<UpdateCarrierEligibilityStatusRequest> | undefined): boolean {
    return proto3.util.equals(UpdateCarrierEligibilityStatusRequest, a, b);
  }
}

/**
 * RaterCarrierEnableStatus defines the enable status of a non Agentero carrier.
 *
 * @generated from message agentero.portal.RaterCarrierEnableStatus
 */
export class RaterCarrierEnableStatus extends Message<RaterCarrierEnableStatus> {
  /**
   * @generated from field: agentero.carrier.Carrier carrier = 1;
   */
  carrier = Carrier.UNDEFINED;

  /**
   * @generated from field: bool enabled = 2;
   */
  enabled = false;

  constructor(data?: PartialMessage<RaterCarrierEnableStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.RaterCarrierEnableStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
    { no: 2, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RaterCarrierEnableStatus {
    return new RaterCarrierEnableStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RaterCarrierEnableStatus {
    return new RaterCarrierEnableStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RaterCarrierEnableStatus {
    return new RaterCarrierEnableStatus().fromJsonString(jsonString, options);
  }

  static equals(a: RaterCarrierEnableStatus | PlainMessage<RaterCarrierEnableStatus> | undefined, b: RaterCarrierEnableStatus | PlainMessage<RaterCarrierEnableStatus> | undefined): boolean {
    return proto3.util.equals(RaterCarrierEnableStatus, a, b);
  }
}

/**
 * @generated from message agentero.portal.AgencySubscription
 */
export class AgencySubscription extends Message<AgencySubscription> {
  /**
   * @generated from field: string contract_id = 2;
   */
  contractId = "";

  /**
   * @generated from field: bool valid = 3;
   */
  valid = false;

  /**
   * @generated from field: bool accepted_contract = 4;
   */
  acceptedContract = false;

  /**
   * @generated from field: bool is_legacy = 5;
   */
  isLegacy = false;

  /**
   * @generated from field: agentero.portal.SubscriptionPlan plan = 6;
   */
  plan?: SubscriptionPlan;

  /**
   * @generated from field: agentero.portal.AgencySubscription.Status status = 7;
   */
  status = AgencySubscription_Status.NON_RENEWING;

  /**
   * @generated from field: google.protobuf.Timestamp trial_start = 8;
   */
  trialStart?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp trial_end = 9;
   */
  trialEnd?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp contract_signed = 10;
   */
  contractSigned?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp contract_renewal = 11;
   */
  contractRenewal?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp next_billing_at = 12;
   */
  nextBillingAt?: Timestamp;

  constructor(data?: PartialMessage<AgencySubscription>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.AgencySubscription";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "contract_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "accepted_contract", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "is_legacy", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "plan", kind: "message", T: SubscriptionPlan },
    { no: 7, name: "status", kind: "enum", T: proto3.getEnumType(AgencySubscription_Status) },
    { no: 8, name: "trial_start", kind: "message", T: Timestamp },
    { no: 9, name: "trial_end", kind: "message", T: Timestamp },
    { no: 10, name: "contract_signed", kind: "message", T: Timestamp },
    { no: 11, name: "contract_renewal", kind: "message", T: Timestamp },
    { no: 12, name: "next_billing_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AgencySubscription {
    return new AgencySubscription().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AgencySubscription {
    return new AgencySubscription().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AgencySubscription {
    return new AgencySubscription().fromJsonString(jsonString, options);
  }

  static equals(a: AgencySubscription | PlainMessage<AgencySubscription> | undefined, b: AgencySubscription | PlainMessage<AgencySubscription> | undefined): boolean {
    return proto3.util.equals(AgencySubscription, a, b);
  }
}

/**
 * @generated from enum agentero.portal.AgencySubscription.Status
 */
export enum AgencySubscription_Status {
  /**
   * @generated from enum value: NON_RENEWING = 0;
   */
  NON_RENEWING = 0,

  /**
   * @generated from enum value: ACTIVE = 1;
   */
  ACTIVE = 1,

  /**
   * @generated from enum value: CANCELLED = 2;
   */
  CANCELLED = 2,

  /**
   * @generated from enum value: IN_TRIAL = 3;
   */
  IN_TRIAL = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(AgencySubscription_Status)
proto3.util.setEnumType(AgencySubscription_Status, "agentero.portal.AgencySubscription.Status", [
  { no: 0, name: "NON_RENEWING" },
  { no: 1, name: "ACTIVE" },
  { no: 2, name: "CANCELLED" },
  { no: 3, name: "IN_TRIAL" },
]);

/**
 * @generated from message agentero.portal.SubscriptionPlan
 */
export class SubscriptionPlan extends Message<SubscriptionPlan> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: agentero.portal.PlanType type = 2;
   */
  type = PlanType.STARTUP_AGENCY_50;

  /**
   * year, month
   *
   * @generated from field: agentero.portal.SubscriptionPlan.Period period = 3;
   */
  period = SubscriptionPlan_Period.MONTH;

  constructor(data?: PartialMessage<SubscriptionPlan>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.SubscriptionPlan";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(PlanType) },
    { no: 3, name: "period", kind: "enum", T: proto3.getEnumType(SubscriptionPlan_Period) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubscriptionPlan {
    return new SubscriptionPlan().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubscriptionPlan {
    return new SubscriptionPlan().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubscriptionPlan {
    return new SubscriptionPlan().fromJsonString(jsonString, options);
  }

  static equals(a: SubscriptionPlan | PlainMessage<SubscriptionPlan> | undefined, b: SubscriptionPlan | PlainMessage<SubscriptionPlan> | undefined): boolean {
    return proto3.util.equals(SubscriptionPlan, a, b);
  }
}

/**
 * @generated from enum agentero.portal.SubscriptionPlan.Period
 */
export enum SubscriptionPlan_Period {
  /**
   * @generated from enum value: MONTH = 0;
   */
  MONTH = 0,

  /**
   * @generated from enum value: YEAR = 1;
   */
  YEAR = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(SubscriptionPlan_Period)
proto3.util.setEnumType(SubscriptionPlan_Period, "agentero.portal.SubscriptionPlan.Period", [
  { no: 0, name: "MONTH" },
  { no: 1, name: "YEAR" },
]);

/**
 * @generated from message agentero.portal.BrandedAppSetting
 */
export class BrandedAppSetting extends Message<BrandedAppSetting> {
  /**
   * @generated from field: string branch_key = 1;
   */
  branchKey = "";

  /**
   * @generated from field: string branch_secret = 2;
   */
  branchSecret = "";

  /**
   * @generated from field: string branch_download_link = 3;
   */
  branchDownloadLink = "";

  /**
   * @generated from field: string one_signal_app_id = 4;
   */
  oneSignalAppId = "";

  /**
   * @generated from field: string one_signal_app_key = 5;
   */
  oneSignalAppKey = "";

  /**
   * @generated from field: string mobile_app_id = 6;
   */
  mobileAppId = "";

  constructor(data?: PartialMessage<BrandedAppSetting>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.BrandedAppSetting";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "branch_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "branch_secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "branch_download_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "one_signal_app_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "one_signal_app_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "mobile_app_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BrandedAppSetting {
    return new BrandedAppSetting().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BrandedAppSetting {
    return new BrandedAppSetting().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BrandedAppSetting {
    return new BrandedAppSetting().fromJsonString(jsonString, options);
  }

  static equals(a: BrandedAppSetting | PlainMessage<BrandedAppSetting> | undefined, b: BrandedAppSetting | PlainMessage<BrandedAppSetting> | undefined): boolean {
    return proto3.util.equals(BrandedAppSetting, a, b);
  }
}

/**
 * @generated from message agentero.portal.MobileSettings
 */
export class MobileSettings extends Message<MobileSettings> {
  /**
   * @generated from field: agentero.portal.PrimaryColor primary_color = 2;
   */
  primaryColor = PrimaryColor.GREEN;

  /**
   * @generated from field: repeated agentero.portal.MobileButton mobile_buttons = 3;
   */
  mobileButtons: MobileButton[] = [];

  /**
   * @generated from field: string contact_email = 4;
   */
  contactEmail = "";

  /**
   * @generated from field: string contact_us_url = 5;
   */
  contactUsUrl = "";

  constructor(data?: PartialMessage<MobileSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.MobileSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "primary_color", kind: "enum", T: proto3.getEnumType(PrimaryColor) },
    { no: 3, name: "mobile_buttons", kind: "message", T: MobileButton, repeated: true },
    { no: 4, name: "contact_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "contact_us_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MobileSettings {
    return new MobileSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MobileSettings {
    return new MobileSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MobileSettings {
    return new MobileSettings().fromJsonString(jsonString, options);
  }

  static equals(a: MobileSettings | PlainMessage<MobileSettings> | undefined, b: MobileSettings | PlainMessage<MobileSettings> | undefined): boolean {
    return proto3.util.equals(MobileSettings, a, b);
  }
}

/**
 * @generated from message agentero.portal.MobileButton
 */
export class MobileButton extends Message<MobileButton> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: agentero.portal.MobileButton.Type type = 2;
   */
  type = MobileButton_Type.LEFT;

  /**
   * @generated from field: string label = 3;
   */
  label = "";

  /**
   * @generated from field: string custom_label = 4;
   */
  customLabel = "";

  /**
   * @generated from field: string custom_value = 5;
   */
  customValue = "";

  constructor(data?: PartialMessage<MobileButton>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.MobileButton";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(MobileButton_Type) },
    { no: 3, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "custom_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "custom_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MobileButton {
    return new MobileButton().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MobileButton {
    return new MobileButton().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MobileButton {
    return new MobileButton().fromJsonString(jsonString, options);
  }

  static equals(a: MobileButton | PlainMessage<MobileButton> | undefined, b: MobileButton | PlainMessage<MobileButton> | undefined): boolean {
    return proto3.util.equals(MobileButton, a, b);
  }
}

/**
 * @generated from enum agentero.portal.MobileButton.Type
 */
export enum MobileButton_Type {
  /**
   * @generated from enum value: LEFT = 0;
   */
  LEFT = 0,

  /**
   * @generated from enum value: RIGHT = 1;
   */
  RIGHT = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(MobileButton_Type)
proto3.util.setEnumType(MobileButton_Type, "agentero.portal.MobileButton.Type", [
  { no: 0, name: "LEFT" },
  { no: 1, name: "RIGHT" },
]);

/**
 * @generated from message agentero.portal.ComparativeRater
 */
export class ComparativeRater extends Message<ComparativeRater> {
  /**
   * @generated from field: agentero.carrier.ComparativeRaterType type = 1;
   */
  type = ComparativeRaterType.COMPARATIVE_RATER_UNDEFINED;

  /**
   * @generated from field: string other_value = 2;
   */
  otherValue = "";

  constructor(data?: PartialMessage<ComparativeRater>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.ComparativeRater";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(ComparativeRaterType) },
    { no: 2, name: "other_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComparativeRater {
    return new ComparativeRater().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComparativeRater {
    return new ComparativeRater().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComparativeRater {
    return new ComparativeRater().fromJsonString(jsonString, options);
  }

  static equals(a: ComparativeRater | PlainMessage<ComparativeRater> | undefined, b: ComparativeRater | PlainMessage<ComparativeRater> | undefined): boolean {
    return proto3.util.equals(ComparativeRater, a, b);
  }
}

/**
 * CarrierConfig defines a carrier config.
 *
 * @generated from message agentero.portal.CarrierConfig
 */
export class CarrierConfig extends Message<CarrierConfig> {
  /**
   * @generated from field: agentero.carrier.Carrier carrier = 1;
   */
  carrier = Carrier.UNDEFINED;

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  /**
   * @generated from field: string uri = 3;
   */
  uri = "";

  constructor(data?: PartialMessage<CarrierConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.CarrierConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "uri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CarrierConfig {
    return new CarrierConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CarrierConfig {
    return new CarrierConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CarrierConfig {
    return new CarrierConfig().fromJsonString(jsonString, options);
  }

  static equals(a: CarrierConfig | PlainMessage<CarrierConfig> | undefined, b: CarrierConfig | PlainMessage<CarrierConfig> | undefined): boolean {
    return proto3.util.equals(CarrierConfig, a, b);
  }
}

/**
 * GetCarriersConfigRequest defines request to get carriers config.
 *
 * @generated from message agentero.portal.GetCarriersConfigRequest
 */
export class GetCarriersConfigRequest extends Message<GetCarriersConfigRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<GetCarriersConfigRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetCarriersConfigRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCarriersConfigRequest {
    return new GetCarriersConfigRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCarriersConfigRequest {
    return new GetCarriersConfigRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCarriersConfigRequest {
    return new GetCarriersConfigRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCarriersConfigRequest | PlainMessage<GetCarriersConfigRequest> | undefined, b: GetCarriersConfigRequest | PlainMessage<GetCarriersConfigRequest> | undefined): boolean {
    return proto3.util.equals(GetCarriersConfigRequest, a, b);
  }
}

/**
 * GetCarriersConfigResponse defines result of getting carriers config.
 *
 * @generated from message agentero.portal.GetCarriersConfigResponse
 */
export class GetCarriersConfigResponse extends Message<GetCarriersConfigResponse> {
  /**
   * @generated from field: repeated agentero.portal.CarrierConfig configs = 1;
   */
  configs: CarrierConfig[] = [];

  constructor(data?: PartialMessage<GetCarriersConfigResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetCarriersConfigResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "configs", kind: "message", T: CarrierConfig, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCarriersConfigResponse {
    return new GetCarriersConfigResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCarriersConfigResponse {
    return new GetCarriersConfigResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCarriersConfigResponse {
    return new GetCarriersConfigResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCarriersConfigResponse | PlainMessage<GetCarriersConfigResponse> | undefined, b: GetCarriersConfigResponse | PlainMessage<GetCarriersConfigResponse> | undefined): boolean {
    return proto3.util.equals(GetCarriersConfigResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetAgenciesRequest
 */
export class GetAgenciesRequest extends Message<GetAgenciesRequest> {
  /**
   * @generated from field: agentero.portal.GetAgenciesRequest.Filters filters = 1;
   */
  filters?: GetAgenciesRequest_Filters;

  /**
   * @generated from field: agentero.portal.GetAgenciesRequest.OrderBy order_by = 2;
   */
  orderBy?: GetAgenciesRequest_OrderBy;

  /**
   * @generated from field: agentero.portal.Pagination pagination = 3;
   */
  pagination?: Pagination;

  constructor(data?: PartialMessage<GetAgenciesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetAgenciesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filters", kind: "message", T: GetAgenciesRequest_Filters },
    { no: 2, name: "order_by", kind: "message", T: GetAgenciesRequest_OrderBy },
    { no: 3, name: "pagination", kind: "message", T: Pagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAgenciesRequest {
    return new GetAgenciesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAgenciesRequest {
    return new GetAgenciesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAgenciesRequest {
    return new GetAgenciesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAgenciesRequest | PlainMessage<GetAgenciesRequest> | undefined, b: GetAgenciesRequest | PlainMessage<GetAgenciesRequest> | undefined): boolean {
    return proto3.util.equals(GetAgenciesRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetAgenciesRequest.Filters
 */
export class GetAgenciesRequest_Filters extends Message<GetAgenciesRequest_Filters> {
  /**
   * @generated from field: string search_text = 1;
   */
  searchText = "";

  /**
   * @generated from field: agentero.portal.EAndOStatus e_and_o_status = 2;
   */
  eAndOStatus = EAndOStatus.UNSPECIFIED;

  /**
   * @generated from field: agentero.portal.QualificationResult qualification_result = 3;
   */
  qualificationResult = QualificationResult.UNSPECIFIED;

  /**
   * @generated from field: agentero.portal.GetAgenciesRequest.Filters.EnablementStatus enablement_status = 4;
   */
  enablementStatus = GetAgenciesRequest_Filters_EnablementStatus.UNSPECIFIED;

  /**
   * @generated from field: agentero.tier.Tier tier = 5;
   */
  tier = Tier.UNSPECIFIED;

  /**
   * @generated from field: agentero.portal.TestingStatus testing_status = 6;
   */
  testingStatus = TestingStatus.UNSPECIFIED;

  constructor(data?: PartialMessage<GetAgenciesRequest_Filters>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetAgenciesRequest.Filters";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "search_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "e_and_o_status", kind: "enum", T: proto3.getEnumType(EAndOStatus) },
    { no: 3, name: "qualification_result", kind: "enum", T: proto3.getEnumType(QualificationResult) },
    { no: 4, name: "enablement_status", kind: "enum", T: proto3.getEnumType(GetAgenciesRequest_Filters_EnablementStatus) },
    { no: 5, name: "tier", kind: "enum", T: proto3.getEnumType(Tier) },
    { no: 6, name: "testing_status", kind: "enum", T: proto3.getEnumType(TestingStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAgenciesRequest_Filters {
    return new GetAgenciesRequest_Filters().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAgenciesRequest_Filters {
    return new GetAgenciesRequest_Filters().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAgenciesRequest_Filters {
    return new GetAgenciesRequest_Filters().fromJsonString(jsonString, options);
  }

  static equals(a: GetAgenciesRequest_Filters | PlainMessage<GetAgenciesRequest_Filters> | undefined, b: GetAgenciesRequest_Filters | PlainMessage<GetAgenciesRequest_Filters> | undefined): boolean {
    return proto3.util.equals(GetAgenciesRequest_Filters, a, b);
  }
}

/**
 * @generated from enum agentero.portal.GetAgenciesRequest.Filters.EnablementStatus
 */
export enum GetAgenciesRequest_Filters_EnablementStatus {
  /**
   * @generated from enum value: ENABLEMENT_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ENABLEMENT_STATUS_ENABLED = 1;
   */
  ENABLED = 1,

  /**
   * @generated from enum value: ENABLEMENT_STATUS_DISABLED = 2;
   */
  DISABLED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(GetAgenciesRequest_Filters_EnablementStatus)
proto3.util.setEnumType(GetAgenciesRequest_Filters_EnablementStatus, "agentero.portal.GetAgenciesRequest.Filters.EnablementStatus", [
  { no: 0, name: "ENABLEMENT_STATUS_UNSPECIFIED" },
  { no: 1, name: "ENABLEMENT_STATUS_ENABLED" },
  { no: 2, name: "ENABLEMENT_STATUS_DISABLED" },
]);

/**
 * @generated from message agentero.portal.GetAgenciesRequest.OrderBy
 */
export class GetAgenciesRequest_OrderBy extends Message<GetAgenciesRequest_OrderBy> {
  /**
   * @generated from field: agentero.portal.GetAgenciesRequest.OrderBy.OrderByField field = 1;
   */
  field = GetAgenciesRequest_OrderBy_OrderByField.DEFAULT;

  /**
   * @generated from field: agentero.portal.Direction direction = 2;
   */
  direction = Direction.ASC;

  constructor(data?: PartialMessage<GetAgenciesRequest_OrderBy>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetAgenciesRequest.OrderBy";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "field", kind: "enum", T: proto3.getEnumType(GetAgenciesRequest_OrderBy_OrderByField) },
    { no: 2, name: "direction", kind: "enum", T: proto3.getEnumType(Direction) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAgenciesRequest_OrderBy {
    return new GetAgenciesRequest_OrderBy().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAgenciesRequest_OrderBy {
    return new GetAgenciesRequest_OrderBy().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAgenciesRequest_OrderBy {
    return new GetAgenciesRequest_OrderBy().fromJsonString(jsonString, options);
  }

  static equals(a: GetAgenciesRequest_OrderBy | PlainMessage<GetAgenciesRequest_OrderBy> | undefined, b: GetAgenciesRequest_OrderBy | PlainMessage<GetAgenciesRequest_OrderBy> | undefined): boolean {
    return proto3.util.equals(GetAgenciesRequest_OrderBy, a, b);
  }
}

/**
 * @generated from enum agentero.portal.GetAgenciesRequest.OrderBy.OrderByField
 */
export enum GetAgenciesRequest_OrderBy_OrderByField {
  /**
   * @generated from enum value: DEFAULT = 0;
   */
  DEFAULT = 0,

  /**
   * @generated from enum value: NAME = 1;
   */
  NAME = 1,

  /**
   * @generated from enum value: EMAIL = 2;
   */
  EMAIL = 2,

  /**
   * @generated from enum value: CREATED_AT = 3;
   */
  CREATED_AT = 3,

  /**
   * @generated from enum value: EO_UPDATED_AT = 4;
   */
  EO_UPDATED_AT = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(GetAgenciesRequest_OrderBy_OrderByField)
proto3.util.setEnumType(GetAgenciesRequest_OrderBy_OrderByField, "agentero.portal.GetAgenciesRequest.OrderBy.OrderByField", [
  { no: 0, name: "DEFAULT" },
  { no: 1, name: "NAME" },
  { no: 2, name: "EMAIL" },
  { no: 3, name: "CREATED_AT" },
  { no: 4, name: "EO_UPDATED_AT" },
]);

/**
 * @generated from message agentero.portal.GetAgenciesResponse
 */
export class GetAgenciesResponse extends Message<GetAgenciesResponse> {
  /**
   * @generated from field: repeated agentero.portal.AgencyDetails agencies = 1;
   */
  agencies: AgencyDetails[] = [];

  /**
   * @generated from field: int64 total_agencies = 2;
   */
  totalAgencies = protoInt64.zero;

  constructor(data?: PartialMessage<GetAgenciesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetAgenciesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agencies", kind: "message", T: AgencyDetails, repeated: true },
    { no: 2, name: "total_agencies", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAgenciesResponse {
    return new GetAgenciesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAgenciesResponse {
    return new GetAgenciesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAgenciesResponse {
    return new GetAgenciesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAgenciesResponse | PlainMessage<GetAgenciesResponse> | undefined, b: GetAgenciesResponse | PlainMessage<GetAgenciesResponse> | undefined): boolean {
    return proto3.util.equals(GetAgenciesResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetAgenciesSummaryRequest
 */
export class GetAgenciesSummaryRequest extends Message<GetAgenciesSummaryRequest> {
  constructor(data?: PartialMessage<GetAgenciesSummaryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetAgenciesSummaryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAgenciesSummaryRequest {
    return new GetAgenciesSummaryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAgenciesSummaryRequest {
    return new GetAgenciesSummaryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAgenciesSummaryRequest {
    return new GetAgenciesSummaryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAgenciesSummaryRequest | PlainMessage<GetAgenciesSummaryRequest> | undefined, b: GetAgenciesSummaryRequest | PlainMessage<GetAgenciesSummaryRequest> | undefined): boolean {
    return proto3.util.equals(GetAgenciesSummaryRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetAgenciesSummaryResponse
 */
export class GetAgenciesSummaryResponse extends Message<GetAgenciesSummaryResponse> {
  /**
   * @generated from field: int64 total_agencies = 1;
   */
  totalAgencies = protoInt64.zero;

  /**
   * @generated from field: int64 total_signups_this_month = 2;
   */
  totalSignupsThisMonth = protoInt64.zero;

  /**
   * @generated from field: int64 total_aggregator_agencies = 3;
   */
  totalAggregatorAgencies = protoInt64.zero;

  /**
   * @generated from field: int64 total_disabled_agencies = 4;
   */
  totalDisabledAgencies = protoInt64.zero;

  constructor(data?: PartialMessage<GetAgenciesSummaryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetAgenciesSummaryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_agencies", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total_signups_this_month", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "total_aggregator_agencies", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "total_disabled_agencies", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAgenciesSummaryResponse {
    return new GetAgenciesSummaryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAgenciesSummaryResponse {
    return new GetAgenciesSummaryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAgenciesSummaryResponse {
    return new GetAgenciesSummaryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAgenciesSummaryResponse | PlainMessage<GetAgenciesSummaryResponse> | undefined, b: GetAgenciesSummaryResponse | PlainMessage<GetAgenciesSummaryResponse> | undefined): boolean {
    return proto3.util.equals(GetAgenciesSummaryResponse, a, b);
  }
}

/**
 * CheckAgencyEmailEligibilityRequest defines request to check agency email eligibility
 *
 * @generated from message agentero.portal.CheckAgencyEmailEligibilityRequest
 */
export class CheckAgencyEmailEligibilityRequest extends Message<CheckAgencyEmailEligibilityRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string agency_id = 2;
   */
  agencyId = "";

  constructor(data?: PartialMessage<CheckAgencyEmailEligibilityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.CheckAgencyEmailEligibilityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckAgencyEmailEligibilityRequest {
    return new CheckAgencyEmailEligibilityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckAgencyEmailEligibilityRequest {
    return new CheckAgencyEmailEligibilityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckAgencyEmailEligibilityRequest {
    return new CheckAgencyEmailEligibilityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CheckAgencyEmailEligibilityRequest | PlainMessage<CheckAgencyEmailEligibilityRequest> | undefined, b: CheckAgencyEmailEligibilityRequest | PlainMessage<CheckAgencyEmailEligibilityRequest> | undefined): boolean {
    return proto3.util.equals(CheckAgencyEmailEligibilityRequest, a, b);
  }
}

/**
 * CheckAgencyEmailEligibilityResponse defines request to check agency email eligibility
 *
 * @generated from message agentero.portal.CheckAgencyEmailEligibilityResponse
 */
export class CheckAgencyEmailEligibilityResponse extends Message<CheckAgencyEmailEligibilityResponse> {
  /**
   * @generated from field: bool eligible = 1;
   */
  eligible = false;

  constructor(data?: PartialMessage<CheckAgencyEmailEligibilityResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.CheckAgencyEmailEligibilityResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "eligible", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckAgencyEmailEligibilityResponse {
    return new CheckAgencyEmailEligibilityResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckAgencyEmailEligibilityResponse {
    return new CheckAgencyEmailEligibilityResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckAgencyEmailEligibilityResponse {
    return new CheckAgencyEmailEligibilityResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CheckAgencyEmailEligibilityResponse | PlainMessage<CheckAgencyEmailEligibilityResponse> | undefined, b: CheckAgencyEmailEligibilityResponse | PlainMessage<CheckAgencyEmailEligibilityResponse> | undefined): boolean {
    return proto3.util.equals(CheckAgencyEmailEligibilityResponse, a, b);
  }
}

/**
 * SaveCarriersConfigRequest defines request to save carriers config.
 *
 * @generated from message agentero.portal.SaveCarriersConfigRequest
 */
export class SaveCarriersConfigRequest extends Message<SaveCarriersConfigRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: repeated agentero.portal.CarrierConfig configs = 2;
   */
  configs: CarrierConfig[] = [];

  /**
   * @generated from field: bool redirect_to_market_access = 3;
   */
  redirectToMarketAccess = false;

  constructor(data?: PartialMessage<SaveCarriersConfigRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.SaveCarriersConfigRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "configs", kind: "message", T: CarrierConfig, repeated: true },
    { no: 3, name: "redirect_to_market_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveCarriersConfigRequest {
    return new SaveCarriersConfigRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveCarriersConfigRequest {
    return new SaveCarriersConfigRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveCarriersConfigRequest {
    return new SaveCarriersConfigRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SaveCarriersConfigRequest | PlainMessage<SaveCarriersConfigRequest> | undefined, b: SaveCarriersConfigRequest | PlainMessage<SaveCarriersConfigRequest> | undefined): boolean {
    return proto3.util.equals(SaveCarriersConfigRequest, a, b);
  }
}

/**
 * GenerateUploadURLRequest requests url to upload report file
 *
 * @generated from message agentero.portal.GenerateUploadURLRequest
 */
export class GenerateUploadURLRequest extends Message<GenerateUploadURLRequest> {
  /**
   * @generated from field: agentero.portal.GenerateUploadURLRequest.FileType file_type = 1;
   */
  fileType = GenerateUploadURLRequest_FileType.PDF;

  /**
   * @generated from field: string period = 2;
   */
  period = "";

  /**
   * @generated from field: string agency_id = 3;
   */
  agencyId = "";

  constructor(data?: PartialMessage<GenerateUploadURLRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GenerateUploadURLRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "file_type", kind: "enum", T: proto3.getEnumType(GenerateUploadURLRequest_FileType) },
    { no: 2, name: "period", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateUploadURLRequest {
    return new GenerateUploadURLRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateUploadURLRequest {
    return new GenerateUploadURLRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateUploadURLRequest {
    return new GenerateUploadURLRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateUploadURLRequest | PlainMessage<GenerateUploadURLRequest> | undefined, b: GenerateUploadURLRequest | PlainMessage<GenerateUploadURLRequest> | undefined): boolean {
    return proto3.util.equals(GenerateUploadURLRequest, a, b);
  }
}

/**
 * @generated from enum agentero.portal.GenerateUploadURLRequest.FileType
 */
export enum GenerateUploadURLRequest_FileType {
  /**
   * @generated from enum value: PDF = 0;
   */
  PDF = 0,
}
// Retrieve enum metadata with: proto3.getEnumType(GenerateUploadURLRequest_FileType)
proto3.util.setEnumType(GenerateUploadURLRequest_FileType, "agentero.portal.GenerateUploadURLRequest.FileType", [
  { no: 0, name: "PDF" },
]);

/**
 * GenerateUploadURLRequest response url to upload report file
 *
 * @generated from message agentero.portal.GenerateUploadURLResponse
 */
export class GenerateUploadURLResponse extends Message<GenerateUploadURLResponse> {
  /**
   * @generated from field: string report_file_url = 1;
   */
  reportFileUrl = "";

  /**
   * @generated from field: string expected_content_type = 2;
   */
  expectedContentType = "";

  constructor(data?: PartialMessage<GenerateUploadURLResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GenerateUploadURLResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_file_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "expected_content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateUploadURLResponse {
    return new GenerateUploadURLResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateUploadURLResponse {
    return new GenerateUploadURLResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateUploadURLResponse {
    return new GenerateUploadURLResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateUploadURLResponse | PlainMessage<GenerateUploadURLResponse> | undefined, b: GenerateUploadURLResponse | PlainMessage<GenerateUploadURLResponse> | undefined): boolean {
    return proto3.util.equals(GenerateUploadURLResponse, a, b);
  }
}

/**
 * Commission defines commission details.
 *
 * @generated from message agentero.portal.Commission
 */
export class Commission extends Message<Commission> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string agency_id = 2;
   */
  agencyId = "";

  /**
   * @generated from field: google.protobuf.Timestamp period = 3;
   */
  period?: Timestamp;

  /**
   * @generated from field: string report_path = 4;
   */
  reportPath = "";

  /**
   * @generated from field: double amount = 5;
   */
  amount = 0;

  /**
   * @generated from field: google.protobuf.Timestamp uploaded_at = 6;
   */
  uploadedAt?: Timestamp;

  constructor(data?: PartialMessage<Commission>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.Commission";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "period", kind: "message", T: Timestamp },
    { no: 4, name: "report_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "uploaded_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Commission {
    return new Commission().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Commission {
    return new Commission().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Commission {
    return new Commission().fromJsonString(jsonString, options);
  }

  static equals(a: Commission | PlainMessage<Commission> | undefined, b: Commission | PlainMessage<Commission> | undefined): boolean {
    return proto3.util.equals(Commission, a, b);
  }
}

/**
 * @generated from message agentero.portal.Agent
 */
export class Agent extends Message<Agent> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string agency_id = 2;
   */
  agencyId = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string full_name = 4;
   */
  fullName = "";

  /**
   * @generated from field: bool agency_admin = 5;
   */
  agencyAdmin = false;

  /**
   * @generated from field: string photo_url = 6;
   */
  photoUrl = "";

  constructor(data?: PartialMessage<Agent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.Agent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "agency_admin", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "photo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Agent {
    return new Agent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Agent {
    return new Agent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Agent {
    return new Agent().fromJsonString(jsonString, options);
  }

  static equals(a: Agent | PlainMessage<Agent> | undefined, b: Agent | PlainMessage<Agent> | undefined): boolean {
    return proto3.util.equals(Agent, a, b);
  }
}

/**
 * @generated from message agentero.portal.DefaultAgent
 */
export class DefaultAgent extends Message<DefaultAgent> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string first_name = 2;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 3;
   */
  lastName = "";

  constructor(data?: PartialMessage<DefaultAgent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.DefaultAgent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DefaultAgent {
    return new DefaultAgent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DefaultAgent {
    return new DefaultAgent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DefaultAgent {
    return new DefaultAgent().fromJsonString(jsonString, options);
  }

  static equals(a: DefaultAgent | PlainMessage<DefaultAgent> | undefined, b: DefaultAgent | PlainMessage<DefaultAgent> | undefined): boolean {
    return proto3.util.equals(DefaultAgent, a, b);
  }
}

/**
 * ListCommissionsRequest requests commissions by agency id.
 *
 * @generated from message agentero.portal.ListCommissionsRequest
 */
export class ListCommissionsRequest extends Message<ListCommissionsRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<ListCommissionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.ListCommissionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCommissionsRequest {
    return new ListCommissionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCommissionsRequest {
    return new ListCommissionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCommissionsRequest {
    return new ListCommissionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListCommissionsRequest | PlainMessage<ListCommissionsRequest> | undefined, b: ListCommissionsRequest | PlainMessage<ListCommissionsRequest> | undefined): boolean {
    return proto3.util.equals(ListCommissionsRequest, a, b);
  }
}

/**
 * ListCommissionsResponse returns commissions  by agency id.
 *
 * @generated from message agentero.portal.ListCommissionsResponse
 */
export class ListCommissionsResponse extends Message<ListCommissionsResponse> {
  /**
   * @generated from field: repeated agentero.portal.Commission commissions = 1;
   */
  commissions: Commission[] = [];

  /**
   * @generated from field: int64 total_commissions = 2;
   */
  totalCommissions = protoInt64.zero;

  constructor(data?: PartialMessage<ListCommissionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.ListCommissionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "commissions", kind: "message", T: Commission, repeated: true },
    { no: 2, name: "total_commissions", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCommissionsResponse {
    return new ListCommissionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCommissionsResponse {
    return new ListCommissionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCommissionsResponse {
    return new ListCommissionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListCommissionsResponse | PlainMessage<ListCommissionsResponse> | undefined, b: ListCommissionsResponse | PlainMessage<ListCommissionsResponse> | undefined): boolean {
    return proto3.util.equals(ListCommissionsResponse, a, b);
  }
}

/**
 * CreateCommissionsRequest create or update a commission.
 *
 * @generated from message agentero.portal.CreateCommissionRequest
 */
export class CreateCommissionRequest extends Message<CreateCommissionRequest> {
  /**
   * @generated from field: agentero.portal.Commission commission = 1;
   */
  commission?: Commission;

  constructor(data?: PartialMessage<CreateCommissionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.CreateCommissionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "commission", kind: "message", T: Commission },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCommissionRequest {
    return new CreateCommissionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCommissionRequest {
    return new CreateCommissionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCommissionRequest {
    return new CreateCommissionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCommissionRequest | PlainMessage<CreateCommissionRequest> | undefined, b: CreateCommissionRequest | PlainMessage<CreateCommissionRequest> | undefined): boolean {
    return proto3.util.equals(CreateCommissionRequest, a, b);
  }
}

/**
 * CreateCommissionsResponse returns the commission identification
 *
 * @generated from message agentero.portal.CreateCommissionResponse
 */
export class CreateCommissionResponse extends Message<CreateCommissionResponse> {
  /**
   * @generated from field: string commission_id = 1;
   */
  commissionId = "";

  constructor(data?: PartialMessage<CreateCommissionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.CreateCommissionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "commission_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCommissionResponse {
    return new CreateCommissionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCommissionResponse {
    return new CreateCommissionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCommissionResponse {
    return new CreateCommissionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCommissionResponse | PlainMessage<CreateCommissionResponse> | undefined, b: CreateCommissionResponse | PlainMessage<CreateCommissionResponse> | undefined): boolean {
    return proto3.util.equals(CreateCommissionResponse, a, b);
  }
}

/**
 * DeleteCommissionRequest  delete a commission.
 *
 * @generated from message agentero.portal.DeleteCommissionRequest
 */
export class DeleteCommissionRequest extends Message<DeleteCommissionRequest> {
  /**
   * @generated from field: string commission_id = 1;
   */
  commissionId = "";

  constructor(data?: PartialMessage<DeleteCommissionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.DeleteCommissionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "commission_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteCommissionRequest {
    return new DeleteCommissionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteCommissionRequest {
    return new DeleteCommissionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteCommissionRequest {
    return new DeleteCommissionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteCommissionRequest | PlainMessage<DeleteCommissionRequest> | undefined, b: DeleteCommissionRequest | PlainMessage<DeleteCommissionRequest> | undefined): boolean {
    return proto3.util.equals(DeleteCommissionRequest, a, b);
  }
}

/**
 * DeleteCommissionResponse returns the commission identification
 *
 * @generated from message agentero.portal.DeleteCommissionResponse
 */
export class DeleteCommissionResponse extends Message<DeleteCommissionResponse> {
  /**
   * @generated from field: string commission_id = 1;
   */
  commissionId = "";

  constructor(data?: PartialMessage<DeleteCommissionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.DeleteCommissionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "commission_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteCommissionResponse {
    return new DeleteCommissionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteCommissionResponse {
    return new DeleteCommissionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteCommissionResponse {
    return new DeleteCommissionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteCommissionResponse | PlainMessage<DeleteCommissionResponse> | undefined, b: DeleteCommissionResponse | PlainMessage<DeleteCommissionResponse> | undefined): boolean {
    return proto3.util.equals(DeleteCommissionResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetAgencyRequest
 */
export class GetAgencyRequest extends Message<GetAgencyRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<GetAgencyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetAgencyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAgencyRequest {
    return new GetAgencyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAgencyRequest {
    return new GetAgencyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAgencyRequest {
    return new GetAgencyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAgencyRequest | PlainMessage<GetAgencyRequest> | undefined, b: GetAgencyRequest | PlainMessage<GetAgencyRequest> | undefined): boolean {
    return proto3.util.equals(GetAgencyRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetAgencyResponse
 */
export class GetAgencyResponse extends Message<GetAgencyResponse> {
  /**
   * @generated from field: agentero.portal.Agency agency = 1;
   */
  agency?: Agency;

  constructor(data?: PartialMessage<GetAgencyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetAgencyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency", kind: "message", T: Agency },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAgencyResponse {
    return new GetAgencyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAgencyResponse {
    return new GetAgencyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAgencyResponse {
    return new GetAgencyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAgencyResponse | PlainMessage<GetAgencyResponse> | undefined, b: GetAgencyResponse | PlainMessage<GetAgencyResponse> | undefined): boolean {
    return proto3.util.equals(GetAgencyResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetAgencyMembersRequest
 */
export class GetAgencyMembersRequest extends Message<GetAgencyMembersRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<GetAgencyMembersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetAgencyMembersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAgencyMembersRequest {
    return new GetAgencyMembersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAgencyMembersRequest {
    return new GetAgencyMembersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAgencyMembersRequest {
    return new GetAgencyMembersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAgencyMembersRequest | PlainMessage<GetAgencyMembersRequest> | undefined, b: GetAgencyMembersRequest | PlainMessage<GetAgencyMembersRequest> | undefined): boolean {
    return proto3.util.equals(GetAgencyMembersRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetAgencyMembersResponse
 */
export class GetAgencyMembersResponse extends Message<GetAgencyMembersResponse> {
  /**
   * @generated from field: repeated agentero.portal.Agent agents = 1;
   */
  agents: Agent[] = [];

  constructor(data?: PartialMessage<GetAgencyMembersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetAgencyMembersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agents", kind: "message", T: Agent, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAgencyMembersResponse {
    return new GetAgencyMembersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAgencyMembersResponse {
    return new GetAgencyMembersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAgencyMembersResponse {
    return new GetAgencyMembersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAgencyMembersResponse | PlainMessage<GetAgencyMembersResponse> | undefined, b: GetAgencyMembersResponse | PlainMessage<GetAgencyMembersResponse> | undefined): boolean {
    return proto3.util.equals(GetAgencyMembersResponse, a, b);
  }
}

/**
 * DeleteAgency defines request to delete agency.
 *
 * @generated from message agentero.portal.DeleteAgencyRequest
 */
export class DeleteAgencyRequest extends Message<DeleteAgencyRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<DeleteAgencyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.DeleteAgencyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAgencyRequest {
    return new DeleteAgencyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAgencyRequest {
    return new DeleteAgencyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteAgencyRequest {
    return new DeleteAgencyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteAgencyRequest | PlainMessage<DeleteAgencyRequest> | undefined, b: DeleteAgencyRequest | PlainMessage<DeleteAgencyRequest> | undefined): boolean {
    return proto3.util.equals(DeleteAgencyRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.CarrierAppointmentUrl
 */
export class CarrierAppointmentUrl extends Message<CarrierAppointmentUrl> {
  /**
   * @generated from field: string portal = 1;
   */
  portal = "";

  /**
   * @generated from field: string documentation = 2;
   */
  documentation = "";

  /**
   * @generated from field: string transfer = 3;
   */
  transfer = "";

  constructor(data?: PartialMessage<CarrierAppointmentUrl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.CarrierAppointmentUrl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "portal", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "documentation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "transfer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CarrierAppointmentUrl {
    return new CarrierAppointmentUrl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CarrierAppointmentUrl {
    return new CarrierAppointmentUrl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CarrierAppointmentUrl {
    return new CarrierAppointmentUrl().fromJsonString(jsonString, options);
  }

  static equals(a: CarrierAppointmentUrl | PlainMessage<CarrierAppointmentUrl> | undefined, b: CarrierAppointmentUrl | PlainMessage<CarrierAppointmentUrl> | undefined): boolean {
    return proto3.util.equals(CarrierAppointmentUrl, a, b);
  }
}

/**
 * @generated from message agentero.portal.CarrierAppointment
 */
export class CarrierAppointment extends Message<CarrierAppointment> {
  /**
   * @generated from field: agentero.carrier.Carrier carrier = 1;
   */
  carrier = Carrier.UNDEFINED;

  /**
   * @generated from field: agentero.carrier.AppointmentStatus status = 2;
   */
  status = AppointmentStatus.UNDEFINED_STATUS;

  /**
   * @generated from field: google.protobuf.Timestamp release_date = 3;
   */
  releaseDate?: Timestamp;

  /**
   * @generated from field: repeated agentero.policy.InsuranceType lobs = 4;
   */
  lobs: InsuranceType[] = [];

  /**
   * @generated from field: agentero.portal.CarrierAppointmentUrl url = 5;
   */
  url?: CarrierAppointmentUrl;

  /**
   * @generated from field: bool is_quotable = 6;
   */
  isQuotable = false;

  /**
   * @generated from field: repeated agentero.policy.InsuranceType quotable_lobs = 8;
   */
  quotableLobs: InsuranceType[] = [];

  /**
   * @generated from field: agentero.portal.CarrierAppointment.CarrierCredentials carrier_credentials = 9;
   */
  carrierCredentials?: CarrierAppointment_CarrierCredentials;

  /**
   * @generated from field: bool is_binding_with_agentero = 11;
   */
  isBindingWithAgentero = false;

  /**
   * @generated from field: bool is_eligibility_hidden = 13;
   */
  isEligibilityHidden = false;

  /**
   * @generated from field: bool is_hidden_on_appointments = 14;
   */
  isHiddenOnAppointments = false;

  /**
   * @generated from field: bool is_appointable = 15;
   */
  isAppointable = false;

  constructor(data?: PartialMessage<CarrierAppointment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.CarrierAppointment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(AppointmentStatus) },
    { no: 3, name: "release_date", kind: "message", T: Timestamp },
    { no: 4, name: "lobs", kind: "enum", T: proto3.getEnumType(InsuranceType), repeated: true },
    { no: 5, name: "url", kind: "message", T: CarrierAppointmentUrl },
    { no: 6, name: "is_quotable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "quotable_lobs", kind: "enum", T: proto3.getEnumType(InsuranceType), repeated: true },
    { no: 9, name: "carrier_credentials", kind: "message", T: CarrierAppointment_CarrierCredentials },
    { no: 11, name: "is_binding_with_agentero", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "is_eligibility_hidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "is_hidden_on_appointments", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "is_appointable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CarrierAppointment {
    return new CarrierAppointment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CarrierAppointment {
    return new CarrierAppointment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CarrierAppointment {
    return new CarrierAppointment().fromJsonString(jsonString, options);
  }

  static equals(a: CarrierAppointment | PlainMessage<CarrierAppointment> | undefined, b: CarrierAppointment | PlainMessage<CarrierAppointment> | undefined): boolean {
    return proto3.util.equals(CarrierAppointment, a, b);
  }
}

/**
 * @generated from message agentero.portal.CarrierAppointment.CarrierCredentials
 */
export class CarrierAppointment_CarrierCredentials extends Message<CarrierAppointment_CarrierCredentials> {
  /**
   * @generated from field: bool are_managed_by_agent = 3;
   */
  areManagedByAgent = false;

  /**
   * @generated from field: bool has_correct_credentials = 4;
   */
  hasCorrectCredentials = false;

  constructor(data?: PartialMessage<CarrierAppointment_CarrierCredentials>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.CarrierAppointment.CarrierCredentials";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "are_managed_by_agent", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "has_correct_credentials", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CarrierAppointment_CarrierCredentials {
    return new CarrierAppointment_CarrierCredentials().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CarrierAppointment_CarrierCredentials {
    return new CarrierAppointment_CarrierCredentials().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CarrierAppointment_CarrierCredentials {
    return new CarrierAppointment_CarrierCredentials().fromJsonString(jsonString, options);
  }

  static equals(a: CarrierAppointment_CarrierCredentials | PlainMessage<CarrierAppointment_CarrierCredentials> | undefined, b: CarrierAppointment_CarrierCredentials | PlainMessage<CarrierAppointment_CarrierCredentials> | undefined): boolean {
    return proto3.util.equals(CarrierAppointment_CarrierCredentials, a, b);
  }
}

/**
 * @generated from message agentero.portal.RequestCarrierAppointmentsTransferRequest
 */
export class RequestCarrierAppointmentsTransferRequest extends Message<RequestCarrierAppointmentsTransferRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: agentero.carrier.Carrier carrier = 2;
   */
  carrier = Carrier.UNDEFINED;

  constructor(data?: PartialMessage<RequestCarrierAppointmentsTransferRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.RequestCarrierAppointmentsTransferRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequestCarrierAppointmentsTransferRequest {
    return new RequestCarrierAppointmentsTransferRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequestCarrierAppointmentsTransferRequest {
    return new RequestCarrierAppointmentsTransferRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequestCarrierAppointmentsTransferRequest {
    return new RequestCarrierAppointmentsTransferRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RequestCarrierAppointmentsTransferRequest | PlainMessage<RequestCarrierAppointmentsTransferRequest> | undefined, b: RequestCarrierAppointmentsTransferRequest | PlainMessage<RequestCarrierAppointmentsTransferRequest> | undefined): boolean {
    return proto3.util.equals(RequestCarrierAppointmentsTransferRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.RequestCarrierAppointmentsPortalRequest
 */
export class RequestCarrierAppointmentsPortalRequest extends Message<RequestCarrierAppointmentsPortalRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: agentero.carrier.Carrier carrier = 2;
   */
  carrier = Carrier.UNDEFINED;

  constructor(data?: PartialMessage<RequestCarrierAppointmentsPortalRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.RequestCarrierAppointmentsPortalRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequestCarrierAppointmentsPortalRequest {
    return new RequestCarrierAppointmentsPortalRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequestCarrierAppointmentsPortalRequest {
    return new RequestCarrierAppointmentsPortalRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequestCarrierAppointmentsPortalRequest {
    return new RequestCarrierAppointmentsPortalRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RequestCarrierAppointmentsPortalRequest | PlainMessage<RequestCarrierAppointmentsPortalRequest> | undefined, b: RequestCarrierAppointmentsPortalRequest | PlainMessage<RequestCarrierAppointmentsPortalRequest> | undefined): boolean {
    return proto3.util.equals(RequestCarrierAppointmentsPortalRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.RequestCarrierAppointmentsPortalResponse
 */
export class RequestCarrierAppointmentsPortalResponse extends Message<RequestCarrierAppointmentsPortalResponse> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<RequestCarrierAppointmentsPortalResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.RequestCarrierAppointmentsPortalResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequestCarrierAppointmentsPortalResponse {
    return new RequestCarrierAppointmentsPortalResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequestCarrierAppointmentsPortalResponse {
    return new RequestCarrierAppointmentsPortalResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequestCarrierAppointmentsPortalResponse {
    return new RequestCarrierAppointmentsPortalResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RequestCarrierAppointmentsPortalResponse | PlainMessage<RequestCarrierAppointmentsPortalResponse> | undefined, b: RequestCarrierAppointmentsPortalResponse | PlainMessage<RequestCarrierAppointmentsPortalResponse> | undefined): boolean {
    return proto3.util.equals(RequestCarrierAppointmentsPortalResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateAgencyContactRequest
 */
export class UpdateAgencyContactRequest extends Message<UpdateAgencyContactRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string phone_number = 4;
   */
  phoneNumber = "";

  /**
   * @generated from field: agentero.contact.Address address = 5;
   */
  address?: Address;

  /**
   * @generated from field: string photo_url = 6;
   */
  photoUrl = "";

  /**
   * @generated from field: string web_url = 7;
   */
  webUrl = "";

  constructor(data?: PartialMessage<UpdateAgencyContactRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateAgencyContactRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "address", kind: "message", T: Address },
    { no: 6, name: "photo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "web_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAgencyContactRequest {
    return new UpdateAgencyContactRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAgencyContactRequest {
    return new UpdateAgencyContactRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAgencyContactRequest {
    return new UpdateAgencyContactRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAgencyContactRequest | PlainMessage<UpdateAgencyContactRequest> | undefined, b: UpdateAgencyContactRequest | PlainMessage<UpdateAgencyContactRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAgencyContactRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateAgencySettingsRequest
 */
export class UpdateAgencySettingsRequest extends Message<UpdateAgencySettingsRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string formstack_name = 2;
   */
  formstackName = "";

  /**
   * @generated from field: string sales_force_id = 3;
   */
  salesForceId = "";

  constructor(data?: PartialMessage<UpdateAgencySettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateAgencySettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "formstack_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sales_force_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAgencySettingsRequest {
    return new UpdateAgencySettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAgencySettingsRequest {
    return new UpdateAgencySettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAgencySettingsRequest {
    return new UpdateAgencySettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAgencySettingsRequest | PlainMessage<UpdateAgencySettingsRequest> | undefined, b: UpdateAgencySettingsRequest | PlainMessage<UpdateAgencySettingsRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAgencySettingsRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateAgencyInfoRequest
 */
export class UpdateAgencyInfoRequest extends Message<UpdateAgencyInfoRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: agentero.portal.ComparativeRater comparative_rater = 2;
   */
  comparativeRater?: ComparativeRater;

  /**
   * @generated from field: repeated string lines_of_business = 3;
   */
  linesOfBusiness: string[] = [];

  /**
   * @generated from field: repeated string states = 4;
   */
  states: string[] = [];

  /**
   * @generated from field: string referral_page = 5;
   */
  referralPage = "";

  /**
   * @generated from field: string default_agent_id = 7;
   */
  defaultAgentId = "";

  /**
   * @generated from field: agentero.portal.OperateAs operate_as = 8;
   */
  operateAs = OperateAs.UNSPECIFIED;

  /**
   * @generated from field: bool security_check_passed = 9;
   */
  securityCheckPassed = false;

  constructor(data?: PartialMessage<UpdateAgencyInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateAgencyInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "comparative_rater", kind: "message", T: ComparativeRater },
    { no: 3, name: "lines_of_business", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "states", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "referral_page", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "default_agent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "operate_as", kind: "enum", T: proto3.getEnumType(OperateAs) },
    { no: 9, name: "security_check_passed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAgencyInfoRequest {
    return new UpdateAgencyInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAgencyInfoRequest {
    return new UpdateAgencyInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAgencyInfoRequest {
    return new UpdateAgencyInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAgencyInfoRequest | PlainMessage<UpdateAgencyInfoRequest> | undefined, b: UpdateAgencyInfoRequest | PlainMessage<UpdateAgencyInfoRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAgencyInfoRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateAgencyResponse
 */
export class UpdateAgencyResponse extends Message<UpdateAgencyResponse> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<UpdateAgencyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateAgencyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAgencyResponse {
    return new UpdateAgencyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAgencyResponse {
    return new UpdateAgencyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAgencyResponse {
    return new UpdateAgencyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAgencyResponse | PlainMessage<UpdateAgencyResponse> | undefined, b: UpdateAgencyResponse | PlainMessage<UpdateAgencyResponse> | undefined): boolean {
    return proto3.util.equals(UpdateAgencyResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateAgencyTOSRequest
 */
export class UpdateAgencyTOSRequest extends Message<UpdateAgencyTOSRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: bool tos_accepted = 2;
   */
  tosAccepted = false;

  constructor(data?: PartialMessage<UpdateAgencyTOSRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateAgencyTOSRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tos_accepted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAgencyTOSRequest {
    return new UpdateAgencyTOSRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAgencyTOSRequest {
    return new UpdateAgencyTOSRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAgencyTOSRequest {
    return new UpdateAgencyTOSRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAgencyTOSRequest | PlainMessage<UpdateAgencyTOSRequest> | undefined, b: UpdateAgencyTOSRequest | PlainMessage<UpdateAgencyTOSRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAgencyTOSRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateAgencyComplianceInformationRequest
 */
export class UpdateAgencyComplianceInformationRequest extends Message<UpdateAgencyComplianceInformationRequest> {
  /**
   * @generated from field: string npn = 1;
   */
  npn = "";

  /**
   * @generated from field: string fein = 2;
   */
  fein = "";

  /**
   * @generated from field: string license_number = 3;
   */
  licenseNumber = "";

  /**
   * @generated from field: string license_state = 4;
   */
  licenseState = "";

  /**
   * @generated from field: string agency_id = 5;
   */
  agencyId = "";

  /**
   * @generated from field: repeated agentero.license.LineOfAuthority lines_of_authority = 6;
   */
  linesOfAuthority: LineOfAuthority[] = [];

  /**
   * force update the agency compliance information forcing to retrieve the information from nipr
   *
   * @generated from field: bool force = 7;
   */
  force = false;

  constructor(data?: PartialMessage<UpdateAgencyComplianceInformationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateAgencyComplianceInformationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "npn", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fein", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "license_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "license_state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "lines_of_authority", kind: "enum", T: proto3.getEnumType(LineOfAuthority), repeated: true },
    { no: 7, name: "force", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAgencyComplianceInformationRequest {
    return new UpdateAgencyComplianceInformationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAgencyComplianceInformationRequest {
    return new UpdateAgencyComplianceInformationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAgencyComplianceInformationRequest {
    return new UpdateAgencyComplianceInformationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAgencyComplianceInformationRequest | PlainMessage<UpdateAgencyComplianceInformationRequest> | undefined, b: UpdateAgencyComplianceInformationRequest | PlainMessage<UpdateAgencyComplianceInformationRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAgencyComplianceInformationRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateAgencyComplianceInformationResponse
 */
export class UpdateAgencyComplianceInformationResponse extends Message<UpdateAgencyComplianceInformationResponse> {
  /**
   * @generated from field: bool is_bind_with_agentero_enabled = 1;
   */
  isBindWithAgenteroEnabled = false;

  constructor(data?: PartialMessage<UpdateAgencyComplianceInformationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateAgencyComplianceInformationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_bind_with_agentero_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAgencyComplianceInformationResponse {
    return new UpdateAgencyComplianceInformationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAgencyComplianceInformationResponse {
    return new UpdateAgencyComplianceInformationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAgencyComplianceInformationResponse {
    return new UpdateAgencyComplianceInformationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAgencyComplianceInformationResponse | PlainMessage<UpdateAgencyComplianceInformationResponse> | undefined, b: UpdateAgencyComplianceInformationResponse | PlainMessage<UpdateAgencyComplianceInformationResponse> | undefined): boolean {
    return proto3.util.equals(UpdateAgencyComplianceInformationResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateAgencyLinesOfAuthorityRequest
 */
export class UpdateAgencyLinesOfAuthorityRequest extends Message<UpdateAgencyLinesOfAuthorityRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: repeated agentero.license.LineOfAuthority lines_of_authority = 2;
   */
  linesOfAuthority: LineOfAuthority[] = [];

  constructor(data?: PartialMessage<UpdateAgencyLinesOfAuthorityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateAgencyLinesOfAuthorityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "lines_of_authority", kind: "enum", T: proto3.getEnumType(LineOfAuthority), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAgencyLinesOfAuthorityRequest {
    return new UpdateAgencyLinesOfAuthorityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAgencyLinesOfAuthorityRequest {
    return new UpdateAgencyLinesOfAuthorityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAgencyLinesOfAuthorityRequest {
    return new UpdateAgencyLinesOfAuthorityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAgencyLinesOfAuthorityRequest | PlainMessage<UpdateAgencyLinesOfAuthorityRequest> | undefined, b: UpdateAgencyLinesOfAuthorityRequest | PlainMessage<UpdateAgencyLinesOfAuthorityRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAgencyLinesOfAuthorityRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateAgencyLinesOfAuthorityResponse
 */
export class UpdateAgencyLinesOfAuthorityResponse extends Message<UpdateAgencyLinesOfAuthorityResponse> {
  /**
   * @generated from field: repeated agentero.license.LineOfAuthority lines_of_authority = 1;
   */
  linesOfAuthority: LineOfAuthority[] = [];

  constructor(data?: PartialMessage<UpdateAgencyLinesOfAuthorityResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateAgencyLinesOfAuthorityResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lines_of_authority", kind: "enum", T: proto3.getEnumType(LineOfAuthority), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAgencyLinesOfAuthorityResponse {
    return new UpdateAgencyLinesOfAuthorityResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAgencyLinesOfAuthorityResponse {
    return new UpdateAgencyLinesOfAuthorityResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAgencyLinesOfAuthorityResponse {
    return new UpdateAgencyLinesOfAuthorityResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAgencyLinesOfAuthorityResponse | PlainMessage<UpdateAgencyLinesOfAuthorityResponse> | undefined, b: UpdateAgencyLinesOfAuthorityResponse | PlainMessage<UpdateAgencyLinesOfAuthorityResponse> | undefined): boolean {
    return proto3.util.equals(UpdateAgencyLinesOfAuthorityResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.CreateAgencyRequest
 */
export class CreateAgencyRequest extends Message<CreateAgencyRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string phone_number = 3;
   */
  phoneNumber = "";

  /**
   * @generated from field: agentero.contact.Address address = 4;
   */
  address?: Address;

  /**
   * @generated from field: string photo_url = 5;
   */
  photoUrl = "";

  /**
   * links for the agency
   *
   * @generated from field: string web_url = 6;
   */
  webUrl = "";

  /**
   * @generated from field: string facebook_url = 7;
   */
  facebookUrl = "";

  /**
   * @generated from field: string linkedin_url = 8;
   */
  linkedinUrl = "";

  /**
   * @generated from field: agentero.portal.PlanType plan_type = 9;
   */
  planType = PlanType.STARTUP_AGENCY_50;

  /**
   * @generated from field: string sales_force_id = 10;
   */
  salesForceId = "";

  /**
   * @generated from field: repeated agentero.portal.LineOfBusiness lines_of_business = 12;
   */
  linesOfBusiness: LineOfBusiness[] = [];

  /**
   * @generated from field: repeated string states = 13;
   */
  states: string[] = [];

  constructor(data?: PartialMessage<CreateAgencyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.CreateAgencyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "address", kind: "message", T: Address },
    { no: 5, name: "photo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "web_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "facebook_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "linkedin_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "plan_type", kind: "enum", T: proto3.getEnumType(PlanType) },
    { no: 10, name: "sales_force_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "lines_of_business", kind: "enum", T: proto3.getEnumType(LineOfBusiness), repeated: true },
    { no: 13, name: "states", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAgencyRequest {
    return new CreateAgencyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAgencyRequest {
    return new CreateAgencyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAgencyRequest {
    return new CreateAgencyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAgencyRequest | PlainMessage<CreateAgencyRequest> | undefined, b: CreateAgencyRequest | PlainMessage<CreateAgencyRequest> | undefined): boolean {
    return proto3.util.equals(CreateAgencyRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.CreateAgencyResponse
 */
export class CreateAgencyResponse extends Message<CreateAgencyResponse> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<CreateAgencyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.CreateAgencyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAgencyResponse {
    return new CreateAgencyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAgencyResponse {
    return new CreateAgencyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAgencyResponse {
    return new CreateAgencyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAgencyResponse | PlainMessage<CreateAgencyResponse> | undefined, b: CreateAgencyResponse | PlainMessage<CreateAgencyResponse> | undefined): boolean {
    return proto3.util.equals(CreateAgencyResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.GenerateAgencyUploadURLRequest
 */
export class GenerateAgencyUploadURLRequest extends Message<GenerateAgencyUploadURLRequest> {
  /**
   * @generated from field: agentero.portal.GenerateAgencyUploadURLRequest.FileType file_type = 1;
   */
  fileType = GenerateAgencyUploadURLRequest_FileType.GIF;

  constructor(data?: PartialMessage<GenerateAgencyUploadURLRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GenerateAgencyUploadURLRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "file_type", kind: "enum", T: proto3.getEnumType(GenerateAgencyUploadURLRequest_FileType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateAgencyUploadURLRequest {
    return new GenerateAgencyUploadURLRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateAgencyUploadURLRequest {
    return new GenerateAgencyUploadURLRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateAgencyUploadURLRequest {
    return new GenerateAgencyUploadURLRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateAgencyUploadURLRequest | PlainMessage<GenerateAgencyUploadURLRequest> | undefined, b: GenerateAgencyUploadURLRequest | PlainMessage<GenerateAgencyUploadURLRequest> | undefined): boolean {
    return proto3.util.equals(GenerateAgencyUploadURLRequest, a, b);
  }
}

/**
 * @generated from enum agentero.portal.GenerateAgencyUploadURLRequest.FileType
 */
export enum GenerateAgencyUploadURLRequest_FileType {
  /**
   * @generated from enum value: GIF = 0;
   */
  GIF = 0,

  /**
   * @generated from enum value: PNG = 1;
   */
  PNG = 1,

  /**
   * @generated from enum value: JPG = 2;
   */
  JPG = 2,

  /**
   * @generated from enum value: JPEG = 3;
   */
  JPEG = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(GenerateAgencyUploadURLRequest_FileType)
proto3.util.setEnumType(GenerateAgencyUploadURLRequest_FileType, "agentero.portal.GenerateAgencyUploadURLRequest.FileType", [
  { no: 0, name: "GIF" },
  { no: 1, name: "PNG" },
  { no: 2, name: "JPG" },
  { no: 3, name: "JPEG" },
]);

/**
 * @generated from message agentero.portal.GenerateAgencyUploadURLResponse
 */
export class GenerateAgencyUploadURLResponse extends Message<GenerateAgencyUploadURLResponse> {
  /**
   * @generated from field: string agency_file_url = 1;
   */
  agencyFileUrl = "";

  /**
   * @generated from field: string agency_file_dir = 2;
   */
  agencyFileDir = "";

  /**
   * @generated from field: string expected_content_type = 3;
   */
  expectedContentType = "";

  constructor(data?: PartialMessage<GenerateAgencyUploadURLResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GenerateAgencyUploadURLResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_file_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "agency_file_dir", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "expected_content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateAgencyUploadURLResponse {
    return new GenerateAgencyUploadURLResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateAgencyUploadURLResponse {
    return new GenerateAgencyUploadURLResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateAgencyUploadURLResponse {
    return new GenerateAgencyUploadURLResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateAgencyUploadURLResponse | PlainMessage<GenerateAgencyUploadURLResponse> | undefined, b: GenerateAgencyUploadURLResponse | PlainMessage<GenerateAgencyUploadURLResponse> | undefined): boolean {
    return proto3.util.equals(GenerateAgencyUploadURLResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateAccessToCommercialRaterRequest
 */
export class UpdateAccessToCommercialRaterRequest extends Message<UpdateAccessToCommercialRaterRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: bool enabled = 2;
   */
  enabled = false;

  constructor(data?: PartialMessage<UpdateAccessToCommercialRaterRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateAccessToCommercialRaterRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAccessToCommercialRaterRequest {
    return new UpdateAccessToCommercialRaterRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAccessToCommercialRaterRequest {
    return new UpdateAccessToCommercialRaterRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAccessToCommercialRaterRequest {
    return new UpdateAccessToCommercialRaterRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAccessToCommercialRaterRequest | PlainMessage<UpdateAccessToCommercialRaterRequest> | undefined, b: UpdateAccessToCommercialRaterRequest | PlainMessage<UpdateAccessToCommercialRaterRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAccessToCommercialRaterRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.HasTerminatedAppointmentsRequest
 */
export class HasTerminatedAppointmentsRequest extends Message<HasTerminatedAppointmentsRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<HasTerminatedAppointmentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.HasTerminatedAppointmentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HasTerminatedAppointmentsRequest {
    return new HasTerminatedAppointmentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HasTerminatedAppointmentsRequest {
    return new HasTerminatedAppointmentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HasTerminatedAppointmentsRequest {
    return new HasTerminatedAppointmentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: HasTerminatedAppointmentsRequest | PlainMessage<HasTerminatedAppointmentsRequest> | undefined, b: HasTerminatedAppointmentsRequest | PlainMessage<HasTerminatedAppointmentsRequest> | undefined): boolean {
    return proto3.util.equals(HasTerminatedAppointmentsRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.HasTerminatedAppointmentsResponse
 */
export class HasTerminatedAppointmentsResponse extends Message<HasTerminatedAppointmentsResponse> {
  /**
   * @generated from field: bool terminated = 1;
   */
  terminated = false;

  constructor(data?: PartialMessage<HasTerminatedAppointmentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.HasTerminatedAppointmentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "terminated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HasTerminatedAppointmentsResponse {
    return new HasTerminatedAppointmentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HasTerminatedAppointmentsResponse {
    return new HasTerminatedAppointmentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HasTerminatedAppointmentsResponse {
    return new HasTerminatedAppointmentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: HasTerminatedAppointmentsResponse | PlainMessage<HasTerminatedAppointmentsResponse> | undefined, b: HasTerminatedAppointmentsResponse | PlainMessage<HasTerminatedAppointmentsResponse> | undefined): boolean {
    return proto3.util.equals(HasTerminatedAppointmentsResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateTerminateAppointmentsRequest
 */
export class UpdateTerminateAppointmentsRequest extends Message<UpdateTerminateAppointmentsRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: bool terminate = 2;
   */
  terminate = false;

  constructor(data?: PartialMessage<UpdateTerminateAppointmentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateTerminateAppointmentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "terminate", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTerminateAppointmentsRequest {
    return new UpdateTerminateAppointmentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTerminateAppointmentsRequest {
    return new UpdateTerminateAppointmentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTerminateAppointmentsRequest {
    return new UpdateTerminateAppointmentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTerminateAppointmentsRequest | PlainMessage<UpdateTerminateAppointmentsRequest> | undefined, b: UpdateTerminateAppointmentsRequest | PlainMessage<UpdateTerminateAppointmentsRequest> | undefined): boolean {
    return proto3.util.equals(UpdateTerminateAppointmentsRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetCarriersEligibilityRequest
 */
export class GetCarriersEligibilityRequest extends Message<GetCarriersEligibilityRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<GetCarriersEligibilityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetCarriersEligibilityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCarriersEligibilityRequest {
    return new GetCarriersEligibilityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCarriersEligibilityRequest {
    return new GetCarriersEligibilityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCarriersEligibilityRequest {
    return new GetCarriersEligibilityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCarriersEligibilityRequest | PlainMessage<GetCarriersEligibilityRequest> | undefined, b: GetCarriersEligibilityRequest | PlainMessage<GetCarriersEligibilityRequest> | undefined): boolean {
    return proto3.util.equals(GetCarriersEligibilityRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetCarriersEligibilityResponse
 */
export class GetCarriersEligibilityResponse extends Message<GetCarriersEligibilityResponse> {
  /**
   * @generated from field: repeated agentero.portal.CarriersEligibilityStatus carriers_eligibility_status = 1;
   */
  carriersEligibilityStatus: CarriersEligibilityStatus[] = [];

  constructor(data?: PartialMessage<GetCarriersEligibilityResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetCarriersEligibilityResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "carriers_eligibility_status", kind: "message", T: CarriersEligibilityStatus, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCarriersEligibilityResponse {
    return new GetCarriersEligibilityResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCarriersEligibilityResponse {
    return new GetCarriersEligibilityResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCarriersEligibilityResponse {
    return new GetCarriersEligibilityResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCarriersEligibilityResponse | PlainMessage<GetCarriersEligibilityResponse> | undefined, b: GetCarriersEligibilityResponse | PlainMessage<GetCarriersEligibilityResponse> | undefined): boolean {
    return proto3.util.equals(GetCarriersEligibilityResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.CarriersEligibilityStatus
 */
export class CarriersEligibilityStatus extends Message<CarriersEligibilityStatus> {
  /**
   * @generated from field: agentero.carrier.Carrier carrier = 1;
   */
  carrier = Carrier.UNDEFINED;

  /**
   * @generated from field: bool is_globally_hidden = 2;
   */
  isGloballyHidden = false;

  /**
   * @generated from field: bool is_agency_hidden = 3;
   */
  isAgencyHidden = false;

  /**
   * @generated from field: agentero.carrier.AppointmentStatus status = 4;
   */
  status = AppointmentStatus.UNDEFINED_STATUS;

  constructor(data?: PartialMessage<CarriersEligibilityStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.CarriersEligibilityStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
    { no: 2, name: "is_globally_hidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "is_agency_hidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "status", kind: "enum", T: proto3.getEnumType(AppointmentStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CarriersEligibilityStatus {
    return new CarriersEligibilityStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CarriersEligibilityStatus {
    return new CarriersEligibilityStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CarriersEligibilityStatus {
    return new CarriersEligibilityStatus().fromJsonString(jsonString, options);
  }

  static equals(a: CarriersEligibilityStatus | PlainMessage<CarriersEligibilityStatus> | undefined, b: CarriersEligibilityStatus | PlainMessage<CarriersEligibilityStatus> | undefined): boolean {
    return proto3.util.equals(CarriersEligibilityStatus, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateAppointmentsEnabledStatusRequest
 */
export class UpdateAppointmentsEnabledStatusRequest extends Message<UpdateAppointmentsEnabledStatusRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: bool enabled = 2;
   */
  enabled = false;

  constructor(data?: PartialMessage<UpdateAppointmentsEnabledStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateAppointmentsEnabledStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAppointmentsEnabledStatusRequest {
    return new UpdateAppointmentsEnabledStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAppointmentsEnabledStatusRequest {
    return new UpdateAppointmentsEnabledStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAppointmentsEnabledStatusRequest {
    return new UpdateAppointmentsEnabledStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAppointmentsEnabledStatusRequest | PlainMessage<UpdateAppointmentsEnabledStatusRequest> | undefined, b: UpdateAppointmentsEnabledStatusRequest | PlainMessage<UpdateAppointmentsEnabledStatusRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAppointmentsEnabledStatusRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.ComplianceError
 */
export class ComplianceError extends Message<ComplianceError> {
  /**
   * @generated from field: string message = 1;
   */
  message = "";

  /**
   * @generated from field: agentero.portal.ComplianceError.Level level = 2;
   */
  level = ComplianceError_Level.UNSPECIFIED;

  constructor(data?: PartialMessage<ComplianceError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.ComplianceError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "level", kind: "enum", T: proto3.getEnumType(ComplianceError_Level) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComplianceError {
    return new ComplianceError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComplianceError {
    return new ComplianceError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComplianceError {
    return new ComplianceError().fromJsonString(jsonString, options);
  }

  static equals(a: ComplianceError | PlainMessage<ComplianceError> | undefined, b: ComplianceError | PlainMessage<ComplianceError> | undefined): boolean {
    return proto3.util.equals(ComplianceError, a, b);
  }
}

/**
 * @generated from enum agentero.portal.ComplianceError.Level
 */
export enum ComplianceError_Level {
  /**
   * @generated from enum value: LEVEL_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: LEVEL_ERROR = 1;
   */
  ERROR = 1,

  /**
   * @generated from enum value: LEVEL_WARNING = 2;
   */
  WARNING = 2,

  /**
   * @generated from enum value: LEVEL_INFO = 3;
   */
  INFO = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ComplianceError_Level)
proto3.util.setEnumType(ComplianceError_Level, "agentero.portal.ComplianceError.Level", [
  { no: 0, name: "LEVEL_UNSPECIFIED" },
  { no: 1, name: "LEVEL_ERROR" },
  { no: 2, name: "LEVEL_WARNING" },
  { no: 3, name: "LEVEL_INFO" },
]);

/**
 * @generated from message agentero.portal.GetAgencyComplianceInformationRequest
 */
export class GetAgencyComplianceInformationRequest extends Message<GetAgencyComplianceInformationRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<GetAgencyComplianceInformationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetAgencyComplianceInformationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAgencyComplianceInformationRequest {
    return new GetAgencyComplianceInformationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAgencyComplianceInformationRequest {
    return new GetAgencyComplianceInformationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAgencyComplianceInformationRequest {
    return new GetAgencyComplianceInformationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAgencyComplianceInformationRequest | PlainMessage<GetAgencyComplianceInformationRequest> | undefined, b: GetAgencyComplianceInformationRequest | PlainMessage<GetAgencyComplianceInformationRequest> | undefined): boolean {
    return proto3.util.equals(GetAgencyComplianceInformationRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetAgencyComplianceInformationResponse
 */
export class GetAgencyComplianceInformationResponse extends Message<GetAgencyComplianceInformationResponse> {
  /**
   * @generated from field: string npn = 1;
   */
  npn = "";

  /**
   * @generated from field: string fein = 2;
   */
  fein = "";

  /**
   * @generated from field: string license_state = 3;
   */
  licenseState = "";

  /**
   * @generated from field: string license_number = 4;
   */
  licenseNumber = "";

  /**
   * @generated from field: agentero.portal.ComplianceError compliance_error = 5;
   */
  complianceError?: ComplianceError;

  /**
   * @generated from field: repeated agentero.license.LineOfAuthority lines_of_authority = 6;
   */
  linesOfAuthority: LineOfAuthority[] = [];

  /**
   * @generated from field: repeated agentero.license.License licenses = 7;
   */
  licenses: License[] = [];

  /**
   * @generated from field: agentero.license.LicenseGlobalStatus license_global_status = 8;
   */
  licenseGlobalStatus = LicenseGlobalStatus.UNSPECIFIED;

  constructor(data?: PartialMessage<GetAgencyComplianceInformationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetAgencyComplianceInformationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "npn", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fein", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "license_state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "license_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "compliance_error", kind: "message", T: ComplianceError },
    { no: 6, name: "lines_of_authority", kind: "enum", T: proto3.getEnumType(LineOfAuthority), repeated: true },
    { no: 7, name: "licenses", kind: "message", T: License, repeated: true },
    { no: 8, name: "license_global_status", kind: "enum", T: proto3.getEnumType(LicenseGlobalStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAgencyComplianceInformationResponse {
    return new GetAgencyComplianceInformationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAgencyComplianceInformationResponse {
    return new GetAgencyComplianceInformationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAgencyComplianceInformationResponse {
    return new GetAgencyComplianceInformationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAgencyComplianceInformationResponse | PlainMessage<GetAgencyComplianceInformationResponse> | undefined, b: GetAgencyComplianceInformationResponse | PlainMessage<GetAgencyComplianceInformationResponse> | undefined): boolean {
    return proto3.util.equals(GetAgencyComplianceInformationResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.QualifyRequest
 */
export class QualifyRequest extends Message<QualifyRequest> {
  /**
   * @generated from field: bool force = 1;
   */
  force = false;

  /**
   * @generated from field: string agency_id = 2;
   */
  agencyId = "";

  constructor(data?: PartialMessage<QualifyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.QualifyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "force", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QualifyRequest {
    return new QualifyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QualifyRequest {
    return new QualifyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QualifyRequest {
    return new QualifyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: QualifyRequest | PlainMessage<QualifyRequest> | undefined, b: QualifyRequest | PlainMessage<QualifyRequest> | undefined): boolean {
    return proto3.util.equals(QualifyRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.QualifyResponse
 */
export class QualifyResponse extends Message<QualifyResponse> {
  /**
   * @generated from field: bool bind_with_agentero_enabled = 1;
   */
  bindWithAgenteroEnabled = false;

  constructor(data?: PartialMessage<QualifyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.QualifyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bind_with_agentero_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QualifyResponse {
    return new QualifyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QualifyResponse {
    return new QualifyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QualifyResponse {
    return new QualifyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: QualifyResponse | PlainMessage<QualifyResponse> | undefined, b: QualifyResponse | PlainMessage<QualifyResponse> | undefined): boolean {
    return proto3.util.equals(QualifyResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.BindWithAgenteroCarrier
 */
export class BindWithAgenteroCarrier extends Message<BindWithAgenteroCarrier> {
  /**
   * @generated from field: agentero.carrier.Carrier carrier = 1;
   */
  carrier = Carrier.UNDEFINED;

  /**
   * @generated from field: google.protobuf.Timestamp release_date = 2;
   */
  releaseDate?: Timestamp;

  /**
   * @generated from field: repeated agentero.policy.InsuranceType lobs = 3;
   */
  lobs: InsuranceType[] = [];

  /**
   * @generated from field: repeated agentero.policy.InsuranceType quotable_lobs = 4;
   */
  quotableLobs: InsuranceType[] = [];

  /**
   * @generated from field: bool is_quotable = 5;
   */
  isQuotable = false;

  constructor(data?: PartialMessage<BindWithAgenteroCarrier>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.BindWithAgenteroCarrier";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
    { no: 2, name: "release_date", kind: "message", T: Timestamp },
    { no: 3, name: "lobs", kind: "enum", T: proto3.getEnumType(InsuranceType), repeated: true },
    { no: 4, name: "quotable_lobs", kind: "enum", T: proto3.getEnumType(InsuranceType), repeated: true },
    { no: 5, name: "is_quotable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BindWithAgenteroCarrier {
    return new BindWithAgenteroCarrier().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BindWithAgenteroCarrier {
    return new BindWithAgenteroCarrier().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BindWithAgenteroCarrier {
    return new BindWithAgenteroCarrier().fromJsonString(jsonString, options);
  }

  static equals(a: BindWithAgenteroCarrier | PlainMessage<BindWithAgenteroCarrier> | undefined, b: BindWithAgenteroCarrier | PlainMessage<BindWithAgenteroCarrier> | undefined): boolean {
    return proto3.util.equals(BindWithAgenteroCarrier, a, b);
  }
}

/**
 * GetBindWithAgenteroCarriersRequest requests bind with agentero carriers by agency id.
 *
 * @generated from message agentero.portal.GetBindWithAgenteroCarriersRequest
 */
export class GetBindWithAgenteroCarriersRequest extends Message<GetBindWithAgenteroCarriersRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<GetBindWithAgenteroCarriersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetBindWithAgenteroCarriersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBindWithAgenteroCarriersRequest {
    return new GetBindWithAgenteroCarriersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBindWithAgenteroCarriersRequest {
    return new GetBindWithAgenteroCarriersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBindWithAgenteroCarriersRequest {
    return new GetBindWithAgenteroCarriersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetBindWithAgenteroCarriersRequest | PlainMessage<GetBindWithAgenteroCarriersRequest> | undefined, b: GetBindWithAgenteroCarriersRequest | PlainMessage<GetBindWithAgenteroCarriersRequest> | undefined): boolean {
    return proto3.util.equals(GetBindWithAgenteroCarriersRequest, a, b);
  }
}

/**
 * GetBindWithAgenteroCarriersResponse returns bind with agentero carriers by agency id.
 *
 * @generated from message agentero.portal.GetBindWithAgenteroCarriersResponse
 */
export class GetBindWithAgenteroCarriersResponse extends Message<GetBindWithAgenteroCarriersResponse> {
  /**
   * @generated from field: repeated agentero.portal.BindWithAgenteroCarrier bind_with_agentero_carriers = 1;
   */
  bindWithAgenteroCarriers: BindWithAgenteroCarrier[] = [];

  constructor(data?: PartialMessage<GetBindWithAgenteroCarriersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetBindWithAgenteroCarriersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bind_with_agentero_carriers", kind: "message", T: BindWithAgenteroCarrier, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBindWithAgenteroCarriersResponse {
    return new GetBindWithAgenteroCarriersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBindWithAgenteroCarriersResponse {
    return new GetBindWithAgenteroCarriersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBindWithAgenteroCarriersResponse {
    return new GetBindWithAgenteroCarriersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetBindWithAgenteroCarriersResponse | PlainMessage<GetBindWithAgenteroCarriersResponse> | undefined, b: GetBindWithAgenteroCarriersResponse | PlainMessage<GetBindWithAgenteroCarriersResponse> | undefined): boolean {
    return proto3.util.equals(GetBindWithAgenteroCarriersResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateAgencyEnablementRequest
 */
export class UpdateAgencyEnablementRequest extends Message<UpdateAgencyEnablementRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: bool enabled = 2;
   */
  enabled = false;

  constructor(data?: PartialMessage<UpdateAgencyEnablementRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateAgencyEnablementRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAgencyEnablementRequest {
    return new UpdateAgencyEnablementRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAgencyEnablementRequest {
    return new UpdateAgencyEnablementRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAgencyEnablementRequest {
    return new UpdateAgencyEnablementRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAgencyEnablementRequest | PlainMessage<UpdateAgencyEnablementRequest> | undefined, b: UpdateAgencyEnablementRequest | PlainMessage<UpdateAgencyEnablementRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAgencyEnablementRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GenerateCarrierLossRatioURLRequest
 */
export class GenerateCarrierLossRatioURLRequest extends Message<GenerateCarrierLossRatioURLRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: agentero.portal.CarrierLossRatioID carrier_loss_ratio_id = 2;
   */
  carrierLossRatioId = CarrierLossRatioID.CARRIER_LOSS_RATIO_UNSPECIFIED;

  constructor(data?: PartialMessage<GenerateCarrierLossRatioURLRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GenerateCarrierLossRatioURLRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "carrier_loss_ratio_id", kind: "enum", T: proto3.getEnumType(CarrierLossRatioID) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateCarrierLossRatioURLRequest {
    return new GenerateCarrierLossRatioURLRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateCarrierLossRatioURLRequest {
    return new GenerateCarrierLossRatioURLRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateCarrierLossRatioURLRequest {
    return new GenerateCarrierLossRatioURLRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateCarrierLossRatioURLRequest | PlainMessage<GenerateCarrierLossRatioURLRequest> | undefined, b: GenerateCarrierLossRatioURLRequest | PlainMessage<GenerateCarrierLossRatioURLRequest> | undefined): boolean {
    return proto3.util.equals(GenerateCarrierLossRatioURLRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GenerateCarrierLossRatioURLResponse
 */
export class GenerateCarrierLossRatioURLResponse extends Message<GenerateCarrierLossRatioURLResponse> {
  /**
   * @generated from field: string policy_file_url = 1;
   */
  policyFileUrl = "";

  /**
   * @generated from field: string expected_content_type = 2;
   */
  expectedContentType = "";

  constructor(data?: PartialMessage<GenerateCarrierLossRatioURLResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GenerateCarrierLossRatioURLResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy_file_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "expected_content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateCarrierLossRatioURLResponse {
    return new GenerateCarrierLossRatioURLResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateCarrierLossRatioURLResponse {
    return new GenerateCarrierLossRatioURLResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateCarrierLossRatioURLResponse {
    return new GenerateCarrierLossRatioURLResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateCarrierLossRatioURLResponse | PlainMessage<GenerateCarrierLossRatioURLResponse> | undefined, b: GenerateCarrierLossRatioURLResponse | PlainMessage<GenerateCarrierLossRatioURLResponse> | undefined): boolean {
    return proto3.util.equals(GenerateCarrierLossRatioURLResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateTopCarriersLossRatioRequest
 */
export class UpdateTopCarriersLossRatioRequest extends Message<UpdateTopCarriersLossRatioRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: repeated agentero.portal.UpdateTopCarriersLossRatioRequest.CarrierLossRatio top_carrier_loss_ratios = 2;
   */
  topCarrierLossRatios: UpdateTopCarriersLossRatioRequest_CarrierLossRatio[] = [];

  constructor(data?: PartialMessage<UpdateTopCarriersLossRatioRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateTopCarriersLossRatioRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "top_carrier_loss_ratios", kind: "message", T: UpdateTopCarriersLossRatioRequest_CarrierLossRatio, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTopCarriersLossRatioRequest {
    return new UpdateTopCarriersLossRatioRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTopCarriersLossRatioRequest {
    return new UpdateTopCarriersLossRatioRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTopCarriersLossRatioRequest {
    return new UpdateTopCarriersLossRatioRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTopCarriersLossRatioRequest | PlainMessage<UpdateTopCarriersLossRatioRequest> | undefined, b: UpdateTopCarriersLossRatioRequest | PlainMessage<UpdateTopCarriersLossRatioRequest> | undefined): boolean {
    return proto3.util.equals(UpdateTopCarriersLossRatioRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateTopCarriersLossRatioRequest.CarrierLossRatio
 */
export class UpdateTopCarriersLossRatioRequest_CarrierLossRatio extends Message<UpdateTopCarriersLossRatioRequest_CarrierLossRatio> {
  /**
   * @generated from field: agentero.portal.CarrierLossRatioID carrier_loss_ratio = 1;
   */
  carrierLossRatio = CarrierLossRatioID.CARRIER_LOSS_RATIO_UNSPECIFIED;

  /**
   * @generated from field: string carrier_name = 2;
   */
  carrierName = "";

  /**
   * @generated from field: int64 total_premium = 3;
   */
  totalPremium = protoInt64.zero;

  /**
   * @generated from field: int64 loss_ratio = 4;
   */
  lossRatio = protoInt64.zero;

  constructor(data?: PartialMessage<UpdateTopCarriersLossRatioRequest_CarrierLossRatio>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateTopCarriersLossRatioRequest.CarrierLossRatio";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "carrier_loss_ratio", kind: "enum", T: proto3.getEnumType(CarrierLossRatioID) },
    { no: 2, name: "carrier_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "total_premium", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "loss_ratio", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTopCarriersLossRatioRequest_CarrierLossRatio {
    return new UpdateTopCarriersLossRatioRequest_CarrierLossRatio().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTopCarriersLossRatioRequest_CarrierLossRatio {
    return new UpdateTopCarriersLossRatioRequest_CarrierLossRatio().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTopCarriersLossRatioRequest_CarrierLossRatio {
    return new UpdateTopCarriersLossRatioRequest_CarrierLossRatio().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTopCarriersLossRatioRequest_CarrierLossRatio | PlainMessage<UpdateTopCarriersLossRatioRequest_CarrierLossRatio> | undefined, b: UpdateTopCarriersLossRatioRequest_CarrierLossRatio | PlainMessage<UpdateTopCarriersLossRatioRequest_CarrierLossRatio> | undefined): boolean {
    return proto3.util.equals(UpdateTopCarriersLossRatioRequest_CarrierLossRatio, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetTopCarriersLossRatioRequest
 */
export class GetTopCarriersLossRatioRequest extends Message<GetTopCarriersLossRatioRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<GetTopCarriersLossRatioRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetTopCarriersLossRatioRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTopCarriersLossRatioRequest {
    return new GetTopCarriersLossRatioRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTopCarriersLossRatioRequest {
    return new GetTopCarriersLossRatioRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTopCarriersLossRatioRequest {
    return new GetTopCarriersLossRatioRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTopCarriersLossRatioRequest | PlainMessage<GetTopCarriersLossRatioRequest> | undefined, b: GetTopCarriersLossRatioRequest | PlainMessage<GetTopCarriersLossRatioRequest> | undefined): boolean {
    return proto3.util.equals(GetTopCarriersLossRatioRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetTopCarriersLossRatioResponse
 */
export class GetTopCarriersLossRatioResponse extends Message<GetTopCarriersLossRatioResponse> {
  /**
   * @generated from field: repeated agentero.portal.GetTopCarriersLossRatioResponse.CarrierLossRatio top_carrier_loss_ratios = 1;
   */
  topCarrierLossRatios: GetTopCarriersLossRatioResponse_CarrierLossRatio[] = [];

  /**
   * @generated from field: optional google.protobuf.Timestamp last_updated_at = 2;
   */
  lastUpdatedAt?: Timestamp;

  constructor(data?: PartialMessage<GetTopCarriersLossRatioResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetTopCarriersLossRatioResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "top_carrier_loss_ratios", kind: "message", T: GetTopCarriersLossRatioResponse_CarrierLossRatio, repeated: true },
    { no: 2, name: "last_updated_at", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTopCarriersLossRatioResponse {
    return new GetTopCarriersLossRatioResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTopCarriersLossRatioResponse {
    return new GetTopCarriersLossRatioResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTopCarriersLossRatioResponse {
    return new GetTopCarriersLossRatioResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTopCarriersLossRatioResponse | PlainMessage<GetTopCarriersLossRatioResponse> | undefined, b: GetTopCarriersLossRatioResponse | PlainMessage<GetTopCarriersLossRatioResponse> | undefined): boolean {
    return proto3.util.equals(GetTopCarriersLossRatioResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetTopCarriersLossRatioResponse.CarrierLossRatio
 */
export class GetTopCarriersLossRatioResponse_CarrierLossRatio extends Message<GetTopCarriersLossRatioResponse_CarrierLossRatio> {
  /**
   * @generated from field: agentero.portal.CarrierLossRatioID carrier_loss_ratio = 1;
   */
  carrierLossRatio = CarrierLossRatioID.CARRIER_LOSS_RATIO_UNSPECIFIED;

  /**
   * @generated from field: string carrier_name = 2;
   */
  carrierName = "";

  /**
   * @generated from field: int64 total_premium = 3;
   */
  totalPremium = protoInt64.zero;

  /**
   * @generated from field: int64 loss_ratio = 4;
   */
  lossRatio = protoInt64.zero;

  /**
   * @generated from field: string file_url = 5;
   */
  fileUrl = "";

  constructor(data?: PartialMessage<GetTopCarriersLossRatioResponse_CarrierLossRatio>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetTopCarriersLossRatioResponse.CarrierLossRatio";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "carrier_loss_ratio", kind: "enum", T: proto3.getEnumType(CarrierLossRatioID) },
    { no: 2, name: "carrier_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "total_premium", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "loss_ratio", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "file_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTopCarriersLossRatioResponse_CarrierLossRatio {
    return new GetTopCarriersLossRatioResponse_CarrierLossRatio().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTopCarriersLossRatioResponse_CarrierLossRatio {
    return new GetTopCarriersLossRatioResponse_CarrierLossRatio().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTopCarriersLossRatioResponse_CarrierLossRatio {
    return new GetTopCarriersLossRatioResponse_CarrierLossRatio().fromJsonString(jsonString, options);
  }

  static equals(a: GetTopCarriersLossRatioResponse_CarrierLossRatio | PlainMessage<GetTopCarriersLossRatioResponse_CarrierLossRatio> | undefined, b: GetTopCarriersLossRatioResponse_CarrierLossRatio | PlainMessage<GetTopCarriersLossRatioResponse_CarrierLossRatio> | undefined): boolean {
    return proto3.util.equals(GetTopCarriersLossRatioResponse_CarrierLossRatio, a, b);
  }
}

/**
 * @generated from message agentero.portal.PersonalLinesPercentages
 */
export class PersonalLinesPercentages extends Message<PersonalLinesPercentages> {
  /**
   * @generated from field: int64 standard_home = 1;
   */
  standardHome = protoInt64.zero;

  /**
   * @generated from field: int64 non_standard_home = 2;
   */
  nonStandardHome = protoInt64.zero;

  /**
   * @generated from field: int64 standard_auto = 3;
   */
  standardAuto = protoInt64.zero;

  /**
   * @generated from field: int64 non_standard_auto = 4;
   */
  nonStandardAuto = protoInt64.zero;

  /**
   * @generated from field: int64 recreational_marine = 5;
   */
  recreationalMarine = protoInt64.zero;

  /**
   * @generated from field: int64 others_auto = 6;
   */
  othersAuto = protoInt64.zero;

  constructor(data?: PartialMessage<PersonalLinesPercentages>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.PersonalLinesPercentages";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "standard_home", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "non_standard_home", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "standard_auto", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "non_standard_auto", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "recreational_marine", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "others_auto", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PersonalLinesPercentages {
    return new PersonalLinesPercentages().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PersonalLinesPercentages {
    return new PersonalLinesPercentages().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PersonalLinesPercentages {
    return new PersonalLinesPercentages().fromJsonString(jsonString, options);
  }

  static equals(a: PersonalLinesPercentages | PlainMessage<PersonalLinesPercentages> | undefined, b: PersonalLinesPercentages | PlainMessage<PersonalLinesPercentages> | undefined): boolean {
    return proto3.util.equals(PersonalLinesPercentages, a, b);
  }
}

/**
 * @generated from message agentero.portal.OtherLinesPercentages
 */
export class OtherLinesPercentages extends Message<OtherLinesPercentages> {
  /**
   * @generated from field: int64 life = 1;
   */
  life = protoInt64.zero;

  /**
   * @generated from field: int64 excess_and_surplus = 2;
   */
  excessAndSurplus = protoInt64.zero;

  /**
   * @generated from field: int64 other = 3;
   */
  other = protoInt64.zero;

  constructor(data?: PartialMessage<OtherLinesPercentages>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.OtherLinesPercentages";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "life", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "excess_and_surplus", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "other", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OtherLinesPercentages {
    return new OtherLinesPercentages().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OtherLinesPercentages {
    return new OtherLinesPercentages().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OtherLinesPercentages {
    return new OtherLinesPercentages().fromJsonString(jsonString, options);
  }

  static equals(a: OtherLinesPercentages | PlainMessage<OtherLinesPercentages> | undefined, b: OtherLinesPercentages | PlainMessage<OtherLinesPercentages> | undefined): boolean {
    return proto3.util.equals(OtherLinesPercentages, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateBookOfBusinessRequest
 */
export class UpdateBookOfBusinessRequest extends Message<UpdateBookOfBusinessRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: int64 total_premium = 2;
   */
  totalPremium = protoInt64.zero;

  /**
   * @generated from field: agentero.portal.PersonalLinesPercentages personal_lines = 3;
   */
  personalLines?: PersonalLinesPercentages;

  /**
   * @generated from field: int64 commercial_lines_percentage = 4;
   */
  commercialLinesPercentage = protoInt64.zero;

  /**
   * @generated from field: agentero.portal.OtherLinesPercentages other_lines = 5;
   */
  otherLines?: OtherLinesPercentages;

  constructor(data?: PartialMessage<UpdateBookOfBusinessRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateBookOfBusinessRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "total_premium", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "personal_lines", kind: "message", T: PersonalLinesPercentages },
    { no: 4, name: "commercial_lines_percentage", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "other_lines", kind: "message", T: OtherLinesPercentages },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateBookOfBusinessRequest {
    return new UpdateBookOfBusinessRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateBookOfBusinessRequest {
    return new UpdateBookOfBusinessRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateBookOfBusinessRequest {
    return new UpdateBookOfBusinessRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateBookOfBusinessRequest | PlainMessage<UpdateBookOfBusinessRequest> | undefined, b: UpdateBookOfBusinessRequest | PlainMessage<UpdateBookOfBusinessRequest> | undefined): boolean {
    return proto3.util.equals(UpdateBookOfBusinessRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetBookOfBusinessRequest
 */
export class GetBookOfBusinessRequest extends Message<GetBookOfBusinessRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<GetBookOfBusinessRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetBookOfBusinessRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBookOfBusinessRequest {
    return new GetBookOfBusinessRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBookOfBusinessRequest {
    return new GetBookOfBusinessRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBookOfBusinessRequest {
    return new GetBookOfBusinessRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetBookOfBusinessRequest | PlainMessage<GetBookOfBusinessRequest> | undefined, b: GetBookOfBusinessRequest | PlainMessage<GetBookOfBusinessRequest> | undefined): boolean {
    return proto3.util.equals(GetBookOfBusinessRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetBookOfBusinessResponse
 */
export class GetBookOfBusinessResponse extends Message<GetBookOfBusinessResponse> {
  /**
   * @generated from field: int64 total_premium = 1;
   */
  totalPremium = protoInt64.zero;

  /**
   * @generated from field: agentero.portal.PersonalLinesPercentages personal_lines = 2;
   */
  personalLines?: PersonalLinesPercentages;

  /**
   * @generated from field: int64 commercial_lines_percentage = 3;
   */
  commercialLinesPercentage = protoInt64.zero;

  /**
   * @generated from field: agentero.portal.OtherLinesPercentages other_lines = 4;
   */
  otherLines?: OtherLinesPercentages;

  constructor(data?: PartialMessage<GetBookOfBusinessResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetBookOfBusinessResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_premium", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "personal_lines", kind: "message", T: PersonalLinesPercentages },
    { no: 3, name: "commercial_lines_percentage", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "other_lines", kind: "message", T: OtherLinesPercentages },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBookOfBusinessResponse {
    return new GetBookOfBusinessResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBookOfBusinessResponse {
    return new GetBookOfBusinessResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBookOfBusinessResponse {
    return new GetBookOfBusinessResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetBookOfBusinessResponse | PlainMessage<GetBookOfBusinessResponse> | undefined, b: GetBookOfBusinessResponse | PlainMessage<GetBookOfBusinessResponse> | undefined): boolean {
    return proto3.util.equals(GetBookOfBusinessResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateAgencyTestingRequest
 */
export class UpdateAgencyTestingRequest extends Message<UpdateAgencyTestingRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: bool is_test = 2;
   */
  isTest = false;

  constructor(data?: PartialMessage<UpdateAgencyTestingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateAgencyTestingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_test", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAgencyTestingRequest {
    return new UpdateAgencyTestingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAgencyTestingRequest {
    return new UpdateAgencyTestingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAgencyTestingRequest {
    return new UpdateAgencyTestingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAgencyTestingRequest | PlainMessage<UpdateAgencyTestingRequest> | undefined, b: UpdateAgencyTestingRequest | PlainMessage<UpdateAgencyTestingRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAgencyTestingRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetTierInformationRequest
 */
export class GetTierInformationRequest extends Message<GetTierInformationRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<GetTierInformationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetTierInformationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTierInformationRequest {
    return new GetTierInformationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTierInformationRequest {
    return new GetTierInformationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTierInformationRequest {
    return new GetTierInformationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTierInformationRequest | PlainMessage<GetTierInformationRequest> | undefined, b: GetTierInformationRequest | PlainMessage<GetTierInformationRequest> | undefined): boolean {
    return proto3.util.equals(GetTierInformationRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetTierInformationResponse
 */
export class GetTierInformationResponse extends Message<GetTierInformationResponse> {
  /**
   * @generated from field: agentero.tier.CurrentTier current_tier = 1;
   */
  currentTier?: CurrentTier;

  /**
   * @generated from field: int32 premium_in_force = 2;
   */
  premiumInForce = 0;

  /**
   * @generated from field: bool has_terminated_appointments = 3;
   */
  hasTerminatedAppointments = false;

  constructor(data?: PartialMessage<GetTierInformationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetTierInformationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "current_tier", kind: "message", T: CurrentTier },
    { no: 2, name: "premium_in_force", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "has_terminated_appointments", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTierInformationResponse {
    return new GetTierInformationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTierInformationResponse {
    return new GetTierInformationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTierInformationResponse {
    return new GetTierInformationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTierInformationResponse | PlainMessage<GetTierInformationResponse> | undefined, b: GetTierInformationResponse | PlainMessage<GetTierInformationResponse> | undefined): boolean {
    return proto3.util.equals(GetTierInformationResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.UpdateCarrierRaterEnablementRequest
 */
export class UpdateCarrierRaterEnablementRequest extends Message<UpdateCarrierRaterEnablementRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: agentero.carrier.Carrier carrier = 2;
   */
  carrier = Carrier.UNDEFINED;

  /**
   * @generated from field: bool disabled = 3;
   */
  disabled = false;

  constructor(data?: PartialMessage<UpdateCarrierRaterEnablementRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.UpdateCarrierRaterEnablementRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
    { no: 3, name: "disabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCarrierRaterEnablementRequest {
    return new UpdateCarrierRaterEnablementRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCarrierRaterEnablementRequest {
    return new UpdateCarrierRaterEnablementRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCarrierRaterEnablementRequest {
    return new UpdateCarrierRaterEnablementRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCarrierRaterEnablementRequest | PlainMessage<UpdateCarrierRaterEnablementRequest> | undefined, b: UpdateCarrierRaterEnablementRequest | PlainMessage<UpdateCarrierRaterEnablementRequest> | undefined): boolean {
    return proto3.util.equals(UpdateCarrierRaterEnablementRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetCarrierRaterConfigurationsRequest
 */
export class GetCarrierRaterConfigurationsRequest extends Message<GetCarrierRaterConfigurationsRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  constructor(data?: PartialMessage<GetCarrierRaterConfigurationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetCarrierRaterConfigurationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCarrierRaterConfigurationsRequest {
    return new GetCarrierRaterConfigurationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCarrierRaterConfigurationsRequest {
    return new GetCarrierRaterConfigurationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCarrierRaterConfigurationsRequest {
    return new GetCarrierRaterConfigurationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCarrierRaterConfigurationsRequest | PlainMessage<GetCarrierRaterConfigurationsRequest> | undefined, b: GetCarrierRaterConfigurationsRequest | PlainMessage<GetCarrierRaterConfigurationsRequest> | undefined): boolean {
    return proto3.util.equals(GetCarrierRaterConfigurationsRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetCarrierRaterConfigurationsResponse
 */
export class GetCarrierRaterConfigurationsResponse extends Message<GetCarrierRaterConfigurationsResponse> {
  /**
   * @generated from field: repeated agentero.portal.GetCarrierRaterConfigurationsResponse.CarrierApproval carriers = 1;
   */
  carriers: GetCarrierRaterConfigurationsResponse_CarrierApproval[] = [];

  constructor(data?: PartialMessage<GetCarrierRaterConfigurationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetCarrierRaterConfigurationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "carriers", kind: "message", T: GetCarrierRaterConfigurationsResponse_CarrierApproval, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCarrierRaterConfigurationsResponse {
    return new GetCarrierRaterConfigurationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCarrierRaterConfigurationsResponse {
    return new GetCarrierRaterConfigurationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCarrierRaterConfigurationsResponse {
    return new GetCarrierRaterConfigurationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCarrierRaterConfigurationsResponse | PlainMessage<GetCarrierRaterConfigurationsResponse> | undefined, b: GetCarrierRaterConfigurationsResponse | PlainMessage<GetCarrierRaterConfigurationsResponse> | undefined): boolean {
    return proto3.util.equals(GetCarrierRaterConfigurationsResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetCarrierRaterConfigurationsResponse.CarrierApproval
 */
export class GetCarrierRaterConfigurationsResponse_CarrierApproval extends Message<GetCarrierRaterConfigurationsResponse_CarrierApproval> {
  /**
   * @generated from field: agentero.carrier.Carrier carrier = 1;
   */
  carrier = Carrier.UNDEFINED;

  /**
   * @generated from field: optional agentero.carrier.ApprovalStatus bwa_approval_status = 2;
   */
  bwaApprovalStatus?: ApprovalStatus;

  /**
   * @generated from field: bool disabled = 3;
   */
  disabled = false;

  /**
   * @generated from field: bool bwa_approval_required = 4;
   */
  bwaApprovalRequired = false;

  constructor(data?: PartialMessage<GetCarrierRaterConfigurationsResponse_CarrierApproval>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetCarrierRaterConfigurationsResponse.CarrierApproval";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
    { no: 2, name: "bwa_approval_status", kind: "enum", T: proto3.getEnumType(ApprovalStatus), opt: true },
    { no: 3, name: "disabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "bwa_approval_required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCarrierRaterConfigurationsResponse_CarrierApproval {
    return new GetCarrierRaterConfigurationsResponse_CarrierApproval().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCarrierRaterConfigurationsResponse_CarrierApproval {
    return new GetCarrierRaterConfigurationsResponse_CarrierApproval().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCarrierRaterConfigurationsResponse_CarrierApproval {
    return new GetCarrierRaterConfigurationsResponse_CarrierApproval().fromJsonString(jsonString, options);
  }

  static equals(a: GetCarrierRaterConfigurationsResponse_CarrierApproval | PlainMessage<GetCarrierRaterConfigurationsResponse_CarrierApproval> | undefined, b: GetCarrierRaterConfigurationsResponse_CarrierApproval | PlainMessage<GetCarrierRaterConfigurationsResponse_CarrierApproval> | undefined): boolean {
    return proto3.util.equals(GetCarrierRaterConfigurationsResponse_CarrierApproval, a, b);
  }
}

/**
 * @generated from message agentero.portal.CarrierApprovalRequestRequest
 */
export class CarrierApprovalRequestRequest extends Message<CarrierApprovalRequestRequest> {
  /**
   * @generated from field: agentero.carrier.Carrier carrier = 1;
   */
  carrier = Carrier.UNDEFINED;

  /**
   * @generated from field: map<string, string> form_data = 2;
   */
  formData: { [key: string]: string } = {};

  constructor(data?: PartialMessage<CarrierApprovalRequestRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.CarrierApprovalRequestRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
    { no: 2, name: "form_data", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CarrierApprovalRequestRequest {
    return new CarrierApprovalRequestRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CarrierApprovalRequestRequest {
    return new CarrierApprovalRequestRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CarrierApprovalRequestRequest {
    return new CarrierApprovalRequestRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CarrierApprovalRequestRequest | PlainMessage<CarrierApprovalRequestRequest> | undefined, b: CarrierApprovalRequestRequest | PlainMessage<CarrierApprovalRequestRequest> | undefined): boolean {
    return proto3.util.equals(CarrierApprovalRequestRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetCarrierApprovalRequestRequest
 */
export class GetCarrierApprovalRequestRequest extends Message<GetCarrierApprovalRequestRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: agentero.carrier.Carrier carrier = 2;
   */
  carrier = Carrier.UNDEFINED;

  constructor(data?: PartialMessage<GetCarrierApprovalRequestRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetCarrierApprovalRequestRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCarrierApprovalRequestRequest {
    return new GetCarrierApprovalRequestRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCarrierApprovalRequestRequest {
    return new GetCarrierApprovalRequestRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCarrierApprovalRequestRequest {
    return new GetCarrierApprovalRequestRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCarrierApprovalRequestRequest | PlainMessage<GetCarrierApprovalRequestRequest> | undefined, b: GetCarrierApprovalRequestRequest | PlainMessage<GetCarrierApprovalRequestRequest> | undefined): boolean {
    return proto3.util.equals(GetCarrierApprovalRequestRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetCarrierApprovalRequestResponse
 */
export class GetCarrierApprovalRequestResponse extends Message<GetCarrierApprovalRequestResponse> {
  /**
   * @generated from field: map<string, string> form_data = 1;
   */
  formData: { [key: string]: string } = {};

  /**
   * @generated from field: agentero.carrier.ApprovalStatus bwa_approval_status = 2;
   */
  bwaApprovalStatus = ApprovalStatus.UNSPECIFIED;

  constructor(data?: PartialMessage<GetCarrierApprovalRequestResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetCarrierApprovalRequestResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "form_data", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 2, name: "bwa_approval_status", kind: "enum", T: proto3.getEnumType(ApprovalStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCarrierApprovalRequestResponse {
    return new GetCarrierApprovalRequestResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCarrierApprovalRequestResponse {
    return new GetCarrierApprovalRequestResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCarrierApprovalRequestResponse {
    return new GetCarrierApprovalRequestResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCarrierApprovalRequestResponse | PlainMessage<GetCarrierApprovalRequestResponse> | undefined, b: GetCarrierApprovalRequestResponse | PlainMessage<GetCarrierApprovalRequestResponse> | undefined): boolean {
    return proto3.util.equals(GetCarrierApprovalRequestResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.ApproveCarrierApprovalRequestRequest
 */
export class ApproveCarrierApprovalRequestRequest extends Message<ApproveCarrierApprovalRequestRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: agentero.carrier.Carrier carrier = 2;
   */
  carrier = Carrier.UNDEFINED;

  constructor(data?: PartialMessage<ApproveCarrierApprovalRequestRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.ApproveCarrierApprovalRequestRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ApproveCarrierApprovalRequestRequest {
    return new ApproveCarrierApprovalRequestRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ApproveCarrierApprovalRequestRequest {
    return new ApproveCarrierApprovalRequestRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ApproveCarrierApprovalRequestRequest {
    return new ApproveCarrierApprovalRequestRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ApproveCarrierApprovalRequestRequest | PlainMessage<ApproveCarrierApprovalRequestRequest> | undefined, b: ApproveCarrierApprovalRequestRequest | PlainMessage<ApproveCarrierApprovalRequestRequest> | undefined): boolean {
    return proto3.util.equals(ApproveCarrierApprovalRequestRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.RejectCarrierApprovalRequestRequest
 */
export class RejectCarrierApprovalRequestRequest extends Message<RejectCarrierApprovalRequestRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: agentero.carrier.Carrier carrier = 2;
   */
  carrier = Carrier.UNDEFINED;

  constructor(data?: PartialMessage<RejectCarrierApprovalRequestRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.RejectCarrierApprovalRequestRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RejectCarrierApprovalRequestRequest {
    return new RejectCarrierApprovalRequestRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RejectCarrierApprovalRequestRequest {
    return new RejectCarrierApprovalRequestRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RejectCarrierApprovalRequestRequest {
    return new RejectCarrierApprovalRequestRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RejectCarrierApprovalRequestRequest | PlainMessage<RejectCarrierApprovalRequestRequest> | undefined, b: RejectCarrierApprovalRequestRequest | PlainMessage<RejectCarrierApprovalRequestRequest> | undefined): boolean {
    return proto3.util.equals(RejectCarrierApprovalRequestRequest, a, b);
  }
}

