import { StatusColor } from '@agentero/components';
import { LicenseStatus } from '@agentero/grpc-clients/grpc/shared/license';

export enum ComplianceStatesLicensesStatus {
	Active = 'active',
	Expired = 'expired',
	Cancelled = 'cancelled',
	LoaNotSupported = 'loa-not-supported'
}

const complianceStatesLicensesStatusMapping: {
	[key in LicenseStatus]: ComplianceStatesLicensesStatus | undefined;
} = {
	[LicenseStatus.LICENSE_STATUS_UNSPECIFIED]: undefined,
	[LicenseStatus.LICENSE_STATUS_EXPIRED]: ComplianceStatesLicensesStatus.Expired,
	[LicenseStatus.LICENSE_STATUS_NOT_ACTIVE]: ComplianceStatesLicensesStatus.Cancelled,
	[LicenseStatus.LICENSE_STATUS_VALID]: ComplianceStatesLicensesStatus.Active,
	[LicenseStatus.LICENSE_STATUS_LOA_NOT_SUPPORTED]: ComplianceStatesLicensesStatus.LoaNotSupported
};

export const parseComplianceStatesLicensesStatus = (
	status: LicenseStatus
): ComplianceStatesLicensesStatus | undefined => complianceStatesLicensesStatusMapping[status];

const statusColor: { [key in ComplianceStatesLicensesStatus]: StatusColor } = {
	[ComplianceStatesLicensesStatus.Active]: StatusColor.Success,
	[ComplianceStatesLicensesStatus.Expired]: StatusColor.Error,
	[ComplianceStatesLicensesStatus.Cancelled]: StatusColor.Error,
	[ComplianceStatesLicensesStatus.LoaNotSupported]: StatusColor.Warning
};

export const getComplianceStateLicenseStatusColor = (status: ComplianceStatesLicensesStatus) =>
	statusColor[status];

const statusKey: { [key in ComplianceStatesLicensesStatus]: { status: string; tooltip: string } } =
	{
		[ComplianceStatesLicensesStatus.Active]: {
			status: 'compliance.license.licenseStateStatus.active',
			tooltip: 'compliance.license.licenseStateStatusTooltip.active'
		},
		[ComplianceStatesLicensesStatus.Expired]: {
			status: 'compliance.license.licenseStateStatus.expired',
			tooltip: 'compliance.license.licenseStateStatusTooltip.expired'
		},
		[ComplianceStatesLicensesStatus.Cancelled]: {
			status: 'compliance.license.licenseStateStatus.cancelled',
			tooltip: 'compliance.license.licenseStateStatusTooltip.cancelled'
		},
		[ComplianceStatesLicensesStatus.LoaNotSupported]: {
			status: 'compliance.license.licenseStateStatus.loaNotSupported',
			tooltip: 'compliance.license.licenseStateStatusTooltip.loaNotSupported'
		}
	};

export const getComplianceStateLicenseStatusKey = (status: ComplianceStatesLicensesStatus) =>
	statusKey[status].status;

export const getComplianceStateLicenseTooltipKey = (status: ComplianceStatesLicensesStatus) =>
	statusKey[status].tooltip;
