// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts,import_extension=none"
// @generated from file agentero/portal/lead.proto (package agentero.portal, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateLeadRiskRequest, GetAgentInfoRequest, GetAgentInfoResponse, GetLeadFormURLRequest, GetLeadFormURLResponse, GetLeadRiskByIDRequest, GetLeadRiskByIDResponse, GetLeadSchemaRequest, GetLeadSchemaResponse, ListLeadRisksRequest, ListLeadRisksResponse } from "./lead_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service agentero.portal.LeadService
 */
export const LeadService = {
  typeName: "agentero.portal.LeadService",
  methods: {
    /**
     * GetLeadSchema returns the schema for the lead form
     *
     * @generated from rpc agentero.portal.LeadService.GetLeadSchema
     */
    getLeadSchema: {
      name: "GetLeadSchema",
      I: GetLeadSchemaRequest,
      O: GetLeadSchemaResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetLeadSchemaByLeadID returns the schema for the lead form
     *
     * @generated from rpc agentero.portal.LeadService.GetLeadSchemaByLeadID
     */
    getLeadSchemaByLeadID: {
      name: "GetLeadSchemaByLeadID",
      I: GetLeadSchemaRequest,
      O: GetLeadSchemaResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListLeadRisks returns a list of lead risks given an agencyID
     *
     * @generated from rpc agentero.portal.LeadService.ListLeadRisks
     */
    listLeadRisks: {
      name: "ListLeadRisks",
      I: ListLeadRisksRequest,
      O: ListLeadRisksResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetLeadRiskByID returns the lead risk by ID
     *
     * @generated from rpc agentero.portal.LeadService.GetLeadRiskByID
     */
    getLeadRiskByID: {
      name: "GetLeadRiskByID",
      I: GetLeadRiskByIDRequest,
      O: GetLeadRiskByIDResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetLeadFormURL returns the URL to get the form lead
     *
     * @generated from rpc agentero.portal.LeadService.GetLeadFormURL
     */
    getLeadFormURL: {
      name: "GetLeadFormURL",
      I: GetLeadFormURLRequest,
      O: GetLeadFormURLResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreateLeadRisk creates a new lead risk
     *
     * @generated from rpc agentero.portal.LeadService.CreateLeadRisk
     */
    createLeadRisk: {
      name: "CreateLeadRisk",
      I: CreateLeadRiskRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * GetAgentInfo returns the agent info
     *
     * @generated from rpc agentero.portal.LeadService.GetAgentInfo
     */
    getAgentInfo: {
      name: "GetAgentInfo",
      I: GetAgentInfoRequest,
      O: GetAgentInfoResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

