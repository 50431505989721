import { Search, fieldsExcludedFromSearch } from '../useAgUrlState';

export type ValueFromKey<T> = T extends (infer U)[] ? U : T;

export type OnFilterChangeArgs<T, K extends keyof T> = {
	key: K;
	value: ValueFromKey<T[K]>;
	search: T;
};

export const addFilter = <T, K extends keyof T>({
	key,
	value,
	search
}: OnFilterChangeArgs<T, K>): Partial<T> => {
	if (Array.isArray(search[key])) {
		return {
			[key]: [...(search[key] as unknown as ValueFromKey<T[K]>[]), value]
		} as unknown as Partial<T>;
	} else {
		return { [key]: value } as unknown as Partial<T>;
	}
};

export const removeFilter = <T, K extends keyof T>({
	key,
	value,
	search
}: OnFilterChangeArgs<T, K>): Partial<T> => {
	if (Array.isArray(search[key])) {
		const property = search[key] as unknown as ValueFromKey<T[K]>[];

		const newValue = property.filter(v => v !== value);
		return { [key]: newValue } as unknown as Partial<T>;
	} else {
		return { [key]: '' } as unknown as Partial<T>;
	}
};

export const clearFilters = <T extends Search<T['field']>>(search: T): Partial<T> =>
	Object.keys(search)
		.filter(key => !fieldsExcludedFromSearch.includes(key))
		.reduce((acc, key) => {
			return { ...acc, [key]: undefined };
		}, {} as Partial<T>);
