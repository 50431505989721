import { createPromiseClient } from '@connectrpc/connect';

import { ComplianceService } from '@agentero/service-clients/portal/compliance';
import { LeadService } from '@agentero/service-clients/portal/lead';
import { QuoteService } from '@agentero/service-clients/portal/quote';
import { RaterService } from '@agentero/service-clients/portal/rater';
import { SessionService } from '@agentero/service-clients/portal/session';
import { VehicleService } from '@agentero/service-clients/portal/vehicle';

import { portalTransport } from './connectClients/portalTransport';

export const leadService = createPromiseClient(LeadService, portalTransport);
export const raterService = createPromiseClient(RaterService, portalTransport);
export const portalService = createPromiseClient(SessionService, portalTransport);
export const complianceService = createPromiseClient(ComplianceService, portalTransport);
export const vehicleService = createPromiseClient(VehicleService, portalTransport);
export const quoteService = createPromiseClient(QuoteService, portalTransport);
