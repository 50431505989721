import styled from '@emotion/styled';

import { GridItem } from './grid/GridItem';

type GridProps = {
	spacing?: number;
	spacingY?: number;
	mb?: number;
};

/**
 * @deprecated Use **'@agentero/styles/jsx'** *Grid* component instead.
 */
export const Grid = styled.div<GridProps>`
	display: flex;
	flex-wrap: wrap;
	margin: 0 ${({ spacing }) => (spacing ? -(spacing / 2) : 0)}px;
	margin-bottom: ${({ mb }) => (mb ? mb : 0)}px;
	row-gap: ${({ spacingY }) => (spacingY ? spacingY : 0)}px;

	${GridItem} {
		padding: 0 ${({ spacing }) => (spacing ? spacing / 2 : 0)}px;
	}
`;
