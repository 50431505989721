import { PropsWithChildren } from 'react';

import { Path, get } from 'react-hook-form';

import { getFieldErrorId, useAgFormContext } from '@agentero/ui';
import { getInputStatusFromError } from '@agentero/utils';

import { InputRadioButtonGroup, RadioInputValue } from '../../InputRadioButtonGroup';
import { FormGroup, FormGroupGenericProps } from './FormGroup';

type FormGroupInputRadioButtonGroupProps<T> = {
	label: string;
	name: Path<T>;
	optional?: boolean;
	values: RadioInputValue[];
	nested?: boolean;
	defaultValue?: string | boolean;
	onGroupChange?: (value: string | boolean) => void;
} & FormGroupGenericProps<T>;

export const FormGroupInputRadioButtonGroup = <T extends {}>({
	name,
	values,
	defaultValue,
	children,
	onGroupChange,
	...formGroupProps
}: PropsWithChildren<FormGroupInputRadioButtonGroupProps<T>>) => {
	const {
		register,
		formState: { errors }
	} = useAgFormContext<T>();

	return (
		<FormGroup
			name={name}
			nestedChildren={children}
			asFieldset={true}
			getReadonlyValue={inputValue =>
				values?.find(option => option.value === inputValue)?.label ?? '—'
			}
			{...formGroupProps}>
			<InputRadioButtonGroup
				inputProps={{
					...register(name),
					'aria-errormessage': getFieldErrorId(name)
				}}
				status={getInputStatusFromError(get(errors, name)?.message as string)}
				values={values}
				defaultValue={defaultValue}
				onChange={onGroupChange}
			/>
		</FormGroup>
	);
};
