import styled from '@emotion/styled';

type FlexItemProps = {
	size?: number | string;
	fitContent?: boolean;
};

export const FlexItem = styled.div<FlexItemProps>`
	flex: ${({ size }) => size || 1};
	min-width: ${({ fitContent }) => (fitContent ? 'fit-content' : 0)};
`;
