import { DataTableSidebarFilters } from './dataTableFilters/DataTableSidebarFilters';
import { DataTableToolbarFilters } from './dataTableFilters/DataTableToolbarFilters';
import { DateRangeState, FilterDateRange } from './dataTableFilters/shared/DateRangeFilter';
import { FilterMultipleCheckbox } from './dataTableFilters/shared/MultipleCheckboxFilter';
import { FilterNumberRange, NumberRangeState } from './dataTableFilters/shared/NumberRangeFilter';
import { FilterSearchMultipleCheckbox } from './dataTableFilters/shared/SearchMultipleCheckboxFilter';
import { FilterText } from './dataTableFilters/shared/TextFilter';

export type BaseSearch = {
	[key: string]: string | string[] | DateRangeState | NumberRangeState;
} & {
	searchText: string;
};

export type Filters<Search extends BaseSearch> = {
	[Key in Extract<keyof Omit<Search, 'searchText'>, string>]: Filter<Search, Key> & {
		label: string;
	};
}[Extract<keyof Omit<Search, 'searchText'>, string>][];

type IsArray<T> = T extends Array<any> ? true : false;

export type Filter<
	Search extends BaseSearch,
	Key extends keyof Search = keyof Search
> = Search[Key] extends DateRangeState
	? FilterDateRange<Search, Key>
	: Search[Key] extends NumberRangeState
	? FilterNumberRange<Search, Key>
	: IsArray<Search[Key]> extends true
	? FilterMultipleCheckbox<Search, Key> | FilterSearchMultipleCheckbox<Search, Key>
	: FilterText<Search, Key>;

type DataTableFiltersProps<Search extends BaseSearch> = {
	filters: Filters<Search>;
	layout: 'sidebar' | 'toolbar';
	search: Search;
	onSearchChange: (search: Search) => void;
};

export const DataTableFilters = <Search extends BaseSearch>({
	filters,
	layout,
	search,
	onSearchChange
}: DataTableFiltersProps<Search>) => {
	return layout === 'sidebar' ? (
		<DataTableSidebarFilters filters={filters} search={search} onSearchChange={onSearchChange} />
	) : (
		<DataTableToolbarFilters filters={filters} search={search} onSearchChange={onSearchChange} />
	);
};
