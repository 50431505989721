import styled from '@emotion/styled';

type LegendProps = {
	optional?: boolean;
};

export const Legend = styled.legend<LegendProps>`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.sizes.getSize(0.5)};
	margin: 0;
	margin-bottom: ${({ theme }) => theme.sizes.getSize(2)};
	${({ theme }) => theme.textSettings.default};
	${({ theme }) => theme.fontWeight.medium};

	${({ optional = false, theme }) =>
		optional &&
		`
		:after {
			content: ' (optional)';
			color: ${theme.colors.gray.lighten40};
			${theme.textSettings.S};
			${theme.fontWeight.regular};
		}
	`}
`;

export const LegendIcon = styled.span`
	margin-left: 8px;
	height: 16px;
	width: 16px;

	svg {
		width: 100%;
		height: auto;
	}
`;
