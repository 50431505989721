// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file agentero/contact/contact.proto (package agentero.contact, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum agentero.contact.ContactLineType
 */
export enum ContactLineType {
  /**
   * @generated from enum value: PERSONAL = 0;
   */
  PERSONAL = 0,

  /**
   * @generated from enum value: COMMERCIAL = 1;
   */
  COMMERCIAL = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(ContactLineType)
proto3.util.setEnumType(ContactLineType, "agentero.contact.ContactLineType", [
  { no: 0, name: "PERSONAL" },
  { no: 1, name: "COMMERCIAL" },
]);

/**
 * @generated from message agentero.contact.Address
 */
export class Address extends Message<Address> {
  /**
   * @generated from field: string street = 1;
   */
  street = "";

  /**
   * @generated from field: string zip_code = 2;
   */
  zipCode = "";

  /**
   * @generated from field: string city = 3;
   */
  city = "";

  /**
   * @generated from field: string state = 4;
   */
  state = "";

  /**
   * @generated from field: string unit = 5;
   */
  unit = "";

  constructor(data?: PartialMessage<Address>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.contact.Address";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "street", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "unit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Address {
    return new Address().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Address {
    return new Address().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Address {
    return new Address().fromJsonString(jsonString, options);
  }

  static equals(a: Address | PlainMessage<Address> | undefined, b: Address | PlainMessage<Address> | undefined): boolean {
    return proto3.util.equals(Address, a, b);
  }
}

