// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file agentero/carrier/carrier.proto (package agentero.carrier, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum agentero.carrier.Carrier
 */
export enum Carrier {
  /**
   * @generated from enum value: UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: HIPPO = 1;
   */
  HIPPO = 1,

  /**
   * @generated from enum value: CLEAR_COVER = 2;
   */
  CLEAR_COVER = 2,

  /**
   * @generated from enum value: OPENLY = 3;
   */
  OPENLY = 3,

  /**
   * @generated from enum value: AON_EDGE = 4;
   */
  AON_EDGE = 4,

  /**
   * @generated from enum value: HAVEN_LIFE = 5;
   */
  HAVEN_LIFE = 5,

  /**
   * @generated from enum value: COVER = 6;
   */
  COVER = 6,

  /**
   * @generated from enum value: GET_COVERED = 7;
   */
  GET_COVERED = 7,

  /**
   * @generated from enum value: COTERIE = 8;
   */
  COTERIE = 8,

  /**
   * @generated from enum value: PIE = 9;
   */
  PIE = 9,

  /**
   * @generated from enum value: BRANCH = 10;
   */
  BRANCH = 10,

  /**
   * @generated from enum value: ELEPHANT = 11;
   */
  ELEPHANT = 11,

  /**
   * @generated from enum value: POUCH = 12;
   */
  POUCH = 12,

  /**
   * @generated from enum value: MILE_AUTO = 13;
   */
  MILE_AUTO = 13,

  /**
   * @generated from enum value: ETHOS = 14;
   */
  ETHOS = 14,

  /**
   * @generated from enum value: LEMONADE = 15;
   */
  LEMONADE = 15,

  /**
   * @generated from enum value: THIMBLE = 16;
   */
  THIMBLE = 16,

  /**
   * @generated from enum value: COWBELL = 17;
   */
  COWBELL = 17,

  /**
   * @generated from enum value: STILLWATER = 18;
   */
  STILLWATER = 18,

  /**
   * @generated from enum value: BAMBOO = 19;
   */
  BAMBOO = 19,

  /**
   * @generated from enum value: STATE_AUTO = 20;
   */
  STATE_AUTO = 20,

  /**
   * @generated from enum value: SENSA = 21;
   */
  SENSA = 21,

  /**
   * @generated from enum value: LADDER = 22;
   */
  LADDER = 22,

  /**
   * @generated from enum value: NEXTCARRIER = 23;
   */
  NEXTCARRIER = 23,

  /**
   * @generated from enum value: GAINSCO = 24;
   */
  GAINSCO = 24,

  /**
   * @generated from enum value: TRAVELERS = 25;
   */
  TRAVELERS = 25,

  /**
   * @generated from enum value: HOAIC = 26;
   */
  HOAIC = 26,

  /**
   * @generated from enum value: CNA = 27;
   */
  CNA = 27,

  /**
   * @generated from enum value: OBIE = 28;
   */
  OBIE = 28,

  /**
   * @generated from enum value: SAFECO = 29;
   */
  SAFECO = 29,

  /**
   * @generated from enum value: PROGRESSIVE = 30;
   */
  PROGRESSIVE = 30,

  /**
   * @generated from enum value: LAMAR = 31;
   */
  LAMAR = 31,

  /**
   * @generated from enum value: BIBERK = 32;
   */
  BIBERK = 32,

  /**
   * @generated from enum value: NATIONWIDE = 33;
   */
  NATIONWIDE = 33,

  /**
   * @generated from enum value: PATHPOINT = 34;
   */
  PATHPOINT = 34,

  /**
   * @generated from enum value: HISCOX = 35;
   */
  HISCOX = 35,

  /**
   * @generated from enum value: AMTRUST = 36;
   */
  AMTRUST = 36,

  /**
   * @generated from enum value: GAIG = 37;
   */
  GAIG = 37,

  /**
   * @generated from enum value: AMIG = 38;
   */
  AMIG = 38,

  /**
   * @generated from enum value: FOREMOST = 39;
   */
  FOREMOST = 39,

  /**
   * @generated from enum value: BRISTOL_WEST = 40;
   */
  BRISTOL_WEST = 40,

  /**
   * @generated from enum value: WESTFIELD = 41;
   */
  WESTFIELD = 41,

  /**
   * @generated from enum value: LONESTAR = 42;
   */
  LONESTAR = 42,

  /**
   * @generated from enum value: DAIRYLAND = 43;
   */
  DAIRYLAND = 43,

  /**
   * @generated from enum value: DELOS = 44;
   */
  DELOS = 44,

  /**
   * @generated from enum value: TRAVELERS_COMMERCIAL = 45;
   */
  TRAVELERS_COMMERCIAL = 45,

  /**
   * @generated from enum value: EMPLOYERS = 46;
   */
  EMPLOYERS = 46,

  /**
   * @generated from enum value: STEADILY = 47;
   */
  STEADILY = 47,

  /**
   * @generated from enum value: ASSURANCE_AMERICA = 48;
   */
  ASSURANCE_AMERICA = 48,

  /**
   * @generated from enum value: COMMONWEALTH = 49;
   */
  COMMONWEALTH = 49,

  /**
   * @generated from enum value: WELLINGTON = 50;
   */
  WELLINGTON = 50,

  /**
   * @generated from enum value: LIO = 51;
   */
  LIO = 51,

  /**
   * @generated from enum value: SWYFFT = 52;
   */
  SWYFFT = 52,

  /**
   * @generated from enum value: NEPTUNE = 53;
   */
  NEPTUNE = 53,

  /**
   * @generated from enum value: ORION180 = 54;
   */
  ORION180 = 54,

  /**
   * @generated from enum value: STONEGATE = 55;
   */
  STONEGATE = 55,

  /**
   * @generated from enum value: UNIQUE = 56;
   */
  UNIQUE = 56,

  /**
   * @generated from enum value: THE_GENERAL = 57;
   */
  THE_GENERAL = 57,

  /**
   * @generated from enum value: FOREMOST_SIGNATURE = 58;
   */
  FOREMOST_SIGNATURE = 58,

  /**
   * @generated from enum value: RAINBOW = 59;
   */
  RAINBOW = 59,

  /**
   * @generated from enum value: ASPIRE = 60;
   */
  ASPIRE = 60,

  /**
   * @generated from enum value: CHUBB = 61;
   */
  CHUBB = 61,

  /**
   * @generated from enum value: HANOVER = 62;
   */
  HANOVER = 62,

  /**
   * @generated from enum value: CHUBB_PERSONAL = 63;
   */
  CHUBB_PERSONAL = 63,

  /**
   * @generated from enum value: SES = 64;
   */
  SES = 64,

  /**
   * @generated from enum value: BOUNDLESS_RIDER = 65;
   */
  BOUNDLESS_RIDER = 65,

  /**
   * @generated from enum value: ROOT = 66;
   */
  ROOT = 66,

  /**
   * @generated from enum value: KEMPER = 67;
   */
  KEMPER = 67,

  /**
   * @generated from enum value: KEMPER_COMMERCIAL = 68;
   */
  KEMPER_COMMERCIAL = 68,

  /**
   * @generated from enum value: THREE = 69;
   */
  THREE = 69,
}
// Retrieve enum metadata with: proto3.getEnumType(Carrier)
proto3.util.setEnumType(Carrier, "agentero.carrier.Carrier", [
  { no: 0, name: "UNDEFINED" },
  { no: 1, name: "HIPPO" },
  { no: 2, name: "CLEAR_COVER" },
  { no: 3, name: "OPENLY" },
  { no: 4, name: "AON_EDGE" },
  { no: 5, name: "HAVEN_LIFE" },
  { no: 6, name: "COVER" },
  { no: 7, name: "GET_COVERED" },
  { no: 8, name: "COTERIE" },
  { no: 9, name: "PIE" },
  { no: 10, name: "BRANCH" },
  { no: 11, name: "ELEPHANT" },
  { no: 12, name: "POUCH" },
  { no: 13, name: "MILE_AUTO" },
  { no: 14, name: "ETHOS" },
  { no: 15, name: "LEMONADE" },
  { no: 16, name: "THIMBLE" },
  { no: 17, name: "COWBELL" },
  { no: 18, name: "STILLWATER" },
  { no: 19, name: "BAMBOO" },
  { no: 20, name: "STATE_AUTO" },
  { no: 21, name: "SENSA" },
  { no: 22, name: "LADDER" },
  { no: 23, name: "NEXTCARRIER" },
  { no: 24, name: "GAINSCO" },
  { no: 25, name: "TRAVELERS" },
  { no: 26, name: "HOAIC" },
  { no: 27, name: "CNA" },
  { no: 28, name: "OBIE" },
  { no: 29, name: "SAFECO" },
  { no: 30, name: "PROGRESSIVE" },
  { no: 31, name: "LAMAR" },
  { no: 32, name: "BIBERK" },
  { no: 33, name: "NATIONWIDE" },
  { no: 34, name: "PATHPOINT" },
  { no: 35, name: "HISCOX" },
  { no: 36, name: "AMTRUST" },
  { no: 37, name: "GAIG" },
  { no: 38, name: "AMIG" },
  { no: 39, name: "FOREMOST" },
  { no: 40, name: "BRISTOL_WEST" },
  { no: 41, name: "WESTFIELD" },
  { no: 42, name: "LONESTAR" },
  { no: 43, name: "DAIRYLAND" },
  { no: 44, name: "DELOS" },
  { no: 45, name: "TRAVELERS_COMMERCIAL" },
  { no: 46, name: "EMPLOYERS" },
  { no: 47, name: "STEADILY" },
  { no: 48, name: "ASSURANCE_AMERICA" },
  { no: 49, name: "COMMONWEALTH" },
  { no: 50, name: "WELLINGTON" },
  { no: 51, name: "LIO" },
  { no: 52, name: "SWYFFT" },
  { no: 53, name: "NEPTUNE" },
  { no: 54, name: "ORION180" },
  { no: 55, name: "STONEGATE" },
  { no: 56, name: "UNIQUE" },
  { no: 57, name: "THE_GENERAL" },
  { no: 58, name: "FOREMOST_SIGNATURE" },
  { no: 59, name: "RAINBOW" },
  { no: 60, name: "ASPIRE" },
  { no: 61, name: "CHUBB" },
  { no: 62, name: "HANOVER" },
  { no: 63, name: "CHUBB_PERSONAL" },
  { no: 64, name: "SES" },
  { no: 65, name: "BOUNDLESS_RIDER" },
  { no: 66, name: "ROOT" },
  { no: 67, name: "KEMPER" },
  { no: 68, name: "KEMPER_COMMERCIAL" },
  { no: 69, name: "THREE" },
]);

/**
 * @generated from enum agentero.carrier.AppointmentStatus
 */
export enum AppointmentStatus {
  /**
   * @generated from enum value: UNDEFINED_STATUS = 0;
   */
  UNDEFINED_STATUS = 0,

  /**
   * @generated from enum value: UNAVAILABLE = 1;
   */
  UNAVAILABLE = 1,

  /**
   * @generated from enum value: AVAILABLE = 2;
   */
  AVAILABLE = 2,

  /**
   * @generated from enum value: REJECTED = 3;
   */
  REJECTED = 3,

  /**
   * @generated from enum value: APPOINTED = 4;
   */
  APPOINTED = 4,

  /**
   * @generated from enum value: IN_PROGRESS = 5;
   */
  IN_PROGRESS = 5,

  /**
   * @generated from enum value: NOT_ELIGIBLE = 6;
   */
  NOT_ELIGIBLE = 6,

  /**
   * @generated from enum value: ANOTHER_AGGREGATOR = 7;
   */
  ANOTHER_AGGREGATOR = 7,

  /**
   * @generated from enum value: NON_SOLICITATION = 8;
   */
  NON_SOLICITATION = 8,

  /**
   * @generated from enum value: PENDING = 9;
   */
  PENDING = 9,

  /**
   * @generated from enum value: TERMINATED = 10;
   */
  TERMINATED = 10,

  /**
   * @generated from enum value: HIDE = 11;
   */
  HIDE = 11,
}
// Retrieve enum metadata with: proto3.getEnumType(AppointmentStatus)
proto3.util.setEnumType(AppointmentStatus, "agentero.carrier.AppointmentStatus", [
  { no: 0, name: "UNDEFINED_STATUS" },
  { no: 1, name: "UNAVAILABLE" },
  { no: 2, name: "AVAILABLE" },
  { no: 3, name: "REJECTED" },
  { no: 4, name: "APPOINTED" },
  { no: 5, name: "IN_PROGRESS" },
  { no: 6, name: "NOT_ELIGIBLE" },
  { no: 7, name: "ANOTHER_AGGREGATOR" },
  { no: 8, name: "NON_SOLICITATION" },
  { no: 9, name: "PENDING" },
  { no: 10, name: "TERMINATED" },
  { no: 11, name: "HIDE" },
]);

/**
 * @generated from enum agentero.carrier.QuotingMode
 */
export enum QuotingMode {
  /**
   * No Quoting
   *
   * @generated from enum value: NONE = 0;
   */
  NONE = 0,

  /**
   * Quoting using Carrier API
   *
   * @generated from enum value: API = 1;
   */
  API = 1,

  /**
   * Quoting using third party rater
   *
   * @generated from enum value: RATER = 2;
   */
  RATER = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(QuotingMode)
proto3.util.setEnumType(QuotingMode, "agentero.carrier.QuotingMode", [
  { no: 0, name: "NONE" },
  { no: 1, name: "API" },
  { no: 2, name: "RATER" },
]);

/**
 * ComparativeRaterType represents the possible comparative raters.
 *
 * @generated from enum agentero.carrier.ComparativeRaterType
 */
export enum ComparativeRaterType {
  /**
   * @generated from enum value: COMPARATIVE_RATER_UNDEFINED = 0;
   */
  COMPARATIVE_RATER_UNDEFINED = 0,

  /**
   * @generated from enum value: EZLYNX = 1;
   */
  EZLYNX = 1,

  /**
   * @generated from enum value: PL_RATER = 2;
   */
  PL_RATER = 2,

  /**
   * @generated from enum value: ITC_TURBORATER = 3;
   */
  ITC_TURBORATER = 3,

  /**
   * @generated from enum value: FSC = 4;
   */
  FSC = 4,

  /**
   * @generated from enum value: BOLT = 5;
   */
  BOLT = 5,

  /**
   * @generated from enum value: QRP = 6;
   */
  QRP = 6,

  /**
   * @generated from enum value: OTHER = 7;
   */
  OTHER = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(ComparativeRaterType)
proto3.util.setEnumType(ComparativeRaterType, "agentero.carrier.ComparativeRaterType", [
  { no: 0, name: "COMPARATIVE_RATER_UNDEFINED" },
  { no: 1, name: "EZLYNX" },
  { no: 2, name: "PL_RATER" },
  { no: 3, name: "ITC_TURBORATER" },
  { no: 4, name: "FSC" },
  { no: 5, name: "BOLT" },
  { no: 6, name: "QRP" },
  { no: 7, name: "OTHER" },
]);

/**
 * @generated from enum agentero.carrier.ApprovalStatus
 */
export enum ApprovalStatus {
  /**
   * @generated from enum value: APPROVAL_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: APPROVAL_STATUS_APPROVED = 1;
   */
  APPROVED = 1,

  /**
   * @generated from enum value: APPROVAL_STATUS_NOT_APPROVED = 2;
   */
  NOT_APPROVED = 2,

  /**
   * @generated from enum value: APPROVAL_STATUS_REQUESTED = 3;
   */
  REQUESTED = 3,

  /**
   * @generated from enum value: APPROVAL_STATUS_NOT_REQUESTED = 4;
   */
  NOT_REQUESTED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ApprovalStatus)
proto3.util.setEnumType(ApprovalStatus, "agentero.carrier.ApprovalStatus", [
  { no: 0, name: "APPROVAL_STATUS_UNSPECIFIED" },
  { no: 1, name: "APPROVAL_STATUS_APPROVED" },
  { no: 2, name: "APPROVAL_STATUS_NOT_APPROVED" },
  { no: 3, name: "APPROVAL_STATUS_REQUESTED" },
  { no: 4, name: "APPROVAL_STATUS_NOT_REQUESTED" },
]);

