export enum State {
	AL = 'AL',
	AK = 'AK',
	AS = 'AS',
	AZ = 'AZ',
	AR = 'AR',
	CA = 'CA',
	CO = 'CO',
	CT = 'CT',
	DE = 'DE',
	DC = 'DC',
	FM = 'FM',
	FL = 'FL',
	GA = 'GA',
	GU = 'GU',
	HI = 'HI',
	ID = 'ID',
	IL = 'IL',
	IN = 'IN',
	IA = 'IA',
	KS = 'KS',
	KY = 'KY',
	LA = 'LA',
	ME = 'ME',
	MH = 'MH',
	MD = 'MD',
	MA = 'MA',
	MI = 'MI',
	MN = 'MN',
	MS = 'MS',
	MO = 'MO',
	MT = 'MT',
	NE = 'NE',
	NV = 'NV',
	NH = 'NH',
	NJ = 'NJ',
	NM = 'NM',
	NY = 'NY',
	NC = 'NC',
	ND = 'ND',
	MP = 'MP',
	OH = 'OH',
	OK = 'OK',
	OR = 'OR',
	PW = 'PW',
	PA = 'PA',
	PR = 'PR',
	RI = 'RI',
	SC = 'SC',
	SD = 'SD',
	TN = 'TN',
	TX = 'TX',
	UT = 'UT',
	VT = 'VT',
	VI = 'VI',
	VA = 'VA',
	WA = 'WA',
	WV = 'WV',
	WI = 'WI',
	WY = 'WY'
}

export const getStatesString = (): string[] => Object.keys(State);

export const parseStateFromString = (state: State): State => State[state] || state;

export const states = [
	{ name: 'Alabama', abbreviation: 'AL' },
	{ name: 'Alaska', abbreviation: 'AK' },
	{ name: 'Arizona', abbreviation: 'AZ' },
	{ name: 'Arkansas', abbreviation: 'AR' },
	{ name: 'California', abbreviation: 'CA' },
	{ name: 'Colorado', abbreviation: 'CO' },
	{ name: 'Connecticut', abbreviation: 'CT' },
	{ name: 'District of Columbia', abbreviation: 'DC' },
	{ name: 'Delaware', abbreviation: 'DE' },
	{ name: 'Florida', abbreviation: 'FL' },
	{ name: 'Georgia', abbreviation: 'GA' },
	{ name: 'Hawaii', abbreviation: 'HI' },
	{ name: 'Idaho', abbreviation: 'ID' },
	{ name: 'Illinois', abbreviation: 'IL' },
	{ name: 'Indiana', abbreviation: 'IN' },
	{ name: 'Iowa', abbreviation: 'IA' },
	{ name: 'Kansas', abbreviation: 'KS' },
	{ name: 'Kentucky', abbreviation: 'KY' },
	{ name: 'Louisiana', abbreviation: 'LA' },
	{ name: 'Maine', abbreviation: 'ME' },
	{ name: 'Maryland', abbreviation: 'MD' },
	{ name: 'Massachusetts', abbreviation: 'MA' },
	{ name: 'Michigan', abbreviation: 'MI' },
	{ name: 'Minnesota', abbreviation: 'MN' },
	{ name: 'Mississippi', abbreviation: 'MS' },
	{ name: 'Missouri', abbreviation: 'MO' },
	{ name: 'Montana', abbreviation: 'MT' },
	{ name: 'Nebraska', abbreviation: 'NE' },
	{ name: 'Nevada', abbreviation: 'NV' },
	{ name: 'New Hampshire', abbreviation: 'NH' },
	{ name: 'New Jersey', abbreviation: 'NJ' },
	{ name: 'New Mexico', abbreviation: 'NM' },
	{ name: 'New York', abbreviation: 'NY' },
	{ name: 'North Carolina', abbreviation: 'NC' },
	{ name: 'North Dakota', abbreviation: 'ND' },
	{ name: 'Ohio', abbreviation: 'OH' },
	{ name: 'Oklahoma', abbreviation: 'OK' },
	{ name: 'Oregon', abbreviation: 'OR' },
	{ name: 'Pennsylvania', abbreviation: 'PA' },
	{ name: 'Puerto Rico', abbreviation: 'PR' },
	{ name: 'Rhode Island', abbreviation: 'RI' },
	{ name: 'South Carolina', abbreviation: 'SC' },
	{ name: 'South Dakota', abbreviation: 'SD' },
	{ name: 'Tennessee', abbreviation: 'TN' },
	{ name: 'Texas', abbreviation: 'TX' },
	{ name: 'Utah', abbreviation: 'UT' },
	{ name: 'Vermont', abbreviation: 'VT' },
	{ name: 'Virginia', abbreviation: 'VA' },
	{ name: 'Washington', abbreviation: 'WA' },
	{ name: 'West Virginia', abbreviation: 'WV' },
	{ name: 'Wisconsin', abbreviation: 'WI' },
	{ name: 'Wyoming', abbreviation: 'WY' }
];

export const getStateNameFromAbbreviation = (abbreviation: string): string => {
	const state = states.find(state => state.abbreviation === abbreviation);
	return state ? state.name : '';
};

export const getStateOptions = () =>
	states.map(state => ({ label: state.name, value: state.abbreviation }));
