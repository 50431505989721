import React from 'react';

import { Global, css } from '@emotion/react';

import { base } from './base';
import { emotionNormalize } from './emotionNormalize';
import { emotionReset } from './emotionReset';
import { variables } from './variables';

export const AgenteroGlobalStyles = () => (
	<Global
		styles={css`
			${emotionReset}
			${emotionNormalize}
			${variables}
			${base}
		`}
	/>
);
