import { PropsWithChildren } from 'react';

import styled from '@emotion/styled';

type ItemProps = {
	isVisible?: boolean;
	mt?: number;
	mb?: number;
	className?: string;
};

export const ItemContainer = styled.div<Omit<ItemProps, 'isVisible'>>`
	${({ mt }) => mt && `margin-top: ${mt}px;`};
	${({ mb }) => mb && `margin-bottom: ${mb}px;`};
`;

export const Item = ({
	isVisible = true,
	mt,
	mb,
	children,
	className
}: PropsWithChildren<ItemProps>) =>
	isVisible ? (
		<ItemContainer className={className} mt={mt} mb={mb}>
			{children}
		</ItemContainer>
	) : null;
