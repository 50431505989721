import { ChangeEvent, PropsWithChildren } from 'react';

import { getInputStatusFromError } from '@agentero/utils';

import { useAgFormContext } from '../Form';
import { Field, FieldGenericProps } from '../shared/Field';
import { getFieldError, getFieldErrorId, removeAllDots } from '../shared/formGroupUtils';
import { InputVariants } from './fieldText/Input';
import { TextArea } from './fieldTextArea/TextArea';

type FieldTextAreaProps<T> = {
	placeholder?: string;
	autoFocus?: boolean;
	maxLength?: number;
	disabled?: boolean;
	onChange?: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined;
	defaultValue?: string | number | undefined;
} & FieldGenericProps<T> &
	InputVariants;

export const FieldTextArea = <T extends {}>({
	name,
	placeholder,
	autoFocus,
	maxLength,
	disabled,
	onChange,
	children,
	defaultValue,
	size,
	...formGroupProps
}: PropsWithChildren<FieldTextAreaProps<T>>) => {
	const {
		register,
		formState: { errors }
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	return (
		<Field name={name} nestedChildren={children} {...formGroupProps}>
			<TextArea
				id={removeAllDots(name)}
				{...register(name, { onChange })}
				status={getInputStatusFromError(fieldError?.message as string)}
				placeholder={placeholder}
				aria-invalid={!!fieldError?.message}
				aria-errormessage={getFieldErrorId(name)}
				autoFocus={autoFocus}
				maxLength={maxLength}
				disabled={disabled}
				defaultValue={defaultValue}
				size={size}
			/>
		</Field>
	);
};
