import { OperateAs as OperateAsProto } from '@agentero/grpc-clients/grpc/agency-fe';
import { OperateAs as OperateAsConnect } from '@agentero/service-clients/portal/agency';

export enum OperateAs {
	Unspecified = 'unspecified',
	Agency = 'agency',
	Agent = 'agent'
}

const operateAsMap: { [key in OperateAsProto]: OperateAs } = {
	[OperateAsProto.OPERATE_AS_UNSPECIFIED]: OperateAs.Unspecified,
	[OperateAsProto.OPERATE_AS_AGENCY]: OperateAs.Agency,
	[OperateAsProto.OPERATE_AS_AGENT]: OperateAs.Agent
};

export const parseOperateAs = (operateAs: OperateAsProto): OperateAs => operateAsMap[operateAs];

const operateAsMapToProto: { [key in OperateAs]: OperateAsProto } = {
	[OperateAs.Unspecified]: OperateAsProto.OPERATE_AS_UNSPECIFIED,
	[OperateAs.Agency]: OperateAsProto.OPERATE_AS_AGENCY,
	[OperateAs.Agent]: OperateAsProto.OPERATE_AS_AGENT
};

export const parseOperateAsToProto = (operateAs: OperateAs): OperateAsProto =>
	operateAsMapToProto[operateAs];

const operateAsMapConnect: { [key in OperateAsProto]: OperateAs } = {
	[OperateAsConnect.UNSPECIFIED]: OperateAs.Unspecified,
	[OperateAsConnect.AGENCY]: OperateAs.Agency,
	[OperateAsConnect.AGENT]: OperateAs.Agent
};

export const parseOperateAsConnect = (operateAs: OperateAsConnect): OperateAs =>
	operateAsMapConnect[operateAs];
