import { EAndOUploadedPolicyType } from '@agentero/grpc-clients/grpc/agency-fe';

export enum EOPolicyType {
	FullPolicy = 'fullPolicy',
	PartialPolicy = 'partialPolicy'
}

const mapToProto = {
	[EOPolicyType.FullPolicy]: EAndOUploadedPolicyType.E_AND_O_UPLOADED_POLICY_FULL,
	[EOPolicyType.PartialPolicy]: EAndOUploadedPolicyType.E_AND_O_UPLOADED_POLICY_PARTIAL
};

const mapFromProto = {
	[EAndOUploadedPolicyType.E_AND_O_UPLOADED_POLICY_UNSPECIFIED]: undefined,
	[EAndOUploadedPolicyType.E_AND_O_UPLOADED_POLICY_FULL]: EOPolicyType.FullPolicy,
	[EAndOUploadedPolicyType.E_AND_O_UPLOADED_POLICY_PARTIAL]: EOPolicyType.PartialPolicy
};

export const parseEOPolicyType = (eaoPolicyType: EOPolicyType): EAndOUploadedPolicyType => {
	return mapToProto[eaoPolicyType];
};

export const parseEOPolicyTypeFromProto = (
	value: EAndOUploadedPolicyType
): EOPolicyType | undefined => {
	return mapFromProto[value];
};
