import { useEffect } from 'react';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { addLog } from 'packages/utilities/logger';

import { loadSnippet } from './shared';

declare global {
	interface Window {
		HubSpotConversations?: {
			widget: any;
			on: any;
		};
		hsConversationsSettings?: any;
	}
}

const hubspotTokenUrl =
	process.env.NEXT_PUBLIC_ENVIRONMENT === 'local'
		? `${process.env.NEXT_PUBLIC_LOCAL_API_URL}/hubspot_token`
		: `${process.env.NEXT_PUBLIC_API_URL}/api/hubspot_token`;

export const HubspotChat = () => {
	const {
		data: { email }
	} = useAgentResource();

	useEffect(() => {
		loadSnippet('https://js.hs-scripts.com/5288569.js')
			.then(() => {
				window.hsConversationsSettings = {
					loadImmediately: false
				};
				fetch(hubspotTokenUrl, {
					method: 'POST',
					body: JSON.stringify({ email })
				})
					.then(response => response.json())
					.then(({ token }) => {
						window.hsConversationsSettings = {
							identificationEmail: email,
							identificationToken: token
						};

						window.HubSpotConversations?.widget && window.HubSpotConversations.widget.load();
					})
					.catch(error => addLog(error));
			})
			.catch(error => addLog(error));
		return;
	}, []);
	return null;
};

export const refreshChat = (options = {}) => {
	if (window?.HubSpotConversations?.widget?.refresh)
		setTimeout(() => window.HubSpotConversations.widget.refresh(options), 1000);
};

export const openChat = () => {
	if (window?.HubSpotConversations?.widget?.open)
		setTimeout(() => window.HubSpotConversations.widget.open(), 4000);
};

export const closeChat = () => {
	if (window?.HubSpotConversations?.widget?.open) window.HubSpotConversations.widget.close();
};

export const removeChat = (clearCookies?: boolean) => {
	window?.HubSpotConversations?.widget?.remove(clearCookies && { resetWidget: true });
};

export const forceRemoveChat = () => {
	let attemptCount = 0;
	const maxAttempts = 10;
	const intervalDelay = 500; // milliseconds

	const removeWidgetChat = setInterval(() => {
		removeChat();
		if (attemptCount >= maxAttempts) {
			clearInterval(removeWidgetChat);
		}

		attemptCount++;
	}, intervalDelay);
};

export const removeDuplicateChat = () => {
	let attemptCount = 0;
	const maxAttempts = 10;
	const intervalDelay = 500; // milliseconds
	const widgetSelector = '#hubspot-conversations-inline-iframe';

	const hideDuplicateWidget = setInterval(() => {
		const widgets = document.querySelectorAll(widgetSelector);

		if (widgets.length > 1) {
			widgets[widgets.length - 1].remove(); // Remove the last widget
			clearInterval(hideDuplicateWidget);
		} else if (attemptCount >= maxAttempts) {
			clearInterval(hideDuplicateWidget);
		}

		attemptCount++;
	}, intervalDelay);
};
