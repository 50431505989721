import type { Interceptor } from '@connectrpc/connect';

import { getAgentData } from 'packages/services/fetch/back-ag/useAgentResource';

export const authInterceptor: Interceptor = next => async req => {
	const agent = getAgentData();

	if (agent) {
		req.header.set('Authorization', `Bearer ${agent.token}`);
	}

	const res = await next(req);

	return res;
};
