import styled from '@emotion/styled';

import { Grid } from './Grid';
import { Space } from './Space';
import { Spinner } from './Spinner';
import { Text } from './Typography';
import { GridItem } from './grid/GridItem';
import { TrackerItem, TrackerItemProps } from './tracker/TrackerItem';

export enum TrackerItemStatus {
	Default,
	Disabled,
	Ready
}

export type TrackerList = {
	title: string;
	list: TrackerItemProps[];
};

type TrackerProps = {
	lists: TrackerList[];
	isLoading: boolean;
	columns?: 1 | 2;
	onItemClick?: (item: TrackerItemProps) => void;
	idSelected?: string;
};

const topSpace = 16;

const TrackerSpinner = styled(Spinner)`
	position: absolute;
	right: 0;
	top: ${topSpace}px;
`;

const TrackerContainer = styled.div`
	position: relative;
`;

export const Tracker = ({
	lists,
	isLoading,
	onItemClick,
	idSelected,
	columns = 1
}: TrackerProps) => {
	return (
		<TrackerContainer>
			<Space spacing={topSpace} direction="column" role="none">
				{lists.map(
					({ title, list }) =>
						list.length > 0 && (
							<Space key={title} spacing={12} direction="column" role="none">
								<Text as="h2">
									<b>{title}</b>
								</Text>

								<Grid role="list" spacing={columns === 1 ? 0 : 56}>
									{list.map(item => (
										<GridItem key={item.label} md={columns === 1 ? 12 : 6} role="listitem">
											<TrackerItem
												{...item}
												onClick={onItemClick ? () => onItemClick?.(item) : undefined}
												isSelected={idSelected === item.id}
											/>
										</GridItem>
									))}
								</Grid>
							</Space>
						)
				)}
			</Space>
			{isLoading && <TrackerSpinner size={24} />}
		</TrackerContainer>
	);
};
