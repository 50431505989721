import {
	MutableRefObject,
	PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useRef
} from 'react';

import { useRouter } from 'next/router';

export type LastURL = {
	lastURL: MutableRefObject<string | null>;
	goBack: ({ defaultRoute }: { defaultRoute: string }) => void;
};

const URLContext = createContext<LastURL>({
	lastURL: { current: null },
	goBack: null
});

export const URLProvider = ({ children }: PropsWithChildren) => {
	const lastURL = useRef<string | null>(null);
	const {
		push,
		pathname,
		asPath,
		//TODO: unify criteria about query param id pages
		query: { id }
	} = useRouter();

	useEffect(() => {
		return () => {
			lastURL.current = `${asPath}`;
		};
	}, [pathname, id]);

	const goBack = ({ defaultRoute }) => {
		if (lastURL.current) {
			push(lastURL.current);
			return;
		}

		push(defaultRoute);
	};

	return <URLContext.Provider value={{ lastURL, goBack }}>{children}</URLContext.Provider>;
};

export const useLastURLContext = () => useContext(URLContext);
