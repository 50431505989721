import styled from '@emotion/styled';

export const ModalContent = styled.div`
	position: relative;
	z-index: 1;
	flex: 1;
	display: flex;
	flex-direction: column;
	min-width: 0;
	width: 100%;
	min-height: 0;
`;
