import styled from '@emotion/styled';

type ChipProps = {
	label?: string;
	Icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
};

const ChipContainer = styled.div<{ round: boolean }>`
	display: inline-flex;
	align-items: center;
	gap: ${({ theme }) => theme.sizes.getSize(1)};
	min-width: 1.75rem;
	height: 1.75rem;
	padding-inline: ${({ round }) => (round ? 0.375 : 1)}rem;
	background: ${({ theme }) => theme.colors.gray.lighten90Alpha};
	border-radius: 1rem;
`;

const ChipIcon = styled.div`
	width: 1rem;
	height: 1rem;

	svg {
		width: 100%;
		height: 100%;
		vertical-align: top;

		path,
		rect {
			fill: ${({ theme }) => theme.colors.gray.lighten40};
		}
	}
`;

const ChipLabel = styled.div`
	${({ theme }) => theme.textSettings.S};
	color: ${({ theme }) => theme.colors.gray.lighten40};

	${({ theme }) => theme.mediaqueries.S} {
		display: block;
	}
`;

export const Chip = ({ Icon, label }: ChipProps) => (
	<ChipContainer round={!label}>
		{Icon && (
			<ChipIcon>
				<Icon />
			</ChipIcon>
		)}
		{label && <ChipLabel>{label}</ChipLabel>}
	</ChipContainer>
);
