import { PropsWithChildren, Suspense } from 'react';

import { ListTableBody } from './ListTableBody';

export type ListTableBodySuspenseProps = {
	fallbackRow: React.ComponentType<{ item: unknown }>;
	items?: number;
};

export const ListTableBodySuspense = ({
	children,
	fallbackRow,
	items = 20
}: PropsWithChildren<ListTableBodySuspenseProps>) => (
	<Suspense
		fallback={
			<ListTableBody<any> items={Array.from({ length: items })} isSkeleton={true}>
				{fallbackRow}
			</ListTableBody>
		}>
		{children}
	</Suspense>
);
