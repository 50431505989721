import { useEffect, useState } from 'react';

export const useLocalStorage = <T>(key: string, initialValue: T) => {
	const isClient = typeof window !== 'undefined';
	const [storedValue, setStoredValue] = useState<T>(() => {
		if (isClient) {
			const item = window.localStorage.getItem(key);
			return item ? JSON.parse(item) : initialValue;
		}
		return initialValue;
	});

	useEffect(() => {
		if (isClient) {
			if (storedValue === null) {
				window.localStorage.removeItem(key);
			} else {
				window.localStorage.setItem(key, JSON.stringify(storedValue));
			}
		}
	}, [isClient, key, storedValue]);

	return [storedValue, setStoredValue] as const;
};
