// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file agentero/form/form.proto (package agentero.form, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Carrier } from "../carrier/carrier_pb";

/**
 * @generated from enum agentero.form.CoverageType
 */
export enum CoverageType {
  /**
   * @generated from enum value: COVERAGE_TYPE_UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: COVERAGE_TYPE_DRIVER = 1;
   */
  DRIVER = 1,

  /**
   * @generated from enum value: COVERAGE_TYPE_VEHICLE = 2;
   */
  VEHICLE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(CoverageType)
proto3.util.setEnumType(CoverageType, "agentero.form.CoverageType", [
  { no: 0, name: "COVERAGE_TYPE_UNDEFINED" },
  { no: 1, name: "COVERAGE_TYPE_DRIVER" },
  { no: 2, name: "COVERAGE_TYPE_VEHICLE" },
]);

/**
 * @generated from enum agentero.form.Size
 */
export enum Size {
  /**
   * @generated from enum value: SIZE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SIZE_SMALL = 1;
   */
  SMALL = 1,

  /**
   * @generated from enum value: SIZE_MEDIUM = 2;
   */
  MEDIUM = 2,

  /**
   * @generated from enum value: SIZE_MEDIUM_LARGE = 4;
   */
  MEDIUM_LARGE = 4,

  /**
   * @generated from enum value: SIZE_LARGE = 3;
   */
  LARGE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Size)
proto3.util.setEnumType(Size, "agentero.form.Size", [
  { no: 0, name: "SIZE_UNSPECIFIED" },
  { no: 1, name: "SIZE_SMALL" },
  { no: 2, name: "SIZE_MEDIUM" },
  { no: 4, name: "SIZE_MEDIUM_LARGE" },
  { no: 3, name: "SIZE_LARGE" },
]);

/**
 * @generated from message agentero.form.RaterForm
 */
export class RaterForm extends Message<RaterForm> {
  /**
   * @generated from field: repeated agentero.form.Step steps = 1;
   */
  steps: Step[] = [];

  /**
   * @generated from field: repeated agentero.carrier.Carrier carriers = 2;
   */
  carriers: Carrier[] = [];

  constructor(data?: PartialMessage<RaterForm>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.RaterForm";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "steps", kind: "message", T: Step, repeated: true },
    { no: 2, name: "carriers", kind: "enum", T: proto3.getEnumType(Carrier), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RaterForm {
    return new RaterForm().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RaterForm {
    return new RaterForm().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RaterForm {
    return new RaterForm().fromJsonString(jsonString, options);
  }

  static equals(a: RaterForm | PlainMessage<RaterForm> | undefined, b: RaterForm | PlainMessage<RaterForm> | undefined): boolean {
    return proto3.util.equals(RaterForm, a, b);
  }
}

/**
 * @generated from message agentero.form.Step
 */
export class Step extends Message<Step> {
  /**
   * @generated from oneof agentero.form.Step.step
   */
  step: {
    /**
     * @generated from field: agentero.form.BasicStep basic_step = 1;
     */
    value: BasicStep;
    case: "basicStep";
  } | {
    /**
     * @generated from field: agentero.form.CarrierStep carrier_step = 2;
     */
    value: CarrierStep;
    case: "carrierStep";
  } | {
    /**
     * @generated from field: agentero.form.ListStep list_step = 3;
     */
    value: ListStep;
    case: "listStep";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Step>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Step";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "basic_step", kind: "message", T: BasicStep, oneof: "step" },
    { no: 2, name: "carrier_step", kind: "message", T: CarrierStep, oneof: "step" },
    { no: 3, name: "list_step", kind: "message", T: ListStep, oneof: "step" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Step {
    return new Step().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Step {
    return new Step().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Step {
    return new Step().fromJsonString(jsonString, options);
  }

  static equals(a: Step | PlainMessage<Step> | undefined, b: Step | PlainMessage<Step> | undefined): boolean {
    return proto3.util.equals(Step, a, b);
  }
}

/**
 * @generated from message agentero.form.BasicStep
 */
export class BasicStep extends Message<BasicStep> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: repeated agentero.form.FieldSection sections = 5 [deprecated = true];
   * @deprecated
   */
  sections: FieldSection[] = [];

  /**
   * @generated from field: optional string summary_title_text = 6;
   */
  summaryTitleText?: string;

  /**
   * @generated from field: repeated string summary_title_fields = 7;
   */
  summaryTitleFields: string[] = [];

  /**
   * @generated from field: string ga_action = 8;
   */
  gaAction = "";

  /**
   * @generated from field: repeated agentero.form.Section form_sections = 9;
   */
  formSections: Section[] = [];

  constructor(data?: PartialMessage<BasicStep>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.BasicStep";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "sections", kind: "message", T: FieldSection, repeated: true },
    { no: 6, name: "summary_title_text", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "summary_title_fields", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "ga_action", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "form_sections", kind: "message", T: Section, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BasicStep {
    return new BasicStep().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BasicStep {
    return new BasicStep().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BasicStep {
    return new BasicStep().fromJsonString(jsonString, options);
  }

  static equals(a: BasicStep | PlainMessage<BasicStep> | undefined, b: BasicStep | PlainMessage<BasicStep> | undefined): boolean {
    return proto3.util.equals(BasicStep, a, b);
  }
}

/**
 * @generated from message agentero.form.Section
 */
export class Section extends Message<Section> {
  /**
   * @generated from oneof agentero.form.Section.section
   */
  section: {
    /**
     * @generated from field: agentero.form.FieldSection field_section = 1;
     */
    value: FieldSection;
    case: "fieldSection";
  } | {
    /**
     * @generated from field: agentero.form.ListFieldSection list_field_section = 2;
     */
    value: ListFieldSection;
    case: "listFieldSection";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Section>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Section";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "field_section", kind: "message", T: FieldSection, oneof: "section" },
    { no: 2, name: "list_field_section", kind: "message", T: ListFieldSection, oneof: "section" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Section {
    return new Section().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Section {
    return new Section().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Section {
    return new Section().fromJsonString(jsonString, options);
  }

  static equals(a: Section | PlainMessage<Section> | undefined, b: Section | PlainMessage<Section> | undefined): boolean {
    return proto3.util.equals(Section, a, b);
  }
}

/**
 * FieldSection is a section that is not a carrier section
 *
 * @generated from message agentero.form.FieldSection
 */
export class FieldSection extends Message<FieldSection> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: repeated agentero.form.Field fields = 4;
   */
  fields: Field[] = [];

  /**
   * @generated from field: bool repeat_for_subsequent = 7;
   */
  repeatForSubsequent = false;

  /**
   * todo: this will be removed when coverages are stored in rater config
   *
   * @generated from field: bool is_coverage = 5;
   */
  isCoverage = false;

  /**
   * @generated from field: agentero.form.CoverageType coverage_type = 6;
   */
  coverageType = CoverageType.UNDEFINED;

  constructor(data?: PartialMessage<FieldSection>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.FieldSection";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "fields", kind: "message", T: Field, repeated: true },
    { no: 7, name: "repeat_for_subsequent", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "is_coverage", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "coverage_type", kind: "enum", T: proto3.getEnumType(CoverageType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FieldSection {
    return new FieldSection().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FieldSection {
    return new FieldSection().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FieldSection {
    return new FieldSection().fromJsonString(jsonString, options);
  }

  static equals(a: FieldSection | PlainMessage<FieldSection> | undefined, b: FieldSection | PlainMessage<FieldSection> | undefined): boolean {
    return proto3.util.equals(FieldSection, a, b);
  }
}

/**
 * listFieldSection is a section that can add or delete items with same fields
 *
 * @generated from message agentero.form.ListFieldSection
 */
export class ListFieldSection extends Message<ListFieldSection> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: repeated agentero.form.Field fields = 4;
   */
  fields: Field[] = [];

  /**
   * @generated from field: bool repeat_for_subsequent = 5;
   */
  repeatForSubsequent = false;

  /**
   * @generated from field: int32 min_elements = 6;
   */
  minElements = 0;

  /**
   * @generated from field: int32 max_elements = 7;
   */
  maxElements = 0;

  constructor(data?: PartialMessage<ListFieldSection>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.ListFieldSection";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "fields", kind: "message", T: Field, repeated: true },
    { no: 5, name: "repeat_for_subsequent", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "min_elements", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "max_elements", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFieldSection {
    return new ListFieldSection().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFieldSection {
    return new ListFieldSection().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListFieldSection {
    return new ListFieldSection().fromJsonString(jsonString, options);
  }

  static equals(a: ListFieldSection | PlainMessage<ListFieldSection> | undefined, b: ListFieldSection | PlainMessage<ListFieldSection> | undefined): boolean {
    return proto3.util.equals(ListFieldSection, a, b);
  }
}

/**
 * CarrierStep is a step that has a list of carriers inside
 *
 * @generated from message agentero.form.CarrierStep
 */
export class CarrierStep extends Message<CarrierStep> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: repeated agentero.form.CarrierQuestion carrier_questions = 6;
   */
  carrierQuestions: CarrierQuestion[] = [];

  constructor(data?: PartialMessage<CarrierStep>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.CarrierStep";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "carrier_questions", kind: "message", T: CarrierQuestion, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CarrierStep {
    return new CarrierStep().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CarrierStep {
    return new CarrierStep().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CarrierStep {
    return new CarrierStep().fromJsonString(jsonString, options);
  }

  static equals(a: CarrierStep | PlainMessage<CarrierStep> | undefined, b: CarrierStep | PlainMessage<CarrierStep> | undefined): boolean {
    return proto3.util.equals(CarrierStep, a, b);
  }
}

/**
 * CarrierQuestion is a group of sections of fields that are specific to a carrier and to a state
 *
 * @generated from message agentero.form.CarrierQuestion
 */
export class CarrierQuestion extends Message<CarrierQuestion> {
  /**
   * @generated from field: agentero.carrier.Carrier carrier = 1;
   */
  carrier = Carrier.UNDEFINED;

  /**
   * @generated from field: repeated agentero.form.FieldSection sections = 2 [deprecated = true];
   * @deprecated
   */
  sections: FieldSection[] = [];

  /**
   * @generated from field: repeated agentero.form.Section form_sections = 3;
   */
  formSections: Section[] = [];

  constructor(data?: PartialMessage<CarrierQuestion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.CarrierQuestion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
    { no: 2, name: "sections", kind: "message", T: FieldSection, repeated: true },
    { no: 3, name: "form_sections", kind: "message", T: Section, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CarrierQuestion {
    return new CarrierQuestion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CarrierQuestion {
    return new CarrierQuestion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CarrierQuestion {
    return new CarrierQuestion().fromJsonString(jsonString, options);
  }

  static equals(a: CarrierQuestion | PlainMessage<CarrierQuestion> | undefined, b: CarrierQuestion | PlainMessage<CarrierQuestion> | undefined): boolean {
    return proto3.util.equals(CarrierQuestion, a, b);
  }
}

/**
 * ListStep is a step that has a list of steps inside. I.e vehicles, drivers, etc.
 *
 * @generated from message agentero.form.ListStep
 */
export class ListStep extends Message<ListStep> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: repeated agentero.form.ListItem items = 5;
   */
  items: ListItem[] = [];

  constructor(data?: PartialMessage<ListStep>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.ListStep";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "items", kind: "message", T: ListItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListStep {
    return new ListStep().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListStep {
    return new ListStep().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListStep {
    return new ListStep().fromJsonString(jsonString, options);
  }

  static equals(a: ListStep | PlainMessage<ListStep> | undefined, b: ListStep | PlainMessage<ListStep> | undefined): boolean {
    return proto3.util.equals(ListStep, a, b);
  }
}

/**
 * @generated from message agentero.form.ListItem
 */
export class ListItem extends Message<ListItem> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: repeated agentero.form.FieldSection sections = 2 [deprecated = true];
   * @deprecated
   */
  sections: FieldSection[] = [];

  /**
   * @generated from field: optional string summary_title_text = 3;
   */
  summaryTitleText?: string;

  /**
   * @generated from field: repeated string summary_title_fields = 4;
   */
  summaryTitleFields: string[] = [];

  /**
   * @generated from field: repeated string summary_subtitle_fields = 5;
   */
  summarySubtitleFields: string[] = [];

  /**
   * @generated from field: int32 min_elements = 6;
   */
  minElements = 0;

  /**
   * @generated from field: int32 max_elements = 7;
   */
  maxElements = 0;

  /**
   * @generated from field: repeated agentero.form.Section form_sections = 8;
   */
  formSections: Section[] = [];

  constructor(data?: PartialMessage<ListItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.ListItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sections", kind: "message", T: FieldSection, repeated: true },
    { no: 3, name: "summary_title_text", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "summary_title_fields", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "summary_subtitle_fields", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "min_elements", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "max_elements", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "form_sections", kind: "message", T: Section, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListItem {
    return new ListItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListItem {
    return new ListItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListItem {
    return new ListItem().fromJsonString(jsonString, options);
  }

  static equals(a: ListItem | PlainMessage<ListItem> | undefined, b: ListItem | PlainMessage<ListItem> | undefined): boolean {
    return proto3.util.equals(ListItem, a, b);
  }
}

/**
 * Field represents a field in a form
 *
 * @generated from message agentero.form.Field
 */
export class Field extends Message<Field> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string label = 2;
   */
  label = "";

  /**
   * @generated from field: bool required = 3;
   */
  required = false;

  /**
   * @generated from field: agentero.form.Field.DependsOn depends_on = 4;
   */
  dependsOn?: Field_DependsOn;

  /**
   * @generated from field: agentero.form.Size size = 5;
   */
  size = Size.UNSPECIFIED;

  /**
   * @generated from field: string description = 6;
   */
  description = "";

  /**
   * @generated from field: string destination = 7;
   */
  destination = "";

  /**
   * @generated from field: repeated string supported_states = 20;
   */
  supportedStates: string[] = [];

  /**
   * @generated from field: agentero.form.Field.Validation validation = 21;
   */
  validation?: Field_Validation;

  /**
   * @generated from field: string default_value = 22;
   */
  defaultValue = "";

  /**
   * @generated from field: bool read_only = 23;
   */
  readOnly = false;

  /**
   * @generated from field: string prefill_from_field = 25;
   */
  prefillFromField = "";

  /**
   * @generated from oneof agentero.form.Field.field
   */
  field: {
    /**
     * @generated from field: agentero.form.Field.Amount amount = 8;
     */
    value: Field_Amount;
    case: "amount";
  } | {
    /**
     * @generated from field: agentero.form.Field.Boolean boolean = 9;
     */
    value: Field_Boolean;
    case: "boolean";
  } | {
    /**
     * @generated from field: agentero.form.Field.Choice choice = 10;
     */
    value: Field_Choice;
    case: "choice";
  } | {
    /**
     * @generated from field: agentero.form.Field.Date date = 11;
     */
    value: Field_Date;
    case: "date";
  } | {
    /**
     * @generated from field: agentero.form.Field.Dropdown dropdown = 12;
     */
    value: Field_Dropdown;
    case: "dropdown";
  } | {
    /**
     * @generated from field: agentero.form.Field.Email email = 13;
     */
    value: Field_Email;
    case: "email";
  } | {
    /**
     * @generated from field: agentero.form.Field.Number number = 14;
     */
    value: Field_Number;
    case: "number";
  } | {
    /**
     * @generated from field: agentero.form.Field.Search search = 15;
     */
    value: Field_Search;
    case: "search";
  } | {
    /**
     * @generated from field: agentero.form.Field.Text text = 16;
     */
    value: Field_Text;
    case: "text";
  } | {
    /**
     * @generated from field: agentero.form.Field.VIN vin = 17;
     */
    value: Field_VIN;
    case: "vin";
  } | {
    /**
     * @generated from field: agentero.form.Field.YearMonth yearMonth = 18;
     */
    value: Field_YearMonth;
    case: "yearMonth";
  } | {
    /**
     * @generated from field: agentero.form.Field.Alert alert = 19;
     */
    value: Field_Alert;
    case: "alert";
  } | {
    /**
     * @generated from field: agentero.form.Field.PhoneNumber phone_number = 24;
     */
    value: Field_PhoneNumber;
    case: "phoneNumber";
  } | {
    /**
     * @generated from field: agentero.form.Field.Signature signature = 26;
     */
    value: Field_Signature;
    case: "signature";
  } | {
    /**
     * @generated from field: agentero.form.Field.Pdf pdf = 27;
     */
    value: Field_Pdf;
    case: "pdf";
  } | {
    /**
     * @generated from field: agentero.form.Field.Video video = 28;
     */
    value: Field_Video;
    case: "video";
  } | {
    /**
     * @generated from field: agentero.form.Field.ScrollViewer scroll_viewer = 29;
     */
    value: Field_ScrollViewer;
    case: "scrollViewer";
  } | {
    /**
     * @generated from field: agentero.form.Field.TextArea text_area = 30;
     */
    value: Field_TextArea;
    case: "textArea";
  } | {
    /**
     * @generated from field: agentero.form.Field.MultiChoice multi_choice = 31;
     */
    value: Field_MultiChoice;
    case: "multiChoice";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Field>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "depends_on", kind: "message", T: Field_DependsOn },
    { no: 5, name: "size", kind: "enum", T: proto3.getEnumType(Size) },
    { no: 6, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "destination", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "supported_states", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 21, name: "validation", kind: "message", T: Field_Validation },
    { no: 22, name: "default_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "read_only", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 25, name: "prefill_from_field", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "amount", kind: "message", T: Field_Amount, oneof: "field" },
    { no: 9, name: "boolean", kind: "message", T: Field_Boolean, oneof: "field" },
    { no: 10, name: "choice", kind: "message", T: Field_Choice, oneof: "field" },
    { no: 11, name: "date", kind: "message", T: Field_Date, oneof: "field" },
    { no: 12, name: "dropdown", kind: "message", T: Field_Dropdown, oneof: "field" },
    { no: 13, name: "email", kind: "message", T: Field_Email, oneof: "field" },
    { no: 14, name: "number", kind: "message", T: Field_Number, oneof: "field" },
    { no: 15, name: "search", kind: "message", T: Field_Search, oneof: "field" },
    { no: 16, name: "text", kind: "message", T: Field_Text, oneof: "field" },
    { no: 17, name: "vin", kind: "message", T: Field_VIN, oneof: "field" },
    { no: 18, name: "yearMonth", kind: "message", T: Field_YearMonth, oneof: "field" },
    { no: 19, name: "alert", kind: "message", T: Field_Alert, oneof: "field" },
    { no: 24, name: "phone_number", kind: "message", T: Field_PhoneNumber, oneof: "field" },
    { no: 26, name: "signature", kind: "message", T: Field_Signature, oneof: "field" },
    { no: 27, name: "pdf", kind: "message", T: Field_Pdf, oneof: "field" },
    { no: 28, name: "video", kind: "message", T: Field_Video, oneof: "field" },
    { no: 29, name: "scroll_viewer", kind: "message", T: Field_ScrollViewer, oneof: "field" },
    { no: 30, name: "text_area", kind: "message", T: Field_TextArea, oneof: "field" },
    { no: 31, name: "multi_choice", kind: "message", T: Field_MultiChoice, oneof: "field" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field {
    return new Field().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field {
    return new Field().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field {
    return new Field().fromJsonString(jsonString, options);
  }

  static equals(a: Field | PlainMessage<Field> | undefined, b: Field | PlainMessage<Field> | undefined): boolean {
    return proto3.util.equals(Field, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.Text
 */
export class Field_Text extends Message<Field_Text> {
  /**
   * @generated from field: string placeholder = 1;
   */
  placeholder = "";

  constructor(data?: PartialMessage<Field_Text>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.Text";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "placeholder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_Text {
    return new Field_Text().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_Text {
    return new Field_Text().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_Text {
    return new Field_Text().fromJsonString(jsonString, options);
  }

  static equals(a: Field_Text | PlainMessage<Field_Text> | undefined, b: Field_Text | PlainMessage<Field_Text> | undefined): boolean {
    return proto3.util.equals(Field_Text, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.TextArea
 */
export class Field_TextArea extends Message<Field_TextArea> {
  /**
   * @generated from field: string placeholder = 1;
   */
  placeholder = "";

  constructor(data?: PartialMessage<Field_TextArea>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.TextArea";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "placeholder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_TextArea {
    return new Field_TextArea().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_TextArea {
    return new Field_TextArea().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_TextArea {
    return new Field_TextArea().fromJsonString(jsonString, options);
  }

  static equals(a: Field_TextArea | PlainMessage<Field_TextArea> | undefined, b: Field_TextArea | PlainMessage<Field_TextArea> | undefined): boolean {
    return proto3.util.equals(Field_TextArea, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.PhoneNumber
 */
export class Field_PhoneNumber extends Message<Field_PhoneNumber> {
  constructor(data?: PartialMessage<Field_PhoneNumber>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.PhoneNumber";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_PhoneNumber {
    return new Field_PhoneNumber().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_PhoneNumber {
    return new Field_PhoneNumber().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_PhoneNumber {
    return new Field_PhoneNumber().fromJsonString(jsonString, options);
  }

  static equals(a: Field_PhoneNumber | PlainMessage<Field_PhoneNumber> | undefined, b: Field_PhoneNumber | PlainMessage<Field_PhoneNumber> | undefined): boolean {
    return proto3.util.equals(Field_PhoneNumber, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.Number
 */
export class Field_Number extends Message<Field_Number> {
  /**
   * @generated from field: string placeholder = 1;
   */
  placeholder = "";

  /**
   * @generated from field: optional int32 min = 2;
   */
  min?: number;

  /**
   * @generated from field: optional int32 max = 3;
   */
  max?: number;

  /**
   * @generated from field: agentero.form.Field.Number.NumberType type = 5;
   */
  type = Field_Number_NumberType.INTEGER;

  /**
   * @generated from field: optional string default_value = 6 [deprecated = true];
   * @deprecated
   */
  defaultValue?: string;

  constructor(data?: PartialMessage<Field_Number>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.Number";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "placeholder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "min", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "max", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(Field_Number_NumberType) },
    { no: 6, name: "default_value", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_Number {
    return new Field_Number().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_Number {
    return new Field_Number().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_Number {
    return new Field_Number().fromJsonString(jsonString, options);
  }

  static equals(a: Field_Number | PlainMessage<Field_Number> | undefined, b: Field_Number | PlainMessage<Field_Number> | undefined): boolean {
    return proto3.util.equals(Field_Number, a, b);
  }
}

/**
 * @generated from enum agentero.form.Field.Number.NumberType
 */
export enum Field_Number_NumberType {
  /**
   * @generated from enum value: INTEGER = 0;
   */
  INTEGER = 0,

  /**
   * @generated from enum value: DECIMAL = 1;
   */
  DECIMAL = 1,

  /**
   * MASK is a number type with the US format. Eg.: 1,000,000.20
   *
   * @generated from enum value: MASK = 2;
   */
  MASK = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Field_Number_NumberType)
proto3.util.setEnumType(Field_Number_NumberType, "agentero.form.Field.Number.NumberType", [
  { no: 0, name: "INTEGER" },
  { no: 1, name: "DECIMAL" },
  { no: 2, name: "MASK" },
]);

/**
 * @generated from message agentero.form.Field.Amount
 */
export class Field_Amount extends Message<Field_Amount> {
  /**
   * @generated from field: string placeholder = 1;
   */
  placeholder = "";

  constructor(data?: PartialMessage<Field_Amount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.Amount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "placeholder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_Amount {
    return new Field_Amount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_Amount {
    return new Field_Amount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_Amount {
    return new Field_Amount().fromJsonString(jsonString, options);
  }

  static equals(a: Field_Amount | PlainMessage<Field_Amount> | undefined, b: Field_Amount | PlainMessage<Field_Amount> | undefined): boolean {
    return proto3.util.equals(Field_Amount, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.Boolean
 */
export class Field_Boolean extends Message<Field_Boolean> {
  constructor(data?: PartialMessage<Field_Boolean>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.Boolean";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_Boolean {
    return new Field_Boolean().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_Boolean {
    return new Field_Boolean().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_Boolean {
    return new Field_Boolean().fromJsonString(jsonString, options);
  }

  static equals(a: Field_Boolean | PlainMessage<Field_Boolean> | undefined, b: Field_Boolean | PlainMessage<Field_Boolean> | undefined): boolean {
    return proto3.util.equals(Field_Boolean, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.Date
 */
export class Field_Date extends Message<Field_Date> {
  /**
   * @generated from field: string placeholder = 1;
   */
  placeholder = "";

  /**
   * @generated from field: optional int32 min_age = 2;
   */
  minAge?: number;

  /**
   * @generated from field: optional int32 max_age = 3;
   */
  maxAge?: number;

  /**
   * @generated from field: optional string max_date = 4;
   */
  maxDate?: string;

  /**
   * @generated from field: optional string min_date = 5;
   */
  minDate?: string;

  constructor(data?: PartialMessage<Field_Date>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.Date";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "placeholder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "min_age", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "max_age", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "max_date", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "min_date", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_Date {
    return new Field_Date().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_Date {
    return new Field_Date().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_Date {
    return new Field_Date().fromJsonString(jsonString, options);
  }

  static equals(a: Field_Date | PlainMessage<Field_Date> | undefined, b: Field_Date | PlainMessage<Field_Date> | undefined): boolean {
    return proto3.util.equals(Field_Date, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.Email
 */
export class Field_Email extends Message<Field_Email> {
  constructor(data?: PartialMessage<Field_Email>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.Email";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_Email {
    return new Field_Email().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_Email {
    return new Field_Email().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_Email {
    return new Field_Email().fromJsonString(jsonString, options);
  }

  static equals(a: Field_Email | PlainMessage<Field_Email> | undefined, b: Field_Email | PlainMessage<Field_Email> | undefined): boolean {
    return proto3.util.equals(Field_Email, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.Dropdown
 */
export class Field_Dropdown extends Message<Field_Dropdown> {
  /**
   * @generated from field: repeated agentero.form.Option options = 1;
   */
  options: Option[] = [];

  /**
   * @generated from field: string default_value = 2 [deprecated = true];
   * @deprecated
   */
  defaultValue = "";

  /**
   * @generated from field: agentero.form.Field.Message message = 3 [deprecated = true];
   * @deprecated
   */
  message?: Field_Message;

  /**
   * @generated from field: bool is_coverage = 4;
   */
  isCoverage = false;

  constructor(data?: PartialMessage<Field_Dropdown>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.Dropdown";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "options", kind: "message", T: Option, repeated: true },
    { no: 2, name: "default_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "message", T: Field_Message },
    { no: 4, name: "is_coverage", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_Dropdown {
    return new Field_Dropdown().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_Dropdown {
    return new Field_Dropdown().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_Dropdown {
    return new Field_Dropdown().fromJsonString(jsonString, options);
  }

  static equals(a: Field_Dropdown | PlainMessage<Field_Dropdown> | undefined, b: Field_Dropdown | PlainMessage<Field_Dropdown> | undefined): boolean {
    return proto3.util.equals(Field_Dropdown, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.Validation
 */
export class Field_Validation extends Message<Field_Validation> {
  /**
   * @generated from field: repeated string invalid_values = 1 [deprecated = true];
   * @deprecated
   */
  invalidValues: string[] = [];

  /**
   * @generated from field: agentero.form.Field.When when = 2;
   */
  when?: Field_When;

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<Field_Validation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.Validation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "invalid_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "when", kind: "message", T: Field_When },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_Validation {
    return new Field_Validation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_Validation {
    return new Field_Validation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_Validation {
    return new Field_Validation().fromJsonString(jsonString, options);
  }

  static equals(a: Field_Validation | PlainMessage<Field_Validation> | undefined, b: Field_Validation | PlainMessage<Field_Validation> | undefined): boolean {
    return proto3.util.equals(Field_Validation, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.Choice
 */
export class Field_Choice extends Message<Field_Choice> {
  /**
   * @generated from field: repeated agentero.form.Option options = 1;
   */
  options: Option[] = [];

  /**
   * @generated from field: string default_value = 2 [deprecated = true];
   * @deprecated
   */
  defaultValue = "";

  constructor(data?: PartialMessage<Field_Choice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.Choice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "options", kind: "message", T: Option, repeated: true },
    { no: 2, name: "default_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_Choice {
    return new Field_Choice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_Choice {
    return new Field_Choice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_Choice {
    return new Field_Choice().fromJsonString(jsonString, options);
  }

  static equals(a: Field_Choice | PlainMessage<Field_Choice> | undefined, b: Field_Choice | PlainMessage<Field_Choice> | undefined): boolean {
    return proto3.util.equals(Field_Choice, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.MultiChoice
 */
export class Field_MultiChoice extends Message<Field_MultiChoice> {
  /**
   * @generated from field: repeated agentero.form.Option options = 1;
   */
  options: Option[] = [];

  constructor(data?: PartialMessage<Field_MultiChoice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.MultiChoice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "options", kind: "message", T: Option, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_MultiChoice {
    return new Field_MultiChoice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_MultiChoice {
    return new Field_MultiChoice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_MultiChoice {
    return new Field_MultiChoice().fromJsonString(jsonString, options);
  }

  static equals(a: Field_MultiChoice | PlainMessage<Field_MultiChoice> | undefined, b: Field_MultiChoice | PlainMessage<Field_MultiChoice> | undefined): boolean {
    return proto3.util.equals(Field_MultiChoice, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.VIN
 */
export class Field_VIN extends Message<Field_VIN> {
  /**
   * @generated from field: bool completed = 1;
   */
  completed = false;

  /**
   * @generated from field: string destination_number = 2;
   */
  destinationNumber = "";

  /**
   * @generated from field: string destination_year = 3;
   */
  destinationYear = "";

  /**
   * @generated from field: string destination_make = 4;
   */
  destinationMake = "";

  /**
   * @generated from field: string destination_model = 5;
   */
  destinationModel = "";

  constructor(data?: PartialMessage<Field_VIN>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.VIN";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "completed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "destination_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "destination_year", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "destination_make", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "destination_model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_VIN {
    return new Field_VIN().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_VIN {
    return new Field_VIN().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_VIN {
    return new Field_VIN().fromJsonString(jsonString, options);
  }

  static equals(a: Field_VIN | PlainMessage<Field_VIN> | undefined, b: Field_VIN | PlainMessage<Field_VIN> | undefined): boolean {
    return proto3.util.equals(Field_VIN, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.YearMonth
 */
export class Field_YearMonth extends Message<Field_YearMonth> {
  /**
   * @generated from field: string destination_year = 1;
   */
  destinationYear = "";

  /**
   * @generated from field: string destination_month = 2;
   */
  destinationMonth = "";

  /**
   * @generated from field: int32 min = 3;
   */
  min = 0;

  constructor(data?: PartialMessage<Field_YearMonth>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.YearMonth";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "destination_year", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "destination_month", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_YearMonth {
    return new Field_YearMonth().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_YearMonth {
    return new Field_YearMonth().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_YearMonth {
    return new Field_YearMonth().fromJsonString(jsonString, options);
  }

  static equals(a: Field_YearMonth | PlainMessage<Field_YearMonth> | undefined, b: Field_YearMonth | PlainMessage<Field_YearMonth> | undefined): boolean {
    return proto3.util.equals(Field_YearMonth, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.Search
 */
export class Field_Search extends Message<Field_Search> {
  /**
   * @generated from field: repeated agentero.form.Option options = 1;
   */
  options: Option[] = [];

  constructor(data?: PartialMessage<Field_Search>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.Search";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "options", kind: "message", T: Option, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_Search {
    return new Field_Search().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_Search {
    return new Field_Search().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_Search {
    return new Field_Search().fromJsonString(jsonString, options);
  }

  static equals(a: Field_Search | PlainMessage<Field_Search> | undefined, b: Field_Search | PlainMessage<Field_Search> | undefined): boolean {
    return proto3.util.equals(Field_Search, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.Alert
 */
export class Field_Alert extends Message<Field_Alert> {
  /**
   * @generated from field: string text = 1;
   */
  text = "";

  /**
   * @generated from field: agentero.form.Field.Alert.alertType type = 2;
   */
  type = Field_Alert_alertType.INFO;

  constructor(data?: PartialMessage<Field_Alert>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.Alert";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(Field_Alert_alertType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_Alert {
    return new Field_Alert().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_Alert {
    return new Field_Alert().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_Alert {
    return new Field_Alert().fromJsonString(jsonString, options);
  }

  static equals(a: Field_Alert | PlainMessage<Field_Alert> | undefined, b: Field_Alert | PlainMessage<Field_Alert> | undefined): boolean {
    return proto3.util.equals(Field_Alert, a, b);
  }
}

/**
 * @generated from enum agentero.form.Field.Alert.alertType
 */
export enum Field_Alert_alertType {
  /**
   * @generated from enum value: INFO = 0;
   */
  INFO = 0,

  /**
   * @generated from enum value: WARNING = 1;
   */
  WARNING = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(Field_Alert_alertType)
proto3.util.setEnumType(Field_Alert_alertType, "agentero.form.Field.Alert.alertType", [
  { no: 0, name: "INFO" },
  { no: 1, name: "WARNING" },
]);

/**
 * @generated from message agentero.form.Field.Signature
 */
export class Field_Signature extends Message<Field_Signature> {
  /**
   * @generated from field: string placeholder = 1;
   */
  placeholder = "";

  constructor(data?: PartialMessage<Field_Signature>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.Signature";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "placeholder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_Signature {
    return new Field_Signature().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_Signature {
    return new Field_Signature().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_Signature {
    return new Field_Signature().fromJsonString(jsonString, options);
  }

  static equals(a: Field_Signature | PlainMessage<Field_Signature> | undefined, b: Field_Signature | PlainMessage<Field_Signature> | undefined): boolean {
    return proto3.util.equals(Field_Signature, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.Pdf
 */
export class Field_Pdf extends Message<Field_Pdf> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<Field_Pdf>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.Pdf";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_Pdf {
    return new Field_Pdf().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_Pdf {
    return new Field_Pdf().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_Pdf {
    return new Field_Pdf().fromJsonString(jsonString, options);
  }

  static equals(a: Field_Pdf | PlainMessage<Field_Pdf> | undefined, b: Field_Pdf | PlainMessage<Field_Pdf> | undefined): boolean {
    return proto3.util.equals(Field_Pdf, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.Video
 */
export class Field_Video extends Message<Field_Video> {
  /**
   * @generated from field: string youtubeId = 1;
   */
  youtubeId = "";

  constructor(data?: PartialMessage<Field_Video>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.Video";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "youtubeId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_Video {
    return new Field_Video().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_Video {
    return new Field_Video().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_Video {
    return new Field_Video().fromJsonString(jsonString, options);
  }

  static equals(a: Field_Video | PlainMessage<Field_Video> | undefined, b: Field_Video | PlainMessage<Field_Video> | undefined): boolean {
    return proto3.util.equals(Field_Video, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.ScrollViewer
 */
export class Field_ScrollViewer extends Message<Field_ScrollViewer> {
  /**
   * @generated from field: string text = 1;
   */
  text = "";

  constructor(data?: PartialMessage<Field_ScrollViewer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.ScrollViewer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_ScrollViewer {
    return new Field_ScrollViewer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_ScrollViewer {
    return new Field_ScrollViewer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_ScrollViewer {
    return new Field_ScrollViewer().fromJsonString(jsonString, options);
  }

  static equals(a: Field_ScrollViewer | PlainMessage<Field_ScrollViewer> | undefined, b: Field_ScrollViewer | PlainMessage<Field_ScrollViewer> | undefined): boolean {
    return proto3.util.equals(Field_ScrollViewer, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.DependsOn
 */
export class Field_DependsOn extends Message<Field_DependsOn> {
  /**
   * @generated from field: string field = 1;
   */
  field = "";

  /**
   * @generated from field: agentero.form.Field.When when = 2;
   */
  when?: Field_When;

  /**
   * @generated from field: string destination = 3;
   */
  destination = "";

  constructor(data?: PartialMessage<Field_DependsOn>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.DependsOn";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "field", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "when", kind: "message", T: Field_When },
    { no: 3, name: "destination", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_DependsOn {
    return new Field_DependsOn().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_DependsOn {
    return new Field_DependsOn().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_DependsOn {
    return new Field_DependsOn().fromJsonString(jsonString, options);
  }

  static equals(a: Field_DependsOn | PlainMessage<Field_DependsOn> | undefined, b: Field_DependsOn | PlainMessage<Field_DependsOn> | undefined): boolean {
    return proto3.util.equals(Field_DependsOn, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.Message
 * @deprecated
 */
export class Field_Message extends Message<Field_Message> {
  /**
   * @generated from field: agentero.form.Field.When when = 1;
   */
  when?: Field_When;

  /**
   * @generated from field: string text = 2;
   */
  text = "";

  constructor(data?: PartialMessage<Field_Message>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.Message";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "when", kind: "message", T: Field_When },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_Message {
    return new Field_Message().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_Message {
    return new Field_Message().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_Message {
    return new Field_Message().fromJsonString(jsonString, options);
  }

  static equals(a: Field_Message | PlainMessage<Field_Message> | undefined, b: Field_Message | PlainMessage<Field_Message> | undefined): boolean {
    return proto3.util.equals(Field_Message, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.When
 */
export class Field_When extends Message<Field_When> {
  /**
   * This field is named "value_in" instead of "in" because "in" is a reserved word in startlark
   *
   * @generated from field: repeated string value_in = 1 [deprecated = true];
   * @deprecated
   */
  valueIn: string[] = [];

  /**
   * @generated from field: repeated string value_not_in = 2 [deprecated = true];
   * @deprecated
   */
  valueNotIn: string[] = [];

  /**
   * @generated from field: agentero.form.Field.When.ComparisonOperator operator = 3;
   */
  operator = Field_When_ComparisonOperator.UNSPECIFIED;

  /**
   * @generated from oneof agentero.form.Field.When.field_or_value
   */
  fieldOrValue: {
    /**
     * @generated from field: agentero.form.Field.When.ComparisonField field = 4;
     */
    value: Field_When_ComparisonField;
    case: "field";
  } | {
    /**
     * @generated from field: string value = 5;
     */
    value: string;
    case: "value";
  } | {
    /**
     * @generated from field: agentero.form.Field.When.Values values = 6;
     */
    value: Field_When_Values;
    case: "values";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Field_When>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.When";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value_in", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "value_not_in", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "operator", kind: "enum", T: proto3.getEnumType(Field_When_ComparisonOperator) },
    { no: 4, name: "field", kind: "message", T: Field_When_ComparisonField, oneof: "field_or_value" },
    { no: 5, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "field_or_value" },
    { no: 6, name: "values", kind: "message", T: Field_When_Values, oneof: "field_or_value" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_When {
    return new Field_When().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_When {
    return new Field_When().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_When {
    return new Field_When().fromJsonString(jsonString, options);
  }

  static equals(a: Field_When | PlainMessage<Field_When> | undefined, b: Field_When | PlainMessage<Field_When> | undefined): boolean {
    return proto3.util.equals(Field_When, a, b);
  }
}

/**
 * @generated from enum agentero.form.Field.When.ComparisonOperator
 */
export enum Field_When_ComparisonOperator {
  /**
   * @generated from enum value: COMPARISON_OPERATOR_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: COMPARISON_OPERATOR_EQUAL = 1;
   */
  EQUAL = 1,

  /**
   * @generated from enum value: COMPARISON_OPERATOR_NOT_EQUAL = 2;
   */
  NOT_EQUAL = 2,

  /**
   * @generated from enum value: COMPARISON_OPERATOR_GREATER_THAN = 3;
   */
  GREATER_THAN = 3,

  /**
   * @generated from enum value: COMPARISON_OPERATOR_GREATER_THAN_OR_EQUAL = 4;
   */
  GREATER_THAN_OR_EQUAL = 4,

  /**
   * @generated from enum value: COMPARISON_OPERATOR_LESS_THAN = 5;
   */
  LESS_THAN = 5,

  /**
   * @generated from enum value: COMPARISON_OPERATOR_LESS_THAN_OR_EQUAL = 6;
   */
  LESS_THAN_OR_EQUAL = 6,

  /**
   * @generated from enum value: COMPARISON_OPERATOR_IN = 7;
   */
  IN = 7,

  /**
   * @generated from enum value: COMPARISON_OPERATOR_NOT_IN = 8;
   */
  NOT_IN = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(Field_When_ComparisonOperator)
proto3.util.setEnumType(Field_When_ComparisonOperator, "agentero.form.Field.When.ComparisonOperator", [
  { no: 0, name: "COMPARISON_OPERATOR_UNSPECIFIED" },
  { no: 1, name: "COMPARISON_OPERATOR_EQUAL" },
  { no: 2, name: "COMPARISON_OPERATOR_NOT_EQUAL" },
  { no: 3, name: "COMPARISON_OPERATOR_GREATER_THAN" },
  { no: 4, name: "COMPARISON_OPERATOR_GREATER_THAN_OR_EQUAL" },
  { no: 5, name: "COMPARISON_OPERATOR_LESS_THAN" },
  { no: 6, name: "COMPARISON_OPERATOR_LESS_THAN_OR_EQUAL" },
  { no: 7, name: "COMPARISON_OPERATOR_IN" },
  { no: 8, name: "COMPARISON_OPERATOR_NOT_IN" },
]);

/**
 * @generated from message agentero.form.Field.When.Values
 */
export class Field_When_Values extends Message<Field_When_Values> {
  /**
   * @generated from field: repeated string values = 1;
   */
  values: string[] = [];

  constructor(data?: PartialMessage<Field_When_Values>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.When.Values";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_When_Values {
    return new Field_When_Values().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_When_Values {
    return new Field_When_Values().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_When_Values {
    return new Field_When_Values().fromJsonString(jsonString, options);
  }

  static equals(a: Field_When_Values | PlainMessage<Field_When_Values> | undefined, b: Field_When_Values | PlainMessage<Field_When_Values> | undefined): boolean {
    return proto3.util.equals(Field_When_Values, a, b);
  }
}

/**
 * @generated from message agentero.form.Field.When.ComparisonField
 */
export class Field_When_ComparisonField extends Message<Field_When_ComparisonField> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string destination = 2;
   */
  destination = "";

  constructor(data?: PartialMessage<Field_When_ComparisonField>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Field.When.ComparisonField";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "destination", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field_When_ComparisonField {
    return new Field_When_ComparisonField().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field_When_ComparisonField {
    return new Field_When_ComparisonField().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field_When_ComparisonField {
    return new Field_When_ComparisonField().fromJsonString(jsonString, options);
  }

  static equals(a: Field_When_ComparisonField | PlainMessage<Field_When_ComparisonField> | undefined, b: Field_When_ComparisonField | PlainMessage<Field_When_ComparisonField> | undefined): boolean {
    return proto3.util.equals(Field_When_ComparisonField, a, b);
  }
}

/**
 * @generated from message agentero.form.Option
 */
export class Option extends Message<Option> {
  /**
   * @generated from field: string value = 1;
   */
  value = "";

  /**
   * @generated from field: string label = 2;
   */
  label = "";

  constructor(data?: PartialMessage<Option>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Option";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Option {
    return new Option().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Option {
    return new Option().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Option {
    return new Option().fromJsonString(jsonString, options);
  }

  static equals(a: Option | PlainMessage<Option> | undefined, b: Option | PlainMessage<Option> | undefined): boolean {
    return proto3.util.equals(Option, a, b);
  }
}

/**
 * @generated from message agentero.form.Coverage
 */
export class Coverage extends Message<Coverage> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: agentero.form.Coverage.coverageType type = 3;
   */
  type = Coverage_coverageType.DRIVER;

  /**
   * @generated from field: string default_value = 4;
   */
  defaultValue = "";

  /**
   * @generated from field: repeated string values = 5 [deprecated = true];
   * @deprecated
   */
  values: string[] = [];

  /**
   * @generated from field: repeated agentero.form.Option options = 6;
   */
  options: Option[] = [];

  constructor(data?: PartialMessage<Coverage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.form.Coverage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(Coverage_coverageType) },
    { no: 4, name: "default_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "options", kind: "message", T: Option, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Coverage {
    return new Coverage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Coverage {
    return new Coverage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Coverage {
    return new Coverage().fromJsonString(jsonString, options);
  }

  static equals(a: Coverage | PlainMessage<Coverage> | undefined, b: Coverage | PlainMessage<Coverage> | undefined): boolean {
    return proto3.util.equals(Coverage, a, b);
  }
}

/**
 * @generated from enum agentero.form.Coverage.coverageType
 */
export enum Coverage_coverageType {
  /**
   * @generated from enum value: DRIVER = 0;
   */
  DRIVER = 0,

  /**
   * @generated from enum value: VEHICLE = 1;
   */
  VEHICLE = 1,

  /**
   * @generated from enum value: DEDUCTIBLE = 2;
   */
  DEDUCTIBLE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Coverage_coverageType)
proto3.util.setEnumType(Coverage_coverageType, "agentero.form.Coverage.coverageType", [
  { no: 0, name: "DRIVER" },
  { no: 1, name: "VEHICLE" },
  { no: 2, name: "DEDUCTIBLE" },
]);

