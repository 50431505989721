import { Table } from '@tanstack/react-table';

import { Pagination } from '../Pagination';

export type PaginationState = {
	offset: number;
	limit: number;
};

type DataTablePaginationProps<TData> = {
	table?: Table<TData>;
	state: PaginationState;
	onPageStateChange: (state: PaginationState) => void;
};

export const DataTablePagination = <TData,>({
	table,
	state,
	onPageStateChange
}: DataTablePaginationProps<TData>) => {
	const onPageChange = (page: number) => {
		const newPaginationState = { ...state, offset: page };
		onPageStateChange(newPaginationState);
	};

	return table ? (
		<Pagination
			onPageChange={onPageChange}
			totalCount={table.getPageCount()}
			currentPage={table.getState().pagination.pageIndex + 1}
			pageSize={table.getState().pagination.pageSize}
		/>
	) : null;
};
