import { ElementType } from 'react';

import styled from '@emotion/styled';

import { ITheme } from '@agentero/styles/emotion';

type TextSize = 'XXS' | 'XS' | 'S' | 'M' | 'default' | 'L' | 'XL' | 'XXL' | 'XXXL' | 'inherit';

type ResponsiveTextSize = {
	xs?: TextSize;
	sm?: TextSize;
	md?: TextSize;
	lg?: TextSize;
	xlg?: TextSize;
	xxlg?: TextSize;
	xxxlg?: TextSize;
};

type Margin = {
	mt?: number;
	mb?: number;
};

export type TextProps = {
	as?: ElementType<any> | undefined;
	colorize?: (colors: ITheme['colors']) => string;
	size?: TextSize | ResponsiveTextSize;
	uppercase?: boolean;
	truncate?: boolean;
	textAlign?: 'left' | 'center' | 'right';
} & Margin;

type GetTextSizesArgs = {
	theme: ITheme;
	size?: TextSize | ResponsiveTextSize;
};

const getTextSizes = ({ theme, size = 'default' }: GetTextSizesArgs) => {
	if (size) {
		if (typeof size === 'string') {
			return theme.textSettings[size];
		}

		return `
			${size.xs && theme.textSettings[size.xs]}
			${theme.mediaqueries.S} {
				${size.sm && theme.textSettings[size.sm]}
			}
			${theme.mediaqueries.M} {
				${size.md && theme.textSettings[size.md]}
			}
			${theme.mediaqueries.L} {
				${size.lg && theme.textSettings[size.lg]}
			}
			${theme.mediaqueries.XL} {
				${size.xlg && theme.textSettings[size.xlg]}
			}
			${theme.mediaqueries.XXL} {
				${size.xxlg && theme.textSettings[size.xxlg]}
			}
			${theme.mediaqueries.XXXL} {
				${size.xxxlg && theme.textSettings[size.xxxlg]}
			}
		`;
	}

	return '';
};

/**
 * @deprecated
 */
export const Text = styled.div<TextProps>`
	${({ theme, colorize }) => colorize && `color: ${colorize(theme.colors)}`};
	${({ uppercase }) => uppercase && 'text-transform: uppercase'};
	margin-top: ${({ mt }) => (mt ? mt + 'px' : 0)};
	margin-bottom: ${({ mb }) => (mb ? mb + 'px' : 0)};
	${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
	${({ truncate }) =>
		truncate &&
		`
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;`}
	${getTextSizes}

	svg {
		vertical-align: top;

		path {
			${({ theme, colorize }) => colorize && `fill: ${colorize(theme.colors)}`};
		}
	}
`;

/**
 * @deprecated
 */
export const Title = styled(Text.withComponent('h2'))`
	${({ theme }) => theme.fontWeight.medium};
`;

type CaptionProps = {
	size?: 'XXS' | 'XS';
} & TextProps;

/**
 * @deprecated
 */
export const Caption = styled(Text.withComponent('span'))<CaptionProps>`
	${({ size = 'XS', theme }) => theme.textSettings[size]};
`;

/**
 * @deprecated
 */
export const DisclaimerText = styled(Text)`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.colors.gray.lighten40};

	svg {
		flex: 0;
		height: 16px;
		min-width: 16px;
		margin-right: 4px;
		fill: ${({ theme }) => theme.colors.gray.lighten40};
	}
`;

export const HeadingGroup = styled.hgroup<Margin>`
	margin-top: ${({ mt }) => (mt ? `${mt}px` : 0)};
	margin-bottom: ${({ mb }) => (mb ? `${mb}px` : 0)};
`;
