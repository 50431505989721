import React, { RefObject } from 'react';

import styled from '@emotion/styled';

import { Truncate } from '../Truncate';
import { Checkbox } from '../form/Checkbox';

type DropdownItemCheckboxProps = React.DetailedHTMLProps<
	React.InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
> & {
	label: string;
};

const StyledDropdownItemCheckbox = styled.li`
	height: 32px;

	> span {
		padding: 0 16px;
	}

	&:hover {
		background: ${props => props.theme.colors.gray.lighten95};
	}
`;

export const DropdownItemCheckbox: React.FC<DropdownItemCheckboxProps> = props => {
	const ref = props.ref as RefObject<HTMLInputElement>;
	const onClick = props.onClick as () => void;
	const { label, ...innerProps } = props;
	return (
		<StyledDropdownItemCheckbox>
			<Checkbox {...innerProps} onClick={onClick} ref={ref} id={`dropdownItem${label}`}>
				<Truncate>{label}</Truncate>
			</Checkbox>
		</StyledDropdownItemCheckbox>
	);
};
