import { PropsWithChildren } from 'react';

import { Controller } from 'react-hook-form';

import { getFieldError, getFieldErrorId, removeAllDots, useAgFormContext } from '@agentero/ui';
import { getInputStatusFromError } from '@agentero/utils';

import { MarkdownInput } from '../MarkdownInput';
import { FormGroup, FormGroupGenericProps } from './FormGroup';

type FormGroupMarkdownInputProps<T> = {
	placeholder?: string;
} & FormGroupGenericProps<T>;

export const FormGroupMarkdownInput = <T extends {}>({
	children,
	name,
	...formGroupProps
}: PropsWithChildren<FormGroupMarkdownInputProps<T>>) => {
	const {
		control,
		formState: { errors }
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	return (
		<FormGroup name={name} nestedChildren={children} {...formGroupProps}>
			<Controller
				name={name}
				control={control}
				render={({ field: fieldProps }) => (
					<MarkdownInput
						id={removeAllDots(name)}
						aria-invalid={!!fieldError?.message}
						aria-errormessage={getFieldErrorId(name)}
						status={getInputStatusFromError(fieldError?.message as string)}
						{...fieldProps}
						placeholder={formGroupProps.placeholder}
					/>
				)}
			/>
		</FormGroup>
	);
};
