import styled from '@emotion/styled';

type FlexProps = {
	spacing?: number;
	mb?: number;
	inline?: boolean;
	nowrap?: boolean;
};

export const Flex = styled.div<FlexProps>`
	display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
	flex-wrap: ${({ nowrap }) => (nowrap ? 'nowrap' : 'wrap')};
	gap: ${({ spacing }) => (spacing ? spacing : 0)}px;
	margin-bottom: ${({ mb }) => (mb ? mb : 0)}px;
`;
