import * as yup from 'yup';
import { TransformFunction } from 'yup/lib/types';

import { parseNumber } from './number';

const isMasked = (value: string) => value.includes(',');

const transformNumberNullable: TransformFunction<yup.NumberSchema> = value => {
	return value || value === 0 ? value : undefined;
};

const transformNumber: TransformFunction<yup.NumberSchema> = (value, originalValue) => {
	if (Array.isArray(originalValue)) {
		return parseInt(originalValue[0]);
	}

	return parseInt(originalValue);
};

const transformNumberDecimal: TransformFunction<yup.NumberSchema> = (value, originalValue) => {
	if (typeof originalValue === 'string' && originalValue === '') {
		return;
	}
	if (typeof originalValue === 'string' && isMasked(originalValue)) {
		return parseNumber(originalValue.replaceAll(',', ''));
	}
	return value;
};

export { transformNumber, transformNumberDecimal, transformNumberNullable };
