import { DetailedHTMLProps, ElementType, HTMLAttributes, PropsWithChildren } from 'react';

import { css, cva, cx } from '@agentero/styles/css';
import { SpacingToken } from '@agentero/styles/tokens';
import { RecipeVariantProps } from '@agentero/styles/types';

export const textStyle = cva({
	base: {
		'& svg': { verticalAlign: 'top' }
	},
	variants: {
		size: {
			inherit: { fontSize: 'inherit', lineHeight: 'inherit' },
			body: { textStyle: 'body.base' },
			'body.small': { textStyle: 'body.small' },
			title: { textStyle: 'title.base' },
			'title.monster': { textStyle: 'title.monster' },
			'title.jumbo': { textStyle: 'title.jumbo' },
			'title.screen': { textStyle: 'title.screen' },
			'title.section': { textStyle: 'title.section' },
			'title.subsection': { textStyle: 'title.subsection' },
			'title.body': { textStyle: 'title.body' },
			'title.group': { textStyle: 'title.group' },
			caption: { textStyle: 'caption.base' },
			tiny: { textStyle: 'tiny.base' }
		},
		uppercase: {
			true: { textTransform: 'uppercase' }
		},
		color: {
			inherit: { color: 'inherit', '& svg': { fill: 'inherit' } },
			default: { color: 'text.default', '& svg': { fill: 'text.default' } },
			weak: {
				color: 'text.default.base.tertiary',
				'& svg': { fill: 'text.default.base.tertiary' }
			},
			success: { color: 'text.success', '& svg': { fill: 'text.success' } },
			danger: { color: 'text.danger', '& svg': { fill: 'text.danger' } },
			warning: { color: 'text.warning', '& svg': { fill: 'text.warning' } },
			info: { color: 'text.info', '& svg': { fill: 'text.info' } }
		},
		align: {
			left: { textAlign: 'left' },
			center: { textAlign: 'center' },
			right: { textAlign: 'right' }
		},
		truncate: {
			true: { width: '100%' }
		},
		lineThrough: {
			true: { textDecoration: 'line-through' }
		}
	},
	defaultVariants: {
		color: 'inherit',
		size: 'body'
	}
});

type TextVariants = RecipeVariantProps<typeof textStyle>;

export type TextAlignType = NonNullable<TextVariants>['align'];

export type TextSizeType = NonNullable<TextVariants>['size'];

export type TextColorType = NonNullable<TextVariants>['color'];

export type TextProps = {
	as?: ElementType;
	mt?: SpacingToken;
	mb?: SpacingToken;
	truncate?: boolean;
	lineClamp?: number;
} & TextVariants &
	DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

export const Text = ({
	children,
	as = 'div',
	mt,
	mb,
	lineClamp,
	truncate,
	size,
	uppercase,
	color,
	align,
	className,
	...props
}: PropsWithChildren<TextProps>) => {
	const Element = as;

	if (truncate && lineClamp) {
		throw new Error('Cannot use truncate and lineClamp at the same time');
	}

	return (
		<Element
			className={cx(
				css({ mt, mb, lineClamp, truncate }),
				textStyle({ size, uppercase, color, align }),
				className
			)}
			{...props}>
			{children}
		</Element>
	);
};
