// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file agentero/portal/vehicle.proto (package agentero.portal, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message agentero.portal.GetMakesRequest
 */
export class GetMakesRequest extends Message<GetMakesRequest> {
  /**
   * @generated from field: string year = 1;
   */
  year = "";

  constructor(data?: PartialMessage<GetMakesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetMakesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "year", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMakesRequest {
    return new GetMakesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMakesRequest {
    return new GetMakesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMakesRequest {
    return new GetMakesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetMakesRequest | PlainMessage<GetMakesRequest> | undefined, b: GetMakesRequest | PlainMessage<GetMakesRequest> | undefined): boolean {
    return proto3.util.equals(GetMakesRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetMakesResponse
 */
export class GetMakesResponse extends Message<GetMakesResponse> {
  /**
   * @generated from field: repeated string makes = 1;
   */
  makes: string[] = [];

  constructor(data?: PartialMessage<GetMakesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetMakesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "makes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMakesResponse {
    return new GetMakesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMakesResponse {
    return new GetMakesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMakesResponse {
    return new GetMakesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetMakesResponse | PlainMessage<GetMakesResponse> | undefined, b: GetMakesResponse | PlainMessage<GetMakesResponse> | undefined): boolean {
    return proto3.util.equals(GetMakesResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetModelsRequest
 */
export class GetModelsRequest extends Message<GetModelsRequest> {
  /**
   * @generated from field: string year = 1;
   */
  year = "";

  /**
   * @generated from field: string make = 2;
   */
  make = "";

  constructor(data?: PartialMessage<GetModelsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetModelsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "year", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "make", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetModelsRequest {
    return new GetModelsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetModelsRequest {
    return new GetModelsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetModelsRequest {
    return new GetModelsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetModelsRequest | PlainMessage<GetModelsRequest> | undefined, b: GetModelsRequest | PlainMessage<GetModelsRequest> | undefined): boolean {
    return proto3.util.equals(GetModelsRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetModelsResponse
 */
export class GetModelsResponse extends Message<GetModelsResponse> {
  /**
   * @generated from field: repeated string models = 1;
   */
  models: string[] = [];

  constructor(data?: PartialMessage<GetModelsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetModelsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "models", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetModelsResponse {
    return new GetModelsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetModelsResponse {
    return new GetModelsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetModelsResponse {
    return new GetModelsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetModelsResponse | PlainMessage<GetModelsResponse> | undefined, b: GetModelsResponse | PlainMessage<GetModelsResponse> | undefined): boolean {
    return proto3.util.equals(GetModelsResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetVINRequest
 */
export class GetVINRequest extends Message<GetVINRequest> {
  /**
   * @generated from field: string year = 1;
   */
  year = "";

  /**
   * @generated from field: string make = 2;
   */
  make = "";

  /**
   * @generated from field: string model = 3;
   */
  model = "";

  constructor(data?: PartialMessage<GetVINRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetVINRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "year", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "make", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetVINRequest {
    return new GetVINRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetVINRequest {
    return new GetVINRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetVINRequest {
    return new GetVINRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetVINRequest | PlainMessage<GetVINRequest> | undefined, b: GetVINRequest | PlainMessage<GetVINRequest> | undefined): boolean {
    return proto3.util.equals(GetVINRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetVINResponse
 */
export class GetVINResponse extends Message<GetVINResponse> {
  /**
   * @generated from field: string vin = 1;
   */
  vin = "";

  constructor(data?: PartialMessage<GetVINResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetVINResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetVINResponse {
    return new GetVINResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetVINResponse {
    return new GetVINResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetVINResponse {
    return new GetVINResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetVINResponse | PlainMessage<GetVINResponse> | undefined, b: GetVINResponse | PlainMessage<GetVINResponse> | undefined): boolean {
    return proto3.util.equals(GetVINResponse, a, b);
  }
}

