import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import { Content } from '@radix-ui/react-accordion';

import { styled } from '@agentero/styles/jsx';

const ContentComponent = styled(Content, {
	base: {
		overflow: 'hidden',
		textStyle: 'body.small',
		transition: 'all',
		paddingInline: '1rem',

		'&[data-state=closed]': {
			animation: 'accordion-up'
		},

		'&[data-state=open]': {
			animation: 'accordion-down'
		},

		'& > div': {
			paddingBlockEnd: '1rem'
		}
	}
});

export const AccordionContent = forwardRef<
	ElementRef<typeof Content>,
	ComponentPropsWithoutRef<typeof Content>
>(({ children, ...props }, ref) => (
	<ContentComponent ref={ref} {...props}>
		<div>{children}</div>
	</ContentComponent>
));

AccordionContent.displayName = Content.displayName;
