'use client';

import { DetailedHTMLProps, LabelHTMLAttributes, PropsWithChildren, ReactNode } from 'react';

import { IconHelp } from '@agentero/icons';
import { css, cva } from '@agentero/styles/css';
import { Stack } from '@agentero/styles/jsx';

import { Tooltip } from '../../../Tooltip';

type LabelProps = DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
	optional?: boolean;
	info?: ReactNode;
	readonly?: boolean;
};

const label = cva({
	base: {
		display: 'flex',
		marginBottom: '8',
		color: 'neutral.10',
		textStyle: 'body.small'
	},
	variants: {
		readonly: {
			true: {
				color: 'neutral.60'
			}
		},
		optional: {
			true: {
				_after: {
					content: '" (optional)"',
					display: 'contents',
					color: 'neutral.40'
				}
			}
		}
	}
});

const LabelComponent = ({
	children,
	readonly = false,
	optional,
	...props
}: Omit<LabelProps, 'info'>) => {
	const labelClassName = label({ readonly, optional });

	const Label = readonly ? 'dt' : 'label';

	return (
		<Label className={labelClassName} {...props}>
			{children}
		</Label>
	);
};

const icon = css({
	width: '20px',
	height: '20px'
});

export const Label = ({ info, ...props }: PropsWithChildren<LabelProps>) => {
	if (info) {
		return (
			<Stack gap={4} direction="row" alignItems="start">
				<LabelComponent {...props} />

				<Tooltip content={info}>
					<IconHelp className={icon} />
				</Tooltip>
			</Stack>
		);
	}

	return <LabelComponent {...props} />;
};
