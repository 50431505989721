import styled from '@emotion/styled';

/**
 * @deprecated Use `a` or `Button` if you want to add an icon from `@agentero/ui` tag instead.
 */
export const TextLink = styled.a<{ truncate?: boolean }>`
	padding: 0;
	color: ${({ theme }) => theme.colors.gray.base};
	${({ theme }) => theme.fontWeight.regular};
	background: none;
	border: none;
	border-radius: 0;
	outline: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	cursor: pointer;

	${({ truncate }) =>
		truncate &&
		`
		display: inline-block;
		vertical-align: top;
		max-width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	`}

	&:disabled,
	&[disabled] {
		opacity: 0.4;
		pointer-events: none;
		user-select: none;
	}
`;

/**
 * @deprecated Use `a` or `Button` if you want to add an icon from `@agentero/ui` tag instead.
 */
export const TextButton = TextLink.withComponent('button');
