import { ButtonHTMLAttributes, DetailedHTMLProps, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
	FieldStatus,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalStatusbar,
	Text
} from '@agentero/components';
import { IconDelete } from '@agentero/icons';
import { Button } from '@agentero/ui';

type DeleteItemButtonProps = {
	item: string;
	name: string;
	isVisible?: boolean;
	onDelete: () => void;
	disabled: boolean;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const DeleteItemButton = ({ item, name, onDelete, disabled }: DeleteItemButtonProps) => {
	const { t } = useTranslation(['rater', 'shared']);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);

	const handleDelete = () => {
		onDelete();
		closeModal();
	};

	return (
		<>
			<Button variant="ghost" onClick={openModal} disabled={disabled}>
				<IconDelete />
			</Button>

			<Modal isOpen={isModalOpen} onDismiss={closeModal}>
				<ModalStatusbar status={FieldStatus.Warning} />
				<ModalContent>
					<ModalHeader title={t('deleteModal.title', { item: item.toLocaleLowerCase() })} />
					<ModalBody>
						<Text size="S">{t('deleteModal.body', { item: item.toLocaleLowerCase(), name })}</Text>
					</ModalBody>
					<ModalFooter>
						<Button variant="secondary" onClick={closeModal}>
							{t('shared:cancel')}
						</Button>
						<Button onClick={handleDelete}>{t('shared:delete')}</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
