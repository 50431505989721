import { PropsWithChildren } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export type CardProps = PropsWithChildren<{
	hasShadow?: boolean;
	hasBorder?: boolean; // Deprecated. It has no effect anymore.
	isSelected?: boolean;
	isHoverable?: boolean;
	mb?: number;
	role?: string;
}>;

export const Card = styled.article<CardProps>`
	padding: ${({ theme }) => theme.sizes.getSize(3)};
	${({ mb }) => (mb ? `margin-bottom: ${mb}px` : '')};
	background: ${({ theme }) => theme.colors.white};
	transition: 0.15s;
	border-radius: 6px;

	${({ as, isHoverable, theme }) =>
		(as === 'a' || as === 'button' || isHoverable) &&
		css`
			color: ${theme.colors.gray.base};
			text-decoration: none;

			@media (hover: hover) and (pointer: fine) {
				&:hover,
				&:focus {
					cursor: pointer;
					background: ${theme.colors.gray.lighten95};
					text-decoration: none;
					color: ${theme.colors.gray.base};
				}
			}

			&:active {
				color: ${theme.colors.gray.base};
				background: ${theme.colors.gray.lighten90Alpha};
			}
		`}

	border: 1px solid ${({ theme }) => theme.colors.gray.lighten90};

	${({ hasShadow, theme }) =>
		hasShadow &&
		css`
			box-shadow: ${theme.shadows.soft};
		`}

	${({ isHoverable, isSelected, theme }) =>
		isSelected &&
		css`
			border: 2px solid ${theme.colors.primary.base};
			${isHoverable &&
			css`
				&:hover {
					background: ${theme.colors.gray.lighten95};
				}
			`}
			padding: 15px;
		`}
`;
