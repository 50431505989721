import { IconInfoOutline } from '@agentero/icons';
import { css } from '@agentero/styles/css';
import { Flex, HStack } from '@agentero/styles/jsx';

import { Text } from '../../Text';

export const FieldHelp = ({ text }: { text: string }) => (
	<HStack gap="4" alignItems="flex-start">
		<Flex
			minWidth="fit-content"
			marginBlockStart="1"
			className={css({
				'& svg': {
					width: '1rem',
					height: '1rem',
					'& path': { fill: 'token(colors.neutral.20)' }
				}
			})}>
			<IconInfoOutline />
		</Flex>
		<Text size="caption" color="weak" dangerouslySetInnerHTML={{ __html: text }} />
	</HStack>
);
