import styled from '@emotion/styled';

export const ModalBody = styled.div`
	overflow: auto;
	padding: 0 32px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	word-break: break-word;
	min-height: 0;

	@media (min-height: 440px) {
		min-height: 96px;
	}

	@media (min-height: 768px) {
		max-height: 650px;
	}
`;
