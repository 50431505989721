import styled from '@emotion/styled';

export const FormItem = styled.div`
	height: 100%;
	margin-bottom: 16px;
`;

export const NestedFormItem = styled.div`
	margin-top: 16px;
`;
