const emptyValue = '—';
const emptyValueTranslation = 'emptyValue';

type StringOrNumber = string | number;

export const booleanToTextTranslation = (value?: boolean) =>
	value ? 'yes' : value === false ? 'no' : emptyValueTranslation;

export const stringToText = (value: StringOrNumber = emptyValue) =>
	(value === 0 || value) && value.toString().trim() !== '' ? `${value}` : emptyValue;

export const amountToText = (value?: StringOrNumber, currency = '$'): string =>
	value ? `${currency}${value}` : emptyValue;

export const enumToText = (
	resource: { [key: string]: string },
	t: (translation: string) => string,
	value?: StringOrNumber
) => (value ? t(resource[value]) : emptyValue);

export const singularize = (word: string) => {
	const endings = {
		ves: 'fe',
		ies: 'y',
		i: 'us',
		zes: 'ze',
		ses: 's',
		es: 'e',
		s: ''
	};
	return word.replace(
		new RegExp(`(${Object.keys(endings).join('|')})$`),
		r => endings[r as keyof typeof endings]
	);
};
