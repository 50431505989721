import React from 'react';

import styled from '@emotion/styled';

import { IconKeyboardBackspace } from '@agentero/icons';

export enum ORDER_DIRECTION {
	ASC = 'asc',
	DESC = 'desc'
}

export enum ORDER_TYPE {
	ASC_FIRST = 'asc_first',
	DESC_FIRST = 'desc_first'
}

const ascSteps = [ORDER_DIRECTION.ASC, ORDER_DIRECTION.DESC];
const descSteps = [ORDER_DIRECTION.DESC, ORDER_DIRECTION.ASC];

interface IOrderButtonProps<T> {
	orderByField: T;
	appliedOrder: IAppliedOrderBy<T> | null;
	onOrder: (orderBy: IAppliedOrderBy<T> | null) => void;
	children: React.ReactElement;
	orderType?: ORDER_TYPE;
}

interface IAppliedOrderBy<T> {
	direction: ORDER_DIRECTION;
	field: T;
}

const OrderButtonIcon = styled.div`
	flex: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 14px;
	width: 14px;
	height: 14px;
	transform-origin: center center;
	margin-right: 2px;
	margin-left: -3px;

	svg {
		width: 100%;
		height: auto;

		path {
			fill: ${({ theme }) => theme.colors.gray.base};
		}
	}
`;

interface IOrderButtonContainerProps {
	isOrder: boolean;
	direction: ORDER_DIRECTION | null;
}

const OrderButtonContainer = styled.button<IOrderButtonContainerProps>`
	position: relative;
	display: inline-flex;
	align-items: center;
	padding: 0;
	padding-left: 16px;
	max-width: 100%;
	vertical-align: top;
	${({ theme }) => theme.textSettings.S};
	${({ theme }) => theme.fontWeight.medium};
	color: ${({ theme, isOrder }) =>
		isOrder ? theme.colors.gray.base : theme.colors.gray.lighten40};
	text-align: left;
	text-decoration: none;
	background: transparent;
	border: none;
	outline: none;
	border-radius: 0;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	user-select: none;
	cursor: pointer;
	transition: color 0.15s;

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 6px;
		left: 7px;
		width: 2px;
		height: 14px;
		background: ${({ theme }) => theme.colors.gray.lighten40};
	}

	&:first-of-type {
		padding-left: 0;

		&:before {
			display: none;
		}
	}

	${OrderButtonIcon} {
		transform: ${({ direction }) =>
			direction === ORDER_DIRECTION.ASC ? 'rotate(90deg)' : 'rotate(-90deg)'};
	}

	svg path {
		fill: ${({ theme, isOrder }) =>
			isOrder ? theme.colors.gray.base : theme.colors.gray.lighten40};
	}

	&:hover {
		color: ${({ theme }) => theme.colors.gray.base};

		svg path {
			fill: ${({ theme }) => theme.colors.gray.base};
		}
	}

	&:active {
		color: ${({ theme }) => theme.colors.gray.lighten40};

		svg path {
			fill: ${({ theme }) => theme.colors.gray.lighten40};
		}
	}
`;

export const OrderButtonText = styled.div`
	display: inline-block;
	max-width: 100%;
	min-width: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	svg {
		vertical-align: middle;
	}
`;

export const OrderButton = <T extends {}>({
	children,
	appliedOrder,
	orderByField,
	onOrder,
	orderType = ORDER_TYPE.ASC_FIRST,
	...props
}: IOrderButtonProps<T>) => {
	const handleOrder = () => {
		const newOrderBy = getNextOrder({
			steps: orderType === ORDER_TYPE.ASC_FIRST ? ascSteps : descSteps,
			appliedOrder,
			orderByField
		});

		onOrder(newOrderBy);
	};

	const isOrder = appliedOrder !== null && appliedOrder.field === orderByField;

	return (
		<OrderButtonContainer
			{...props}
			isOrder={isOrder}
			direction={isOrder && appliedOrder ? appliedOrder.direction : null}
			onClick={handleOrder}>
			{isOrder && (
				<OrderButtonIcon>
					<IconKeyboardBackspace />
				</OrderButtonIcon>
			)}
			<OrderButtonText data-cy="order-button-text">{children}</OrderButtonText>
		</OrderButtonContainer>
	);
};

type NextOrderParams<T> = {
	steps: ORDER_DIRECTION[];
	orderByField: T;
	appliedOrder: IAppliedOrderBy<T> | null;
};

type NextOrder = <T extends {}>(args: NextOrderParams<T>) => IAppliedOrderBy<T> | null;

const getNextOrder: NextOrder = ({ steps, appliedOrder, orderByField }) => {
	const currentField = appliedOrder?.field;
	const currentDirection = appliedOrder?.direction;

	const index = steps.findIndex(
		stepDirection => currentField == orderByField && currentDirection == stepDirection
	);

	if (index < steps.length - 1) {
		return {
			direction: steps[index + 1],
			field: orderByField
		};
	}
	return null;
};
