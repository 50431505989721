import { PropsWithChildren } from 'react';

import { IconCheck } from '@agentero/icons';
import { styled } from '@agentero/styles/jsx';

const CheckListRoot = styled('ul', {
	base: {
		display: 'flex',
		flexDirection: 'column',
		gap: '8',
		textStyle: 'body.small'
	}
});

const CheckListItemRoot = styled('li', {
	base: {
		display: 'flex',
		alignItems: 'flex-start',
		gap: '8'
	}
});

const CheckListItemIcon = styled('div', {
	base: {
		'--size': '1.25em',
		position: 'relative',
		top: '0.125em',
		flexShrink: 0,
		minWidth: 'var(--size)',
		'& svg': {
			width: 'var(--size)',
			height: 'var(--size)'
		}
	}
});

const CheckListItem = ({ children }: PropsWithChildren) => {
	return (
		<CheckListItemRoot>
			<CheckListItemIcon>
				<IconCheck />
			</CheckListItemIcon>
			{children}
		</CheckListItemRoot>
	);
};

export const CheckList = {
	Root: CheckListRoot,
	Item: CheckListItem
};
