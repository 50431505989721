import logger from 'logging/logger';

import { AddLog } from '@agentero/service-errors';

import { postNewLog } from 'packages/services/mutate/next-js/postNewLog';

export enum LogLevel {
	Info = 'info',
	Warn = 'warn',
	Error = 'error'
}

export const addLog: AddLog = (log, logLevel = LogLevel.Error, logInServer = true) => {
	const isServer = typeof window === 'undefined';
	const userAgent = isServer ? 'server' : navigator.userAgent;

	// If we are in the client and we want to log in the server,
	if (!isServer && logInServer) {
		postNewLog({ ...log, userAgent }, logLevel);
		return;
	}

	// If we are in the server and we want to log in the server
	// or we are in the client and we want to log in the browser
	if ((isServer && logInServer) || !isServer) {
		logger[logLevel]({ ...log, userAgent });
	}
};
