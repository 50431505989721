import { GetSessionResponse } from '@agentero/service-clients/portal/session';

import { EOStatus, OperateAs, parseOperateAsConnect } from '../agency';
import { isEOMissing, parseEOStatusConnect } from '../agency/AgencyEO';
import {
	ComplianceLicenseGlobalStatus,
	isLicenseMissing,
	isLicenseValid,
	parseComplianceGlobalStatusConnect
} from '../compliance';
import { Role } from './Role';
import { parseBwAQualificationConnect } from './shared/BwAQualification';

export type AgentCompliance = {
	eoStatus: EOStatus;
	bwaQualified?: boolean;
	agencyLicenseStatus: ComplianceLicenseGlobalStatus;
	agencyAdminLicenseStatus: ComplianceLicenseGlobalStatus;
	agentLicenseStatus: ComplianceLicenseGlobalStatus;
	operateAs: OperateAs;
	agencyResidentStatus: ComplianceLicenseGlobalStatus;
	agentResidentStatus: ComplianceLicenseGlobalStatus;
};

export const parseAgentCompliance = (agentSession: GetSessionResponse): AgentCompliance => ({
	eoStatus: parseEOStatusConnect(agentSession?.eAndOStatus) ?? EOStatus.Invalid,
	agencyLicenseStatus:
		parseComplianceGlobalStatusConnect(agentSession.agencyLicenseStatus) ??
		ComplianceLicenseGlobalStatus.None,
	agentLicenseStatus:
		parseComplianceGlobalStatusConnect(agentSession.agentLicenseStatus) ??
		ComplianceLicenseGlobalStatus.None,
	operateAs: parseOperateAsConnect(agentSession.howOperateAs) ?? OperateAs.Unspecified,
	bwaQualified: parseBwAQualificationConnect(agentSession.bwaQualification),
	agencyResidentStatus:
		parseComplianceGlobalStatusConnect(agentSession.agencyResidentLicenseStatus) ??
		ComplianceLicenseGlobalStatus.None,
	agentResidentStatus:
		parseComplianceGlobalStatusConnect(agentSession.agentResidentLicenseStatus) ??
		ComplianceLicenseGlobalStatus.None,
	agencyAdminLicenseStatus:
		parseComplianceGlobalStatusConnect(agentSession.agencyAdminLicenseStatus) ??
		ComplianceLicenseGlobalStatus.None
});

export const isComplianceMissing = (compliance: AgentCompliance, role: Role) => {
	if (compliance.operateAs === OperateAs.Unspecified) return true;

	if (role === Role.AgencyAdmin) {
		const isAgencyLicenseMissing = isLicenseMissing(compliance.agencyLicenseStatus);
		const isAgentLicenseMissing = isLicenseMissing(compliance.agentLicenseStatus);
		const isEAndOMissing = isEOMissing(compliance.eoStatus);

		if (compliance.operateAs === OperateAs.Agency) {
			return isAgencyLicenseMissing || isAgentLicenseMissing || isEAndOMissing;
		} else {
			return isAgentLicenseMissing || isEAndOMissing;
		}
	}

	return false; //NOTE: This avoids to show the wizard to normal Agents
};

export const isComplianceBwaValid = (compliance: AgentCompliance) => {
	return compliance.bwaQualified;
};

export const isAppointmentsComplianceValid = (compliance: AgentCompliance) => {
	return compliance.bwaQualified;
};

export const isComplianceValidWithEOValid = (compliance: AgentCompliance) => {
	const isAgencyLicenseValid = isLicenseValid(compliance.agencyLicenseStatus);
	const isAgentAdminLicenseValid = isLicenseValid(compliance.agencyAdminLicenseStatus);
	const isEAndOMissing = isEOMissing(compliance.eoStatus);

	if (compliance.operateAs === OperateAs.Agency) {
		return isAgencyLicenseValid && isAgentAdminLicenseValid && !isEAndOMissing;
	}

	const isAgentLicenseValid = isLicenseValid(compliance.agentLicenseStatus);

	return isAgentAdminLicenseValid && isAgentLicenseValid && !isEAndOMissing;
};

export const isResidentStateValid = (compliance: AgentCompliance) => {
	return compliance.operateAs === OperateAs.Agency
		? compliance.agencyResidentStatus === ComplianceLicenseGlobalStatus.Valid
		: compliance.agentResidentStatus === ComplianceLicenseGlobalStatus.Valid;
};

export const getAgentLicenseGlobalStatus = (
	compliance: AgentCompliance
): ComplianceLicenseGlobalStatus => {
	const isAgentLicenseValid = isLicenseValid(compliance.agentLicenseStatus);

	if (isAgentLicenseValid) {
		const isAgentResidentLicenseValid = isLicenseValid(compliance.agentResidentStatus);
		return isAgentResidentLicenseValid
			? ComplianceLicenseGlobalStatus.Valid
			: ComplianceLicenseGlobalStatus.ResidentStatusInvalid;
	}

	return compliance.agentLicenseStatus;
};

export const getAgencyAdminLicenseGlobalStatus = (
	compliance: AgentCompliance,
	isAgencyAdmin: boolean
): ComplianceLicenseGlobalStatus => {
	const isAgencyAdminLicenseValid = isLicenseValid(compliance.agencyAdminLicenseStatus);

	if (isAgencyAdminLicenseValid && isAgencyAdmin) {
		const isAgentResidentLicenseValid = isLicenseValid(compliance.agentResidentStatus);
		return isAgentResidentLicenseValid
			? ComplianceLicenseGlobalStatus.Valid
			: ComplianceLicenseGlobalStatus.ResidentStatusInvalid;
	}

	return compliance.agencyAdminLicenseStatus;
};

export const getAgencyLicenseGlobalStatus = (
	compliance: AgentCompliance
): ComplianceLicenseGlobalStatus => {
	const isAgencyLicenseValid = isLicenseValid(compliance.agencyLicenseStatus);

	if (isAgencyLicenseValid) {
		const isAgencyResidentLicenseValid = isLicenseValid(compliance.agencyResidentStatus);

		return isAgencyResidentLicenseValid
			? ComplianceLicenseGlobalStatus.Valid
			: ComplianceLicenseGlobalStatus.ResidentStatusInvalid;
	}

	return compliance.agencyLicenseStatus;
};
