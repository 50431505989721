// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file agentero/portal/session.proto (package agentero.portal, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { InsuranceType } from "../policy/policy_pb";
import { EAndOStatus, OperateAs } from "./agency_pb";
import { LicenseGlobalStatus } from "../license/license_pb";
import { CurrentTier } from "../tier/tier_pb";

/**
 * QualificationStatus represents the bwa qualification status.
 *
 * @generated from enum agentero.portal.QualificationStatus
 */
export enum QualificationStatus {
  /**
   * @generated from enum value: QUALIFICATION_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: QUALIFICATION_STATUS_QUALIFIED = 1;
   */
  QUALIFIED = 1,

  /**
   * @generated from enum value: QUALIFICATION_STATUS_NOT_QUALIFIED = 2;
   */
  NOT_QUALIFIED = 2,

  /**
   * @generated from enum value: QUALIFICATION_STATUS_PENDING_QUALIFICATION = 3;
   */
  PENDING_QUALIFICATION = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(QualificationStatus)
proto3.util.setEnumType(QualificationStatus, "agentero.portal.QualificationStatus", [
  { no: 0, name: "QUALIFICATION_STATUS_UNSPECIFIED" },
  { no: 1, name: "QUALIFICATION_STATUS_QUALIFIED" },
  { no: 2, name: "QUALIFICATION_STATUS_NOT_QUALIFIED" },
  { no: 3, name: "QUALIFICATION_STATUS_PENDING_QUALIFICATION" },
]);

/**
 * @generated from enum agentero.portal.AgentRole
 */
export enum AgentRole {
  /**
   * @generated from enum value: AGENT_ROLE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: AGENT_ROLE_REGULAR_AGENT = 1;
   */
  REGULAR_AGENT = 1,

  /**
   * @generated from enum value: AGENT_ROLE_AGENCY_ADMIN = 2;
   */
  AGENCY_ADMIN = 2,

  /**
   * @generated from enum value: AGENT_ROLE_SUPER_ADMIN = 3;
   */
  SUPER_ADMIN = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(AgentRole)
proto3.util.setEnumType(AgentRole, "agentero.portal.AgentRole", [
  { no: 0, name: "AGENT_ROLE_UNSPECIFIED" },
  { no: 1, name: "AGENT_ROLE_REGULAR_AGENT" },
  { no: 2, name: "AGENT_ROLE_AGENCY_ADMIN" },
  { no: 3, name: "AGENT_ROLE_SUPER_ADMIN" },
]);

/**
 * @generated from message agentero.portal.GetSessionRequest
 */
export class GetSessionRequest extends Message<GetSessionRequest> {
  constructor(data?: PartialMessage<GetSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSessionRequest {
    return new GetSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSessionRequest {
    return new GetSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSessionRequest {
    return new GetSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSessionRequest | PlainMessage<GetSessionRequest> | undefined, b: GetSessionRequest | PlainMessage<GetSessionRequest> | undefined): boolean {
    return proto3.util.equals(GetSessionRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetSessionResponse
 */
export class GetSessionResponse extends Message<GetSessionResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string full_name = 3;
   */
  fullName = "";

  /**
   * @generated from field: string agency_id = 5;
   */
  agencyId = "";

  /**
   * @generated from field: string agency_name = 6;
   */
  agencyName = "";

  /**
   * @generated from field: bool has_requested_import_email_setup = 12;
   */
  hasRequestedImportEmailSetup = false;

  /**
   * @generated from field: bool binding_with_agentero = 15;
   */
  bindingWithAgentero = false;

  /**
   * @generated from field: bool has_commercial_rater = 16;
   */
  hasCommercialRater = false;

  /**
   * @generated from field: bool appointments_enabled = 17;
   */
  appointmentsEnabled = false;

  /**
   * @generated from field: repeated agentero.policy.InsuranceType quotable_lobs = 18;
   */
  quotableLobs: InsuranceType[] = [];

  /**
   * @generated from field: agentero.portal.EAndOStatus e_and_o_status = 19;
   */
  eAndOStatus = EAndOStatus.UNSPECIFIED;

  /**
   * @generated from field: agentero.portal.QualificationStatus bwa_qualification = 20;
   */
  bwaQualification = QualificationStatus.UNSPECIFIED;

  /**
   * @generated from field: agentero.license.LicenseGlobalStatus agency_license_status = 21;
   */
  agencyLicenseStatus = LicenseGlobalStatus.UNSPECIFIED;

  /**
   * @generated from field: agentero.license.LicenseGlobalStatus agent_license_status = 22;
   */
  agentLicenseStatus = LicenseGlobalStatus.UNSPECIFIED;

  /**
   * @generated from field: agentero.portal.OperateAs how_operate_as = 23;
   */
  howOperateAs = OperateAs.UNSPECIFIED;

  /**
   * @generated from field: agentero.license.LicenseGlobalStatus agency_resident_license_status = 24;
   */
  agencyResidentLicenseStatus = LicenseGlobalStatus.UNSPECIFIED;

  /**
   * @generated from field: agentero.license.LicenseGlobalStatus agent_resident_license_status = 25;
   */
  agentResidentLicenseStatus = LicenseGlobalStatus.UNSPECIFIED;

  /**
   * @generated from field: agentero.license.LicenseGlobalStatus agency_admin_license_status = 26;
   */
  agencyAdminLicenseStatus = LicenseGlobalStatus.UNSPECIFIED;

  /**
   * @generated from field: agentero.tier.CurrentTier current_tier = 27;
   */
  currentTier?: CurrentTier;

  /**
   * @generated from field: agentero.portal.CommunicationPreferences communication_preferences = 28;
   */
  communicationPreferences?: CommunicationPreferences;

  /**
   * @generated from field: bool has_terminated_appointments = 29;
   */
  hasTerminatedAppointments = false;

  /**
   * The following fields must be included to replace the current_expert endpoint
   *
   * @generated from field: string token = 30;
   */
  token = "";

  /**
   * @generated from field: string first_name = 31;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 32;
   */
  lastName = "";

  /**
   * @generated from field: string photo_url = 33;
   */
  photoUrl = "";

  /**
   * @generated from field: bool is_impersonated = 34;
   */
  isImpersonated = false;

  /**
   * @generated from field: bool is_contract_accepted = 35;
   */
  isContractAccepted = false;

  /**
   * @generated from field: string phone_number = 36;
   */
  phoneNumber = "";

  /**
   * @generated from field: string agency_form_stack_name = 37;
   */
  agencyFormStackName = "";

  /**
   * Before called "is_agency_admin".
   *
   * @generated from field: agentero.portal.AgentRole role = 38;
   */
  role = AgentRole.UNSPECIFIED;

  constructor(data?: PartialMessage<GetSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "agency_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "has_requested_import_email_setup", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "binding_with_agentero", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "has_commercial_rater", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "appointments_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "quotable_lobs", kind: "enum", T: proto3.getEnumType(InsuranceType), repeated: true },
    { no: 19, name: "e_and_o_status", kind: "enum", T: proto3.getEnumType(EAndOStatus) },
    { no: 20, name: "bwa_qualification", kind: "enum", T: proto3.getEnumType(QualificationStatus) },
    { no: 21, name: "agency_license_status", kind: "enum", T: proto3.getEnumType(LicenseGlobalStatus) },
    { no: 22, name: "agent_license_status", kind: "enum", T: proto3.getEnumType(LicenseGlobalStatus) },
    { no: 23, name: "how_operate_as", kind: "enum", T: proto3.getEnumType(OperateAs) },
    { no: 24, name: "agency_resident_license_status", kind: "enum", T: proto3.getEnumType(LicenseGlobalStatus) },
    { no: 25, name: "agent_resident_license_status", kind: "enum", T: proto3.getEnumType(LicenseGlobalStatus) },
    { no: 26, name: "agency_admin_license_status", kind: "enum", T: proto3.getEnumType(LicenseGlobalStatus) },
    { no: 27, name: "current_tier", kind: "message", T: CurrentTier },
    { no: 28, name: "communication_preferences", kind: "message", T: CommunicationPreferences },
    { no: 29, name: "has_terminated_appointments", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 30, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 32, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 33, name: "photo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 34, name: "is_impersonated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 35, name: "is_contract_accepted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 36, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 37, name: "agency_form_stack_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 38, name: "role", kind: "enum", T: proto3.getEnumType(AgentRole) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSessionResponse {
    return new GetSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSessionResponse {
    return new GetSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSessionResponse {
    return new GetSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSessionResponse | PlainMessage<GetSessionResponse> | undefined, b: GetSessionResponse | PlainMessage<GetSessionResponse> | undefined): boolean {
    return proto3.util.equals(GetSessionResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.CommunicationPreferences
 */
export class CommunicationPreferences extends Message<CommunicationPreferences> {
  /**
   * @generated from field: bool email = 1;
   */
  email = false;

  /**
   * @generated from field: bool sms = 2;
   */
  sms = false;

  constructor(data?: PartialMessage<CommunicationPreferences>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.CommunicationPreferences";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "sms", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CommunicationPreferences {
    return new CommunicationPreferences().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CommunicationPreferences {
    return new CommunicationPreferences().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CommunicationPreferences {
    return new CommunicationPreferences().fromJsonString(jsonString, options);
  }

  static equals(a: CommunicationPreferences | PlainMessage<CommunicationPreferences> | undefined, b: CommunicationPreferences | PlainMessage<CommunicationPreferences> | undefined): boolean {
    return proto3.util.equals(CommunicationPreferences, a, b);
  }
}

