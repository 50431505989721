import { FC, PropsWithChildren, ReactNode } from 'react';

import { Divider, styled } from '@agentero/styles/jsx';

import { Text } from './Text';

type SectionComponents = {
	Title: FC<SectionTitleProps>;
	Divider: FC;
	Header: FC<PropsWithChildren>;
};

const SectionRoot = styled('section', {
	base: {
		display: 'flex',
		flexDirection: 'column',
		gap: '16'
	}
});

type SectionTitleProps = {
	children: ReactNode;
	hasBorder?: boolean;
};

const SectionTitleRoot = styled('hgroup', {
	base: {
		display: 'flex',
		flexDirection: 'column',
		gap: '4',
		position: 'relative'
	}
});

const SectionTitle = ({ children }: SectionTitleProps) => (
	<Text size="title.body" as="h3" mb="4">
		{children}
	</Text>
);

const SectionDivider = () => <Divider color="border" />;

const SectionHeader = ({ children }: PropsWithChildren) => (
	<SectionTitleRoot>{children}</SectionTitleRoot>
);

export const Section: FC<PropsWithChildren> & SectionComponents = ({ children }) => (
	<SectionRoot>{children}</SectionRoot>
);

Section.Header = SectionHeader;
Section.Title = SectionTitle;
Section.Divider = SectionDivider;
