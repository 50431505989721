import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';

import { styled } from '@agentero/styles/jsx';

import { FieldStatus } from '../../shared/fieldStatus';
import { removeAllDots } from '../../shared/formGroupUtils';

export type CheckChipsValue = {
	label: string;
	value: boolean | string;
	onChange?: (value: boolean | string) => void;
};

export type CheckChipsValueProps = {
	status?: FieldStatus;
	onChange?: (value: boolean | string) => void;
	onBlur?: () => void;
	value?: boolean | string;
	inputProps?: Omit<
		DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
		'css' | 'size' | 'ref'
	>;
	values: CheckChipsValue[];
	defaultValue?: string | boolean;
};

const InputCheck = styled('input', {
	base: {
		position: 'absolute',
		margin: '0.813rem 0.625rem 0 0.625rem',
		borderRadius: '50%',
		appearance: 'none',
		_before: {
			content: '""',
			display: 'inline-block',
			width: '0.75rem',
			height: '0.75rem',
			marginTop: '1px',
			borderColor: 'neutral.80',
			borderWidth: '0.0625rem',
			verticalAlign: 'top',
			borderRadius: '50%',
			backgroundPosition: 'center center',
			transition: '0.15s'
		},
		_checked: {
			'&+ label': {
				background: 'brand.95',
				borderColor: 'brand.20',
				color: 'brand.20',
				_hover: {
					background: 'brand.90'
				}
			},
			_before: {
				borderColor: 'brand.20',
				backgroundRepeat: 'no-repeat',

				backgroundColor: 'brand.20',
				backgroundImage:
					"url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='7' viewBox='0 0 24 24'%3E%3Cpath d='M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z' fill='%23fff'/%3E%3C/svg%3E\")"
			}
		},
		_focusVisible: {
			'&+ label': {
				outlineColor: 'brand',
				outlineOffset: '0.125rem',
				outlineStyle: 'solid',
				outlineWidth: '0.125rem'
			}
		}
	}
});

const LabelCheck = styled('label', {
	base: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		paddingLeft: '28px',
		borderRadius: '1.5rem',
		borderWidth: '0.0625rem',
		borderColor: 'neutral.80',
		paddingInline: '12',
		textStyle: 'body.small',
		minHeight: '2rem',
		minWidth: '4.5rem',
		marginTop: '0.25rem',
		cursor: 'pointer',
		_hover: {
			background: 'neutral.95'
		}
	},
	variants: {
		hasError: {
			true: {
				borderColor: 'danger',
				_focusVisible: {
					outlineColor: 'danger'
				}
			}
		}
	}
});

const Container = styled('ul', {
	base: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: '8',
		position: 'relative'
	}
});

export const CheckChips = ({
	onChange,
	value,
	values,
	status,
	inputProps
}: CheckChipsValueProps) => {
	return (
		<Container>
			{values.map(({ label, value: valueProp, onChange: onChangeProp }) => {
				const id = `${removeAllDots(inputProps?.name ?? '')}${label}`;

				return (
					<li key={id}>
						<InputCheck
							{...inputProps}
							type="checkbox"
							id={id}
							checked={value !== undefined ? value === valueProp : undefined}
							value={String(valueProp)}
							aria-label={label}
							onChange={event => {
								onChangeProp?.(valueProp);
								onChange?.(valueProp);
								inputProps?.onChange?.(event);
							}}
						/>
						<LabelCheck htmlFor={id} hasError={status === FieldStatus.Error}>
							{label}
						</LabelCheck>
					</li>
				);
			})}
		</Container>
	);
};
