import { Timestamp } from '@bufbuild/protobuf';

import { CurrentTier, PaymentState } from '@agentero/grpc-clients/grpc/shared/tier';
import {
	CurrentTier as CurrentTierConnect,
	PaymentState as PaymentStateConnect
} from '@agentero/service-clients/portal/shared/tier';
import { getDateFromGrpc, parseDateToLargeString } from '@agentero/utils';

import { Tier, parseTier, parseTierConnect } from '../tier';
import {
	Campaign,
	isIncludedInTwoPoliciesMonths,
	parseCampaignEnum,
	parseCampaignEnumConnect
} from './agentSubscription/Campaign';

export type AgentSubscription = {
	tier: Tier;
	isTrial: boolean;
	trialDaysLeft: number;
	hasFirstPolicySold: boolean;
	isBlocked: boolean;
	isActive: boolean;
	activeAt?: string;
	campaigns?: Campaign[];
	isPaymentFailed?: boolean;
	isBasedOnPif: boolean;
};

export const isAllowedToGetAppointed = (subscription: AgentSubscription): boolean => {
	return (
		subscription.tier !== Tier.Bronze ||
		(!!subscription.campaigns?.length && isIncludedInTwoPoliciesMonths(subscription.campaigns))
	);
};

const parseSubscriptionBlocked = (currentTier?: CurrentTier.AsObject): boolean => {
	return currentTier
		? currentTier.isTrial && currentTier.trialDaysLeft <= 0 && !currentTier.hasSoldFirstPolicy
		: false;
};

const parseSubscriptionBlockedConnect = (currentTier?: CurrentTierConnect): boolean => {
	return currentTier
		? currentTier.isTrial && currentTier.trialDaysLeft <= 0 && !currentTier.hasSoldFirstPolicy
		: false;
};

export const parseAgentSubscription = (
	currentTier: CurrentTier.AsObject | undefined
): AgentSubscription => {
	const suscriptionMigrationActive = currentTier
		? getDateFromGrpc(currentTier?.migrationActiveAt)
		: undefined;

	return {
		hasFirstPolicySold: !!currentTier?.hasSoldFirstPolicy,
		trialDaysLeft: currentTier?.trialDaysLeft || 0,
		isTrial: !!currentTier?.isTrial,
		tier: parseTier(currentTier?.tier),
		isBlocked: parseSubscriptionBlocked(currentTier),
		isActive: !suscriptionMigrationActive || suscriptionMigrationActive < new Date(),
		activeAt: suscriptionMigrationActive
			? parseDateToLargeString({ date: suscriptionMigrationActive, language: 'en-US' })
			: undefined,
		campaigns: currentTier?.campaignsList?.length
			? (currentTier?.campaignsList
					.map(campaign => parseCampaignEnum(campaign))
					.filter(Boolean) as Campaign[])
			: undefined,
		isPaymentFailed: currentTier?.paymentState === PaymentState.PAYMENT_STATE_FAILED,
		isBasedOnPif: !!currentTier?.isBasedOnPif
	};
};

export const parseAgentSubscriptionConnect = (
	currentTier: CurrentTierConnect | undefined
): AgentSubscription | undefined => {
	if (!currentTier) return undefined;

	const subscriptionMigrationActive = currentTier
		? new Timestamp(currentTier?.migrationActiveAt).toDate()
		: undefined;

	return {
		hasFirstPolicySold: !!currentTier?.hasSoldFirstPolicy,
		trialDaysLeft: currentTier?.trialDaysLeft || 0,
		isTrial: !!currentTier?.isTrial,
		tier: parseTierConnect(currentTier?.tier),
		isBlocked: parseSubscriptionBlockedConnect(currentTier),
		isActive: !subscriptionMigrationActive || subscriptionMigrationActive < new Date(),
		activeAt: subscriptionMigrationActive
			? parseDateToLargeString({ date: subscriptionMigrationActive, language: 'en-US' })
			: undefined,
		campaigns: currentTier?.campaigns?.length
			? (currentTier?.campaigns
					.map(campaign => parseCampaignEnumConnect(campaign))
					.filter(Boolean) as Campaign[])
			: undefined,
		isPaymentFailed: currentTier?.paymentState === PaymentStateConnect.FAILED,
		isBasedOnPif: !!currentTier?.isBasedOnPif
	};
};
