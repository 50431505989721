import { ReactNode } from 'react';

import styled from '@emotion/styled';

import { removeAllDots } from '@agentero/ui';

import { Text } from '../../Typography';
import { Label } from '../Label';

type ReadonlyFieldProps = {
	name: string;
	label: string;
	value?: string;
	description?: ReactNode;
	optional?: boolean;
};

const ReadonlyFieldContainer = styled.div`
	margin-bottom: 16px;
`;

export const ReadonlyField = ({
	name,
	label,
	value,
	description,
	optional
}: ReadonlyFieldProps) => (
	<ReadonlyFieldContainer>
		<Label
			id={`${removeAllDots(name)}-term`}
			readonly
			info={description}
			optional={optional}
			aria-label={label}>
			{label}
		</Label>
		<Text size="S" as="dd" aria-labelledby={`${removeAllDots(name)}-term`}>
			{value}
		</Text>
	</ReadonlyFieldContainer>
);
