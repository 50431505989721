import { useRef } from 'react';

import { createCalendar } from '@internationalized/date';
import { AriaRangeCalendarProps, DateValue, useRangeCalendar } from '@react-aria/calendar';
import { useDateFormatter } from '@react-aria/i18n';
import { useRangeCalendarState } from '@react-stately/calendar';

import { IconKeyboardArrowLeft, IconKeyboardArrowRight } from '@agentero/icons';
import { Divider, HStack, Stack, VisuallyHidden } from '@agentero/styles/jsx';
import { Text } from '@agentero/ui';

import { CalendarGrid } from './calendar/CalendarGrid';
import { ButtonAria } from './shared/ButtonAria';

type CalendarRangeProps = AriaRangeCalendarProps<DateValue>;

export const CalendarRange = (props: CalendarRangeProps) => {
	const state = useRangeCalendarState({
		...props,
		locale: 'en-US',
		visibleDuration: { months: 2 },
		createCalendar
	});

	const monthDateFormatter = useDateFormatter({
		month: 'long',
		year: 'numeric'
	});

	const ref = useRef(null);
	const { calendarProps, prevButtonProps, nextButtonProps } = useRangeCalendar(props, state, ref);

	return (
		<Stack
			{...calendarProps}
			ref={ref}
			width="fit-content"
			border="0.0625rem solid token(colors.border)"
			padding={16}
			borderRadius="lg"
			background="white">
			<HStack gap={24}>
				<HStack flex={1} justifyContent="space-between">
					<VisuallyHidden>
						<h2>{calendarProps['aria-label']}</h2>
					</VisuallyHidden>
					<ButtonAria variant="ghost" {...prevButtonProps}>
						<IconKeyboardArrowLeft />
					</ButtonAria>
					<Text as="h2" aria-hidden>
						<b>{monthDateFormatter.format(state.visibleRange.start.toDate(state.timeZone))}</b>
					</Text>
					<div />
				</HStack>

				<HStack flex={1} justifyContent="space-between">
					<div />
					<Text as="h2" aria-hidden>
						<b>
							{monthDateFormatter.format(
								state.visibleRange.start.add({ months: 1 }).toDate(state.timeZone)
							)}
						</b>
					</Text>
					<ButtonAria variant="ghost" {...nextButtonProps}>
						<IconKeyboardArrowRight />
					</ButtonAria>
				</HStack>
			</HStack>
			<HStack gap={12} alignItems="flex-start">
				<CalendarGrid state={state} />
				<Divider orientation="vertical" />
				<CalendarGrid state={state} offset={{ months: 1 }} />
			</HStack>
		</Stack>
	);
};
