import {
	ComponentType,
	PropsWithChildren,
	SVGProps,
	useCallback,
	useContext,
	useState
} from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { IconKeyboardArrowRight } from '@agentero/icons';
import { ITheme } from '@agentero/styles/emotion';

import { DropdownMenuContex } from '../DropdownMenu';

interface IDropdownItem {
	text: string;
	Icon?: ComponentType<SVGProps<SVGSVGElement>>;
	onClick?: () => void;
	closePanelOnClick?: boolean;
	isSelected: boolean;
}

const DropdownItemContainer = styled.li<{ isOpen: boolean; isSelected: boolean }>`
	cursor: pointer;
	color: ${props =>
		props.isOpen || props.isSelected
			? props.theme.colors.gray.base
			: props.theme.colors.gray.lighten40};
	${props =>
		props.isOpen || props.isSelected
			? props.theme.fontWeight.medium
			: props.theme.fontWeight.regular}
	position: relative;

	&:hover {
		background: ${props => props.theme.colors.gray.lighten95};
	}
`;

const DropdownItemButton = styled.div`
	display: flex;
	align-items: center;
	padding: 8px 16px;
`;

const DropdownItemOption = styled.div`
	position: relative;
	flex: 1;
`;

const icon = (theme: ITheme, isOpen: boolean, isSelected: boolean) => css`
	transform: rotate(90deg);
	transform: ${isOpen || isSelected ? 'rotate(-90deg)' : ''};

	${theme.mediaqueries.M} {
		transform: rotate(0deg);
		transform: ${isOpen || isSelected ? 'rotate(0deg)' : ''};
	}
	path {
		fill: ${isOpen || isSelected ? theme.colors.gray.base : theme.colors.gray.lighten40};
	}
`;

const DropdownItemSubPanelContainer = styled.div`
	margin-top: -8px;

	${props => props.theme.mediaqueries.M} {
		width: 240px;
		position: absolute;
		z-index: 1;
		overflow: hidden;
		left: 100%;
		top: 0;
		box-shadow: ${props => props.theme.shadows.hard};
		background: ${props => props.theme.colors.white};
	}
`;

const DropdownItemSubPanel = styled.ul`
	padding: 8px 0;
	overflow-y: auto;
	max-height: 169px;

	${props => props.theme.mediaqueries.M} {
		max-height: 298px;
	}
`;

export const DropdownItem = ({
	children,
	text,
	Icon,
	onClick,
	closePanelOnClick = false,
	isSelected,
	...props
}: PropsWithChildren<IDropdownItem>) => {
	const { onClose } = useContext(DropdownMenuContex);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const onCloseSubPanel = useCallback(() => {
		if (children) {
			setIsOpen(false);
		}
	}, [setIsOpen, children]);
	const onOpenSubPanel = useCallback(() => {
		if (children) {
			setIsOpen(true);
		}
	}, [setIsOpen]);

	const onItemClick = useCallback(() => {
		if (onClick) {
			onClick();
		}
		if (closePanelOnClick) {
			onClose();
		}
	}, [onClick]);

	return (
		<DropdownItemContainer
			{...props}
			isSelected={isSelected}
			onMouseEnter={onOpenSubPanel}
			onMouseLeave={onCloseSubPanel}
			isOpen={isOpen}
			onClick={onItemClick}>
			{Icon && <Icon css={(theme: ITheme) => icon(theme, isOpen, isSelected)} />}
			<DropdownItemButton>
				<DropdownItemOption>{text}</DropdownItemOption>
				{children && (
					<IconKeyboardArrowRight css={(theme: ITheme) => icon(theme, isOpen, isSelected)} />
				)}
			</DropdownItemButton>
			{children && isOpen && (
				<DropdownItemSubPanelContainer data-cy="dropdown-panel-item-subpanel">
					<DropdownItemSubPanel>{children}</DropdownItemSubPanel>
				</DropdownItemSubPanelContainer>
			)}
		</DropdownItemContainer>
	);
};
