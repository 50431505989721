import {
	AppointmentType,
	GetCarrierAppetiteDataResponse
} from '@agentero/grpc-clients/grpc/carrier-fe';

export enum CarrierRaterStatus {
	Appointed = 'appointed',
	Direct = 'direct',
	BWA = 'bind-with-agentero'
}

const appointmentFromProtoMapping = {
	[AppointmentType.APPOINTMENT_TYPE_UNDEFINED]: undefined,
	[AppointmentType.APPOINTMENT_TYPE_APPOINTED]: CarrierRaterStatus.Appointed,
	[AppointmentType.APPOINTMENT_TYPE_DIRECT]: CarrierRaterStatus.Direct,
	[AppointmentType.APPOINTMENT_TYPE_BIND_WITH_AGENTERO]: CarrierRaterStatus.BWA
};

const parseAppointmentType = (appointmentType: AppointmentType): CarrierRaterStatus => {
	return appointmentFromProtoMapping[appointmentType]!;
};

export type CarrierRaterAppetite = {
	status: CarrierRaterStatus;
	states: string[];
	appetiteContent: string;
	bwaQuotableStates: string[];
	appointedQuotableStates: string[];
	directQuotableStates: string[];
	bwaDisabledQuotableStates: string[];
	appointedDisabledQuotableStates: string[];
};

export const parseCarrierRaterAppetite = (
	appetiteContent: GetCarrierAppetiteDataResponse.AsObject
): CarrierRaterAppetite => {
	return {
		status: parseAppointmentType(appetiteContent.appointmentType),
		states: appetiteContent.statesList,
		appetiteContent: appetiteContent.appetiteContent,
		bwaQuotableStates: appetiteContent.bwaQuotableStatesList,
		appointedQuotableStates: appetiteContent.appointedQuotableStatesList,
		directQuotableStates: appetiteContent.directQuotableStatesList,
		bwaDisabledQuotableStates: appetiteContent.bwaDisabledStatesList,
		appointedDisabledQuotableStates: appetiteContent.appointedDisabledStatesList
	};
};
