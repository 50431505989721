import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

import { getUtcMilliseconds } from './date';

export const getProtoTimestampFromDate = (date?: Date) => {
	const timestamp = new Timestamp();

	if (!date) return timestamp;

	timestamp.setSeconds(getUtcMilliseconds(date) / 1000);
	return timestamp;
};

export const getProtoTimestamp = (date: Date) => {
	const timestamp = new Timestamp();
	timestamp.fromDate(date);

	return timestamp;
};

export const getDateFromGrpc = (date: Timestamp.AsObject | undefined): Date => {
	if (!date) return new Date(1900, 0, 1);

	return new Date(date.seconds * 1000);
};
