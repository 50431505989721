import { useState } from 'react';

import { AppliedFilter } from '@agentero/components';

import { Search, TranslationsOmitted, fieldsExcludedFromSearch } from '../useAgUrlState';
import { OnFilterChangeArgs, ValueFromKey } from './filters';

const translateFilter = <T extends Search<T['field']>, K extends keyof T>(
	translations: TranslationsOmitted<T>,
	key: K,
	value: ValueFromKey<T[K]>
) => {
	//@ts-ignore
	if (translations[key]) {
		//@ts-ignore
		return translations[key](value);
	}
	return value as string;
};

export const getInitialAppliedFilters = <T extends Search<T['field']>>(
	search: T,
	translations: TranslationsOmitted<T>
): AppliedFilter[] => {
	return Object.keys(search)
		.filter(key => !fieldsExcludedFromSearch.includes(key))
		.reduce((acc, key) => {
			const value = search[key];
			if (Array.isArray(value)) {
				return [
					...acc,
					...value.map(value => ({
						label: translateFilter(translations, key, value as ValueFromKey<T[string]>),
						key,
						value
					}))
				];
			}
			if (value) {
				return [
					...acc,
					{
						label: translateFilter(translations, key, value as ValueFromKey<T[string]>),
						key,
						value
					} as AppliedFilter
				];
			}

			return acc;
		}, [] as AppliedFilter[]);
};

export const useAppliedFilters = <T extends Search<T['field']>>(
	search: T,
	translations: TranslationsOmitted<T>
) => {
	const [appliedFilters, setAppliedFilters] = useState(() =>
		getInitialAppliedFilters(search, translations)
	);

	const addAppliedFilter = <K extends keyof T>({
		key,
		value,
		search
	}: OnFilterChangeArgs<T, K>) => {
		setAppliedFilters(prev => [
			{
				label: translateFilter(translations, key, value as ValueFromKey<T[K]>),
				key: key as string,
				value
			},
			...(Array.isArray(search[key]) ? prev : prev.filter(filter => filter.key !== key))
		]);
	};

	const removeAppliedFilter = <K extends keyof T>({ key, value }: OnFilterChangeArgs<T, K>) => {
		setAppliedFilters(prev =>
			prev.filter(filter => !(filter.key === key && filter.value === value))
		);
	};

	const clearAppliedFilter = () => setAppliedFilters([]);

	const isFilterApplied = getIsFilterApplied(appliedFilters, search.searchTerm);

	return {
		appliedFilters,
		isFilterApplied,
		addAppliedFilter,
		removeAppliedFilter,
		clearAppliedFilter
	};
};

const getIsFilterApplied = (appliedFilters: AppliedFilter[], search: string) => {
	return appliedFilters.length > 0 || search !== '';
};
