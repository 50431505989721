import { PropsWithChildren } from 'react';

import { getFieldError, getFieldErrorId, removeAllDots, useAgFormContext } from '@agentero/ui';
import { getInputStatusFromError } from '@agentero/utils';

import { InputPassword } from '../InputPassword';
import { FormGroup, FormGroupGenericProps } from './FormGroup';

type FormGroupInputTextProps<T> = {
	autoFocus?: boolean;
	disabled?: boolean;
} & FormGroupGenericProps<T>;

export const FormGroupInputPassword = <T extends {}>({
	name,
	autoFocus,
	disabled,
	children,
	...formGroupProps
}: PropsWithChildren<FormGroupInputTextProps<T>>) => {
	const {
		register,
		formState: { errors }
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	return (
		<FormGroup name={name} nestedChildren={children} {...formGroupProps}>
			<InputPassword
				id={removeAllDots(name)}
				{...register(name)}
				disabled={disabled}
				status={getInputStatusFromError(fieldError?.message as string)}
				aria-invalid={!!fieldError?.message}
				aria-errormessage={getFieldErrorId(name)}
				autoFocus={autoFocus}
			/>
		</FormGroup>
	);
};
