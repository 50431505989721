import { PropsWithChildren } from 'react';

import styled from '@emotion/styled';

const ModalHeaderContainer = styled.div`
	padding: 24px 32px;
	margin-right: 56px;
`;

const ModalHeaderTitle = styled.div`
	${({ theme }) => theme.textSettings.L};
	${({ theme }) => theme.fontWeight.medium};
`;

interface IModalHeader {
	title?: string;
}

export const ModalHeader = ({ title, children }: PropsWithChildren<IModalHeader>) => (
	<ModalHeaderContainer>
		<ModalHeaderTitle>{title ?? children}</ModalHeaderTitle>
	</ModalHeaderContainer>
);
