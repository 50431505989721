import { useRef } from 'react';

import { NumberFormatOptions } from '@internationalized/number';
import { useNumberFormatter } from '@react-aria/i18n';
import { AriaSliderProps, useSlider } from '@react-aria/slider';
import { useSliderState } from '@react-stately/slider';

import { Box, HStack, Stack, styled } from '@agentero/styles/jsx';
import { Text } from '@agentero/ui';

import { Thumb } from './slider/Thumb';

type RangeSliderProps = {
	formatOptions?: NumberFormatOptions;
} & AriaSliderProps<number[]>;

const RangeSliderTrackContainer = styled('div', {
	base: {
		height: '20'
	},
	variants: {
		disabled: {
			true: {
				opacity: '0.7'
			}
		}
	}
});

const RangeSliderTrack = styled('div', {
	base: {
		position: 'absolute',
		top: '7',
		left: '-10',
		right: '-10',
		height: '6',
		backgroundColor: 'neutral.80',
		borderRadius: 'sm',
		overflow: 'hidden'
	}
});

const RangeSliderFill = styled('div', {
	base: {
		position: 'absolute',
		top: '7',
		height: '6',
		backgroundColor: 'neutral'
	}
});

export const RangeSlider = (props: RangeSliderProps) => {
	const trackRef = useRef(null);

	const numberFormatter = useNumberFormatter(props.formatOptions);
	const state = useSliderState({ ...props, numberFormatter });
	const { groupProps, trackProps, outputProps } = useSlider(props, state, trackRef);

	const rightThumbPercentage = (1 - state.getThumbPercent(1)) * 100 + '%';
	const leftThumbPercentage = state.getThumbPercent(0) * 100 + '%';

	return (
		<Stack gap="4" {...groupProps} className={`slider ${state.orientation}`}>
			<div>
				<output {...outputProps}>
					<HStack justifyContent="space-between">
						<Text size="caption">{state.getThumbValueLabel(0)}</Text>
						<Text size="caption">{state.getThumbValueLabel(1)}</Text>
					</HStack>
				</output>
			</div>

			<Box paddingInline="10" position="relative">
				<RangeSliderTrackContainer {...trackProps} ref={trackRef} disabled={state.isDisabled}>
					<RangeSliderTrack />
					<RangeSliderFill style={{ right: rightThumbPercentage, left: leftThumbPercentage }} />
					<Thumb index={0} state={state} trackRef={trackRef} />
					<Thumb index={1} state={state} trackRef={trackRef} />
				</RangeSliderTrackContainer>
			</Box>
		</Stack>
	);
};
