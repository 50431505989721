export const isEmail = /^\w+([\.-]?\w+)*([+]?\w+)?@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const isPhone = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
export const isValidUrl = (url: string) => {
	const pattern = new RegExp(
		'^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
		'i'
	);
	return !!pattern.test(url);
};
export const isUppercase = /(?=.*?[A-Z])/g;
export const isLowercase = /(?=.*?[a-z])/g;
export const isNumeric = /(?=.*?[0-9])/g;
export const hasOneSymbolOfTheSet = /(?=.*?[.,\-_&%$!\/[\]{\}@#|\\])/g;
export const isMoreThan10Characters = (value: string) => value.length > 10;
export const isPassword =
	/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.,\-_&%$!\/[\]{\}@#|\\]).{11,}$/;

export const isStartWithStreetNumber = /^([a-zA-Z]?[0-9]+([-\/][0-9]+)?[a-zA-Z]?)\s(.*)/;
export const isFEIN = /^\d{9}$/;
export const isZipCode = /^\d{5}(?:[-\s]\d{4})?$/;
export const isUSPhone = /^\+1\d+$/;
