// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file agentero/license/license.proto (package agentero.license, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum agentero.license.LineOfAuthority
 */
export enum LineOfAuthority {
  /**
   * @generated from enum value: LINE_OF_AUTHORITY_UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: LINE_OF_AUTHORITY_LIFE = 1;
   */
  LIFE = 1,

  /**
   * @generated from enum value: LINE_OF_AUTHORITY_PERSONAL = 2;
   */
  PERSONAL = 2,

  /**
   * @generated from enum value: LINE_OF_AUTHORITY_PERSONAL_AUTO = 3;
   */
  PERSONAL_AUTO = 3,

  /**
   * @generated from enum value: LINE_OF_AUTHORITY_PROPERTY_AND_CASUALTY = 4;
   */
  PROPERTY_AND_CASUALTY = 4,

  /**
   * @generated from enum value: LINE_OF_AUTHORITY_HEALTH = 5;
   */
  HEALTH = 5,

  /**
   * @generated from enum value: LINE_OF_AUTHORITY_COMMERCIAL = 6;
   */
  COMMERCIAL = 6,

  /**
   * Limited Commercial is only used in E&O
   *
   * @generated from enum value: LINE_OF_AUTHORITY_LIMITED_COMMERCIAL = 7;
   */
  LIMITED_COMMERCIAL = 7,

  /**
   * @generated from enum value: LINE_OF_AUTHORITY_SURPLUS_LINES = 8;
   */
  SURPLUS_LINES = 8,

  /**
   * @generated from enum value: LINE_OF_AUTHORITY_OTHER = 9;
   */
  OTHER = 9,

  /**
   * @generated from enum value: LINE_OF_AUTHORITY_PRINCIPAL = 10;
   */
  PRINCIPAL = 10,
}
// Retrieve enum metadata with: proto3.getEnumType(LineOfAuthority)
proto3.util.setEnumType(LineOfAuthority, "agentero.license.LineOfAuthority", [
  { no: 0, name: "LINE_OF_AUTHORITY_UNDEFINED" },
  { no: 1, name: "LINE_OF_AUTHORITY_LIFE" },
  { no: 2, name: "LINE_OF_AUTHORITY_PERSONAL" },
  { no: 3, name: "LINE_OF_AUTHORITY_PERSONAL_AUTO" },
  { no: 4, name: "LINE_OF_AUTHORITY_PROPERTY_AND_CASUALTY" },
  { no: 5, name: "LINE_OF_AUTHORITY_HEALTH" },
  { no: 6, name: "LINE_OF_AUTHORITY_COMMERCIAL" },
  { no: 7, name: "LINE_OF_AUTHORITY_LIMITED_COMMERCIAL" },
  { no: 8, name: "LINE_OF_AUTHORITY_SURPLUS_LINES" },
  { no: 9, name: "LINE_OF_AUTHORITY_OTHER" },
  { no: 10, name: "LINE_OF_AUTHORITY_PRINCIPAL" },
]);

/**
 * @generated from enum agentero.license.LicenseStatus
 */
export enum LicenseStatus {
  /**
   * @generated from enum value: LICENSE_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: LICENSE_STATUS_EXPIRED = 1;
   */
  EXPIRED = 1,

  /**
   * @generated from enum value: LICENSE_STATUS_VALID = 2;
   */
  VALID = 2,

  /**
   * @generated from enum value: LICENSE_STATUS_NOT_ACTIVE = 3;
   */
  NOT_ACTIVE = 3,

  /**
   * @generated from enum value: LICENSE_STATUS_LOA_NOT_SUPPORTED = 4;
   */
  LOA_NOT_SUPPORTED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(LicenseStatus)
proto3.util.setEnumType(LicenseStatus, "agentero.license.LicenseStatus", [
  { no: 0, name: "LICENSE_STATUS_UNSPECIFIED" },
  { no: 1, name: "LICENSE_STATUS_EXPIRED" },
  { no: 2, name: "LICENSE_STATUS_VALID" },
  { no: 3, name: "LICENSE_STATUS_NOT_ACTIVE" },
  { no: 4, name: "LICENSE_STATUS_LOA_NOT_SUPPORTED" },
]);

/**
 * LicenseGlobalStatus is the license global status
 *
 * @generated from enum agentero.license.LicenseGlobalStatus
 */
export enum LicenseGlobalStatus {
  /**
   * @generated from enum value: LICENSE_GLOBAL_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: LICENSE_GLOBAL_STATUS_NOT_FOUND = 1;
   */
  NOT_FOUND = 1,

  /**
   * @generated from enum value: LICENSE_GLOBAL_STATUS_VALID = 2;
   */
  VALID = 2,

  /**
   * @generated from enum value: LICENSE_GLOBAL_STATUS_INVALID = 3;
   */
  INVALID = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(LicenseGlobalStatus)
proto3.util.setEnumType(LicenseGlobalStatus, "agentero.license.LicenseGlobalStatus", [
  { no: 0, name: "LICENSE_GLOBAL_STATUS_UNSPECIFIED" },
  { no: 1, name: "LICENSE_GLOBAL_STATUS_NOT_FOUND" },
  { no: 2, name: "LICENSE_GLOBAL_STATUS_VALID" },
  { no: 3, name: "LICENSE_GLOBAL_STATUS_INVALID" },
]);

/**
 * @generated from message agentero.license.LicenseLOA
 */
export class LicenseLOA extends Message<LicenseLOA> {
  /**
   * @generated from field: agentero.license.LineOfAuthority LOA = 1;
   */
  LOA = LineOfAuthority.UNDEFINED;

  /**
   * @generated from field: google.protobuf.Timestamp issue_date = 2;
   */
  issueDate?: Timestamp;

  constructor(data?: PartialMessage<LicenseLOA>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.license.LicenseLOA";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "LOA", kind: "enum", T: proto3.getEnumType(LineOfAuthority) },
    { no: 2, name: "issue_date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LicenseLOA {
    return new LicenseLOA().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LicenseLOA {
    return new LicenseLOA().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LicenseLOA {
    return new LicenseLOA().fromJsonString(jsonString, options);
  }

  static equals(a: LicenseLOA | PlainMessage<LicenseLOA> | undefined, b: LicenseLOA | PlainMessage<LicenseLOA> | undefined): boolean {
    return proto3.util.equals(LicenseLOA, a, b);
  }
}

/**
 * @generated from message agentero.license.License
 */
export class License extends Message<License> {
  /**
   * @generated from field: string license_number = 1;
   */
  licenseNumber = "";

  /**
   * @generated from field: string license_state = 2;
   */
  licenseState = "";

  /**
   * @generated from field: google.protobuf.Timestamp expiration_date = 3;
   */
  expirationDate?: Timestamp;

  /**
   * @generated from field: string residency_status = 4;
   */
  residencyStatus = "";

  /**
   * @generated from field: bool active = 5;
   */
  active = false;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 6;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: agentero.license.LicenseStatus status = 7;
   */
  status = LicenseStatus.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp issue_date = 8;
   */
  issueDate?: Timestamp;

  /**
   * @generated from field: repeated agentero.license.LicenseLOA license_loas = 9;
   */
  licenseLoas: LicenseLOA[] = [];

  constructor(data?: PartialMessage<License>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.license.License";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "license_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "license_state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "expiration_date", kind: "message", T: Timestamp },
    { no: 4, name: "residency_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "updated_at", kind: "message", T: Timestamp },
    { no: 7, name: "status", kind: "enum", T: proto3.getEnumType(LicenseStatus) },
    { no: 8, name: "issue_date", kind: "message", T: Timestamp },
    { no: 9, name: "license_loas", kind: "message", T: LicenseLOA, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): License {
    return new License().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): License {
    return new License().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): License {
    return new License().fromJsonString(jsonString, options);
  }

  static equals(a: License | PlainMessage<License> | undefined, b: License | PlainMessage<License> | undefined): boolean {
    return proto3.util.equals(License, a, b);
  }
}

