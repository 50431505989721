import { ChangeEventHandler } from 'react';

import { Input } from '../../../form/fields/fieldText/Input';
import { BaseSearch } from '../../DataTableFilters';

export type FilterText<Search extends BaseSearch, Key extends keyof Search = keyof Search> = {
	key: Key;
	type: 'text';
	placeholder?: string;
};

type TextFilterProps<Search extends BaseSearch> = {
	filter: FilterText<Search>;
	search: Search;
	onSearchChange: (search: Search) => void;
};

export const TextFilter = <Search extends BaseSearch>({
	filter,
	search,
	onSearchChange
}: TextFilterProps<Search>) => {
	const { placeholder } = filter;
	const defaultValue = search[filter.key] as string;

	const onTextChange: ChangeEventHandler<HTMLInputElement> = event => {
		const { value } = event.target;

		const newSearch = { ...search, [filter.key]: value };
		onSearchChange(newSearch);
	};

	return (
		<Input
			size="md"
			type="search"
			placeholder={placeholder ?? 'Search'}
			onChange={onTextChange}
			defaultValue={defaultValue}
		/>
	);
};
