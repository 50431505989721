import { CurrentTier, Campaign as ProtoCampaign } from '@agentero/grpc-clients/grpc/shared/tier';
import {
	Campaign as CampaignConnect,
	CurrentTier_CampaignInfo
} from '@agentero/service-clients/portal/shared/tier';

export enum Campaign {
	Unspecified = 'campaign-unspecified',
	TwoPolicies = 'campaign-two-policies',
	GracePeriod = 'campaign-two-months-grace'
}

export const isIncludedInTwoPoliciesMonths = (campaigns: Campaign[]): boolean =>
	campaigns.includes(Campaign.TwoPolicies);

const map = {
	[ProtoCampaign.CAMPAIGN_UNSPECIFIED]: Campaign.Unspecified,
	[ProtoCampaign.CAMPAIGN_TWO_POLICIES_MONTH]: Campaign.TwoPolicies,
	[ProtoCampaign.CAMPAIGN_TWO_MONTHS_GRACE]: Campaign.GracePeriod
};

export const parseCampaignEnum = (
	value?: CurrentTier.CampaignInfo.AsObject
): Campaign | undefined => {
	return value ? map[value.name] : undefined;
};

export const parseCampaignProtoEnum = (value: ProtoCampaign): Campaign => map[value];

const mapConnect = {
	[CampaignConnect.UNSPECIFIED]: Campaign.Unspecified,
	[CampaignConnect.TWO_POLICIES_MONTH]: Campaign.TwoPolicies,
	[CampaignConnect.TWO_MONTHS_GRACE]: Campaign.GracePeriod
};

export const parseCampaignEnumConnect = (
	// eslint-disable-next-line camelcase
	value: CurrentTier_CampaignInfo
): Campaign => mapConnect[value.name];
