import styled from '@emotion/styled';

export const FormSection = styled.fieldset`
	width: 100%;
	margin-bottom: 16px;

	&:last-of-type {
		margin-bottom: 0;
	}
`;

export const FormData = styled.div`
	margin-bottom: -16px;
`;

export const FormActions = styled.div<{ justifyContent?: string }>`
	display: flex;
	flex-direction: column;

	${({ theme }) => theme.mediaqueries.S} {
		flex-direction: row;
		justify-content: ${({ justifyContent = 'flex-end' }) => justifyContent};
	}

	> *:last-of-type:not(div) {
		display: flex;
		width: 100%;
		text-align: center;
		order: 1;
		margin-bottom: 8px;

		${({ theme }) => theme.mediaqueries.S} {
			display: inline-flex;
			width: auto;
			order: 2;
			margin-left: 16px;
			margin-bottom: 0;
		}
	}

	> *:first-of-type:not(div) {
		display: block;
		width: 100%;
		text-align: center;
		order: 2;

		${({ theme }) => theme.mediaqueries.S} {
			display: inline-flex;
			width: auto;
			order: 1;
		}
	}
`;

export const FormDivider = styled.hr`
	height: 1px;
	border: 0;
	margin: -8px 0 16px;
	background: ${({ theme }) => theme.colors.gray.lighten80};
`;
