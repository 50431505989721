import { HTMLInputTypeAttribute, PropsWithChildren } from 'react';

import { Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormError, FormGroupInputInteger, Grid, GridItem } from '@agentero/components';
import { YearMonthState } from '@agentero/models/quote';
import { getFieldError, useAgFormContext } from '@agentero/ui';
import { formatList } from '@agentero/utils';

import { FormGroup, FormGroupGenericProps } from './FormGroup';

const YEAR_MONTH_INPUT_MONTH_PROP = 'month';
const YEAR_MONTH_INPUT_YEAR_PROP = 'year';

type FormGroupInputTextProps<T> = {
	yearName: Path<T>;
	monthName: Path<T>;
	type?: HTMLInputTypeAttribute;
	placeholder?: string;
	minValue?: number;
	maxValue?: number;
	autoFocus?: boolean;
} & FormGroupGenericProps<T>;

const setValueAs = (value: string) => (value === '' ? undefined : value);

export const FormGroupInputYearMonth = <T extends {}>({
	name,
	yearName,
	monthName,
	children,
	disabled,
	...formGroupProps
}: PropsWithChildren<FormGroupInputTextProps<T>>) => {
	const {
		formState: { errors }
	} = useAgFormContext<T>();
	const { t } = useTranslation('rater');

	return (
		<FormGroup
			asFieldset
			name={name}
			nestedChildren={children}
			getReadonlyValue={value => {
				const inputValue = value as YearMonthState;

				if (
					!inputValue ||
					(!inputValue[YEAR_MONTH_INPUT_MONTH_PROP] && !inputValue[YEAR_MONTH_INPUT_YEAR_PROP])
				) {
					return '—';
				}

				return inputValue
					? formatList([
							t('yearsTotal', { count: inputValue?.year }),
							t('monthsTotal', { count: inputValue?.month })
					  ])
					: '';
			}}
			{...formGroupProps}>
			<Grid spacing={8} mb={-16}>
				<GridItem md={3}>
					<FormGroupInputInteger
						label={t('years')}
						name={yearName}
						registerOptions={{
							setValueAs,
							deps: [monthName]
						}}
						maxValue={99}
						minValue={0}
						displayErrorMessage={false}
						disabled={disabled}
					/>
				</GridItem>
				<GridItem md={3}>
					<FormGroupInputInteger
						label={t('months')}
						name={monthName}
						registerOptions={{
							setValueAs,
							deps: [yearName]
						}}
						maxValue={11}
						minValue={0}
						displayErrorMessage={false}
						disabled={disabled}
					/>
				</GridItem>
			</Grid>
			<FormError
				id="yearMonthErr"
				error={
					getFieldError(errors, yearName)?.message || getFieldError(errors, monthName)?.message
				}
			/>
		</FormGroup>
	);
};
