import React from 'react';

import { Toaster } from 'sonner';

import { IconCheckCircle, IconErrorOutline, IconInfoOutline, IconWarning } from '@agentero/icons';
import { css } from '@agentero/styles/css';

type ToasterProps = Omit<Parameters<typeof Toaster>[0], 'toastOptions' | 'icons'> &
	React.HTMLAttributes<HTMLDivElement>;

export const Toast = ({ position = 'top-right', duration = 5000, ...props }: ToasterProps) => (
	<Toaster
		{...props}
		position={position}
		duration={duration}
		toastOptions={{
			className: css({
				borderColor: 'token(colors.border.default.base.primary) !important',
				boxShadow: 'token(shadows.lg) !important',
				borderRadius: 'token(radii.sm) !important',
				color: 'token(neutral) !important',
				gap: 'token(spacing.8) !important'
			})
		}}
		icons={{
			success: <IconCheckCircle className={css({ '& path': { fill: 'success.40' } })} />,
			info: <IconInfoOutline className={css({ '& path': { fill: 'blue.40' } })} />,
			warning: <IconWarning className={css({ '& path': { fill: 'warning.40' } })} />,
			error: <IconErrorOutline className={css({ '& path': { fill: 'danger.40' } })} />
		}}
	/>
);
