type FormatPremiumArgs = {
	premium: number;
	fractionDigits?: number;
	forceFractionDigits?: boolean;
	language?: string;
	hasCurrency?: boolean;
};

export const formatPremium = ({
	premium,
	fractionDigits = 2,
	forceFractionDigits = false,
	language = 'en-US',
	hasCurrency = false
}: FormatPremiumArgs): string => {
	const decimalsAmount = premium % 1 === 0 && !forceFractionDigits ? 0 : fractionDigits;
	const currencyProps = hasCurrency
		? ({ style: 'currency', currency: 'USD' } as {
				style?: keyof Intl.NumberFormatOptionsStyleRegistry;
				currency?: string;
		  })
		: {};

	return new Intl.NumberFormat(language, {
		...currencyProps,
		minimumFractionDigits: decimalsAmount,
		maximumFractionDigits: decimalsAmount
	}).format(premium);
};
