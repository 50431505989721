import { Keyframes, keyframes } from '@emotion/react';

import { getTextSizeAndLineHeight } from '../utils';

interface ITextSettings {
	[key: string]: string;
	XXL: string;
	XL: string;
	L: string;
	M: string;
	default: string;
	S: string;
	XS: string;
	XXS: string;
	inherit: string;
}

interface IFontWeight {
	[key: string]: string;
	regular: string;
	medium: string;
}

export interface ITheme {
	name: string;
	colors: {
		gray: {
			base: string;
			lighten95: string;
			lighten95Alpha: string;
			lighten90: string;
			lighten90Alpha: string;
			lighten80: string;
			lighten80Alpha: string;
			lighten40: string;
			lighten40Alpha: string;
			lighten20: string;
		};
		primary: {
			base: string;
			lighten80: string;
			lighten50: string;
			darken10: string;
			darken30: string;
			darken50: string;
		};
		white: string;
		status: {
			success: {
				base: string;
				lighten80: string;
				lighten50: string;
				darken10: string;
				darken30: string;
				darken50: string;
			};
			error: {
				base: string;
				lighten80: string;
				lighten40: string;
				darken30: string;
				darken50: string;
			};
			info: {
				base: string;
				lighten80: string;
				lighten40: string;
				darken30: string;
				darken50: string;
			};
			warning: {
				base: string;
				lighten80: string;
				lighten40: string;
				darken30: string;
				darken50: string;
			};
		};
		violet: {
			base: string;
			lighten80: string;
			lighten40: string;
			darken30: string;
			darken50: string;
		};
		pink: {
			base: string;
			lighten80: string;
			lighten40: string;
			darken30: string;
			darken50: string;
		};
		orange: {
			base: string;
			lighten80: string;
			darken30: string;
		};
		yellow: {
			base: string;
			lighten80: string;
			darken30: string;
		};
	};
	shadows: {
		soft: string;
		medium: string;
		hard: string;
	};
	textSettings: ITextSettings;
	fontWeight: IFontWeight;
	mediaqueries: {
		S: string;
		M: string;
		L: string;
		XL: string;
		XXL: string;
		XXXL: string;
	};
	overlay: {
		main: string;
		light: string;
	};
	gradients: {
		main: string;
	};
	sizes: {
		base: string;
		getSize: (size: number) => string;
		sidebar: string;
	};
	animations: {
		scale: Keyframes;
	};
	easings: {
		easeInOutQuint: string;
		easeOutBack: string;
	};
}

export const theme: ITheme = {
	name: 'AgenteroTheme',
	colors: {
		gray: {
			base: 'var(--color-gray-base)',
			lighten95: 'var(--color-gray-lighten-95)',
			lighten95Alpha: 'var(--color-gray-lighten-95-alpha)',
			lighten90: 'var(--color-gray-lighten-90)',
			lighten90Alpha: 'var(--color-gray-lighten-90-alpha)',
			lighten80: 'var(--color-gray-lighten-80)',
			lighten80Alpha: 'var(--color-gray-lighten-80-alpha)',
			lighten40: 'var(--color-gray-lighten-40)',
			lighten40Alpha: 'var(--color-gray-lighten-40-alpha)',
			lighten20: 'var(--color-gray-lighten-20)'
		},
		primary: {
			base: 'var(--color-primary-base)',
			lighten80: 'var(--color-primary-lighten-80)',
			lighten50: 'var(--color-primary-lighten-50)',
			darken10: 'var(--color-primary-darken-10)',
			darken30: 'var(--color-primary-darken-30)',
			darken50: 'var(--color-primary-darken-50)'
		},
		white: '#FFFFFF',
		status: {
			success: {
				base: 'var(--color-primary-base)',
				lighten80: 'var(--color-primary-lighten-80)',
				lighten50: 'var(--color-primary-lighten-50)',
				darken10: 'var(--color-primary-darken-10)',
				darken30: 'var(--color-primary-darken-30)',
				darken50: 'var(--color-primary-darken-50)'
			},
			error: {
				base: 'var(--color-red-base)',
				lighten80: 'var(--color-red-lighten-80)',
				lighten40: 'var(--color-red-lighten-40)',
				darken30: 'var(--color-red-darken-30)',
				darken50: 'var(--color-red-darken-50)'
			},
			info: {
				base: 'var(--color-blue-base)',
				lighten80: 'var(--color-blue-lighten-80)',
				lighten40: 'var(--color-blue-lighten-40)',
				darken30: 'var(--color-blue-darken-30)',
				darken50: 'var(--color-blue-darken-50)'
			},
			warning: {
				base: 'var(--color-orange-base)',
				lighten80: 'var(--color-orange-lighten-80)',
				lighten40: 'var(--color-orange-lighten-40)',
				darken30: 'var(--color-orange-darken-30)',
				darken50: 'var(--color-orange-darken-50)'
			}
		},
		violet: {
			base: 'var(--color-violet-base)',
			lighten80: 'var(--color-violet-lighten-80)',
			lighten40: 'var(--color-violet-lighten-40)',
			darken30: 'var(--color-violet-darken-30)',
			darken50: 'var(--color-violet-darken-50)'
		},
		pink: {
			base: 'var(--color-pink-base)',
			lighten80: 'var(--color-pink-lighten-80)',
			lighten40: 'var(--color-pink-lighten-40)',
			darken30: 'var(--color-pink-darken-30)',
			darken50: 'var(--color-pink-darken-50)'
		},
		orange: {
			base: 'var(--color-oranged-base)',
			lighten80: 'var(--color-oranged-lighten-80)',
			darken30: 'var(--color-oranged-darken-30)'
		},
		yellow: {
			base: 'var(--color-yellow-base)',
			lighten80: 'var(--color-yellow-lighten-80)',
			darken30: 'var(--color-yellow-darken-30)'
		}
	},
	shadows: {
		soft: '0 2px 4px var(--color-gray-lighten-80)',
		medium: '0 8px 16px rgba(40, 43, 51, 0.16)',
		hard: '0 8px 20px rgba(40, 43, 51, 0.32)'
	},
	textSettings: {
		XXXL: getTextSizeAndLineHeight(36, 40),
		XXL: getTextSizeAndLineHeight(32, 36),
		XL: getTextSizeAndLineHeight(24, 32),
		L: getTextSizeAndLineHeight(20, 32),
		M: getTextSizeAndLineHeight(18, 28),
		default: getTextSizeAndLineHeight(16, 24),
		S: getTextSizeAndLineHeight(14, 24),
		XS: getTextSizeAndLineHeight(12, 16),
		XXS: getTextSizeAndLineHeight(10, 16),
		inherit: 'font-size: inherit; line-height: inherit;'
	},
	fontWeight: {
		regular: 'font-family: var(--font-family); font-weight: 400;',
		medium: 'font-weight: 600;'
	},
	mediaqueries: {
		S: '@media (min-width: 480px)',
		M: '@media (min-width: 768px)',
		L: '@media (min-width: 1024px)',
		XL: '@media (min-width: 1280px)',
		XXL: '@media (min-width: 1366px)',
		XXXL: '@media (min-width: 1600px)'
	},
	overlay: {
		main: 'background: rgba(40, 43, 51, 0.8);',
		light: 'background: rgba(255, 255, 255, 0.7);'
	},
	gradients: {
		main: 'linear-gradient(107.38deg, #165e48 15.46%, #28a981 82.04%)'
	},
	sizes: {
		base: 'var(--size-unit)',
		getSize: (size: number) => `calc(${size} * var(--size-unit))`,
		sidebar: 'var(--size-sidebar-width)'
	},
	animations: {
		scale: keyframes`
			0% { transform: scale(0); }
			100% { transform: scale(1); }
		`
	},
	easings: {
		easeInOutQuint: 'var(--easing-ease-in-out-quint)',
		easeOutBack: 'var(--easing-ease-out-back)'
	}
};
