import { PropsWithChildren } from 'react';

import { Controller } from 'react-hook-form';

import { FileUpload } from '@agentero/models/shared';
import { getFieldError, getFieldErrorId, removeAllDots, useAgFormContext } from '@agentero/ui';
import { getInputStatusFromError } from '@agentero/utils';

import { FileDrop } from '../FileDrop';
import { FormGroup, FormGroupGenericProps } from './FormGroup';

type FormGroupFileDropProps<T> = {
	accept: string;
	availableFilesText: string;
	availableFileTypesError: string;
	height?: number;
} & FormGroupGenericProps<T>;

export const FormGroupFileDrop = <T extends {}>({
	children,
	name,
	accept,
	availableFilesText,
	availableFileTypesError,
	height,
	...formGroupProps
}: PropsWithChildren<FormGroupFileDropProps<T>>) => {
	const {
		control,
		formState: { errors },
		setError
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	const onError = () =>
		setError(name, { type: 'invalid-file-type', message: availableFileTypesError });

	return (
		<FormGroup name={name} nestedChildren={children} {...formGroupProps}>
			<Controller
				name={name}
				control={control}
				render={({ field: { onChange, value, onBlur } }) => (
					<FileDrop
						onError={onError}
						onValueChange={(value: FileUpload) => onChange(value)}
						accept={accept}
						availableFilesText={availableFilesText}
						availableFileTypesError={availableFileTypesError}
						id={removeAllDots(name)}
						status={getInputStatusFromError(fieldError?.message as string)}
						aria-invalid={!!fieldError?.message}
						aria-errormessage={getFieldErrorId(name)}
						valueFile={value}
						height={height}
						onBlur={onBlur}
						previewMode={formGroupProps.disabled}
					/>
				)}
			/>
		</FormGroup>
	);
};
