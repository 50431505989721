import { ComparativeRaterType as ProtoComparativeRater } from '@agentero/grpc-clients/grpc/shared/carrier';

export enum ComparativeRater {
	None = 'none',
	Ezlynz = 'ezlynz',
	PlRater = 'pl_rater',
	ItcTurborater = 'itc_turborater',
	Fsc = 'fsc',
	Bolt = 'bolt',
	Qrp = 'qrp',
	Other = 'other'
}

export const parseComparativeRater = (comparativeRater: ProtoComparativeRater): ComparativeRater =>
	comparativeRaterFromProtoMapping[comparativeRater];

const comparativeRaterFromProtoMapping: { [key in ProtoComparativeRater]: ComparativeRater } = {
	[ProtoComparativeRater.COMPARATIVE_RATER_UNDEFINED]: ComparativeRater.None,
	[ProtoComparativeRater.EZLYNX]: ComparativeRater.Ezlynz,
	[ProtoComparativeRater.PL_RATER]: ComparativeRater.PlRater,
	[ProtoComparativeRater.ITC_TURBORATER]: ComparativeRater.ItcTurborater,
	[ProtoComparativeRater.FSC]: ComparativeRater.Fsc,
	[ProtoComparativeRater.BOLT]: ComparativeRater.Bolt,
	[ProtoComparativeRater.QRP]: ComparativeRater.Qrp,
	[ProtoComparativeRater.OTHER]: ComparativeRater.Other
};

export const parseComparativeRaterToProto = (
	comparativeRater: ComparativeRater
): ProtoComparativeRater => comparativeRaterToProtoMapping[comparativeRater];

const comparativeRaterToProtoMapping: { [key in ComparativeRater]: ProtoComparativeRater } = {
	[ComparativeRater.None]: ProtoComparativeRater.COMPARATIVE_RATER_UNDEFINED,
	[ComparativeRater.Ezlynz]: ProtoComparativeRater.EZLYNX,
	[ComparativeRater.PlRater]: ProtoComparativeRater.PL_RATER,
	[ComparativeRater.ItcTurborater]: ProtoComparativeRater.ITC_TURBORATER,
	[ComparativeRater.Fsc]: ProtoComparativeRater.FSC,
	[ComparativeRater.Bolt]: ProtoComparativeRater.BOLT,
	[ComparativeRater.Qrp]: ProtoComparativeRater.QRP,
	[ComparativeRater.Other]: ProtoComparativeRater.OTHER
};
