import styled from '@emotion/styled';

export const Wrapper = styled.div`
	width: 100%;
	margin-bottom: 16px;

	&:last-of-type {
		margin-bottom: 0;
	}
`;
