import { License } from '@agentero/grpc-clients/grpc/shared/license';

import { State } from '../../shared';
import {
	ComplianceStatesLicensesStatus,
	parseComplianceStatesLicensesStatus
} from './shared/ComplianceStatesLicensesStatus';

export type ComplianceStatesByStatus = {
	status: ComplianceStatesLicensesStatus;
	states: State[];
};

export const parseComplianceStatesByStatus = (
	licenses: License.AsObject[]
): ComplianceStatesByStatus[] =>
	Object.keys(ComplianceStatesLicensesStatus)
		.map(statusKey => {
			const status = statusKey as keyof typeof ComplianceStatesLicensesStatus;
			const license = licenses.filter(
				license =>
					parseComplianceStatesLicensesStatus(license.status) ===
					ComplianceStatesLicensesStatus[status]
			);

			return {
				status: ComplianceStatesLicensesStatus[status],
				states: license.map(({ licenseState }) => licenseState as State)
			};
		})
		.filter(({ states }) => states.length > 0);
