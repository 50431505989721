import { PropsWithChildren } from 'react';

import { Controller, Path, get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getFieldErrorId, useAgFormContext } from '@agentero/ui';
import { booleanToTextTranslation, getInputStatusFromError } from '@agentero/utils';

import { InputRadioButtonBooleanGroup } from '../../InputRadioButtonGroup';
import { Space } from '../../Space';
import { FormGroup, FormGroupGenericProps } from './FormGroup';

type FormGroupInputRadioButtonProps<T> = {
	label: string;
	name: Path<T>;
	optional?: boolean;
	nested?: boolean;
	onGroupChange?: (value: string | boolean) => void;
} & FormGroupGenericProps<T>;

export const FormGroupInputRadioButtonBooleanGroup = <T extends {}>({
	name,
	onGroupChange,
	children,
	...formGroupProps
}: PropsWithChildren<FormGroupInputRadioButtonProps<T>>) => {
	const { t } = useTranslation(['shared']);

	const {
		control,
		formState: { errors }
	} = useAgFormContext<T>();

	return (
		<FormGroup
			name={name}
			nestedChildren={children}
			asFieldset={true}
			getReadonlyValue={inputValue =>
				t(booleanToTextTranslation(inputValue as boolean | undefined))
			}
			{...formGroupProps}>
			<Space spacing={0} css={{ margin: 1 }}>
				<Controller
					control={control}
					name={name}
					render={({ field: { onChange, value, onBlur } }) => (
						<InputRadioButtonBooleanGroup
							inputProps={{ 'aria-errormessage': getFieldErrorId(name) }}
							status={getInputStatusFromError(get(errors, name)?.message as string)}
							value={value}
							onBlur={onBlur}
							onChange={onChange}
							onGroupChange={onGroupChange}
						/>
					)}
				/>
			</Space>
		</FormGroup>
	);
};
