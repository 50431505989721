// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file agentero/policy/policy.proto (package agentero.policy, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum agentero.policy.InsuranceType
 */
export enum InsuranceType {
  /**
   * @generated from enum value: UNDEFINED_INSURANCE_TYPE = 0;
   */
  UNDEFINED_INSURANCE_TYPE = 0,

  /**
   * @generated from enum value: PERSONAL_AUTO = 1;
   */
  PERSONAL_AUTO = 1,

  /**
   * @generated from enum value: PERSONAL_UMBRELLA = 2;
   */
  PERSONAL_UMBRELLA = 2,

  /**
   * @generated from enum value: HOMEOWNERS = 3;
   */
  HOMEOWNERS = 3,

  /**
   * @generated from enum value: RENTERS = 4;
   */
  RENTERS = 4,

  /**
   * @generated from enum value: CONDO = 5;
   */
  CONDO = 5,

  /**
   * @generated from enum value: DWELLING_FIRE = 6;
   */
  DWELLING_FIRE = 6,

  /**
   * @generated from enum value: LANDLORD = 7;
   */
  LANDLORD = 7,

  /**
   * @generated from enum value: MOTORCYCLE = 9;
   */
  MOTORCYCLE = 9,

  /**
   * @generated from enum value: FLOOD = 10;
   */
  FLOOD = 10,

  /**
   * @generated from enum value: ATV_OFF_ROAD_VEHICLE = 11;
   */
  ATV_OFF_ROAD_VEHICLE = 11,

  /**
   * Deprecated in favor of MANUFACTURED_HOME
   *
   * @generated from enum value: MOBILE_HOMEOWNERS = 25 [deprecated = true];
   * @deprecated
   */
  MOBILE_HOMEOWNERS = 25,

  /**
   * @generated from enum value: LIFE = 26;
   */
  LIFE = 26,

  /**
   * @generated from enum value: OTHER = 30;
   */
  OTHER = 30,

  /**
   * @generated from enum value: COMMERCIAL_AUTO = 31;
   */
  COMMERCIAL_AUTO = 31,

  /**
   * @generated from enum value: COMMERCIAL = 32;
   */
  COMMERCIAL = 32,

  /**
   * @generated from enum value: INLAND_MARINE = 33;
   */
  INLAND_MARINE = 33,

  /**
   * @generated from enum value: PROFESSIONAL_LIABILITY = 35;
   */
  PROFESSIONAL_LIABILITY = 35,

  /**
   * @generated from enum value: GENERAL_LIABILITY = 36;
   */
  GENERAL_LIABILITY = 36,

  /**
   * @generated from enum value: BUSINESS_OWNER_POLICY = 37;
   */
  BUSINESS_OWNER_POLICY = 37,

  /**
   * @generated from enum value: WORKERS_COMPENSATION = 38;
   */
  WORKERS_COMPENSATION = 38,

  /**
   * @generated from enum value: COMMERCIAL_PROPERTY = 39;
   */
  COMMERCIAL_PROPERTY = 39,

  /**
   * @generated from enum value: COMMERCIAL_UMBRELLA = 40;
   */
  COMMERCIAL_UMBRELLA = 40,

  /**
   * @generated from enum value: DIRECTORS_AND_OFFICERS = 41;
   */
  DIRECTORS_AND_OFFICERS = 41,

  /**
   * @generated from enum value: ERRORS_AND_OMISSIONS = 43;
   */
  ERRORS_AND_OMISSIONS = 43,

  /**
   * @generated from enum value: EXCESS_LIABILITY = 46;
   */
  EXCESS_LIABILITY = 46,

  /**
   * @generated from enum value: CYBER_LIABILITY = 55;
   */
  CYBER_LIABILITY = 55,

  /**
   * @generated from enum value: RENT_GUARANTEE = 70;
   */
  RENT_GUARANTEE = 70,

  /**
   * @generated from enum value: EXCESS_AND_SURPLUS_COMMERCIAL_CASUALTY = 74;
   */
  EXCESS_AND_SURPLUS_COMMERCIAL_CASUALTY = 74,

  /**
   * @generated from enum value: SPECIAL_EVENTS = 83;
   */
  SPECIAL_EVENTS = 83,

  /**
   * @generated from enum value: SPECIALTY_COMMERCIAL = 84;
   */
  SPECIALTY_COMMERCIAL = 84,

  /**
   * @generated from enum value: COMMERCIAL_FLOOD = 86;
   */
  COMMERCIAL_FLOOD = 86,

  /**
   * @generated from enum value: PARAMETRIC_EARTHQUAKE = 87;
   */
  PARAMETRIC_EARTHQUAKE = 87,

  /**
   * @generated from enum value: HOME_AND_ELECTRONICS_PROTECTION = 88;
   */
  HOME_AND_ELECTRONICS_PROTECTION = 88,

  /**
   * @generated from enum value: RECREATIONAL_VEHICLES = 89;
   */
  RECREATIONAL_VEHICLES = 89,
}
// Retrieve enum metadata with: proto3.getEnumType(InsuranceType)
proto3.util.setEnumType(InsuranceType, "agentero.policy.InsuranceType", [
  { no: 0, name: "UNDEFINED_INSURANCE_TYPE" },
  { no: 1, name: "PERSONAL_AUTO" },
  { no: 2, name: "PERSONAL_UMBRELLA" },
  { no: 3, name: "HOMEOWNERS" },
  { no: 4, name: "RENTERS" },
  { no: 5, name: "CONDO" },
  { no: 6, name: "DWELLING_FIRE" },
  { no: 7, name: "LANDLORD" },
  { no: 9, name: "MOTORCYCLE" },
  { no: 10, name: "FLOOD" },
  { no: 11, name: "ATV_OFF_ROAD_VEHICLE" },
  { no: 25, name: "MOBILE_HOMEOWNERS" },
  { no: 26, name: "LIFE" },
  { no: 30, name: "OTHER" },
  { no: 31, name: "COMMERCIAL_AUTO" },
  { no: 32, name: "COMMERCIAL" },
  { no: 33, name: "INLAND_MARINE" },
  { no: 35, name: "PROFESSIONAL_LIABILITY" },
  { no: 36, name: "GENERAL_LIABILITY" },
  { no: 37, name: "BUSINESS_OWNER_POLICY" },
  { no: 38, name: "WORKERS_COMPENSATION" },
  { no: 39, name: "COMMERCIAL_PROPERTY" },
  { no: 40, name: "COMMERCIAL_UMBRELLA" },
  { no: 41, name: "DIRECTORS_AND_OFFICERS" },
  { no: 43, name: "ERRORS_AND_OMISSIONS" },
  { no: 46, name: "EXCESS_LIABILITY" },
  { no: 55, name: "CYBER_LIABILITY" },
  { no: 70, name: "RENT_GUARANTEE" },
  { no: 74, name: "EXCESS_AND_SURPLUS_COMMERCIAL_CASUALTY" },
  { no: 83, name: "SPECIAL_EVENTS" },
  { no: 84, name: "SPECIALTY_COMMERCIAL" },
  { no: 86, name: "COMMERCIAL_FLOOD" },
  { no: 87, name: "PARAMETRIC_EARTHQUAKE" },
  { no: 88, name: "HOME_AND_ELECTRONICS_PROTECTION" },
  { no: 89, name: "RECREATIONAL_VEHICLES" },
]);

/**
 * @generated from enum agentero.policy.LineOfInsurance
 */
export enum LineOfInsurance {
  /**
   * @generated from enum value: LINE_OF_INSURANCE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: LINE_OF_INSURANCE_PERSONAL = 1;
   */
  PERSONAL = 1,

  /**
   * @generated from enum value: LINE_OF_INSURANCE_COMMERCIAL = 2;
   */
  COMMERCIAL = 2,

  /**
   * @generated from enum value: LINE_OF_INSURANCE_LIFE = 3;
   */
  LIFE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(LineOfInsurance)
proto3.util.setEnumType(LineOfInsurance, "agentero.policy.LineOfInsurance", [
  { no: 0, name: "LINE_OF_INSURANCE_UNSPECIFIED" },
  { no: 1, name: "LINE_OF_INSURANCE_PERSONAL" },
  { no: 2, name: "LINE_OF_INSURANCE_COMMERCIAL" },
  { no: 3, name: "LINE_OF_INSURANCE_LIFE" },
]);

/**
 * @generated from enum agentero.policy.PremiumTerm
 */
export enum PremiumTerm {
  /**
   * @generated from enum value: ANNUAL = 0;
   */
  ANNUAL = 0,

  /**
   * @generated from enum value: SIX_MONTHS = 1;
   */
  SIX_MONTHS = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(PremiumTerm)
proto3.util.setEnumType(PremiumTerm, "agentero.policy.PremiumTerm", [
  { no: 0, name: "ANNUAL" },
  { no: 1, name: "SIX_MONTHS" },
]);

/**
 * PolicyComponent is a representation of the coverages and
 * deductibles of a policy.
 *
 * @generated from message agentero.policy.PolicyComponent
 */
export class PolicyComponent extends Message<PolicyComponent> {
  /**
   * A unique identifier of the type of component. It is an open
   * representation because each Carrier most likely is going to have
   * its own unique codes.
   *
   * @generated from field: string code = 1;
   */
  code = "";

  /**
   * A text description of the component.
   *
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * The value(s) of the component, if any.
   *
   * @generated from field: repeated string values = 3;
   */
  values: string[] = [];

  /**
   * The type of value.
   *
   * @generated from field: agentero.policy.PolicyComponent.ValueType value_type = 4;
   */
  valueType = PolicyComponent_ValueType.UNSPECIFIED;

  constructor(data?: PartialMessage<PolicyComponent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.policy.PolicyComponent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "value_type", kind: "enum", T: proto3.getEnumType(PolicyComponent_ValueType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PolicyComponent {
    return new PolicyComponent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PolicyComponent {
    return new PolicyComponent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PolicyComponent {
    return new PolicyComponent().fromJsonString(jsonString, options);
  }

  static equals(a: PolicyComponent | PlainMessage<PolicyComponent> | undefined, b: PolicyComponent | PlainMessage<PolicyComponent> | undefined): boolean {
    return proto3.util.equals(PolicyComponent, a, b);
  }
}

/**
 * @generated from enum agentero.policy.PolicyComponent.ValueType
 */
export enum PolicyComponent_ValueType {
  /**
   * @generated from enum value: VALUE_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: VALUE_TYPE_AMOUNT = 1;
   */
  AMOUNT = 1,

  /**
   * @generated from enum value: VALUE_TYPE_PERCENTAGE = 2;
   */
  PERCENTAGE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(PolicyComponent_ValueType)
proto3.util.setEnumType(PolicyComponent_ValueType, "agentero.policy.PolicyComponent.ValueType", [
  { no: 0, name: "VALUE_TYPE_UNSPECIFIED" },
  { no: 1, name: "VALUE_TYPE_AMOUNT" },
  { no: 2, name: "VALUE_TYPE_PERCENTAGE" },
]);

/**
 * @generated from message agentero.policy.QuotePremium
 */
export class QuotePremium extends Message<QuotePremium> {
  /**
   * @generated from field: double amount = 1;
   */
  amount = 0;

  /**
   * @generated from field: agentero.policy.PremiumTerm term = 2;
   */
  term = PremiumTerm.ANNUAL;

  /**
   * @generated from field: double fees = 3;
   */
  fees = 0;

  constructor(data?: PartialMessage<QuotePremium>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.policy.QuotePremium";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "term", kind: "enum", T: proto3.getEnumType(PremiumTerm) },
    { no: 3, name: "fees", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuotePremium {
    return new QuotePremium().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuotePremium {
    return new QuotePremium().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuotePremium {
    return new QuotePremium().fromJsonString(jsonString, options);
  }

  static equals(a: QuotePremium | PlainMessage<QuotePremium> | undefined, b: QuotePremium | PlainMessage<QuotePremium> | undefined): boolean {
    return proto3.util.equals(QuotePremium, a, b);
  }
}

/**
 * Bundle refers to the extra quote information that
 * we get from some carriers when you get special prices if
 * you do more than one line of business in the same quote.
 * Bundle will have one of the following states:
 * 1. Not set: The carrier does not support bundling or we don't have a bundling offer for this quote
 * 2. Only the insurance_type is set: We don't have a quote for the given insurance_type, but we have a bundling offer
 * for this quote that can be used as a fallback.
 * 3. Both insurance_type and premium are set: We have a normal quote for this LOB and also have a bundle to show.
 *
 * @generated from message agentero.policy.Bundle
 */
export class Bundle extends Message<Bundle> {
  /**
   * @generated from field: agentero.policy.QuotePremium premium = 1;
   */
  premium?: QuotePremium;

  /**
   * @generated from field: agentero.policy.InsuranceType insurance_type = 2;
   */
  insuranceType = InsuranceType.UNDEFINED_INSURANCE_TYPE;

  /**
   * @generated from field: repeated agentero.policy.Bundle.MontlyTermPayment monthly_term_payments = 3;
   */
  monthlyTermPayments: Bundle_MontlyTermPayment[] = [];

  constructor(data?: PartialMessage<Bundle>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.policy.Bundle";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "premium", kind: "message", T: QuotePremium },
    { no: 2, name: "insurance_type", kind: "enum", T: proto3.getEnumType(InsuranceType) },
    { no: 3, name: "monthly_term_payments", kind: "message", T: Bundle_MontlyTermPayment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Bundle {
    return new Bundle().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Bundle {
    return new Bundle().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Bundle {
    return new Bundle().fromJsonString(jsonString, options);
  }

  static equals(a: Bundle | PlainMessage<Bundle> | undefined, b: Bundle | PlainMessage<Bundle> | undefined): boolean {
    return proto3.util.equals(Bundle, a, b);
  }
}

/**
 * @generated from message agentero.policy.Bundle.MontlyTermPayment
 */
export class Bundle_MontlyTermPayment extends Message<Bundle_MontlyTermPayment> {
  /**
   * @generated from field: double amount = 1;
   */
  amount = 0;

  /**
   * @generated from field: double fees = 2;
   */
  fees = 0;

  constructor(data?: PartialMessage<Bundle_MontlyTermPayment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.policy.Bundle.MontlyTermPayment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "fees", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Bundle_MontlyTermPayment {
    return new Bundle_MontlyTermPayment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Bundle_MontlyTermPayment {
    return new Bundle_MontlyTermPayment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Bundle_MontlyTermPayment {
    return new Bundle_MontlyTermPayment().fromJsonString(jsonString, options);
  }

  static equals(a: Bundle_MontlyTermPayment | PlainMessage<Bundle_MontlyTermPayment> | undefined, b: Bundle_MontlyTermPayment | PlainMessage<Bundle_MontlyTermPayment> | undefined): boolean {
    return proto3.util.equals(Bundle_MontlyTermPayment, a, b);
  }
}

