import styled from '@emotion/styled';

type FormBoxProps = {
	error?: boolean;
};

export const FormBox = styled.div<FormBoxProps>`
	max-height: 160px;
	padding: 8px 16px;
	overflow: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	border: 1px solid
		${({ theme, error }) => (error ? theme.colors.status.error.base : theme.colors.gray.lighten80)};
	border-radius: 2px;
`;
