import { Path } from 'react-hook-form';

import { FormGroupInput } from '../FormGroupInput';
import { FormGroupSelect, SelectValue } from '../FormGroupSelect';

type FormGroupVINRelatedInputProps<T> = {
	name: Path<T>;
	label: string;
	disabled?: boolean;
	options: SelectValue[];
	isReadOnly: boolean;
	onChange?: () => void;
};

export const FormGroupVINRelatedInput = <T extends {}>({
	name,
	label,
	disabled,
	options,
	isReadOnly,
	onChange
}: FormGroupVINRelatedInputProps<T>) => {
	return isReadOnly ? (
		<FormGroupInput<T> name={name} label={label} disabled />
	) : (
		<FormGroupSelect<T>
			name={name}
			label={label}
			placeholder={`Select ${label.toLowerCase()}`}
			values={options}
			disabled={disabled}
			onChange={onChange}
		/>
	);
};
