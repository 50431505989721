import { createGrpcWebTransport } from '@connectrpc/connect-web';

import { devToolsInterceptor } from '@agentero/service-clients/devToolsInterceptor';

import { authInterceptor } from './portalTransport/authInterceptor';

export const portalTransport = createGrpcWebTransport({
	baseUrl: process.env.NEXT_PUBLIC_GRPC_URL,
	useBinaryFormat: true,
	interceptors: [devToolsInterceptor, authInterceptor]
});
