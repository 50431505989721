import { PropsWithChildren, createContext, useContext, useRef } from 'react';

type AgentUniqueIdentifierContextValue = {
	agentUniqueIdentifier: string;
};

const AgentUniqueIdentifierContext = createContext<AgentUniqueIdentifierContextValue>({
	agentUniqueIdentifier: ''
});

export const AgentUniqueIdentifierContextProvider = ({
	children,
	agentUniqueIdentifier
}: PropsWithChildren<AgentUniqueIdentifierContextValue>) => {
	const identifier = useRef(agentUniqueIdentifier);

	if (identifier.current !== agentUniqueIdentifier && identifier.current === '') {
		identifier.current = agentUniqueIdentifier;
	}

	return (
		<AgentUniqueIdentifierContext.Provider value={{ agentUniqueIdentifier: identifier.current }}>
			<span id="agent-identifier" data-identifier={identifier.current}></span>
			{children}
		</AgentUniqueIdentifierContext.Provider>
	);
};

export const useAgentUniqueIdentifier = () => useContext(AgentUniqueIdentifierContext);
