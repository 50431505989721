import { useRef, useState } from 'react';

import { Flex, Stack } from '@agentero/styles/jsx';

import { Button } from '../../../../Button';
import { InputSearch } from '../../../../InputSearch';
import { ScrollArea } from '../../../../ScrollArea';
import { CheckboxGroup } from '../../../../inputs/CheckboxGroup';
import { BaseSearch } from '../../../DataTableFilters';
import { SearchMultipleCheckboxFilterProps } from '../../shared/SearchMultipleCheckboxFilter';

export const SidebarSearchMultipleCheckboxFilter = <Search extends BaseSearch>({
	filter,
	search,
	onSearchChange
}: SearchMultipleCheckboxFilterProps<Search>) => {
	const [textSearch, setSearch] = useState('');
	const viewPortRef = useRef<HTMLDivElement>(null);
	const { options, label } = filter;
	const value = search[filter.key] as string[];

	const onGroupChange = (values: string[]) => {
		const newSearch = { ...search, [filter.key]: values };

		onSearchChange(newSearch);
	};

	const clearFilter = () => {
		const newSearch = { ...search, [filter.key]: [] };

		onSearchChange(newSearch);
	};

	const onInputSearchChange = (value: string) => {
		viewPortRef.current?.scrollTo(0, 0);
		setSearch(value);
	};

	const filteredOptions = options.filter(option =>
		option.label.toLowerCase().includes(textSearch.toLowerCase())
	);

	return (
		<Stack paddingBlockStart="4">
			<InputSearch value={textSearch} onChange={onInputSearchChange} />
			<ScrollArea height="132px" viewPortRef={viewPortRef}>
				<Stack padding="8">
					<CheckboxGroup.Root
						label={''}
						aria-label={label}
						value={value || null}
						onChange={onGroupChange}>
						{filteredOptions.map(({ value, label }) => (
							<CheckboxGroup.Item key={value} value={value}>
								{label}
							</CheckboxGroup.Item>
						))}
					</CheckboxGroup.Root>
				</Stack>
			</ScrollArea>
			<Flex>
				<Button variant="link" onClick={clearFilter}>
					Clear filter
				</Button>
			</Flex>
		</Stack>
	);
};
