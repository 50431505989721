import * as yup from 'yup';

import { Search } from '../useAgUrlState';

export const queryToSearchShape = <T extends Search<T['field']>>(
	defaultSearch: T,
	query: Partial<T>
) => {
	const newQuery = JSON.parse(JSON.stringify(query));

	Object.keys(query).map(key => {
		if (Array.isArray(defaultSearch[key]) && !Array.isArray(query[key])) {
			newQuery[key] = [query[key]];
		} else if (
			defaultSearch[key] !== '' &&
			!isNaN(defaultSearch[key] as number) &&
			!isNaN(query[key] as number)
		) {
			newQuery[key] = parseInt(query[key] as string);
		}
	});

	return newQuery;
};

type GetStateArgs<T> = {
	query: Partial<T>;
	schema: yup.AnyObjectSchema;
	defaultSearch: T;
};

export const getState = <T extends Search<T['field']>>({
	query,
	schema,
	defaultSearch
}: GetStateArgs<T>): Partial<T> => {
	try {
		const search = { ...defaultSearch, ...query };

		return schema.validateSync(search, {
			abortEarly: false
		});
	} catch (e) {
		const error = e as yup.ValidationError;
		const errorPaths = error.inner.map(({ path }) => path!);

		const queryObject = queryToSearchShape(defaultSearch, query);

		const validObject = getValidObject(queryObject, errorPaths);
		return validObject;
	}
};

const getValidObject = (object: unknown, selectors: string[]) => {
	const copy = JSON.parse(JSON.stringify(object));

	selectors.map(s => {
		const paths = s
			.replace(/\[([^\[\]]*)\]/g, '.$1.')
			.split('.')
			.filter(t => t !== '');

		deleteProp(copy, paths);
	});

	return copy;
};

const deleteProp = (object: any, path: string[]) => {
	const last = path.pop() as string;

	const parent = path.reduce((o, k) => o[k] || {}, object);

	if (Array.isArray(parent)) {
		parent.splice(Number(last), 1);
	} else {
		delete parent[last];
	}
};
