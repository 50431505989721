import styled from '@emotion/styled';

import { IconTextContainer, IconTextIcon } from './IconText';

export interface IButton {
	isBig?: boolean;
	isDisabled?: boolean; //TODO: Remove this prop in favor of disabled
	isBlock?: boolean;
	hasMinWidth?: boolean;
	as?: string;
	href?: string;
	target?: string;
	rel?: string;
}

/**
 * @deprecated
 */
export const Button = styled.button<IButton>`
	--height: 32px;
	--height-big: 40px;
	--padding: 0 12px;
	--padding-big: 0 16px;
	--svg-margin: 0 -6px;
	--svg-margin-big: 0 -7px;

	display: ${props => (props.isBlock ? 'flex' : 'inline-flex')};
	align-items: center;
	justify-content: center;
	padding: ${props => (props.isBig ? 'var(--padding-big)' : 'var(--padding)')};
	${props => props.hasMinWidth && 'min-width: 5.375rem;'};
	${props => props.isBlock && 'width: 100%;'};
	max-width: 100%;
	height: ${({ isBig }) => (isBig ? 'var(--height-big)' : 'var(--height)')};
	min-height: ${({ isBig }) => (isBig ? 'var(--height-big)' : 'var(--height)')};
	${props => props.theme.fontWeight.medium};
	${({ theme }) => theme.textSettings.S};
	color: ${props => props.theme.colors.gray.lighten40};
	text-align: center;
	text-decoration: none;
	cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
	pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
	background: transparent;
	border: 1px solid transparent;
	border-radius: 4px;
	outline: none;
	opacity: ${({ isDisabled }) => (isDisabled ? '0.4' : '1')};
	transition: 0.15s;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	/* TODO: Change this when the icons are better done */
	> svg {
		display: block;
		width: ${({ isBig }) => (isBig ? '1.25rem' : '1.125rem')};
		height: ${({ isBig }) => (isBig ? '1.25rem' : '1.125rem')};
		margin: ${props => (props.isBig ? 'var(--svg-margin-big)' : 'var(--svg-margin)')};

		path {
			transition: 0.15s;
		}
	}

	&:focus,
	&:hover {
		text-decoration: none;
		color: ${props => props.theme.colors.gray.lighten40};
	}

	&:disabled,
	&[disabled] {
		opacity: 0.4;
		pointer-events: none;
		user-select: none;
	}

	${IconTextContainer} {
		gap: 10px;
	}

	${IconTextIcon} {
		margin: 0 -2px;
	}
`;
