const tokens = {
  "fonts.body": {
    "value": "Inter, sans-serif",
    "variable": "var(--fonts-body)"
  },
  "fontSizes.12": {
    "value": "0.75rem",
    "variable": "var(--font-sizes-12)"
  },
  "fontSizes.14": {
    "value": "0.875rem",
    "variable": "var(--font-sizes-14)"
  },
  "fontSizes.16": {
    "value": "1rem",
    "variable": "var(--font-sizes-16)"
  },
  "fontSizes.18": {
    "value": "1.125rem",
    "variable": "var(--font-sizes-18)"
  },
  "fontSizes.22": {
    "value": "1.375rem",
    "variable": "var(--font-sizes-22)"
  },
  "fontSizes.26": {
    "value": "1.625rem",
    "variable": "var(--font-sizes-26)"
  },
  "fontSizes.32": {
    "value": "2rem",
    "variable": "var(--font-sizes-32)"
  },
  "fontSizes.48": {
    "value": "3rem",
    "variable": "var(--font-sizes-48)"
  },
  "lineHeights.16": {
    "value": "1rem",
    "variable": "var(--line-heights-16)"
  },
  "lineHeights.18": {
    "value": "1.125rem",
    "variable": "var(--line-heights-18)"
  },
  "lineHeights.20": {
    "value": "1.25rem",
    "variable": "var(--line-heights-20)"
  },
  "lineHeights.24": {
    "value": "1.5rem",
    "variable": "var(--line-heights-24)"
  },
  "lineHeights.28": {
    "value": "1.75rem",
    "variable": "var(--line-heights-28)"
  },
  "lineHeights.32": {
    "value": "2rem",
    "variable": "var(--line-heights-32)"
  },
  "lineHeights.36": {
    "value": "2.25rem",
    "variable": "var(--line-heights-36)"
  },
  "lineHeights.52": {
    "value": "3.25rem",
    "variable": "var(--line-heights-52)"
  },
  "lineHeights.80": {
    "value": "5rem",
    "variable": "var(--line-heights-80)"
  },
  "colors.white": {
    "value": "#FFFFFF",
    "variable": "var(--colors-white)"
  },
  "colors.slate.100": {
    "value": "#EBEDF2",
    "variable": "var(--colors-slate-100)"
  },
  "colors.slate.200": {
    "value": "#CFD2DB",
    "variable": "var(--colors-slate-200)"
  },
  "colors.slate.300": {
    "value": "#B3B8C7",
    "variable": "var(--colors-slate-300)"
  },
  "colors.slate.400": {
    "value": "#949AAD",
    "variable": "var(--colors-slate-400)"
  },
  "colors.slate.500": {
    "value": "#727A91",
    "variable": "var(--colors-slate-500)"
  },
  "colors.slate.600": {
    "value": "#5A6072",
    "variable": "var(--colors-slate-600)"
  },
  "colors.slate.700": {
    "value": "#434856",
    "variable": "var(--colors-slate-700)"
  },
  "colors.slate.800": {
    "value": "#2D3039",
    "variable": "var(--colors-slate-800)"
  },
  "colors.slate.900": {
    "value": "#16181D",
    "variable": "var(--colors-slate-900)"
  },
  "colors.slate.050": {
    "value": "#F7F8FA",
    "variable": "var(--colors-slate-050)"
  },
  "colors.neutrals.100": {
    "value": "#E3E3E3",
    "variable": "var(--colors-neutrals-100)"
  },
  "colors.neutrals.200": {
    "value": "#CDCDCD",
    "variable": "var(--colors-neutrals-200)"
  },
  "colors.neutrals.300": {
    "value": "#B2B2B2",
    "variable": "var(--colors-neutrals-300)"
  },
  "colors.neutrals.400": {
    "value": "#949494",
    "variable": "var(--colors-neutrals-400)"
  },
  "colors.neutrals.500": {
    "value": "#767676",
    "variable": "var(--colors-neutrals-500)"
  },
  "colors.neutrals.600": {
    "value": "#5A5A5A",
    "variable": "var(--colors-neutrals-600)"
  },
  "colors.neutrals.700": {
    "value": "#434343",
    "variable": "var(--colors-neutrals-700)"
  },
  "colors.neutrals.800": {
    "value": "#303030",
    "variable": "var(--colors-neutrals-800)"
  },
  "colors.neutrals.900": {
    "value": "#242424",
    "variable": "var(--colors-neutrals-900)"
  },
  "colors.neutrals.1000": {
    "value": "#000000",
    "variable": "var(--colors-neutrals-1000)"
  },
  "colors.neutrals.000": {
    "value": "#FFFFFF",
    "variable": "var(--colors-neutrals-000)"
  },
  "colors.neutrals.050": {
    "value": "#F3F3F3",
    "variable": "var(--colors-neutrals-050)"
  },
  "colors.neutral.10": {
    "value": "#242424",
    "variable": "var(--colors-neutral-10)"
  },
  "colors.neutral.30": {
    "value": "#434343",
    "variable": "var(--colors-neutral-30)"
  },
  "colors.neutral.40": {
    "value": "#5A5A5A",
    "variable": "var(--colors-neutral-40)"
  },
  "colors.neutral.50": {
    "value": "#767676",
    "variable": "var(--colors-neutral-50)"
  },
  "colors.neutral.60": {
    "value": "#949494",
    "variable": "var(--colors-neutral-60)"
  },
  "colors.neutral.70": {
    "value": "#B2B2B2",
    "variable": "var(--colors-neutral-70)"
  },
  "colors.neutral.80": {
    "value": "#CDCDCD",
    "variable": "var(--colors-neutral-80)"
  },
  "colors.neutral.90": {
    "value": "#E3E3E3",
    "variable": "var(--colors-neutral-90)"
  },
  "colors.neutral.95": {
    "value": "#F3F3F3",
    "variable": "var(--colors-neutral-95)"
  },
  "colors.neutral": {
    "value": "#303030",
    "variable": "var(--colors-neutral)"
  },
  "colors.brand.10": {
    "value": "#0A2920",
    "variable": "var(--colors-brand-10)"
  },
  "colors.brand.20": {
    "value": "#14523F",
    "variable": "var(--colors-brand-20)"
  },
  "colors.brand.30": {
    "value": "#1E7B5F",
    "variable": "var(--colors-brand-30)"
  },
  "colors.brand.40": {
    "value": "#28A47F",
    "variable": "var(--colors-brand-40)"
  },
  "colors.brand.60": {
    "value": "#5BD7B2",
    "variable": "var(--colors-brand-60)"
  },
  "colors.brand.70": {
    "value": "#84E1C5",
    "variable": "var(--colors-brand-70)"
  },
  "colors.brand.80": {
    "value": "#ADEBD8",
    "variable": "var(--colors-brand-80)"
  },
  "colors.brand.90": {
    "value": "#D6F5EC",
    "variable": "var(--colors-brand-90)"
  },
  "colors.brand.95": {
    "value": "#EAFAF5",
    "variable": "var(--colors-brand-95)"
  },
  "colors.brand.100": {
    "value": "#D6F5EC",
    "variable": "var(--colors-brand-100)"
  },
  "colors.brand.200": {
    "value": "#ADEBD8",
    "variable": "var(--colors-brand-200)"
  },
  "colors.brand.300": {
    "value": "#84E1C5",
    "variable": "var(--colors-brand-300)"
  },
  "colors.brand.400": {
    "value": "#5BD7B2",
    "variable": "var(--colors-brand-400)"
  },
  "colors.brand.500": {
    "value": "#32CD9F",
    "variable": "var(--colors-brand-500)"
  },
  "colors.brand.600": {
    "value": "#28A47F",
    "variable": "var(--colors-brand-600)"
  },
  "colors.brand.700": {
    "value": "#1E7B5F",
    "variable": "var(--colors-brand-700)"
  },
  "colors.brand.800": {
    "value": "#14523F",
    "variable": "var(--colors-brand-800)"
  },
  "colors.brand.900": {
    "value": "#0A2920",
    "variable": "var(--colors-brand-900)"
  },
  "colors.brand.050": {
    "value": "#EAFAF5",
    "variable": "var(--colors-brand-050)"
  },
  "colors.brand": {
    "value": "#32CD9F",
    "variable": "var(--colors-brand)"
  },
  "colors.positive.100": {
    "value": "#D1FADA",
    "variable": "var(--colors-positive-100)"
  },
  "colors.positive.200": {
    "value": "#A3F5B5",
    "variable": "var(--colors-positive-200)"
  },
  "colors.positive.300": {
    "value": "#75F090",
    "variable": "var(--colors-positive-300)"
  },
  "colors.positive.400": {
    "value": "#47EB6B",
    "variable": "var(--colors-positive-400)"
  },
  "colors.positive.500": {
    "value": "#19E646",
    "variable": "var(--colors-positive-500)"
  },
  "colors.positive.600": {
    "value": "#14B838",
    "variable": "var(--colors-positive-600)"
  },
  "colors.positive.700": {
    "value": "#0F8A2A",
    "variable": "var(--colors-positive-700)"
  },
  "colors.positive.800": {
    "value": "#0A5C1C",
    "variable": "var(--colors-positive-800)"
  },
  "colors.positive.900": {
    "value": "#052E0E",
    "variable": "var(--colors-positive-900)"
  },
  "colors.positive.050": {
    "value": "#E8FCEC",
    "variable": "var(--colors-positive-050)"
  },
  "colors.green.10": {
    "value": "#052E0E",
    "variable": "var(--colors-green-10)"
  },
  "colors.green.20": {
    "value": "#0A5C1C",
    "variable": "var(--colors-green-20)"
  },
  "colors.green.30": {
    "value": "#0F8A2A",
    "variable": "var(--colors-green-30)"
  },
  "colors.green.40": {
    "value": "#14B838",
    "variable": "var(--colors-green-40)"
  },
  "colors.green.60": {
    "value": "#47EB6B",
    "variable": "var(--colors-green-60)"
  },
  "colors.green.70": {
    "value": "#75F090",
    "variable": "var(--colors-green-70)"
  },
  "colors.green.80": {
    "value": "#A3F5B5",
    "variable": "var(--colors-green-80)"
  },
  "colors.green.90": {
    "value": "#D1FADA",
    "variable": "var(--colors-green-90)"
  },
  "colors.green.95": {
    "value": "#E8FCEC",
    "variable": "var(--colors-green-95)"
  },
  "colors.green": {
    "value": "#19E646",
    "variable": "var(--colors-green)"
  },
  "colors.danger.100": {
    "value": "#F7D4D4",
    "variable": "var(--colors-danger-100)"
  },
  "colors.danger.200": {
    "value": "#F0A8A8",
    "variable": "var(--colors-danger-200)"
  },
  "colors.danger.300": {
    "value": "#E87D7D",
    "variable": "var(--colors-danger-300)"
  },
  "colors.danger.400": {
    "value": "#E05252",
    "variable": "var(--colors-danger-400)"
  },
  "colors.danger.500": {
    "value": "#D92626",
    "variable": "var(--colors-danger-500)"
  },
  "colors.danger.600": {
    "value": "#AD1F1F",
    "variable": "var(--colors-danger-600)"
  },
  "colors.danger.700": {
    "value": "#821717",
    "variable": "var(--colors-danger-700)"
  },
  "colors.danger.800": {
    "value": "#570F0F",
    "variable": "var(--colors-danger-800)"
  },
  "colors.danger.900": {
    "value": "#2B0808",
    "variable": "var(--colors-danger-900)"
  },
  "colors.danger.050": {
    "value": "#FBE9E9",
    "variable": "var(--colors-danger-050)"
  },
  "colors.red.10": {
    "value": "#2B0808",
    "variable": "var(--colors-red-10)"
  },
  "colors.red.20": {
    "value": "#570F0F",
    "variable": "var(--colors-red-20)"
  },
  "colors.red.30": {
    "value": "#821717",
    "variable": "var(--colors-red-30)"
  },
  "colors.red.40": {
    "value": "#AD1F1F",
    "variable": "var(--colors-red-40)"
  },
  "colors.red.60": {
    "value": "#E05252",
    "variable": "var(--colors-red-60)"
  },
  "colors.red.70": {
    "value": "#E87D7D",
    "variable": "var(--colors-red-70)"
  },
  "colors.red.80": {
    "value": "#F0A8A8",
    "variable": "var(--colors-red-80)"
  },
  "colors.red.90": {
    "value": "#F7D4D4",
    "variable": "var(--colors-red-90)"
  },
  "colors.red.95": {
    "value": "#FBE9E9",
    "variable": "var(--colors-red-95)"
  },
  "colors.red": {
    "value": "#D92626",
    "variable": "var(--colors-red)"
  },
  "colors.warning.100": {
    "value": "#FDF1CE",
    "variable": "var(--colors-warning-100)"
  },
  "colors.warning.200": {
    "value": "#FBE49D",
    "variable": "var(--colors-warning-200)"
  },
  "colors.warning.300": {
    "value": "#FAD66B",
    "variable": "var(--colors-warning-300)"
  },
  "colors.warning.400": {
    "value": "#F8C83A",
    "variable": "var(--colors-warning-400)"
  },
  "colors.warning.500": {
    "value": "#F6BB09",
    "variable": "var(--colors-warning-500)"
  },
  "colors.warning.600": {
    "value": "#C59507",
    "variable": "var(--colors-warning-600)"
  },
  "colors.warning.700": {
    "value": "#947005",
    "variable": "var(--colors-warning-700)"
  },
  "colors.warning.800": {
    "value": "#624B04",
    "variable": "var(--colors-warning-800)"
  },
  "colors.warning.900": {
    "value": "#312502",
    "variable": "var(--colors-warning-900)"
  },
  "colors.warning.050": {
    "value": "#FEF8E6",
    "variable": "var(--colors-warning-050)"
  },
  "colors.yellow.10": {
    "value": "#312502",
    "variable": "var(--colors-yellow-10)"
  },
  "colors.yellow.20": {
    "value": "#624B04",
    "variable": "var(--colors-yellow-20)"
  },
  "colors.yellow.30": {
    "value": "#947005",
    "variable": "var(--colors-yellow-30)"
  },
  "colors.yellow.40": {
    "value": "#C59507",
    "variable": "var(--colors-yellow-40)"
  },
  "colors.yellow.60": {
    "value": "#F8C83A",
    "variable": "var(--colors-yellow-60)"
  },
  "colors.yellow.70": {
    "value": "#FAD66B",
    "variable": "var(--colors-yellow-70)"
  },
  "colors.yellow.80": {
    "value": "#FBE49D",
    "variable": "var(--colors-yellow-80)"
  },
  "colors.yellow.90": {
    "value": "#FDF1CE",
    "variable": "var(--colors-yellow-90)"
  },
  "colors.yellow.95": {
    "value": "#FEF8E6",
    "variable": "var(--colors-yellow-95)"
  },
  "colors.yellow": {
    "value": "#F6BB09",
    "variable": "var(--colors-yellow)"
  },
  "colors.orange.10": {
    "value": "#321501",
    "variable": "var(--colors-orange-10)"
  },
  "colors.orange.20": {
    "value": "#632B03",
    "variable": "var(--colors-orange-20)"
  },
  "colors.orange.30": {
    "value": "#954004",
    "variable": "var(--colors-orange-30)"
  },
  "colors.orange.40": {
    "value": "#C75605",
    "variable": "var(--colors-orange-40)"
  },
  "colors.orange.60": {
    "value": "#FA8938",
    "variable": "var(--colors-orange-60)"
  },
  "colors.orange.70": {
    "value": "#FBA66A",
    "variable": "var(--colors-orange-70)"
  },
  "colors.orange.80": {
    "value": "#FCC49C",
    "variable": "var(--colors-orange-80)"
  },
  "colors.orange.90": {
    "value": "#FEE1CD",
    "variable": "var(--colors-orange-90)"
  },
  "colors.orange.95": {
    "value": "#FEF8E6",
    "variable": "var(--colors-orange-95)"
  },
  "colors.orange.100": {
    "value": "#FEE1CD",
    "variable": "var(--colors-orange-100)"
  },
  "colors.orange.200": {
    "value": "#FCC49C",
    "variable": "var(--colors-orange-200)"
  },
  "colors.orange.300": {
    "value": "#FBA66A",
    "variable": "var(--colors-orange-300)"
  },
  "colors.orange.400": {
    "value": "#FA8938",
    "variable": "var(--colors-orange-400)"
  },
  "colors.orange.500": {
    "value": "#F96B06",
    "variable": "var(--colors-orange-500)"
  },
  "colors.orange.600": {
    "value": "#C75605",
    "variable": "var(--colors-orange-600)"
  },
  "colors.orange.700": {
    "value": "#954004",
    "variable": "var(--colors-orange-700)"
  },
  "colors.orange.800": {
    "value": "#632B03",
    "variable": "var(--colors-orange-800)"
  },
  "colors.orange.900": {
    "value": "#321501",
    "variable": "var(--colors-orange-900)"
  },
  "colors.orange.050": {
    "value": "#FEF0E6",
    "variable": "var(--colors-orange-050)"
  },
  "colors.orange": {
    "value": "#F96B06",
    "variable": "var(--colors-orange)"
  },
  "colors.blue.10": {
    "value": "#021731",
    "variable": "var(--colors-blue-10)"
  },
  "colors.blue.20": {
    "value": "#032E63",
    "variable": "var(--colors-blue-20)"
  },
  "colors.blue.30": {
    "value": "#054594",
    "variable": "var(--colors-blue-30)"
  },
  "colors.blue.40": {
    "value": "#065CC6",
    "variable": "var(--colors-blue-40)"
  },
  "colors.blue.60": {
    "value": "#398FF9",
    "variable": "var(--colors-blue-60)"
  },
  "colors.blue.70": {
    "value": "#6BABFA",
    "variable": "var(--colors-blue-70)"
  },
  "colors.blue.80": {
    "value": "#9CC7FC",
    "variable": "var(--colors-blue-80)"
  },
  "colors.blue.90": {
    "value": "#CEE3FD",
    "variable": "var(--colors-blue-90)"
  },
  "colors.blue.95": {
    "value": "#E6F1FE",
    "variable": "var(--colors-blue-95)"
  },
  "colors.blue.100": {
    "value": "#CEE3FD",
    "variable": "var(--colors-blue-100)"
  },
  "colors.blue.200": {
    "value": "#9CC7FC",
    "variable": "var(--colors-blue-200)"
  },
  "colors.blue.300": {
    "value": "#6BABFA",
    "variable": "var(--colors-blue-300)"
  },
  "colors.blue.400": {
    "value": "#398FF9",
    "variable": "var(--colors-blue-400)"
  },
  "colors.blue.500": {
    "value": "#0874F7",
    "variable": "var(--colors-blue-500)"
  },
  "colors.blue.600": {
    "value": "#065CC6",
    "variable": "var(--colors-blue-600)"
  },
  "colors.blue.700": {
    "value": "#054594",
    "variable": "var(--colors-blue-700)"
  },
  "colors.blue.800": {
    "value": "#032E63",
    "variable": "var(--colors-blue-800)"
  },
  "colors.blue.900": {
    "value": "#021731",
    "variable": "var(--colors-blue-900)"
  },
  "colors.blue.050": {
    "value": "#E6F1FE",
    "variable": "var(--colors-blue-050)"
  },
  "colors.blue": {
    "value": "#0874F7",
    "variable": "var(--colors-blue)"
  },
  "colors.purple.10": {
    "value": "#1A0231",
    "variable": "var(--colors-purple-10)"
  },
  "colors.purple.20": {
    "value": "#350561",
    "variable": "var(--colors-purple-20)"
  },
  "colors.purple.30": {
    "value": "#4F0792",
    "variable": "var(--colors-purple-30)"
  },
  "colors.purple.40": {
    "value": "#6909C3",
    "variable": "var(--colors-purple-40)"
  },
  "colors.purple.60": {
    "value": "#9C3CF6",
    "variable": "var(--colors-purple-60)"
  },
  "colors.purple.70": {
    "value": "#B56DF8",
    "variable": "var(--colors-purple-70)"
  },
  "colors.purple.80": {
    "value": "#CE9EFA",
    "variable": "var(--colors-purple-80)"
  },
  "colors.purple.90": {
    "value": "#E6CEFD",
    "variable": "var(--colors-purple-90)"
  },
  "colors.purple.95": {
    "value": "#F3E7FE",
    "variable": "var(--colors-purple-95)"
  },
  "colors.purple.100": {
    "value": "#E6CEFD",
    "variable": "var(--colors-purple-100)"
  },
  "colors.purple.200": {
    "value": "#CE9EFA",
    "variable": "var(--colors-purple-200)"
  },
  "colors.purple.300": {
    "value": "#B56DF8",
    "variable": "var(--colors-purple-300)"
  },
  "colors.purple.400": {
    "value": "#9C3CF6",
    "variable": "var(--colors-purple-400)"
  },
  "colors.purple.500": {
    "value": "#830BF4",
    "variable": "var(--colors-purple-500)"
  },
  "colors.purple.600": {
    "value": "#6909C3",
    "variable": "var(--colors-purple-600)"
  },
  "colors.purple.700": {
    "value": "#4F0792",
    "variable": "var(--colors-purple-700)"
  },
  "colors.purple.800": {
    "value": "#350561",
    "variable": "var(--colors-purple-800)"
  },
  "colors.purple.900": {
    "value": "#1A0231",
    "variable": "var(--colors-purple-900)"
  },
  "colors.purple.050": {
    "value": "#F3E7FE",
    "variable": "var(--colors-purple-050)"
  },
  "colors.purple": {
    "value": "#830BF4",
    "variable": "var(--colors-purple)"
  },
  "colors.pink.10": {
    "value": "#2F0415",
    "variable": "var(--colors-pink-10)"
  },
  "colors.pink.20": {
    "value": "#5E082A",
    "variable": "var(--colors-pink-20)"
  },
  "colors.pink.30": {
    "value": "#8D0C40",
    "variable": "var(--colors-pink-30)"
  },
  "colors.pink.40": {
    "value": "#BC1055",
    "variable": "var(--colors-pink-40)"
  },
  "colors.pink.60": {
    "value": "#EF4388",
    "variable": "var(--colors-pink-60)"
  },
  "colors.pink.70": {
    "value": "#F372A6",
    "variable": "var(--colors-pink-70)"
  },
  "colors.pink.80": {
    "value": "#F7A1C3",
    "variable": "var(--colors-pink-80)"
  },
  "colors.pink.90": {
    "value": "#FBD0E1",
    "variable": "var(--colors-pink-90)"
  },
  "colors.pink.95": {
    "value": "#FDE8F0",
    "variable": "var(--colors-pink-95)"
  },
  "colors.pink.100": {
    "value": "#FBD0E1",
    "variable": "var(--colors-pink-100)"
  },
  "colors.pink.200": {
    "value": "#F7A1C3",
    "variable": "var(--colors-pink-200)"
  },
  "colors.pink.300": {
    "value": "#F372A6",
    "variable": "var(--colors-pink-300)"
  },
  "colors.pink.400": {
    "value": "#EF4388",
    "variable": "var(--colors-pink-400)"
  },
  "colors.pink.500": {
    "value": "#EB146A",
    "variable": "var(--colors-pink-500)"
  },
  "colors.pink.600": {
    "value": "#BC1055",
    "variable": "var(--colors-pink-600)"
  },
  "colors.pink.700": {
    "value": "#8D0C40",
    "variable": "var(--colors-pink-700)"
  },
  "colors.pink.800": {
    "value": "#5E082A",
    "variable": "var(--colors-pink-800)"
  },
  "colors.pink.900": {
    "value": "#2F0415",
    "variable": "var(--colors-pink-900)"
  },
  "colors.pink.050": {
    "value": "#FDE8F0",
    "variable": "var(--colors-pink-050)"
  },
  "colors.pink": {
    "value": "#830BF4",
    "variable": "var(--colors-pink)"
  },
  "colors.overlay.dark.100": {
    "value": "rgba(22, 24, 29, 0.1)",
    "variable": "var(--colors-overlay-dark-100)"
  },
  "colors.overlay.dark.200": {
    "value": "rgba(22, 24, 29, 0.2)",
    "variable": "var(--colors-overlay-dark-200)"
  },
  "colors.overlay.dark.300": {
    "value": "rgba(22, 24, 29, 0.3)",
    "variable": "var(--colors-overlay-dark-300)"
  },
  "colors.overlay.dark.400": {
    "value": "rgba(22, 24, 29, 0.4)",
    "variable": "var(--colors-overlay-dark-400)"
  },
  "colors.overlay.dark.500": {
    "value": "rgba(22, 24, 29, 0.5)",
    "variable": "var(--colors-overlay-dark-500)"
  },
  "colors.overlay.dark.600": {
    "value": "rgba(22, 24, 29, 0.6)",
    "variable": "var(--colors-overlay-dark-600)"
  },
  "colors.overlay.dark.700": {
    "value": "rgba(22, 24, 29, 0.7)",
    "variable": "var(--colors-overlay-dark-700)"
  },
  "colors.overlay.dark.800": {
    "value": "rgba(22, 24, 29, 0.8)",
    "variable": "var(--colors-overlay-dark-800)"
  },
  "colors.overlay.dark.900": {
    "value": "rgba(22, 24, 29, 0.9)",
    "variable": "var(--colors-overlay-dark-900)"
  },
  "colors.overlay.dark.050": {
    "value": "rgba(22, 24, 29, 0.05)",
    "variable": "var(--colors-overlay-dark-050)"
  },
  "colors.overlay.light.100": {
    "value": "rgba(255, 255, 255, 0.1)",
    "variable": "var(--colors-overlay-light-100)"
  },
  "colors.overlay.light.200": {
    "value": "rgba(255, 255, 255, 0.2)",
    "variable": "var(--colors-overlay-light-200)"
  },
  "colors.overlay.light.300": {
    "value": "rgba(255, 255, 255, 0.3)",
    "variable": "var(--colors-overlay-light-300)"
  },
  "colors.overlay.light.400": {
    "value": "rgba(255, 255, 255, 0.4)",
    "variable": "var(--colors-overlay-light-400)"
  },
  "colors.overlay.light.500": {
    "value": "rgba(255, 255, 255, 0.5)",
    "variable": "var(--colors-overlay-light-500)"
  },
  "colors.overlay.light.600": {
    "value": "rgba(255, 255, 255, 0.6)",
    "variable": "var(--colors-overlay-light-600)"
  },
  "colors.overlay.light.700": {
    "value": "rgba(255, 255, 255, 0.7)",
    "variable": "var(--colors-overlay-light-700)"
  },
  "colors.overlay.light.800": {
    "value": "rgba(255, 255, 255, 0.8)",
    "variable": "var(--colors-overlay-light-800)"
  },
  "colors.overlay.light.900": {
    "value": "rgba(255, 255, 255, 0.9)",
    "variable": "var(--colors-overlay-light-900)"
  },
  "colors.overlay.light.050": {
    "value": "rgba(255, 255, 255, 0.05)",
    "variable": "var(--colors-overlay-light-050)"
  },
  "spacing.0": {
    "value": "0rem",
    "variable": "var(--spacing-0)"
  },
  "spacing.1": {
    "value": "0.0625rem",
    "variable": "var(--spacing-1)"
  },
  "spacing.2": {
    "value": "0.125rem",
    "variable": "var(--spacing-2)"
  },
  "spacing.4": {
    "value": "0.25rem",
    "variable": "var(--spacing-4)"
  },
  "spacing.6": {
    "value": "0.375rem",
    "variable": "var(--spacing-6)"
  },
  "spacing.8": {
    "value": "0.5rem",
    "variable": "var(--spacing-8)"
  },
  "spacing.10": {
    "value": "0.625rem",
    "variable": "var(--spacing-10)"
  },
  "spacing.12": {
    "value": "0.75rem",
    "variable": "var(--spacing-12)"
  },
  "spacing.14": {
    "value": "0.875rem",
    "variable": "var(--spacing-14)"
  },
  "spacing.16": {
    "value": "1rem",
    "variable": "var(--spacing-16)"
  },
  "spacing.20": {
    "value": "1.25rem",
    "variable": "var(--spacing-20)"
  },
  "spacing.24": {
    "value": "1.5rem",
    "variable": "var(--spacing-24)"
  },
  "spacing.28": {
    "value": "1.75rem",
    "variable": "var(--spacing-28)"
  },
  "spacing.32": {
    "value": "2rem",
    "variable": "var(--spacing-32)"
  },
  "spacing.36": {
    "value": "2.25rem",
    "variable": "var(--spacing-36)"
  },
  "spacing.40": {
    "value": "2.5rem",
    "variable": "var(--spacing-40)"
  },
  "spacing.44": {
    "value": "2.75rem",
    "variable": "var(--spacing-44)"
  },
  "spacing.48": {
    "value": "3rem",
    "variable": "var(--spacing-48)"
  },
  "spacing.56": {
    "value": "3.5rem",
    "variable": "var(--spacing-56)"
  },
  "spacing.64": {
    "value": "4rem",
    "variable": "var(--spacing-64)"
  },
  "spacing.80": {
    "value": "5rem",
    "variable": "var(--spacing-80)"
  },
  "spacing.96": {
    "value": "6rem",
    "variable": "var(--spacing-96)"
  },
  "spacing.112": {
    "value": "7rem",
    "variable": "var(--spacing-112)"
  },
  "spacing.128": {
    "value": "8rem",
    "variable": "var(--spacing-128)"
  },
  "spacing.144": {
    "value": "9rem",
    "variable": "var(--spacing-144)"
  },
  "spacing.160": {
    "value": "10rem",
    "variable": "var(--spacing-160)"
  },
  "spacing.176": {
    "value": "11rem",
    "variable": "var(--spacing-176)"
  },
  "spacing.192": {
    "value": "12rem",
    "variable": "var(--spacing-192)"
  },
  "spacing.208": {
    "value": "13rem",
    "variable": "var(--spacing-208)"
  },
  "spacing.224": {
    "value": "14rem",
    "variable": "var(--spacing-224)"
  },
  "spacing.240": {
    "value": "15rem",
    "variable": "var(--spacing-240)"
  },
  "spacing.256": {
    "value": "16rem",
    "variable": "var(--spacing-256)"
  },
  "spacing.288": {
    "value": "18rem",
    "variable": "var(--spacing-288)"
  },
  "spacing.320": {
    "value": "20rem",
    "variable": "var(--spacing-320)"
  },
  "spacing.384": {
    "value": "24rem",
    "variable": "var(--spacing-384)"
  },
  "radii.xs": {
    "value": "0.125rem",
    "variable": "var(--radii-xs)"
  },
  "radii.sm": {
    "value": "0.25rem",
    "variable": "var(--radii-sm)"
  },
  "radii.md": {
    "value": "0.5rem",
    "variable": "var(--radii-md)"
  },
  "radii.lg": {
    "value": "0.75rem",
    "variable": "var(--radii-lg)"
  },
  "radii.xl": {
    "value": "1rem",
    "variable": "var(--radii-xl)"
  },
  "radii.2xl": {
    "value": "1.5rem",
    "variable": "var(--radii-2xl)"
  },
  "radii.full": {
    "value": "9999px",
    "variable": "var(--radii-full)"
  },
  "shadows.1": {
    "value": "0rem 0.0625rem 0.125rem 0rem var(--colors-overlay-dark-050)",
    "variable": "var(--shadows-1)"
  },
  "shadows.2": {
    "value": "0rem 0.0625rem 0.125rem 0rem var(--colors-overlay-dark-050), 0rem 0.0625rem 0.125rem 0rem var(--colors-overlay-dark-050)",
    "variable": "var(--shadows-2)"
  },
  "shadows.3": {
    "value": "0rem 0.25rem 0.375rem -0.0625rem var(--colors-overlay-dark-100), 0rem 0.125rem 0.25rem -0.0625rem var(--colors-overlay-dark-050)",
    "variable": "var(--shadows-3)"
  },
  "shadows.4": {
    "value": "0rem 0.625rem 0.9375rem -0.1875rem var(--colors-overlay-dark-100), 0rem 0.25rem 0.375rem -0.125rem var(--colors-overlay-dark-050)",
    "variable": "var(--shadows-4)"
  },
  "shadows.5": {
    "value": "0rem 1.25rem 1.5625rem -0.3125rem var(--colors-overlay-dark-100), 0rem 0.625rem 0.625rem -0.3125rem var(--colors-overlay-dark-050)",
    "variable": "var(--shadows-5)"
  },
  "shadows.6": {
    "value": "0rem 1.5625rem 3.125rem -0.75rem var(--colors-overlay-dark-200)",
    "variable": "var(--shadows-6)"
  },
  "easings.easeOutBack": {
    "value": "cubic-bezier(0.34, 1.56, 0.64, 1)",
    "variable": "var(--easings-ease-out-back)"
  },
  "easings.easeOutExpo": {
    "value": "cubic-bezier(0.16, 1, 0.3, 1)",
    "variable": "var(--easings-ease-out-expo)"
  },
  "animations.accordion-down": {
    "value": "accordion-down 0.2s ease-out",
    "variable": "var(--animations-accordion-down)"
  },
  "animations.accordion-up": {
    "value": "accordion-up 0.2s ease-out",
    "variable": "var(--animations-accordion-up)"
  },
  "zIndex.modal": {
    "value": 1000,
    "variable": "var(--z-index-modal)"
  },
  "breakpoints.sm": {
    "value": "30rem",
    "variable": "var(--breakpoints-sm)"
  },
  "breakpoints.md": {
    "value": "48rem",
    "variable": "var(--breakpoints-md)"
  },
  "breakpoints.lg": {
    "value": "64rem",
    "variable": "var(--breakpoints-lg)"
  },
  "breakpoints.xl": {
    "value": "80rem",
    "variable": "var(--breakpoints-xl)"
  },
  "breakpoints.2xl": {
    "value": "85.375rem",
    "variable": "var(--breakpoints-2xl)"
  },
  "breakpoints.3xl": {
    "value": "100rem",
    "variable": "var(--breakpoints-3xl)"
  },
  "sizes.breakpoint-sm": {
    "value": "30rem",
    "variable": "var(--sizes-breakpoint-sm)"
  },
  "sizes.breakpoint-md": {
    "value": "48rem",
    "variable": "var(--sizes-breakpoint-md)"
  },
  "sizes.breakpoint-lg": {
    "value": "64rem",
    "variable": "var(--sizes-breakpoint-lg)"
  },
  "sizes.breakpoint-xl": {
    "value": "80rem",
    "variable": "var(--sizes-breakpoint-xl)"
  },
  "sizes.breakpoint-2xl": {
    "value": "85.375rem",
    "variable": "var(--sizes-breakpoint-2xl)"
  },
  "sizes.breakpoint-3xl": {
    "value": "100rem",
    "variable": "var(--sizes-breakpoint-3xl)"
  },
  "colors.background.default.base.primary": {
    "value": "var(--colors-neutrals-000)",
    "variable": "var(--colors-background-default-base-primary)"
  },
  "colors.background.default.base.primaryHover": {
    "value": "var(--colors-slate-100)",
    "variable": "var(--colors-background-default-base-primary-hover)"
  },
  "colors.background.default.base.secondary": {
    "value": "var(--colors-slate-050)",
    "variable": "var(--colors-background-default-base-secondary)"
  },
  "colors.background.default.base.secondaryHover": {
    "value": "var(--colors-slate-200)",
    "variable": "var(--colors-background-default-base-secondary-hover)"
  },
  "colors.background.default.base.tertiary": {
    "value": "var(--colors-slate-100)",
    "variable": "var(--colors-background-default-base-tertiary)"
  },
  "colors.background.default.base.tertiaryHover": {
    "value": "var(--colors-slate-300)",
    "variable": "var(--colors-background-default-base-tertiary-hover)"
  },
  "colors.background.default.neutral.primary": {
    "value": "var(--colors-neutrals-050)",
    "variable": "var(--colors-background-default-neutral-primary)"
  },
  "colors.background.default.neutral.primaryHover": {
    "value": "var(--colors-neutrals-100)",
    "variable": "var(--colors-background-default-neutral-primary-hover)"
  },
  "colors.background.default.neutral.secondary": {
    "value": "var(--colors-neutrals-100)",
    "variable": "var(--colors-background-default-neutral-secondary)"
  },
  "colors.background.default.neutral.secondaryHover": {
    "value": "var(--colors-neutrals-200)",
    "variable": "var(--colors-background-default-neutral-secondary-hover)"
  },
  "colors.background.default.neutral.tertiary": {
    "value": "var(--colors-neutrals-200)",
    "variable": "var(--colors-background-default-neutral-tertiary)"
  },
  "colors.background.default.neutral.tertiaryHover": {
    "value": "var(--colors-neutrals-300)",
    "variable": "var(--colors-background-default-neutral-tertiary-hover)"
  },
  "colors.background.default.brand.primary": {
    "value": "var(--colors-brand-500)",
    "variable": "var(--colors-background-default-brand-primary)"
  },
  "colors.background.default.brand.primaryHover": {
    "value": "var(--colors-brand-600)",
    "variable": "var(--colors-background-default-brand-primary-hover)"
  },
  "colors.background.default.brand.secondary": {
    "value": "var(--colors-brand-200)",
    "variable": "var(--colors-background-default-brand-secondary)"
  },
  "colors.background.default.brand.secondaryHover": {
    "value": "var(--colors-brand-300)",
    "variable": "var(--colors-background-default-brand-secondary-hover)"
  },
  "colors.background.default.brand.tertiary": {
    "value": "var(--colors-brand-050)",
    "variable": "var(--colors-background-default-brand-tertiary)"
  },
  "colors.background.default.brand.tertiaryHover": {
    "value": "var(--colors-brand-100)",
    "variable": "var(--colors-background-default-brand-tertiary-hover)"
  },
  "colors.background.default.positive.primary": {
    "value": "var(--colors-positive-500)",
    "variable": "var(--colors-background-default-positive-primary)"
  },
  "colors.background.default.positive.primaryHover": {
    "value": "var(--colors-positive-600)",
    "variable": "var(--colors-background-default-positive-primary-hover)"
  },
  "colors.background.default.positive.secondary": {
    "value": "var(--colors-positive-200)",
    "variable": "var(--colors-background-default-positive-secondary)"
  },
  "colors.background.default.positive.secondaryHover": {
    "value": "var(--colors-positive-300)",
    "variable": "var(--colors-background-default-positive-secondary-hover)"
  },
  "colors.background.default.positive.tertiary": {
    "value": "var(--colors-positive-050)",
    "variable": "var(--colors-background-default-positive-tertiary)"
  },
  "colors.background.default.positive.tertiaryHover": {
    "value": "var(--colors-positive-100)",
    "variable": "var(--colors-background-default-positive-tertiary-hover)"
  },
  "colors.background.default.danger.primary": {
    "value": "var(--colors-danger-500)",
    "variable": "var(--colors-background-default-danger-primary)"
  },
  "colors.background.default.danger.primaryHover": {
    "value": "var(--colors-danger-600)",
    "variable": "var(--colors-background-default-danger-primary-hover)"
  },
  "colors.background.default.danger.secondary": {
    "value": "var(--colors-danger-200)",
    "variable": "var(--colors-background-default-danger-secondary)"
  },
  "colors.background.default.danger.secondaryHover": {
    "value": "var(--colors-danger-300)",
    "variable": "var(--colors-background-default-danger-secondary-hover)"
  },
  "colors.background.default.danger.tertiary": {
    "value": "var(--colors-danger-050)",
    "variable": "var(--colors-background-default-danger-tertiary)"
  },
  "colors.background.default.danger.tertiaryHover": {
    "value": "var(--colors-danger-100)",
    "variable": "var(--colors-background-default-danger-tertiary-hover)"
  },
  "colors.background.default.warning.primary": {
    "value": "var(--colors-warning-500)",
    "variable": "var(--colors-background-default-warning-primary)"
  },
  "colors.background.default.warning.primaryHover": {
    "value": "var(--colors-warning-600)",
    "variable": "var(--colors-background-default-warning-primary-hover)"
  },
  "colors.background.default.warning.secondary": {
    "value": "var(--colors-warning-200)",
    "variable": "var(--colors-background-default-warning-secondary)"
  },
  "colors.background.default.warning.secondaryHover": {
    "value": "var(--colors-warning-300)",
    "variable": "var(--colors-background-default-warning-secondary-hover)"
  },
  "colors.background.default.warning.tertiary": {
    "value": "var(--colors-warning-050)",
    "variable": "var(--colors-background-default-warning-tertiary)"
  },
  "colors.background.default.warning.tertiaryHover": {
    "value": "var(--colors-warning-100)",
    "variable": "var(--colors-background-default-warning-tertiary-hover)"
  },
  "colors.background.default.disable.primary": {
    "value": "var(--colors-neutrals-050)",
    "variable": "var(--colors-background-default-disable-primary)"
  },
  "colors.background.input.normal": {
    "value": "var(--colors-neutrals-000)",
    "variable": "var(--colors-background-input-normal)"
  },
  "colors.background.input.disable": {
    "value": "var(--colors-slate-050)",
    "variable": "var(--colors-background-input-disable)"
  },
  "colors.background.button.primaryDefault": {
    "value": "var(--colors-brand-600)",
    "variable": "var(--colors-background-button-primary-default)"
  },
  "colors.background.button.primaryHover": {
    "value": "var(--colors-brand-700)",
    "variable": "var(--colors-background-button-primary-hover)"
  },
  "colors.background.button.primaryDisable": {
    "value": "var(--colors-slate-100)",
    "variable": "var(--colors-background-button-primary-disable)"
  },
  "colors.background.button.primaryDestructiveNormal": {
    "value": "var(--colors-danger-500)",
    "variable": "var(--colors-background-button-primary-destructive-normal)"
  },
  "colors.background.button.primaryDestructiveHover": {
    "value": "var(--colors-danger-600)",
    "variable": "var(--colors-background-button-primary-destructive-hover)"
  },
  "colors.background.button.primaryDestructiveDisable": {
    "value": "var(--colors-danger-100)",
    "variable": "var(--colors-background-button-primary-destructive-disable)"
  },
  "colors.background.button.primaryNeutralDefault": {
    "value": "var(--colors-slate-700)",
    "variable": "var(--colors-background-button-primary-neutral-default)"
  },
  "colors.background.button.primaryNeutralHover": {
    "value": "var(--colors-slate-800)",
    "variable": "var(--colors-background-button-primary-neutral-hover)"
  },
  "colors.background.button.secondaryOutlineDefault": {
    "value": "var(--colors-neutrals-000)",
    "variable": "var(--colors-background-button-secondary-outline-default)"
  },
  "colors.background.button.secondaryOutlineHover": {
    "value": "var(--colors-slate-100)",
    "variable": "var(--colors-background-button-secondary-outline-hover)"
  },
  "colors.bg": {
    "value": "var(--colors-neutrals-000)",
    "variable": "var(--colors-bg)"
  },
  "colors.border.input.default": {
    "value": "var(--colors-slate-200)",
    "variable": "var(--colors-border-input-default)"
  },
  "colors.border.input.hover": {
    "value": "var(--colors-slate-400)",
    "variable": "var(--colors-border-input-hover)"
  },
  "colors.border.input.focus": {
    "value": "var(--colors-brand-600)",
    "variable": "var(--colors-border-input-focus)"
  },
  "colors.border.input.disable": {
    "value": "var(--colors-slate-100)",
    "variable": "var(--colors-border-input-disable)"
  },
  "colors.border.input.destructive": {
    "value": "var(--colors-danger-500)",
    "variable": "var(--colors-border-input-destructive)"
  },
  "colors.border.button.secondaryOutlineDefault": {
    "value": "var(--colors-slate-200)",
    "variable": "var(--colors-border-button-secondary-outline-default)"
  },
  "colors.border.button.secondaryOutlineDisable": {
    "value": "var(--colors-slate-100)",
    "variable": "var(--colors-border-button-secondary-outline-disable)"
  },
  "colors.border.default.base.primary": {
    "value": "var(--colors-slate-100)",
    "variable": "var(--colors-border-default-base-primary)"
  },
  "colors.border.default.base.secondary": {
    "value": "var(--colors-slate-200)",
    "variable": "var(--colors-border-default-base-secondary)"
  },
  "colors.border.default.base.tertiary": {
    "value": "var(--colors-slate-300)",
    "variable": "var(--colors-border-default-base-tertiary)"
  },
  "colors.border.default.neutral.primary": {
    "value": "var(--colors-neutrals-100)",
    "variable": "var(--colors-border-default-neutral-primary)"
  },
  "colors.border.default.neutral.secondary": {
    "value": "var(--colors-neutrals-200)",
    "variable": "var(--colors-border-default-neutral-secondary)"
  },
  "colors.border.default.neutral.tertiary": {
    "value": "var(--colors-neutrals-300)",
    "variable": "var(--colors-border-default-neutral-tertiary)"
  },
  "colors.border.default.brand.primary": {
    "value": "var(--colors-brand-600)",
    "variable": "var(--colors-border-default-brand-primary)"
  },
  "colors.border.default.brand.secondary": {
    "value": "var(--colors-brand-300)",
    "variable": "var(--colors-border-default-brand-secondary)"
  },
  "colors.border.default.brand.tertiary": {
    "value": "var(--colors-brand-300)",
    "variable": "var(--colors-border-default-brand-tertiary)"
  },
  "colors.border.default.positive.primary": {
    "value": "var(--colors-positive-600)",
    "variable": "var(--colors-border-default-positive-primary)"
  },
  "colors.border.default.positive.secondary": {
    "value": "var(--colors-positive-600)",
    "variable": "var(--colors-border-default-positive-secondary)"
  },
  "colors.border.default.positive.tertiary": {
    "value": "var(--colors-positive-500)",
    "variable": "var(--colors-border-default-positive-tertiary)"
  },
  "colors.border.default.danger.primary": {
    "value": "var(--colors-danger-700)",
    "variable": "var(--colors-border-default-danger-primary)"
  },
  "colors.border.default.danger.secondary": {
    "value": "var(--colors-danger-600)",
    "variable": "var(--colors-border-default-danger-secondary)"
  },
  "colors.border.default.danger.tertiary": {
    "value": "var(--colors-danger-500)",
    "variable": "var(--colors-border-default-danger-tertiary)"
  },
  "colors.border.default.warning.primary": {
    "value": "var(--colors-warning-600)",
    "variable": "var(--colors-border-default-warning-primary)"
  },
  "colors.border.default.warning.secondary": {
    "value": "var(--colors-warning-600)",
    "variable": "var(--colors-border-default-warning-secondary)"
  },
  "colors.border.default.warning.tertiary": {
    "value": "var(--colors-warning-500)",
    "variable": "var(--colors-border-default-warning-tertiary)"
  },
  "colors.border.default.disable.primary": {
    "value": "var(--colors-slate-100)",
    "variable": "var(--colors-border-default-disable-primary)"
  },
  "colors.border": {
    "value": "var(--colors-neutrals-100)",
    "variable": "var(--colors-border)"
  },
  "colors.text.input.normal": {
    "value": "var(--colors-slate-800)",
    "variable": "var(--colors-text-input-normal)"
  },
  "colors.text.input.disable": {
    "value": "var(--colors-slate-300)",
    "variable": "var(--colors-text-input-disable)"
  },
  "colors.text.input.placeholder": {
    "value": "var(--colors-slate-500)",
    "variable": "var(--colors-text-input-placeholder)"
  },
  "colors.text.input.destructive": {
    "value": "var(--colors-danger-500)",
    "variable": "var(--colors-text-input-destructive)"
  },
  "colors.text.button.primary": {
    "value": "var(--colors-neutrals-000)",
    "variable": "var(--colors-text-button-primary)"
  },
  "colors.text.button.disable": {
    "value": "var(--colors-slate-300)",
    "variable": "var(--colors-text-button-disable)"
  },
  "colors.text.default.base.primary": {
    "value": "var(--colors-slate-900)",
    "variable": "var(--colors-text-default-base-primary)"
  },
  "colors.text.default.base.secondary": {
    "value": "var(--colors-neutrals-700)",
    "variable": "var(--colors-text-default-base-secondary)"
  },
  "colors.text.default.base.tertiary": {
    "value": "var(--colors-neutrals-500)",
    "variable": "var(--colors-text-default-base-tertiary)"
  },
  "colors.text.default.base.onBasePrimary": {
    "value": "var(--colors-slate-800)",
    "variable": "var(--colors-text-default-base-on-base-primary)"
  },
  "colors.text.default.base.onBaseSecondary": {
    "value": "var(--colors-slate-700)",
    "variable": "var(--colors-text-default-base-on-base-secondary)"
  },
  "colors.text.default.base.onBaseTertiary": {
    "value": "var(--colors-slate-800)",
    "variable": "var(--colors-text-default-base-on-base-tertiary)"
  },
  "colors.text.default.neutral.primary": {
    "value": "var(--colors-neutrals-1000)",
    "variable": "var(--colors-text-default-neutral-primary)"
  },
  "colors.text.default.neutral.secondary": {
    "value": "var(--colors-neutrals-700)",
    "variable": "var(--colors-text-default-neutral-secondary)"
  },
  "colors.text.default.neutral.tertiary": {
    "value": "var(--colors-neutrals-500)",
    "variable": "var(--colors-text-default-neutral-tertiary)"
  },
  "colors.text.default.neutral.onNeutralPrimary": {
    "value": "var(--colors-neutrals-800)",
    "variable": "var(--colors-text-default-neutral-on-neutral-primary)"
  },
  "colors.text.default.neutral.onNeutralSecondary": {
    "value": "var(--colors-neutrals-800)",
    "variable": "var(--colors-text-default-neutral-on-neutral-secondary)"
  },
  "colors.text.default.neutral.onNeutralTertiary": {
    "value": "var(--colors-neutrals-900)",
    "variable": "var(--colors-text-default-neutral-on-neutral-tertiary)"
  },
  "colors.text.default.brand.primary": {
    "value": "var(--colors-brand-500)",
    "variable": "var(--colors-text-default-brand-primary)"
  },
  "colors.text.default.brand.secondary": {
    "value": "var(--colors-brand-700)",
    "variable": "var(--colors-text-default-brand-secondary)"
  },
  "colors.text.default.brand.tertiary": {
    "value": "var(--colors-brand-800)",
    "variable": "var(--colors-text-default-brand-tertiary)"
  },
  "colors.text.default.brand.onBrandPrimary": {
    "value": "var(--colors-brand-900)",
    "variable": "var(--colors-text-default-brand-on-brand-primary)"
  },
  "colors.text.default.brand.onBrandSecondary": {
    "value": "var(--colors-brand-800)",
    "variable": "var(--colors-text-default-brand-on-brand-secondary)"
  },
  "colors.text.default.brand.onBrandTertiary": {
    "value": "var(--colors-brand-700)",
    "variable": "var(--colors-text-default-brand-on-brand-tertiary)"
  },
  "colors.text.default.positive.primary": {
    "value": "var(--colors-positive-500)",
    "variable": "var(--colors-text-default-positive-primary)"
  },
  "colors.text.default.positive.secondary": {
    "value": "var(--colors-positive-700)",
    "variable": "var(--colors-text-default-positive-secondary)"
  },
  "colors.text.default.positive.tertiary": {
    "value": "var(--colors-positive-800)",
    "variable": "var(--colors-text-default-positive-tertiary)"
  },
  "colors.text.default.positive.onPositivePrimary": {
    "value": "var(--colors-positive-800)",
    "variable": "var(--colors-text-default-positive-on-positive-primary)"
  },
  "colors.text.default.positive.onPositiveSecondary": {
    "value": "var(--colors-positive-800)",
    "variable": "var(--colors-text-default-positive-on-positive-secondary)"
  },
  "colors.text.default.positive.onPositiveTertiary": {
    "value": "var(--colors-positive-800)",
    "variable": "var(--colors-text-default-positive-on-positive-tertiary)"
  },
  "colors.text.default.danger.primary": {
    "value": "var(--colors-danger-500)",
    "variable": "var(--colors-text-default-danger-primary)"
  },
  "colors.text.default.danger.secondary": {
    "value": "var(--colors-danger-600)",
    "variable": "var(--colors-text-default-danger-secondary)"
  },
  "colors.text.default.danger.tertiary": {
    "value": "var(--colors-danger-800)",
    "variable": "var(--colors-text-default-danger-tertiary)"
  },
  "colors.text.default.danger.onDangerPrimary": {
    "value": "var(--colors-neutrals-000)",
    "variable": "var(--colors-text-default-danger-on-danger-primary)"
  },
  "colors.text.default.danger.onDangerSecondary": {
    "value": "var(--colors-neutrals-000)",
    "variable": "var(--colors-text-default-danger-on-danger-secondary)"
  },
  "colors.text.default.danger.onDangerTertiary": {
    "value": "var(--colors-danger-500)",
    "variable": "var(--colors-text-default-danger-on-danger-tertiary)"
  },
  "colors.text.default.warning.primary": {
    "value": "var(--colors-warning-500)",
    "variable": "var(--colors-text-default-warning-primary)"
  },
  "colors.text.default.warning.secondary": {
    "value": "var(--colors-warning-700)",
    "variable": "var(--colors-text-default-warning-secondary)"
  },
  "colors.text.default.warning.tertiary": {
    "value": "var(--colors-warning-800)",
    "variable": "var(--colors-text-default-warning-tertiary)"
  },
  "colors.text.default.warning.onWarningPrimary": {
    "value": "var(--colors-warning-800)",
    "variable": "var(--colors-text-default-warning-on-warning-primary)"
  },
  "colors.text.default.warning.onWarningSecondary": {
    "value": "var(--colors-warning-800)",
    "variable": "var(--colors-text-default-warning-on-warning-secondary)"
  },
  "colors.text.default.warning.onWarningTertiary": {
    "value": "var(--colors-warning-800)",
    "variable": "var(--colors-text-default-warning-on-warning-tertiary)"
  },
  "colors.text.default.disable.primary": {
    "value": "var(--colors-slate-200)",
    "variable": "var(--colors-text-default-disable-primary)"
  },
  "colors.text.default.disable.onDisablePrimary": {
    "value": "var(--colors-slate-200)",
    "variable": "var(--colors-text-default-disable-on-disable-primary)"
  },
  "colors.text": {
    "value": "var(--colors-neutrals-800)",
    "variable": "var(--colors-text)"
  },
  "colors.text.weak": {
    "value": "var(--colors-neutrals-500)",
    "variable": "var(--colors-text-weak)"
  },
  "colors.text.success": {
    "value": "var(--colors-positive-700)",
    "variable": "var(--colors-text-success)"
  },
  "colors.text.danger": {
    "value": "var(--colors-danger-600)",
    "variable": "var(--colors-text-danger)"
  },
  "colors.text.warning": {
    "value": "var(--colors-warning-700)",
    "variable": "var(--colors-text-warning)"
  },
  "colors.text.info": {
    "value": "var(--colors-blue-700)",
    "variable": "var(--colors-text-info)"
  },
  "colors.icon.input.default": {
    "value": "var(--colors-slate-400)",
    "variable": "var(--colors-icon-input-default)"
  },
  "colors.icon.input.disable": {
    "value": "var(--colors-slate-300)",
    "variable": "var(--colors-icon-input-disable)"
  },
  "colors.icon.button.primary": {
    "value": "var(--colors-neutrals-000)",
    "variable": "var(--colors-icon-button-primary)"
  },
  "colors.icon.button.disable": {
    "value": "var(--colors-slate-300)",
    "variable": "var(--colors-icon-button-disable)"
  },
  "colors.icon.default.base.primary": {
    "value": "var(--colors-slate-900)",
    "variable": "var(--colors-icon-default-base-primary)"
  },
  "colors.icon.default.base.secondary": {
    "value": "var(--colors-neutrals-700)",
    "variable": "var(--colors-icon-default-base-secondary)"
  },
  "colors.icon.default.base.tertiary": {
    "value": "var(--colors-neutrals-500)",
    "variable": "var(--colors-icon-default-base-tertiary)"
  },
  "colors.icon.default.base.onBasePrimary": {
    "value": "var(--colors-slate-800)",
    "variable": "var(--colors-icon-default-base-on-base-primary)"
  },
  "colors.icon.default.base.onBaseSecondary": {
    "value": "var(--colors-slate-700)",
    "variable": "var(--colors-icon-default-base-on-base-secondary)"
  },
  "colors.icon.default.base.onBaseTertiary": {
    "value": "var(--colors-slate-800)",
    "variable": "var(--colors-icon-default-base-on-base-tertiary)"
  },
  "colors.icon.default.neutral.primary": {
    "value": "var(--colors-neutrals-1000)",
    "variable": "var(--colors-icon-default-neutral-primary)"
  },
  "colors.icon.default.neutral.secondary": {
    "value": "var(--colors-neutrals-700)",
    "variable": "var(--colors-icon-default-neutral-secondary)"
  },
  "colors.icon.default.neutral.tertiary": {
    "value": "var(--colors-neutrals-500)",
    "variable": "var(--colors-icon-default-neutral-tertiary)"
  },
  "colors.icon.default.neutral.onNeutralPrimary": {
    "value": "var(--colors-neutrals-000)",
    "variable": "var(--colors-icon-default-neutral-on-neutral-primary)"
  },
  "colors.icon.default.neutral.onNeutralSecondary": {
    "value": "var(--colors-neutrals-700)",
    "variable": "var(--colors-icon-default-neutral-on-neutral-secondary)"
  },
  "colors.icon.default.neutral.onNeutralTertiary": {
    "value": "var(--colors-neutrals-600)",
    "variable": "var(--colors-icon-default-neutral-on-neutral-tertiary)"
  },
  "colors.icon.default.brand.primary": {
    "value": "var(--colors-brand-500)",
    "variable": "var(--colors-icon-default-brand-primary)"
  },
  "colors.icon.default.brand.secondary": {
    "value": "var(--colors-brand-700)",
    "variable": "var(--colors-icon-default-brand-secondary)"
  },
  "colors.icon.default.brand.tertiary": {
    "value": "var(--colors-brand-800)",
    "variable": "var(--colors-icon-default-brand-tertiary)"
  },
  "colors.icon.default.brand.onBrandPrimary": {
    "value": "var(--colors-brand-900)",
    "variable": "var(--colors-icon-default-brand-on-brand-primary)"
  },
  "colors.icon.default.brand.onBrandSecondary": {
    "value": "var(--colors-brand-800)",
    "variable": "var(--colors-icon-default-brand-on-brand-secondary)"
  },
  "colors.icon.default.brand.onBrandTertiary": {
    "value": "var(--colors-brand-700)",
    "variable": "var(--colors-icon-default-brand-on-brand-tertiary)"
  },
  "colors.icon.default.positive.primary": {
    "value": "var(--colors-positive-500)",
    "variable": "var(--colors-icon-default-positive-primary)"
  },
  "colors.icon.default.positive.secondary": {
    "value": "var(--colors-positive-700)",
    "variable": "var(--colors-icon-default-positive-secondary)"
  },
  "colors.icon.default.positive.tertiary": {
    "value": "var(--colors-positive-800)",
    "variable": "var(--colors-icon-default-positive-tertiary)"
  },
  "colors.icon.default.positive.onPositivePrimary": {
    "value": "var(--colors-positive-800)",
    "variable": "var(--colors-icon-default-positive-on-positive-primary)"
  },
  "colors.icon.default.positive.onPositiveSecondary": {
    "value": "var(--colors-positive-800)",
    "variable": "var(--colors-icon-default-positive-on-positive-secondary)"
  },
  "colors.icon.default.positive.onPositiveTertiary": {
    "value": "var(--colors-positive-800)",
    "variable": "var(--colors-icon-default-positive-on-positive-tertiary)"
  },
  "colors.icon.default.danger.primary": {
    "value": "var(--colors-danger-500)",
    "variable": "var(--colors-icon-default-danger-primary)"
  },
  "colors.icon.default.danger.secondary": {
    "value": "var(--colors-danger-600)",
    "variable": "var(--colors-icon-default-danger-secondary)"
  },
  "colors.icon.default.danger.tertiary": {
    "value": "var(--colors-danger-800)",
    "variable": "var(--colors-icon-default-danger-tertiary)"
  },
  "colors.icon.default.danger.onDangerPrimary": {
    "value": "var(--colors-neutrals-000)",
    "variable": "var(--colors-icon-default-danger-on-danger-primary)"
  },
  "colors.icon.default.danger.onDangerSecondary": {
    "value": "var(--colors-neutrals-000)",
    "variable": "var(--colors-icon-default-danger-on-danger-secondary)"
  },
  "colors.icon.default.danger.onDangerTertiary": {
    "value": "var(--colors-danger-500)",
    "variable": "var(--colors-icon-default-danger-on-danger-tertiary)"
  },
  "colors.icon.default.warning.primary": {
    "value": "var(--colors-warning-500)",
    "variable": "var(--colors-icon-default-warning-primary)"
  },
  "colors.icon.default.warning.secondary": {
    "value": "var(--colors-warning-700)",
    "variable": "var(--colors-icon-default-warning-secondary)"
  },
  "colors.icon.default.warning.tertiary": {
    "value": "var(--colors-warning-800)",
    "variable": "var(--colors-icon-default-warning-tertiary)"
  },
  "colors.icon.default.warning.onWarningPrimary": {
    "value": "var(--colors-warning-800)",
    "variable": "var(--colors-icon-default-warning-on-warning-primary)"
  },
  "colors.icon.default.warning.onWarningSecondary": {
    "value": "var(--colors-warning-800)",
    "variable": "var(--colors-icon-default-warning-on-warning-secondary)"
  },
  "colors.icon.default.warning.onWarningTertiary": {
    "value": "var(--colors-warning-800)",
    "variable": "var(--colors-icon-default-warning-on-warning-tertiary)"
  },
  "colors.icon.default.disable.primary": {
    "value": "var(--colors-slate-200)",
    "variable": "var(--colors-icon-default-disable-primary)"
  },
  "colors.icon.default.disable.onDisablePrimary": {
    "value": "var(--colors-slate-200)",
    "variable": "var(--colors-icon-default-disable-on-disable-primary)"
  },
  "colors.focusRing.button.neutral": {
    "value": "var(--colors-slate-800)",
    "variable": "var(--colors-focus-ring-button-neutral)"
  },
  "colors.button.bg.primary": {
    "value": "var(--colors-neutrals-900)",
    "variable": "var(--colors-button-bg-primary)"
  },
  "colors.button.bg.primary.hover": {
    "value": "var(--colors-neutrals-700)",
    "variable": "var(--colors-button-bg-primary-hover)"
  },
  "colors.button.bg.primary.active": {
    "value": "var(--colors-neutrals-500)",
    "variable": "var(--colors-button-bg-primary-active)"
  },
  "colors.button.bg.primary.status.danger": {
    "value": "var(--colors-danger-500)",
    "variable": "var(--colors-button-bg-primary-status-danger)"
  },
  "colors.button.bg.primary.status.danger.hover": {
    "value": "var(--colors-danger-600)",
    "variable": "var(--colors-button-bg-primary-status-danger-hover)"
  },
  "colors.button.bg.primary.status.danger.active": {
    "value": "var(--colors-danger-700)",
    "variable": "var(--colors-button-bg-primary-status-danger-active)"
  },
  "colors.button.bg.secondary": {
    "value": "var(--colors-neutrals-000)",
    "variable": "var(--colors-button-bg-secondary)"
  },
  "colors.button.bg.secondary.hover": {
    "value": "var(--colors-neutrals-050)",
    "variable": "var(--colors-button-bg-secondary-hover)"
  },
  "colors.button.bg.secondary.active": {
    "value": "var(--colors-neutrals-100)",
    "variable": "var(--colors-button-bg-secondary-active)"
  },
  "colors.button.bg.secondary.status.danger.hover": {
    "value": "var(--colors-danger-050)",
    "variable": "var(--colors-button-bg-secondary-status-danger-hover)"
  },
  "colors.button.bg.secondary.status.danger.active": {
    "value": "var(--colors-danger-100)",
    "variable": "var(--colors-button-bg-secondary-status-danger-active)"
  },
  "colors.button.bg.tertiary": {
    "value": "var(--colors-neutrals-000)",
    "variable": "var(--colors-button-bg-tertiary)"
  },
  "colors.button.bg.tertiary.hover": {
    "value": "var(--colors-neutrals-050)",
    "variable": "var(--colors-button-bg-tertiary-hover)"
  },
  "colors.button.bg.tertiary.active": {
    "value": "var(--colors-neutrals-100)",
    "variable": "var(--colors-button-bg-tertiary-active)"
  },
  "colors.button.bg.tertiary.status.danger.hover": {
    "value": "var(--colors-danger-050)",
    "variable": "var(--colors-button-bg-tertiary-status-danger-hover)"
  },
  "colors.button.bg.tertiary.status.danger.active": {
    "value": "var(--colors-danger-100)",
    "variable": "var(--colors-button-bg-tertiary-status-danger-active)"
  },
  "colors.button.border.primary": {
    "value": "var(--colors-neutrals-900)",
    "variable": "var(--colors-button-border-primary)"
  },
  "colors.button.border.primary.hover": {
    "value": "var(--colors-neutrals-700)",
    "variable": "var(--colors-button-border-primary-hover)"
  },
  "colors.button.border.primary.active": {
    "value": "var(--colors-neutrals-500)",
    "variable": "var(--colors-button-border-primary-active)"
  },
  "colors.button.border.primary.status.danger": {
    "value": "var(--colors-danger-500)",
    "variable": "var(--colors-button-border-primary-status-danger)"
  },
  "colors.button.border.primary.status.danger.hover": {
    "value": "var(--colors-danger-600)",
    "variable": "var(--colors-button-border-primary-status-danger-hover)"
  },
  "colors.button.border.primary.status.danger.active": {
    "value": "var(--colors-danger-700)",
    "variable": "var(--colors-button-border-primary-status-danger-active)"
  },
  "colors.button.border.secondary": {
    "value": "var(--colors-neutrals-900)",
    "variable": "var(--colors-button-border-secondary)"
  },
  "colors.button.border.secondary.status.danger": {
    "value": "var(--colors-danger-600)",
    "variable": "var(--colors-button-border-secondary-status-danger)"
  },
  "colors.button.border.tertiary": {
    "value": "transparent",
    "variable": "var(--colors-button-border-tertiary)"
  },
  "colors.button.outline": {
    "value": "var(--colors-neutrals-300)",
    "variable": "var(--colors-button-outline)"
  },
  "colors.button.outline.status.danger": {
    "value": "var(--colors-danger-300)",
    "variable": "var(--colors-button-outline-status-danger)"
  },
  "colors.button.text.primary": {
    "value": "var(--colors-neutrals-000)",
    "variable": "var(--colors-button-text-primary)"
  },
  "colors.button.text.primary.status.danger": {
    "value": "var(--colors-neutrals-000)",
    "variable": "var(--colors-button-text-primary-status-danger)"
  },
  "colors.button.text.secondary": {
    "value": "var(--colors-neutrals-900)",
    "variable": "var(--colors-button-text-secondary)"
  },
  "colors.button.text.secondary.status.danger": {
    "value": "var(--colors-danger-600)",
    "variable": "var(--colors-button-text-secondary-status-danger)"
  },
  "colors.button.text.tertiary": {
    "value": "var(--colors-neutrals-900)",
    "variable": "var(--colors-button-text-tertiary)"
  },
  "colors.button.text.tertiary.status.danger": {
    "value": "var(--colors-danger-600)",
    "variable": "var(--colors-button-text-tertiary-status-danger)"
  },
  "colors.button.text.link.status.danger": {
    "value": "var(--colors-danger-600)",
    "variable": "var(--colors-button-text-link-status-danger)"
  },
  "colors.success.10": {
    "value": "var(--colors-green-10)",
    "variable": "var(--colors-success-10)"
  },
  "colors.success.20": {
    "value": "var(--colors-green-20)",
    "variable": "var(--colors-success-20)"
  },
  "colors.success.30": {
    "value": "var(--colors-green-30)",
    "variable": "var(--colors-success-30)"
  },
  "colors.success.40": {
    "value": "var(--colors-green-40)",
    "variable": "var(--colors-success-40)"
  },
  "colors.success.60": {
    "value": "var(--colors-green-60)",
    "variable": "var(--colors-success-60)"
  },
  "colors.success.70": {
    "value": "var(--colors-green-70)",
    "variable": "var(--colors-success-70)"
  },
  "colors.success.80": {
    "value": "var(--colors-green-80)",
    "variable": "var(--colors-success-80)"
  },
  "colors.success.90": {
    "value": "var(--colors-green-90)",
    "variable": "var(--colors-success-90)"
  },
  "colors.success.95": {
    "value": "var(--colors-green-95)",
    "variable": "var(--colors-success-95)"
  },
  "colors.success": {
    "value": "var(--colors-green)",
    "variable": "var(--colors-success)"
  },
  "colors.warning.10": {
    "value": "var(--colors-yellow-10)",
    "variable": "var(--colors-warning-10)"
  },
  "colors.warning.20": {
    "value": "var(--colors-yellow-20)",
    "variable": "var(--colors-warning-20)"
  },
  "colors.warning.30": {
    "value": "var(--colors-yellow-30)",
    "variable": "var(--colors-warning-30)"
  },
  "colors.warning.40": {
    "value": "var(--colors-yellow-40)",
    "variable": "var(--colors-warning-40)"
  },
  "colors.warning.60": {
    "value": "var(--colors-yellow-60)",
    "variable": "var(--colors-warning-60)"
  },
  "colors.warning.70": {
    "value": "var(--colors-yellow-70)",
    "variable": "var(--colors-warning-70)"
  },
  "colors.warning.80": {
    "value": "var(--colors-yellow-80)",
    "variable": "var(--colors-warning-80)"
  },
  "colors.warning.90": {
    "value": "var(--colors-yellow-90)",
    "variable": "var(--colors-warning-90)"
  },
  "colors.warning.95": {
    "value": "var(--colors-yellow-95)",
    "variable": "var(--colors-warning-95)"
  },
  "colors.warning": {
    "value": "var(--colors-yellow)",
    "variable": "var(--colors-warning)"
  },
  "colors.danger.10": {
    "value": "var(--colors-red-10)",
    "variable": "var(--colors-danger-10)"
  },
  "colors.danger.20": {
    "value": "var(--colors-red-20)",
    "variable": "var(--colors-danger-20)"
  },
  "colors.danger.30": {
    "value": "var(--colors-red-30)",
    "variable": "var(--colors-danger-30)"
  },
  "colors.danger.40": {
    "value": "var(--colors-red-40)",
    "variable": "var(--colors-danger-40)"
  },
  "colors.danger.60": {
    "value": "var(--colors-red-60)",
    "variable": "var(--colors-danger-60)"
  },
  "colors.danger.70": {
    "value": "var(--colors-red-70)",
    "variable": "var(--colors-danger-70)"
  },
  "colors.danger.80": {
    "value": "var(--colors-red-80)",
    "variable": "var(--colors-danger-80)"
  },
  "colors.danger.90": {
    "value": "var(--colors-red-90)",
    "variable": "var(--colors-danger-90)"
  },
  "colors.danger.95": {
    "value": "var(--colors-red-95)",
    "variable": "var(--colors-danger-95)"
  },
  "colors.danger": {
    "value": "var(--colors-red)",
    "variable": "var(--colors-danger)"
  },
  "colors.overlay.background": {
    "value": "rgba(22, 24, 29, 0.3)",
    "variable": "var(--colors-overlay-background)"
  },
  "shadows.xs": {
    "value": "var(--shadows-1)",
    "variable": "var(--shadows-xs)"
  },
  "shadows.sm": {
    "value": "var(--shadows-2)",
    "variable": "var(--shadows-sm)"
  },
  "shadows.md": {
    "value": "var(--shadows-3)",
    "variable": "var(--shadows-md)"
  },
  "shadows.lg": {
    "value": "var(--shadows-4)",
    "variable": "var(--shadows-lg)"
  },
  "shadows.xl": {
    "value": "var(--shadows-5)",
    "variable": "var(--shadows-xl)"
  },
  "shadows.2xl": {
    "value": "var(--shadows-6)",
    "variable": "var(--shadows-2xl)"
  },
  "spacing.-1": {
    "value": "calc(var(--spacing-1) * -1)",
    "variable": "var(--spacing-1)"
  },
  "spacing.-2": {
    "value": "calc(var(--spacing-2) * -1)",
    "variable": "var(--spacing-2)"
  },
  "spacing.-4": {
    "value": "calc(var(--spacing-4) * -1)",
    "variable": "var(--spacing-4)"
  },
  "spacing.-6": {
    "value": "calc(var(--spacing-6) * -1)",
    "variable": "var(--spacing-6)"
  },
  "spacing.-8": {
    "value": "calc(var(--spacing-8) * -1)",
    "variable": "var(--spacing-8)"
  },
  "spacing.-10": {
    "value": "calc(var(--spacing-10) * -1)",
    "variable": "var(--spacing-10)"
  },
  "spacing.-12": {
    "value": "calc(var(--spacing-12) * -1)",
    "variable": "var(--spacing-12)"
  },
  "spacing.-14": {
    "value": "calc(var(--spacing-14) * -1)",
    "variable": "var(--spacing-14)"
  },
  "spacing.-16": {
    "value": "calc(var(--spacing-16) * -1)",
    "variable": "var(--spacing-16)"
  },
  "spacing.-20": {
    "value": "calc(var(--spacing-20) * -1)",
    "variable": "var(--spacing-20)"
  },
  "spacing.-24": {
    "value": "calc(var(--spacing-24) * -1)",
    "variable": "var(--spacing-24)"
  },
  "spacing.-28": {
    "value": "calc(var(--spacing-28) * -1)",
    "variable": "var(--spacing-28)"
  },
  "spacing.-32": {
    "value": "calc(var(--spacing-32) * -1)",
    "variable": "var(--spacing-32)"
  },
  "spacing.-36": {
    "value": "calc(var(--spacing-36) * -1)",
    "variable": "var(--spacing-36)"
  },
  "spacing.-40": {
    "value": "calc(var(--spacing-40) * -1)",
    "variable": "var(--spacing-40)"
  },
  "spacing.-44": {
    "value": "calc(var(--spacing-44) * -1)",
    "variable": "var(--spacing-44)"
  },
  "spacing.-48": {
    "value": "calc(var(--spacing-48) * -1)",
    "variable": "var(--spacing-48)"
  },
  "spacing.-56": {
    "value": "calc(var(--spacing-56) * -1)",
    "variable": "var(--spacing-56)"
  },
  "spacing.-64": {
    "value": "calc(var(--spacing-64) * -1)",
    "variable": "var(--spacing-64)"
  },
  "spacing.-80": {
    "value": "calc(var(--spacing-80) * -1)",
    "variable": "var(--spacing-80)"
  },
  "spacing.-96": {
    "value": "calc(var(--spacing-96) * -1)",
    "variable": "var(--spacing-96)"
  },
  "spacing.-112": {
    "value": "calc(var(--spacing-112) * -1)",
    "variable": "var(--spacing-112)"
  },
  "spacing.-128": {
    "value": "calc(var(--spacing-128) * -1)",
    "variable": "var(--spacing-128)"
  },
  "spacing.-144": {
    "value": "calc(var(--spacing-144) * -1)",
    "variable": "var(--spacing-144)"
  },
  "spacing.-160": {
    "value": "calc(var(--spacing-160) * -1)",
    "variable": "var(--spacing-160)"
  },
  "spacing.-176": {
    "value": "calc(var(--spacing-176) * -1)",
    "variable": "var(--spacing-176)"
  },
  "spacing.-192": {
    "value": "calc(var(--spacing-192) * -1)",
    "variable": "var(--spacing-192)"
  },
  "spacing.-208": {
    "value": "calc(var(--spacing-208) * -1)",
    "variable": "var(--spacing-208)"
  },
  "spacing.-224": {
    "value": "calc(var(--spacing-224) * -1)",
    "variable": "var(--spacing-224)"
  },
  "spacing.-240": {
    "value": "calc(var(--spacing-240) * -1)",
    "variable": "var(--spacing-240)"
  },
  "spacing.-256": {
    "value": "calc(var(--spacing-256) * -1)",
    "variable": "var(--spacing-256)"
  },
  "spacing.-288": {
    "value": "calc(var(--spacing-288) * -1)",
    "variable": "var(--spacing-288)"
  },
  "spacing.-320": {
    "value": "calc(var(--spacing-320) * -1)",
    "variable": "var(--spacing-320)"
  },
  "spacing.-384": {
    "value": "calc(var(--spacing-384) * -1)",
    "variable": "var(--spacing-384)"
  },
  "colors.colorPalette": {
    "value": "var(--colors-color-palette)",
    "variable": "var(--colors-color-palette)"
  },
  "colors.colorPalette.100": {
    "value": "var(--colors-color-palette-100)",
    "variable": "var(--colors-color-palette-100)"
  },
  "colors.colorPalette.200": {
    "value": "var(--colors-color-palette-200)",
    "variable": "var(--colors-color-palette-200)"
  },
  "colors.colorPalette.300": {
    "value": "var(--colors-color-palette-300)",
    "variable": "var(--colors-color-palette-300)"
  },
  "colors.colorPalette.400": {
    "value": "var(--colors-color-palette-400)",
    "variable": "var(--colors-color-palette-400)"
  },
  "colors.colorPalette.500": {
    "value": "var(--colors-color-palette-500)",
    "variable": "var(--colors-color-palette-500)"
  },
  "colors.colorPalette.600": {
    "value": "var(--colors-color-palette-600)",
    "variable": "var(--colors-color-palette-600)"
  },
  "colors.colorPalette.700": {
    "value": "var(--colors-color-palette-700)",
    "variable": "var(--colors-color-palette-700)"
  },
  "colors.colorPalette.800": {
    "value": "var(--colors-color-palette-800)",
    "variable": "var(--colors-color-palette-800)"
  },
  "colors.colorPalette.900": {
    "value": "var(--colors-color-palette-900)",
    "variable": "var(--colors-color-palette-900)"
  },
  "colors.colorPalette.050": {
    "value": "var(--colors-color-palette-050)",
    "variable": "var(--colors-color-palette-050)"
  },
  "colors.colorPalette.1000": {
    "value": "var(--colors-color-palette-1000)",
    "variable": "var(--colors-color-palette-1000)"
  },
  "colors.colorPalette.000": {
    "value": "var(--colors-color-palette-000)",
    "variable": "var(--colors-color-palette-000)"
  },
  "colors.colorPalette.10": {
    "value": "var(--colors-color-palette-10)",
    "variable": "var(--colors-color-palette-10)"
  },
  "colors.colorPalette.30": {
    "value": "var(--colors-color-palette-30)",
    "variable": "var(--colors-color-palette-30)"
  },
  "colors.colorPalette.40": {
    "value": "var(--colors-color-palette-40)",
    "variable": "var(--colors-color-palette-40)"
  },
  "colors.colorPalette.50": {
    "value": "var(--colors-color-palette-50)",
    "variable": "var(--colors-color-palette-50)"
  },
  "colors.colorPalette.60": {
    "value": "var(--colors-color-palette-60)",
    "variable": "var(--colors-color-palette-60)"
  },
  "colors.colorPalette.70": {
    "value": "var(--colors-color-palette-70)",
    "variable": "var(--colors-color-palette-70)"
  },
  "colors.colorPalette.80": {
    "value": "var(--colors-color-palette-80)",
    "variable": "var(--colors-color-palette-80)"
  },
  "colors.colorPalette.90": {
    "value": "var(--colors-color-palette-90)",
    "variable": "var(--colors-color-palette-90)"
  },
  "colors.colorPalette.95": {
    "value": "var(--colors-color-palette-95)",
    "variable": "var(--colors-color-palette-95)"
  },
  "colors.colorPalette.20": {
    "value": "var(--colors-color-palette-20)",
    "variable": "var(--colors-color-palette-20)"
  },
  "colors.colorPalette.dark.100": {
    "value": "var(--colors-color-palette-dark-100)",
    "variable": "var(--colors-color-palette-dark-100)"
  },
  "colors.colorPalette.dark.200": {
    "value": "var(--colors-color-palette-dark-200)",
    "variable": "var(--colors-color-palette-dark-200)"
  },
  "colors.colorPalette.dark.300": {
    "value": "var(--colors-color-palette-dark-300)",
    "variable": "var(--colors-color-palette-dark-300)"
  },
  "colors.colorPalette.dark.400": {
    "value": "var(--colors-color-palette-dark-400)",
    "variable": "var(--colors-color-palette-dark-400)"
  },
  "colors.colorPalette.dark.500": {
    "value": "var(--colors-color-palette-dark-500)",
    "variable": "var(--colors-color-palette-dark-500)"
  },
  "colors.colorPalette.dark.600": {
    "value": "var(--colors-color-palette-dark-600)",
    "variable": "var(--colors-color-palette-dark-600)"
  },
  "colors.colorPalette.dark.700": {
    "value": "var(--colors-color-palette-dark-700)",
    "variable": "var(--colors-color-palette-dark-700)"
  },
  "colors.colorPalette.dark.800": {
    "value": "var(--colors-color-palette-dark-800)",
    "variable": "var(--colors-color-palette-dark-800)"
  },
  "colors.colorPalette.dark.900": {
    "value": "var(--colors-color-palette-dark-900)",
    "variable": "var(--colors-color-palette-dark-900)"
  },
  "colors.colorPalette.dark.050": {
    "value": "var(--colors-color-palette-dark-050)",
    "variable": "var(--colors-color-palette-dark-050)"
  },
  "colors.colorPalette.light.100": {
    "value": "var(--colors-color-palette-light-100)",
    "variable": "var(--colors-color-palette-light-100)"
  },
  "colors.colorPalette.light.200": {
    "value": "var(--colors-color-palette-light-200)",
    "variable": "var(--colors-color-palette-light-200)"
  },
  "colors.colorPalette.light.300": {
    "value": "var(--colors-color-palette-light-300)",
    "variable": "var(--colors-color-palette-light-300)"
  },
  "colors.colorPalette.light.400": {
    "value": "var(--colors-color-palette-light-400)",
    "variable": "var(--colors-color-palette-light-400)"
  },
  "colors.colorPalette.light.500": {
    "value": "var(--colors-color-palette-light-500)",
    "variable": "var(--colors-color-palette-light-500)"
  },
  "colors.colorPalette.light.600": {
    "value": "var(--colors-color-palette-light-600)",
    "variable": "var(--colors-color-palette-light-600)"
  },
  "colors.colorPalette.light.700": {
    "value": "var(--colors-color-palette-light-700)",
    "variable": "var(--colors-color-palette-light-700)"
  },
  "colors.colorPalette.light.800": {
    "value": "var(--colors-color-palette-light-800)",
    "variable": "var(--colors-color-palette-light-800)"
  },
  "colors.colorPalette.light.900": {
    "value": "var(--colors-color-palette-light-900)",
    "variable": "var(--colors-color-palette-light-900)"
  },
  "colors.colorPalette.light.050": {
    "value": "var(--colors-color-palette-light-050)",
    "variable": "var(--colors-color-palette-light-050)"
  },
  "colors.colorPalette.default.base.primary": {
    "value": "var(--colors-color-palette-default-base-primary)",
    "variable": "var(--colors-color-palette-default-base-primary)"
  },
  "colors.colorPalette.base.primary": {
    "value": "var(--colors-color-palette-base-primary)",
    "variable": "var(--colors-color-palette-base-primary)"
  },
  "colors.colorPalette.primary": {
    "value": "var(--colors-color-palette-primary)",
    "variable": "var(--colors-color-palette-primary)"
  },
  "colors.colorPalette.default.base.primaryHover": {
    "value": "var(--colors-color-palette-default-base-primary-hover)",
    "variable": "var(--colors-color-palette-default-base-primary-hover)"
  },
  "colors.colorPalette.base.primaryHover": {
    "value": "var(--colors-color-palette-base-primary-hover)",
    "variable": "var(--colors-color-palette-base-primary-hover)"
  },
  "colors.colorPalette.primaryHover": {
    "value": "var(--colors-color-palette-primary-hover)",
    "variable": "var(--colors-color-palette-primary-hover)"
  },
  "colors.colorPalette.default.base.secondary": {
    "value": "var(--colors-color-palette-default-base-secondary)",
    "variable": "var(--colors-color-palette-default-base-secondary)"
  },
  "colors.colorPalette.base.secondary": {
    "value": "var(--colors-color-palette-base-secondary)",
    "variable": "var(--colors-color-palette-base-secondary)"
  },
  "colors.colorPalette.secondary": {
    "value": "var(--colors-color-palette-secondary)",
    "variable": "var(--colors-color-palette-secondary)"
  },
  "colors.colorPalette.default.base.secondaryHover": {
    "value": "var(--colors-color-palette-default-base-secondary-hover)",
    "variable": "var(--colors-color-palette-default-base-secondary-hover)"
  },
  "colors.colorPalette.base.secondaryHover": {
    "value": "var(--colors-color-palette-base-secondary-hover)",
    "variable": "var(--colors-color-palette-base-secondary-hover)"
  },
  "colors.colorPalette.secondaryHover": {
    "value": "var(--colors-color-palette-secondary-hover)",
    "variable": "var(--colors-color-palette-secondary-hover)"
  },
  "colors.colorPalette.default.base.tertiary": {
    "value": "var(--colors-color-palette-default-base-tertiary)",
    "variable": "var(--colors-color-palette-default-base-tertiary)"
  },
  "colors.colorPalette.base.tertiary": {
    "value": "var(--colors-color-palette-base-tertiary)",
    "variable": "var(--colors-color-palette-base-tertiary)"
  },
  "colors.colorPalette.tertiary": {
    "value": "var(--colors-color-palette-tertiary)",
    "variable": "var(--colors-color-palette-tertiary)"
  },
  "colors.colorPalette.default.base.tertiaryHover": {
    "value": "var(--colors-color-palette-default-base-tertiary-hover)",
    "variable": "var(--colors-color-palette-default-base-tertiary-hover)"
  },
  "colors.colorPalette.base.tertiaryHover": {
    "value": "var(--colors-color-palette-base-tertiary-hover)",
    "variable": "var(--colors-color-palette-base-tertiary-hover)"
  },
  "colors.colorPalette.tertiaryHover": {
    "value": "var(--colors-color-palette-tertiary-hover)",
    "variable": "var(--colors-color-palette-tertiary-hover)"
  },
  "colors.colorPalette.default.neutral.primary": {
    "value": "var(--colors-color-palette-default-neutral-primary)",
    "variable": "var(--colors-color-palette-default-neutral-primary)"
  },
  "colors.colorPalette.neutral.primary": {
    "value": "var(--colors-color-palette-neutral-primary)",
    "variable": "var(--colors-color-palette-neutral-primary)"
  },
  "colors.colorPalette.default.neutral.primaryHover": {
    "value": "var(--colors-color-palette-default-neutral-primary-hover)",
    "variable": "var(--colors-color-palette-default-neutral-primary-hover)"
  },
  "colors.colorPalette.neutral.primaryHover": {
    "value": "var(--colors-color-palette-neutral-primary-hover)",
    "variable": "var(--colors-color-palette-neutral-primary-hover)"
  },
  "colors.colorPalette.default.neutral.secondary": {
    "value": "var(--colors-color-palette-default-neutral-secondary)",
    "variable": "var(--colors-color-palette-default-neutral-secondary)"
  },
  "colors.colorPalette.neutral.secondary": {
    "value": "var(--colors-color-palette-neutral-secondary)",
    "variable": "var(--colors-color-palette-neutral-secondary)"
  },
  "colors.colorPalette.default.neutral.secondaryHover": {
    "value": "var(--colors-color-palette-default-neutral-secondary-hover)",
    "variable": "var(--colors-color-palette-default-neutral-secondary-hover)"
  },
  "colors.colorPalette.neutral.secondaryHover": {
    "value": "var(--colors-color-palette-neutral-secondary-hover)",
    "variable": "var(--colors-color-palette-neutral-secondary-hover)"
  },
  "colors.colorPalette.default.neutral.tertiary": {
    "value": "var(--colors-color-palette-default-neutral-tertiary)",
    "variable": "var(--colors-color-palette-default-neutral-tertiary)"
  },
  "colors.colorPalette.neutral.tertiary": {
    "value": "var(--colors-color-palette-neutral-tertiary)",
    "variable": "var(--colors-color-palette-neutral-tertiary)"
  },
  "colors.colorPalette.default.neutral.tertiaryHover": {
    "value": "var(--colors-color-palette-default-neutral-tertiary-hover)",
    "variable": "var(--colors-color-palette-default-neutral-tertiary-hover)"
  },
  "colors.colorPalette.neutral.tertiaryHover": {
    "value": "var(--colors-color-palette-neutral-tertiary-hover)",
    "variable": "var(--colors-color-palette-neutral-tertiary-hover)"
  },
  "colors.colorPalette.default.brand.primary": {
    "value": "var(--colors-color-palette-default-brand-primary)",
    "variable": "var(--colors-color-palette-default-brand-primary)"
  },
  "colors.colorPalette.brand.primary": {
    "value": "var(--colors-color-palette-brand-primary)",
    "variable": "var(--colors-color-palette-brand-primary)"
  },
  "colors.colorPalette.default.brand.primaryHover": {
    "value": "var(--colors-color-palette-default-brand-primary-hover)",
    "variable": "var(--colors-color-palette-default-brand-primary-hover)"
  },
  "colors.colorPalette.brand.primaryHover": {
    "value": "var(--colors-color-palette-brand-primary-hover)",
    "variable": "var(--colors-color-palette-brand-primary-hover)"
  },
  "colors.colorPalette.default.brand.secondary": {
    "value": "var(--colors-color-palette-default-brand-secondary)",
    "variable": "var(--colors-color-palette-default-brand-secondary)"
  },
  "colors.colorPalette.brand.secondary": {
    "value": "var(--colors-color-palette-brand-secondary)",
    "variable": "var(--colors-color-palette-brand-secondary)"
  },
  "colors.colorPalette.default.brand.secondaryHover": {
    "value": "var(--colors-color-palette-default-brand-secondary-hover)",
    "variable": "var(--colors-color-palette-default-brand-secondary-hover)"
  },
  "colors.colorPalette.brand.secondaryHover": {
    "value": "var(--colors-color-palette-brand-secondary-hover)",
    "variable": "var(--colors-color-palette-brand-secondary-hover)"
  },
  "colors.colorPalette.default.brand.tertiary": {
    "value": "var(--colors-color-palette-default-brand-tertiary)",
    "variable": "var(--colors-color-palette-default-brand-tertiary)"
  },
  "colors.colorPalette.brand.tertiary": {
    "value": "var(--colors-color-palette-brand-tertiary)",
    "variable": "var(--colors-color-palette-brand-tertiary)"
  },
  "colors.colorPalette.default.brand.tertiaryHover": {
    "value": "var(--colors-color-palette-default-brand-tertiary-hover)",
    "variable": "var(--colors-color-palette-default-brand-tertiary-hover)"
  },
  "colors.colorPalette.brand.tertiaryHover": {
    "value": "var(--colors-color-palette-brand-tertiary-hover)",
    "variable": "var(--colors-color-palette-brand-tertiary-hover)"
  },
  "colors.colorPalette.default.positive.primary": {
    "value": "var(--colors-color-palette-default-positive-primary)",
    "variable": "var(--colors-color-palette-default-positive-primary)"
  },
  "colors.colorPalette.positive.primary": {
    "value": "var(--colors-color-palette-positive-primary)",
    "variable": "var(--colors-color-palette-positive-primary)"
  },
  "colors.colorPalette.default.positive.primaryHover": {
    "value": "var(--colors-color-palette-default-positive-primary-hover)",
    "variable": "var(--colors-color-palette-default-positive-primary-hover)"
  },
  "colors.colorPalette.positive.primaryHover": {
    "value": "var(--colors-color-palette-positive-primary-hover)",
    "variable": "var(--colors-color-palette-positive-primary-hover)"
  },
  "colors.colorPalette.default.positive.secondary": {
    "value": "var(--colors-color-palette-default-positive-secondary)",
    "variable": "var(--colors-color-palette-default-positive-secondary)"
  },
  "colors.colorPalette.positive.secondary": {
    "value": "var(--colors-color-palette-positive-secondary)",
    "variable": "var(--colors-color-palette-positive-secondary)"
  },
  "colors.colorPalette.default.positive.secondaryHover": {
    "value": "var(--colors-color-palette-default-positive-secondary-hover)",
    "variable": "var(--colors-color-palette-default-positive-secondary-hover)"
  },
  "colors.colorPalette.positive.secondaryHover": {
    "value": "var(--colors-color-palette-positive-secondary-hover)",
    "variable": "var(--colors-color-palette-positive-secondary-hover)"
  },
  "colors.colorPalette.default.positive.tertiary": {
    "value": "var(--colors-color-palette-default-positive-tertiary)",
    "variable": "var(--colors-color-palette-default-positive-tertiary)"
  },
  "colors.colorPalette.positive.tertiary": {
    "value": "var(--colors-color-palette-positive-tertiary)",
    "variable": "var(--colors-color-palette-positive-tertiary)"
  },
  "colors.colorPalette.default.positive.tertiaryHover": {
    "value": "var(--colors-color-palette-default-positive-tertiary-hover)",
    "variable": "var(--colors-color-palette-default-positive-tertiary-hover)"
  },
  "colors.colorPalette.positive.tertiaryHover": {
    "value": "var(--colors-color-palette-positive-tertiary-hover)",
    "variable": "var(--colors-color-palette-positive-tertiary-hover)"
  },
  "colors.colorPalette.default.danger.primary": {
    "value": "var(--colors-color-palette-default-danger-primary)",
    "variable": "var(--colors-color-palette-default-danger-primary)"
  },
  "colors.colorPalette.danger.primary": {
    "value": "var(--colors-color-palette-danger-primary)",
    "variable": "var(--colors-color-palette-danger-primary)"
  },
  "colors.colorPalette.default.danger.primaryHover": {
    "value": "var(--colors-color-palette-default-danger-primary-hover)",
    "variable": "var(--colors-color-palette-default-danger-primary-hover)"
  },
  "colors.colorPalette.danger.primaryHover": {
    "value": "var(--colors-color-palette-danger-primary-hover)",
    "variable": "var(--colors-color-palette-danger-primary-hover)"
  },
  "colors.colorPalette.default.danger.secondary": {
    "value": "var(--colors-color-palette-default-danger-secondary)",
    "variable": "var(--colors-color-palette-default-danger-secondary)"
  },
  "colors.colorPalette.danger.secondary": {
    "value": "var(--colors-color-palette-danger-secondary)",
    "variable": "var(--colors-color-palette-danger-secondary)"
  },
  "colors.colorPalette.default.danger.secondaryHover": {
    "value": "var(--colors-color-palette-default-danger-secondary-hover)",
    "variable": "var(--colors-color-palette-default-danger-secondary-hover)"
  },
  "colors.colorPalette.danger.secondaryHover": {
    "value": "var(--colors-color-palette-danger-secondary-hover)",
    "variable": "var(--colors-color-palette-danger-secondary-hover)"
  },
  "colors.colorPalette.default.danger.tertiary": {
    "value": "var(--colors-color-palette-default-danger-tertiary)",
    "variable": "var(--colors-color-palette-default-danger-tertiary)"
  },
  "colors.colorPalette.danger.tertiary": {
    "value": "var(--colors-color-palette-danger-tertiary)",
    "variable": "var(--colors-color-palette-danger-tertiary)"
  },
  "colors.colorPalette.default.danger.tertiaryHover": {
    "value": "var(--colors-color-palette-default-danger-tertiary-hover)",
    "variable": "var(--colors-color-palette-default-danger-tertiary-hover)"
  },
  "colors.colorPalette.danger.tertiaryHover": {
    "value": "var(--colors-color-palette-danger-tertiary-hover)",
    "variable": "var(--colors-color-palette-danger-tertiary-hover)"
  },
  "colors.colorPalette.default.warning.primary": {
    "value": "var(--colors-color-palette-default-warning-primary)",
    "variable": "var(--colors-color-palette-default-warning-primary)"
  },
  "colors.colorPalette.warning.primary": {
    "value": "var(--colors-color-palette-warning-primary)",
    "variable": "var(--colors-color-palette-warning-primary)"
  },
  "colors.colorPalette.default.warning.primaryHover": {
    "value": "var(--colors-color-palette-default-warning-primary-hover)",
    "variable": "var(--colors-color-palette-default-warning-primary-hover)"
  },
  "colors.colorPalette.warning.primaryHover": {
    "value": "var(--colors-color-palette-warning-primary-hover)",
    "variable": "var(--colors-color-palette-warning-primary-hover)"
  },
  "colors.colorPalette.default.warning.secondary": {
    "value": "var(--colors-color-palette-default-warning-secondary)",
    "variable": "var(--colors-color-palette-default-warning-secondary)"
  },
  "colors.colorPalette.warning.secondary": {
    "value": "var(--colors-color-palette-warning-secondary)",
    "variable": "var(--colors-color-palette-warning-secondary)"
  },
  "colors.colorPalette.default.warning.secondaryHover": {
    "value": "var(--colors-color-palette-default-warning-secondary-hover)",
    "variable": "var(--colors-color-palette-default-warning-secondary-hover)"
  },
  "colors.colorPalette.warning.secondaryHover": {
    "value": "var(--colors-color-palette-warning-secondary-hover)",
    "variable": "var(--colors-color-palette-warning-secondary-hover)"
  },
  "colors.colorPalette.default.warning.tertiary": {
    "value": "var(--colors-color-palette-default-warning-tertiary)",
    "variable": "var(--colors-color-palette-default-warning-tertiary)"
  },
  "colors.colorPalette.warning.tertiary": {
    "value": "var(--colors-color-palette-warning-tertiary)",
    "variable": "var(--colors-color-palette-warning-tertiary)"
  },
  "colors.colorPalette.default.warning.tertiaryHover": {
    "value": "var(--colors-color-palette-default-warning-tertiary-hover)",
    "variable": "var(--colors-color-palette-default-warning-tertiary-hover)"
  },
  "colors.colorPalette.warning.tertiaryHover": {
    "value": "var(--colors-color-palette-warning-tertiary-hover)",
    "variable": "var(--colors-color-palette-warning-tertiary-hover)"
  },
  "colors.colorPalette.default.disable.primary": {
    "value": "var(--colors-color-palette-default-disable-primary)",
    "variable": "var(--colors-color-palette-default-disable-primary)"
  },
  "colors.colorPalette.disable.primary": {
    "value": "var(--colors-color-palette-disable-primary)",
    "variable": "var(--colors-color-palette-disable-primary)"
  },
  "colors.colorPalette.input.normal": {
    "value": "var(--colors-color-palette-input-normal)",
    "variable": "var(--colors-color-palette-input-normal)"
  },
  "colors.colorPalette.normal": {
    "value": "var(--colors-color-palette-normal)",
    "variable": "var(--colors-color-palette-normal)"
  },
  "colors.colorPalette.input.disable": {
    "value": "var(--colors-color-palette-input-disable)",
    "variable": "var(--colors-color-palette-input-disable)"
  },
  "colors.colorPalette.disable": {
    "value": "var(--colors-color-palette-disable)",
    "variable": "var(--colors-color-palette-disable)"
  },
  "colors.colorPalette.button.primaryDefault": {
    "value": "var(--colors-color-palette-button-primary-default)",
    "variable": "var(--colors-color-palette-button-primary-default)"
  },
  "colors.colorPalette.primaryDefault": {
    "value": "var(--colors-color-palette-primary-default)",
    "variable": "var(--colors-color-palette-primary-default)"
  },
  "colors.colorPalette.button.primaryHover": {
    "value": "var(--colors-color-palette-button-primary-hover)",
    "variable": "var(--colors-color-palette-button-primary-hover)"
  },
  "colors.colorPalette.button.primaryDisable": {
    "value": "var(--colors-color-palette-button-primary-disable)",
    "variable": "var(--colors-color-palette-button-primary-disable)"
  },
  "colors.colorPalette.primaryDisable": {
    "value": "var(--colors-color-palette-primary-disable)",
    "variable": "var(--colors-color-palette-primary-disable)"
  },
  "colors.colorPalette.button.primaryDestructiveNormal": {
    "value": "var(--colors-color-palette-button-primary-destructive-normal)",
    "variable": "var(--colors-color-palette-button-primary-destructive-normal)"
  },
  "colors.colorPalette.primaryDestructiveNormal": {
    "value": "var(--colors-color-palette-primary-destructive-normal)",
    "variable": "var(--colors-color-palette-primary-destructive-normal)"
  },
  "colors.colorPalette.button.primaryDestructiveHover": {
    "value": "var(--colors-color-palette-button-primary-destructive-hover)",
    "variable": "var(--colors-color-palette-button-primary-destructive-hover)"
  },
  "colors.colorPalette.primaryDestructiveHover": {
    "value": "var(--colors-color-palette-primary-destructive-hover)",
    "variable": "var(--colors-color-palette-primary-destructive-hover)"
  },
  "colors.colorPalette.button.primaryDestructiveDisable": {
    "value": "var(--colors-color-palette-button-primary-destructive-disable)",
    "variable": "var(--colors-color-palette-button-primary-destructive-disable)"
  },
  "colors.colorPalette.primaryDestructiveDisable": {
    "value": "var(--colors-color-palette-primary-destructive-disable)",
    "variable": "var(--colors-color-palette-primary-destructive-disable)"
  },
  "colors.colorPalette.button.primaryNeutralDefault": {
    "value": "var(--colors-color-palette-button-primary-neutral-default)",
    "variable": "var(--colors-color-palette-button-primary-neutral-default)"
  },
  "colors.colorPalette.primaryNeutralDefault": {
    "value": "var(--colors-color-palette-primary-neutral-default)",
    "variable": "var(--colors-color-palette-primary-neutral-default)"
  },
  "colors.colorPalette.button.primaryNeutralHover": {
    "value": "var(--colors-color-palette-button-primary-neutral-hover)",
    "variable": "var(--colors-color-palette-button-primary-neutral-hover)"
  },
  "colors.colorPalette.primaryNeutralHover": {
    "value": "var(--colors-color-palette-primary-neutral-hover)",
    "variable": "var(--colors-color-palette-primary-neutral-hover)"
  },
  "colors.colorPalette.button.secondaryOutlineDefault": {
    "value": "var(--colors-color-palette-button-secondary-outline-default)",
    "variable": "var(--colors-color-palette-button-secondary-outline-default)"
  },
  "colors.colorPalette.secondaryOutlineDefault": {
    "value": "var(--colors-color-palette-secondary-outline-default)",
    "variable": "var(--colors-color-palette-secondary-outline-default)"
  },
  "colors.colorPalette.button.secondaryOutlineHover": {
    "value": "var(--colors-color-palette-button-secondary-outline-hover)",
    "variable": "var(--colors-color-palette-button-secondary-outline-hover)"
  },
  "colors.colorPalette.secondaryOutlineHover": {
    "value": "var(--colors-color-palette-secondary-outline-hover)",
    "variable": "var(--colors-color-palette-secondary-outline-hover)"
  },
  "colors.colorPalette.input.default": {
    "value": "var(--colors-color-palette-input-default)",
    "variable": "var(--colors-color-palette-input-default)"
  },
  "colors.colorPalette.default": {
    "value": "var(--colors-color-palette-default)",
    "variable": "var(--colors-color-palette-default)"
  },
  "colors.colorPalette.input.hover": {
    "value": "var(--colors-color-palette-input-hover)",
    "variable": "var(--colors-color-palette-input-hover)"
  },
  "colors.colorPalette.hover": {
    "value": "var(--colors-color-palette-hover)",
    "variable": "var(--colors-color-palette-hover)"
  },
  "colors.colorPalette.input.focus": {
    "value": "var(--colors-color-palette-input-focus)",
    "variable": "var(--colors-color-palette-input-focus)"
  },
  "colors.colorPalette.focus": {
    "value": "var(--colors-color-palette-focus)",
    "variable": "var(--colors-color-palette-focus)"
  },
  "colors.colorPalette.input.destructive": {
    "value": "var(--colors-color-palette-input-destructive)",
    "variable": "var(--colors-color-palette-input-destructive)"
  },
  "colors.colorPalette.destructive": {
    "value": "var(--colors-color-palette-destructive)",
    "variable": "var(--colors-color-palette-destructive)"
  },
  "colors.colorPalette.button.secondaryOutlineDisable": {
    "value": "var(--colors-color-palette-button-secondary-outline-disable)",
    "variable": "var(--colors-color-palette-button-secondary-outline-disable)"
  },
  "colors.colorPalette.secondaryOutlineDisable": {
    "value": "var(--colors-color-palette-secondary-outline-disable)",
    "variable": "var(--colors-color-palette-secondary-outline-disable)"
  },
  "colors.colorPalette.input.placeholder": {
    "value": "var(--colors-color-palette-input-placeholder)",
    "variable": "var(--colors-color-palette-input-placeholder)"
  },
  "colors.colorPalette.placeholder": {
    "value": "var(--colors-color-palette-placeholder)",
    "variable": "var(--colors-color-palette-placeholder)"
  },
  "colors.colorPalette.button.primary": {
    "value": "var(--colors-color-palette-button-primary)",
    "variable": "var(--colors-color-palette-button-primary)"
  },
  "colors.colorPalette.button.disable": {
    "value": "var(--colors-color-palette-button-disable)",
    "variable": "var(--colors-color-palette-button-disable)"
  },
  "colors.colorPalette.default.base.onBasePrimary": {
    "value": "var(--colors-color-palette-default-base-on-base-primary)",
    "variable": "var(--colors-color-palette-default-base-on-base-primary)"
  },
  "colors.colorPalette.base.onBasePrimary": {
    "value": "var(--colors-color-palette-base-on-base-primary)",
    "variable": "var(--colors-color-palette-base-on-base-primary)"
  },
  "colors.colorPalette.onBasePrimary": {
    "value": "var(--colors-color-palette-on-base-primary)",
    "variable": "var(--colors-color-palette-on-base-primary)"
  },
  "colors.colorPalette.default.base.onBaseSecondary": {
    "value": "var(--colors-color-palette-default-base-on-base-secondary)",
    "variable": "var(--colors-color-palette-default-base-on-base-secondary)"
  },
  "colors.colorPalette.base.onBaseSecondary": {
    "value": "var(--colors-color-palette-base-on-base-secondary)",
    "variable": "var(--colors-color-palette-base-on-base-secondary)"
  },
  "colors.colorPalette.onBaseSecondary": {
    "value": "var(--colors-color-palette-on-base-secondary)",
    "variable": "var(--colors-color-palette-on-base-secondary)"
  },
  "colors.colorPalette.default.base.onBaseTertiary": {
    "value": "var(--colors-color-palette-default-base-on-base-tertiary)",
    "variable": "var(--colors-color-palette-default-base-on-base-tertiary)"
  },
  "colors.colorPalette.base.onBaseTertiary": {
    "value": "var(--colors-color-palette-base-on-base-tertiary)",
    "variable": "var(--colors-color-palette-base-on-base-tertiary)"
  },
  "colors.colorPalette.onBaseTertiary": {
    "value": "var(--colors-color-palette-on-base-tertiary)",
    "variable": "var(--colors-color-palette-on-base-tertiary)"
  },
  "colors.colorPalette.default.neutral.onNeutralPrimary": {
    "value": "var(--colors-color-palette-default-neutral-on-neutral-primary)",
    "variable": "var(--colors-color-palette-default-neutral-on-neutral-primary)"
  },
  "colors.colorPalette.neutral.onNeutralPrimary": {
    "value": "var(--colors-color-palette-neutral-on-neutral-primary)",
    "variable": "var(--colors-color-palette-neutral-on-neutral-primary)"
  },
  "colors.colorPalette.onNeutralPrimary": {
    "value": "var(--colors-color-palette-on-neutral-primary)",
    "variable": "var(--colors-color-palette-on-neutral-primary)"
  },
  "colors.colorPalette.default.neutral.onNeutralSecondary": {
    "value": "var(--colors-color-palette-default-neutral-on-neutral-secondary)",
    "variable": "var(--colors-color-palette-default-neutral-on-neutral-secondary)"
  },
  "colors.colorPalette.neutral.onNeutralSecondary": {
    "value": "var(--colors-color-palette-neutral-on-neutral-secondary)",
    "variable": "var(--colors-color-palette-neutral-on-neutral-secondary)"
  },
  "colors.colorPalette.onNeutralSecondary": {
    "value": "var(--colors-color-palette-on-neutral-secondary)",
    "variable": "var(--colors-color-palette-on-neutral-secondary)"
  },
  "colors.colorPalette.default.neutral.onNeutralTertiary": {
    "value": "var(--colors-color-palette-default-neutral-on-neutral-tertiary)",
    "variable": "var(--colors-color-palette-default-neutral-on-neutral-tertiary)"
  },
  "colors.colorPalette.neutral.onNeutralTertiary": {
    "value": "var(--colors-color-palette-neutral-on-neutral-tertiary)",
    "variable": "var(--colors-color-palette-neutral-on-neutral-tertiary)"
  },
  "colors.colorPalette.onNeutralTertiary": {
    "value": "var(--colors-color-palette-on-neutral-tertiary)",
    "variable": "var(--colors-color-palette-on-neutral-tertiary)"
  },
  "colors.colorPalette.default.brand.onBrandPrimary": {
    "value": "var(--colors-color-palette-default-brand-on-brand-primary)",
    "variable": "var(--colors-color-palette-default-brand-on-brand-primary)"
  },
  "colors.colorPalette.brand.onBrandPrimary": {
    "value": "var(--colors-color-palette-brand-on-brand-primary)",
    "variable": "var(--colors-color-palette-brand-on-brand-primary)"
  },
  "colors.colorPalette.onBrandPrimary": {
    "value": "var(--colors-color-palette-on-brand-primary)",
    "variable": "var(--colors-color-palette-on-brand-primary)"
  },
  "colors.colorPalette.default.brand.onBrandSecondary": {
    "value": "var(--colors-color-palette-default-brand-on-brand-secondary)",
    "variable": "var(--colors-color-palette-default-brand-on-brand-secondary)"
  },
  "colors.colorPalette.brand.onBrandSecondary": {
    "value": "var(--colors-color-palette-brand-on-brand-secondary)",
    "variable": "var(--colors-color-palette-brand-on-brand-secondary)"
  },
  "colors.colorPalette.onBrandSecondary": {
    "value": "var(--colors-color-palette-on-brand-secondary)",
    "variable": "var(--colors-color-palette-on-brand-secondary)"
  },
  "colors.colorPalette.default.brand.onBrandTertiary": {
    "value": "var(--colors-color-palette-default-brand-on-brand-tertiary)",
    "variable": "var(--colors-color-palette-default-brand-on-brand-tertiary)"
  },
  "colors.colorPalette.brand.onBrandTertiary": {
    "value": "var(--colors-color-palette-brand-on-brand-tertiary)",
    "variable": "var(--colors-color-palette-brand-on-brand-tertiary)"
  },
  "colors.colorPalette.onBrandTertiary": {
    "value": "var(--colors-color-palette-on-brand-tertiary)",
    "variable": "var(--colors-color-palette-on-brand-tertiary)"
  },
  "colors.colorPalette.default.positive.onPositivePrimary": {
    "value": "var(--colors-color-palette-default-positive-on-positive-primary)",
    "variable": "var(--colors-color-palette-default-positive-on-positive-primary)"
  },
  "colors.colorPalette.positive.onPositivePrimary": {
    "value": "var(--colors-color-palette-positive-on-positive-primary)",
    "variable": "var(--colors-color-palette-positive-on-positive-primary)"
  },
  "colors.colorPalette.onPositivePrimary": {
    "value": "var(--colors-color-palette-on-positive-primary)",
    "variable": "var(--colors-color-palette-on-positive-primary)"
  },
  "colors.colorPalette.default.positive.onPositiveSecondary": {
    "value": "var(--colors-color-palette-default-positive-on-positive-secondary)",
    "variable": "var(--colors-color-palette-default-positive-on-positive-secondary)"
  },
  "colors.colorPalette.positive.onPositiveSecondary": {
    "value": "var(--colors-color-palette-positive-on-positive-secondary)",
    "variable": "var(--colors-color-palette-positive-on-positive-secondary)"
  },
  "colors.colorPalette.onPositiveSecondary": {
    "value": "var(--colors-color-palette-on-positive-secondary)",
    "variable": "var(--colors-color-palette-on-positive-secondary)"
  },
  "colors.colorPalette.default.positive.onPositiveTertiary": {
    "value": "var(--colors-color-palette-default-positive-on-positive-tertiary)",
    "variable": "var(--colors-color-palette-default-positive-on-positive-tertiary)"
  },
  "colors.colorPalette.positive.onPositiveTertiary": {
    "value": "var(--colors-color-palette-positive-on-positive-tertiary)",
    "variable": "var(--colors-color-palette-positive-on-positive-tertiary)"
  },
  "colors.colorPalette.onPositiveTertiary": {
    "value": "var(--colors-color-palette-on-positive-tertiary)",
    "variable": "var(--colors-color-palette-on-positive-tertiary)"
  },
  "colors.colorPalette.default.danger.onDangerPrimary": {
    "value": "var(--colors-color-palette-default-danger-on-danger-primary)",
    "variable": "var(--colors-color-palette-default-danger-on-danger-primary)"
  },
  "colors.colorPalette.danger.onDangerPrimary": {
    "value": "var(--colors-color-palette-danger-on-danger-primary)",
    "variable": "var(--colors-color-palette-danger-on-danger-primary)"
  },
  "colors.colorPalette.onDangerPrimary": {
    "value": "var(--colors-color-palette-on-danger-primary)",
    "variable": "var(--colors-color-palette-on-danger-primary)"
  },
  "colors.colorPalette.default.danger.onDangerSecondary": {
    "value": "var(--colors-color-palette-default-danger-on-danger-secondary)",
    "variable": "var(--colors-color-palette-default-danger-on-danger-secondary)"
  },
  "colors.colorPalette.danger.onDangerSecondary": {
    "value": "var(--colors-color-palette-danger-on-danger-secondary)",
    "variable": "var(--colors-color-palette-danger-on-danger-secondary)"
  },
  "colors.colorPalette.onDangerSecondary": {
    "value": "var(--colors-color-palette-on-danger-secondary)",
    "variable": "var(--colors-color-palette-on-danger-secondary)"
  },
  "colors.colorPalette.default.danger.onDangerTertiary": {
    "value": "var(--colors-color-palette-default-danger-on-danger-tertiary)",
    "variable": "var(--colors-color-palette-default-danger-on-danger-tertiary)"
  },
  "colors.colorPalette.danger.onDangerTertiary": {
    "value": "var(--colors-color-palette-danger-on-danger-tertiary)",
    "variable": "var(--colors-color-palette-danger-on-danger-tertiary)"
  },
  "colors.colorPalette.onDangerTertiary": {
    "value": "var(--colors-color-palette-on-danger-tertiary)",
    "variable": "var(--colors-color-palette-on-danger-tertiary)"
  },
  "colors.colorPalette.default.warning.onWarningPrimary": {
    "value": "var(--colors-color-palette-default-warning-on-warning-primary)",
    "variable": "var(--colors-color-palette-default-warning-on-warning-primary)"
  },
  "colors.colorPalette.warning.onWarningPrimary": {
    "value": "var(--colors-color-palette-warning-on-warning-primary)",
    "variable": "var(--colors-color-palette-warning-on-warning-primary)"
  },
  "colors.colorPalette.onWarningPrimary": {
    "value": "var(--colors-color-palette-on-warning-primary)",
    "variable": "var(--colors-color-palette-on-warning-primary)"
  },
  "colors.colorPalette.default.warning.onWarningSecondary": {
    "value": "var(--colors-color-palette-default-warning-on-warning-secondary)",
    "variable": "var(--colors-color-palette-default-warning-on-warning-secondary)"
  },
  "colors.colorPalette.warning.onWarningSecondary": {
    "value": "var(--colors-color-palette-warning-on-warning-secondary)",
    "variable": "var(--colors-color-palette-warning-on-warning-secondary)"
  },
  "colors.colorPalette.onWarningSecondary": {
    "value": "var(--colors-color-palette-on-warning-secondary)",
    "variable": "var(--colors-color-palette-on-warning-secondary)"
  },
  "colors.colorPalette.default.warning.onWarningTertiary": {
    "value": "var(--colors-color-palette-default-warning-on-warning-tertiary)",
    "variable": "var(--colors-color-palette-default-warning-on-warning-tertiary)"
  },
  "colors.colorPalette.warning.onWarningTertiary": {
    "value": "var(--colors-color-palette-warning-on-warning-tertiary)",
    "variable": "var(--colors-color-palette-warning-on-warning-tertiary)"
  },
  "colors.colorPalette.onWarningTertiary": {
    "value": "var(--colors-color-palette-on-warning-tertiary)",
    "variable": "var(--colors-color-palette-on-warning-tertiary)"
  },
  "colors.colorPalette.default.disable.onDisablePrimary": {
    "value": "var(--colors-color-palette-default-disable-on-disable-primary)",
    "variable": "var(--colors-color-palette-default-disable-on-disable-primary)"
  },
  "colors.colorPalette.disable.onDisablePrimary": {
    "value": "var(--colors-color-palette-disable-on-disable-primary)",
    "variable": "var(--colors-color-palette-disable-on-disable-primary)"
  },
  "colors.colorPalette.onDisablePrimary": {
    "value": "var(--colors-color-palette-on-disable-primary)",
    "variable": "var(--colors-color-palette-on-disable-primary)"
  },
  "colors.colorPalette.weak": {
    "value": "var(--colors-color-palette-weak)",
    "variable": "var(--colors-color-palette-weak)"
  },
  "colors.colorPalette.success": {
    "value": "var(--colors-color-palette-success)",
    "variable": "var(--colors-color-palette-success)"
  },
  "colors.colorPalette.danger": {
    "value": "var(--colors-color-palette-danger)",
    "variable": "var(--colors-color-palette-danger)"
  },
  "colors.colorPalette.warning": {
    "value": "var(--colors-color-palette-warning)",
    "variable": "var(--colors-color-palette-warning)"
  },
  "colors.colorPalette.info": {
    "value": "var(--colors-color-palette-info)",
    "variable": "var(--colors-color-palette-info)"
  },
  "colors.colorPalette.button.neutral": {
    "value": "var(--colors-color-palette-button-neutral)",
    "variable": "var(--colors-color-palette-button-neutral)"
  },
  "colors.colorPalette.neutral": {
    "value": "var(--colors-color-palette-neutral)",
    "variable": "var(--colors-color-palette-neutral)"
  },
  "colors.colorPalette.bg.primary": {
    "value": "var(--colors-color-palette-bg-primary)",
    "variable": "var(--colors-color-palette-bg-primary)"
  },
  "colors.colorPalette.bg.primary.hover": {
    "value": "var(--colors-color-palette-bg-primary-hover)",
    "variable": "var(--colors-color-palette-bg-primary-hover)"
  },
  "colors.colorPalette.primary.hover": {
    "value": "var(--colors-color-palette-primary-hover)",
    "variable": "var(--colors-color-palette-primary-hover)"
  },
  "colors.colorPalette.bg.primary.active": {
    "value": "var(--colors-color-palette-bg-primary-active)",
    "variable": "var(--colors-color-palette-bg-primary-active)"
  },
  "colors.colorPalette.primary.active": {
    "value": "var(--colors-color-palette-primary-active)",
    "variable": "var(--colors-color-palette-primary-active)"
  },
  "colors.colorPalette.active": {
    "value": "var(--colors-color-palette-active)",
    "variable": "var(--colors-color-palette-active)"
  },
  "colors.colorPalette.bg.primary.status.danger": {
    "value": "var(--colors-color-palette-bg-primary-status-danger)",
    "variable": "var(--colors-color-palette-bg-primary-status-danger)"
  },
  "colors.colorPalette.primary.status.danger": {
    "value": "var(--colors-color-palette-primary-status-danger)",
    "variable": "var(--colors-color-palette-primary-status-danger)"
  },
  "colors.colorPalette.status.danger": {
    "value": "var(--colors-color-palette-status-danger)",
    "variable": "var(--colors-color-palette-status-danger)"
  },
  "colors.colorPalette.bg.primary.status.danger.hover": {
    "value": "var(--colors-color-palette-bg-primary-status-danger-hover)",
    "variable": "var(--colors-color-palette-bg-primary-status-danger-hover)"
  },
  "colors.colorPalette.primary.status.danger.hover": {
    "value": "var(--colors-color-palette-primary-status-danger-hover)",
    "variable": "var(--colors-color-palette-primary-status-danger-hover)"
  },
  "colors.colorPalette.status.danger.hover": {
    "value": "var(--colors-color-palette-status-danger-hover)",
    "variable": "var(--colors-color-palette-status-danger-hover)"
  },
  "colors.colorPalette.danger.hover": {
    "value": "var(--colors-color-palette-danger-hover)",
    "variable": "var(--colors-color-palette-danger-hover)"
  },
  "colors.colorPalette.bg.primary.status.danger.active": {
    "value": "var(--colors-color-palette-bg-primary-status-danger-active)",
    "variable": "var(--colors-color-palette-bg-primary-status-danger-active)"
  },
  "colors.colorPalette.primary.status.danger.active": {
    "value": "var(--colors-color-palette-primary-status-danger-active)",
    "variable": "var(--colors-color-palette-primary-status-danger-active)"
  },
  "colors.colorPalette.status.danger.active": {
    "value": "var(--colors-color-palette-status-danger-active)",
    "variable": "var(--colors-color-palette-status-danger-active)"
  },
  "colors.colorPalette.danger.active": {
    "value": "var(--colors-color-palette-danger-active)",
    "variable": "var(--colors-color-palette-danger-active)"
  },
  "colors.colorPalette.bg.secondary": {
    "value": "var(--colors-color-palette-bg-secondary)",
    "variable": "var(--colors-color-palette-bg-secondary)"
  },
  "colors.colorPalette.bg.secondary.hover": {
    "value": "var(--colors-color-palette-bg-secondary-hover)",
    "variable": "var(--colors-color-palette-bg-secondary-hover)"
  },
  "colors.colorPalette.secondary.hover": {
    "value": "var(--colors-color-palette-secondary-hover)",
    "variable": "var(--colors-color-palette-secondary-hover)"
  },
  "colors.colorPalette.bg.secondary.active": {
    "value": "var(--colors-color-palette-bg-secondary-active)",
    "variable": "var(--colors-color-palette-bg-secondary-active)"
  },
  "colors.colorPalette.secondary.active": {
    "value": "var(--colors-color-palette-secondary-active)",
    "variable": "var(--colors-color-palette-secondary-active)"
  },
  "colors.colorPalette.bg.secondary.status.danger.hover": {
    "value": "var(--colors-color-palette-bg-secondary-status-danger-hover)",
    "variable": "var(--colors-color-palette-bg-secondary-status-danger-hover)"
  },
  "colors.colorPalette.secondary.status.danger.hover": {
    "value": "var(--colors-color-palette-secondary-status-danger-hover)",
    "variable": "var(--colors-color-palette-secondary-status-danger-hover)"
  },
  "colors.colorPalette.bg.secondary.status.danger.active": {
    "value": "var(--colors-color-palette-bg-secondary-status-danger-active)",
    "variable": "var(--colors-color-palette-bg-secondary-status-danger-active)"
  },
  "colors.colorPalette.secondary.status.danger.active": {
    "value": "var(--colors-color-palette-secondary-status-danger-active)",
    "variable": "var(--colors-color-palette-secondary-status-danger-active)"
  },
  "colors.colorPalette.bg.tertiary": {
    "value": "var(--colors-color-palette-bg-tertiary)",
    "variable": "var(--colors-color-palette-bg-tertiary)"
  },
  "colors.colorPalette.bg.tertiary.hover": {
    "value": "var(--colors-color-palette-bg-tertiary-hover)",
    "variable": "var(--colors-color-palette-bg-tertiary-hover)"
  },
  "colors.colorPalette.tertiary.hover": {
    "value": "var(--colors-color-palette-tertiary-hover)",
    "variable": "var(--colors-color-palette-tertiary-hover)"
  },
  "colors.colorPalette.bg.tertiary.active": {
    "value": "var(--colors-color-palette-bg-tertiary-active)",
    "variable": "var(--colors-color-palette-bg-tertiary-active)"
  },
  "colors.colorPalette.tertiary.active": {
    "value": "var(--colors-color-palette-tertiary-active)",
    "variable": "var(--colors-color-palette-tertiary-active)"
  },
  "colors.colorPalette.bg.tertiary.status.danger.hover": {
    "value": "var(--colors-color-palette-bg-tertiary-status-danger-hover)",
    "variable": "var(--colors-color-palette-bg-tertiary-status-danger-hover)"
  },
  "colors.colorPalette.tertiary.status.danger.hover": {
    "value": "var(--colors-color-palette-tertiary-status-danger-hover)",
    "variable": "var(--colors-color-palette-tertiary-status-danger-hover)"
  },
  "colors.colorPalette.bg.tertiary.status.danger.active": {
    "value": "var(--colors-color-palette-bg-tertiary-status-danger-active)",
    "variable": "var(--colors-color-palette-bg-tertiary-status-danger-active)"
  },
  "colors.colorPalette.tertiary.status.danger.active": {
    "value": "var(--colors-color-palette-tertiary-status-danger-active)",
    "variable": "var(--colors-color-palette-tertiary-status-danger-active)"
  },
  "colors.colorPalette.border.primary": {
    "value": "var(--colors-color-palette-border-primary)",
    "variable": "var(--colors-color-palette-border-primary)"
  },
  "colors.colorPalette.border.primary.hover": {
    "value": "var(--colors-color-palette-border-primary-hover)",
    "variable": "var(--colors-color-palette-border-primary-hover)"
  },
  "colors.colorPalette.border.primary.active": {
    "value": "var(--colors-color-palette-border-primary-active)",
    "variable": "var(--colors-color-palette-border-primary-active)"
  },
  "colors.colorPalette.border.primary.status.danger": {
    "value": "var(--colors-color-palette-border-primary-status-danger)",
    "variable": "var(--colors-color-palette-border-primary-status-danger)"
  },
  "colors.colorPalette.border.primary.status.danger.hover": {
    "value": "var(--colors-color-palette-border-primary-status-danger-hover)",
    "variable": "var(--colors-color-palette-border-primary-status-danger-hover)"
  },
  "colors.colorPalette.border.primary.status.danger.active": {
    "value": "var(--colors-color-palette-border-primary-status-danger-active)",
    "variable": "var(--colors-color-palette-border-primary-status-danger-active)"
  },
  "colors.colorPalette.border.secondary": {
    "value": "var(--colors-color-palette-border-secondary)",
    "variable": "var(--colors-color-palette-border-secondary)"
  },
  "colors.colorPalette.border.secondary.status.danger": {
    "value": "var(--colors-color-palette-border-secondary-status-danger)",
    "variable": "var(--colors-color-palette-border-secondary-status-danger)"
  },
  "colors.colorPalette.secondary.status.danger": {
    "value": "var(--colors-color-palette-secondary-status-danger)",
    "variable": "var(--colors-color-palette-secondary-status-danger)"
  },
  "colors.colorPalette.border.tertiary": {
    "value": "var(--colors-color-palette-border-tertiary)",
    "variable": "var(--colors-color-palette-border-tertiary)"
  },
  "colors.colorPalette.outline": {
    "value": "var(--colors-color-palette-outline)",
    "variable": "var(--colors-color-palette-outline)"
  },
  "colors.colorPalette.outline.status.danger": {
    "value": "var(--colors-color-palette-outline-status-danger)",
    "variable": "var(--colors-color-palette-outline-status-danger)"
  },
  "colors.colorPalette.text.primary": {
    "value": "var(--colors-color-palette-text-primary)",
    "variable": "var(--colors-color-palette-text-primary)"
  },
  "colors.colorPalette.text.primary.status.danger": {
    "value": "var(--colors-color-palette-text-primary-status-danger)",
    "variable": "var(--colors-color-palette-text-primary-status-danger)"
  },
  "colors.colorPalette.text.secondary": {
    "value": "var(--colors-color-palette-text-secondary)",
    "variable": "var(--colors-color-palette-text-secondary)"
  },
  "colors.colorPalette.text.secondary.status.danger": {
    "value": "var(--colors-color-palette-text-secondary-status-danger)",
    "variable": "var(--colors-color-palette-text-secondary-status-danger)"
  },
  "colors.colorPalette.text.tertiary": {
    "value": "var(--colors-color-palette-text-tertiary)",
    "variable": "var(--colors-color-palette-text-tertiary)"
  },
  "colors.colorPalette.text.tertiary.status.danger": {
    "value": "var(--colors-color-palette-text-tertiary-status-danger)",
    "variable": "var(--colors-color-palette-text-tertiary-status-danger)"
  },
  "colors.colorPalette.tertiary.status.danger": {
    "value": "var(--colors-color-palette-tertiary-status-danger)",
    "variable": "var(--colors-color-palette-tertiary-status-danger)"
  },
  "colors.colorPalette.text.link.status.danger": {
    "value": "var(--colors-color-palette-text-link-status-danger)",
    "variable": "var(--colors-color-palette-text-link-status-danger)"
  },
  "colors.colorPalette.link.status.danger": {
    "value": "var(--colors-color-palette-link-status-danger)",
    "variable": "var(--colors-color-palette-link-status-danger)"
  },
  "colors.colorPalette.background": {
    "value": "var(--colors-color-palette-background)",
    "variable": "var(--colors-color-palette-background)"
  }
}

export function token(path, fallback) {
  return tokens[path]?.value || fallback
}

function tokenVar(path, fallback) {
  return tokens[path]?.variable || fallback
}

token.var = tokenVar