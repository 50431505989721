import { DetailedHTMLProps, InputHTMLAttributes, forwardRef, useState } from 'react';

import styled from '@emotion/styled';

import { Input } from '@agentero/components';
import { InputProps } from '@agentero/components/src/form/Input';
import { IconVisibility, IconVisibilityOff } from '@agentero/icons';
import { Button } from '@agentero/ui';

type InputPasswordProps = DetailedHTMLProps<
	InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
> &
	InputProps;

const InputPasswordContainer = styled.div<InputProps>`
	position: relative;

	${Input} {
		padding-right: ${({ isBig }) => (isBig ? '46px' : '32px')};

		&:focus {
			padding-right: ${({ isBig }) => (isBig ? '46px' : '32px')};
		}

		+ svg {
			margin: ${({ isBig }) => (isBig ? '8px' : '4px')};
		}
	}
`;

const InputPasswordViewButton = styled.div`
	position: absolute;
	top: 4px;
	right: 4px;

	svg {
		width: 100%;
		height: 100%;
		margin: 0;
	}
`;

export const InputPassword = forwardRef<HTMLInputElement, InputPasswordProps>(
	({ isBig, disabled, ...props }, ref) => {
		const [type, setType] = useState<'password' | 'text'>('password');

		const onView = () => setType(type === 'password' ? 'text' : 'password');

		return (
			<InputPasswordContainer>
				<Input ref={ref} {...props} disabled={disabled} isBig={isBig} type={type} />
				{!disabled && (
					<InputPasswordViewButton>
						<Button variant="ghost" onClick={onView} type="button" size={isBig ? 'md' : undefined}>
							{type === 'password' ? <IconVisibility /> : <IconVisibilityOff />}
						</Button>
					</InputPasswordViewButton>
				)}
			</InputPasswordContainer>
		);
	}
);
