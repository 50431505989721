import { RpcError } from 'grpc-web';

import { RequestStatusError } from '../RequestStatusError';
import { RequestError } from './RequestError';
import { getRequestError } from './getRequestError';

export enum RequestType {
	Grpc = 'Grpc',
	Fetch = 'Fetch'
}

export const handleGrpcResponse = (
	reject: (error: RequestError) => void,
	error: RpcError,
	userId: string
) => {
	if (error) {
		reject(
			getRequestError({
				messageError: error.message,
				statusError: error.code as unknown as RequestStatusError,
				logMetadata: {
					userId,
					type: RequestType.Grpc
				}
			})
		);
	}
};

export const handleFetchResponse = (
	response: Response,
	reject: (error: RequestError) => void,
	userId: string
) => {
	const res = response.clone();

	if (!res.ok) {
		reject(
			getRequestError({
				messageError: res.statusText,
				statusError: res.status as RequestStatusError,
				logMetadata: {
					userId,
					type: RequestType.Fetch,
					url: res.url
				}
			})
		);
		return;
	}

	return res.json();
};

export const handleFetchCatchError = (
	messageError: string,
	reject: (error: RequestError) => void,
	userId: string
) =>
	reject(
		getRequestError({
			messageError,
			statusError: RequestStatusError.Unknown,
			logMetadata: {
				type: RequestType.Fetch,
				userId
			}
		})
	);
