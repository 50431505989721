import { AnyMessage } from '@bufbuild/protobuf';
import { ConnectError, Interceptor, UnaryRequest, UnaryResponse } from '@connectrpc/connect';

enum StatusCode {
	OK,
	CANCELLED,
	UNKNOWN,
	INVALID_ARGUMENT,
	DEADLINE_EXCEEDED,
	NOT_FOUND,
	ALREADY_EXISTS,
	PERMISSION_DENIED,
	RESOURCE_EXHAUSTED,
	FAILED_PRECONDITION,
	ABORTED,
	OUT_OF_RANGE,
	UNIMPLEMENTED,
	INTERNAL,
	UNAVAILABLE,
	DATA_LOSS,
	UNAUTHENTICATED
}

const createId = (): string => {
	if (typeof window !== 'undefined') {
		const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
		return uint32.toString(16);
	}
	return '';
};

type SendNetworkMessageArgs = {
	action: unknown;
};

const sendNetworkMessage = ({ action }: SendNetworkMessageArgs) => {
	if (typeof window !== 'undefined') {
		window.postMessage({
			source: 'grpc-web-devtools',
			action
		});
	}
};

type SendUnaryNetworkRequestMessage = {
	networkId: string;
	request: UnaryRequest<AnyMessage, AnyMessage>;
};

const getMetadata = (header: Headers) => {
	const metadata: Record<string, string> = {};
	for (const pair of header.entries()) {
		metadata[pair[0]] = pair[1];
	}

	return metadata;
};

export const sendUnaryNetworkRequestMessage = ({
	networkId,
	request
}: SendUnaryNetworkRequestMessage) => {
	const action = {
		type: 'unary-request',
		payload: {
			partial: {
				id: networkId,
				type: 'unary',
				url: request.method.name,
				request: {
					body: request.message,
					metadata: getMetadata(request.header)
				}
			}
		}
	};

	try {
		sendNetworkMessage({ action });
	} catch {}
};

type SendUnaryResponseArgs = {
	networkId: string;
	response: UnaryResponse<AnyMessage, AnyMessage>;
	time: number;
};

export const sendUnaryNetworkResponseMessage = ({
	networkId,
	response,
	time
}: SendUnaryResponseArgs) => {
	const action = {
		type: 'unary-response',
		payload: {
			networkId,
			partial: {
				time,
				status: StatusCode.OK,
				response: {
					body: response.message,
					metadata: getMetadata(response.header)
				}
			}
		}
	};

	try {
		sendNetworkMessage({ action });
	} catch {}
};

type SendUnaryNetworkErrorMessageArgs = {
	networkId: string;
	time: number;
	error: ConnectError;
};

export const sendUnaryNetworkErrorMessage = ({
	networkId,
	error,
	time
}: SendUnaryNetworkErrorMessageArgs) => {
	const action = {
		type: 'unary-error',
		payload: {
			networkId,
			partial: {
				time,
				status: error.code,
				response: error.message
			}
		}
	};

	try {
		sendNetworkMessage({ action });
	} catch {}
};

export const devToolsInterceptor: Interceptor = next => async request => {
	if (!request.stream) {
		const start = performance.now();
		const networkId = createId();

		sendUnaryNetworkRequestMessage({
			networkId,
			request: request as UnaryRequest<AnyMessage, AnyMessage>
		});

		return await next(request)
			.then(response => {
				const time = Math.round(performance.now() - start);
				sendUnaryNetworkResponseMessage({
					response: response as UnaryResponse<AnyMessage, AnyMessage>,
					networkId,
					time
				});

				return response;
			})
			.catch(error => {
				const time = Math.round(performance.now() - start);
				sendUnaryNetworkErrorMessage({ error, networkId, time });
				throw error;
			});
	} else {
		return next(request);
	}
};
