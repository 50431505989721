import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { InputRadioButton } from './InputRadioButton';
import { FieldStatus } from './form/fieldStatus';

export type RadioInputValue = {
	label: string;
	value: boolean | string;
	onChange?: (value: boolean | string) => void;
};

export type InputRadioButtonGroupProps = {
	status?: FieldStatus;
	onChange?: (value: boolean | string) => void;
	onBlur?: () => void;
	value?: boolean | string;
	inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
	values: RadioInputValue[];
	defaultValue?: string | boolean;
};

const InputRadioButtonGroupContainer = styled.ul`
	display: inline-grid;
	grid-auto-columns: minmax(104px, 1fr);
	grid-auto-flow: row;

	${({ theme }) => theme.mediaqueries.M} {
		grid-auto-flow: column;
	}

	li:first-of-type {
		span {
			border-radius: 2px 0 0 2px;
		}
	}

	li:last-of-type {
		span {
			border-radius: 0 2px 2px 0;
		}
	}
`;

export const InputRadioButtonGroup = ({
	status,
	onBlur,
	onChange,
	inputProps,
	value,
	values,
	defaultValue
}: InputRadioButtonGroupProps) => {
	return (
		<div>
			<InputRadioButtonGroupContainer>
				{values.map(({ label, value: valueProp, onChange: onChangeProp }) => (
					<li key={label}>
						<InputRadioButton
							label={label}
							status={status}
							checked={value !== undefined ? value === valueProp : undefined}
							defaultChecked={defaultValue ? defaultValue === valueProp : undefined}
							value={valueProp !== undefined ? String(valueProp) : undefined}
							{...inputProps}
							onBlur={onBlur ? onBlur : inputProps?.onBlur}
							onChange={
								onChange || onChangeProp
									? e => {
											onChangeProp?.(valueProp);
											onChange?.(valueProp);
											inputProps?.onChange?.(e);
									  }
									: inputProps?.onChange
							}
						/>
					</li>
				))}
			</InputRadioButtonGroupContainer>
		</div>
	);
};

type InputRadioButtonBooleanGroupProps = Partial<InputRadioButtonGroupProps> & {
	onGroupChange?: (value: boolean | string) => void;
};

export const InputRadioButtonBooleanGroup = ({
	status,
	onBlur,
	onChange,
	value,
	inputProps,
	onGroupChange
}: InputRadioButtonBooleanGroupProps) => {
	const { t } = useTranslation(['shared']);

	return (
		<InputRadioButtonGroup
			inputProps={inputProps}
			status={status}
			value={value !== undefined ? value : ''}
			onBlur={onBlur}
			onChange={onChange}
			values={[
				{
					label: t('shared:yes'),
					value: true,
					onChange: onGroupChange
						? () => {
								onGroupChange(true);
						  }
						: undefined
				},
				{
					label: t('shared:no'),
					value: false,
					onChange: onGroupChange
						? () => {
								onGroupChange(false);
						  }
						: undefined
				}
			]}
		/>
	);
};
