import { styled } from '@agentero/styles/jsx';

export const List = styled('ul', {
	base: {
		listStyle: 'disc',
		paddingInlineStart: '32',
		display: 'flex',
		flexDirection: 'column',
		gap: '8'
	}
});
