import { useState } from 'react';

import Link from 'next/link';

import { IconNavigationMenu } from '@agentero/icons';
import { css } from '@agentero/styles/css';
import { HStack, styled } from '@agentero/styles/jsx';
import { stack } from '@agentero/styles/patterns';

import { Text } from './Text';

const LinkItem = styled('li', {
	base: {
		textStyle: 'body.small',
		paddingLeft: '20',
		marginLeft: '-0.1875rem',
		cursor: 'pointer',
		position: 'relative',
		_before: {
			position: 'absolute',
			content: '""',
			display: 'none',
			backgroundColor: 'neutral.30',
			width: '0.1875rem',
			height: '100%',
			top: 0,
			left: '0.1875rem',
			borderRadius: '0.1875rem'
		}
	},
	variants: {
		isActive: {
			true: {
				fontWeight: '600',
				paddingLeft: '20',
				_before: {
					display: 'block'
				}
			}
		}
	}
});

export type ContentMenuItem = {
	label: string;
	elementId: string;
};

type ContentMenuProps = {
	items: ContentMenuItem[];
};

export const ContentMenu = ({ items }: ContentMenuProps) => {
	const [itemActive, setItemActive] = useState<string>(items.length ? items[0].label : '');

	const onItemClick = (label: string) => {
		setItemActive(label);
	};

	return (
		<nav className={stack({ gap: '12' })}>
			<HStack>
				<IconNavigationMenu />
				<Text size="caption">On this page</Text>
			</HStack>
			<ul
				className={stack({
					gap: '12',
					position: 'relative',
					'& a': {
						textDecoration: 'none'
					},
					_before: {
						position: 'absolute',
						content: '""',
						display: 'block',
						backgroundColor: 'neutral.95',
						width: '0.1875rem',
						height: '100%',
						top: 0,
						left: 0,
						borderRadius: '0.1875rem'
					}
				})}>
				{items.map(({ label, elementId }) => (
					<LinkItem key={label} onClick={() => onItemClick(label)} isActive={itemActive === label}>
						<Link className={css({ display: 'block', width: '100%' })} href={`#${elementId}`}>
							{label}
						</Link>
					</LinkItem>
				))}
			</ul>
		</nav>
	);
};
