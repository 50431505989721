import { RequestPaymentMethodRequest } from '@agentero/grpc-clients/grpc/tier-fe';
import { handleGrpcResponse } from '@agentero/service-errors';

import { tierServiceClient } from 'packages/services/grpcClients';

type FetchChargeBeeUpdatePaymentInformationHostedPage = {
	token: string;
	userId: string;
	redirectUrl: string;
};

export const fetchChargeBeePaidPaymentFailedUrl = ({
	token,
	userId,
	redirectUrl
}: FetchChargeBeeUpdatePaymentInformationHostedPage) =>
	new Promise<string>((resolve, reject) => {
		const request = new RequestPaymentMethodRequest();

		request.setRedirectUrl(redirectUrl);

		tierServiceClient.requestPaymentMethod(
			request,
			{ authorization: `Bearer ${token}` },
			(error, response) => {
				if (error) {
					handleGrpcResponse(reject, error, userId);
					return;
				}

				const checkout = response.toObject();

				resolve(checkout.url);
			}
		);
	});
