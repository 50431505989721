import { PropsWithChildren } from 'react';

import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';

type QueryClientProviderProps = {
	dehydratedState?: unknown;
};

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: typeof window !== 'undefined' ? 2 : undefined,
			refetchOnWindowFocus: typeof window !== 'undefined' ? false : undefined,
			refetchOnMount: false
		},
		mutations: {
			// mutation options
		}
	}
});

export const QueryCacheProvider = ({
	children,
	dehydratedState
}: PropsWithChildren<QueryClientProviderProps>) => {
	return (
		<QueryClientProvider client={queryClient}>
			<HydrationBoundary state={dehydratedState}>{children}</HydrationBoundary>
		</QueryClientProvider>
	);
};
