import {
	GetCarrierAppetiteResourcesResponse,
	Relevance,
	Type
} from '@agentero/grpc-clients/grpc/carrier-fe';

export enum ResourceType {
	Download = 'download',
	Link = 'link',
	Video = 'video'
}

const typeMap = {
	[Type.TYPE_UNSPECIFIED]: ResourceType.Link,
	[Type.TYPE_DOCUMENT]: ResourceType.Download,
	[Type.TYPE_EXTERNAL_LINK]: ResourceType.Link,
	[Type.TYPE_VIDEO]: ResourceType.Video
};

const parseResourceType = (type: Type): ResourceType => {
	return typeMap[type];
};

const typeMapToProto = {
	[ResourceType.Link]: Type.TYPE_EXTERNAL_LINK,
	[ResourceType.Download]: Type.TYPE_DOCUMENT,
	[ResourceType.Video]: Type.TYPE_VIDEO
};

export const parseTypeToProto = (type: ResourceType): Type => {
	return typeMapToProto[type];
};

export enum ResourceRelevance {
	High = 'high',
	Additional = 'additional'
}

const resourceMap = {
	[Relevance.RELEVANCE_UNSPECIFIED]: ResourceRelevance.Additional,
	[Relevance.RELEVANCE_HIGH]: ResourceRelevance.High,
	[Relevance.RELEVANCE_ADDITIONAL]: ResourceRelevance.Additional
};

const resourceProtoMap = {
	[ResourceRelevance.High]: Relevance.RELEVANCE_HIGH,
	[ResourceRelevance.Additional]: Relevance.RELEVANCE_ADDITIONAL
};

export const parseResourceTypeToProto = (relevance: ResourceRelevance): Relevance => {
	return resourceProtoMap[relevance];
};

const parseResourceRelevance = (relevance: Relevance): ResourceRelevance => {
	return resourceMap[relevance];
};

export type CarrierResource = {
	id: string;
	name: string;
	type: ResourceType;
	url: string;
	relevance: ResourceRelevance;
};

export const parseListCarrierResource = (
	response: GetCarrierAppetiteResourcesResponse.AsObject
): CarrierResource[] => {
	return response.resourcesList
		? response.resourcesList.map(resource => {
				return {
					id: resource.id,
					name: resource.name,
					type: parseResourceType(resource.type),
					url: resource.url,
					relevance: parseResourceRelevance(resource.relevance)
				};
		  })
		: [];
};

export const parseAdminCarrierAppetiteResources = (
	response: GetCarrierAppetiteResourcesResponse.AsObject
): CarrierResource[] => {
	return response.resourcesList.map(resource => ({
		id: resource.id,
		name: resource.name,
		type: parseResourceType(resource.type),
		url: resource.url,
		relevance: parseResourceRelevance(resource.relevance)
	}));
};
