// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file agentero/portal/quote.proto (package agentero.portal, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { Address, Quote } from "../rater/rater_pb";
import { InsuranceType } from "../policy/policy_pb";

/**
 * @generated from message agentero.portal.GetQuoteProposalRequest
 */
export class GetQuoteProposalRequest extends Message<GetQuoteProposalRequest> {
  /**
   * @generated from field: string workitem_id = 1;
   */
  workitemId = "";

  constructor(data?: PartialMessage<GetQuoteProposalRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetQuoteProposalRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workitem_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuoteProposalRequest {
    return new GetQuoteProposalRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuoteProposalRequest {
    return new GetQuoteProposalRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuoteProposalRequest {
    return new GetQuoteProposalRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuoteProposalRequest | PlainMessage<GetQuoteProposalRequest> | undefined, b: GetQuoteProposalRequest | PlainMessage<GetQuoteProposalRequest> | undefined): boolean {
    return proto3.util.equals(GetQuoteProposalRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetQuoteProposalResponse
 */
export class GetQuoteProposalResponse extends Message<GetQuoteProposalResponse> {
  /**
   * @generated from field: bytes proposal_pdf = 1;
   */
  proposalPdf = new Uint8Array(0);

  constructor(data?: PartialMessage<GetQuoteProposalResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetQuoteProposalResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proposal_pdf", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuoteProposalResponse {
    return new GetQuoteProposalResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuoteProposalResponse {
    return new GetQuoteProposalResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuoteProposalResponse {
    return new GetQuoteProposalResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuoteProposalResponse | PlainMessage<GetQuoteProposalResponse> | undefined, b: GetQuoteProposalResponse | PlainMessage<GetQuoteProposalResponse> | undefined): boolean {
    return proto3.util.equals(GetQuoteProposalResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.SetQuoteSalesPitchRequest
 */
export class SetQuoteSalesPitchRequest extends Message<SetQuoteSalesPitchRequest> {
  /**
   * @generated from field: string quote_id = 1;
   */
  quoteId = "";

  /**
   * @generated from field: string sales_pitch = 2;
   */
  salesPitch = "";

  /**
   * @generated from field: bool generate = 3;
   */
  generate = false;

  /**
   * @generated from field: string feedback = 4;
   */
  feedback = "";

  constructor(data?: PartialMessage<SetQuoteSalesPitchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.SetQuoteSalesPitchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "quote_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sales_pitch", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "generate", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "feedback", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetQuoteSalesPitchRequest {
    return new SetQuoteSalesPitchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetQuoteSalesPitchRequest {
    return new SetQuoteSalesPitchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetQuoteSalesPitchRequest {
    return new SetQuoteSalesPitchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetQuoteSalesPitchRequest | PlainMessage<SetQuoteSalesPitchRequest> | undefined, b: SetQuoteSalesPitchRequest | PlainMessage<SetQuoteSalesPitchRequest> | undefined): boolean {
    return proto3.util.equals(SetQuoteSalesPitchRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.SetQuoteSalesPitchResponse
 */
export class SetQuoteSalesPitchResponse extends Message<SetQuoteSalesPitchResponse> {
  /**
   * @generated from field: string sales_pitch = 1;
   */
  salesPitch = "";

  constructor(data?: PartialMessage<SetQuoteSalesPitchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.SetQuoteSalesPitchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sales_pitch", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetQuoteSalesPitchResponse {
    return new SetQuoteSalesPitchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetQuoteSalesPitchResponse {
    return new SetQuoteSalesPitchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetQuoteSalesPitchResponse {
    return new SetQuoteSalesPitchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SetQuoteSalesPitchResponse | PlainMessage<SetQuoteSalesPitchResponse> | undefined, b: SetQuoteSalesPitchResponse | PlainMessage<SetQuoteSalesPitchResponse> | undefined): boolean {
    return proto3.util.equals(SetQuoteSalesPitchResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.Coverage
 */
export class Coverage extends Message<Coverage> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: agentero.portal.Coverage.coverageType type = 3;
   */
  type = Coverage_coverageType.DRIVER;

  /**
   * @generated from field: string default_value = 4;
   */
  defaultValue = "";

  /**
   * @generated from field: repeated string values = 5;
   */
  values: string[] = [];

  constructor(data?: PartialMessage<Coverage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.Coverage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(Coverage_coverageType) },
    { no: 4, name: "default_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Coverage {
    return new Coverage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Coverage {
    return new Coverage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Coverage {
    return new Coverage().fromJsonString(jsonString, options);
  }

  static equals(a: Coverage | PlainMessage<Coverage> | undefined, b: Coverage | PlainMessage<Coverage> | undefined): boolean {
    return proto3.util.equals(Coverage, a, b);
  }
}

/**
 * @generated from enum agentero.portal.Coverage.coverageType
 */
export enum Coverage_coverageType {
  /**
   * @generated from enum value: DRIVER = 0;
   */
  DRIVER = 0,

  /**
   * @generated from enum value: VEHICLE = 1;
   */
  VEHICLE = 1,

  /**
   * @generated from enum value: DEDUCTIBLE = 2;
   */
  DEDUCTIBLE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Coverage_coverageType)
proto3.util.setEnumType(Coverage_coverageType, "agentero.portal.Coverage.coverageType", [
  { no: 0, name: "DRIVER" },
  { no: 1, name: "VEHICLE" },
  { no: 2, name: "DEDUCTIBLE" },
]);

/**
 * @generated from message agentero.portal.GetCoveragesResponse
 */
export class GetCoveragesResponse extends Message<GetCoveragesResponse> {
  /**
   * @generated from field: map<string, agentero.portal.Coverage> coverages = 1;
   */
  coverages: { [key: string]: Coverage } = {};

  constructor(data?: PartialMessage<GetCoveragesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetCoveragesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "coverages", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Coverage} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCoveragesResponse {
    return new GetCoveragesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCoveragesResponse {
    return new GetCoveragesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCoveragesResponse {
    return new GetCoveragesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCoveragesResponse | PlainMessage<GetCoveragesResponse> | undefined, b: GetCoveragesResponse | PlainMessage<GetCoveragesResponse> | undefined): boolean {
    return proto3.util.equals(GetCoveragesResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetQuoteDetailsRequest
 */
export class GetQuoteDetailsRequest extends Message<GetQuoteDetailsRequest> {
  /**
   * @generated from field: string workitem_id = 1;
   */
  workitemId = "";

  constructor(data?: PartialMessage<GetQuoteDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetQuoteDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workitem_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuoteDetailsRequest {
    return new GetQuoteDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuoteDetailsRequest {
    return new GetQuoteDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuoteDetailsRequest {
    return new GetQuoteDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuoteDetailsRequest | PlainMessage<GetQuoteDetailsRequest> | undefined, b: GetQuoteDetailsRequest | PlainMessage<GetQuoteDetailsRequest> | undefined): boolean {
    return proto3.util.equals(GetQuoteDetailsRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetQuoteDetailsResponse
 */
export class GetQuoteDetailsResponse extends Message<GetQuoteDetailsResponse> {
  /**
   * In the next iterations, we will send all the coverages,
   * so we won't send the quote.
   *
   * @generated from field: agentero.rater.Quote quote = 1;
   */
  quote?: Quote;

  /**
   * @generated from field: agentero.policy.InsuranceType insurance_type = 2;
   */
  insuranceType = InsuranceType.UNDEFINED_INSURANCE_TYPE;

  /**
   * @generated from field: string applicant = 3;
   */
  applicant = "";

  /**
   * @generated from field: agentero.rater.Address address = 4;
   */
  address?: Address;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 6;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp effective_date = 7;
   */
  effectiveDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp expiration_date = 8;
   */
  expirationDate?: Timestamp;

  /**
   * @generated from oneof agentero.portal.GetQuoteDetailsResponse.summary
   */
  summary: {
    /**
     * @generated from field: agentero.portal.GetQuoteDetailsResponse.Attachment attachment = 9;
     */
    value: GetQuoteDetailsResponse_Attachment;
    case: "attachment";
  } | {
    /**
     * @generated from field: string summary_url = 10;
     */
    value: string;
    case: "summaryUrl";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: string contact_link = 11;
   */
  contactLink = "";

  /**
   * @generated from field: string ai_sales_pitch = 12;
   */
  aiSalesPitch = "";

  constructor(data?: PartialMessage<GetQuoteDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetQuoteDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "quote", kind: "message", T: Quote },
    { no: 2, name: "insurance_type", kind: "enum", T: proto3.getEnumType(InsuranceType) },
    { no: 3, name: "applicant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "address", kind: "message", T: Address },
    { no: 6, name: "created_at", kind: "message", T: Timestamp },
    { no: 7, name: "effective_date", kind: "message", T: Timestamp },
    { no: 8, name: "expiration_date", kind: "message", T: Timestamp },
    { no: 9, name: "attachment", kind: "message", T: GetQuoteDetailsResponse_Attachment, oneof: "summary" },
    { no: 10, name: "summary_url", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "summary" },
    { no: 11, name: "contact_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "ai_sales_pitch", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuoteDetailsResponse {
    return new GetQuoteDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuoteDetailsResponse {
    return new GetQuoteDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuoteDetailsResponse {
    return new GetQuoteDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuoteDetailsResponse | PlainMessage<GetQuoteDetailsResponse> | undefined, b: GetQuoteDetailsResponse | PlainMessage<GetQuoteDetailsResponse> | undefined): boolean {
    return proto3.util.equals(GetQuoteDetailsResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetQuoteDetailsResponse.Attachment
 */
export class GetQuoteDetailsResponse_Attachment extends Message<GetQuoteDetailsResponse_Attachment> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string file_name = 2;
   */
  fileName = "";

  /**
   * @generated from field: string file_dir = 3;
   */
  fileDir = "";

  /**
   * @generated from field: string file_url = 4;
   */
  fileUrl = "";

  /**
   * @generated from field: google.protobuf.Timestamp uploaded_at = 5;
   */
  uploadedAt?: Timestamp;

  constructor(data?: PartialMessage<GetQuoteDetailsResponse_Attachment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetQuoteDetailsResponse.Attachment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "file_dir", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "file_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "uploaded_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuoteDetailsResponse_Attachment {
    return new GetQuoteDetailsResponse_Attachment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuoteDetailsResponse_Attachment {
    return new GetQuoteDetailsResponse_Attachment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuoteDetailsResponse_Attachment {
    return new GetQuoteDetailsResponse_Attachment().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuoteDetailsResponse_Attachment | PlainMessage<GetQuoteDetailsResponse_Attachment> | undefined, b: GetQuoteDetailsResponse_Attachment | PlainMessage<GetQuoteDetailsResponse_Attachment> | undefined): boolean {
    return proto3.util.equals(GetQuoteDetailsResponse_Attachment, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetContactQuoteDetailsRequest
 */
export class GetContactQuoteDetailsRequest extends Message<GetContactQuoteDetailsRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: bool is_agent = 2;
   */
  isAgent = false;

  constructor(data?: PartialMessage<GetContactQuoteDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetContactQuoteDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_agent", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetContactQuoteDetailsRequest {
    return new GetContactQuoteDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetContactQuoteDetailsRequest {
    return new GetContactQuoteDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetContactQuoteDetailsRequest {
    return new GetContactQuoteDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetContactQuoteDetailsRequest | PlainMessage<GetContactQuoteDetailsRequest> | undefined, b: GetContactQuoteDetailsRequest | PlainMessage<GetContactQuoteDetailsRequest> | undefined): boolean {
    return proto3.util.equals(GetContactQuoteDetailsRequest, a, b);
  }
}

