import styled from '@emotion/styled';

import { Input } from './Input';
import { FieldStatus } from './fieldStatus';

const InputAsTextArea = Input.withComponent('textarea');

export type TextAreaProps = {
	status?: FieldStatus;
	minHeight?: number;
};

export const TextArea = styled(InputAsTextArea)<TextAreaProps>`
	display: block;
	min-height: ${({ minHeight }) => (minHeight ? minHeight : 80)}px;
	resize: vertical;
	padding-block: 11px;
	field-sizing: content;
	height: unset;
`;
