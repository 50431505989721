import { useEffect, useLayoutEffect, useState } from 'react';

const IS_SERVER = typeof window === 'undefined';
const useIsomorphicLayoutEffect = IS_SERVER ? useEffect : useLayoutEffect;

export const useMediaQuery = (mediaQuery: string) => {
	const [matches, setMatches] = useState(true);

	useEffect(() => setMatches(window.matchMedia(mediaQuery).matches), []);

	useIsomorphicLayoutEffect(() => {
		const mediaQueryList = window.matchMedia(mediaQuery);
		const listener = (e: MediaQueryListEvent) => setMatches(e.matches);
		mediaQueryList.addListener(listener);
		return () => mediaQueryList.removeListener(listener);
	}, [mediaQuery]);

	return matches;
};
