import { PropsWithChildren, ReactElement, ReactNode } from 'react';

import styled from '@emotion/styled';

import { IconError, IconInfoOutline, IconWarning } from '@agentero/icons';
import { ITheme } from '@agentero/styles/emotion';

export enum AlertStatus {
	Success,
	Error,
	Warning,
	Info,
	Neutral
}

type AlertProps = {
	status: AlertStatus;
	action?: ReactNode;
	icon?: ReactNode;
	className?: string;
	size?: 'sm' | 'md';
	ghost?: boolean;
};

type GetAlertVariant = { status: AlertStatus; theme: ITheme; ghost?: boolean };

export const successVariant = (theme: ITheme, ghost?: boolean) => `
	background: ${theme.colors.status.success.lighten80};
	${ghost ? `color: ${theme.colors.status.success.base};` : ''}

	svg path {
		fill: ${theme.colors.status.success.base};
	}

	[data-role=action-button] {
		color: ${theme.colors.status.success.base};
	}
`;
export const errorVariant = (theme: ITheme, ghost?: boolean) => `
	background: ${theme.colors.status.error.lighten80};
	${ghost ? `color: ${theme.colors.status.error.base};` : ''}

	svg path {
		fill: ${theme.colors.status.error.base};
	}

	[data-role=action-button] {
		color: ${theme.colors.status.error.base};
	}

	a {
		color: ${theme.colors.status.error.base};
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
			fill: ${theme.colors.status.error.base};
			color: ${theme.colors.status.error.base};
		}

		&:active {
			fill: ${theme.colors.status.error.darken30};
			color: ${theme.colors.status.error.darken30};
		}
	}
`;
export const warningVariant = (theme: ITheme, ghost?: boolean) => `
	background: ${theme.colors.status.warning.lighten80};
	${ghost ? `color: ${theme.colors.status.warning.darken50};` : ''}


	svg path {
		fill: ${theme.colors.status.warning.darken50};
	}

	[data-role=action-button] {
		color: ${theme.colors.status.warning.base};
	}

	a {
		color: ${theme.colors.status.warning.darken50};
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
			fill: ${theme.colors.status.warning.darken50};
			color: ${theme.colors.status.warning.darken50};
		}

		&:active {
			fill: ${theme.colors.status.warning.darken50};
			color: ${theme.colors.status.warning.darken50};
		}
	}
`;
export const infoVariant = (theme: ITheme, ghost?: boolean) => `
	background: ${theme.colors.status.info.lighten80};
	${ghost ? `color: ${theme.colors.status.info.darken30};` : ''}

	svg path {
		fill: ${theme.colors.status.info.darken30};
	}

	[data-role=action-button] {
		color: ${theme.colors.status.info.darken30};
	}

	a {
		color: ${theme.colors.status.info.darken30};
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
			fill: ${theme.colors.status.info.darken30};
			color: ${theme.colors.status.info.darken30};
		}

		&:active {
			fill: ${theme.colors.status.info.darken50};
			color: ${theme.colors.status.info.darken50};
		}
	}
`;
export const neutralVariant = (theme: ITheme, ghost?: boolean) => `
	background: ${theme.colors.gray.lighten90};
	${ghost ? `color: ${theme.colors.gray.lighten40};` : ''}

	svg path {
		fill: ${theme.colors.gray.lighten40};
	}

	[data-role=action-button] {
		color: ${theme.colors.gray.base};
	}

	a {
		color: ${theme.colors.gray.base};
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
			fill: ${theme.colors.gray.base};
			color: ${theme.colors.gray.base};
		}

		&:active {
			fill: ${theme.colors.gray.base};
			color: ${theme.colors.gray.base};
		}
	}
`;

export const alertVariants: { [key: number]: (theme: ITheme, ghost?: boolean) => string } = {
	[AlertStatus.Success]: successVariant,
	[AlertStatus.Error]: errorVariant,
	[AlertStatus.Warning]: warningVariant,
	[AlertStatus.Info]: infoVariant,
	[AlertStatus.Neutral]: neutralVariant
};

const getAlertVariant = ({ status, theme, ghost }: GetAlertVariant) =>
	alertVariants[status](theme, ghost);

const AlertContainer = styled.div<AlertProps>`
	padding: ${({ size }) => (size === 'sm' ? '12px 16px' : '16px 24px')};
	${({ theme, size }) => (size === 'sm' ? theme.textSettings.XS : theme.textSettings.S)};
	color: ${({ theme }) => theme.colors.gray.base};
	border-radius: 0.125rem;

	${getAlertVariant}

	${({ ghost }) => (ghost ? 'background: transparent; padding: 0;' : '')}

	${({ theme }) => theme.mediaqueries.M} {
		display: flex;
	}
`;

const AlertBody = styled.div`
	display: flex;
	flex: 1;
	min-width: 0;
`;

const AlertIcon = styled.div<Pick<AlertProps, 'size'>>`
	flex: 0 1 16px;
	height: 16px;
	margin: 4px 8px 0 0;
	${({ size }) => size === 'sm' && 'margin-top: 0;'};

	svg {
		width: 100%;
		height: auto;
		vertical-align: top;
	}
`;

const AlertText = styled.div`
	flex: 1;
`;

const AlertActions = styled.div`
	margin: 8px 0 0 24px;

	${({ theme }) => theme.mediaqueries.M} {
		margin: 0 0 0 16px;
	}
`;

const iconMap: { [key in AlertStatus]: ReactElement } = {
	[AlertStatus.Success]: <IconInfoOutline />,
	[AlertStatus.Error]: <IconError />,
	[AlertStatus.Warning]: <IconWarning />,
	[AlertStatus.Info]: <IconInfoOutline />,
	[AlertStatus.Neutral]: <IconInfoOutline />
};

// TODO: move to @agentero/ui and deprecate this one
export const Alert = ({
	children,
	status,
	action,
	icon,
	className,
	size = 'md',
	ghost = false,
	...data
}: PropsWithChildren<AlertProps>) => (
	<AlertContainer
		status={status}
		className={className}
		size={size}
		role="alert"
		aria-labelledby="alertText"
		ghost={ghost}
		{...data}>
		<AlertBody>
			<AlertIcon size={size}>{icon ? icon : iconMap[status]}</AlertIcon>
			<AlertText id="alertText">{children}</AlertText>
		</AlertBody>
		{action && <AlertActions>{action}</AlertActions>}
	</AlertContainer>
);
