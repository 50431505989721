import { RequestStatusError } from '../RequestStatusError';
import { RequestError, RequestErrorArgs } from './RequestError';
import { InvalidDataReceivedError } from './custom/InvalidDataReceivedError';
import { InternalServerError } from './grpc/InternalServerError';
import { RpcDataLossError } from './grpc/RpcDataLossError';
import { RpcFailedPreconditionError } from './grpc/RpcFailedPreconditionError';
import { RpcInternalError } from './grpc/RpcInternalError';
import { RpcPermissionDeniedError } from './grpc/RpcPermissionDeniedError';
import { RpcUnavailableError } from './grpc/RpcUnavailableError';
import { RpcUnknownError } from './grpc/RpcUnknownError';

const errorMapping: {
	[key: number]: ({ messageError, logMetadata, statusError }: RequestErrorArgs) => RequestError;
} = {
	[RequestStatusError.InvalidDataReceived]: ({ messageError, logMetadata }) =>
		new InvalidDataReceivedError({ messageError, logMetadata }),
	[RequestStatusError.RpcUnknown]: ({ messageError, logMetadata }) =>
		new RpcUnknownError({ messageError, logMetadata }),
	[RequestStatusError.RpcInternal]: ({ messageError, logMetadata }) =>
		new RpcInternalError({ messageError, logMetadata }),
	[RequestStatusError.RpcUnavailable]: ({ messageError, logMetadata }) =>
		new RpcUnavailableError({ messageError, logMetadata }),
	[RequestStatusError.RpcDataLoss]: ({ messageError, logMetadata }) =>
		new RpcDataLossError({ messageError, logMetadata }),
	[RequestStatusError.RpcPermissionDenied]: ({ messageError, logMetadata }) =>
		new RpcPermissionDeniedError({ messageError, logMetadata }),
	[RequestStatusError.HttpInternalServerError]: ({ messageError, logMetadata }) =>
		new InternalServerError({ messageError, logMetadata }),
	[RequestStatusError.RpcFailedPrecondition]: ({ messageError, logMetadata }) =>
		new RpcFailedPreconditionError({ messageError, logMetadata })
};

const defaultError = ({ messageError, logMetadata, statusError }: RequestErrorArgs) =>
	new RequestError({ messageError, statusError, logMetadata });

export const getRequestError = ({ messageError, logMetadata, statusError }: RequestErrorArgs) => {
	const error = errorMapping[statusError] || defaultError;
	return error({ messageError, statusError, logMetadata });
};
