import { styled } from '@agentero/styles/jsx';

const VideoIframeContainer = styled('div', {
	base: {
		position: 'relative',
		aspectRatio: '16/9'
	}
});

const VideoIframe = styled('iframe', {
	base: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%'
	}
});

type YoutubeVideoContainerProps = {
	youTubeId: string;
	title: string;
};

export const YoutubeVideoContainer = ({ youTubeId, title }: YoutubeVideoContainerProps) => {
	return (
		<VideoIframeContainer>
			<VideoIframe
				src={`https://www.youtube.com/embed/${youTubeId}`}
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				title={title}
			/>
		</VideoIframeContainer>
	);
};
