import { styled } from '@agentero/styles/jsx';

import { inputRecipe } from '../fieldText/Input';

const TextAreaAsInput = styled('textarea', inputRecipe);

export const TextArea = styled(TextAreaAsInput, {
	base: {
		display: 'block',
		minHeight: 80,
		resize: 'vertical',
		paddingBlock: 11
	}
});
