import styled from '@emotion/styled';

type ValidationsRule = {
	text: string;
	pattern: RegExp | ((value: string) => boolean);
};

type ValidationListProps = {
	value: string;
	rules: ValidationsRule[];
};

const ValidationListContainer = styled.ul`
	margin-top: 16px;
	list-style: inside;
	text-align: left;
`;

const ValidationListItem = styled.li<{ isActive: boolean }>`
	padding-left: 8px;
	${({ theme }) => theme.textSettings.S};
	color: ${({ isActive, theme }) =>
		isActive ? theme.colors.gray.base : theme.colors.gray.lighten40};
`;

export const ValidationList = ({ value, rules }: ValidationListProps) => {
	const isActive = (pattern: RegExp | ((value: string) => boolean)) => {
		if (pattern instanceof RegExp) {
			const regex = new RegExp(pattern);
			return !!regex.test(value);
		} else {
			return pattern(value);
		}
	};

	return (
		<ValidationListContainer data-cy="password-validations">
			{rules.map(({ text, pattern }) => (
				<ValidationListItem key={text} isActive={isActive(pattern)}>
					{text}
				</ValidationListItem>
			))}
		</ValidationListContainer>
	);
};
