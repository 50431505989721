import { PropsWithChildren, useRef, useState } from 'react';

import styled from '@emotion/styled';

import { Text } from '@agentero/components';
import { useSSRLayoutEffect } from '@agentero/hooks';

type ExpanderParentProps = {
	maxHeight: number;
	isExpanded: boolean;
};

const ExpanderParent = styled.div<ExpanderParentProps>`
	position: relative;
	max-height: ${({ maxHeight, isExpanded }) => (isExpanded ? 'none' : `${maxHeight}px`)};
	overflow: hidden;
	transition: max-height 0.3s ease-in-out;
`;

const ExpanderShadow = styled.div`
	position: absolute;
	bottom: 0;
	height: 16px;
	width: 100%;
	background: linear-gradient(
		0deg,
		${({ theme }) => theme.colors.white} 0%,
		rgba(255, 255, 255, 0) 100%
	);
`;

type ExpanderProps = {
	maxHeight: number;
	hasShadow?: boolean;
};

type ExpanderState = {
	isExpanded: boolean;
	isTogglable: boolean;
	isMeasured: boolean;
};

export const Expander = ({
	maxHeight,
	children,
	hasShadow = true
}: PropsWithChildren<ExpanderProps>) => {
	const [state, setState] = useState<ExpanderState>({
		isExpanded: false,
		isTogglable: false,
		isMeasured: false
	});
	const childRef = useRef<HTMLDivElement>(null);

	const measureHeight = () => {
		if (childRef.current) {
			const { height } = childRef.current.getBoundingClientRect();
			setState(prevState => ({
				...prevState,
				isTogglable: height > maxHeight,
				isMeasured: true
			}));
		}
	};

	useSSRLayoutEffect(() => {
		measureHeight();

		const resizeObserver = new ResizeObserver(measureHeight);
		if (childRef.current) {
			resizeObserver.observe(childRef.current);
		}

		window.addEventListener('resize', measureHeight);

		return () => {
			resizeObserver.disconnect();
			window.removeEventListener('resize', measureHeight);
		};
	}, [maxHeight]);

	const onToggle = () =>
		setState(prevState => ({ ...prevState, isExpanded: !prevState.isExpanded }));

	return (
		<div>
			<ExpanderParent maxHeight={maxHeight} isExpanded={state.isExpanded}>
				<div ref={childRef}>{children}</div>
				{state.isTogglable && !state.isExpanded && hasShadow && state.isMeasured && (
					<ExpanderShadow role="separator" />
				)}
			</ExpanderParent>
			{state.isTogglable && state.isMeasured && (
				<Text size="S" mt={8}>
					<a onClick={onToggle}>
						<b>{state.isExpanded ? 'See less' : 'See more'}</b>
					</a>
				</Text>
			)}
		</div>
	);
};
