'use client';

import { Root } from '@radix-ui/react-accordion';

import { AccordionContent } from './accordion/Content';
import { AccordionHeader } from './accordion/Header';
import { AccordionItem } from './accordion/Item';
import { AccordionTrigger } from './accordion/Trigger';

export const Accordion = {
	Root,
	Header: AccordionHeader,
	Trigger: AccordionTrigger,
	Content: AccordionContent,
	Item: AccordionItem
};
