export const numberWithCommas = (value: number | string) =>
	value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const toFixNumber = (value: number, fix: number) =>
	Number(value.toFixed(fix).replace(/[.,]00$/, ''));

export const parseNumber = (x: string | number) => (typeof x === 'string' ? Number(x) : x);

export const stringToFloat = (value: string) => parseFloat(value.replaceAll(',', ''));

export const isStringNumber = (value: string) => !isNaN(parseFloat(value));
