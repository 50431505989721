import { css } from '@emotion/react';

import { theme } from './theme';

export const base = css`
	@layer emotionBase {
		* {
			box-sizing: border-box;
		}

		html {
			${theme.fontWeight.regular};
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}

		html,
		body {
			font-size: 100%;
			line-height: 1.5;
			height: 100%;
			padding: 0;
			margin: 0;
			color: ${theme.colors.gray.base};
			fill: ${theme.colors.gray.base};
			overflow-x: hidden;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin: 0;
			font-weight: normal;
		}

		b {
			${theme.fontWeight.medium}
		}

		strong {
			${theme.fontWeight.bold}
			font-weight: normal;
		}

		a {
			cursor: pointer;
			fill: ${theme.colors.primary.base};
			color: ${theme.colors.primary.base};

			&:hover,
			&:focus {
				text-decoration: underline;
				fill: ${theme.colors.primary.base};
				color: ${theme.colors.primary.base};
			}

			&:active {
				fill: ${theme.colors.primary.darken10};
				color: ${theme.colors.primary.darken10};
			}
		}

		fieldset {
			padding: 0;
		}

		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-internal-autofill-selected,
		textarea:-webkit-autofill,
		textarea:-webkit-autofill:hover,
		textarea:-webkit-autofill:focus,
		select:-webkit-autofill,
		select:-webkit-autofill:hover,
		select:-webkit-autofill:focus {
			-webkit-box-shadow: 0 0 0px 1000px ${theme.colors.white} inset;
		}

		#__next {
			display: flex;
			flex-direction: column;
			height: 100%;
		}
	}
`;
