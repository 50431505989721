import styled from '@emotion/styled';

type ListTableBodyEmpty = {
	size?: 'S' | 'default';
};

export const ListTableBodyEmpty = styled.div<ListTableBodyEmpty>`
	position: relative;
	min-height: 0;
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: ${({ size = 'default' }) => (size === 'default' ? '32px 16px' : '16px')};
	text-align: center;
	border-left: 1px solid ${({ theme }) => theme.colors.gray.lighten90};
	border-right: 1px solid ${({ theme }) => theme.colors.gray.lighten90};
	background: ${({ theme }) => theme.colors.white};

	&:before {
		content: '';
		position: absolute;
		display: block;
		z-index: 1;
		bottom: 0;
		left: 0;
		right: 0;
		height: 1px;
		background: ${({ theme }) => theme.colors.gray.lighten90};
	}

	${({ theme }) => theme.mediaqueries.M} {
		position: unset;
		padding: ${({ size = 'default' }) => (size === 'default' ? '72px 16px' : '16px')};
	}
`;
