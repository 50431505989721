import React, { useCallback, useContext } from 'react';

import styled from '@emotion/styled';

import { MenuContext } from './MenuPanel';
import { MenuItemText } from './menuItem/MenuItemText';

export const MenuItemContainer = styled.li<{ isSelected: boolean }>`
	display: flex;
	align-items: center;
	position: relative;
	color: ${({ theme, isSelected }) =>
		isSelected ? theme.colors.gray.base : theme.colors.gray.lighten40};
	height: 40px;
	text-align: left;
	padding: 0 16px;
	cursor: pointer;
	transition: 0.15s;
	${({ theme, isSelected }) => (isSelected ? theme.fontWeight.medium : theme.fontWeight.regular)}
	${({ theme }) => theme.textSettings.S}

	${MenuItemText} {
		transition: 0.15s;
	}

	svg {
		height: 16px;
		width: 16px;
	}

	&:hover {
		background: ${props => props.theme.colors.gray.lighten95};
		color: ${props => props.theme.colors.gray.base};
		${props => props.theme.fontWeight.medium};

		${MenuItemText} {
			color: ${props => props.theme.colors.gray.base};
		}

		svg path {
			fill: ${props => props.theme.colors.gray.base};
		}
	}

	&:active {
		background: ${props => props.theme.colors.gray.lighten90};
	}
`;

interface IMenuItem
	extends React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> {
	closePanelOnClick?: boolean;
	isSelected?: boolean;
}

export const MenuItem: React.FC<IMenuItem> = ({
	closePanelOnClick = true,
	isSelected = false,
	...liProps
}) => {
	const { closePanel } = useContext(MenuContext);
	const onClick = useCallback((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		if (closePanelOnClick) {
			closePanel();
		}
		if (liProps.onClick) {
			liProps.onClick(event);
		}
	}, []);
	return <MenuItemContainer {...liProps} isSelected={isSelected} onClick={onClick} />;
};
