import { PropsWithChildren, ReactNode } from 'react';

import styled from '@emotion/styled';

interface IIconText extends IIconTextIcon {
	label: string | ReactNode;
	className?: string;
	truncate?: boolean;
}

export const IconTextContainer = styled.span<{ truncate?: boolean }>`
	display: ${({ truncate }) => (truncate ? 'flex' : 'inline-flex')};
	gap: 8px;
	vertical-align: top;
	align-items: flex-start;
`;

interface IIconTextIcon {
	iconRight?: boolean;
	iconSize?: 'M' | 'S';
}

export const IconTextIcon = styled.span<IIconTextIcon>(
	({ iconRight, iconSize }) => `
	${iconRight ? 'order: 2' : 'order: 1'};
	${iconRight ? 'margin-right: 7px' : 'margin-left: 7px'};
	flex-grow: 0;
	min-width: ${iconSize === 'M' ? '1.125rem' : '1rem'};
	width: ${iconSize === 'M' ? '1.125rem' : '1rem'};

	svg {
		width: 100%;
		height: auto;
		margin-top: ${iconSize === 'M' ? '3px' : '4px'};
		vertical-align: top;
	}
`
);

type IconTextProps = {
	iconRight?: boolean;
	truncate?: boolean;
};

export const IconTextLabel = styled.span<IconTextProps>`
	${({ iconRight }) => (iconRight ? 'order: 1' : 'order: 2')};
	text-align: left;

	${({ truncate }) =>
		truncate
			? `
					white-space: nowrap;
					min-width: 0;
					overflow: hidden;
					text-overflow: ellipsis;
				`
			: ''};
`;

export const IconText = ({
	iconRight,
	label,
	children,
	className,
	iconSize = 'M',
	truncate = false,
	...props
}: PropsWithChildren<IIconText>) => (
	<IconTextContainer className={className} truncate={truncate}>
		<IconTextIcon iconRight={iconRight} iconSize={iconSize}>
			{children}
		</IconTextIcon>
		<IconTextLabel {...props} iconRight={iconRight} truncate={truncate}>
			{label}
		</IconTextLabel>
	</IconTextContainer>
);
