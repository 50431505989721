import styled from '@emotion/styled';

export const CardHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin: -16px -16px 0;
	padding: 16px;
`;

export const CardHeaderMain = styled.div`
	display: flex;
	align-items: center;
	min-width: 0;
	margin: 2px 16px 2px 0;
`;

export const CardHeaderMainActions = styled.div`
	margin: -4px 0 -4px 16px;
`;

export const CardHeaderActions = styled.div`
	display: flex;
	align-items: center;
	margin: -8px 0;
	padding-left: 8px;
`;
