import { PropsWithChildren } from 'react';

import { DehydratedState } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { QueryCacheProvider } from 'packages/services/QueryCache';

type ReactQueryProviderProps = {
	dehydratedState?: DehydratedState;
};

const ReactQueryProvider = ({
	dehydratedState,
	children
}: PropsWithChildren<ReactQueryProviderProps>) => {
	return (
		<QueryCacheProvider dehydratedState={dehydratedState}>
			{children}
			{process.env.NEXT_PUBLIC_ENVIRONMENT === 'local' && <ReactQueryDevtools position="bottom" />}
		</QueryCacheProvider>
	);
};

export { ReactQueryProvider };
