import { Tier as TierProto } from '@agentero/grpc-clients/grpc/shared/tier';
import { Tier as TierConnect } from '@agentero/service-clients/portal/shared/tier';

export enum Tier {
	Unspecified = 'unspecified',
	Bronze = 'basic',
	Silver = 'standard',
	Gold = 'elite'
}

const tierMap: { [key in TierProto]: Tier } = {
	[TierProto.TIER_UNSPECIFIED]: Tier.Unspecified,
	[TierProto.TIER_BASIC]: Tier.Bronze,
	[TierProto.TIER_STANDARD]: Tier.Silver,
	[TierProto.TIER_ELITE]: Tier.Gold
};

export const parseTier = (tier?: TierProto): Tier => (tier ? tierMap[tier] : Tier.Unspecified);

const tierMapToProto: { [key in Tier]: TierProto } = {
	[Tier.Unspecified]: TierProto.TIER_UNSPECIFIED,
	[Tier.Bronze]: TierProto.TIER_BASIC,
	[Tier.Silver]: TierProto.TIER_STANDARD,
	[Tier.Gold]: TierProto.TIER_ELITE
};

const tierMapConnect: { [key in TierProto]: Tier } = {
	[TierConnect.UNSPECIFIED]: Tier.Unspecified,
	[TierConnect.BASIC]: Tier.Bronze,
	[TierConnect.STANDARD]: Tier.Silver,
	[TierConnect.ELITE]: Tier.Gold
};

export const parseTierConnect = (tier?: TierConnect): Tier =>
	tier ? tierMapConnect[tier] : Tier.Unspecified;

export const parseTierToProto = (tier: Tier): TierProto => tierMapToProto[tier];

export const isSubscriptionTierUpgradable = (tier?: Tier): boolean =>
	tier ? [Tier.Bronze, Tier.Silver].includes(tier) : false;
