import { useEffect } from 'react';

export const useOnClickOutside = (handler: (e: Event) => void) => {
	useEffect(() => {
		window.addEventListener('mousedown', handler);
		window.addEventListener('touchstart', handler);
		return () => {
			window.removeEventListener('mousedown', handler);
			window.removeEventListener('touchstart', handler);
		};
	}, [handler]);

	return {
		onMouseDown: (e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation(),
		onTouchStart: (e: React.TouchEvent<HTMLElement>) => e.stopPropagation()
	};
};
