import styled from '@emotion/styled';

import { ListRow } from './ListRow';
import { ListCell } from './listRow/ListCell';

export const ListTableHead = styled.div`
	position: sticky;
	z-index: 4;
	top: 0;

	${ListRow} {
		background: ${({ theme }) => theme.colors.gray.lighten90};
		margin-bottom: 0;
		padding-top: 1px;
		border-bottom: 0;
		z-index: 4;

		&:hover {
			background: ${({ theme }) => theme.colors.gray.lighten90};
		}

		&:before {
			display: none;
		}
	}

	${ListCell} {
		padding: 8px;
	}

	+ [role='rowgroup'] {
		margin-top: unset;
		padding-top: 1px;
	}
`;

ListTableHead.defaultProps = {
	role: 'rowgroup',
	//@ts-ignore
	['data-cy']: 'list-table-head'
};
