import { DetailedHTMLProps, InputHTMLAttributes, forwardRef } from 'react';

import { NumericFormat } from 'react-number-format';

import { Input, InputVariants } from '../fieldText/Input';

type InputNumberProps = {
	maxNumber?: number;
	thousandSeparator?: boolean;
} & Omit<
	DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
	'size' | 'css'
> &
	InputVariants;

export const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
	(
		{
			status,
			placeholder,
			onChange,
			value,
			maxNumber,
			thousandSeparator = false,
			id,
			defaultValue,
			size,
			disabled,
			maxLength
		},
		ref
	) => (
		<NumericFormat
			id={id}
			status={status}
			size={size}
			value={value as string | number}
			placeholder={placeholder}
			thousandSeparator={thousandSeparator}
			fixedDecimalScale={false}
			onChange={onChange}
			customInput={Input}
			allowNegative={false}
			isAllowed={values => {
				const { floatValue } = values;
				return maxNumber && floatValue ? floatValue <= maxNumber : true;
			}}
			getInputRef={ref}
			defaultValue={defaultValue as number | undefined}
			disabled={disabled}
			maxLength={maxLength}
		/>
	)
);
