type AppliedFiltersActionType =
	| 'TOGGLE_SPACE'
	| 'TOGGLE_ALL_FILTERS_VISIBILITY'
	| 'SHOW_SHOW_MORE'
	| 'HIDE_SHOW_MORE';

export type AppliedFiltersAction = {
	type: AppliedFiltersActionType;
};

export type AppliedFiltersState = {
	hasSpace: boolean;
	isAllFiltersVisible: boolean;
};

export const appliedFiltersReducer: (
	state: AppliedFiltersState,
	action: AppliedFiltersAction
) => AppliedFiltersState = (state, action) => {
	switch (action.type) {
		case 'TOGGLE_SPACE':
			return { ...state, hasSpace: !state.hasSpace };
		case 'TOGGLE_ALL_FILTERS_VISIBILITY':
			return { ...state, isAllFiltersVisible: !state.isAllFiltersVisible };
		case 'SHOW_SHOW_MORE':
			return { ...state, hasSpace: false };
		case 'HIDE_SHOW_MORE':
			return { hasSpace: true, isAllFiltersVisible: false };
	}
};
