'use client';

import { PropsWithChildren, forwardRef } from 'react';

import { sva } from '@agentero/styles/css';
import { createStyleContext } from '@agentero/styles/tools';
import { RecipeVariantProps } from '@agentero/styles/types';

const cellSharedStyles = {
	verticalAlign: 'middle',
	paddingInline: '16',
	whiteSpace: 'nowrap',
	'&:first-of-type': {
		paddingInlineStart: '24'
	},
	'&:last-of-type': {
		paddingInlineEnd: '24'
	},
	'&:has([type="checkbox"])': {
		width: '0',
		paddingEnd: '0'
	}
};

const stickyHeaderSharedStyles = {
	'& > tr:first-of-type': {
		position: 'sticky',
		top: 0,
		zIndex: 1,
		background: 'bg',
		boxShadow: '0 0.03125rem 0 0 token(colors.border)'
	}
};

const stickyFooterSharedStyles = {
	'& > tr:last-of-type': {
		position: 'sticky',
		bottom: 0,
		zIndex: 1,
		background: 'bg',
		boxShadow: '0 -0.03125rem 0 0 token(colors.border)'
	}
};

export const tableStyles = sva({
	slots: ['root', 'scroll', 'table', 'head', 'body', 'row', 'header', 'cell'],
	base: {
		root: {
			'--border': '0.0625rem solid token(colors.border)',
			overflow: 'hidden'
		},
		scroll: {
			overflow: 'auto',
			WebkitOverflowScrolling: 'touch'
		},
		table: {
			width: '100%'
		},
		head: {},
		body: {
			'& tr': {
				borderTop: 'var(--border)',
				transition: 'background-color 0.15s',
				_hover: {
					backgroundColor: 'neutral.95'
				}
			}
		},
		row: {
			transition: 'background-color 0.15s'
		},
		header: {
			height: '3rem',
			textAlign: 'left',
			textStyle: 'caption.base',
			color: 'neutral.50',
			...cellSharedStyles
		},
		cell: {
			textStyle: 'body.small',
			...cellSharedStyles
		}
	},
	variants: {
		size: {
			xxs: {
				header: {
					height: '2rem',
					paddingInline: '2',
					paddingBlock: '2',
					'&:first-of-type': {
						paddingInlineStart: '4'
					},
					'&:last-of-type': {
						paddingInlineEnd: '4'
					}
				},
				cell: {
					paddingInline: '2',
					paddingBlock: '2',
					'&:first-of-type': {
						paddingInlineStart: '4'
					},
					'&:last-of-type': {
						paddingInlineEnd: '4'
					}
				}
			},
			xs: {
				header: {
					height: '2rem'
				},
				cell: {
					paddingBlock: '8'
				}
			},
			sm: {
				header: {
					height: '2.5rem'
				},
				cell: {
					paddingBlock: '16'
				}
			},
			md: {
				header: {
					height: '3rem'
				},
				cell: {
					paddingBlock: '24'
				}
			}
		},
		enclosed: {
			true: {
				root: {
					borderRadius: 'md',
					border: 'var(--border)'
				}
			}
		},
		sticky: {
			header: {
				head: stickyHeaderSharedStyles
			},
			footer: {
				body: stickyFooterSharedStyles
			},
			headerAndFooter: {
				head: stickyHeaderSharedStyles,
				body: stickyFooterSharedStyles
			}
		}
	},
	defaultVariants: {
		enclosed: true,
		size: 'md'
	}
});

const { withProvider, withContext } = createStyleContext(tableStyles);

export type TableRootProps = RecipeVariantProps<typeof tableStyles> & { maxHeight?: number };

const TableRootTable = withProvider('table', 'table');

const TableRoot = forwardRef<HTMLDivElement, PropsWithChildren<TableRootProps>>(
	({ children, maxHeight, ...props }, ref) => {
		const classes = tableStyles(props);

		return (
			<div className={classes.root}>
				<div
					className={classes.scroll}
					ref={ref}
					style={{ maxHeight: maxHeight ? `${maxHeight / 16}rem` : undefined }}>
					<TableRootTable {...props}>{children}</TableRootTable>
				</div>
			</div>
		);
	}
);

export const Table = {
	Root: TableRoot,
	Head: withContext('thead', 'head'),
	Body: withContext('tbody', 'body'),
	Row: withContext('tr', 'row'),
	Header: withContext('th', 'header'),
	Cell: withContext('td', 'cell')
};
