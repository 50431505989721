const dateObj: Intl.DateTimeFormatOptions = {
	month: '2-digit',
	year: 'numeric',
	day: '2-digit',
	hour: 'numeric',
	minute: 'numeric'
};

export const parseToTimeZone = (date: Date, language: string, timezone: string) => {
	return date.toLocaleDateString(language, { timeZone: timezone, ...dateObj });
};

const DEFAULT_TIMEZONE = 'America/Chicago';

export const formatTimeZone = (timeZone = DEFAULT_TIMEZONE) => {
	const timezone = timeZone === '' ? DEFAULT_TIMEZONE : timeZone;

	const date = new Date();

	const options: Intl.DateTimeFormatOptions = {
		timeZone: timezone,
		timeZoneName: 'short'
	};
	const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
	const parts = dateTimeFormat.formatToParts(date);

	const timeZoneName = parts.find(part => part.type === 'timeZoneName')!.value;

	const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
	const tzDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
	//@ts-ignore
	const offset = (tzDate - utcDate) / 60000;

	const offsetHours = Math.floor(offset / 60);
	const offsetMinutes = Math.abs(offset % 60);

	const offsetSign = offsetHours <= 0 ? '-' : '+';
	const formattedOffset = `${offsetSign} ${String(Math.abs(offsetHours)).padStart(2, '0')}:${String(
		offsetMinutes
	).padStart(2, '0')}`;

	return `${timeZoneName} ${formattedOffset}`;
};
