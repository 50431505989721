// Frontends
import * as pb from 'google-protobuf';
import { StreamInterceptor, UnaryInterceptor } from 'grpc-web';

import {
	AgencyServiceClient,
	CommissionServiceClient
} from '@agentero/grpc-clients/grpc/agency-fe';
import { AgentServiceClient } from '@agentero/grpc-clients/grpc/agent-fe';
import { AppointmentServiceClient } from '@agentero/grpc-clients/grpc/appointment-fe';
import { BindServiceClient } from '@agentero/grpc-clients/grpc/binding-fe';
import { CarrierServiceClient } from '@agentero/grpc-clients/grpc/carrier-fe';
import { LeadServiceClient } from '@agentero/grpc-clients/grpc/lead-fe';
import { NewsServiceClient } from '@agentero/grpc-clients/grpc/news-fe';
import { QuoteServiceClient } from '@agentero/grpc-clients/grpc/quote-fe';
import {
	RateApplicationServiceClient,
	RaterServiceClient
} from '@agentero/grpc-clients/grpc/rater-fe';
import { TierServiceClient } from '@agentero/grpc-clients/grpc/tier-fe';
import { VehicleServiceClient } from '@agentero/grpc-clients/grpc/vehicle-fe';

declare global {
	interface Window {
		__GRPCWEB_DEVTOOLS__?: any;
	}
}

type ServiceOptions = {
	unaryInterceptors: UnaryInterceptor<pb.Message, pb.Message>[];
	streamInterceptors: StreamInterceptor<pb.Message, pb.Message>[];
};

declare const __GRPC_WEB_DEVTOOLS__: (options?: null | ServiceOptions) => ServiceOptions;

const defaultTools = (options?: ServiceOptions) => options;

const grpcDevTools =
	typeof __GRPC_WEB_DEVTOOLS__ !== 'undefined' ? __GRPC_WEB_DEVTOOLS__ : defaultTools;

const unaryInterceptors: UnaryInterceptor<pb.Message, pb.Message>[] = [];
const streamInterceptors: StreamInterceptor<pb.Message, pb.Message>[] = [];

const serviceConfig = grpcDevTools({
	unaryInterceptors,
	streamInterceptors
});

// Frontends

const agencyFrontendService = new AgencyServiceClient(
	process.env.NEXT_PUBLIC_GRPC_URL,
	null,
	serviceConfig
);
const commissionsFrontendService = new CommissionServiceClient(
	process.env.NEXT_PUBLIC_GRPC_URL,
	null,
	serviceConfig
);
const carrierFrontendService = new CarrierServiceClient(
	process.env.NEXT_PUBLIC_GRPC_URL,
	null,
	serviceConfig
);
const agentFrontendService = new AgentServiceClient(
	process.env.NEXT_PUBLIC_GRPC_URL,
	null,
	serviceConfig
);
const raterService = new RaterServiceClient(process.env.NEXT_PUBLIC_GRPC_URL, null, serviceConfig);
const bindingService = new BindServiceClient(process.env.NEXT_PUBLIC_GRPC_URL, null, serviceConfig);
const vehicleService = new VehicleServiceClient(
	process.env.NEXT_PUBLIC_GRPC_URL,
	null,
	serviceConfig
);
const newsFrontendService = new NewsServiceClient(
	process.env.NEXT_PUBLIC_GRPC_URL,
	null,
	serviceConfig
);

// Services

const rateApplicationServiceClient = new RateApplicationServiceClient(
	process.env.NEXT_PUBLIC_GRPC_URL,
	null,
	serviceConfig
);

const appointmentServiceClient = new AppointmentServiceClient(
	process.env.NEXT_PUBLIC_GRPC_URL,
	null,
	serviceConfig
);

const tierServiceClient = new TierServiceClient(
	process.env.NEXT_PUBLIC_GRPC_URL,
	null,
	serviceConfig
);

const leadServiceClient = new LeadServiceClient(
	process.env.NEXT_PUBLIC_GRPC_URL,
	null,
	serviceConfig
);

const quoteService = new QuoteServiceClient(process.env.NEXT_PUBLIC_GRPC_URL, null, serviceConfig);

if (typeof window !== 'undefined') {
	const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});

	enableDevTools([
		agencyFrontendService,
		commissionsFrontendService,
		carrierFrontendService,
		agentFrontendService,
		raterService,
		vehicleService,
		rateApplicationServiceClient,
		bindingService,
		appointmentServiceClient,
		tierServiceClient,
		quoteService,
		leadServiceClient,
		newsFrontendService
	]);
}

export {
	agencyFrontendService,
	commissionsFrontendService,
	carrierFrontendService,
	agentFrontendService,
	raterService,
	vehicleService,
	rateApplicationServiceClient,
	bindingService,
	appointmentServiceClient,
	tierServiceClient,
	quoteService,
	leadServiceClient,
	newsFrontendService
};
