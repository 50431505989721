import { useEffect, useRef, useState } from 'react';

import { Box, Divider, HStack, Stack, styled } from '@agentero/styles/jsx';
import { Text } from '@agentero/ui';

import { Accordion } from '../../Accordion';
import { BaseSearch, Filter, Filters } from '../DataTableFilters';
import { SidebarFilterItem } from './dataTableSidebarFilters/SidebarFilterItem';
import { TextFilter } from './shared/TextFilter';

const DataTableSidebarFiltersRoot = styled('div', {
	base: {
		'--sidebar-width': '18.75rem',
		minWidth: 'var(--sidebar-width)',
		maxWidth: 'var(--sidebar-width)',
		position: 'sticky',
		top: '6.5rem',
		alignSelf: 'flex-start',
		height: 'calc(100vh - 20rem)',
		overflow: 'hidden auto',
		marginBlockStart: '-4',
		marginInline: '-4',
		paddingBlockStart: '4',
		paddingInline: '4'
	}
});

type DataTableSidebarFiltersProps<Search extends BaseSearch> = {
	filters: Filters<Search>;
	search: Search;
	onSearchChange: (search: Search) => void;
};

export const DataTableSidebarFilters = <Search extends BaseSearch>({
	search,
	filters,
	onSearchChange
}: DataTableSidebarFiltersProps<Search>) => {
	const rootRef = useRef<HTMLDivElement>(null);
	const [scrollTop, setScrollTop] = useState(0);

	const handleScroll = () => setScrollTop(rootRef.current?.scrollTop || 0);

	useEffect(() => {
		const currentRef = rootRef.current;
		currentRef?.addEventListener('scroll', handleScroll);

		return () => currentRef?.removeEventListener('scroll', handleScroll);
	});

	return (
		<DataTableSidebarFiltersRoot ref={rootRef}>
			{scrollTop > 0 && <Box position="sticky" top="-4" height="1px" backgroundColor="border" />}
			<Stack>
				<TextFilter
					search={search}
					filter={{
						key: 'searchText',
						type: 'text'
					}}
					onSearchChange={onSearchChange}
				/>

				<div>
					<Divider />
					<Accordion.Root type="multiple" defaultValue={filters.map(({ key }) => key)}>
						{filters.map(filter => (
							<Accordion.Item key={String(filter.key)} value={String(filter.key)}>
								<Accordion.Trigger>
									<HStack>
										<Text size="body.small">
											<b>{filter.label}</b>
										</Text>
										<SidebarFilterItem.Selected
											filter={filter as unknown as Filter<Search>}
											search={search}
										/>
									</HStack>
								</Accordion.Trigger>
								<Accordion.Content>
									<SidebarFilterItem.Content
										filter={filter as unknown as Filter<Search>}
										search={search}
										onSearchChange={onSearchChange}
									/>
								</Accordion.Content>
							</Accordion.Item>
						))}
					</Accordion.Root>
				</div>
			</Stack>
			<Box position="sticky" bottom="0" height="1px" backgroundColor="border" marginTop="-1" />
		</DataTableSidebarFiltersRoot>
	);
};
