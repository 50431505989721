import { FC, PropsWithChildren } from 'react';

import styled from '@emotion/styled';

type BoxProps = {
	hasBorder?: boolean; // Deprecated. It has no effect anymore.
	id?: string;
};

type BoxComponents = {
	Header: FC<PropsWithChildren> & BoxHeaderComponents;
	Body: FC<PropsWithChildren>;
};

type BoxContainerProps = Required<BoxProps>;

type BoxHeaderComponents = {
	Main: FC<PropsWithChildren>;
	Actions: FC<PropsWithChildren>;
};

const BoxContainer = styled.section<BoxContainerProps>`
	background: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.gray.lighten90};

	${({ theme }) => theme.mediaqueries.M} {
		margin: unset;
	}
`;

const BoxHeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 24px;
`;

const BoxHeaderMain = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const BoxHeaderActions = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;

	${({ theme }) => theme.mediaqueries.M} {
		gap: 16px;
	}
`;

const BoxHeader: FC<PropsWithChildren> & BoxHeaderComponents = ({ children, ...props }) => {
	return <BoxHeaderContainer {...props}>{children}</BoxHeaderContainer>;
};

BoxHeader.Main = BoxHeaderMain;
BoxHeader.Actions = BoxHeaderActions;

const BoxBody = styled.div`
	padding: 0 24px 24px;
`;

export const Box: FC<PropsWithChildren<BoxProps>> & BoxComponents = ({
	children,
	hasBorder = false,
	id = '',
	...props
}) => {
	return (
		<BoxContainer id={id} hasBorder={hasBorder} {...props}>
			{children}
		</BoxContainer>
	);
};

Box.Header = BoxHeader;
Box.Body = BoxBody;
