// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts,import_extension=none"
// @generated from file agentero/portal/session.proto (package agentero.portal, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetSessionRequest, GetSessionResponse } from "./session_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service agentero.portal.SessionService
 */
export const SessionService = {
  typeName: "agentero.portal.SessionService",
  methods: {
    /**
     * GetSession returns extra information about the current authenticated agent.
     *
     * @generated from rpc agentero.portal.SessionService.GetSession
     */
    getSession: {
      name: "GetSession",
      I: GetSessionRequest,
      O: GetSessionResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

