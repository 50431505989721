import { PropsWithChildren } from 'react';

import { getInputStatusFromError } from '@agentero/utils';

import { useAgFormContext } from '../Form';
import { Field, FieldGenericProps } from '../shared/Field';
import { getFieldError, getFieldErrorId } from '../shared/formGroupUtils';
import { CheckChips, CheckChipsValue } from './fieldCheckChips/CheckChips';
import { InputVariants } from './fieldText/Input';

type FieldTextProps<T> = {
	onGroupChange?: (value: string | boolean) => void;
	values: CheckChipsValue[];
	defaultValue?: string | boolean;
} & FieldGenericProps<T> &
	InputVariants;

export const FieldCheckChips = <T extends {}>({
	name,
	defaultValue,
	onGroupChange,
	children,
	values,
	...formGroupProps
}: PropsWithChildren<FieldTextProps<T>>) => {
	const {
		register,
		formState: { errors }
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	return (
		<Field
			name={name}
			nestedChildren={children}
			asFieldset
			//@ts-ignore
			getReadonlyValue={(inputValue: string[] = []) =>
				inputValue.length > 0
					? inputValue
							.map(value => values.find(options => options.value === value)?.label)
							.join(', ')
					: '—'
			}
			{...formGroupProps}>
			<CheckChips
				inputProps={{
					...register(name),
					'aria-errormessage': getFieldErrorId(name),
					'aria-invalid': !!fieldError?.message
				}}
				status={getInputStatusFromError(fieldError?.message as string)}
				values={values}
				defaultValue={defaultValue}
				onChange={onGroupChange}
			/>
		</Field>
	);
};
