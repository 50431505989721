import { Fragment, PropsWithChildren } from 'react';

import { getFieldError, useAgFormContext } from '@agentero/ui';
import { formatList } from '@agentero/utils';

import { Checkbox } from '../Checkbox';
import { FormBox } from '../FormBox';
import { FormGroup, FormGroupGenericProps } from './FormGroup';

type FormGroupInputTextProps<T> = {
	options: FormGroupMultiselectOption[];
	autoFocus?: boolean;
	disabled?: boolean;
} & FormGroupGenericProps<T>;

type FormGroupMultiselectOption = {
	label: string;
	value: string;
};

export const FormGroupMultiselect = <T extends {}>({
	name,
	autoFocus,
	disabled,
	options,
	children,
	...formGroupProps
}: PropsWithChildren<FormGroupInputTextProps<T>>) => {
	const {
		register,
		formState: { errors }
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	const getReadonlyValue = (values: unknown) => {
		const list = (values as unknown as string[]).map(
			value => options.find(option => option.value === value)!.label
		);

		return list.length > 0 ? formatList(list) : '—';
	};

	const Box =
		options.length > 4
			? ({ children }: PropsWithChildren) => <FormBox error={!!fieldError}>{children}</FormBox>
			: Fragment;

	return (
		<FormGroup
			name={name}
			nestedChildren={children}
			getReadonlyValue={getReadonlyValue}
			{...formGroupProps}>
			<Box>
				{options.map(({ label, value }, index) => (
					<Checkbox
						key={value}
						{...register(name)}
						id={`${name}-${value}`}
						value={value}
						autoFocus={autoFocus && index === 0}
						disabled={disabled}>
						{label}
					</Checkbox>
				))}
			</Box>
		</FormGroup>
	);
};
