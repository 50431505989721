import { ChangeEvent, PropsWithChildren } from 'react';

import { getFieldError, getFieldErrorId, removeAllDots, useAgFormContext } from '@agentero/ui';
import { getInputStatusFromError } from '@agentero/utils';

import { TextArea, TextAreaProps } from '../TextArea';
import { FormGroup, FormGroupGenericProps } from './FormGroup';

type FormGroupInputTextProps<T> = {
	placeholder?: string;
	autoFocus?: boolean;
	maxLength?: number;
	disabled?: boolean;
	onChange?: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined;
	defaultValue?: string | number | undefined;
} & Omit<TextAreaProps, 'status'> &
	FormGroupGenericProps<T>;

export const FormGroupTextArea = <T extends {}>({
	name,
	placeholder,
	autoFocus,
	maxLength,
	disabled,
	onChange,
	children,
	defaultValue,
	minHeight,
	...formGroupProps
}: PropsWithChildren<FormGroupInputTextProps<T>>) => {
	const {
		register,
		formState: { errors }
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	return (
		<FormGroup name={name} nestedChildren={children} {...formGroupProps}>
			<TextArea
				id={removeAllDots(name)}
				{...register(name, { onChange })}
				status={getInputStatusFromError(fieldError?.message as string)}
				placeholder={placeholder}
				aria-invalid={!!fieldError?.message}
				aria-errormessage={getFieldErrorId(name)}
				autoFocus={autoFocus}
				maxLength={maxLength}
				disabled={disabled}
				defaultValue={defaultValue}
				minHeight={minHeight}
			/>
		</FormGroup>
	);
};
