import { styled } from '@agentero/styles/jsx';
import { SpacingToken } from '@agentero/styles/tokens';

type SparksProps = {
	size?: SpacingToken;
};

const SparksRoot = styled('svg', {});

const SparkBig = styled('path', {
	base: {
		fill: 'neutral',
		transformOrigin: 'center',
		transformBox: 'fill-box',
		animation: 'spinAround 1s infinite ease-in-out'
	}
});

const SparkSmallTop = styled('path', {
	base: {
		fill: 'neutral',
		transformOrigin: 'center',
		transformBox: 'fill-box',
		animation: 'spinAround 1s infinite ease-in-out',
		animationDelay: '.1s'
	}
});

const SparkSmallBottom = styled('path', {
	base: {
		fill: 'neutral',
		transformOrigin: 'center',
		transformBox: 'fill-box',
		animation: 'spinAround 1s infinite ease-in-out',
		animationDelay: '.1s'
	}
});

export const Sparks = ({ size = '32' }: SparksProps) => (
	<SparksRoot
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}>
		<SparkBig d="M6.225 16.625C6.09167 16.625 5.9625 16.5875 5.8375 16.5125C5.7125 16.4375 5.61667 16.3333 5.55 16.2L3.925 12.725L0.425 11.15C0.291667 11.0833 0.1875 10.9875 0.1125 10.8625C0.0375 10.7375 0 10.6083 0 10.475C0 10.3417 0.0375 10.2125 0.1125 10.0875C0.1875 9.96251 0.291667 9.86668 0.425 9.80001L3.925 8.22501L5.55 4.77501C5.61667 4.62501 5.7125 4.51251 5.8375 4.43751C5.9625 4.36251 6.09167 4.32501 6.225 4.32501C6.35833 4.32501 6.4875 4.36251 6.6125 4.43751C6.7375 4.51251 6.83333 4.61668 6.9 4.75001L8.55 8.22501L12.025 9.80001C12.175 9.86668 12.2875 9.96251 12.3625 10.0875C12.4375 10.2125 12.475 10.3417 12.475 10.475C12.475 10.6083 12.4375 10.7375 12.3625 10.8625C12.2875 10.9875 12.175 11.0833 12.025 11.15L8.55 12.725L6.9 16.2C6.83333 16.35 6.7375 16.4583 6.6125 16.525C6.4875 16.5917 6.35833 16.625 6.225 16.625ZM6.225 13.95L7.425 11.55L9.875 10.475L7.425 9.40001L6.225 7.00001L5.05 9.40001L2.575 10.475L5.05 11.55L6.225 13.95Z" />
		<SparkSmallTop d="M17.2 20.95C17.1333 20.95 17.0666 20.9334 17 20.9C16.9333 20.8667 16.8833 20.8084 16.85 20.725L16.125 19.1L14.325 18.325C14.2416 18.2917 14.1833 18.2459 14.15 18.1875C14.1166 18.1292 14.1 18.0584 14.1 17.975C14.1 17.8917 14.1166 17.8209 14.15 17.7625C14.1833 17.7042 14.2416 17.6584 14.325 17.625L16.125 16.85L16.85 15.125C16.8833 15.0417 16.9291 14.9834 16.9875 14.95C17.0458 14.9167 17.1166 14.9 17.2 14.9C17.2833 14.9 17.3541 14.9167 17.4125 14.95C17.4708 14.9834 17.5166 15.0417 17.55 15.125L18.275 16.85L20.075 17.625C20.1583 17.6584 20.2166 17.7042 20.25 17.7625C20.2833 17.8209 20.3 17.8917 20.3 17.975C20.3 18.0584 20.2833 18.1292 20.25 18.1875C20.2166 18.2459 20.1583 18.2917 20.075 18.325L18.275 19.1L17.55 20.725C17.5166 20.8084 17.4666 20.8667 17.4 20.9C17.3333 20.9334 17.2666 20.95 17.2 20.95Z" />
		<SparkSmallBottom d="M17.2001 6.05C17.1167 6.05 17.0459 6.03333 16.9876 6C16.9292 5.96667 16.8834 5.90833 16.8501 5.825L16.1251 4.125L14.3001 3.325C14.2167 3.29167 14.1584 3.24583 14.1251 3.1875C14.0917 3.12917 14.0751 3.05833 14.0751 2.975C14.0751 2.89167 14.0917 2.82083 14.1251 2.7625C14.1584 2.70417 14.2167 2.65833 14.3001 2.625L16.1251 1.85L16.8501 0.225C16.8834 0.141667 16.9292 0.0833333 16.9876 0.05C17.0459 0.0166667 17.1167 0 17.2001 0C17.2834 0 17.3542 0.0166667 17.4126 0.05C17.4709 0.0833333 17.5167 0.141667 17.5501 0.225L18.2751 1.85L20.1001 2.625C20.1834 2.65833 20.2417 2.70417 20.2751 2.7625C20.3084 2.82083 20.3251 2.89167 20.3251 2.975C20.3251 3.05833 20.3084 3.12917 20.2751 3.1875C20.2417 3.24583 20.1834 3.29167 20.1001 3.325L18.2751 4.125L17.5501 5.825C17.5167 5.90833 17.4709 5.96667 17.4126 6C17.3542 6.03333 17.2834 6.05 17.2001 6.05Z" />
	</SparksRoot>
);
