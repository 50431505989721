import styled from '@emotion/styled';

import { ITheme } from '@agentero/styles/emotion';

import { FieldStatus } from '../form/fieldStatus';

const defaultVariant = (theme: ITheme) => `
	background-color: ${theme.colors.gray.lighten80};
`;
const successVariant = (theme: ITheme) => `
	background-color: ${theme.colors.primary.base};
`;
const errorVariant = (theme: ITheme) => `
	background-color: ${theme.colors.status.error.base};
`;
const warningVariant = (theme: ITheme) => `
	background-color: ${theme.colors.status.warning.base};
`;
const infoVariant = (theme: ITheme) => `
	background-color: ${theme.colors.status.info.base};
`;

const statusVariants: { [key in FieldStatus]: (theme: ITheme) => string } = {
	[FieldStatus.None]: defaultVariant,
	[FieldStatus.Success]: successVariant,
	[FieldStatus.Error]: errorVariant,
	[FieldStatus.Warning]: warningVariant,
	[FieldStatus.Info]: infoVariant
};

type VariantType = ({ status, theme }: { status?: FieldStatus; theme: ITheme }) => string;

const getStatusVariant: VariantType = ({ status = FieldStatus.Warning, theme }) => {
	const variant = statusVariants[status];
	return variant(theme);
};

export const ModalStatusbar = styled.div<{ status?: FieldStatus }>`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 8px;
	${getStatusVariant}

	+ div {
		padding-left: 8px;
	}

	${({ theme }) => theme.mediaqueries.M} {
		width: 40px;

		+ div {
			padding-left: 40px;
		}
	}
`;
