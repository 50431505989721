export const HEXToRGB = (value: string) => {
	if (value.length != 6) {
		throw 'Only six-digit hex colors are allowed.';
	}

	const aRgbHex = value.match(/.{1,2}/g);

	if (!aRgbHex) {
		throw 'The hex value is not correct.';
	}

	const aRgb = [parseInt(aRgbHex[0], 16), parseInt(aRgbHex[1], 16), parseInt(aRgbHex[2], 16)];

	return aRgb;
};

export const HEXtoRGBA = (value: string) => {
	const rgb = HEXToRGB(value);
	const r = rgb[0];
	const g = rgb[1];
	const b = rgb[2];
	const a = 1 - Math.min(r, Math.min(g, b)) / 255;
	const rgba = [255 + (r - 255) / a, 255 + (g - 255) / a, 255 + (b - 255) / a, a];
	return `rgba(${rgba[0]},${rgba[1]},${rgba[2]},${rgba[3]})`;
};
