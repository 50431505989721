import { PropsWithChildren, ReactElement, useReducer } from 'react';

import styled from '@emotion/styled';

import { DatalistContext } from './datalist/DatalistContext';
import { DatalistAction, DatalistState, datalistReducer } from './datalist/datalistReducer';

type DatalistInputProps = {
	onFocus: () => void;
	onBlur: () => void;
	onChange: () => void;
};

type DatalistProps = {
	input: (props: DatalistInputProps) => ReactElement;
	inputValueLength: number;
	inputValueMinLength?: number;
};

const initialState: DatalistState = {
	list: {
		isOpen: false,
		focusedIndex: 0
	}
};

const DatalistContainer = styled.div`
	position: relative;
`;

const DatalistPanel = styled.div`
	position: absolute;
	top: 100%;
	right: 0;
	width: 100%;
	background: ${({ theme }) => theme.colors.white};
	border: solid 1px ${({ theme }) => theme.colors.gray.lighten80};
	z-index: 10;
`;

export const Datalist = ({
	input,
	children,
	inputValueLength,
	inputValueMinLength = 1
}: PropsWithChildren<DatalistProps>) => {
	const [state, dispatch] = useReducer(datalistReducer, initialState);
	const { focusedIndex, isOpen } = state.list;

	const onFocus = () => dispatch({ type: DatalistAction.SetIsPanelOpen, payload: true });
	const onBlur = () => dispatch({ type: DatalistAction.SetIsPanelOpen, payload: false });
	const onSearchChange = () => dispatch({ type: DatalistAction.OnInputChange });

	return (
		<DatalistContainer>
			<DatalistContext.Provider value={{ focusedIndex, dispatch }}>
				{input({ onFocus, onBlur, onChange: onSearchChange })}
				{isOpen && inputValueLength >= inputValueMinLength && (
					<DatalistPanel>{children}</DatalistPanel>
				)}
			</DatalistContext.Provider>
		</DatalistContainer>
	);
};
