import { PropsWithChildren, forwardRef } from 'react';

import { useCheckbox } from '@react-aria/checkbox';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps, useObjectRef } from '@react-aria/utils';
import { useToggleState } from '@react-stately/toggle';

import { CheckboxComponent, CheckboxComponentProps } from './checkbox/CheckboxComponent';

export type CheckboxProps = Omit<CheckboxComponentProps, 'inputProps'>;

export const Checkbox = forwardRef<HTMLInputElement, PropsWithChildren<CheckboxProps>>(
	(props, ref) => {
		const { children } = props;

		const state = useToggleState(props);
		const objRef = useObjectRef(ref);
		const { inputProps } = useCheckbox(props, state, objRef);
		const { focusProps } = useFocusRing();

		return (
			<CheckboxComponent
				{...props}
				ref={objRef}
				inputProps={mergeProps(inputProps, focusProps)}
				isSelected={state.isSelected}>
				{children}
			</CheckboxComponent>
		);
	}
);
