import { PropsWithChildren } from 'react';

import styled from '@emotion/styled';

import { IconInfoOutline } from '@agentero/icons';
import { Tooltip } from '@agentero/ui';

import { Text, TextProps } from './Typography';

type InfoTextProps = {
	tooltip: string | React.ReactNode;
} & TextProps;

const InfoTextTooltipContainer = styled.span`
	display: inline-flex;
	vertical-align: middle;
	margin-top: -0.2%;
	margin-bottom: -0.1%;
	margin-left: ${({ theme }) => theme.sizes.getSize(0.5)};

	svg {
		height: ${({ theme }) => theme.sizes.getSize(3)};
		width: ${({ theme }) => theme.sizes.getSize(3)};
		padding: ${({ theme }) => theme.sizes.getSize(0.5)};
	}
`;

export const InfoText = ({ tooltip, children, ...props }: PropsWithChildren<InfoTextProps>) => (
	<Text {...props}>
		{children}
		<InfoTextTooltipContainer>
			<Tooltip content={tooltip}>
				<IconInfoOutline role="img" aria-label="Info" />
			</Tooltip>
		</InfoTextTooltipContainer>
	</Text>
);
