import { Flex, Stack } from '@agentero/styles/jsx';

import { Button } from '../../../../Button';
import { CheckboxGroup } from '../../../../inputs/CheckboxGroup';
import { BaseSearch } from '../../../DataTableFilters';
import { MultipleCheckboxFilterProps } from '../../shared/MultipleCheckboxFilter';

export const SidebarMultipleCheckboxFilter = <Search extends BaseSearch>({
	filter,
	search,
	onSearchChange
}: MultipleCheckboxFilterProps<Search>) => {
	const { options, label } = filter;
	const value = search[filter.key] as string[];

	const onGroupChange = (values: string[]) => {
		const newSearch = { ...search, [filter.key]: values };

		onSearchChange(newSearch);
	};

	const clearFilter = () => {
		const newSearch = { ...search, [filter.key]: [] };

		onSearchChange(newSearch);
	};

	return (
		<Stack>
			<CheckboxGroup.Root
				label={''}
				aria-label={label}
				value={value || null}
				onChange={onGroupChange}>
				{options.map(({ value, label }) => (
					<CheckboxGroup.Item key={value} value={value}>
						{label}
					</CheckboxGroup.Item>
				))}
			</CheckboxGroup.Root>
			<Flex>
				<Button variant="link" onClick={clearFilter}>
					Clear filter
				</Button>
			</Flex>
		</Stack>
	);
};
