import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useEffectSkipFirst = (effect: EffectCallback, deps: DependencyList) => {
	const skipEffect = useRef(true);
	useEffect(() => {
		if (!skipEffect.current) {
			effect();
		}

		skipEffect.current = false;
	}, deps);
};
