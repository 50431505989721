import { HTMLInputTypeAttribute, PropsWithChildren } from 'react';

import { Controller, RegisterOptions } from 'react-hook-form';

import { getFieldError, getFieldErrorId, removeAllDots, useAgFormContext } from '@agentero/ui';
import { getInputStatusFromError } from '@agentero/utils';

import { InputTel } from '../InputTel';
import { FormGroup, FormGroupGenericProps } from './FormGroup';

type FormGroupInputPhoneNumberProps<T extends {}> = {
	type?: HTMLInputTypeAttribute;
	placeholder?: string;
	autoFocus?: boolean;
	registerOptions?: RegisterOptions<T>;
	displayErrorMessage?: boolean;
	defaultValue?: string;
} & FormGroupGenericProps<T>;

export const FormGroupInputPhoneNumber = <T extends {}>({
	name,
	registerOptions,
	displayErrorMessage,
	placeholder,
	autoFocus,
	children,
	disabled,
	defaultValue,
	...formGroupProps
}: PropsWithChildren<FormGroupInputPhoneNumberProps<T>>) => {
	const {
		control,
		formState: { errors }
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	return (
		<FormGroup
			name={name}
			nestedChildren={children}
			{...formGroupProps}
			displayErrorMessage={displayErrorMessage}>
			<Controller
				rules={registerOptions}
				name={name}
				control={control}
				render={({ field: fieldProps }) => (
					<InputTel
						id={removeAllDots(name)}
						status={getInputStatusFromError(fieldError?.message)}
						placeholder={placeholder}
						aria-invalid={!!fieldError?.message}
						aria-errormessage={getFieldErrorId(name)}
						autoFocus={autoFocus}
						disabled={disabled}
						defaultValue={defaultValue}
						{...fieldProps}
					/>
				)}
			/>
		</FormGroup>
	);
};
