import { StatusColor } from '@agentero/components';
import { LicenseGlobalStatus } from '@agentero/grpc-clients/grpc/shared/license';
import { LicenseGlobalStatus as LicenseGlobalStatusConnect } from '@agentero/service-clients/portal/shared/license';

export enum ComplianceLicenseGlobalStatus {
	None = 'none',
	NotFound = 'not_found',
	Invalid = 'invalid',
	Valid = 'valid',
	ResidentStatusInvalid = 'resident_status_invalid'
}

const complianceGlobalStatusMap: { [key in LicenseGlobalStatus]: ComplianceLicenseGlobalStatus } = {
	[LicenseGlobalStatus.LICENSE_GLOBAL_STATUS_UNSPECIFIED]: ComplianceLicenseGlobalStatus.None,
	[LicenseGlobalStatus.LICENSE_GLOBAL_STATUS_NOT_FOUND]: ComplianceLicenseGlobalStatus.NotFound,
	[LicenseGlobalStatus.LICENSE_GLOBAL_STATUS_INVALID]: ComplianceLicenseGlobalStatus.Invalid,
	[LicenseGlobalStatus.LICENSE_GLOBAL_STATUS_VALID]: ComplianceLicenseGlobalStatus.Valid
};

export const parseComplianceGlobalStatus = (
	status: LicenseGlobalStatus
): ComplianceLicenseGlobalStatus => complianceGlobalStatusMap[status];

const complianceGlobalStatusKeys: { [key in ComplianceLicenseGlobalStatus]: string } = {
	[ComplianceLicenseGlobalStatus.None]: '',
	[ComplianceLicenseGlobalStatus.NotFound]: 'compliance.license.globalStatus.notFound',
	[ComplianceLicenseGlobalStatus.Invalid]: 'compliance.license.globalStatus.invalid',
	[ComplianceLicenseGlobalStatus.Valid]: 'compliance.license.globalStatus.valid',
	[ComplianceLicenseGlobalStatus.ResidentStatusInvalid]:
		'compliance.license.globalStatus.residentStatusInvalid'
};

export const getComplianceGlobalStatusKey = (status: ComplianceLicenseGlobalStatus) =>
	complianceGlobalStatusKeys[status];

const complianceGlobalStatusStatusColor: { [key in ComplianceLicenseGlobalStatus]: StatusColor } = {
	[ComplianceLicenseGlobalStatus.None]: StatusColor.Neutral,
	[ComplianceLicenseGlobalStatus.NotFound]: StatusColor.Error,
	[ComplianceLicenseGlobalStatus.Invalid]: StatusColor.Error,
	[ComplianceLicenseGlobalStatus.Valid]: StatusColor.Success,
	[ComplianceLicenseGlobalStatus.ResidentStatusInvalid]: StatusColor.Error
};

export const getComplianceGlobalStatusStatusColor = (status: ComplianceLicenseGlobalStatus) =>
	complianceGlobalStatusStatusColor[status];

const complianceGlobalStatusMapConnect: {
	[key in LicenseGlobalStatusConnect]: ComplianceLicenseGlobalStatus;
} = {
	[LicenseGlobalStatusConnect.UNSPECIFIED]: ComplianceLicenseGlobalStatus.None,
	[LicenseGlobalStatusConnect.NOT_FOUND]: ComplianceLicenseGlobalStatus.NotFound,
	[LicenseGlobalStatusConnect.INVALID]: ComplianceLicenseGlobalStatus.Invalid,
	[LicenseGlobalStatusConnect.VALID]: ComplianceLicenseGlobalStatus.Valid
};

export const parseComplianceGlobalStatusConnect = (
	status: LicenseGlobalStatusConnect
): ComplianceLicenseGlobalStatus => complianceGlobalStatusMapConnect[status];
