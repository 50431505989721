import styled from '@emotion/styled';

export const Filters = styled.div`
	position: relative;
	z-index: 2;
`;

export const FiltersContent = styled.div`
	position: relative;
	margin-bottom: 8px;

	&:after {
		content: '';
		clear: both;
		display: table;
	}
`;

export const FiltersLeft = styled.div`
	position: static;
`;

export const FiltersText = styled.div`
	position: static;
	display: flex;
	align-items: center;
`;

export const FiltersRight = styled.div`
	float: right;
	display: flex;
	margin: 0 0 8px 8px;
`;

export const FiltersPreContent = styled.div`
	margin-bottom: 12px;
`;

export const FiltersLegend = styled.div`
	margin-right: 16px;
`;

export const FiltersMenuContainer = styled.div`
	float: left;
	margin: 0 8px 8px 0;
`;
