import { PropsWithChildren } from 'react';

import { Controller } from 'react-hook-form';

import { getFieldError, getFieldErrorId, removeAllDots, useAgFormContext } from '@agentero/ui';
import { getInputStatusFromError } from '@agentero/utils';

import { InputCurrency } from '../InputCurrency';
import { FormGroup, FormGroupGenericProps } from './FormGroup';

type FormGroupInputCurrencyProps<T> = FormGroupGenericProps<T>;

export const FormGroupInputCurrency = <T extends {}>({
	name,
	children,
	...formGroupProps
}: PropsWithChildren<FormGroupInputCurrencyProps<T>>) => {
	const {
		control,
		formState: { errors }
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	return (
		<FormGroup name={name} nestedChildren={children} {...formGroupProps}>
			<Controller
				name={name}
				control={control}
				render={({ field: { onChange, onBlur, name, value, ref } }) => (
					<InputCurrency
						id={removeAllDots(name)}
						status={getInputStatusFromError(fieldError?.message as string)}
						placeholder="0.00"
						name={name}
						value={value}
						onBlur={onBlur}
						ref={ref}
						onChange={onChange}
						aria-invalid={!!fieldError?.message}
						aria-errormessage={getFieldErrorId(name)}
					/>
				)}
			/>
		</FormGroup>
	);
};
