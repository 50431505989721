import { AlertStatus } from '@agentero/components';
import { ComplianceError } from '@agentero/grpc-clients/grpc/agency-fe';

export type ComplianceLicenseError = {
	message: string;
	status: AlertStatus;
};

export const parseComplianceLicenseError = (
	complianceError?: ComplianceError.AsObject
): ComplianceLicenseError | undefined =>
	complianceError
		? {
				message: complianceError.message,
				status: parseComplianceStatus(complianceError.level)
		  }
		: undefined;

const parseComplianceStatus = (level: ComplianceError.Level) => complianceStatusMapping[level];

const complianceStatusMapping: { [key in ComplianceError.Level]: AlertStatus } = {
	[ComplianceError.Level.LEVEL_ERROR]: AlertStatus.Error,
	[ComplianceError.Level.LEVEL_INFO]: AlertStatus.Info,
	[ComplianceError.Level.LEVEL_WARNING]: AlertStatus.Warning,
	[ComplianceError.Level.LEVEL_UNSPECIFIED]: AlertStatus.Neutral
};
