import { DetailedHTMLProps, LabelHTMLAttributes, PropsWithChildren, ReactNode } from 'react';

import styled from '@emotion/styled';

import { IconHelp } from '@agentero/icons';
import { Tooltip } from '@agentero/ui';

import { Space } from '../Space';

type LabelProps = DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
	optional?: boolean;
	info?: ReactNode;
	readonly?: boolean;
};

const LabelContainer = styled.label<LabelProps>`
	display: flex;
	margin-bottom: 4px;
	color: ${({ theme, readonly }) =>
		readonly ? theme.colors.gray.lighten40 : theme.colors.gray.base};
	${({ theme }) => theme.textSettings.S};
	${({ theme }) => theme.fontWeight.regular};

	${({ theme, optional = false }) =>
		optional &&
		`
		:after {
			content: ' (optional)';
			display: contents;
			color: ${theme.colors.gray.lighten40};
		}
	`}
`;

const LabelComponent = ({ children, readonly = false, ...props }: Omit<LabelProps, 'info'>) => {
	return (
		<LabelContainer as={readonly ? 'dt' : 'label'} readonly={readonly} {...props}>
			{children}
		</LabelContainer>
	);
};

export const Label = ({ info, ...props }: PropsWithChildren<LabelProps>) => {
	if (info) {
		return (
			<Space spacing={2} alignItems="start">
				<LabelComponent {...props} />

				<Tooltip content={info}>
					<IconHelp css={{ width: 20, height: 20, margin: 2 }} />
				</Tooltip>
			</Space>
		);
	}

	return <LabelComponent {...props} />;
};
