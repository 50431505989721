import styled from '@emotion/styled';

import { getStepContainerStyles } from './Step';

const NonNumericStepContainer = styled.li`
	${getStepContainerStyles}
`;

const NonNumericStepDotsContainer = styled.div`
	--container-size: ${({ theme }) => theme.sizes.getSize(4)};

	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: var(--container-size);
	height: var(--container-size);
`;

const NonNumericStepDots = styled.div`
	--container-size: ${({ theme }) => theme.sizes.getSize(0.75)};

	width: var(--container-size);
	height: var(--container-size);
	background-color: ${({ theme }) => theme.colors.gray.lighten80};
	border-radius: 50%;

	&:before,
	&:after {
		content: '';
		position: absolute;
		display: block;
		width: var(--container-size);
		height: var(--container-size);
		background-color: ${({ theme }) => theme.colors.gray.lighten80};
		border-radius: 50%;
	}

	&:before {
		left: 0;
	}

	&:after {
		right: 0;
	}
`;

export const NonNumericStep = () => {
	return (
		<NonNumericStepContainer aria-current="false">
			<NonNumericStepDotsContainer>
				<NonNumericStepDots
					role="status"
					aria-label="number of steps in between still undetermined"
				/>
			</NonNumericStepDotsContainer>
		</NonNumericStepContainer>
	);
};
