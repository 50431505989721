import {
	EAndOInvalidReasons,
	EAndOStatus,
	EandOValidation,
	GetEandOResponse
} from '@agentero/grpc-clients/grpc/agency-fe';
import { EAndOStatus as EAndOStatusConnect } from '@agentero/service-clients/portal/agency';
import { getDateFromGrpc } from '@agentero/utils';

import { parseLinesOfAuthorityListFromProto } from '../compliance/complianceLicense/LineOfAuthority';
import { LineOfAuthority } from '../shared';
import { EOPolicyType, parseEOPolicyTypeFromProto } from './AgencyEOPolicyType';

export enum EOStatus {
	Unknown,
	Invalid,
	InReview,
	Approved
}

export enum EOInvalidReasons {
	DataError = 'data-error',
	WrongCoverageType = 'wrong-coverage-type',
	WrongCoverageProfession = 'wrong-coverage-profession',
	WrongInsuredName = 'wrong-insured-name',
	WrongDeductible = 'wrong-deductible',
	OtherReason = 'other-reason',
	EmptyEO = 'empty-eo'
}

export type AgencyEOLicenseData = {
	expirationDate: Date;
	coveragePerClaimLimit: number;
	carrier: string;
	policyNumber: string;
	linesOfAuthorityList: LineOfAuthority[];
	pdfUrl?: string;
};

export enum AgencyEODataError {
	ExpirationDate = 'expiration-date',
	CoveragePerClaimLimit = 'coverage-per-claim-limit',
	LineOfAuthorityError = 'line-of-authority-error'
}

export const lineOfAuthorityValidInEO = [LineOfAuthority.Personal, LineOfAuthority.Commercial];

export const coveragePerClaimLimitMin = 1000000;

export const eoExpirationDateExcess = new Date();
eoExpirationDateExcess.setMonth(eoExpirationDateExcess.getMonth() + 15);
eoExpirationDateExcess.setHours(0, 0, 0, 0);

export const eoExpirationDate = new Date();
eoExpirationDate.setDate(eoExpirationDate.getDate());
eoExpirationDate.setHours(0, 0, 0, 0);

export const validateAgencyEOLicenseData = (
	agencyEOLicenseData: AgencyEOLicenseData
): AgencyEODataError[] => {
	const result = [] as AgencyEODataError[];

	const dateValue = new Date(agencyEOLicenseData.expirationDate);
	dateValue.setHours(0, 0, 0, 0);

	if (
		eoExpirationDate.getTime() >= dateValue.getTime() ||
		dateValue.getTime() > eoExpirationDateExcess.getTime()
	) {
		result.push(AgencyEODataError.ExpirationDate);
	}

	if (agencyEOLicenseData.coveragePerClaimLimit < coveragePerClaimLimitMin) {
		result.push(AgencyEODataError.CoveragePerClaimLimit);
	}

	if (
		!agencyEOLicenseData.linesOfAuthorityList.some(loa => lineOfAuthorityValidInEO.includes(loa))
	) {
		result.push(AgencyEODataError.LineOfAuthorityError);
	}

	return result;
};
export type AgencyEO = AgencyEOLicenseData & {
	status: EOStatus;
	fullPolicyRequired?: boolean;
	policyType?: EOPolicyType;
	invalidReasons: EOInvalidReasons[];
	otherInvalidReason?: string;
};

const eoStatusFromProtoMap: { [key in EAndOStatus]: EOStatus } = {
	[EAndOStatus.E_AND_O_STATUS_UNSPECIFIED]: EOStatus.Unknown,
	[EAndOStatus.E_AND_O_STATUS_INVALID]: EOStatus.Invalid,
	[EAndOStatus.E_AND_O_STATUS_IN_REVIEW]: EOStatus.InReview,
	[EAndOStatus.E_AND_O_STATUS_VALID]: EOStatus.Approved
};

export const parseEOStatus = (eaoStatus: EAndOStatus): EOStatus => eoStatusFromProtoMap[eaoStatus];

/* -------- connect --------*/

export const parseEOStatusConnect = (eaoStatus: EAndOStatusConnect): EOStatus =>
	eoStatusFromProtoMap[eaoStatus];

const eoStatusToProtoMap: { [key in EOStatus]: EAndOStatus } = {
	[EOStatus.Unknown]: EAndOStatus.E_AND_O_STATUS_UNSPECIFIED,
	[EOStatus.Invalid]: EAndOStatus.E_AND_O_STATUS_INVALID,
	[EOStatus.InReview]: EAndOStatus.E_AND_O_STATUS_IN_REVIEW,
	[EOStatus.Approved]: EAndOStatus.E_AND_O_STATUS_VALID
};

export const parseEOStatusToProto = (eaoStatus: EOStatus): EAndOStatus =>
	eoStatusToProtoMap[eaoStatus];

const eoInvalidReasonsFromProtoMap: { [key in EAndOInvalidReasons]: EOInvalidReasons } = {
	[EAndOInvalidReasons.E_AND_O_INVALID_REASON_UNSPECIFIED]: EOInvalidReasons.DataError,
	[EAndOInvalidReasons.E_AND_O_INVALID_REASON_WRONG_DATA]: EOInvalidReasons.DataError,
	[EAndOInvalidReasons.E_AND_O_INVALID_REASON_WRONG_COVERAGE_TYPE]:
		EOInvalidReasons.WrongCoverageType,
	[EAndOInvalidReasons.E_AND_O_INVALID_REASON_WRONG_COVERAGE_PROFESSION]:
		EOInvalidReasons.WrongCoverageProfession,
	[EAndOInvalidReasons.E_AND_O_INVALID_REASON_WRONG_INSURED_NAME]:
		EOInvalidReasons.WrongInsuredName,
	[EAndOInvalidReasons.E_AND_O_INVALID_REASON_WRONG_DEDUCTIBLE]: EOInvalidReasons.WrongDeductible,
	[EAndOInvalidReasons.E_AND_O_INVALID_REASON_WRONG_LOA]: EOInvalidReasons.DataError,
	[EAndOInvalidReasons.E_AND_O_INVALID_REASON_OTHER]: EOInvalidReasons.OtherReason
};

const parseEOInvalidReasons = (eaoInvalidReason: EAndOInvalidReasons): EOInvalidReasons =>
	eoInvalidReasonsFromProtoMap[eaoInvalidReason];

const eoInvalidReasonsToProtoMap: { [key in EOInvalidReasons]: EAndOInvalidReasons } = {
	[EOInvalidReasons.DataError]: EAndOInvalidReasons.E_AND_O_INVALID_REASON_WRONG_DATA,
	[EOInvalidReasons.WrongCoverageType]:
		EAndOInvalidReasons.E_AND_O_INVALID_REASON_WRONG_COVERAGE_TYPE,
	[EOInvalidReasons.WrongCoverageProfession]:
		EAndOInvalidReasons.E_AND_O_INVALID_REASON_WRONG_COVERAGE_PROFESSION,
	[EOInvalidReasons.WrongInsuredName]:
		EAndOInvalidReasons.E_AND_O_INVALID_REASON_WRONG_INSURED_NAME,
	[EOInvalidReasons.WrongDeductible]: EAndOInvalidReasons.E_AND_O_INVALID_REASON_WRONG_DEDUCTIBLE,
	[EOInvalidReasons.OtherReason]: EAndOInvalidReasons.E_AND_O_INVALID_REASON_OTHER,
	[EOInvalidReasons.EmptyEO]: EAndOInvalidReasons.E_AND_O_INVALID_REASON_UNSPECIFIED
};

export const parseEOInvalidReasonsToProto = (
	eaoInvalidReason: EOInvalidReasons
): EAndOInvalidReasons => eoInvalidReasonsToProtoMap[eaoInvalidReason];

export const parseAgencyEO = (eando: GetEandOResponse.AsObject): AgencyEO => ({
	policyNumber: eando.licenseInfo?.policyNumber ?? '',
	status: eando.validation ? parseEOStatus(eando.validation!.status) : EOStatus.Invalid,
	expirationDate: eando.licenseInfo
		? getDateFromGrpc(eando.licenseInfo.expirationDate)
		: new Date(),
	coveragePerClaimLimit: eando.licenseInfo?.coveragePerClaimLimit ?? 0,
	carrier: eando.licenseInfo?.carrier ?? '',
	invalidReasons: eando.validation
		? eando.validation.invalidReasonsList.map(parseEOInvalidReasons)
		: [EOInvalidReasons.EmptyEO],
	otherInvalidReason: eando.validation?.otherInvalidReason,
	linesOfAuthorityList: eando.validation
		? parseLinesOfAuthorityListFromProto(eando.validation.linesOfAuthorityList)
		: [],
	pdfUrl: eando.licenseInfo?.pdfUrl,
	fullPolicyRequired:
		eando.validation?.fullPolicyRequired !==
		EandOValidation.EOFullPolicyRequirement.EO_FULL_POLICY_UNSPECIFIED
			? eando.validation?.fullPolicyRequired ===
			  EandOValidation.EOFullPolicyRequirement.EO_FULL_POLICY_REQUIRED
			: undefined,
	policyType: eando.validation
		? parseEOPolicyTypeFromProto(eando.validation.uploadedPolicyType)
		: undefined
});

export const calculateDefaultValueRequireFullPolicy = (carrierValue: string) => {
	return carriersStringsRequireFullPolicy
		.map(value => value.toLowerCase().trim())
		.includes(carrierValue.toLowerCase().trim())
		? true
		: undefined;
};

export const carriersRequireFullPolicy = [
	'Next',
	'State National',
	'Hiscox',
	'Biberk',
	'Berkshire Hathaway',
	'National Liability & Fire Insurance'
];

export const carriersStringsRequireFullPolicy = [
	'next',
	'next insurance',
	'state national',
	'state national insurance',
	'state national insurance company',
	'Hiscox',
	'biberk',
	'Berkshire',
	'Berkshire Hathaway',
	'National Liability & Fire Insurance',
	'National Liability Fire Insurance',
	'National Liability & Fire',
	'National Liability Fire'
];

export const isEOMissing = (eo: EOStatus) => [EOStatus.Invalid, EOStatus.Unknown].includes(eo);

export const isEOValid = (eo: EOStatus) => [EOStatus.Approved].includes(eo);
