import React, {
	PropsWithChildren,
	createContext,
	useCallback,
	useEffect,
	useRef,
	useState
} from 'react';

import styled from '@emotion/styled';

type MenuContextValue = {
	closePanel: () => void;
};

type MenuPanelContainerProps = {
	position: 'top' | 'bottom' | null;
};

type MenuPanelProps = {
	className?: string;
	closePanel: () => void;
};

export const MenuContext = createContext<MenuContextValue>({
	closePanel: () => null
});

const MenuPanelContainer = styled.ul<MenuPanelContainerProps>`
	position: absolute;
	opacity: ${({ position }) => (position ? '1' : '0')};
	z-index: 3;
	${({ position }) => position}: 0;
	right: 100%;
	width: 192px;
	padding: 8px 0;
	${props => props.theme.textSettings.S}
	box-shadow: ${props => props.theme.shadows.medium};
	background: ${props => props.theme.colors.white};
	pointer-events: auto;
`;

export const MenuPanel = ({
	children,
	closePanel,
	className
}: PropsWithChildren<MenuPanelProps>) => {
	const menuPanelContainer = useRef<HTMLUListElement>(null);
	const [position, setPosition] = useState<'top' | 'bottom' | null>(null);
	const stopPropagation = useCallback((event: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
		event.stopPropagation();
		event.preventDefault();
	}, []);

	useEffect(() => {
		const root = document.getElementById('__next') as HTMLElement;
		root.addEventListener('click', closePanel, false);

		return () => {
			root.removeEventListener('click', closePanel, false);
		};
	}, [closePanel]);

	useEffect(() => {
		if (menuPanelContainer && menuPanelContainer.current) {
			const { y, height } = menuPanelContainer.current.getBoundingClientRect() as DOMRect;
			const windowHeight = window.innerHeight;

			if (y + height + 40 >= windowHeight) {
				setPosition('bottom');
				return;
			}

			setPosition('top');
		}
	}, []);

	return (
		<MenuContext.Provider value={{ closePanel }}>
			<MenuPanelContainer
				onClick={stopPropagation}
				ref={menuPanelContainer}
				position={position}
				className={className}>
				{children}
			</MenuPanelContainer>
		</MenuContext.Provider>
	);
};
