import { forwardRef } from 'react';

import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const ToggleContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
`;

const ToggleLabel = styled.label`
	display: inline-flex;
	align-items: center;
	transition: opacity 0.15s;
`;

const ToggleSwitch = styled.div<{ isBig: boolean }>`
	cursor: pointer;
	width: ${({ isBig, theme }) => (isBig ? theme.sizes.getSize(5) : theme.sizes.getSize(4))};
	height: ${({ isBig, theme }) => (isBig ? theme.sizes.getSize(2) : theme.sizes.getSize(1.5))};
	background: ${({ theme }) => theme.colors.gray.lighten80};
	border-radius: 8px;
	transition: 0.3s cubic-bezier(0.83, 0, 0.17, 1);
`;

const ToggleCircle = styled.div<{ isBig: boolean }>`
	position: relative;
	width: ${({ isBig, theme }) => (isBig ? theme.sizes.getSize(3) : theme.sizes.getSize(2.5))};
	height: ${({ isBig, theme }) => (isBig ? theme.sizes.getSize(3) : theme.sizes.getSize(2.5))};
	margin: -4px 0;
	background: ${({ theme }) => theme.colors.gray.lighten40};
	border-radius: 50%;
	transition: 0.3s cubic-bezier(0.83, 0, 0.17, 1);
	box-shadow: ${({ theme }) => theme.shadows.hard};

	&:before {
		content: '';
		position: absolute;
		top: -6px;
		left: -6px;
		right: -6px;
		bottom: -6px;
		display: block;
		border-radius: 50%;
		border: 6px solid ${({ theme }) => theme.colors.gray.lighten80};
		opacity: 0;
		transition: 0.3s cubic-bezier(0.83, 0, 0.17, 1);
	}
`;

const ToggleLabelText = styled.div`
	margin-left: 8px;
	${({ theme }) => theme.textSettings.S};

	&:before {
		content: attr(data-off);
	}
`;

const ToggleCheckbox = styled.input`
	position: absolute;
	top: 0;
	opacity: 0;

	&:checked + ${ToggleLabel} {
		${ToggleSwitch} {
			background: ${({ theme }) => theme.colors.primary.lighten80};
		}

		${ToggleCircle} {
			background: ${({ theme }) => theme.colors.primary.base};
			transform: translateX(12px);
		}

		${ToggleLabelText} {
			&:before {
				content: attr(data-on);
			}
		}
	}

	&:checked:focus + ${ToggleLabel} {
		${ToggleCircle} {
			&:before {
				border-color: ${({ theme }) => theme.colors.primary.lighten50};
			}
		}
	}

	&:focus + ${ToggleLabel} {
		${ToggleCircle} {
			&:before {
				opacity: 0.5;
			}
		}
	}

	&:disabled + ${ToggleLabel} {
		opacity: 0.5;

		${ToggleSwitch} {
			cursor: default;
		}
	}
`;

type ToggleTextProps =
	| {
			on: string;
			off: string;
	  }
	| 'off';

type ToggleProps = {
	id: string;
	isBig?: boolean;
	text?: ToggleTextProps;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
	({ id, text, isBig = false, ...props }, ref) => {
		const { t } = useTranslation('toggle');
		const toggleText = text ?? { on: t('on'), off: t('off') };

		return (
			<ToggleContainer onClick={event => event.stopPropagation()}>
				<ToggleCheckbox type="checkbox" id={id} {...props} ref={ref} role="switch" />
				<ToggleLabel htmlFor={id}>
					<ToggleSwitch isBig={isBig}>
						<ToggleCircle isBig={isBig} />
					</ToggleSwitch>

					{toggleText !== 'off' && (
						<ToggleLabelText data-on={toggleText.on} data-off={toggleText.off} />
					)}
				</ToggleLabel>
			</ToggleContainer>
		);
	}
);

export const ToggleGroup = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: ${({ theme }) => theme.sizes.getSize(5)};
	row-gap: ${({ theme }) => theme.sizes.getSize(1)};
	align-items: center;

	> *,
	label {
		margin: 0;
	}
`;
