import styled from '@emotion/styled';

import { ITheme } from '@agentero/styles/emotion';

export enum StatusColor {
	Success,
	Error,
	Warning,
	Info,
	Neutral,
	Violet,
	Pink,
	Orange,
	Yellow
}

type StatusColorLabelProps = {
	status: StatusColor;
	ghost?: boolean;
	solid?: boolean;
};

type StatusColorTheme = {
	dark: string;
	light: string;
};

const variant = (theme: ITheme, color: StatusColorTheme, ghost = false, solid = false) => `
	color: ${solid ? theme.colors.white : color.dark};
	${
		ghost
			? `border: 1px solid ${color.dark}; padding: 1px 5px;`
			: `background: ${solid ? color.dark : color.light}`
	};
`;

const variantWithColor = (color: (theme: ITheme) => StatusColorTheme) => {
	return (theme: ITheme, ghost = false, solid = false) =>
		variant(theme, color(theme), ghost, solid);
};

const convertColorToStatusColor = (color: { darken50: string; lighten80: string }) => ({
	dark: color.darken50,
	light: color.lighten80
});

export const statusVariants: {
	[key: number]: (theme: ITheme, ghost: boolean, solid: boolean) => string;
} = {
	[StatusColor.Success]: variantWithColor(theme =>
		convertColorToStatusColor(theme.colors.status.success)
	),
	[StatusColor.Error]: variantWithColor(theme =>
		convertColorToStatusColor(theme.colors.status.error)
	),
	[StatusColor.Warning]: variantWithColor(theme =>
		convertColorToStatusColor(theme.colors.status.warning)
	),
	[StatusColor.Info]: variantWithColor(theme =>
		convertColorToStatusColor(theme.colors.status.info)
	),
	[StatusColor.Violet]: variantWithColor(theme => ({
		dark: theme.colors.violet.base,
		light: theme.colors.violet.lighten80
	})),
	[StatusColor.Neutral]: variantWithColor(theme => ({
		dark: theme.colors.gray.lighten20,
		light: theme.colors.gray.lighten90
	})),
	[StatusColor.Pink]: variantWithColor(theme => ({
		dark: theme.colors.pink.darken30,
		light: theme.colors.pink.lighten80
	})),
	[StatusColor.Orange]: variantWithColor(theme => ({
		dark: theme.colors.orange.darken30, //Orange.20
		light: theme.colors.orange.lighten80 //Orange.90
	})),
	[StatusColor.Yellow]: variantWithColor(theme => ({
		dark: theme.colors.yellow.darken30, //Yellow.20
		light: theme.colors.yellow.lighten80 //Yellow.90
	}))
};

type GetStatusVariant = {
	theme: ITheme;
	status: StatusColor;
	ghost?: boolean;
	solid?: boolean;
};

const getStatusVariant = ({ status, theme, ghost = false, solid = false }: GetStatusVariant) => {
	const variant = statusVariants[status];
	return variant(theme, ghost, solid);
};

/**
 * @deprecated Use **'@agentero/ui'** *Tag* instead.
 */
export const StatusColorLabel = styled.span<StatusColorLabelProps>`
	display: inline-block;
	vertical-align: top;
	padding: 2px 6px;
	white-space: nowrap;
	${({ theme }) => theme.fontWeight.medium};
	${({ theme }) => theme.textSettings.XXS};
	letter-spacing: 0.4px;
	border-radius: 0.125rem;

	${getStatusVariant}

	&:first-letter {
		text-transform: uppercase;
	}
`;

StatusColorLabel.defaultProps = {
	role: 'status'
};
