import { DeepRequired, FieldErrorsImpl, Path, get } from 'react-hook-form';

export const removeAllDots = (name: string) => name.replaceAll('.', '');

export const getFieldErrorId = (name: string) => `${removeAllDots(name)}Err`;

export const getFieldError = <T extends {}>(
	errors: FieldErrorsImpl<DeepRequired<T>>,
	name: Path<T>
) => get(errors, name);
