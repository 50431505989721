import { DateDuration, endOfMonth, getWeeksInMonth } from '@internationalized/date';
import { useCalendarGrid } from '@react-aria/calendar';
import { CalendarState, RangeCalendarState } from '@react-stately/calendar';

import { styled } from '@agentero/styles/jsx';

import { CalendarCell } from './CalendarCell';

const CalendarRow = styled('tr', {
	base: {
		display: 'flex',
		marginTop: '0.375rem'
	}
});

const CalendarTable = styled('table', {
	base: {
		width: 'fit-content'
	}
});

const CalendarHeaderCell = styled('th', {
	base: {
		width: '2rem',
		paddingBlockEnd: '4',
		textStyle: 'caption.base'
	}
});

type CalendarGridProps = {
	state: CalendarState | RangeCalendarState;
	offset?: DateDuration;
};

export const CalendarGrid = ({ state, offset = {} }: CalendarGridProps) => {
	const startDate = state.visibleRange.start.add(offset);
	const endDate = endOfMonth(startDate);

	const { gridProps, headerProps } = useCalendarGrid(
		{
			startDate,
			endDate
		},
		state
	);

	// Get the number of weeks in the month so we can render the proper number of rows.
	const weeksInMonth = getWeeksInMonth(state.visibleRange.start, 'en-US');

	return (
		<CalendarTable {...gridProps} cellPadding={0}>
			<thead {...headerProps}>
				<CalendarRow>
					{['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
						<CalendarHeaderCell key={index}>{day}</CalendarHeaderCell>
					))}
				</CalendarRow>
			</thead>
			<tbody>
				{[...new Array(weeksInMonth).keys()].map(weekIndex => (
					<CalendarRow key={weekIndex}>
						{state
							.getDatesInWeek(weekIndex, startDate)
							.map((date, i) =>
								date ? (
									<CalendarCell key={i} state={state} date={date} currentMonth={startDate} />
								) : (
									<td key={i} />
								)
							)}
					</CalendarRow>
				))}
			</tbody>
		</CalendarTable>
	);
};
