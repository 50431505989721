import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';

import { styled } from '@agentero/styles/jsx';

import { FieldStatus } from '../../shared/fieldStatus';
import { removeAllDots } from '../../shared/formGroupUtils';

export type RadioChipsValue = {
	label: string;
	value: boolean | string;
	onChange?: (value: boolean | string) => void;
};

export type RadioChipsValueProps = {
	status?: FieldStatus;
	onChange?: (value: boolean | string) => void;
	onBlur?: () => void;
	value?: boolean | string;
	inputProps?: Omit<
		DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
		'css' | 'size' | 'ref'
	>;
	values: RadioChipsValue[];
	defaultValue?: string | boolean;
};

const InputRadio = styled('input', {
	base: {
		position: 'absolute',
		left: '-9999px',
		_checked: {
			'&+ label': {
				background: 'brand.95',
				borderColor: 'brand.20',
				color: 'brand.20',
				fontWeight: 600,
				_hover: {
					background: 'brand.90'
				}
			}
		},
		_focusVisible: {
			'&+ label': {
				outlineColor: 'brand',
				outlineOffset: '0.125rem',
				outlineStyle: 'solid',
				outlineWidth: '0.125rem'
			}
		}
	}
});

const LabelRadio = styled('label', {
	base: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		borderRadius: '1.5rem',
		borderWidth: '0.0625rem',
		borderColor: 'neutral.80',
		paddingInline: '12',
		textStyle: 'body.small',
		minHeight: '2rem',
		minWidth: '4.5rem',
		marginTop: '0.25rem',
		cursor: 'pointer',
		_hover: {
			background: 'neutral.95'
		}
	},
	variants: {
		hasError: {
			true: {
				borderColor: 'danger',
				_focusVisible: {
					outlineColor: 'danger'
				}
			}
		}
	}
});

const Container = styled('ul', {
	base: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: '8',
		position: 'relative'
	}
});

export const RadioChips = ({
	onChange,
	value,
	values,
	status,
	inputProps
}: RadioChipsValueProps) => {
	return (
		<Container>
			{values.map(({ label, value: valueProp, onChange: onChangeProp }) => {
				const id = `${removeAllDots(inputProps?.name ?? '')}${label}`;

				return (
					<li key={id}>
						<InputRadio
							{...inputProps}
							type="radio"
							id={id}
							checked={value !== undefined ? value === valueProp : undefined}
							value={String(valueProp)}
							aria-label={label}
							onChange={event => {
								onChangeProp?.(valueProp);
								onChange?.(valueProp);
								inputProps?.onChange?.(event);
							}}
						/>
						<LabelRadio htmlFor={id} hasError={status === FieldStatus.Error}>
							{label}
						</LabelRadio>
					</li>
				);
			})}
		</Container>
	);
};
