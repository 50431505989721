// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file agentero/risk/risk.proto (package agentero.risk, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * RoofMaterial represents the material of the roof
 *
 * @generated from enum agentero.risk.RoofMaterial
 */
export enum RoofMaterial {
  /**
   * @generated from enum value: ROOF_MATERIAL_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ROOF_MATERIAL_ARCHITECTURAL = 1;
   */
  ARCHITECTURAL = 1,

  /**
   * @generated from enum value: ROOF_MATERIAL_ASBESTOS = 2;
   */
  ASBESTOS = 2,

  /**
   * @generated from enum value: ROOF_MATERIAL_THREE_TAB = 3;
   */
  THREE_TAB = 3,

  /**
   * @generated from enum value: ROOF_MATERIAL_TILE = 4;
   */
  TILE = 4,

  /**
   * @generated from enum value: ROOF_MATERIAL_SLATE = 5;
   */
  SLATE = 5,

  /**
   * @generated from enum value: ROOF_MATERIAL_COMPOSITION = 6;
   */
  COMPOSITION = 6,

  /**
   * @generated from enum value: ROOF_MATERIAL_FOAM = 7;
   */
  FOAM = 7,

  /**
   * @generated from enum value: ROOF_MATERIAL_RUBBER = 8;
   */
  RUBBER = 8,

  /**
   * @generated from enum value: ROOF_MATERIAL_TAR = 9;
   */
  TAR = 9,

  /**
   * @generated from enum value: ROOF_MATERIAL_GRAVEL = 10;
   */
  GRAVEL = 10,

  /**
   * @generated from enum value: ROOF_MATERIAL_METAL = 11;
   */
  METAL = 11,

  /**
   * @generated from enum value: ROOF_MATERIAL_POLYMER = 12;
   */
  POLYMER = 12,

  /**
   * @generated from enum value: ROOF_MATERIAL_ROLLED = 13;
   */
  ROLLED = 13,

  /**
   * @generated from enum value: ROOF_MATERIAL_TLOCK = 14;
   */
  TLOCK = 14,

  /**
   * @generated from enum value: ROOF_MATERIAL_WOOD = 15;
   */
  WOOD = 15,

  /**
   * @generated from enum value: ROOF_MATERIAL_OTHER = 16;
   */
  OTHER = 16,

  /**
   * @generated from enum value: ROOF_MATERIAL_REINFORCED_CONCRETE = 17;
   */
  REINFORCED_CONCRETE = 17,

  /**
   * @generated from enum value: ROOF_MATERIAL_SPANISH_TILE = 18;
   */
  SPANISH_TILE = 18,

  /**
   * @generated from enum value: ROOF_MATERIAL_CLAY_TILE = 19;
   */
  CLAY_TILE = 19,

  /**
   * @generated from enum value: ROOF_MATERIAL_CONCRETE_TILE = 20;
   */
  CONCRETE_TILE = 20,
}
// Retrieve enum metadata with: proto3.getEnumType(RoofMaterial)
proto3.util.setEnumType(RoofMaterial, "agentero.risk.RoofMaterial", [
  { no: 0, name: "ROOF_MATERIAL_UNSPECIFIED" },
  { no: 1, name: "ROOF_MATERIAL_ARCHITECTURAL" },
  { no: 2, name: "ROOF_MATERIAL_ASBESTOS" },
  { no: 3, name: "ROOF_MATERIAL_THREE_TAB" },
  { no: 4, name: "ROOF_MATERIAL_TILE" },
  { no: 5, name: "ROOF_MATERIAL_SLATE" },
  { no: 6, name: "ROOF_MATERIAL_COMPOSITION" },
  { no: 7, name: "ROOF_MATERIAL_FOAM" },
  { no: 8, name: "ROOF_MATERIAL_RUBBER" },
  { no: 9, name: "ROOF_MATERIAL_TAR" },
  { no: 10, name: "ROOF_MATERIAL_GRAVEL" },
  { no: 11, name: "ROOF_MATERIAL_METAL" },
  { no: 12, name: "ROOF_MATERIAL_POLYMER" },
  { no: 13, name: "ROOF_MATERIAL_ROLLED" },
  { no: 14, name: "ROOF_MATERIAL_TLOCK" },
  { no: 15, name: "ROOF_MATERIAL_WOOD" },
  { no: 16, name: "ROOF_MATERIAL_OTHER" },
  { no: 17, name: "ROOF_MATERIAL_REINFORCED_CONCRETE" },
  { no: 18, name: "ROOF_MATERIAL_SPANISH_TILE" },
  { no: 19, name: "ROOF_MATERIAL_CLAY_TILE" },
  { no: 20, name: "ROOF_MATERIAL_CONCRETE_TILE" },
]);

/**
 * Stories represents the number of stories in the building
 *
 * @generated from enum agentero.risk.BuildingMaterial
 */
export enum BuildingMaterial {
  /**
   * @generated from enum value: BUILDING_MATERIAL__UNSPECIFIED = 0;
   */
  _UNSPECIFIED = 0,

  /**
   * @generated from enum value: BUILDING_MATERIAL_WOOD_FRAME_MASONRY = 1;
   */
  WOOD_FRAME_MASONRY = 1,

  /**
   * @generated from enum value: BUILDING_MATERIAL_WOOD_FRAME_NON_MASONRY = 2;
   */
  WOOD_FRAME_NON_MASONRY = 2,

  /**
   * @generated from enum value: BUILDING_MATERIAL_WOOD_FRAME_UNKNOWN_SIDING = 3;
   */
  WOOD_FRAME_UNKNOWN_SIDING = 3,

  /**
   * @generated from enum value: BUILDING_MATERIAL_MASONRY = 4;
   */
  MASONRY = 4,

  /**
   * @generated from enum value: BUILDING_MATERIAL_CONCRETE = 5;
   */
  CONCRETE = 5,

  /**
   * @generated from enum value: BUILDING_MATERIAL_STEEL = 6;
   */
  STEEL = 6,

  /**
   * @generated from enum value: BUILDING_MATERIAL_LOG = 7;
   */
  LOG = 7,

  /**
   * @generated from enum value: BUILDING_MATERIAL_MOBILE = 8;
   */
  MOBILE = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(BuildingMaterial)
proto3.util.setEnumType(BuildingMaterial, "agentero.risk.BuildingMaterial", [
  { no: 0, name: "BUILDING_MATERIAL__UNSPECIFIED" },
  { no: 1, name: "BUILDING_MATERIAL_WOOD_FRAME_MASONRY" },
  { no: 2, name: "BUILDING_MATERIAL_WOOD_FRAME_NON_MASONRY" },
  { no: 3, name: "BUILDING_MATERIAL_WOOD_FRAME_UNKNOWN_SIDING" },
  { no: 4, name: "BUILDING_MATERIAL_MASONRY" },
  { no: 5, name: "BUILDING_MATERIAL_CONCRETE" },
  { no: 6, name: "BUILDING_MATERIAL_STEEL" },
  { no: 7, name: "BUILDING_MATERIAL_LOG" },
  { no: 8, name: "BUILDING_MATERIAL_MOBILE" },
]);

/**
 * HomeStyle represents the style of the home
 *
 * @generated from enum agentero.risk.HomeStyle
 */
export enum HomeStyle {
  /**
   * @generated from enum value: HOME_STYLE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: HOME_STYLE_RANCH = 1;
   */
  RANCH = 1,

  /**
   * @generated from enum value: HOME_STYLE_CONTEMPORARY = 2;
   */
  CONTEMPORARY = 2,

  /**
   * @generated from enum value: HOME_STYLE_COLONIAL = 3;
   */
  COLONIAL = 3,

  /**
   * @generated from enum value: HOME_STYLE_MEDITERRANEAN = 4;
   */
  MEDITERRANEAN = 4,

  /**
   * @generated from enum value: HOME_STYLE_CAPE_COD = 5;
   */
  CAPE_COD = 5,

  /**
   * @generated from enum value: HOME_STYLE_SPLIT_LEVEL = 6;
   */
  SPLIT_LEVEL = 6,

  /**
   * @generated from enum value: HOME_STYLE_TRI_LEVEL = 7;
   */
  TRI_LEVEL = 7,

  /**
   * @generated from enum value: HOME_STYLE_ADOBE = 8;
   */
  ADOBE = 8,

  /**
   * @generated from enum value: HOME_STYLE_VICTORIAN = 9;
   */
  VICTORIAN = 9,

  /**
   * @generated from enum value: HOME_STYLE_TOWN_HOUSE_END = 10;
   */
  TOWN_HOUSE_END = 10,

  /**
   * @generated from enum value: HOME_STYLE_TOWN_HOUSE_CENTER = 11;
   */
  TOWN_HOUSE_CENTER = 11,

  /**
   * @generated from enum value: HOME_STYLE_QUEEN_ANNE = 12;
   */
  QUEEN_ANNE = 12,

  /**
   * @generated from enum value: HOME_STYLE_ROW_HOUSE_CENTER = 13;
   */
  ROW_HOUSE_CENTER = 13,

  /**
   * @generated from enum value: HOME_STYLE_ROW_HOUSE_END = 14;
   */
  ROW_HOUSE_END = 14,

  /**
   * @generated from enum value: HOME_STYLE_MOBILE_MANUFACTURE = 15;
   */
  MOBILE_MANUFACTURE = 15,
}
// Retrieve enum metadata with: proto3.getEnumType(HomeStyle)
proto3.util.setEnumType(HomeStyle, "agentero.risk.HomeStyle", [
  { no: 0, name: "HOME_STYLE_UNSPECIFIED" },
  { no: 1, name: "HOME_STYLE_RANCH" },
  { no: 2, name: "HOME_STYLE_CONTEMPORARY" },
  { no: 3, name: "HOME_STYLE_COLONIAL" },
  { no: 4, name: "HOME_STYLE_MEDITERRANEAN" },
  { no: 5, name: "HOME_STYLE_CAPE_COD" },
  { no: 6, name: "HOME_STYLE_SPLIT_LEVEL" },
  { no: 7, name: "HOME_STYLE_TRI_LEVEL" },
  { no: 8, name: "HOME_STYLE_ADOBE" },
  { no: 9, name: "HOME_STYLE_VICTORIAN" },
  { no: 10, name: "HOME_STYLE_TOWN_HOUSE_END" },
  { no: 11, name: "HOME_STYLE_TOWN_HOUSE_CENTER" },
  { no: 12, name: "HOME_STYLE_QUEEN_ANNE" },
  { no: 13, name: "HOME_STYLE_ROW_HOUSE_CENTER" },
  { no: 14, name: "HOME_STYLE_ROW_HOUSE_END" },
  { no: 15, name: "HOME_STYLE_MOBILE_MANUFACTURE" },
]);

/**
 * SidingType represents the type of siding
 *
 * @generated from enum agentero.risk.SidingType
 */
export enum SidingType {
  /**
   * @generated from enum value: SIDING_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SIDING_TYPE_VINYL_SIDING = 1;
   */
  VINYL_SIDING = 1,

  /**
   * @generated from enum value: SIDING_TYPE_WOOD_SIDING = 2;
   */
  WOOD_SIDING = 2,

  /**
   * @generated from enum value: SIDING_TYPE_BRICK_VENEER = 3;
   */
  BRICK_VENEER = 3,

  /**
   * @generated from enum value: SIDING_TYPE_STUCCO_ON_FRAME = 4;
   */
  STUCCO_ON_FRAME = 4,

  /**
   * @generated from enum value: SIDING_TYPE_ALUMINUM_SIDING = 5;
   */
  ALUMINUM_SIDING = 5,

  /**
   * @generated from enum value: SIDING_TYPE_SOLID_BRICK = 6;
   */
  SOLID_BRICK = 6,

  /**
   * @generated from enum value: SIDING_TYPE_HARDY_PLANK_CEMENT_FIBER = 7;
   */
  HARDY_PLANK_CEMENT_FIBER = 7,

  /**
   * @generated from enum value: SIDING_TYPE_CLAPBOARD = 8;
   */
  CLAPBOARD = 8,

  /**
   * @generated from enum value: SIDING_TYPE_EIFS_SYNTHETIC_STUCCO = 9;
   */
  EIFS_SYNTHETIC_STUCCO = 9,

  /**
   * @generated from enum value: SIDING_TYPE_BRICK_BLOCK = 10;
   */
  BRICK_BLOCK = 10,

  /**
   * @generated from enum value: SIDING_TYPE_WOOD_SHAKES = 11;
   */
  WOOD_SHAKES = 11,

  /**
   * @generated from enum value: SIDING_TYPE_LOGS = 12;
   */
  LOGS = 12,
}
// Retrieve enum metadata with: proto3.getEnumType(SidingType)
proto3.util.setEnumType(SidingType, "agentero.risk.SidingType", [
  { no: 0, name: "SIDING_TYPE_UNSPECIFIED" },
  { no: 1, name: "SIDING_TYPE_VINYL_SIDING" },
  { no: 2, name: "SIDING_TYPE_WOOD_SIDING" },
  { no: 3, name: "SIDING_TYPE_BRICK_VENEER" },
  { no: 4, name: "SIDING_TYPE_STUCCO_ON_FRAME" },
  { no: 5, name: "SIDING_TYPE_ALUMINUM_SIDING" },
  { no: 6, name: "SIDING_TYPE_SOLID_BRICK" },
  { no: 7, name: "SIDING_TYPE_HARDY_PLANK_CEMENT_FIBER" },
  { no: 8, name: "SIDING_TYPE_CLAPBOARD" },
  { no: 9, name: "SIDING_TYPE_EIFS_SYNTHETIC_STUCCO" },
  { no: 10, name: "SIDING_TYPE_BRICK_BLOCK" },
  { no: 11, name: "SIDING_TYPE_WOOD_SHAKES" },
  { no: 12, name: "SIDING_TYPE_LOGS" },
]);

/**
 * Stories represents the number of stories in the building
 *
 * @generated from enum agentero.risk.Stories
 */
export enum Stories {
  /**
   * @generated from enum value: STORIES_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: STORIES_ONE = 1;
   */
  ONE = 1,

  /**
   * @generated from enum value: STORIES_ONE_POINT_FIVE = 2;
   */
  ONE_POINT_FIVE = 2,

  /**
   * @generated from enum value: STORIES_TWO = 3;
   */
  TWO = 3,

  /**
   * @generated from enum value: STORIES_TWO_POINT_FIVE = 4;
   */
  TWO_POINT_FIVE = 4,

  /**
   * @generated from enum value: STORIES_THREE = 5;
   */
  THREE = 5,

  /**
   * @generated from enum value: STORIES_FOUR_PLUS = 6;
   */
  FOUR_PLUS = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(Stories)
proto3.util.setEnumType(Stories, "agentero.risk.Stories", [
  { no: 0, name: "STORIES_UNSPECIFIED" },
  { no: 1, name: "STORIES_ONE" },
  { no: 2, name: "STORIES_ONE_POINT_FIVE" },
  { no: 3, name: "STORIES_TWO" },
  { no: 4, name: "STORIES_TWO_POINT_FIVE" },
  { no: 5, name: "STORIES_THREE" },
  { no: 6, name: "STORIES_FOUR_PLUS" },
]);

/**
 * DwellingType represents the type of dwelling
 *
 * @generated from enum agentero.risk.DwellingType
 */
export enum DwellingType {
  /**
   * @generated from enum value: DWELLING_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DWELLING_TYPE_SINGLE_FAMILY = 1;
   */
  SINGLE_FAMILY = 1,

  /**
   * @generated from enum value: DWELLING_TYPE_DUPLEX = 2;
   */
  DUPLEX = 2,

  /**
   * @generated from enum value: DWELLING_TYPE_TRIPLEX = 3;
   */
  TRIPLEX = 3,

  /**
   * @generated from enum value: DWELLING_TYPE_FOURPLEX = 4;
   */
  FOURPLEX = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(DwellingType)
proto3.util.setEnumType(DwellingType, "agentero.risk.DwellingType", [
  { no: 0, name: "DWELLING_TYPE_UNSPECIFIED" },
  { no: 1, name: "DWELLING_TYPE_SINGLE_FAMILY" },
  { no: 2, name: "DWELLING_TYPE_DUPLEX" },
  { no: 3, name: "DWELLING_TYPE_TRIPLEX" },
  { no: 4, name: "DWELLING_TYPE_FOURPLEX" },
]);

/**
 * ResidenceUse represents the use of the residence
 *
 * @generated from enum agentero.risk.ResidenceUse
 */
export enum ResidenceUse {
  /**
   * @generated from enum value: RESIDENCE_USE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: RESIDENCE_USE_PRIMARY = 1;
   */
  PRIMARY = 1,

  /**
   * @generated from enum value: RESIDENCE_USE_SECONDARY = 2;
   */
  SECONDARY = 2,

  /**
   * @generated from enum value: RESIDENCE_USE_RENTAL = 3;
   */
  RENTAL = 3,

  /**
   * @generated from enum value: RESIDENCE_USE_BUSINESS = 4;
   */
  BUSINESS = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ResidenceUse)
proto3.util.setEnumType(ResidenceUse, "agentero.risk.ResidenceUse", [
  { no: 0, name: "RESIDENCE_USE_UNSPECIFIED" },
  { no: 1, name: "RESIDENCE_USE_PRIMARY" },
  { no: 2, name: "RESIDENCE_USE_SECONDARY" },
  { no: 3, name: "RESIDENCE_USE_RENTAL" },
  { no: 4, name: "RESIDENCE_USE_BUSINESS" },
]);

/**
 * RoofShape represents the shape of the roof
 *
 * @generated from enum agentero.risk.RoofShape
 */
export enum RoofShape {
  /**
   * @generated from enum value: ROOF_SHAPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ROOF_SHAPE_FLAT = 1;
   */
  FLAT = 1,

  /**
   * @generated from enum value: ROOF_SHAPE_HIP = 2;
   */
  HIP = 2,

  /**
   * @generated from enum value: ROOF_SHAPE_GABLE = 3;
   */
  GABLE = 3,

  /**
   * @generated from enum value: ROOF_SHAPE_OTHER = 4;
   */
  OTHER = 4,

  /**
   * @generated from enum value: ROOF_SHAPE_COMPLEX = 5;
   */
  COMPLEX = 5,

  /**
   * @generated from enum value: ROOF_SHAPE_GAMBREL = 6;
   */
  GAMBREL = 6,

  /**
   * @generated from enum value: ROOF_SHAPE_SHED = 7;
   */
  SHED = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(RoofShape)
proto3.util.setEnumType(RoofShape, "agentero.risk.RoofShape", [
  { no: 0, name: "ROOF_SHAPE_UNSPECIFIED" },
  { no: 1, name: "ROOF_SHAPE_FLAT" },
  { no: 2, name: "ROOF_SHAPE_HIP" },
  { no: 3, name: "ROOF_SHAPE_GABLE" },
  { no: 4, name: "ROOF_SHAPE_OTHER" },
  { no: 5, name: "ROOF_SHAPE_COMPLEX" },
  { no: 6, name: "ROOF_SHAPE_GAMBREL" },
  { no: 7, name: "ROOF_SHAPE_SHED" },
]);

/**
 * @generated from enum agentero.risk.SwimmingPoolType
 */
export enum SwimmingPoolType {
  /**
   * @generated from enum value: SWIMMING_POOL_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SWIMMING_POOL_TYPE_ABOVE_GROUND = 1;
   */
  ABOVE_GROUND = 1,

  /**
   * @generated from enum value: SWIMMING_POOL_TYPE_IN_GROUND = 2;
   */
  IN_GROUND = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SwimmingPoolType)
proto3.util.setEnumType(SwimmingPoolType, "agentero.risk.SwimmingPoolType", [
  { no: 0, name: "SWIMMING_POOL_TYPE_UNSPECIFIED" },
  { no: 1, name: "SWIMMING_POOL_TYPE_ABOVE_GROUND" },
  { no: 2, name: "SWIMMING_POOL_TYPE_IN_GROUND" },
]);

/**
 * @generated from enum agentero.risk.FoundationType
 */
export enum FoundationType {
  /**
   * @generated from enum value: FOUNDATION_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: FOUNDATION_TYPE_STILTS = 1;
   */
  STILTS = 1,

  /**
   * @generated from enum value: FOUNDATION_TYPE_PIER_OR_PILINGS = 2;
   */
  PIER_OR_PILINGS = 2,

  /**
   * @generated from enum value: FOUNDATION_TYPE_CONCRETE_SLAB = 3;
   */
  CONCRETE_SLAB = 3,

  /**
   * @generated from enum value: FOUNDATION_TYPE_CRAWLSPACE = 4;
   */
  CRAWLSPACE = 4,

  /**
   * @generated from enum value: FOUNDATION_TYPE_BASEMENT = 5;
   */
  BASEMENT = 5,

  /**
   * @generated from enum value: FOUNDATION_TYPE_HILLSIDE = 6;
   */
  HILLSIDE = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(FoundationType)
proto3.util.setEnumType(FoundationType, "agentero.risk.FoundationType", [
  { no: 0, name: "FOUNDATION_TYPE_UNSPECIFIED" },
  { no: 1, name: "FOUNDATION_TYPE_STILTS" },
  { no: 2, name: "FOUNDATION_TYPE_PIER_OR_PILINGS" },
  { no: 3, name: "FOUNDATION_TYPE_CONCRETE_SLAB" },
  { no: 4, name: "FOUNDATION_TYPE_CRAWLSPACE" },
  { no: 5, name: "FOUNDATION_TYPE_BASEMENT" },
  { no: 6, name: "FOUNDATION_TYPE_HILLSIDE" },
]);

/**
 * @generated from enum agentero.risk.CurrentCarrier
 */
export enum CurrentCarrier {
  /**
   * @generated from enum value: CURRENT_CARRIER_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CURRENT_CARRIER_ALL_STATE = 1;
   */
  ALL_STATE = 1,

  /**
   * @generated from enum value: CURRENT_CARRIER_AMERICAN_FAMILY = 2;
   */
  AMERICAN_FAMILY = 2,

  /**
   * @generated from enum value: CURRENT_CARRIER_AMICA = 3;
   */
  AMICA = 3,

  /**
   * @generated from enum value: CURRENT_CARRIER_AUTO_CLUB = 4;
   */
  AUTO_CLUB = 4,

  /**
   * @generated from enum value: CURRENT_CARRIER_AUTO_OWNERS = 5;
   */
  AUTO_OWNERS = 5,

  /**
   * @generated from enum value: CURRENT_CARRIER_ERIE = 6;
   */
  ERIE = 6,

  /**
   * @generated from enum value: CURRENT_CARRIER_FARM_BUREAU = 7;
   */
  FARM_BUREAU = 7,

  /**
   * @generated from enum value: CURRENT_CARRIER_FARMERS = 8;
   */
  FARMERS = 8,

  /**
   * @generated from enum value: CURRENT_CARRIER_GEICO = 9;
   */
  GEICO = 9,

  /**
   * @generated from enum value: CURRENT_CARRIER_HARTFORD = 10;
   */
  HARTFORD = 10,

  /**
   * @generated from enum value: CURRENT_CARRIER_INFINITY = 11;
   */
  INFINITY = 11,

  /**
   * @generated from enum value: CURRENT_CARRIER_KEMPER = 12;
   */
  KEMPER = 12,

  /**
   * @generated from enum value: CURRENT_CARRIER_LIBERTY_MUTUAL = 13;
   */
  LIBERTY_MUTUAL = 13,

  /**
   * @generated from enum value: CURRENT_CARRIER_MERCURY = 14;
   */
  MERCURY = 14,

  /**
   * @generated from enum value: CURRENT_CARRIER_MET_LIFE = 15;
   */
  MET_LIFE = 15,

  /**
   * @generated from enum value: CURRENT_CARRIER_NATIONWIDE = 16;
   */
  NATIONWIDE = 16,

  /**
   * @generated from enum value: CURRENT_CARRIER_PROGRESSIVE = 17;
   */
  PROGRESSIVE = 17,

  /**
   * @generated from enum value: CURRENT_CARRIER_SAFECO = 18;
   */
  SAFECO = 18,

  /**
   * @generated from enum value: CURRENT_CARRIER_STATE_FARM = 19;
   */
  STATE_FARM = 19,

  /**
   * @generated from enum value: CURRENT_CARRIER_STILLWATER = 20;
   */
  STILLWATER = 20,

  /**
   * @generated from enum value: CURRENT_CARRIER_TRAVELERS = 21;
   */
  TRAVELERS = 21,

  /**
   * @generated from enum value: CURRENT_CARRIER_TWENTY_FIRST_CENTURY = 22;
   */
  TWENTY_FIRST_CENTURY = 22,

  /**
   * @generated from enum value: CURRENT_CARRIER_USAA = 23;
   */
  USAA = 23,

  /**
   * @generated from enum value: CURRENT_CARRIER_OTHER = 24;
   */
  OTHER = 24,
}
// Retrieve enum metadata with: proto3.getEnumType(CurrentCarrier)
proto3.util.setEnumType(CurrentCarrier, "agentero.risk.CurrentCarrier", [
  { no: 0, name: "CURRENT_CARRIER_UNSPECIFIED" },
  { no: 1, name: "CURRENT_CARRIER_ALL_STATE" },
  { no: 2, name: "CURRENT_CARRIER_AMERICAN_FAMILY" },
  { no: 3, name: "CURRENT_CARRIER_AMICA" },
  { no: 4, name: "CURRENT_CARRIER_AUTO_CLUB" },
  { no: 5, name: "CURRENT_CARRIER_AUTO_OWNERS" },
  { no: 6, name: "CURRENT_CARRIER_ERIE" },
  { no: 7, name: "CURRENT_CARRIER_FARM_BUREAU" },
  { no: 8, name: "CURRENT_CARRIER_FARMERS" },
  { no: 9, name: "CURRENT_CARRIER_GEICO" },
  { no: 10, name: "CURRENT_CARRIER_HARTFORD" },
  { no: 11, name: "CURRENT_CARRIER_INFINITY" },
  { no: 12, name: "CURRENT_CARRIER_KEMPER" },
  { no: 13, name: "CURRENT_CARRIER_LIBERTY_MUTUAL" },
  { no: 14, name: "CURRENT_CARRIER_MERCURY" },
  { no: 15, name: "CURRENT_CARRIER_MET_LIFE" },
  { no: 16, name: "CURRENT_CARRIER_NATIONWIDE" },
  { no: 17, name: "CURRENT_CARRIER_PROGRESSIVE" },
  { no: 18, name: "CURRENT_CARRIER_SAFECO" },
  { no: 19, name: "CURRENT_CARRIER_STATE_FARM" },
  { no: 20, name: "CURRENT_CARRIER_STILLWATER" },
  { no: 21, name: "CURRENT_CARRIER_TRAVELERS" },
  { no: 22, name: "CURRENT_CARRIER_TWENTY_FIRST_CENTURY" },
  { no: 23, name: "CURRENT_CARRIER_USAA" },
  { no: 24, name: "CURRENT_CARRIER_OTHER" },
]);

/**
 * Occupation represents the occupation of the applicant.
 *
 * @generated from enum agentero.risk.Occupation
 */
export enum Occupation {
  /**
   * @generated from enum value: OCCUPATION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: OCCUPATION_STUDENT = 1;
   */
  STUDENT = 1,

  /**
   * @generated from enum value: OCCUPATION_HOMEMAKER = 2;
   */
  HOMEMAKER = 2,

  /**
   * @generated from enum value: OCCUPATION_UNEMPLOYED = 3;
   */
  UNEMPLOYED = 3,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_ANALYST = 4;
   */
  INSURANCE_ANALYST = 4,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_RECEPTIONIST_SECRETARY = 5;
   */
  PRODUCTION_MANUFACTURING_RECEPTIONIST_SECRETARY = 5,

  /**
   * @generated from enum value: OCCUPATION_INFORMATION_TECHNOLOGY_DIRECTOR_ADMINISTRATOR = 6;
   */
  INFORMATION_TECHNOLOGY_DIRECTOR_ADMINISTRATOR = 6,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_FEDERAL_AGENT_MARSHALL = 7;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_FEDERAL_AGENT_MARSHALL = 7,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_HOSPICE_VOLUNTEER = 8;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_HOSPICE_VOLUNTEER = 8,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_ANALYST = 9;
   */
  GOVERNMENT_MILITARY_ANALYST = 9,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_ACTIVITY_RECREATIONAL_ASSISTANT = 10;
   */
  SPORTS_RECREATION_ACTIVITY_RECREATIONAL_ASSISTANT = 10,

  /**
   * @generated from enum value: OCCUPATION_RECEPCIONIST_SECRETARY = 11;
   */
  RECEPCIONIST_SECRETARY = 11,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_ACTUARIAL_CLERK = 12;
   */
  INSURANCE_ACTUARIAL_CLERK = 12,

  /**
   * @generated from enum value: OCCUPATION_INFORMATION_TECHNOLOGY_CLERK = 13;
   */
  INFORMATION_TECHNOLOGY_CLERK = 13,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_CLERK = 14;
   */
  INSURANCE_CLERK = 14,

  /**
   * @generated from enum value: OCCUPATION_AGRICULTURE_FORESTY_FISHING_CLERK = 15;
   */
  AGRICULTURE_FORESTY_FISHING_CLERK = 15,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_CLERK = 16;
   */
  SPORTS_RECREATION_CLERK = 16,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_PARKING_LOT_ATTENDANT = 17;
   */
  TRAVEL_TRANSPORTATION_STORAGE_PARKING_LOT_ATTENDANT = 17,

  /**
   * @generated from enum value: OCCUPATION_INFORMATION_TECHNOLOGY_PROJECT_COORDINATOR = 18;
   */
  INFORMATION_TECHNOLOGY_PROJECT_COORDINATOR = 18,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_CAMP_COUNSELOR_LEAD = 19;
   */
  SPORTS_RECREATION_CAMP_COUNSELOR_LEAD = 19,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_SUPPORT_SERVICES = 20;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_SUPPORT_SERVICES = 20,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_EXECUTIVE = 21;
   */
  BANKING_FINANCE_REAL_ESTATE_EXECUTIVE = 21,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_TRANSPORTATION_SPECIALIST = 22;
   */
  TRAVEL_TRANSPORTATION_STORAGE_TRANSPORTATION_SPECIALIST = 22,

  /**
   * @generated from enum value: OCCUPATION_INFORMATION_TECHNOLOGY_ENGINEER_HARDWARE = 23;
   */
  INFORMATION_TECHNOLOGY_ENGINEER_HARDWARE = 23,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_MANAGER_R_D = 24;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_MANAGER_R_D = 24,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_PRODUCT_MANAGER = 25;
   */
  INSURANCE_PRODUCT_MANAGER = 25,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_MANAGER_DISTRICT = 26;
   */
  BUSINESS_SALES_OFFICE_MANAGER_DISTRICT = 26,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_MANAGER_DEPARTMENT_STORE = 27;
   */
  BUSINESS_SALES_OFFICE_MANAGER_DEPARTMENT_STORE = 27,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_MANAGER_GENERAL_OPERATIONS = 28;
   */
  BUSINESS_SALES_OFFICE_MANAGER_GENERAL_OPERATIONS = 28,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_MANAGER_SUPERVISOR_OFFICE = 29;
   */
  BUSINESS_SALES_OFFICE_MANAGER_SUPERVISOR_OFFICE = 29,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_EVENT_MANAGER_PROMOTER = 30;
   */
  SPORTS_RECREATION_EVENT_MANAGER_PROMOTER = 30,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_OFFICER_WARRANT = 31;
   */
  GOVERNMENT_MILITARY_OFFICER_WARRANT = 31,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_AIRPORT_OPERATIONS_CREW = 32;
   */
  TRAVEL_TRANSPORTATION_STORAGE_AIRPORT_OPERATIONS_CREW = 32,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_PARK_RANGER = 33;
   */
  SPORTS_RECREATION_PARK_RANGER = 33,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_PRINTER = 34;
   */
  ART_DESIGN_MEDIA_PRINTER = 34,

  /**
   * @generated from enum value: OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_FIELD_SERVICE_TECHNICIAN = 35;
   */
  REPAIR_MAINTENANCE_GROUNDS_FIELD_SERVICE_TECHNICIAN = 35,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_RESEARCH_PROGRAM_DIRECTOR = 36;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_RESEARCH_PROGRAM_DIRECTOR = 36,

  /**
   * @generated from enum value: OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_BUILDING_MAINTENANCE_ENGINEER = 37;
   */
  REPAIR_MAINTENANCE_GROUNDS_BUILDING_MAINTENANCE_ENGINEER = 37,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_CLINICAL_DATA_COORDINATOR = 38;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_CLINICAL_DATA_COORDINATOR = 38,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_COMMISSIONER = 39;
   */
  GOVERNMENT_MILITARY_COMMISSIONER = 39,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_COMMISSIONER = 40;
   */
  INSURANCE_COMMISSIONER = 40,

  /**
   * @generated from enum value: OCCUPATION_PERSONAL_CARE_SERVICE_PET_SERVICES = 41;
   */
  PERSONAL_CARE_SERVICE_PET_SERVICES = 41,

  /**
   * @generated from enum value: OCCUPATION_PERSONAL_CARE_SERVICE_HOUSEKEEPER = 42;
   */
  PERSONAL_CARE_SERVICE_HOUSEKEEPER = 42,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_DIRECTOR_ADMINISTRATOR = 43;
   */
  INSURANCE_DIRECTOR_ADMINISTRATOR = 43,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_OTHER = 44;
   */
  GOVERNMENT_MILITARY_OTHER = 44,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_ATTORNEY = 45;
   */
  INSURANCE_ATTORNEY = 45,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_ACCOUNT_EXECUTIVE = 46;
   */
  BUSINESS_SALES_OFFICE_ACCOUNT_EXECUTIVE = 46,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_ACTOR = 47;
   */
  ART_DESIGN_MEDIA_ACTOR = 47,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_ACTUARY = 48;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_ACTUARY = 48,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_ACTUARY = 49;
   */
  INSURANCE_ACTUARY = 49,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_ADMINISTRATIVE_ASSISTANT = 50;
   */
  GOVERNMENT_MILITARY_ADMINISTRATIVE_ASSISTANT = 50,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_ADMINISTRATIVE_ASSISTANT = 51;
   */
  BANKING_FINANCE_REAL_ESTATE_ADMINISTRATIVE_ASSISTANT = 51,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_ADMINISTRATIVE_ASSISTANT = 52;
   */
  BUSINESS_SALES_OFFICE_ADMINISTRATIVE_ASSISTANT = 52,

  /**
   * @generated from enum value: OCCUPATION_EDUCATION_LIBRARY_ADMINISTRATIVE_ASSISTANT = 53;
   */
  EDUCATION_LIBRARY_ADMINISTRATIVE_ASSISTANT = 53,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_ADMINISTRATIVE_ASSISTANT = 54;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_ADMINISTRATIVE_ASSISTANT = 54,

  /**
   * @generated from enum value: OCCUPATION_INFORMATION_TECHNOLOGY_ADMINISTRATIVE_ASSISTANT = 55;
   */
  INFORMATION_TECHNOLOGY_ADMINISTRATIVE_ASSISTANT = 55,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_ADMINISTRATIVE_ASSISTANT = 56;
   */
  INSURANCE_ADMINISTRATIVE_ASSISTANT = 56,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_ADMINISTRATIVE_ASSISTANT = 57;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_ADMINISTRATIVE_ASSISTANT = 57,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_ADMINISTRATIVE_ASSISTANT = 58;
   */
  PRODUCTION_MANUFACTURING_ADMINISTRATIVE_ASSISTANT = 58,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_ADMINISTRATIVE_ASSISTANT = 59;
   */
  SPORTS_RECREATION_ADMINISTRATIVE_ASSISTANT = 59,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_ADMINISTRATIVE_ASSISTANT = 60;
   */
  TRAVEL_TRANSPORTATION_STORAGE_ADMINISTRATIVE_ASSISTANT = 60,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_CLERK_OFFICE = 61;
   */
  BUSINESS_SALES_OFFICE_CLERK_OFFICE = 61,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_RECEPTIONIST_SECRETARY = 62;
   */
  GOVERNMENT_MILITARY_RECEPTIONIST_SECRETARY = 62,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_RECEPTIONIST_SECRETARY = 63;
   */
  ART_DESIGN_MEDIA_RECEPTIONIST_SECRETARY = 63,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_RECEPTIONIST_SECRETARY = 64;
   */
  BANKING_FINANCE_REAL_ESTATE_RECEPTIONIST_SECRETARY = 64,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_RECEPTIONIST_SECRETARY = 65;
   */
  BUSINESS_SALES_OFFICE_RECEPTIONIST_SECRETARY = 65,

  /**
   * @generated from enum value: OCCUPATION_EDUCATION_LIBRARY_RECEPTIONIST_SECRETARY = 66;
   */
  EDUCATION_LIBRARY_RECEPTIONIST_SECRETARY = 66,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_RECEPTIONIST_SECRETARY = 67;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_RECEPTIONIST_SECRETARY = 67,

  /**
   * @generated from enum value: OCCUPATION_INFORMATION_TECHNOLOGY_RECEPTIONIST_SECRETARY = 68;
   */
  INFORMATION_TECHNOLOGY_RECEPTIONIST_SECRETARY = 68,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_RECEPTIONIST_SECRETARY = 69;
   */
  INSURANCE_RECEPTIONIST_SECRETARY = 69,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_RECEPTIONIST_SECRETARY = 70;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_RECEPTIONIST_SECRETARY = 70,

  /**
   * @generated from enum value: OCCUPATION_PERSONAL_CARE_SERVICE_RECEPTIONIST_SECRETARY = 71;
   */
  PERSONAL_CARE_SERVICE_RECEPTIONIST_SECRETARY = 71,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_RECEPTIONIST_SECRETARY = 72;
   */
  SPORTS_RECREATION_RECEPTIONIST_SECRETARY = 72,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_RECEPTIONIST_SECRETARY = 73;
   */
  TRAVEL_TRANSPORTATION_STORAGE_RECEPTIONIST_SECRETARY = 73,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_PLANT_MANAGER = 74;
   */
  PRODUCTION_MANUFACTURING_PLANT_MANAGER = 74,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_DIRECTOR_ADMINISTRATOR = 75;
   */
  BANKING_FINANCE_REAL_ESTATE_DIRECTOR_ADMINISTRATOR = 75,

  /**
   * @generated from enum value: OCCUPATION_INFORMATION_TECHNOLOGY_NETWORK_ADMINISTRATOR = 76;
   */
  INFORMATION_TECHNOLOGY_NETWORK_ADMINISTRATOR = 76,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_DIRECTOR_ADMINISTRATIVE = 77;
   */
  BUSINESS_SALES_OFFICE_DIRECTOR_ADMINISTRATIVE = 77,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_DIRECTOR_ADMINISTRATOR = 78;
   */
  GOVERNMENT_MILITARY_DIRECTOR_ADMINISTRATOR = 78,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_FINANCIAL_ADVISOR = 79;
   */
  BANKING_FINANCE_REAL_ESTATE_FINANCIAL_ADVISOR = 79,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_OTHER = 80;
   */
  ART_DESIGN_MEDIA_OTHER = 80,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_OTHER = 81;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_OTHER = 81,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_AGENT = 82;
   */
  SPORTS_RECREATION_AGENT = 82,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_OTHER = 83;
   */
  TRAVEL_TRANSPORTATION_STORAGE_OTHER = 83,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_AGENT_BROKER = 84;
   */
  INSURANCE_AGENT_BROKER = 84,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_REALTOR = 85;
   */
  BANKING_FINANCE_REAL_ESTATE_REALTOR = 85,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_SALES_AGENT_REPRESENTATIVE = 86;
   */
  BANKING_FINANCE_REAL_ESTATE_SALES_AGENT_REPRESENTATIVE = 86,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_TICKET_AGENT = 87;
   */
  TRAVEL_TRANSPORTATION_STORAGE_TICKET_AGENT = 87,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_TRAVEL_AGENT = 88;
   */
  TRAVEL_TRANSPORTATION_STORAGE_TRAVEL_AGENT = 88,

  /**
   * @generated from enum value: OCCUPATION_EDUCATION_LIBRARY_TEACHING_ASSISTANT_AIDE = 89;
   */
  EDUCATION_LIBRARY_TEACHING_ASSISTANT_AIDE = 89,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_AIR_TRAFFIC_CONTROL = 90;
   */
  TRAVEL_TRANSPORTATION_STORAGE_AIR_TRAFFIC_CONTROL = 90,

  /**
   * @generated from enum value: OCCUPATION_INFORMATION_TECHNOLOGY_ANALYST = 91;
   */
  INFORMATION_TECHNOLOGY_ANALYST = 91,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_ANALYST = 92;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_ANALYST = 92,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_ANALYST = 93;
   */
  BANKING_FINANCE_REAL_ESTATE_ANALYST = 93,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_UNDERWRITER = 94;
   */
  BANKING_FINANCE_REAL_ESTATE_UNDERWRITER = 94,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_APPRAISER_REAL_ESTATE = 95;
   */
  BANKING_FINANCE_REAL_ESTATE_APPRAISER_REAL_ESTATE = 95,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_APPRAISER_REAL_ESTATE = 96;
   */
  CONSTRUCTION_ENERGY_MINING_APPRAISER_REAL_ESTATE = 96,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_ARCHITECT = 97;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_ARCHITECT = 97,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_ARTIST_ANIMATOR = 98;
   */
  ART_DESIGN_MEDIA_ARTIST_ANIMATOR = 98,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_BELLHOP = 99;
   */
  FOOD_SERVICE_HOTEL_SERVICES_BELLHOP = 99,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_BELLHOP_PORTER = 100;
   */
  TRAVEL_TRANSPORTATION_STORAGE_BELLHOP_PORTER = 100,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_VALET = 101;
   */
  FOOD_SERVICE_HOTEL_SERVICES_VALET = 101,

  /**
   * @generated from enum value: OCCUPATION_EDUCATION_LIBRARY_GRADUATE_TEACHING_ASSISTANT = 102;
   */
  EDUCATION_LIBRARY_GRADUATE_TEACHING_ASSISTANT = 102,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_LAB_ASSISTANT = 103;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_LAB_ASSISTANT = 103,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_ADMINISTRATIVE_ASSISTANT = 104;
   */
  ART_DESIGN_MEDIA_ADMINISTRATIVE_ASSISTANT = 104,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_PROCESS_SERVER = 105;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_PROCESS_SERVER = 105,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_LEGAL_ASSISTANT_SECRETARY = 106;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_LEGAL_ASSISTANT_SECRETARY = 106,

  /**
   * @generated from enum value: OCCUPATION_PERSONAL_CARE_SERVICE_CAREGIVER = 107;
   */
  PERSONAL_CARE_SERVICE_CAREGIVER = 107,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_LAB_ASSISTANT = 108;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_LAB_ASSISTANT = 108,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_ASSISTANT_MEDIC_DENT_VET = 109;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_ASSISTANT_MEDIC_DENT_VET = 109,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_ATHLETE = 110;
   */
  SPORTS_RECREATION_ATHLETE = 110,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_LEGISLATOR = 111;
   */
  GOVERNMENT_MILITARY_LEGISLATOR = 111,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_ATTORNEY = 112;
   */
  GOVERNMENT_MILITARY_ATTORNEY = 112,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_ATTORNEY = 113;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_ATTORNEY = 113,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_ACCOUNTANT_AUDITOR = 114;
   */
  BANKING_FINANCE_REAL_ESTATE_ACCOUNTANT_AUDITOR = 114,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_ACCOUNTANT_AUDITOR = 115;
   */
  INSURANCE_ACCOUNTANT_AUDITOR = 115,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_BAKER = 116;
   */
  FOOD_SERVICE_HOTEL_SERVICES_BAKER = 116,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_CSR_TELLER = 117;
   */
  BANKING_FINANCE_REAL_ESTATE_CSR_TELLER = 117,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_BARTENDER = 118;
   */
  FOOD_SERVICE_HOTEL_SERVICES_BARTENDER = 118,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_MATE_SAILOR = 119;
   */
  TRAVEL_TRANSPORTATION_STORAGE_MATE_SAILOR = 119,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_PRINTER_BOOKBINDER = 120;
   */
  PRODUCTION_MANUFACTURING_PRINTER_BOOKBINDER = 120,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_BOOKKEEPER = 121;
   */
  BANKING_FINANCE_REAL_ESTATE_BOOKKEEPER = 121,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_ANNOUNCER_BROADCASTER = 122;
   */
  ART_DESIGN_MEDIA_ANNOUNCER_BROADCASTER = 122,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_OTHER = 123;
   */
  INSURANCE_OTHER = 123,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_BROKER = 124;
   */
  BANKING_FINANCE_REAL_ESTATE_BROKER = 124,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_TRADER_FINANCIAL_INSTRUMENTS = 125;
   */
  BANKING_FINANCE_REAL_ESTATE_TRADER_FINANCIAL_INSTRUMENTS = 125,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_BUYER = 126;
   */
  BUSINESS_SALES_OFFICE_BUYER = 126,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_CARPENTER = 127;
   */
  CONSTRUCTION_ENERGY_MINING_CARPENTER = 127,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_CASHIER_CHECKER = 128;
   */
  BUSINESS_SALES_OFFICE_CASHIER_CHECKER = 128,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_CATERER = 129;
   */
  FOOD_SERVICE_HOTEL_SERVICES_CATERER = 129,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_CHIROPRACTOR = 130;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_CHIROPRACTOR = 130,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_CLAIMS_ADJUSTER = 131;
   */
  INSURANCE_CLAIMS_ADJUSTER = 131,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_COLLECTIONS = 132;
   */
  BANKING_FINANCE_REAL_ESTATE_COLLECTIONS = 132,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_OTHER = 133;
   */
  BANKING_FINANCE_REAL_ESTATE_OTHER = 133,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_CLERK = 134;
   */
  BANKING_FINANCE_REAL_ESTATE_CLERK = 134,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_CLERK = 135;
   */
  GOVERNMENT_MILITARY_CLERK = 135,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_DESK_CLERK = 136;
   */
  FOOD_SERVICE_HOTEL_SERVICES_DESK_CLERK = 136,

  /**
   * @generated from enum value: OCCUPATION_EDUCATION_LIBRARY_CLERK = 137;
   */
  EDUCATION_LIBRARY_CLERK = 137,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_CLERK = 138;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_CLERK = 138,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_CLERK = 139;
   */
  ART_DESIGN_MEDIA_CLERK = 139,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_CLERK = 140;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_CLERK = 140,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_CLERK = 141;
   */
  PRODUCTION_MANUFACTURING_CLERK = 141,

  /**
   * @generated from enum value: OCCUPATION_INFORMATION_TECHNOLOGY_PROGRAMMER = 142;
   */
  INFORMATION_TECHNOLOGY_PROGRAMMER = 142,

  /**
   * @generated from enum value: OCCUPATION_INFORMATION_TECHNOLOGY_SUPPORT_TECHNICIAN = 143;
   */
  INFORMATION_TECHNOLOGY_SUPPORT_TECHNICIAN = 143,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_CONSTRUCTION_WORKER = 144;
   */
  CONSTRUCTION_ENERGY_MINING_CONSTRUCTION_WORKER = 144,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_BRICKLAYER_MASON = 145;
   */
  CONSTRUCTION_ENERGY_MINING_BRICKLAYER_MASON = 145,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_CARPET_INSTALLER = 146;
   */
  CONSTRUCTION_ENERGY_MINING_CARPET_INSTALLER = 146,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_CONCRETE_WORKER = 147;
   */
  CONSTRUCTION_ENERGY_MINING_CONCRETE_WORKER = 147,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_FLOOR_LAYER_FINISHER = 148;
   */
  CONSTRUCTION_ENERGY_MINING_FLOOR_LAYER_FINISHER = 148,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_HANDYMAN = 149;
   */
  CONSTRUCTION_ENERGY_MINING_HANDYMAN = 149,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_LABORER_WORKER = 150;
   */
  CONSTRUCTION_ENERGY_MINING_LABORER_WORKER = 150,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_PLASTER_DRYWALL_STUCCO = 151;
   */
  CONSTRUCTION_ENERGY_MINING_PLASTER_DRYWALL_STUCCO = 151,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_ROOFER = 152;
   */
  CONSTRUCTION_ENERGY_MINING_ROOFER = 152,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_WELDER = 153;
   */
  CONSTRUCTION_ENERGY_MINING_WELDER = 153,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_CONSULTANT = 154;
   */
  BANKING_FINANCE_REAL_ESTATE_CONSULTANT = 154,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_CONSULTANT = 155;
   */
  BUSINESS_SALES_OFFICE_CONSULTANT = 155,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_DISHWASHER = 156;
   */
  FOOD_SERVICE_HOTEL_SERVICES_DISHWASHER = 156,

  /**
   * @generated from enum value: OCCUPATION_AGRICULTURE_FORESTY_FISHING_LABORER_WORKER = 157;
   */
  AGRICULTURE_FORESTY_FISHING_LABORER_WORKER = 157,

  /**
   * @generated from enum value: OCCUPATION_AGRICULTURE_FORESTY_FISHING_LOGGER = 158;
   */
  AGRICULTURE_FORESTY_FISHING_LOGGER = 158,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_PRODUCTION_CREW = 159;
   */
  ART_DESIGN_MEDIA_PRODUCTION_CREW = 159,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_PROJECTIONIST = 161;
   */
  ART_DESIGN_MEDIA_PROJECTIONIST = 161,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_CONTRACTOR = 162;
   */
  CONSTRUCTION_ENERGY_MINING_CONTRACTOR = 162,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_UMPIRE_REFEREE = 163;
   */
  SPORTS_RECREATION_UMPIRE_REFEREE = 163,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_FURNITURE_FINISHER = 164;
   */
  PRODUCTION_MANUFACTURING_FURNITURE_FINISHER = 164,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_UPHOLSTERER = 165;
   */
  PRODUCTION_MANUFACTURING_UPHOLSTERER = 165,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_CONTROLLER = 166;
   */
  BANKING_FINANCE_REAL_ESTATE_CONTROLLER = 166,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_REGULATOR = 167;
   */
  GOVERNMENT_MILITARY_REGULATOR = 167,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_CHEF = 168;
   */
  FOOD_SERVICE_HOTEL_SERVICES_CHEF = 168,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_COOK_RESTAURANT_CAFETERIA = 169;
   */
  FOOD_SERVICE_HOTEL_SERVICES_COOK_RESTAURANT_CAFETERIA = 169,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_COOK_WORKER_FAST_FOOD = 170;
   */
  FOOD_SERVICE_HOTEL_SERVICES_COOK_WORKER_FAST_FOOD = 170,

  /**
   * @generated from enum value: OCCUPATION_PERSONAL_CARE_SERVICE_OTHER = 171;
   */
  PERSONAL_CARE_SERVICE_OTHER = 171,

  /**
   * @generated from enum value: OCCUPATION_EDUCATION_LIBRARY_COUNSELOR = 172;
   */
  EDUCATION_LIBRARY_COUNSELOR = 172,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_OTHER = 173;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_OTHER = 173,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_COURT_CLERK_REPORTER = 174;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_COURT_CLERK_REPORTER = 174,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_OTHER = 175;
   */
  PRODUCTION_MANUFACTURING_OTHER = 175,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_CURATOR = 176;
   */
  ART_DESIGN_MEDIA_CURATOR = 176,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_CONCIERGE = 177;
   */
  FOOD_SERVICE_HOTEL_SERVICES_CONCIERGE = 177,

  /**
   * @generated from enum value: OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_CUSTODIAN_JANITOR = 178;
   */
  REPAIR_MAINTENANCE_GROUNDS_CUSTODIAN_JANITOR = 178,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_CLIENT_CARE_WORKER = 179;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_CLIENT_CARE_WORKER = 179,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_CUSTOMER_SERVICE_REPRESENTATIVE = 180;
   */
  BUSINESS_SALES_OFFICE_CUSTOMER_SERVICE_REPRESENTATIVE = 180,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_INSURANCE_CSR = 181;
   */
  INSURANCE_INSURANCE_CSR = 181,

  /**
   * @generated from enum value: OCCUPATION_PERSONAL_CARE_SERVICE_NANNY = 182;
   */
  PERSONAL_CARE_SERVICE_NANNY = 182,

  /**
   * @generated from enum value: OCCUPATION_EDUCATION_LIBRARY_CHILD_DAYCARE_WORKER = 183;
   */
  EDUCATION_LIBRARY_CHILD_DAYCARE_WORKER = 183,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_DELIVERY_PERSON = 184;
   */
  FOOD_SERVICE_HOTEL_SERVICES_DELIVERY_PERSON = 184,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_MESSENGER_COURIER = 185;
   */
  BUSINESS_SALES_OFFICE_MESSENGER_COURIER = 185,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_DENTAL_HYGIENIST = 186;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_DENTAL_HYGIENIST = 186,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_DENTIST = 187;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_DENTIST = 187,

  /**
   * @generated from enum value: OCCUPATION_INFORMATION_TECHNOLOGY_WEB_DEVELOPER = 188;
   */
  INFORMATION_TECHNOLOGY_WEB_DEVELOPER = 188,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_DESIGNER = 189;
   */
  ART_DESIGN_MEDIA_DESIGNER = 189,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_DIETICIAN = 190;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_DIETICIAN = 190,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_DIRECTOR_PROGRAM = 191;
   */
  SPORTS_RECREATION_DIRECTOR_PROGRAM = 191,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_CHIEF_EXECUTIVE = 192;
   */
  GOVERNMENT_MILITARY_CHIEF_EXECUTIVE = 192,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_EXECUTIVE = 193;
   */
  BUSINESS_SALES_OFFICE_EXECUTIVE = 193,

  /**
   * @generated from enum value: OCCUPATION_INFORMATION_TECHNOLOGY_EXECUTIVE = 194;
   */
  INFORMATION_TECHNOLOGY_EXECUTIVE = 194,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_EXECUTIVE = 195;
   */
  INSURANCE_EXECUTIVE = 195,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_DISPATCHER = 196;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_DISPATCHER = 196,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_DISPATCHER = 197;
   */
  TRAVEL_TRANSPORTATION_STORAGE_DISPATCHER = 197,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_DRAFTER = 198;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_DRAFTER = 198,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_DRIVER_BUS_STREETCAR = 199;
   */
  TRAVEL_TRANSPORTATION_STORAGE_DRIVER_BUS_STREETCAR = 199,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_DRIVER_TAXI_LIMO = 200;
   */
  TRAVEL_TRANSPORTATION_STORAGE_DRIVER_TAXI_LIMO = 200,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_DRIVER_TRUCK_DELIVERY = 201;
   */
  TRAVEL_TRANSPORTATION_STORAGE_DRIVER_TRUCK_DELIVERY = 201,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_ENLISTED_MILITARY_PERSONNEL_E1_E4 = 202;
   */
  GOVERNMENT_MILITARY_ENLISTED_MILITARY_PERSONNEL_E1_E4 = 202,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_NCO_E5_9 = 203;
   */
  GOVERNMENT_MILITARY_NCO_E5_9 = 203,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_EDITOR = 204;
   */
  ART_DESIGN_MEDIA_EDITOR = 204,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_ELECTRICIAN_LINESMAN = 205;
   */
  CONSTRUCTION_ENERGY_MINING_ELECTRICIAN_LINESMAN = 205,

  /**
   * @generated from enum value: OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_ELECTRICIAN = 206;
   */
  REPAIR_MAINTENANCE_GROUNDS_ELECTRICIAN = 206,

  /**
   * @generated from enum value: OCCUPATION_INFORMATION_TECHNOLOGY_OTHER = 207;
   */
  INFORMATION_TECHNOLOGY_OTHER = 207,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_CHEMIST = 208;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_CHEMIST = 208,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_ENGINEER = 209;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_ENGINEER = 209,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_OTHER = 210;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_OTHER = 210,

  /**
   * @generated from enum value: OCCUPATION_INFORMATION_TECHNOLOGY_ENGINEER_SOFTWARE = 211;
   */
  INFORMATION_TECHNOLOGY_ENGINEER_SOFTWARE = 211,

  /**
   * @generated from enum value: OCCUPATION_INFORMATION_TECHNOLOGY_SYSTEMS_SECURITY = 212;
   */
  INFORMATION_TECHNOLOGY_SYSTEMS_SECURITY = 212,

  /**
   * @generated from enum value: OCCUPATION_INFORMATION_TECHNOLOGY_ENGINEER_SYSTEMS = 213;
   */
  INFORMATION_TECHNOLOGY_ENGINEER_SYSTEMS = 213,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_MINER = 214;
   */
  CONSTRUCTION_ENERGY_MINING_MINER = 214,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_PACKER = 215;
   */
  PRODUCTION_MANUFACTURING_PACKER = 215,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_TEXTILE_WORKER = 216;
   */
  PRODUCTION_MANUFACTURING_TEXTILE_WORKER = 216,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_FACTORY_WORKER = 217;
   */
  PRODUCTION_MANUFACTURING_FACTORY_WORKER = 217,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_FIRE_CHIEF = 218;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_FIRE_CHIEF = 218,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_FIRE_FIGHTER_SUPERVISOR = 219;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_FIRE_FIGHTER_SUPERVISOR = 219,

  /**
   * @generated from enum value: OCCUPATION_AGRICULTURE_FORESTY_FISHING_FISHERMAN = 220;
   */
  AGRICULTURE_FORESTY_FISHING_FISHERMAN = 220,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_FLIGHT_ATTENDANT = 221;
   */
  TRAVEL_TRANSPORTATION_STORAGE_FLIGHT_ATTENDANT = 221,

  /**
   * @generated from enum value: OCCUPATION_AGRICULTURE_FORESTY_FISHING_FLORIST = 222;
   */
  AGRICULTURE_FORESTY_FISHING_FLORIST = 222,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_FOREMAN_SUPERVISOR = 223;
   */
  CONSTRUCTION_ENERGY_MINING_FOREMAN_SUPERVISOR = 223,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_FOREMAN_SUPERVISOR = 224;
   */
  PRODUCTION_MANUFACTURING_FOREMAN_SUPERVISOR = 224,

  /**
   * @generated from enum value: OCCUPATION_AGRICULTURE_FORESTY_FISHING_TIMBER_GRADER_SCALER = 225;
   */
  AGRICULTURE_FORESTY_FISHING_TIMBER_GRADER_SCALER = 225,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_SECURITY_GUARD = 226;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_SECURITY_GUARD = 226,

  /**
   * @generated from enum value: OCCUPATION_PERSONAL_CARE_SERVICE_HAIR_STYLIST_BARBER = 227;
   */
  PERSONAL_CARE_SERVICE_HAIR_STYLIST_BARBER = 227,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_HOST_MAITRE_D = 228;
   */
  FOOD_SERVICE_HOTEL_SERVICES_HOST_MAITRE_D = 228,

  /**
   * @generated from enum value: OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_HOUSEKEEPER_MAID = 229;
   */
  REPAIR_MAINTENANCE_GROUNDS_HOUSEKEEPER_MAID = 229,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_HOUSEKEEPER_MAID = 230;
   */
  FOOD_SERVICE_HOTEL_SERVICES_HOUSEKEEPER_MAID = 230,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_H_R_REPRESENTATIVE = 231;
   */
  BUSINESS_SALES_OFFICE_H_R_REPRESENTATIVE = 231,

  /**
   * @generated from enum value: OCCUPATION_AGRICULTURE_FORESTY_FISHING_AGRICULTURE_INSPECTOR_GRADER = 232;
   */
  AGRICULTURE_FORESTY_FISHING_AGRICULTURE_INSPECTOR_GRADER = 232,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_INSPECTOR = 233;
   */
  PRODUCTION_MANUFACTURING_INSPECTOR = 233,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_INSPECTOR = 234;
   */
  CONSTRUCTION_ENERGY_MINING_INSPECTOR = 234,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_PRIVATE_INVESTIGATOR_DETECTIVE = 235;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_PRIVATE_INVESTIGATOR_DETECTIVE = 235,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_INVESTMENT_BANKER = 236;
   */
  BANKING_FINANCE_REAL_ESTATE_INVESTMENT_BANKER = 236,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_INVESTOR = 237;
   */
  BANKING_FINANCE_REAL_ESTATE_INVESTOR = 237,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_SPORTS_BROADCAST_JOURNALIST = 238;
   */
  SPORTS_RECREATION_SPORTS_BROADCAST_JOURNALIST = 238,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_JUDGE_HEARING_OFFICER = 239;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_JUDGE_HEARING_OFFICER = 239,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_MANAGER_H_R_PUBLIC_RELATIONS = 240;
   */
  BUSINESS_SALES_OFFICE_MANAGER_H_R_PUBLIC_RELATIONS = 240,

  /**
   * @generated from enum value: OCCUPATION_AGRICULTURE_FORESTY_FISHING_ARBORIST = 241;
   */
  AGRICULTURE_FORESTY_FISHING_ARBORIST = 241,

  /**
   * @generated from enum value: OCCUPATION_AGRICULTURE_FORESTY_FISHING_LANDSCAPING_NURSERY_WORKER = 242;
   */
  AGRICULTURE_FORESTY_FISHING_LANDSCAPING_NURSERY_WORKER = 242,

  /**
   * @generated from enum value: OCCUPATION_AGRICULTURE_FORESTY_FISHING_LANDSCAPER = 243;
   */
  AGRICULTURE_FORESTY_FISHING_LANDSCAPER = 243,

  /**
   * @generated from enum value: OCCUPATION_EDUCATION_LIBRARY_LIBRARIAN_CURATOR = 244;
   */
  EDUCATION_LIBRARY_LIBRARIAN_CURATOR = 244,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_LIFE_GUARD = 245;
   */
  SPORTS_RECREATION_LIFE_GUARD = 245,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_LONGSHOREMAN = 246;
   */
  TRAVEL_TRANSPORTATION_STORAGE_LONGSHOREMAN = 246,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_MAYOR_CITY_MANAGER = 247;
   */
  GOVERNMENT_MILITARY_MAYOR_CITY_MANAGER = 247,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_MANAGER_CREDIT_LOAN = 248;
   */
  BANKING_FINANCE_REAL_ESTATE_MANAGER_CREDIT_LOAN = 248,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_MANAGER_FINANCE_INSURANCE = 249;
   */
  BUSINESS_SALES_OFFICE_MANAGER_FINANCE_INSURANCE = 249,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_MANAGER_FITNESS_CLUB = 250;
   */
  SPORTS_RECREATION_MANAGER_FITNESS_CLUB = 250,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_OTHER = 251;
   */
  FOOD_SERVICE_HOTEL_SERVICES_OTHER = 251,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_MANAGER_PORTFOLIO_PRODUCTION = 252;
   */
  BANKING_FINANCE_REAL_ESTATE_MANAGER_PORTFOLIO_PRODUCTION = 252,

  /**
   * @generated from enum value: OCCUPATION_INFORMATION_TECHNOLOGY_MANAGER_SYSTEMS = 253;
   */
  INFORMATION_TECHNOLOGY_MANAGER_SYSTEMS = 253,

  /**
   * @generated from enum value: OCCUPATION_EDUCATION_LIBRARY_AUDIO_VISUAL_TECH = 254;
   */
  EDUCATION_LIBRARY_AUDIO_VISUAL_TECH = 254,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_MANAGER_PROJECT = 255;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_MANAGER_PROJECT = 255,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_CONSTRUCTION_PROJECT_MANAGER = 256;
   */
  CONSTRUCTION_ENERGY_MINING_CONSTRUCTION_PROJECT_MANAGER = 256,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_MANAGER_PROPERTY = 257;
   */
  BANKING_FINANCE_REAL_ESTATE_MANAGER_PROPERTY = 257,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_MANAGER = 258;
   */
  FOOD_SERVICE_HOTEL_SERVICES_MANAGER = 258,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_MANAGER_MARKETING_SALES = 259;
   */
  BUSINESS_SALES_OFFICE_MANAGER_MARKETING_SALES = 259,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_MANAGER_WAREHOUSE_DISTRICT = 260;
   */
  TRAVEL_TRANSPORTATION_STORAGE_MANAGER_WAREHOUSE_DISTRICT = 260,

  /**
   * @generated from enum value: OCCUPATION_PERSONAL_CARE_SERVICE_MANICURIST = 261;
   */
  PERSONAL_CARE_SERVICE_MANICURIST = 261,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_BAILIFF = 262;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_BAILIFF = 262,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_DEPUTY_SHERIFF = 263;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_DEPUTY_SHERIFF = 263,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_SHERIFF = 264;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_SHERIFF = 264,

  /**
   * @generated from enum value: OCCUPATION_PERSONAL_CARE_SERVICE_MASSEUSE = 265;
   */
  PERSONAL_CARE_SERVICE_MASSEUSE = 265,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_MATHEMATICIAN = 266;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_MATHEMATICIAN = 266,

  /**
   * @generated from enum value: OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_MAINTENANCE_MECHANIC = 267;
   */
  REPAIR_MAINTENANCE_GROUNDS_MAINTENANCE_MECHANIC = 267,

  /**
   * @generated from enum value: OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_MECHANIC = 268;
   */
  REPAIR_MAINTENANCE_GROUNDS_MECHANIC = 268,

  /**
   * @generated from enum value: OCCUPATION_AGRICULTURE_FORESTY_FISHING_MILL_WORKER = 269;
   */
  AGRICULTURE_FORESTY_FISHING_MILL_WORKER = 269,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_MORTICIAN = 270;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_MORTICIAN = 270,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_COMPOSER_DIRECTOR = 271;
   */
  ART_DESIGN_MEDIA_COMPOSER_DIRECTOR = 271,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_MUSICIAN_SINGER = 272;
   */
  ART_DESIGN_MEDIA_MUSICIAN_SINGER = 272,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_NURSE_C_N_A = 273;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_NURSE_C_N_A = 273,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_NURSE_LPN = 274;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_NURSE_LPN = 274,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_NURSE_PRACTITIONER = 275;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_NURSE_PRACTITIONER = 275,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_NURSE_RN = 276;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_NURSE_RN = 276,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_OFFICER_COMMISSIONED = 277;
   */
  GOVERNMENT_MILITARY_OFFICER_COMMISSIONED = 277,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_CORRECTIONS_OFFICER = 278;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_CORRECTIONS_OFFICER = 278,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_LOAN_ESCROW_PROCESSOR = 279;
   */
  BANKING_FINANCE_REAL_ESTATE_LOAN_ESCROW_PROCESSOR = 279,

  /**
   * @generated from enum value: OCCUPATION_BANKING_FINANCE_REAL_ESTATE_BRANCH_MANAGER = 280;
   */
  BANKING_FINANCE_REAL_ESTATE_BRANCH_MANAGER = 280,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_HIGHWAY_PATROL_OFFICER = 281;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_HIGHWAY_PATROL_OFFICER = 281,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_POLICE_OFFICER_SUPERVISOR = 282;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_POLICE_OFFICER_SUPERVISOR = 282,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_POLICE_CHIEF = 283;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_POLICE_CHIEF = 283,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_GAMING_OFFICER_INVESTIGATOR = 284;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_GAMING_OFFICER_INVESTIGATOR = 284,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_POLICE_DETECTIVE_INVESTIGATOR = 285;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_POLICE_DETECTIVE_INVESTIGATOR = 285,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_BOILER_OPERATOR_MAKER = 286;
   */
  CONSTRUCTION_ENERGY_MINING_BOILER_OPERATOR_MAKER = 286,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_OIL_GAS_DRILLER_RIG_OPERATOR = 287;
   */
  CONSTRUCTION_ENERGY_MINING_OIL_GAS_DRILLER_RIG_OPERATOR = 287,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_UTILITY_WORKER = 288;
   */
  CONSTRUCTION_ENERGY_MINING_UTILITY_WORKER = 288,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_OTHER = 289;
   */
  CONSTRUCTION_ENERGY_MINING_OTHER = 289,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_OPTOMETRIST = 290;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_OPTOMETRIST = 290,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_ORTHODONTIST = 291;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_ORTHODONTIST = 291,

  /**
   * @generated from enum value: OCCUPATION_AGRICULTURE_FORESTY_FISHING_FARM_RANCH_OWNER = 292;
   */
  AGRICULTURE_FORESTY_FISHING_FARM_RANCH_OWNER = 292,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_PAINTER = 293;
   */
  CONSTRUCTION_ENERGY_MINING_PAINTER = 293,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_PARALEGAL_LAW_CLERK = 294;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_PARALEGAL_LAW_CLERK = 294,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_PARAMEDIC_E_M_TECHNICIAN = 295;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_PARAMEDIC_E_M_TECHNICIAN = 295,

  /**
   * @generated from enum value: OCCUPATION_AGRICULTURE_FORESTY_FISHING_RANGER = 296;
   */
  AGRICULTURE_FORESTY_FISHING_RANGER = 296,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_PARK_RANGER = 297;
   */
  GOVERNMENT_MILITARY_PARK_RANGER = 297,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_ANIMAL_CONTROL_OFFICER = 298;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_ANIMAL_CONTROL_OFFICER = 298,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_PHARMACIST = 299;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_PHARMACIST = 299,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_PHOTOGRAPHER = 300;
   */
  ART_DESIGN_MEDIA_PHOTOGRAPHER = 300,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_OTHER = 301;
   */
  SPORTS_RECREATION_OTHER = 301,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_PHYSICAL_THERAPIST = 302;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_PHYSICAL_THERAPIST = 302,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_DOCTOR = 303;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_DOCTOR = 303,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_PILOT_CAPTAIN_ENGINEER = 304;
   */
  TRAVEL_TRANSPORTATION_STORAGE_PILOT_CAPTAIN_ENGINEER = 304,

  /**
   * @generated from enum value: OCCUPATION_AGRICULTURE_FORESTY_FISHING_OTHER = 305;
   */
  AGRICULTURE_FORESTY_FISHING_OTHER = 305,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_PLANNER = 306;
   */
  GOVERNMENT_MILITARY_PLANNER = 306,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_PLUMBER = 307;
   */
  CONSTRUCTION_ENERGY_MINING_PLUMBER = 307,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_COUNCIL_MEMBER = 308;
   */
  GOVERNMENT_MILITARY_COUNCIL_MEMBER = 308,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_US_POSTAL_WORKER = 309;
   */
  GOVERNMENT_MILITARY_US_POSTAL_WORKER = 309,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_POSTMASTER = 310;
   */
  GOVERNMENT_MILITARY_POSTMASTER = 310,

  /**
   * @generated from enum value: OCCUPATION_EDUCATION_LIBRARY_PRINCIPAL = 311;
   */
  EDUCATION_LIBRARY_PRINCIPAL = 311,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_PRODUCER = 312;
   */
  ART_DESIGN_MEDIA_PRODUCER = 312,

  /**
   * @generated from enum value: OCCUPATION_EDUCATION_LIBRARY_PROFESSOR_COLLEGE = 313;
   */
  EDUCATION_LIBRARY_PROFESSOR_COLLEGE = 313,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_PSYCHOLOGIST = 314;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_PSYCHOLOGIST = 314,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_QUALITY_CONTROL = 315;
   */
  PRODUCTION_MANUFACTURING_QUALITY_CONTROL = 315,

  /**
   * @generated from enum value: OCCUPATION_AGRICULTURE_FORESTY_FISHING_FARM_RANCH_WORKER = 316;
   */
  AGRICULTURE_FORESTY_FISHING_FARM_RANCH_WORKER = 316,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_CLERGY = 317;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_CLERGY = 317,

  /**
   * @generated from enum value: OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_HEAT_AIR_CONDITIONER_REPAIRMAN = 318;
   */
  REPAIR_MAINTENANCE_GROUNDS_HEAT_AIR_CONDITIONER_REPAIRMAN = 318,

  /**
   * @generated from enum value: OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_LANDSCAPE_GROUNDS_MAINTENANCE = 319;
   */
  REPAIR_MAINTENANCE_GROUNDS_LANDSCAPE_GROUNDS_MAINTENANCE = 319,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_LABORER = 320;
   */
  TRAVEL_TRANSPORTATION_STORAGE_LABORER = 320,

  /**
   * @generated from enum value: OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_HANDYMAN = 321;
   */
  REPAIR_MAINTENANCE_GROUNDS_HANDYMAN = 321,

  /**
   * @generated from enum value: OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_OTHER = 322;
   */
  REPAIR_MAINTENANCE_GROUNDS_OTHER = 322,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_JEWELER = 323;
   */
  PRODUCTION_MANUFACTURING_JEWELER = 323,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_JOURNALIST_REPORTER = 324;
   */
  ART_DESIGN_MEDIA_JOURNALIST_REPORTER = 324,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_MARKETING_RESEARCHER = 325;
   */
  BUSINESS_SALES_OFFICE_MARKETING_RESEARCHER = 325,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_RESEARCHER = 326;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_RESEARCHER = 326,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_TICKET_SALES_USHER = 327;
   */
  ART_DESIGN_MEDIA_TICKET_SALES_USHER = 327,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_CONCESSIONARE = 328;
   */
  FOOD_SERVICE_HOTEL_SERVICES_CONCESSIONARE = 328,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_CONCESSIONAIRE = 329;
   */
  SPORTS_RECREATION_CONCESSIONAIRE = 329,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_SALES_COUNTER_RENTAL = 330;
   */
  BUSINESS_SALES_OFFICE_SALES_COUNTER_RENTAL = 330,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_SALES_HOMEBASED = 331;
   */
  BUSINESS_SALES_OFFICE_SALES_HOMEBASED = 331,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_SALES_MANUFACTURE_REP = 332;
   */
  BUSINESS_SALES_OFFICE_SALES_MANUFACTURE_REP = 332,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_OTHER = 333;
   */
  BUSINESS_SALES_OFFICE_OTHER = 333,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_SALES_REPRESENTATIVE = 334;
   */
  INSURANCE_SALES_REPRESENTATIVE = 334,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_SALES_TICKET_MEMBERSHIP = 335;
   */
  SPORTS_RECREATION_SALES_TICKET_MEMBERSHIP = 335,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_SALES_RETAIL_WHOLESALE = 336;
   */
  BUSINESS_SALES_OFFICE_SALES_RETAIL_WHOLESALE = 336,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_SCIENTIST = 337;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_SCIENTIST = 337,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_TAILOR_CUSTOM_SEWER = 338;
   */
  PRODUCTION_MANUFACTURING_TAILOR_CUSTOM_SEWER = 338,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_AIRPORT_SECURITY_OFFICER = 339;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_AIRPORT_SECURITY_OFFICER = 339,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_SHOEMAKER = 340;
   */
  PRODUCTION_MANUFACTURING_SHOEMAKER = 340,

  /**
   * @generated from enum value: OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_EXAMINER = 341;
   */
  LEGAL_LAW_ENFORCEMENT_SECURITY_EXAMINER = 341,

  /**
   * @generated from enum value: OCCUPATION_EDUCATION_LIBRARY_SUPERINTENDENT = 342;
   */
  EDUCATION_LIBRARY_SUPERINTENDENT = 342,

  /**
   * @generated from enum value: OCCUPATION_AGRICULTURE_FORESTY_FISHING_SUPERVISOR = 343;
   */
  AGRICULTURE_FORESTY_FISHING_SUPERVISOR = 343,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_SURVEYOR_MAPMAKER = 344;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_SURVEYOR_MAPMAKER = 344,

  /**
   * @generated from enum value: OCCUPATION_EDUCATION_LIBRARY_OTHER = 345;
   */
  EDUCATION_LIBRARY_OTHER = 345,

  /**
   * @generated from enum value: OCCUPATION_EDUCATION_LIBRARY_TEACHER_COLLEGE = 346;
   */
  EDUCATION_LIBRARY_TEACHER_COLLEGE = 346,

  /**
   * @generated from enum value: OCCUPATION_EDUCATION_LIBRARY_TEACHER_K_12 = 347;
   */
  EDUCATION_LIBRARY_TEACHER_K_12 = 347,

  /**
   * @generated from enum value: OCCUPATION_EDUCATION_LIBRARY_INSTRUCTOR_VOCATION = 348;
   */
  EDUCATION_LIBRARY_INSTRUCTOR_VOCATION = 348,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_COACH = 349;
   */
  SPORTS_RECREATION_COACH = 349,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_METER_READER = 350;
   */
  GOVERNMENT_MILITARY_METER_READER = 350,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_FOOD_PRODUCTION_PACKING = 351;
   */
  FOOD_SERVICE_HOTEL_SERVICES_FOOD_PRODUCTION_PACKING = 351,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_TECHNICIAN = 352;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_TECHNICIAN = 352,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_TECHNICIAN = 353;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_TECHNICIAN = 353,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_THERAPIST = 354;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_THERAPIST = 354,

  /**
   * @generated from enum value: OCCUPATION_SPORTS_RECREATION_TRAINER_INSTRUCTOR = 355;
   */
  SPORTS_RECREATION_TRAINER_INSTRUCTOR = 355,

  /**
   * @generated from enum value: OCCUPATION_EDUCATION_LIBRARY_TUTOR = 356;
   */
  EDUCATION_LIBRARY_TUTOR = 356,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_UNDERWRITER = 357;
   */
  INSURANCE_UNDERWRITER = 357,

  /**
   * @generated from enum value: OCCUPATION_BUSINESS_SALES_OFFICE_SALES_ROUTE_VENDOR = 358;
   */
  BUSINESS_SALES_OFFICE_SALES_ROUTE_VENDOR = 358,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_VETERINARIAN = 359;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_VETERINARIAN = 359,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_BUS_PERSON = 360;
   */
  FOOD_SERVICE_HOTEL_SERVICES_BUS_PERSON = 360,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_WINE_STEWARD = 361;
   */
  FOOD_SERVICE_HOTEL_SERVICES_WINE_STEWARD = 361,

  /**
   * @generated from enum value: OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_WAITER_WAITRESS = 362;
   */
  FOOD_SERVICE_HOTEL_SERVICES_WAITER_WAITRESS = 362,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_METALWORKER = 363;
   */
  CONSTRUCTION_ENERGY_MINING_METALWORKER = 363,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_RAILROAD_WORKER = 364;
   */
  TRAVEL_TRANSPORTATION_STORAGE_RAILROAD_WORKER = 364,

  /**
   * @generated from enum value: OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_SOCIOLOGIST = 365;
   */
  ENGINEER_ARCHITECT_SCIENCE_MATCH_SOCIOLOGIST = 365,

  /**
   * @generated from enum value: OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_SOCIAL_WORKER = 366;
   */
  MEDICAL_SOCIAL_SERVICES_RELIGION_SOCIAL_WORKER = 366,

  /**
   * @generated from enum value: OCCUPATION_INFORMATION_TECHNOLOGY_TECHNICAL_WRITER = 367;
   */
  INFORMATION_TECHNOLOGY_TECHNICAL_WRITER = 367,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_AUTHOR_WRITER = 368;
   */
  ART_DESIGN_MEDIA_AUTHOR_WRITER = 368,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_CLERK = 369;
   */
  TRAVEL_TRANSPORTATION_STORAGE_CLERK = 369,

  /**
   * @generated from enum value: OCCUPATION_INSURANCE_CUSTOMER_SERVICE_REPRESENTATIVE = 370;
   */
  INSURANCE_CUSTOMER_SERVICE_REPRESENTATIVE = 370,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_SUBWAY_LIGHT_RAIL_OPERATOR = 371;
   */
  TRAVEL_TRANSPORTATION_STORAGE_SUBWAY_LIGHT_RAIL_OPERATOR = 371,

  /**
   * @generated from enum value: OCCUPATION_PERSONAL_CARE_SERVICE_DRY_CLEANER_LAUNDRY = 372;
   */
  PERSONAL_CARE_SERVICE_DRY_CLEANER_LAUNDRY = 372,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_REFINING_OPERATOR = 373;
   */
  PRODUCTION_MANUFACTURING_REFINING_OPERATOR = 373,

  /**
   * @generated from enum value: OCCUPATION_AGRICULTURE_FORESTY_FISHING_EQUIPMENT_OPERATOR = 374;
   */
  AGRICULTURE_FORESTY_FISHING_EQUIPMENT_OPERATOR = 374,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_EQUIPMENT_OPERATOR = 375;
   */
  CONSTRUCTION_ENERGY_MINING_EQUIPMENT_OPERATOR = 375,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_CRANE_LOADER_OPERATOR = 376;
   */
  TRAVEL_TRANSPORTATION_STORAGE_CRANE_LOADER_OPERATOR = 376,

  /**
   * @generated from enum value: OCCUPATION_PRODUCTION_MANUFACTURING_MACHINE_OPERATOR = 377;
   */
  PRODUCTION_MANUFACTURING_MACHINE_OPERATOR = 377,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_CRANE_OPERATOR = 378;
   */
  CONSTRUCTION_ENERGY_MINING_CRANE_OPERATOR = 378,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_ELEVATOR_TECHNICIAN_INSTALLER = 379;
   */
  CONSTRUCTION_ENERGY_MINING_ELEVATOR_TECHNICIAN_INSTALLER = 379,

  /**
   * @generated from enum value: OCCUPATION_CONSTRUCTION_ENERGY_MINING_HEAT_AIR_TECHNICIAN = 380;
   */
  CONSTRUCTION_ENERGY_MINING_HEAT_AIR_TECHNICIAN = 380,

  /**
   * @generated from enum value: OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_SHIPPING_RECEIVING_CLERK = 381;
   */
  TRAVEL_TRANSPORTATION_STORAGE_SHIPPING_RECEIVING_CLERK = 381,

  /**
   * @generated from enum value: OCCUPATION_ART_DESIGN_MEDIA_CHOREOGRAPHY_DANCER = 382;
   */
  ART_DESIGN_MEDIA_CHOREOGRAPHY_DANCER = 382,

  /**
   * @generated from enum value: OCCUPATION_GOVERNMENT_MILITARY_ACCOUNTANT_AUDITOR = 383;
   */
  GOVERNMENT_MILITARY_ACCOUNTANT_AUDITOR = 383,
}
// Retrieve enum metadata with: proto3.getEnumType(Occupation)
proto3.util.setEnumType(Occupation, "agentero.risk.Occupation", [
  { no: 0, name: "OCCUPATION_UNSPECIFIED" },
  { no: 1, name: "OCCUPATION_STUDENT" },
  { no: 2, name: "OCCUPATION_HOMEMAKER" },
  { no: 3, name: "OCCUPATION_UNEMPLOYED" },
  { no: 4, name: "OCCUPATION_INSURANCE_ANALYST" },
  { no: 5, name: "OCCUPATION_PRODUCTION_MANUFACTURING_RECEPTIONIST_SECRETARY" },
  { no: 6, name: "OCCUPATION_INFORMATION_TECHNOLOGY_DIRECTOR_ADMINISTRATOR" },
  { no: 7, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_FEDERAL_AGENT_MARSHALL" },
  { no: 8, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_HOSPICE_VOLUNTEER" },
  { no: 9, name: "OCCUPATION_GOVERNMENT_MILITARY_ANALYST" },
  { no: 10, name: "OCCUPATION_SPORTS_RECREATION_ACTIVITY_RECREATIONAL_ASSISTANT" },
  { no: 11, name: "OCCUPATION_RECEPCIONIST_SECRETARY" },
  { no: 12, name: "OCCUPATION_INSURANCE_ACTUARIAL_CLERK" },
  { no: 13, name: "OCCUPATION_INFORMATION_TECHNOLOGY_CLERK" },
  { no: 14, name: "OCCUPATION_INSURANCE_CLERK" },
  { no: 15, name: "OCCUPATION_AGRICULTURE_FORESTY_FISHING_CLERK" },
  { no: 16, name: "OCCUPATION_SPORTS_RECREATION_CLERK" },
  { no: 17, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_PARKING_LOT_ATTENDANT" },
  { no: 18, name: "OCCUPATION_INFORMATION_TECHNOLOGY_PROJECT_COORDINATOR" },
  { no: 19, name: "OCCUPATION_SPORTS_RECREATION_CAMP_COUNSELOR_LEAD" },
  { no: 20, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_SUPPORT_SERVICES" },
  { no: 21, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_EXECUTIVE" },
  { no: 22, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_TRANSPORTATION_SPECIALIST" },
  { no: 23, name: "OCCUPATION_INFORMATION_TECHNOLOGY_ENGINEER_HARDWARE" },
  { no: 24, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_MANAGER_R_D" },
  { no: 25, name: "OCCUPATION_INSURANCE_PRODUCT_MANAGER" },
  { no: 26, name: "OCCUPATION_BUSINESS_SALES_OFFICE_MANAGER_DISTRICT" },
  { no: 27, name: "OCCUPATION_BUSINESS_SALES_OFFICE_MANAGER_DEPARTMENT_STORE" },
  { no: 28, name: "OCCUPATION_BUSINESS_SALES_OFFICE_MANAGER_GENERAL_OPERATIONS" },
  { no: 29, name: "OCCUPATION_BUSINESS_SALES_OFFICE_MANAGER_SUPERVISOR_OFFICE" },
  { no: 30, name: "OCCUPATION_SPORTS_RECREATION_EVENT_MANAGER_PROMOTER" },
  { no: 31, name: "OCCUPATION_GOVERNMENT_MILITARY_OFFICER_WARRANT" },
  { no: 32, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_AIRPORT_OPERATIONS_CREW" },
  { no: 33, name: "OCCUPATION_SPORTS_RECREATION_PARK_RANGER" },
  { no: 34, name: "OCCUPATION_ART_DESIGN_MEDIA_PRINTER" },
  { no: 35, name: "OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_FIELD_SERVICE_TECHNICIAN" },
  { no: 36, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_RESEARCH_PROGRAM_DIRECTOR" },
  { no: 37, name: "OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_BUILDING_MAINTENANCE_ENGINEER" },
  { no: 38, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_CLINICAL_DATA_COORDINATOR" },
  { no: 39, name: "OCCUPATION_GOVERNMENT_MILITARY_COMMISSIONER" },
  { no: 40, name: "OCCUPATION_INSURANCE_COMMISSIONER" },
  { no: 41, name: "OCCUPATION_PERSONAL_CARE_SERVICE_PET_SERVICES" },
  { no: 42, name: "OCCUPATION_PERSONAL_CARE_SERVICE_HOUSEKEEPER" },
  { no: 43, name: "OCCUPATION_INSURANCE_DIRECTOR_ADMINISTRATOR" },
  { no: 44, name: "OCCUPATION_GOVERNMENT_MILITARY_OTHER" },
  { no: 45, name: "OCCUPATION_INSURANCE_ATTORNEY" },
  { no: 46, name: "OCCUPATION_BUSINESS_SALES_OFFICE_ACCOUNT_EXECUTIVE" },
  { no: 47, name: "OCCUPATION_ART_DESIGN_MEDIA_ACTOR" },
  { no: 48, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_ACTUARY" },
  { no: 49, name: "OCCUPATION_INSURANCE_ACTUARY" },
  { no: 50, name: "OCCUPATION_GOVERNMENT_MILITARY_ADMINISTRATIVE_ASSISTANT" },
  { no: 51, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_ADMINISTRATIVE_ASSISTANT" },
  { no: 52, name: "OCCUPATION_BUSINESS_SALES_OFFICE_ADMINISTRATIVE_ASSISTANT" },
  { no: 53, name: "OCCUPATION_EDUCATION_LIBRARY_ADMINISTRATIVE_ASSISTANT" },
  { no: 54, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_ADMINISTRATIVE_ASSISTANT" },
  { no: 55, name: "OCCUPATION_INFORMATION_TECHNOLOGY_ADMINISTRATIVE_ASSISTANT" },
  { no: 56, name: "OCCUPATION_INSURANCE_ADMINISTRATIVE_ASSISTANT" },
  { no: 57, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_ADMINISTRATIVE_ASSISTANT" },
  { no: 58, name: "OCCUPATION_PRODUCTION_MANUFACTURING_ADMINISTRATIVE_ASSISTANT" },
  { no: 59, name: "OCCUPATION_SPORTS_RECREATION_ADMINISTRATIVE_ASSISTANT" },
  { no: 60, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_ADMINISTRATIVE_ASSISTANT" },
  { no: 61, name: "OCCUPATION_BUSINESS_SALES_OFFICE_CLERK_OFFICE" },
  { no: 62, name: "OCCUPATION_GOVERNMENT_MILITARY_RECEPTIONIST_SECRETARY" },
  { no: 63, name: "OCCUPATION_ART_DESIGN_MEDIA_RECEPTIONIST_SECRETARY" },
  { no: 64, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_RECEPTIONIST_SECRETARY" },
  { no: 65, name: "OCCUPATION_BUSINESS_SALES_OFFICE_RECEPTIONIST_SECRETARY" },
  { no: 66, name: "OCCUPATION_EDUCATION_LIBRARY_RECEPTIONIST_SECRETARY" },
  { no: 67, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_RECEPTIONIST_SECRETARY" },
  { no: 68, name: "OCCUPATION_INFORMATION_TECHNOLOGY_RECEPTIONIST_SECRETARY" },
  { no: 69, name: "OCCUPATION_INSURANCE_RECEPTIONIST_SECRETARY" },
  { no: 70, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_RECEPTIONIST_SECRETARY" },
  { no: 71, name: "OCCUPATION_PERSONAL_CARE_SERVICE_RECEPTIONIST_SECRETARY" },
  { no: 72, name: "OCCUPATION_SPORTS_RECREATION_RECEPTIONIST_SECRETARY" },
  { no: 73, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_RECEPTIONIST_SECRETARY" },
  { no: 74, name: "OCCUPATION_PRODUCTION_MANUFACTURING_PLANT_MANAGER" },
  { no: 75, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_DIRECTOR_ADMINISTRATOR" },
  { no: 76, name: "OCCUPATION_INFORMATION_TECHNOLOGY_NETWORK_ADMINISTRATOR" },
  { no: 77, name: "OCCUPATION_BUSINESS_SALES_OFFICE_DIRECTOR_ADMINISTRATIVE" },
  { no: 78, name: "OCCUPATION_GOVERNMENT_MILITARY_DIRECTOR_ADMINISTRATOR" },
  { no: 79, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_FINANCIAL_ADVISOR" },
  { no: 80, name: "OCCUPATION_ART_DESIGN_MEDIA_OTHER" },
  { no: 81, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_OTHER" },
  { no: 82, name: "OCCUPATION_SPORTS_RECREATION_AGENT" },
  { no: 83, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_OTHER" },
  { no: 84, name: "OCCUPATION_INSURANCE_AGENT_BROKER" },
  { no: 85, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_REALTOR" },
  { no: 86, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_SALES_AGENT_REPRESENTATIVE" },
  { no: 87, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_TICKET_AGENT" },
  { no: 88, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_TRAVEL_AGENT" },
  { no: 89, name: "OCCUPATION_EDUCATION_LIBRARY_TEACHING_ASSISTANT_AIDE" },
  { no: 90, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_AIR_TRAFFIC_CONTROL" },
  { no: 91, name: "OCCUPATION_INFORMATION_TECHNOLOGY_ANALYST" },
  { no: 92, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_ANALYST" },
  { no: 93, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_ANALYST" },
  { no: 94, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_UNDERWRITER" },
  { no: 95, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_APPRAISER_REAL_ESTATE" },
  { no: 96, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_APPRAISER_REAL_ESTATE" },
  { no: 97, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_ARCHITECT" },
  { no: 98, name: "OCCUPATION_ART_DESIGN_MEDIA_ARTIST_ANIMATOR" },
  { no: 99, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_BELLHOP" },
  { no: 100, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_BELLHOP_PORTER" },
  { no: 101, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_VALET" },
  { no: 102, name: "OCCUPATION_EDUCATION_LIBRARY_GRADUATE_TEACHING_ASSISTANT" },
  { no: 103, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_LAB_ASSISTANT" },
  { no: 104, name: "OCCUPATION_ART_DESIGN_MEDIA_ADMINISTRATIVE_ASSISTANT" },
  { no: 105, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_PROCESS_SERVER" },
  { no: 106, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_LEGAL_ASSISTANT_SECRETARY" },
  { no: 107, name: "OCCUPATION_PERSONAL_CARE_SERVICE_CAREGIVER" },
  { no: 108, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_LAB_ASSISTANT" },
  { no: 109, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_ASSISTANT_MEDIC_DENT_VET" },
  { no: 110, name: "OCCUPATION_SPORTS_RECREATION_ATHLETE" },
  { no: 111, name: "OCCUPATION_GOVERNMENT_MILITARY_LEGISLATOR" },
  { no: 112, name: "OCCUPATION_GOVERNMENT_MILITARY_ATTORNEY" },
  { no: 113, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_ATTORNEY" },
  { no: 114, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_ACCOUNTANT_AUDITOR" },
  { no: 115, name: "OCCUPATION_INSURANCE_ACCOUNTANT_AUDITOR" },
  { no: 116, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_BAKER" },
  { no: 117, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_CSR_TELLER" },
  { no: 118, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_BARTENDER" },
  { no: 119, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_MATE_SAILOR" },
  { no: 120, name: "OCCUPATION_PRODUCTION_MANUFACTURING_PRINTER_BOOKBINDER" },
  { no: 121, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_BOOKKEEPER" },
  { no: 122, name: "OCCUPATION_ART_DESIGN_MEDIA_ANNOUNCER_BROADCASTER" },
  { no: 123, name: "OCCUPATION_INSURANCE_OTHER" },
  { no: 124, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_BROKER" },
  { no: 125, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_TRADER_FINANCIAL_INSTRUMENTS" },
  { no: 126, name: "OCCUPATION_BUSINESS_SALES_OFFICE_BUYER" },
  { no: 127, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_CARPENTER" },
  { no: 128, name: "OCCUPATION_BUSINESS_SALES_OFFICE_CASHIER_CHECKER" },
  { no: 129, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_CATERER" },
  { no: 130, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_CHIROPRACTOR" },
  { no: 131, name: "OCCUPATION_INSURANCE_CLAIMS_ADJUSTER" },
  { no: 132, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_COLLECTIONS" },
  { no: 133, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_OTHER" },
  { no: 134, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_CLERK" },
  { no: 135, name: "OCCUPATION_GOVERNMENT_MILITARY_CLERK" },
  { no: 136, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_DESK_CLERK" },
  { no: 137, name: "OCCUPATION_EDUCATION_LIBRARY_CLERK" },
  { no: 138, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_CLERK" },
  { no: 139, name: "OCCUPATION_ART_DESIGN_MEDIA_CLERK" },
  { no: 140, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_CLERK" },
  { no: 141, name: "OCCUPATION_PRODUCTION_MANUFACTURING_CLERK" },
  { no: 142, name: "OCCUPATION_INFORMATION_TECHNOLOGY_PROGRAMMER" },
  { no: 143, name: "OCCUPATION_INFORMATION_TECHNOLOGY_SUPPORT_TECHNICIAN" },
  { no: 144, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_CONSTRUCTION_WORKER" },
  { no: 145, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_BRICKLAYER_MASON" },
  { no: 146, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_CARPET_INSTALLER" },
  { no: 147, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_CONCRETE_WORKER" },
  { no: 148, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_FLOOR_LAYER_FINISHER" },
  { no: 149, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_HANDYMAN" },
  { no: 150, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_LABORER_WORKER" },
  { no: 151, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_PLASTER_DRYWALL_STUCCO" },
  { no: 152, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_ROOFER" },
  { no: 153, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_WELDER" },
  { no: 154, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_CONSULTANT" },
  { no: 155, name: "OCCUPATION_BUSINESS_SALES_OFFICE_CONSULTANT" },
  { no: 156, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_DISHWASHER" },
  { no: 157, name: "OCCUPATION_AGRICULTURE_FORESTY_FISHING_LABORER_WORKER" },
  { no: 158, name: "OCCUPATION_AGRICULTURE_FORESTY_FISHING_LOGGER" },
  { no: 159, name: "OCCUPATION_ART_DESIGN_MEDIA_PRODUCTION_CREW" },
  { no: 161, name: "OCCUPATION_ART_DESIGN_MEDIA_PROJECTIONIST" },
  { no: 162, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_CONTRACTOR" },
  { no: 163, name: "OCCUPATION_SPORTS_RECREATION_UMPIRE_REFEREE" },
  { no: 164, name: "OCCUPATION_PRODUCTION_MANUFACTURING_FURNITURE_FINISHER" },
  { no: 165, name: "OCCUPATION_PRODUCTION_MANUFACTURING_UPHOLSTERER" },
  { no: 166, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_CONTROLLER" },
  { no: 167, name: "OCCUPATION_GOVERNMENT_MILITARY_REGULATOR" },
  { no: 168, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_CHEF" },
  { no: 169, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_COOK_RESTAURANT_CAFETERIA" },
  { no: 170, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_COOK_WORKER_FAST_FOOD" },
  { no: 171, name: "OCCUPATION_PERSONAL_CARE_SERVICE_OTHER" },
  { no: 172, name: "OCCUPATION_EDUCATION_LIBRARY_COUNSELOR" },
  { no: 173, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_OTHER" },
  { no: 174, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_COURT_CLERK_REPORTER" },
  { no: 175, name: "OCCUPATION_PRODUCTION_MANUFACTURING_OTHER" },
  { no: 176, name: "OCCUPATION_ART_DESIGN_MEDIA_CURATOR" },
  { no: 177, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_CONCIERGE" },
  { no: 178, name: "OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_CUSTODIAN_JANITOR" },
  { no: 179, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_CLIENT_CARE_WORKER" },
  { no: 180, name: "OCCUPATION_BUSINESS_SALES_OFFICE_CUSTOMER_SERVICE_REPRESENTATIVE" },
  { no: 181, name: "OCCUPATION_INSURANCE_INSURANCE_CSR" },
  { no: 182, name: "OCCUPATION_PERSONAL_CARE_SERVICE_NANNY" },
  { no: 183, name: "OCCUPATION_EDUCATION_LIBRARY_CHILD_DAYCARE_WORKER" },
  { no: 184, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_DELIVERY_PERSON" },
  { no: 185, name: "OCCUPATION_BUSINESS_SALES_OFFICE_MESSENGER_COURIER" },
  { no: 186, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_DENTAL_HYGIENIST" },
  { no: 187, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_DENTIST" },
  { no: 188, name: "OCCUPATION_INFORMATION_TECHNOLOGY_WEB_DEVELOPER" },
  { no: 189, name: "OCCUPATION_ART_DESIGN_MEDIA_DESIGNER" },
  { no: 190, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_DIETICIAN" },
  { no: 191, name: "OCCUPATION_SPORTS_RECREATION_DIRECTOR_PROGRAM" },
  { no: 192, name: "OCCUPATION_GOVERNMENT_MILITARY_CHIEF_EXECUTIVE" },
  { no: 193, name: "OCCUPATION_BUSINESS_SALES_OFFICE_EXECUTIVE" },
  { no: 194, name: "OCCUPATION_INFORMATION_TECHNOLOGY_EXECUTIVE" },
  { no: 195, name: "OCCUPATION_INSURANCE_EXECUTIVE" },
  { no: 196, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_DISPATCHER" },
  { no: 197, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_DISPATCHER" },
  { no: 198, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_DRAFTER" },
  { no: 199, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_DRIVER_BUS_STREETCAR" },
  { no: 200, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_DRIVER_TAXI_LIMO" },
  { no: 201, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_DRIVER_TRUCK_DELIVERY" },
  { no: 202, name: "OCCUPATION_GOVERNMENT_MILITARY_ENLISTED_MILITARY_PERSONNEL_E1_E4" },
  { no: 203, name: "OCCUPATION_GOVERNMENT_MILITARY_NCO_E5_9" },
  { no: 204, name: "OCCUPATION_ART_DESIGN_MEDIA_EDITOR" },
  { no: 205, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_ELECTRICIAN_LINESMAN" },
  { no: 206, name: "OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_ELECTRICIAN" },
  { no: 207, name: "OCCUPATION_INFORMATION_TECHNOLOGY_OTHER" },
  { no: 208, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_CHEMIST" },
  { no: 209, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_ENGINEER" },
  { no: 210, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_OTHER" },
  { no: 211, name: "OCCUPATION_INFORMATION_TECHNOLOGY_ENGINEER_SOFTWARE" },
  { no: 212, name: "OCCUPATION_INFORMATION_TECHNOLOGY_SYSTEMS_SECURITY" },
  { no: 213, name: "OCCUPATION_INFORMATION_TECHNOLOGY_ENGINEER_SYSTEMS" },
  { no: 214, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_MINER" },
  { no: 215, name: "OCCUPATION_PRODUCTION_MANUFACTURING_PACKER" },
  { no: 216, name: "OCCUPATION_PRODUCTION_MANUFACTURING_TEXTILE_WORKER" },
  { no: 217, name: "OCCUPATION_PRODUCTION_MANUFACTURING_FACTORY_WORKER" },
  { no: 218, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_FIRE_CHIEF" },
  { no: 219, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_FIRE_FIGHTER_SUPERVISOR" },
  { no: 220, name: "OCCUPATION_AGRICULTURE_FORESTY_FISHING_FISHERMAN" },
  { no: 221, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_FLIGHT_ATTENDANT" },
  { no: 222, name: "OCCUPATION_AGRICULTURE_FORESTY_FISHING_FLORIST" },
  { no: 223, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_FOREMAN_SUPERVISOR" },
  { no: 224, name: "OCCUPATION_PRODUCTION_MANUFACTURING_FOREMAN_SUPERVISOR" },
  { no: 225, name: "OCCUPATION_AGRICULTURE_FORESTY_FISHING_TIMBER_GRADER_SCALER" },
  { no: 226, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_SECURITY_GUARD" },
  { no: 227, name: "OCCUPATION_PERSONAL_CARE_SERVICE_HAIR_STYLIST_BARBER" },
  { no: 228, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_HOST_MAITRE_D" },
  { no: 229, name: "OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_HOUSEKEEPER_MAID" },
  { no: 230, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_HOUSEKEEPER_MAID" },
  { no: 231, name: "OCCUPATION_BUSINESS_SALES_OFFICE_H_R_REPRESENTATIVE" },
  { no: 232, name: "OCCUPATION_AGRICULTURE_FORESTY_FISHING_AGRICULTURE_INSPECTOR_GRADER" },
  { no: 233, name: "OCCUPATION_PRODUCTION_MANUFACTURING_INSPECTOR" },
  { no: 234, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_INSPECTOR" },
  { no: 235, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_PRIVATE_INVESTIGATOR_DETECTIVE" },
  { no: 236, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_INVESTMENT_BANKER" },
  { no: 237, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_INVESTOR" },
  { no: 238, name: "OCCUPATION_SPORTS_RECREATION_SPORTS_BROADCAST_JOURNALIST" },
  { no: 239, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_JUDGE_HEARING_OFFICER" },
  { no: 240, name: "OCCUPATION_BUSINESS_SALES_OFFICE_MANAGER_H_R_PUBLIC_RELATIONS" },
  { no: 241, name: "OCCUPATION_AGRICULTURE_FORESTY_FISHING_ARBORIST" },
  { no: 242, name: "OCCUPATION_AGRICULTURE_FORESTY_FISHING_LANDSCAPING_NURSERY_WORKER" },
  { no: 243, name: "OCCUPATION_AGRICULTURE_FORESTY_FISHING_LANDSCAPER" },
  { no: 244, name: "OCCUPATION_EDUCATION_LIBRARY_LIBRARIAN_CURATOR" },
  { no: 245, name: "OCCUPATION_SPORTS_RECREATION_LIFE_GUARD" },
  { no: 246, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_LONGSHOREMAN" },
  { no: 247, name: "OCCUPATION_GOVERNMENT_MILITARY_MAYOR_CITY_MANAGER" },
  { no: 248, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_MANAGER_CREDIT_LOAN" },
  { no: 249, name: "OCCUPATION_BUSINESS_SALES_OFFICE_MANAGER_FINANCE_INSURANCE" },
  { no: 250, name: "OCCUPATION_SPORTS_RECREATION_MANAGER_FITNESS_CLUB" },
  { no: 251, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_OTHER" },
  { no: 252, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_MANAGER_PORTFOLIO_PRODUCTION" },
  { no: 253, name: "OCCUPATION_INFORMATION_TECHNOLOGY_MANAGER_SYSTEMS" },
  { no: 254, name: "OCCUPATION_EDUCATION_LIBRARY_AUDIO_VISUAL_TECH" },
  { no: 255, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_MANAGER_PROJECT" },
  { no: 256, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_CONSTRUCTION_PROJECT_MANAGER" },
  { no: 257, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_MANAGER_PROPERTY" },
  { no: 258, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_MANAGER" },
  { no: 259, name: "OCCUPATION_BUSINESS_SALES_OFFICE_MANAGER_MARKETING_SALES" },
  { no: 260, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_MANAGER_WAREHOUSE_DISTRICT" },
  { no: 261, name: "OCCUPATION_PERSONAL_CARE_SERVICE_MANICURIST" },
  { no: 262, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_BAILIFF" },
  { no: 263, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_DEPUTY_SHERIFF" },
  { no: 264, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_SHERIFF" },
  { no: 265, name: "OCCUPATION_PERSONAL_CARE_SERVICE_MASSEUSE" },
  { no: 266, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_MATHEMATICIAN" },
  { no: 267, name: "OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_MAINTENANCE_MECHANIC" },
  { no: 268, name: "OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_MECHANIC" },
  { no: 269, name: "OCCUPATION_AGRICULTURE_FORESTY_FISHING_MILL_WORKER" },
  { no: 270, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_MORTICIAN" },
  { no: 271, name: "OCCUPATION_ART_DESIGN_MEDIA_COMPOSER_DIRECTOR" },
  { no: 272, name: "OCCUPATION_ART_DESIGN_MEDIA_MUSICIAN_SINGER" },
  { no: 273, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_NURSE_C_N_A" },
  { no: 274, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_NURSE_LPN" },
  { no: 275, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_NURSE_PRACTITIONER" },
  { no: 276, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_NURSE_RN" },
  { no: 277, name: "OCCUPATION_GOVERNMENT_MILITARY_OFFICER_COMMISSIONED" },
  { no: 278, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_CORRECTIONS_OFFICER" },
  { no: 279, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_LOAN_ESCROW_PROCESSOR" },
  { no: 280, name: "OCCUPATION_BANKING_FINANCE_REAL_ESTATE_BRANCH_MANAGER" },
  { no: 281, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_HIGHWAY_PATROL_OFFICER" },
  { no: 282, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_POLICE_OFFICER_SUPERVISOR" },
  { no: 283, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_POLICE_CHIEF" },
  { no: 284, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_GAMING_OFFICER_INVESTIGATOR" },
  { no: 285, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_POLICE_DETECTIVE_INVESTIGATOR" },
  { no: 286, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_BOILER_OPERATOR_MAKER" },
  { no: 287, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_OIL_GAS_DRILLER_RIG_OPERATOR" },
  { no: 288, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_UTILITY_WORKER" },
  { no: 289, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_OTHER" },
  { no: 290, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_OPTOMETRIST" },
  { no: 291, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_ORTHODONTIST" },
  { no: 292, name: "OCCUPATION_AGRICULTURE_FORESTY_FISHING_FARM_RANCH_OWNER" },
  { no: 293, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_PAINTER" },
  { no: 294, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_PARALEGAL_LAW_CLERK" },
  { no: 295, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_PARAMEDIC_E_M_TECHNICIAN" },
  { no: 296, name: "OCCUPATION_AGRICULTURE_FORESTY_FISHING_RANGER" },
  { no: 297, name: "OCCUPATION_GOVERNMENT_MILITARY_PARK_RANGER" },
  { no: 298, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_ANIMAL_CONTROL_OFFICER" },
  { no: 299, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_PHARMACIST" },
  { no: 300, name: "OCCUPATION_ART_DESIGN_MEDIA_PHOTOGRAPHER" },
  { no: 301, name: "OCCUPATION_SPORTS_RECREATION_OTHER" },
  { no: 302, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_PHYSICAL_THERAPIST" },
  { no: 303, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_DOCTOR" },
  { no: 304, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_PILOT_CAPTAIN_ENGINEER" },
  { no: 305, name: "OCCUPATION_AGRICULTURE_FORESTY_FISHING_OTHER" },
  { no: 306, name: "OCCUPATION_GOVERNMENT_MILITARY_PLANNER" },
  { no: 307, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_PLUMBER" },
  { no: 308, name: "OCCUPATION_GOVERNMENT_MILITARY_COUNCIL_MEMBER" },
  { no: 309, name: "OCCUPATION_GOVERNMENT_MILITARY_US_POSTAL_WORKER" },
  { no: 310, name: "OCCUPATION_GOVERNMENT_MILITARY_POSTMASTER" },
  { no: 311, name: "OCCUPATION_EDUCATION_LIBRARY_PRINCIPAL" },
  { no: 312, name: "OCCUPATION_ART_DESIGN_MEDIA_PRODUCER" },
  { no: 313, name: "OCCUPATION_EDUCATION_LIBRARY_PROFESSOR_COLLEGE" },
  { no: 314, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_PSYCHOLOGIST" },
  { no: 315, name: "OCCUPATION_PRODUCTION_MANUFACTURING_QUALITY_CONTROL" },
  { no: 316, name: "OCCUPATION_AGRICULTURE_FORESTY_FISHING_FARM_RANCH_WORKER" },
  { no: 317, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_CLERGY" },
  { no: 318, name: "OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_HEAT_AIR_CONDITIONER_REPAIRMAN" },
  { no: 319, name: "OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_LANDSCAPE_GROUNDS_MAINTENANCE" },
  { no: 320, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_LABORER" },
  { no: 321, name: "OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_HANDYMAN" },
  { no: 322, name: "OCCUPATION_REPAIR_MAINTENANCE_GROUNDS_OTHER" },
  { no: 323, name: "OCCUPATION_PRODUCTION_MANUFACTURING_JEWELER" },
  { no: 324, name: "OCCUPATION_ART_DESIGN_MEDIA_JOURNALIST_REPORTER" },
  { no: 325, name: "OCCUPATION_BUSINESS_SALES_OFFICE_MARKETING_RESEARCHER" },
  { no: 326, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_RESEARCHER" },
  { no: 327, name: "OCCUPATION_ART_DESIGN_MEDIA_TICKET_SALES_USHER" },
  { no: 328, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_CONCESSIONARE" },
  { no: 329, name: "OCCUPATION_SPORTS_RECREATION_CONCESSIONAIRE" },
  { no: 330, name: "OCCUPATION_BUSINESS_SALES_OFFICE_SALES_COUNTER_RENTAL" },
  { no: 331, name: "OCCUPATION_BUSINESS_SALES_OFFICE_SALES_HOMEBASED" },
  { no: 332, name: "OCCUPATION_BUSINESS_SALES_OFFICE_SALES_MANUFACTURE_REP" },
  { no: 333, name: "OCCUPATION_BUSINESS_SALES_OFFICE_OTHER" },
  { no: 334, name: "OCCUPATION_INSURANCE_SALES_REPRESENTATIVE" },
  { no: 335, name: "OCCUPATION_SPORTS_RECREATION_SALES_TICKET_MEMBERSHIP" },
  { no: 336, name: "OCCUPATION_BUSINESS_SALES_OFFICE_SALES_RETAIL_WHOLESALE" },
  { no: 337, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_SCIENTIST" },
  { no: 338, name: "OCCUPATION_PRODUCTION_MANUFACTURING_TAILOR_CUSTOM_SEWER" },
  { no: 339, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_AIRPORT_SECURITY_OFFICER" },
  { no: 340, name: "OCCUPATION_PRODUCTION_MANUFACTURING_SHOEMAKER" },
  { no: 341, name: "OCCUPATION_LEGAL_LAW_ENFORCEMENT_SECURITY_EXAMINER" },
  { no: 342, name: "OCCUPATION_EDUCATION_LIBRARY_SUPERINTENDENT" },
  { no: 343, name: "OCCUPATION_AGRICULTURE_FORESTY_FISHING_SUPERVISOR" },
  { no: 344, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_SURVEYOR_MAPMAKER" },
  { no: 345, name: "OCCUPATION_EDUCATION_LIBRARY_OTHER" },
  { no: 346, name: "OCCUPATION_EDUCATION_LIBRARY_TEACHER_COLLEGE" },
  { no: 347, name: "OCCUPATION_EDUCATION_LIBRARY_TEACHER_K_12" },
  { no: 348, name: "OCCUPATION_EDUCATION_LIBRARY_INSTRUCTOR_VOCATION" },
  { no: 349, name: "OCCUPATION_SPORTS_RECREATION_COACH" },
  { no: 350, name: "OCCUPATION_GOVERNMENT_MILITARY_METER_READER" },
  { no: 351, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_FOOD_PRODUCTION_PACKING" },
  { no: 352, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_TECHNICIAN" },
  { no: 353, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_TECHNICIAN" },
  { no: 354, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_THERAPIST" },
  { no: 355, name: "OCCUPATION_SPORTS_RECREATION_TRAINER_INSTRUCTOR" },
  { no: 356, name: "OCCUPATION_EDUCATION_LIBRARY_TUTOR" },
  { no: 357, name: "OCCUPATION_INSURANCE_UNDERWRITER" },
  { no: 358, name: "OCCUPATION_BUSINESS_SALES_OFFICE_SALES_ROUTE_VENDOR" },
  { no: 359, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_VETERINARIAN" },
  { no: 360, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_BUS_PERSON" },
  { no: 361, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_WINE_STEWARD" },
  { no: 362, name: "OCCUPATION_FOOD_SERVICE_HOTEL_SERVICES_WAITER_WAITRESS" },
  { no: 363, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_METALWORKER" },
  { no: 364, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_RAILROAD_WORKER" },
  { no: 365, name: "OCCUPATION_ENGINEER_ARCHITECT_SCIENCE_MATCH_SOCIOLOGIST" },
  { no: 366, name: "OCCUPATION_MEDICAL_SOCIAL_SERVICES_RELIGION_SOCIAL_WORKER" },
  { no: 367, name: "OCCUPATION_INFORMATION_TECHNOLOGY_TECHNICAL_WRITER" },
  { no: 368, name: "OCCUPATION_ART_DESIGN_MEDIA_AUTHOR_WRITER" },
  { no: 369, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_CLERK" },
  { no: 370, name: "OCCUPATION_INSURANCE_CUSTOMER_SERVICE_REPRESENTATIVE" },
  { no: 371, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_SUBWAY_LIGHT_RAIL_OPERATOR" },
  { no: 372, name: "OCCUPATION_PERSONAL_CARE_SERVICE_DRY_CLEANER_LAUNDRY" },
  { no: 373, name: "OCCUPATION_PRODUCTION_MANUFACTURING_REFINING_OPERATOR" },
  { no: 374, name: "OCCUPATION_AGRICULTURE_FORESTY_FISHING_EQUIPMENT_OPERATOR" },
  { no: 375, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_EQUIPMENT_OPERATOR" },
  { no: 376, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_CRANE_LOADER_OPERATOR" },
  { no: 377, name: "OCCUPATION_PRODUCTION_MANUFACTURING_MACHINE_OPERATOR" },
  { no: 378, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_CRANE_OPERATOR" },
  { no: 379, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_ELEVATOR_TECHNICIAN_INSTALLER" },
  { no: 380, name: "OCCUPATION_CONSTRUCTION_ENERGY_MINING_HEAT_AIR_TECHNICIAN" },
  { no: 381, name: "OCCUPATION_TRAVEL_TRANSPORTATION_STORAGE_SHIPPING_RECEIVING_CLERK" },
  { no: 382, name: "OCCUPATION_ART_DESIGN_MEDIA_CHOREOGRAPHY_DANCER" },
  { no: 383, name: "OCCUPATION_GOVERNMENT_MILITARY_ACCOUNTANT_AUDITOR" },
]);

/**
 * EmploymentStatus represents the employment status of the applicant.
 *
 * @generated from enum agentero.risk.EmploymentStatus
 */
export enum EmploymentStatus {
  /**
   * @generated from enum value: EMPLOYMENT_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: EMPLOYMENT_STATUS_EMPLOYED_PRIVATELY = 1;
   */
  EMPLOYED_PRIVATELY = 1,

  /**
   * @generated from enum value: EMPLOYMENT_STATUS_FULL_STUDENT = 2;
   */
  FULL_STUDENT = 2,

  /**
   * @generated from enum value: EMPLOYMENT_STATUS_HOME_MAKER = 3;
   */
  HOME_MAKER = 3,

  /**
   * @generated from enum value: EMPLOYMENT_STATUS_SERVE_MILITARY = 4;
   */
  SERVE_MILITARY = 4,

  /**
   * @generated from enum value: EMPLOYMENT_STATUS_RETIRED = 5;
   */
  RETIRED = 5,

  /**
   * @generated from enum value: EMPLOYMENT_STATUS_SELF_EMP = 6;
   */
  SELF_EMP = 6,

  /**
   * @generated from enum value: EMPLOYMENT_STATUS_UNEMPLOYED = 7;
   */
  UNEMPLOYED = 7,

  /**
   * @generated from enum value: EMPLOYMENT_STATUS_DISABLED = 8;
   */
  DISABLED = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(EmploymentStatus)
proto3.util.setEnumType(EmploymentStatus, "agentero.risk.EmploymentStatus", [
  { no: 0, name: "EMPLOYMENT_STATUS_UNSPECIFIED" },
  { no: 1, name: "EMPLOYMENT_STATUS_EMPLOYED_PRIVATELY" },
  { no: 2, name: "EMPLOYMENT_STATUS_FULL_STUDENT" },
  { no: 3, name: "EMPLOYMENT_STATUS_HOME_MAKER" },
  { no: 4, name: "EMPLOYMENT_STATUS_SERVE_MILITARY" },
  { no: 5, name: "EMPLOYMENT_STATUS_RETIRED" },
  { no: 6, name: "EMPLOYMENT_STATUS_SELF_EMP" },
  { no: 7, name: "EMPLOYMENT_STATUS_UNEMPLOYED" },
  { no: 8, name: "EMPLOYMENT_STATUS_DISABLED" },
]);

/**
 * VehicleUsage represents the usage that the driver uses the vehicle for.
 *
 * @generated from enum agentero.risk.VehicleUsage
 */
export enum VehicleUsage {
  /**
   * @generated from enum value: VEHICLE_USAGE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: VEHICLE_USAGE_ARTISAN = 1;
   */
  ARTISAN = 1,

  /**
   * @generated from enum value: VEHICLE_USAGE_BUSINESS = 2;
   */
  BUSINESS = 2,

  /**
   * @generated from enum value: VEHICLE_USAGE_COMMUTE = 3;
   */
  COMMUTE = 3,

  /**
   * @generated from enum value: VEHICLE_USAGE_FARM = 4;
   */
  FARM = 4,

  /**
   * @generated from enum value: VEHICLE_USAGE_PLEASURE = 5;
   */
  PLEASURE = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(VehicleUsage)
proto3.util.setEnumType(VehicleUsage, "agentero.risk.VehicleUsage", [
  { no: 0, name: "VEHICLE_USAGE_UNSPECIFIED" },
  { no: 1, name: "VEHICLE_USAGE_ARTISAN" },
  { no: 2, name: "VEHICLE_USAGE_BUSINESS" },
  { no: 3, name: "VEHICLE_USAGE_COMMUTE" },
  { no: 4, name: "VEHICLE_USAGE_FARM" },
  { no: 5, name: "VEHICLE_USAGE_PLEASURE" },
]);

/**
 * MaritalStatus represents the marital status of the applicant.
 *
 * @generated from enum agentero.risk.MaritalStatus
 */
export enum MaritalStatus {
  /**
   * @generated from enum value: MARITAL_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: MARITAL_STATUS_MARRIED = 1;
   */
  MARRIED = 1,

  /**
   * @generated from enum value: MARITAL_STATUS_SINGLE = 2;
   */
  SINGLE = 2,

  /**
   * @generated from enum value: MARITAL_STATUS_DIVORCED = 3;
   */
  DIVORCED = 3,

  /**
   * @generated from enum value: MARITAL_STATUS_SEPARATED = 4;
   */
  SEPARATED = 4,

  /**
   * @generated from enum value: MARITAL_STATUS_WIDOWED = 5;
   */
  WIDOWED = 5,

  /**
   * @generated from enum value: MARITAL_STATUS_CIVIL = 6;
   */
  CIVIL = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(MaritalStatus)
proto3.util.setEnumType(MaritalStatus, "agentero.risk.MaritalStatus", [
  { no: 0, name: "MARITAL_STATUS_UNSPECIFIED" },
  { no: 1, name: "MARITAL_STATUS_MARRIED" },
  { no: 2, name: "MARITAL_STATUS_SINGLE" },
  { no: 3, name: "MARITAL_STATUS_DIVORCED" },
  { no: 4, name: "MARITAL_STATUS_SEPARATED" },
  { no: 5, name: "MARITAL_STATUS_WIDOWED" },
  { no: 6, name: "MARITAL_STATUS_CIVIL" },
]);

/**
 * Gender represents the gender of the applicant.
 *
 * @generated from enum agentero.risk.Gender
 */
export enum Gender {
  /**
   * @generated from enum value: GENDER_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: GENDER_MALE = 1;
   */
  MALE = 1,

  /**
   * @generated from enum value: GENDER_FEMALE = 2;
   */
  FEMALE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Gender)
proto3.util.setEnumType(Gender, "agentero.risk.Gender", [
  { no: 0, name: "GENDER_UNSPECIFIED" },
  { no: 1, name: "GENDER_MALE" },
  { no: 2, name: "GENDER_FEMALE" },
]);

/**
 * HighestEducationLevel is the highest level of education applicant.
 *
 * @generated from enum agentero.risk.HighestEducationLevel
 */
export enum HighestEducationLevel {
  /**
   * @generated from enum value: HIGHEST_EDUCATION_LEVEL_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: HIGHEST_EDUCATION_LEVEL_MASTERS_DEGREE = 1;
   */
  MASTERS_DEGREE = 1,

  /**
   * @generated from enum value: HIGHEST_EDUCATION_LEVEL_ASSOCIATE = 2;
   */
  ASSOCIATE = 2,

  /**
   * @generated from enum value: HIGHEST_EDUCATION_LEVEL_BACHELORS = 3;
   */
  BACHELORS = 3,

  /**
   * @generated from enum value: HIGHEST_EDUCATION_LEVEL_HIGHSCHOOL = 4;
   */
  HIGHSCHOOL = 4,

  /**
   * @generated from enum value: HIGHEST_EDUCATION_LEVEL_LOWER_THAN_HIGHSCHOOL = 5;
   */
  LOWER_THAN_HIGHSCHOOL = 5,

  /**
   * @generated from enum value: HIGHEST_EDUCATION_LEVEL_PHD = 6;
   */
  PHD = 6,

  /**
   * @generated from enum value: HIGHEST_EDUCATION_LEVEL_SOME_COLLEGE = 7;
   */
  SOME_COLLEGE = 7,

  /**
   * @generated from enum value: HIGHEST_EDUCATION_LEVEL_LAW_DEGREE = 8;
   */
  LAW_DEGREE = 8,

  /**
   * @generated from enum value: HIGHEST_EDUCATION_LEVEL_VOCATIONAL = 9;
   */
  VOCATIONAL = 9,
}
// Retrieve enum metadata with: proto3.getEnumType(HighestEducationLevel)
proto3.util.setEnumType(HighestEducationLevel, "agentero.risk.HighestEducationLevel", [
  { no: 0, name: "HIGHEST_EDUCATION_LEVEL_UNSPECIFIED" },
  { no: 1, name: "HIGHEST_EDUCATION_LEVEL_MASTERS_DEGREE" },
  { no: 2, name: "HIGHEST_EDUCATION_LEVEL_ASSOCIATE" },
  { no: 3, name: "HIGHEST_EDUCATION_LEVEL_BACHELORS" },
  { no: 4, name: "HIGHEST_EDUCATION_LEVEL_HIGHSCHOOL" },
  { no: 5, name: "HIGHEST_EDUCATION_LEVEL_LOWER_THAN_HIGHSCHOOL" },
  { no: 6, name: "HIGHEST_EDUCATION_LEVEL_PHD" },
  { no: 7, name: "HIGHEST_EDUCATION_LEVEL_SOME_COLLEGE" },
  { no: 8, name: "HIGHEST_EDUCATION_LEVEL_LAW_DEGREE" },
  { no: 9, name: "HIGHEST_EDUCATION_LEVEL_VOCATIONAL" },
]);

/**
 * DriverLicenseStatus is the status of the driver's license.
 *
 * @generated from enum agentero.risk.DriverLicenseStatus
 */
export enum DriverLicenseStatus {
  /**
   * @generated from enum value: DRIVER_LICENSE_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DRIVER_LICENSE_STATUS_FOREIGN = 1;
   */
  FOREIGN = 1,

  /**
   * @generated from enum value: DRIVER_LICENSE_STATUS_NONLICENSED = 2;
   */
  NONLICENSED = 2,

  /**
   * @generated from enum value: DRIVER_LICENSE_STATUS_PERMIT = 3;
   */
  PERMIT = 3,

  /**
   * @generated from enum value: DRIVER_LICENSE_STATUS_RESTRICTED = 4;
   */
  RESTRICTED = 4,

  /**
   * @generated from enum value: DRIVER_LICENSE_STATUS_REVOKED = 5;
   */
  REVOKED = 5,

  /**
   * @generated from enum value: DRIVER_LICENSE_STATUS_SURRENDERED = 6;
   */
  SURRENDERED = 6,

  /**
   * @generated from enum value: DRIVER_LICENSE_STATUS_SUSPENDED = 7;
   */
  SUSPENDED = 7,

  /**
   * @generated from enum value: DRIVER_LICENSE_STATUS_VALID = 8;
   */
  VALID = 8,

  /**
   * @generated from enum value: DRIVER_LICENSE_STATUS_EXPIRED = 9;
   */
  EXPIRED = 9,
}
// Retrieve enum metadata with: proto3.getEnumType(DriverLicenseStatus)
proto3.util.setEnumType(DriverLicenseStatus, "agentero.risk.DriverLicenseStatus", [
  { no: 0, name: "DRIVER_LICENSE_STATUS_UNSPECIFIED" },
  { no: 1, name: "DRIVER_LICENSE_STATUS_FOREIGN" },
  { no: 2, name: "DRIVER_LICENSE_STATUS_NONLICENSED" },
  { no: 3, name: "DRIVER_LICENSE_STATUS_PERMIT" },
  { no: 4, name: "DRIVER_LICENSE_STATUS_RESTRICTED" },
  { no: 5, name: "DRIVER_LICENSE_STATUS_REVOKED" },
  { no: 6, name: "DRIVER_LICENSE_STATUS_SURRENDERED" },
  { no: 7, name: "DRIVER_LICENSE_STATUS_SUSPENDED" },
  { no: 8, name: "DRIVER_LICENSE_STATUS_VALID" },
  { no: 9, name: "DRIVER_LICENSE_STATUS_EXPIRED" },
]);

/**
 * RelationshipWithInsured is the relationship of the driver to the applicant.
 *
 * @generated from enum agentero.risk.RelationshipWithInsured
 */
export enum RelationshipWithInsured {
  /**
   * @generated from enum value: RELATIONSHIP_WITH_INSURED_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: RELATIONSHIP_WITH_INSURED_APPLICANT = 1;
   */
  APPLICANT = 1,

  /**
   * @generated from enum value: RELATIONSHIP_WITH_INSURED_CHILD = 2;
   */
  CHILD = 2,

  /**
   * @generated from enum value: RELATIONSHIP_WITH_INSURED_NONRESIDENTDRIVER = 3;
   */
  NONRESIDENTDRIVER = 3,

  /**
   * @generated from enum value: RELATIONSHIP_WITH_INSURED_OTHER = 4;
   */
  OTHER = 4,

  /**
   * @generated from enum value: RELATIONSHIP_WITH_INSURED_OTHERRELATIVE = 5;
   */
  OTHERRELATIVE = 5,

  /**
   * @generated from enum value: RELATIONSHIP_WITH_INSURED_PARENT = 6;
   */
  PARENT = 6,

  /**
   * @generated from enum value: RELATIONSHIP_WITH_INSURED_PARTNER = 7;
   */
  PARTNER = 7,

  /**
   * @generated from enum value: RELATIONSHIP_WITH_INSURED_SPOUSE = 8;
   */
  SPOUSE = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(RelationshipWithInsured)
proto3.util.setEnumType(RelationshipWithInsured, "agentero.risk.RelationshipWithInsured", [
  { no: 0, name: "RELATIONSHIP_WITH_INSURED_UNSPECIFIED" },
  { no: 1, name: "RELATIONSHIP_WITH_INSURED_APPLICANT" },
  { no: 2, name: "RELATIONSHIP_WITH_INSURED_CHILD" },
  { no: 3, name: "RELATIONSHIP_WITH_INSURED_NONRESIDENTDRIVER" },
  { no: 4, name: "RELATIONSHIP_WITH_INSURED_OTHER" },
  { no: 5, name: "RELATIONSHIP_WITH_INSURED_OTHERRELATIVE" },
  { no: 6, name: "RELATIONSHIP_WITH_INSURED_PARENT" },
  { no: 7, name: "RELATIONSHIP_WITH_INSURED_PARTNER" },
  { no: 8, name: "RELATIONSHIP_WITH_INSURED_SPOUSE" },
]);

/**
 * VehicleOwnership is the type of ownership of the vehicle.
 *
 * @generated from enum agentero.risk.VehicleOwnership
 */
export enum VehicleOwnership {
  /**
   * @generated from enum value: VEHICLE_OWNERSHIP_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: VEHICLE_OWNERSHIP_OWNED = 1;
   */
  OWNED = 1,

  /**
   * @generated from enum value: VEHICLE_OWNERSHIP_FINANCED = 2;
   */
  FINANCED = 2,

  /**
   * @generated from enum value: VEHICLE_OWNERSHIP_LEASED = 3;
   */
  LEASED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(VehicleOwnership)
proto3.util.setEnumType(VehicleOwnership, "agentero.risk.VehicleOwnership", [
  { no: 0, name: "VEHICLE_OWNERSHIP_UNSPECIFIED" },
  { no: 1, name: "VEHICLE_OWNERSHIP_OWNED" },
  { no: 2, name: "VEHICLE_OWNERSHIP_FINANCED" },
  { no: 3, name: "VEHICLE_OWNERSHIP_LEASED" },
]);

/**
 * ViolationType represents the type of violation.
 *
 * @generated from enum agentero.risk.ViolationType
 */
export enum ViolationType {
  /**
   * @generated from enum value: VIOLATION_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * VIOLATION_TYPE_ACCIDENT_WITH_PEDESTRIAN represents an accident involving a pedestrian.
   *
   * @generated from enum value: VIOLATION_TYPE_ACCIDENT_WITH_PEDESTRIAN = 1;
   */
  ACCIDENT_WITH_PEDESTRIAN = 1,

  /**
   * VIOLATION_TYPE_AT_FAULT represents an at-fault accident.
   *
   * @generated from enum value: VIOLATION_TYPE_AT_FAULT = 2;
   */
  AT_FAULT = 2,

  /**
   * VIOLATION_TYPE_ACCIDENT_NOT_AT_FAULT represents an accident where the driver is not at fault.
   *
   * @generated from enum value: VIOLATION_TYPE_ACCIDENT_NOT_AT_FAULT = 3;
   */
  ACCIDENT_NOT_AT_FAULT = 3,

  /**
   * VIOLATION_TYPE_DRIVING_AT_NIGHT_WITHOUT_LIGHTS represents driving at night without lights.
   *
   * @generated from enum value: VIOLATION_TYPE_DRIVING_AT_NIGHT_WITHOUT_LIGHTS = 4;
   */
  DRIVING_AT_NIGHT_WITHOUT_LIGHTS = 4,

  /**
   * VIOLATION_TYPE_AVOIDING_TRAFFIC_CONTROL_DEVICE represents avoiding a traffic control device.
   *
   * @generated from enum value: VIOLATION_TYPE_AVOIDING_TRAFFIC_CONTROL_DEVICE = 5;
   */
  AVOIDING_TRAFFIC_CONTROL_DEVICE = 5,

  /**
   * VIOLATION_TYPE_DRIVING_UNDER_MINIMUM_SPEED_LIMIT represents driving under the minimum speed limit.
   *
   * @generated from enum value: VIOLATION_TYPE_DRIVING_UNDER_MINIMUM_SPEED_LIMIT = 6;
   */
  DRIVING_UNDER_MINIMUM_SPEED_LIMIT = 6,

  /**
   * VIOLATION_TYPE_ALL_OTHER_MOVING_VIOLATIONS represents all other moving violations.
   *
   * @generated from enum value: VIOLATION_TYPE_ALL_OTHER_MOVING_VIOLATIONS = 7;
   */
  ALL_OTHER_MOVING_VIOLATIONS = 7,

  /**
   * VIOLATION_TYPE_IMPROPER_DRIVING represents improper driving.
   *
   * @generated from enum value: VIOLATION_TYPE_IMPROPER_DRIVING = 8;
   */
  IMPROPER_DRIVING = 8,

  /**
   * VIOLATION_TYPE_LOANED_DL_TO_ANOTHER_PERSON represents loaning driver's license to another person.
   *
   * @generated from enum value: VIOLATION_TYPE_LOANED_DL_TO_ANOTHER_PERSON = 9;
   */
  LOANED_DL_TO_ANOTHER_PERSON = 9,

  /**
   * VIOLATION_TYPE_DISOBEY_POLICE_ORDER represents disobeying a police order.
   *
   * @generated from enum value: VIOLATION_TYPE_DISOBEY_POLICE_ORDER = 10;
   */
  DISOBEY_POLICE_ORDER = 10,

  /**
   * VIOLATION_TYPE_ALL_OTHER_NON_MOVING_VIOLATIONS represents all other non-moving violations.
   *
   * @generated from enum value: VIOLATION_TYPE_ALL_OTHER_NON_MOVING_VIOLATIONS = 11;
   */
  ALL_OTHER_NON_MOVING_VIOLATIONS = 11,

  /**
   * VIOLATION_TYPE_CONVICTION_OF_INSURANCE_FRAUD represents conviction of insurance fraud.
   *
   * @generated from enum value: VIOLATION_TYPE_CONVICTION_OF_INSURANCE_FRAUD = 12;
   */
  CONVICTION_OF_INSURANCE_FRAUD = 12,

  /**
   * VIOLATION_TYPE_UNRESTRAINED_CHILD represents driving with an unrestrained child.
   *
   * @generated from enum value: VIOLATION_TYPE_UNRESTRAINED_CHILD = 13;
   */
  UNRESTRAINED_CHILD = 13,

  /**
   * VIOLATION_TYPE_VEHICLE_EMISSIONS_SUSPENSION represents vehicle emissions suspension.
   *
   * @generated from enum value: VIOLATION_TYPE_VEHICLE_EMISSIONS_SUSPENSION = 14;
   */
  VEHICLE_EMISSIONS_SUSPENSION = 14,

  /**
   * VIOLATION_TYPE_LEAVING_SCENE_HIT_AND_RUN represents leaving the scene of an accident or hit-and-run.
   *
   * @generated from enum value: VIOLATION_TYPE_LEAVING_SCENE_HIT_AND_RUN = 15;
   */
  LEAVING_SCENE_HIT_AND_RUN = 15,

  /**
   * VIOLATION_TYPE_OPEN_CONTAINER_VIOLATION represents an open container violation.
   *
   * @generated from enum value: VIOLATION_TYPE_OPEN_CONTAINER_VIOLATION = 16;
   */
  OPEN_CONTAINER_VIOLATION = 16,

  /**
   * VIOLATION_TYPE_CONSUMING_ALCOHOL_WHILE_DRIVING represents consuming alcohol while driving.
   *
   * @generated from enum value: VIOLATION_TYPE_CONSUMING_ALCOHOL_WHILE_DRIVING = 17;
   */
  CONSUMING_ALCOHOL_WHILE_DRIVING = 17,

  /**
   * VIOLATION_TYPE_MINOR_BAC represents a minor blood alcohol content (BAC).
   *
   * @generated from enum value: VIOLATION_TYPE_MINOR_BAC = 18;
   */
  MINOR_BAC = 18,

  /**
   * VIOLATION_TYPE_DUI_ALCOHOL represents driving under the influence (DUI) of alcohol or liquor.
   *
   * @generated from enum value: VIOLATION_TYPE_DUI_ALCOHOL = 19;
   */
  DUI_ALCOHOL = 19,

  /**
   * VIOLATION_TYPE_DUI_DRUGS_OPIATES represents driving under the influence (DUI) of drugs or opiates.
   *
   * @generated from enum value: VIOLATION_TYPE_DUI_DRUGS_OPIATES = 20;
   */
  DUI_DRUGS_OPIATES = 20,

  /**
   * VIOLATION_TYPE_DRIVING_WHILE_SUSPENDED_OR_REVOKED represents driving while suspended or revoked.
   *
   * @generated from enum value: VIOLATION_TYPE_DRIVING_WHILE_SUSPENDED_OR_REVOKED = 21;
   */
  DRIVING_WHILE_SUSPENDED_OR_REVOKED = 21,

  /**
   * VIOLATION_TYPE_EQUIPMENT_VIOLATIONS represents equipment violations.
   *
   * @generated from enum value: VIOLATION_TYPE_EQUIPMENT_VIOLATIONS = 22;
   */
  EQUIPMENT_VIOLATIONS = 22,

  /**
   * VIOLATION_TYPE_EQUIPMENT_OR_CARGO_LITTERING represents equipment or cargo littering.
   *
   * @generated from enum value: VIOLATION_TYPE_EQUIPMENT_OR_CARGO_LITTERING = 23;
   */
  EQUIPMENT_OR_CARGO_LITTERING = 23,

  /**
   * VIOLATION_TYPE_FAILURE_TO_STOP_FOR_RED_LIGHT represents failure to stop for a red light.
   *
   * @generated from enum value: VIOLATION_TYPE_FAILURE_TO_STOP_FOR_RED_LIGHT = 24;
   */
  FAILURE_TO_STOP_FOR_RED_LIGHT = 24,

  /**
   * VIOLATION_TYPE_FAILURE_TO_STOP_FOR_STOP_SIGN represents failure to stop for a stop sign.
   *
   * @generated from enum value: VIOLATION_TYPE_FAILURE_TO_STOP_FOR_STOP_SIGN = 25;
   */
  FAILURE_TO_STOP_FOR_STOP_SIGN = 25,

  /**
   * VIOLATION_TYPE_HOMICIDE_WITH_A_MOTOR_VEHICLE represents homicide with a motor vehicle.
   *
   * @generated from enum value: VIOLATION_TYPE_HOMICIDE_WITH_A_MOTOR_VEHICLE = 26;
   */
  HOMICIDE_WITH_A_MOTOR_VEHICLE = 26,

  /**
   * VIOLATION_TYPE_ELUDING_POLICE_EVADING_ARREST represents eluding police or evading arrest.
   *
   * @generated from enum value: VIOLATION_TYPE_ELUDING_POLICE_EVADING_ARREST = 27;
   */
  ELUDING_POLICE_EVADING_ARREST = 27,

  /**
   * VIOLATION_TYPE_FELONY_INVOLVING_A_MOTOR_VEHICLE represents a felony involving a motor vehicle.
   *
   * @generated from enum value: VIOLATION_TYPE_FELONY_INVOLVING_A_MOTOR_VEHICLE = 28;
   */
  FELONY_INVOLVING_A_MOTOR_VEHICLE = 28,

  /**
   * VIOLATION_TYPE_VEHICULAR_MANSLAUGHTER represents vehicular manslaughter.
   *
   * @generated from enum value: VIOLATION_TYPE_VEHICULAR_MANSLAUGHTER = 29;
   */
  VEHICULAR_MANSLAUGHTER = 29,

  /**
   * VIOLATION_TYPE_NEGLIGENT_DRIVING represents negligent driving.
   *
   * @generated from enum value: VIOLATION_TYPE_NEGLIGENT_DRIVING = 30;
   */
  NEGLIGENT_DRIVING = 30,

  /**
   * VIOLATION_TYPE_FALSE_LICENSE_OR_REGISTRATION represents false license or registration.
   *
   * @generated from enum value: VIOLATION_TYPE_FALSE_LICENSE_OR_REGISTRATION = 31;
   */
  FALSE_LICENSE_OR_REGISTRATION = 31,

  /**
   * VIOLATION_TYPE_DISPLAY_ANOTHER_PERSONS_DL represents displaying another person's driver's license.
   *
   * @generated from enum value: VIOLATION_TYPE_DISPLAY_ANOTHER_PERSONS_DL = 32;
   */
  DISPLAY_ANOTHER_PERSONS_DL = 32,

  /**
   * VIOLATION_TYPE_DRIVING_WITH_EXPIRED_LICENSE represents driving with an expired license.
   *
   * @generated from enum value: VIOLATION_TYPE_DRIVING_WITH_EXPIRED_LICENSE = 33;
   */
  DRIVING_WITH_EXPIRED_LICENSE = 33,

  /**
   * VIOLATION_TYPE_OBTAINING_LICENSE_BY_MISREPRESENTING represents obtaining a license by misrepresenting information.
   *
   * @generated from enum value: VIOLATION_TYPE_OBTAINING_LICENSE_BY_MISREPRESENTING = 34;
   */
  OBTAINING_LICENSE_BY_MISREPRESENTING = 34,

  /**
   * VIOLATION_TYPE_DRIVING_WITHOUT_LICENSE_OR_PERMIT represents driving without a license or permit.
   *
   * @generated from enum value: VIOLATION_TYPE_DRIVING_WITHOUT_LICENSE_OR_PERMIT = 35;
   */
  DRIVING_WITHOUT_LICENSE_OR_PERMIT = 35,

  /**
   * VIOLATION_TYPE_FAILURE_TO_WEAR_SEAT_BELT represents failure to wear a seat belt.
   *
   * @generated from enum value: VIOLATION_TYPE_FAILURE_TO_WEAR_SEAT_BELT = 36;
   */
  FAILURE_TO_WEAR_SEAT_BELT = 36,

  /**
   * VIOLATION_TYPE_IMPROPERLY_BACKING represents improper backing.
   *
   * @generated from enum value: VIOLATION_TYPE_IMPROPERLY_BACKING = 37;
   */
  IMPROPERLY_BACKING = 37,

  /**
   * VIOLATION_TYPE_CHANGING_LANES_WHEN_UNSAFE represents changing lanes when unsafe.
   *
   * @generated from enum value: VIOLATION_TYPE_CHANGING_LANES_WHEN_UNSAFE = 38;
   */
  CHANGING_LANES_WHEN_UNSAFE = 38,

  /**
   * VIOLATION_TYPE_CROSSING_YELLOW_LINE represents crossing a yellow line.
   *
   * @generated from enum value: VIOLATION_TYPE_CROSSING_YELLOW_LINE = 39;
   */
  CROSSING_YELLOW_LINE = 39,

  /**
   * VIOLATION_TYPE_DRIVING_ON_SHOULDER represents driving on the shoulder of the road.
   *
   * @generated from enum value: VIOLATION_TYPE_DRIVING_ON_SHOULDER = 40;
   */
  DRIVING_ON_SHOULDER = 40,

  /**
   * VIOLATION_TYPE_DRIVING_ON_SIDEWALK_OR_PARKWAY represents driving on a sidewalk or parkway.
   *
   * @generated from enum value: VIOLATION_TYPE_DRIVING_ON_SIDEWALK_OR_PARKWAY = 41;
   */
  DRIVING_ON_SIDEWALK_OR_PARKWAY = 41,

  /**
   * VIOLATION_TYPE_FAILURE_TO_KEEP_RIGHT represents failure to keep right.
   *
   * @generated from enum value: VIOLATION_TYPE_FAILURE_TO_KEEP_RIGHT = 42;
   */
  FAILURE_TO_KEEP_RIGHT = 42,

  /**
   * VIOLATION_TYPE_IMPROPER_USE_OF_LANE represents improper use of a lane.
   *
   * @generated from enum value: VIOLATION_TYPE_IMPROPER_USE_OF_LANE = 43;
   */
  IMPROPER_USE_OF_LANE = 43,

  /**
   * VIOLATION_TYPE_BUS_CAR_POOL_HOV_LANE_VIOLATION represents a violation of bus, carpool, or HOV lane.
   *
   * @generated from enum value: VIOLATION_TYPE_BUS_CAR_POOL_HOV_LANE_VIOLATION = 44;
   */
  BUS_CAR_POOL_HOV_LANE_VIOLATION = 44,

  /**
   * VIOLATION_TYPE_FOLLOWING_IMPROPERLY represents following improperly.
   *
   * @generated from enum value: VIOLATION_TYPE_FOLLOWING_IMPROPERLY = 45;
   */
  FOLLOWING_IMPROPERLY = 45,

  /**
   * VIOLATION_TYPE_FOLLOWING_TOO_CLOSE represents following too closely.
   *
   * @generated from enum value: VIOLATION_TYPE_FOLLOWING_TOO_CLOSE = 46;
   */
  FOLLOWING_TOO_CLOSE = 46,

  /**
   * VIOLATION_TYPE_DRIVING_ON_WRONG_SIDE_OF_ROAD represents driving on the wrong side of the road.
   *
   * @generated from enum value: VIOLATION_TYPE_DRIVING_ON_WRONG_SIDE_OF_ROAD = 47;
   */
  DRIVING_ON_WRONG_SIDE_OF_ROAD = 47,

  /**
   * VIOLATION_TYPE_DRIVING_WRONG_WAY represents driving the wrong way.
   *
   * @generated from enum value: VIOLATION_TYPE_DRIVING_WRONG_WAY = 48;
   */
  DRIVING_WRONG_WAY = 48,

  /**
   * VIOLATION_TYPE_FAILURE_TO_YIELD represents failure to yield.
   *
   * @generated from enum value: VIOLATION_TYPE_FAILURE_TO_YIELD = 49;
   */
  FAILURE_TO_YIELD = 49,

  /**
   * VIOLATION_TYPE_LICENSE_SUSPENSION represents license suspension.
   *
   * @generated from enum value: VIOLATION_TYPE_LICENSE_SUSPENSION = 50;
   */
  LICENSE_SUSPENSION = 50,

  /**
   * VIOLATION_TYPE_CRIMINAL_NEGLIGENCE represents criminal negligence.
   *
   * @generated from enum value: VIOLATION_TYPE_CRIMINAL_NEGLIGENCE = 51;
   */
  CRIMINAL_NEGLIGENCE = 51,

  /**
   * VIOLATION_TYPE_STEALING_AUTO represents auto theft.
   *
   * @generated from enum value: VIOLATION_TYPE_STEALING_AUTO = 52;
   */
  STEALING_AUTO = 52,

  /**
   * VIOLATION_TYPE_SPEED_WITH_INJURY represents speeding with injury.
   *
   * @generated from enum value: VIOLATION_TYPE_SPEED_WITH_INJURY = 53;
   */
  SPEED_WITH_INJURY = 53,

  /**
   * VIOLATION_TYPE_COMPREHENSIVE_CLAIM represents a comprehensive insurance claim.
   *
   * @generated from enum value: VIOLATION_TYPE_COMPREHENSIVE_CLAIM = 54;
   */
  COMPREHENSIVE_CLAIM = 54,

  /**
   * VIOLATION_TYPE_USE_OF_WIRELESS_DEVICE_WITHOUT_HANDSFREE_WHILE_DRIVING represents use of a wireless device without hands-free mode while driving.
   *
   * @generated from enum value: VIOLATION_TYPE_USE_OF_WIRELESS_DEVICE_WITHOUT_HANDSFREE_WHILE_DRIVING = 55;
   */
  USE_OF_WIRELESS_DEVICE_WITHOUT_HANDSFREE_WHILE_DRIVING = 55,

  /**
   * VIOLATION_TYPE_USE_OF_WIRELESS_DEVICE_FOR_TEXT_BASED_COMMUNICATION_WHILE_DRIVING represents use of a wireless device for text-based communication while driving.
   *
   * @generated from enum value: VIOLATION_TYPE_USE_OF_WIRELESS_DEVICE_FOR_TEXT_BASED_COMMUNICATION_WHILE_DRIVING = 56;
   */
  USE_OF_WIRELESS_DEVICE_FOR_TEXT_BASED_COMMUNICATION_WHILE_DRIVING = 56,

  /**
   * VIOLATION_TYPE_EXPIRED_OR_IMPROPER_LICENSE_PLATE represents an expired or improper license plate.
   *
   * @generated from enum value: VIOLATION_TYPE_EXPIRED_OR_IMPROPER_LICENSE_PLATE = 57;
   */
  EXPIRED_OR_IMPROPER_LICENSE_PLATE = 57,

  /**
   * VIOLATION_TYPE_NO_LIABILITY_INSURANCE_IN_FORCE represents no liability insurance in force.
   *
   * @generated from enum value: VIOLATION_TYPE_NO_LIABILITY_INSURANCE_IN_FORCE = 58;
   */
  NO_LIABILITY_INSURANCE_IN_FORCE = 58,

  /**
   * VIOLATION_TYPE_PARKING_ON_ROADWAY represents parking on a roadway.
   *
   * @generated from enum value: VIOLATION_TYPE_PARKING_ON_ROADWAY = 59;
   */
  PARKING_ON_ROADWAY = 59,

  /**
   * VIOLATION_TYPE_IMPROPER_PASSING represents improper passing.
   *
   * @generated from enum value: VIOLATION_TYPE_IMPROPER_PASSING = 60;
   */
  IMPROPER_PASSING = 60,

  /**
   * VIOLATION_TYPE_IMPROPER_PASSING_OF_A_SCHOOL_BUS represents improper passing of a school bus.
   *
   * @generated from enum value: VIOLATION_TYPE_IMPROPER_PASSING_OF_A_SCHOOL_BUS = 61;
   */
  IMPROPER_PASSING_OF_A_SCHOOL_BUS = 61,

  /**
   * VIOLATION_TYPE_RACING_SPEED_CONTEST represents racing or speed contest violation.
   *
   * @generated from enum value: VIOLATION_TYPE_RACING_SPEED_CONTEST = 62;
   */
  RACING_SPEED_CONTEST = 62,

  /**
   * VIOLATION_TYPE_RECKLESS_DRIVING represents reckless driving.
   *
   * @generated from enum value: VIOLATION_TYPE_RECKLESS_DRIVING = 63;
   */
  RECKLESS_DRIVING = 63,

  /**
   * VIOLATION_TYPE_IMPLIED_CONSENT_REFUSE_BREATH_TEST represents refusal of a breath test under implied consent.
   *
   * @generated from enum value: VIOLATION_TYPE_IMPLIED_CONSENT_REFUSE_BREATH_TEST = 64;
   */
  IMPLIED_CONSENT_REFUSE_BREATH_TEST = 64,

  /**
   * VIOLATION_TYPE_SPEEDING_OVER_30MPH_OVER_LIMIT represents speeding over 30 mph above the speed limit.
   *
   * @generated from enum value: VIOLATION_TYPE_SPEEDING_OVER_30MPH_OVER_LIMIT = 65;
   */
  SPEEDING_OVER_30MPH_OVER_LIMIT = 65,

  /**
   * VIOLATION_TYPE_SPEEDING_LESS_THAN_30MPH_OVER_LIMIT represents speeding less than 30 mph above the speed limit.
   *
   * @generated from enum value: VIOLATION_TYPE_SPEEDING_LESS_THAN_30MPH_OVER_LIMIT = 66;
   */
  SPEEDING_LESS_THAN_30MPH_OVER_LIMIT = 66,

  /**
   * VIOLATION_TYPE_SPEEDING_OVER_100MPH_OVER_LIMIT represents speeding over 100 mph above the speed limit.
   *
   * @generated from enum value: VIOLATION_TYPE_SPEEDING_OVER_100MPH_OVER_LIMIT = 67;
   */
  SPEEDING_OVER_100MPH_OVER_LIMIT = 67,

  /**
   * VIOLATION_TYPE_FAILURE_TO_STOP_AFTER_ACCIDENT represents failure to stop after an accident.
   *
   * @generated from enum value: VIOLATION_TYPE_FAILURE_TO_STOP_AFTER_ACCIDENT = 68;
   */
  FAILURE_TO_STOP_AFTER_ACCIDENT = 68,

  /**
   * VIOLATION_TYPE_FAILURE_TO_GIVE_STOP_OR_TURN_SIGNAL represents failure to give a stop or turn signal.
   *
   * @generated from enum value: VIOLATION_TYPE_FAILURE_TO_GIVE_STOP_OR_TURN_SIGNAL = 69;
   */
  FAILURE_TO_GIVE_STOP_OR_TURN_SIGNAL = 69,

  /**
   * VIOLATION_TYPE_GIVING_IMPROPER_SIGNAL represents giving an improper signal.
   *
   * @generated from enum value: VIOLATION_TYPE_GIVING_IMPROPER_SIGNAL = 70;
   */
  GIVING_IMPROPER_SIGNAL = 70,

  /**
   * VIOLATION_TYPE_VIOLATION_OF_DL_RESTRICTION represents violation of driver's license restriction.
   *
   * @generated from enum value: VIOLATION_TYPE_VIOLATION_OF_DL_RESTRICTION = 71;
   */
  VIOLATION_OF_DL_RESTRICTION = 71,
}
// Retrieve enum metadata with: proto3.getEnumType(ViolationType)
proto3.util.setEnumType(ViolationType, "agentero.risk.ViolationType", [
  { no: 0, name: "VIOLATION_TYPE_UNSPECIFIED" },
  { no: 1, name: "VIOLATION_TYPE_ACCIDENT_WITH_PEDESTRIAN" },
  { no: 2, name: "VIOLATION_TYPE_AT_FAULT" },
  { no: 3, name: "VIOLATION_TYPE_ACCIDENT_NOT_AT_FAULT" },
  { no: 4, name: "VIOLATION_TYPE_DRIVING_AT_NIGHT_WITHOUT_LIGHTS" },
  { no: 5, name: "VIOLATION_TYPE_AVOIDING_TRAFFIC_CONTROL_DEVICE" },
  { no: 6, name: "VIOLATION_TYPE_DRIVING_UNDER_MINIMUM_SPEED_LIMIT" },
  { no: 7, name: "VIOLATION_TYPE_ALL_OTHER_MOVING_VIOLATIONS" },
  { no: 8, name: "VIOLATION_TYPE_IMPROPER_DRIVING" },
  { no: 9, name: "VIOLATION_TYPE_LOANED_DL_TO_ANOTHER_PERSON" },
  { no: 10, name: "VIOLATION_TYPE_DISOBEY_POLICE_ORDER" },
  { no: 11, name: "VIOLATION_TYPE_ALL_OTHER_NON_MOVING_VIOLATIONS" },
  { no: 12, name: "VIOLATION_TYPE_CONVICTION_OF_INSURANCE_FRAUD" },
  { no: 13, name: "VIOLATION_TYPE_UNRESTRAINED_CHILD" },
  { no: 14, name: "VIOLATION_TYPE_VEHICLE_EMISSIONS_SUSPENSION" },
  { no: 15, name: "VIOLATION_TYPE_LEAVING_SCENE_HIT_AND_RUN" },
  { no: 16, name: "VIOLATION_TYPE_OPEN_CONTAINER_VIOLATION" },
  { no: 17, name: "VIOLATION_TYPE_CONSUMING_ALCOHOL_WHILE_DRIVING" },
  { no: 18, name: "VIOLATION_TYPE_MINOR_BAC" },
  { no: 19, name: "VIOLATION_TYPE_DUI_ALCOHOL" },
  { no: 20, name: "VIOLATION_TYPE_DUI_DRUGS_OPIATES" },
  { no: 21, name: "VIOLATION_TYPE_DRIVING_WHILE_SUSPENDED_OR_REVOKED" },
  { no: 22, name: "VIOLATION_TYPE_EQUIPMENT_VIOLATIONS" },
  { no: 23, name: "VIOLATION_TYPE_EQUIPMENT_OR_CARGO_LITTERING" },
  { no: 24, name: "VIOLATION_TYPE_FAILURE_TO_STOP_FOR_RED_LIGHT" },
  { no: 25, name: "VIOLATION_TYPE_FAILURE_TO_STOP_FOR_STOP_SIGN" },
  { no: 26, name: "VIOLATION_TYPE_HOMICIDE_WITH_A_MOTOR_VEHICLE" },
  { no: 27, name: "VIOLATION_TYPE_ELUDING_POLICE_EVADING_ARREST" },
  { no: 28, name: "VIOLATION_TYPE_FELONY_INVOLVING_A_MOTOR_VEHICLE" },
  { no: 29, name: "VIOLATION_TYPE_VEHICULAR_MANSLAUGHTER" },
  { no: 30, name: "VIOLATION_TYPE_NEGLIGENT_DRIVING" },
  { no: 31, name: "VIOLATION_TYPE_FALSE_LICENSE_OR_REGISTRATION" },
  { no: 32, name: "VIOLATION_TYPE_DISPLAY_ANOTHER_PERSONS_DL" },
  { no: 33, name: "VIOLATION_TYPE_DRIVING_WITH_EXPIRED_LICENSE" },
  { no: 34, name: "VIOLATION_TYPE_OBTAINING_LICENSE_BY_MISREPRESENTING" },
  { no: 35, name: "VIOLATION_TYPE_DRIVING_WITHOUT_LICENSE_OR_PERMIT" },
  { no: 36, name: "VIOLATION_TYPE_FAILURE_TO_WEAR_SEAT_BELT" },
  { no: 37, name: "VIOLATION_TYPE_IMPROPERLY_BACKING" },
  { no: 38, name: "VIOLATION_TYPE_CHANGING_LANES_WHEN_UNSAFE" },
  { no: 39, name: "VIOLATION_TYPE_CROSSING_YELLOW_LINE" },
  { no: 40, name: "VIOLATION_TYPE_DRIVING_ON_SHOULDER" },
  { no: 41, name: "VIOLATION_TYPE_DRIVING_ON_SIDEWALK_OR_PARKWAY" },
  { no: 42, name: "VIOLATION_TYPE_FAILURE_TO_KEEP_RIGHT" },
  { no: 43, name: "VIOLATION_TYPE_IMPROPER_USE_OF_LANE" },
  { no: 44, name: "VIOLATION_TYPE_BUS_CAR_POOL_HOV_LANE_VIOLATION" },
  { no: 45, name: "VIOLATION_TYPE_FOLLOWING_IMPROPERLY" },
  { no: 46, name: "VIOLATION_TYPE_FOLLOWING_TOO_CLOSE" },
  { no: 47, name: "VIOLATION_TYPE_DRIVING_ON_WRONG_SIDE_OF_ROAD" },
  { no: 48, name: "VIOLATION_TYPE_DRIVING_WRONG_WAY" },
  { no: 49, name: "VIOLATION_TYPE_FAILURE_TO_YIELD" },
  { no: 50, name: "VIOLATION_TYPE_LICENSE_SUSPENSION" },
  { no: 51, name: "VIOLATION_TYPE_CRIMINAL_NEGLIGENCE" },
  { no: 52, name: "VIOLATION_TYPE_STEALING_AUTO" },
  { no: 53, name: "VIOLATION_TYPE_SPEED_WITH_INJURY" },
  { no: 54, name: "VIOLATION_TYPE_COMPREHENSIVE_CLAIM" },
  { no: 55, name: "VIOLATION_TYPE_USE_OF_WIRELESS_DEVICE_WITHOUT_HANDSFREE_WHILE_DRIVING" },
  { no: 56, name: "VIOLATION_TYPE_USE_OF_WIRELESS_DEVICE_FOR_TEXT_BASED_COMMUNICATION_WHILE_DRIVING" },
  { no: 57, name: "VIOLATION_TYPE_EXPIRED_OR_IMPROPER_LICENSE_PLATE" },
  { no: 58, name: "VIOLATION_TYPE_NO_LIABILITY_INSURANCE_IN_FORCE" },
  { no: 59, name: "VIOLATION_TYPE_PARKING_ON_ROADWAY" },
  { no: 60, name: "VIOLATION_TYPE_IMPROPER_PASSING" },
  { no: 61, name: "VIOLATION_TYPE_IMPROPER_PASSING_OF_A_SCHOOL_BUS" },
  { no: 62, name: "VIOLATION_TYPE_RACING_SPEED_CONTEST" },
  { no: 63, name: "VIOLATION_TYPE_RECKLESS_DRIVING" },
  { no: 64, name: "VIOLATION_TYPE_IMPLIED_CONSENT_REFUSE_BREATH_TEST" },
  { no: 65, name: "VIOLATION_TYPE_SPEEDING_OVER_30MPH_OVER_LIMIT" },
  { no: 66, name: "VIOLATION_TYPE_SPEEDING_LESS_THAN_30MPH_OVER_LIMIT" },
  { no: 67, name: "VIOLATION_TYPE_SPEEDING_OVER_100MPH_OVER_LIMIT" },
  { no: 68, name: "VIOLATION_TYPE_FAILURE_TO_STOP_AFTER_ACCIDENT" },
  { no: 69, name: "VIOLATION_TYPE_FAILURE_TO_GIVE_STOP_OR_TURN_SIGNAL" },
  { no: 70, name: "VIOLATION_TYPE_GIVING_IMPROPER_SIGNAL" },
  { no: 71, name: "VIOLATION_TYPE_VIOLATION_OF_DL_RESTRICTION" },
]);

/**
 * @generated from message agentero.risk.Risk
 */
export class Risk extends Message<Risk> {
  /**
   * @generated from oneof agentero.risk.Risk.details
   */
  details: {
    /**
     * @generated from field: agentero.risk.HomeRisk home_risk = 1;
     */
    value: HomeRisk;
    case: "homeRisk";
  } | {
    /**
     * @generated from field: agentero.risk.AutoRisk auto_risk = 2;
     */
    value: AutoRisk;
    case: "autoRisk";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Risk>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.risk.Risk";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "home_risk", kind: "message", T: HomeRisk, oneof: "details" },
    { no: 2, name: "auto_risk", kind: "message", T: AutoRisk, oneof: "details" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Risk {
    return new Risk().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Risk {
    return new Risk().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Risk {
    return new Risk().fromJsonString(jsonString, options);
  }

  static equals(a: Risk | PlainMessage<Risk> | undefined, b: Risk | PlainMessage<Risk> | undefined): boolean {
    return proto3.util.equals(Risk, a, b);
  }
}

/**
 * @generated from message agentero.risk.HomeRisk
 */
export class HomeRisk extends Message<HomeRisk> {
  /**
   * Prospect information used for risk calculations
   *
   * @generated from field: string first_name = 1;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 2;
   */
  lastName = "";

  /**
   * @generated from field: google.protobuf.Timestamp birth_date = 3;
   */
  birthDate?: Timestamp;

  /**
   * @generated from field: agentero.risk.MaritalStatus marital_status = 11;
   */
  maritalStatus = MaritalStatus.UNSPECIFIED;

  /**
   * @generated from field: agentero.risk.Gender gender = 12;
   */
  gender = Gender.UNSPECIFIED;

  /**
   * Risk Address
   *
   * @generated from field: string street = 4;
   */
  street = "";

  /**
   * @generated from field: string street_number = 10;
   */
  streetNumber = "";

  /**
   * @generated from field: string zip_code = 5;
   */
  zipCode = "";

  /**
   * @generated from field: string city = 6;
   */
  city = "";

  /**
   * @generated from field: string state = 7;
   */
  state = "";

  /**
   * @generated from field: string county = 16;
   */
  county = "";

  /**
   * Apartment number / unit number.
   * Part of the risk address
   *
   * @generated from field: string unit = 8;
   */
  unit = "";

  /**
   * @generated from field: agentero.risk.AdditionalFields additional_fields = 9;
   */
  additionalFields?: AdditionalFields;

  /**
   * @generated from field: agentero.risk.CurrentCarrier current_carrier = 13;
   */
  currentCarrier = CurrentCarrier.UNSPECIFIED;

  /**
   * @generated from field: agentero.risk.DurationPeriod time_with_current_carrier = 14;
   */
  timeWithCurrentCarrier?: DurationPeriod;

  /**
   * @generated from field: google.protobuf.Timestamp effective_date = 15;
   */
  effectiveDate?: Timestamp;

  /**
   * This map contains questions (and their answers) that describe the risk
   * but do not fit in any of the existing fields.
   * Typically, it will be used to collect carrier specific questions that
   * have not been mapped yet.
   *
   * @generated from field: map<string, agentero.risk.Answer> questions = 17;
   */
  questions: { [key: string]: Answer } = {};

  constructor(data?: PartialMessage<HomeRisk>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.risk.HomeRisk";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "birth_date", kind: "message", T: Timestamp },
    { no: 11, name: "marital_status", kind: "enum", T: proto3.getEnumType(MaritalStatus) },
    { no: 12, name: "gender", kind: "enum", T: proto3.getEnumType(Gender) },
    { no: 4, name: "street", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "street_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "county", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "unit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "additional_fields", kind: "message", T: AdditionalFields },
    { no: 13, name: "current_carrier", kind: "enum", T: proto3.getEnumType(CurrentCarrier) },
    { no: 14, name: "time_with_current_carrier", kind: "message", T: DurationPeriod },
    { no: 15, name: "effective_date", kind: "message", T: Timestamp },
    { no: 17, name: "questions", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Answer} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeRisk {
    return new HomeRisk().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeRisk {
    return new HomeRisk().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeRisk {
    return new HomeRisk().fromJsonString(jsonString, options);
  }

  static equals(a: HomeRisk | PlainMessage<HomeRisk> | undefined, b: HomeRisk | PlainMessage<HomeRisk> | undefined): boolean {
    return proto3.util.equals(HomeRisk, a, b);
  }
}

/**
 * AdditionalFields contains fields that are not part of the core risk
 * These fields are used for the specific carrier questions
 *
 * @generated from message agentero.risk.AdditionalFields
 */
export class AdditionalFields extends Message<AdditionalFields> {
  /**
   * @generated from field: float dwelling_coverage = 1;
   */
  dwellingCoverage = 0;

  /**
   * @generated from field: string deductible = 2;
   */
  deductible = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: bool previous_cancellations = 4;
   */
  previousCancellations = false;

  /**
   * @generated from field: agentero.risk.Property property = 5;
   */
  property?: Property;

  /**
   * @generated from field: float personal_liability = 6;
   */
  personalLiability = 0;

  /**
   * @generated from field: string phone_number = 7;
   */
  phoneNumber = "";

  /**
   * @generated from field: optional float loss_of_use = 8;
   */
  lossOfUse?: number;

  constructor(data?: PartialMessage<AdditionalFields>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.risk.AdditionalFields";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dwelling_coverage", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "deductible", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "previous_cancellations", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "property", kind: "message", T: Property },
    { no: 6, name: "personal_liability", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "loss_of_use", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdditionalFields {
    return new AdditionalFields().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdditionalFields {
    return new AdditionalFields().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdditionalFields {
    return new AdditionalFields().fromJsonString(jsonString, options);
  }

  static equals(a: AdditionalFields | PlainMessage<AdditionalFields> | undefined, b: AdditionalFields | PlainMessage<AdditionalFields> | undefined): boolean {
    return proto3.util.equals(AdditionalFields, a, b);
  }
}

/**
 * @generated from message agentero.risk.Property
 */
export class Property extends Message<Property> {
  /**
   * @generated from field: int32 square_ft = 1;
   */
  squareFt = 0;

  /**
   * @generated from field: agentero.risk.DwellingType dwelling_type = 2;
   */
  dwellingType = DwellingType.UNSPECIFIED;

  /**
   * @generated from field: agentero.risk.ResidenceUse residence_use = 3;
   */
  residenceUse = ResidenceUse.UNSPECIFIED;

  /**
   * @generated from field: bool swimming_pool = 4;
   */
  swimmingPool = false;

  /**
   * @generated from field: bool swimming_pool_fence = 5;
   */
  swimmingPoolFence = false;

  /**
   * @generated from field: bool trampoline = 6;
   */
  trampoline = false;

  /**
   * @generated from field: bool mortgage = 7;
   */
  mortgage = false;

  /**
   * @generated from field: bool closing = 8;
   */
  closing = false;

  /**
   * @generated from field: bool currently_insured = 9;
   */
  currentlyInsured = false;

  /**
   * @generated from field: optional agentero.risk.RoofShape roof_shape = 10;
   */
  roofShape?: RoofShape;

  /**
   * @generated from field: optional int32 roof_age = 11 [deprecated = true];
   * @deprecated
   */
  roofAge?: number;

  /**
   * @generated from field: optional int32 year_built = 12;
   */
  yearBuilt?: number;

  /**
   * @generated from field: optional bool mobile_home = 13;
   */
  mobileHome?: boolean;

  /**
   * @generated from field: optional int32 roof_year = 14;
   */
  roofYear?: number;

  /**
   * @generated from field: agentero.risk.Stories stories = 15;
   */
  stories = Stories.UNSPECIFIED;

  /**
   * @generated from field: agentero.risk.BuildingMaterial building_material = 16;
   */
  buildingMaterial = BuildingMaterial._UNSPECIFIED;

  /**
   * @generated from field: agentero.risk.RoofMaterial roof_material = 17;
   */
  roofMaterial = RoofMaterial.UNSPECIFIED;

  /**
   * @generated from field: int32 bathrooms = 18;
   */
  bathrooms = 0;

  /**
   * @generated from field: google.protobuf.Timestamp purchase_date = 19;
   */
  purchaseDate?: Timestamp;

  /**
   * @generated from field: int32 years_at_residence = 20;
   */
  yearsAtResidence = 0;

  /**
   * @generated from field: agentero.risk.HomeStyle home_style = 21;
   */
  homeStyle = HomeStyle.UNSPECIFIED;

  /**
   * @generated from field: agentero.risk.SwimmingPoolType swimming_pool_type = 22;
   */
  swimmingPoolType = SwimmingPoolType.UNSPECIFIED;

  /**
   * @generated from field: agentero.risk.SidingType siding_type = 23;
   */
  sidingType = SidingType.UNSPECIFIED;

  /**
   * @generated from field: optional int64 distance_to_hydrant = 24;
   */
  distanceToHydrant?: bigint;

  /**
   * @generated from field: agentero.risk.FoundationType foundation_type = 25;
   */
  foundationType = FoundationType.UNSPECIFIED;

  constructor(data?: PartialMessage<Property>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.risk.Property";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "square_ft", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "dwelling_type", kind: "enum", T: proto3.getEnumType(DwellingType) },
    { no: 3, name: "residence_use", kind: "enum", T: proto3.getEnumType(ResidenceUse) },
    { no: 4, name: "swimming_pool", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "swimming_pool_fence", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "trampoline", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "mortgage", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "closing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "currently_insured", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "roof_shape", kind: "enum", T: proto3.getEnumType(RoofShape), opt: true },
    { no: 11, name: "roof_age", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "year_built", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 13, name: "mobile_home", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 14, name: "roof_year", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 15, name: "stories", kind: "enum", T: proto3.getEnumType(Stories) },
    { no: 16, name: "building_material", kind: "enum", T: proto3.getEnumType(BuildingMaterial) },
    { no: 17, name: "roof_material", kind: "enum", T: proto3.getEnumType(RoofMaterial) },
    { no: 18, name: "bathrooms", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 19, name: "purchase_date", kind: "message", T: Timestamp },
    { no: 20, name: "years_at_residence", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 21, name: "home_style", kind: "enum", T: proto3.getEnumType(HomeStyle) },
    { no: 22, name: "swimming_pool_type", kind: "enum", T: proto3.getEnumType(SwimmingPoolType) },
    { no: 23, name: "siding_type", kind: "enum", T: proto3.getEnumType(SidingType) },
    { no: 24, name: "distance_to_hydrant", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 25, name: "foundation_type", kind: "enum", T: proto3.getEnumType(FoundationType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Property {
    return new Property().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Property {
    return new Property().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Property {
    return new Property().fromJsonString(jsonString, options);
  }

  static equals(a: Property | PlainMessage<Property> | undefined, b: Property | PlainMessage<Property> | undefined): boolean {
    return proto3.util.equals(Property, a, b);
  }
}

/**
 * @generated from message agentero.risk.AutoRisk
 */
export class AutoRisk extends Message<AutoRisk> {
  /**
   * Risk Address
   *
   * @generated from field: string street = 1;
   */
  street = "";

  /**
   * @generated from field: string street_number = 12;
   */
  streetNumber = "";

  /**
   * @generated from field: string zip_code = 2;
   */
  zipCode = "";

  /**
   * @generated from field: string city = 3;
   */
  city = "";

  /**
   * @generated from field: string state = 4;
   */
  state = "";

  /**
   * Apartment number / unit number.
   * Part of the risk address
   *
   * @generated from field: string unit = 5;
   */
  unit = "";

  /**
   * @generated from field: string county = 21;
   */
  county = "";

  /**
   * @generated from field: agentero.risk.Driver primary_driver = 6;
   */
  primaryDriver?: Driver;

  /**
   * @generated from field: repeated agentero.risk.Driver other_drivers = 7;
   */
  otherDrivers: Driver[] = [];

  /**
   * @generated from field: repeated agentero.risk.Vehicle vehicles = 8;
   */
  vehicles: Vehicle[] = [];

  /**
   * @generated from field: agentero.risk.DriverCoverages driver_coverages = 9;
   */
  driverCoverages?: DriverCoverages;

  /**
   * @generated from field: bool proof_of_prior_auto_insurance = 10 [deprecated = true];
   * @deprecated
   */
  proofOfPriorAutoInsurance = false;

  /**
   * @generated from field: agentero.risk.CurrentCarrier current_carrier = 11;
   */
  currentCarrier = CurrentCarrier.UNSPECIFIED;

  /**
   * @generated from field: agentero.risk.DurationPeriod time_with_current_carrier = 13;
   */
  timeWithCurrentCarrier?: DurationPeriod;

  /**
   * This map contains questions (and their answers) that describe the risk
   * but do not fit in any of the existing fields.
   * Typically, it will be used to collect carrier specific questions that
   * have not been mapped yet.
   *
   * @generated from field: map<string, agentero.risk.Answer> questions = 14;
   */
  questions: { [key: string]: Answer } = {};

  /**
   * @generated from field: agentero.risk.DurationPeriod time_at_residence = 15;
   */
  timeAtResidence?: DurationPeriod;

  /**
   * @generated from field: google.protobuf.Timestamp current_carrier_expiration_date = 16;
   */
  currentCarrierExpirationDate?: Timestamp;

  /**
   * @generated from field: optional string other_carrier = 17;
   */
  otherCarrier?: string;

  /**
   * @generated from field: google.protobuf.Timestamp effective_date = 18;
   */
  effectiveDate?: Timestamp;

  /**
   * @generated from field: string current_policy_number = 19;
   */
  currentPolicyNumber = "";

  /**
   * @generated from field: agentero.risk.DriverCoverages current_driver_coverages = 20;
   */
  currentDriverCoverages?: DriverCoverages;

  constructor(data?: PartialMessage<AutoRisk>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.risk.AutoRisk";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "street", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "street_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "unit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "county", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "primary_driver", kind: "message", T: Driver },
    { no: 7, name: "other_drivers", kind: "message", T: Driver, repeated: true },
    { no: 8, name: "vehicles", kind: "message", T: Vehicle, repeated: true },
    { no: 9, name: "driver_coverages", kind: "message", T: DriverCoverages },
    { no: 10, name: "proof_of_prior_auto_insurance", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "current_carrier", kind: "enum", T: proto3.getEnumType(CurrentCarrier) },
    { no: 13, name: "time_with_current_carrier", kind: "message", T: DurationPeriod },
    { no: 14, name: "questions", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Answer} },
    { no: 15, name: "time_at_residence", kind: "message", T: DurationPeriod },
    { no: 16, name: "current_carrier_expiration_date", kind: "message", T: Timestamp },
    { no: 17, name: "other_carrier", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "effective_date", kind: "message", T: Timestamp },
    { no: 19, name: "current_policy_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "current_driver_coverages", kind: "message", T: DriverCoverages },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutoRisk {
    return new AutoRisk().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutoRisk {
    return new AutoRisk().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutoRisk {
    return new AutoRisk().fromJsonString(jsonString, options);
  }

  static equals(a: AutoRisk | PlainMessage<AutoRisk> | undefined, b: AutoRisk | PlainMessage<AutoRisk> | undefined): boolean {
    return proto3.util.equals(AutoRisk, a, b);
  }
}

/**
 * @generated from message agentero.risk.DurationPeriod
 */
export class DurationPeriod extends Message<DurationPeriod> {
  /**
   * @generated from field: int32 years = 1;
   */
  years = 0;

  /**
   * @generated from field: int32 months = 2;
   */
  months = 0;

  constructor(data?: PartialMessage<DurationPeriod>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.risk.DurationPeriod";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "years", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "months", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DurationPeriod {
    return new DurationPeriod().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DurationPeriod {
    return new DurationPeriod().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DurationPeriod {
    return new DurationPeriod().fromJsonString(jsonString, options);
  }

  static equals(a: DurationPeriod | PlainMessage<DurationPeriod> | undefined, b: DurationPeriod | PlainMessage<DurationPeriod> | undefined): boolean {
    return proto3.util.equals(DurationPeriod, a, b);
  }
}

/**
 * DriverCoverages represents the coverages for a driver.
 *
 * @generated from message agentero.risk.DriverCoverages
 */
export class DriverCoverages extends Message<DriverCoverages> {
  /**
   * @generated from field: string bodily_injury = 1;
   */
  bodilyInjury = "";

  /**
   * @generated from field: string property_damage = 2;
   */
  propertyDamage = "";

  /**
   * @generated from field: string uninsured_motorist_bodily_injury = 3;
   */
  uninsuredMotoristBodilyInjury = "";

  /**
   * @generated from field: string underinsured_motorist_bodily_injury = 4;
   */
  underinsuredMotoristBodilyInjury = "";

  /**
   * @generated from field: string medical_payments = 5;
   */
  medicalPayments = "";

  /**
   * @generated from field: string uninsured_motorist_property_damage = 6;
   */
  uninsuredMotoristPropertyDamage = "";

  /**
   * @generated from field: string basic_personal_injury_protection = 7;
   */
  basicPersonalInjuryProtection = "";

  /**
   * @generated from field: string accidental_death = 8;
   */
  accidentalDeath = "";

  /**
   * @generated from field: string additional_personal_injury_protection = 9;
   */
  additionalPersonalInjuryProtection = "";

  /**
   * @generated from field: string work_loss_benefit = 10;
   */
  workLossBenefit = "";

  /**
   * @generated from field: string personal_injury_protection_attendant_care_option = 11;
   */
  personalInjuryProtectionAttendantCareOption = "";

  /**
   * @generated from field: string limited_property_damage_liability = 12;
   */
  limitedPropertyDamageLiability = "";

  /**
   * @generated from field: string extraordinary_medical_benefits = 13;
   */
  extraordinaryMedicalBenefits = "";

  /**
   * @generated from field: string funeral_benefits = 14;
   */
  funeralBenefits = "";

  /**
   * @generated from field: string medical_benefits = 15;
   */
  medicalBenefits = "";

  constructor(data?: PartialMessage<DriverCoverages>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.risk.DriverCoverages";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bodily_injury", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "property_damage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "uninsured_motorist_bodily_injury", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "underinsured_motorist_bodily_injury", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "medical_payments", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "uninsured_motorist_property_damage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "basic_personal_injury_protection", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "accidental_death", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "additional_personal_injury_protection", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "work_loss_benefit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "personal_injury_protection_attendant_care_option", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "limited_property_damage_liability", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "extraordinary_medical_benefits", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "funeral_benefits", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "medical_benefits", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DriverCoverages {
    return new DriverCoverages().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DriverCoverages {
    return new DriverCoverages().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DriverCoverages {
    return new DriverCoverages().fromJsonString(jsonString, options);
  }

  static equals(a: DriverCoverages | PlainMessage<DriverCoverages> | undefined, b: DriverCoverages | PlainMessage<DriverCoverages> | undefined): boolean {
    return proto3.util.equals(DriverCoverages, a, b);
  }
}

/**
 * VehicleCoverage represents the coverages for a vehicle.
 *
 * @generated from message agentero.risk.VehicleCoverage
 */
export class VehicleCoverage extends Message<VehicleCoverage> {
  /**
   * @generated from field: string comprehensive = 1;
   */
  comprehensive = "";

  /**
   * @generated from field: string collision = 2;
   */
  collision = "";

  /**
   * @generated from field: string rental = 3;
   */
  rental = "";

  /**
   * @generated from field: string roadside = 4;
   */
  roadside = "";

  /**
   * @generated from field: string towing_and_labor = 5;
   */
  towingAndLabor = "";

  /**
   * @generated from field: string underinsured_motorist_property_damage = 6;
   */
  underinsuredMotoristPropertyDamage = "";

  constructor(data?: PartialMessage<VehicleCoverage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.risk.VehicleCoverage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "comprehensive", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "collision", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "rental", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "roadside", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "towing_and_labor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "underinsured_motorist_property_damage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VehicleCoverage {
    return new VehicleCoverage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VehicleCoverage {
    return new VehicleCoverage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VehicleCoverage {
    return new VehicleCoverage().fromJsonString(jsonString, options);
  }

  static equals(a: VehicleCoverage | PlainMessage<VehicleCoverage> | undefined, b: VehicleCoverage | PlainMessage<VehicleCoverage> | undefined): boolean {
    return proto3.util.equals(VehicleCoverage, a, b);
  }
}

/**
 * Driver represents the driver of the vehicle.
 *
 * @generated from message agentero.risk.Driver
 */
export class Driver extends Message<Driver> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 2;
   */
  lastName = "";

  /**
   * @generated from field: google.protobuf.Timestamp birth_date = 3;
   */
  birthDate?: Timestamp;

  /**
   * @generated from field: agentero.risk.MaritalStatus marital_status = 4;
   */
  maritalStatus = MaritalStatus.UNSPECIFIED;

  /**
   * @generated from field: agentero.risk.Gender gender = 5;
   */
  gender = Gender.UNSPECIFIED;

  /**
   * @generated from field: agentero.risk.HighestEducationLevel highest_education_level = 6;
   */
  highestEducationLevel = HighestEducationLevel.UNSPECIFIED;

  /**
   * @generated from field: agentero.risk.RelationshipWithInsured relationship_with_insured = 7;
   */
  relationshipWithInsured = RelationshipWithInsured.UNSPECIFIED;

  /**
   * @generated from field: agentero.risk.DriverLicenseStatus license_status = 9;
   */
  licenseStatus = DriverLicenseStatus.UNSPECIFIED;

  /**
   * @generated from field: agentero.risk.EmploymentStatus employment_status = 10;
   */
  employmentStatus = EmploymentStatus.UNSPECIFIED;

  /**
   * @generated from field: agentero.risk.Occupation occupation = 11;
   */
  occupation = Occupation.UNSPECIFIED;

  /**
   * @generated from field: optional bool is_homeowner = 12;
   */
  isHomeowner?: boolean;

  /**
   * @generated from field: map<string, agentero.risk.Answer> questions = 13;
   */
  questions: { [key: string]: Answer } = {};

  /**
   * @generated from field: string license_number = 14;
   */
  licenseNumber = "";

  /**
   * @generated from field: string license_state = 15;
   */
  licenseState = "";

  /**
   * @generated from field: repeated agentero.risk.Violation violations = 16;
   */
  violations: Violation[] = [];

  constructor(data?: PartialMessage<Driver>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.risk.Driver";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "birth_date", kind: "message", T: Timestamp },
    { no: 4, name: "marital_status", kind: "enum", T: proto3.getEnumType(MaritalStatus) },
    { no: 5, name: "gender", kind: "enum", T: proto3.getEnumType(Gender) },
    { no: 6, name: "highest_education_level", kind: "enum", T: proto3.getEnumType(HighestEducationLevel) },
    { no: 7, name: "relationship_with_insured", kind: "enum", T: proto3.getEnumType(RelationshipWithInsured) },
    { no: 9, name: "license_status", kind: "enum", T: proto3.getEnumType(DriverLicenseStatus) },
    { no: 10, name: "employment_status", kind: "enum", T: proto3.getEnumType(EmploymentStatus) },
    { no: 11, name: "occupation", kind: "enum", T: proto3.getEnumType(Occupation) },
    { no: 12, name: "is_homeowner", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 13, name: "questions", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Answer} },
    { no: 14, name: "license_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "license_state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "violations", kind: "message", T: Violation, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Driver {
    return new Driver().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Driver {
    return new Driver().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Driver {
    return new Driver().fromJsonString(jsonString, options);
  }

  static equals(a: Driver | PlainMessage<Driver> | undefined, b: Driver | PlainMessage<Driver> | undefined): boolean {
    return proto3.util.equals(Driver, a, b);
  }
}

/**
 * Vehicle represents a vehicle that is owned by the driver.
 *
 * @generated from message agentero.risk.Vehicle
 */
export class Vehicle extends Message<Vehicle> {
  /**
   * @generated from field: string vin = 1;
   */
  vin = "";

  /**
   * @generated from field: string year = 2;
   */
  year = "";

  /**
   * @generated from field: string make = 3;
   */
  make = "";

  /**
   * @generated from field: string model = 4;
   */
  model = "";

  /**
   * @generated from field: agentero.risk.VehicleOwnership vehicle_ownership = 5;
   */
  vehicleOwnership = VehicleOwnership.UNSPECIFIED;

  /**
   * @generated from field: agentero.risk.VehicleCoverage coverage = 6;
   */
  coverage?: VehicleCoverage;

  /**
   * @generated from field: string annual_mileage = 7;
   */
  annualMileage = "";

  /**
   * @generated from field: agentero.risk.VehicleUsage vehicle_usage = 8;
   */
  vehicleUsage = VehicleUsage.UNSPECIFIED;

  /**
   * @generated from field: map<string, agentero.risk.Answer> questions = 9;
   */
  questions: { [key: string]: Answer } = {};

  /**
   * @generated from field: bool ridesharing = 10;
   */
  ridesharing = false;

  constructor(data?: PartialMessage<Vehicle>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.risk.Vehicle";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "year", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "make", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "vehicle_ownership", kind: "enum", T: proto3.getEnumType(VehicleOwnership) },
    { no: 6, name: "coverage", kind: "message", T: VehicleCoverage },
    { no: 7, name: "annual_mileage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "vehicle_usage", kind: "enum", T: proto3.getEnumType(VehicleUsage) },
    { no: 9, name: "questions", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Answer} },
    { no: 10, name: "ridesharing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Vehicle {
    return new Vehicle().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Vehicle {
    return new Vehicle().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Vehicle {
    return new Vehicle().fromJsonString(jsonString, options);
  }

  static equals(a: Vehicle | PlainMessage<Vehicle> | undefined, b: Vehicle | PlainMessage<Vehicle> | undefined): boolean {
    return proto3.util.equals(Vehicle, a, b);
  }
}

/**
 * Answer is a union of all possible answer types.
 *
 * @generated from message agentero.risk.Answer
 */
export class Answer extends Message<Answer> {
  /**
   * @generated from oneof agentero.risk.Answer.kind
   */
  kind: {
    /**
     * @generated from field: int32 int_value = 1;
     */
    value: number;
    case: "intValue";
  } | {
    /**
     * @generated from field: string string_value = 2;
     */
    value: string;
    case: "stringValue";
  } | {
    /**
     * @generated from field: bool bool_value = 3;
     */
    value: boolean;
    case: "boolValue";
  } | {
    /**
     * @generated from field: google.protobuf.Timestamp timestamp_value = 4;
     */
    value: Timestamp;
    case: "timestampValue";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Answer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.risk.Answer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "int_value", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "kind" },
    { no: 2, name: "string_value", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "kind" },
    { no: 3, name: "bool_value", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "kind" },
    { no: 4, name: "timestamp_value", kind: "message", T: Timestamp, oneof: "kind" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Answer {
    return new Answer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Answer {
    return new Answer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Answer {
    return new Answer().fromJsonString(jsonString, options);
  }

  static equals(a: Answer | PlainMessage<Answer> | undefined, b: Answer | PlainMessage<Answer> | undefined): boolean {
    return proto3.util.equals(Answer, a, b);
  }
}

/**
 * @generated from message agentero.risk.Violation
 */
export class Violation extends Message<Violation> {
  /**
   * @generated from field: agentero.risk.ViolationType violation_type = 1;
   */
  violationType = ViolationType.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp violation_date = 2;
   */
  violationDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp conviction_date = 3;
   */
  convictionDate?: Timestamp;

  constructor(data?: PartialMessage<Violation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.risk.Violation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "violation_type", kind: "enum", T: proto3.getEnumType(ViolationType) },
    { no: 2, name: "violation_date", kind: "message", T: Timestamp },
    { no: 3, name: "conviction_date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Violation {
    return new Violation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Violation {
    return new Violation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Violation {
    return new Violation().fromJsonString(jsonString, options);
  }

  static equals(a: Violation | PlainMessage<Violation> | undefined, b: Violation | PlainMessage<Violation> | undefined): boolean {
    return proto3.util.equals(Violation, a, b);
  }
}

