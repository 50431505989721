import React, { RefObject, forwardRef, useEffect, useRef } from 'react';

import styled from '@emotion/styled';

import { useCombinedRefs } from '@agentero/hooks';
import { ITheme } from '@agentero/styles/emotion';

import { Label } from './Label';
import { FieldStatus } from './fieldStatus';

const defaultVariant = (theme: ITheme) => `
	+ span {
		border-color: ${theme.colors.gray.lighten40};
	}

	&:hover {
		+ span {
			background-color: ${theme.colors.gray.lighten95};
			box-shadow: 0 0 0 4px ${theme.colors.gray.lighten95};
		}
	}

	&:focus,
	&:active {
		+ span {
			background-color: ${theme.colors.gray.lighten90};
			box-shadow: 0 0 0 4px ${theme.colors.gray.lighten90};
		}
	}

	&:indeterminate,
	&:checked {
		+ span {
			border-color: ${theme.colors.primary.base};
			background-color: ${theme.colors.primary.base};
		}

		&:hover {
			+ span {
				background-color: ${theme.colors.primary.base};
				box-shadow: 0 0 0 4px ${theme.colors.primary.lighten80};
			}
		}

		&:focus,
		&:active {
			+ span {
				background-color: ${theme.colors.primary.base};
				box-shadow: 0 0 0 4px ${theme.colors.primary.lighten80};
			}
		}
	}

	&:disabled {
		+ span {
			border-color: ${theme.colors.gray.lighten80};
		}

		&:indeterminate,
		&:checked {
			+ span {
				border-color: ${theme.colors.primary.lighten50};
				background-color: ${theme.colors.primary.lighten50};
			}
		}
	}
`;
const successVariant = (theme: ITheme) => `
	+ span {
		border-color: ${theme.colors.primary.base};
	}

	&:hover {
		+ span {
			background-color: ${theme.colors.primary.lighten80};
			box-shadow: 0 0 0 4px ${theme.colors.primary.lighten80};
		}
	}

	&:focus,
	&:active {
		+ span {
			background-color: ${theme.colors.primary.lighten80};
			box-shadow: 0 0 0 4px ${theme.colors.primary.lighten80};
		}
	}

	&:indeterminate,
	&:checked {
		+ span {
			border-color: ${theme.colors.primary.base};
			background-color: ${theme.colors.primary.base};
		}

		&:hover {
			+ span {
				background-color: ${theme.colors.primary.base};
				box-shadow: 0 0 0 4px ${theme.colors.primary.lighten80};
			}
		}

		&:focus,
		&:active {
			+ span {
				background-color: ${theme.colors.primary.base};
				box-shadow: 0 0 0 4px ${theme.colors.primary.lighten80};
			}
		}
	}

	&:disabled {
		+ span {
			border-color: ${theme.colors.primary.lighten50};
		}

		&:indeterminate,
		&:checked {
			+ span {
				border-color: ${theme.colors.primary.lighten50};
				background-color: ${theme.colors.primary.lighten50};
			}
		}
	}
`;
const errorVariant = (theme: ITheme) => `
	+ span {
		border-color: ${theme.colors.status.error.base};
	}

	&:hover {
		+ span {
			background-color: ${theme.colors.status.error.lighten80};
			box-shadow: 0 0 0 4px ${theme.colors.status.error.lighten80};
		}
	}

	&:focus,
	&:active {
		+ span {
			background-color: ${theme.colors.status.error.lighten40};
			box-shadow: 0 0 0 4px ${theme.colors.status.error.lighten40};
		}
	}

	&:indeterminate,
	&:checked {
		+ span {
			border-color: ${theme.colors.status.error.base};
			background-color: ${theme.colors.status.error.base};
		}

		&:hover {
			+ span {
				background-color: ${theme.colors.status.error.base};
				box-shadow: 0 0 0 4px ${theme.colors.status.error.lighten80};
			}
		}

		&:focus,
		&:active {
			+ span {
				background-color: ${theme.colors.status.error.base};
				box-shadow: 0 0 0 4px ${theme.colors.status.error.lighten40};
			}
		}
	}

	&:disabled {
		+ span {
			border-color: ${theme.colors.status.error.lighten40};
		}

		&:indeterminate,
		&:checked {
			+ span {
				border-color: ${theme.colors.status.error.lighten40};
				background-color: ${theme.colors.status.error.lighten40};
			}
		}
	}
`;
const warningVariant = (theme: ITheme) => `
	+ span {
		border-color: ${theme.colors.status.warning.base};
	}

	&:hover {
		+ span {
			background-color: ${theme.colors.status.warning.lighten80};
			box-shadow: 0 0 0 4px ${theme.colors.status.warning.lighten80};
		}
	}

	&:focus,
	&:active {
		+ span {
			background-color: ${theme.colors.status.warning.lighten40};
			box-shadow: 0 0 0 4px ${theme.colors.status.warning.lighten40};
		}
	}

	&:indeterminate,
	&:checked {
		+ span {
			border-color: ${theme.colors.status.warning.base};
			background-color: ${theme.colors.status.warning.base};
		}

		&:hover {
			+ span {
				background-color: ${theme.colors.status.warning.base};
				box-shadow: 0 0 0 4px ${theme.colors.status.warning.lighten80};
			}
		}

		&:focus,
		&:active {
			+ span {
				background-color: ${theme.colors.status.warning.base};
				box-shadow: 0 0 0 4px ${theme.colors.status.warning.lighten40};
			}
		}
	}

	&:disabled {
		+ span {
			border-color: ${theme.colors.status.warning.lighten40};
		}

		&:indeterminate,
		&:checked {
			+ span {
				border-color: ${theme.colors.status.warning.lighten40};
				background-color: ${theme.colors.status.warning.lighten40};
			}
		}
	}
`;
const infoVariant = (theme: ITheme) => `
	+ span {
		border-color: ${theme.colors.status.info.base};
	}

	&:hover {
		+ span {
			background-color: ${theme.colors.status.info.lighten80};
			box-shadow: 0 0 0 4px ${theme.colors.status.info.lighten80};
		}
	}

	&:focus,
	&:active {
		+ span {
			background-color: ${theme.colors.status.info.lighten40};
			box-shadow: 0 0 0 4px ${theme.colors.status.info.lighten40};
		}
	}

	&:indeterminate,
	&:checked {
		+ span {
			border-color: ${theme.colors.status.info.base};
			background-color: ${theme.colors.status.info.base};
		}

		&:hover {
			+ span {
				background-color: ${theme.colors.status.info.base};
				box-shadow: 0 0 0 4px ${theme.colors.status.info.lighten80};
			}
		}

		&:focus,
		&:active {
			+ span {
				background-color: ${theme.colors.status.info.base};
				box-shadow: 0 0 0 4px ${theme.colors.status.info.lighten40};
			}
		}
	}

	&:disabled {
		+ span {
			border-color: ${theme.colors.status.info.lighten40};
		}

		&:indeterminate,
		&:checked {
			+ span {
				border-color: ${theme.colors.status.info.lighten40};
				background-color: ${theme.colors.status.info.lighten40};
			}
		}
	}
`;

const statusVariants: { [key in FieldStatus]: (theme: ITheme) => string } = {
	[FieldStatus.None]: defaultVariant,
	[FieldStatus.Success]: successVariant,
	[FieldStatus.Error]: errorVariant,
	[FieldStatus.Warning]: warningVariant,
	[FieldStatus.Info]: infoVariant
};

type GetStatusVariant = InputCheckbox & { theme: ITheme };

const getStatusVariant = ({ status = FieldStatus.None, theme }: GetStatusVariant) => {
	const variant = statusVariants[status];
	return variant(theme);
};

type InputCheckbox = {
	status?: FieldStatus;
	indeterminate?: boolean;
};

type CheckboxProps = React.DetailedHTMLProps<
	React.InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
> &
	InputCheckbox;

// @ts-ignore
const CheckboxContainer = styled.span<{ status?: FieldStatus; disabled?: boolean }>`
	position: relative;
	display: flex;
	align-items: start;
	${({ disabled }) => (disabled ? 'pointer-events: none;' : '')}

	span {
		position: relative;
		pointer-events: none;
		display: inline-block;
		width: 16px;
		min-width: 16px;
		height: 16px;
		margin: 8px 0;
		vertical-align: middle;
		border-width: 1px;
		border-style: solid;
		box-sizing: border-box;
		transition: 0s;
		transition-delay: 0.15s;
		background: ${({ theme }) => theme.colors.white};

		&:before {
			content: '';
			position: absolute;
			top: 6px;
			left: 2px;
			border: 1px solid ${({ theme }) => theme.colors.white};
			width: 8px;
			transition: 0.15s;
			transition-delay: 0s;
			clip-path: inset(0 100% 0 0);
			box-sizing: content-box;
		}

		&:after {
			content: '';
			position: absolute;
			top: 2.5px;
			left: 2px;
			display: block;
			height: 4px;
			width: 8px;
			border-left: 2px solid ${({ theme }) => theme.colors.white};
			border-bottom: 2px solid ${({ theme }) => theme.colors.white};
			transform: rotate(-45deg);
			transition: 0.15s;
			transition-delay: 0s;
			clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
			box-sizing: content-box;
		}

		+ label {
			flex: 1;
			min-width: 0;
			margin-left: 8px;
			margin-bottom: 0;
			padding-top: 4px;
			height: auto;
			min-height: 32px;
			${({ theme }) => theme.fontWeight.regular};
			color: ${({ theme }) => theme.colors.gray.base};

			&:empty {
				margin-left: 0;
			}
		}
	}

	input[type='checkbox'] {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 32px;
		opacity: 0;
		cursor: pointer;
		margin-top: 0;

		&:hover {
			+ span {
				transition: 0.15s;
			}
		}

		&:focus,
		&:active {
			+ span {
				transition: 0.15s;
			}
		}

		&:checked {
			+ span {
				transition-delay: 0s;

				&:after {
					transform: rotate(-45deg);
					transition-delay: 0.1s;
					clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
				}
			}
		}

		&:indeterminate {
			+ span {
				transition-delay: 0s;

				&:before {
					transition-delay: 0.1s;
					clip-path: inset(0 0 0 0);
				}
			}
		}

		&:disabled {
			cursor: default;

			+ span {
				transition-delay: 0s;

				+ label {
					color: ${({ theme }) => theme.colors.gray.lighten40};
				}
			}
		}

		${getStatusVariant}
	}
`;

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
	({ id, className, children, indeterminate, status, disabled, ...props }, ref) => {
		const innerRef = useRef<HTMLInputElement>(null);
		const combinedRef = useCombinedRefs(ref, innerRef);

		useEffect(() => {
			if (innerRef.current && indeterminate !== undefined) {
				innerRef.current.indeterminate = indeterminate;
			}
		}, [indeterminate, innerRef.current?.indeterminate]);

		return (
			<CheckboxContainer status={status} disabled={disabled}>
				<input
					id={id}
					type="checkbox"
					disabled={disabled}
					{...props}
					ref={combinedRef as unknown as RefObject<HTMLInputElement>}
				/>
				<span className={className} />
				<Label htmlFor={id}>{children}</Label>
			</CheckboxContainer>
		);
	}
);
