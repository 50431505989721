import styled from '@emotion/styled';

import { ITheme } from '@agentero/styles/emotion';

import { DragStatus } from '../DragStatus';

export const defaultVariant = () => ``;

export const successVariant = (theme: ITheme) => `
	background: ${theme.colors.primary.lighten80};

	svg {
		path {
			fill: ${theme.colors.primary.base};
		}
	}
`;

export const errorVariant = (theme: ITheme) => `
	background: ${theme.colors.status.error.lighten40};

	svg {
		path {
			fill: ${theme.colors.status.error.base};
		}
	}
`;

export const statusVariants: { [key: number]: (theme: ITheme) => string } = {
	[DragStatus.None]: defaultVariant,
	[DragStatus.ValidFiles]: successVariant,
	[DragStatus.InvalidFiles]: errorVariant
};

type DroppableIconProps = {
	dragStatus: DragStatus;
};

type GetStatusVariant = DroppableIconProps & { theme: ITheme };

export const getStatusVariant = ({ dragStatus, theme }: GetStatusVariant) => {
	const variant = statusVariants[dragStatus];
	return variant(theme);
};

export const DroppableIcon = styled.div<DroppableIconProps>`
	display: inline-block;
	border-radius: 50%;
	padding: 8px;
	background: ${({ theme }) => theme.colors.gray.lighten90};
	margin-bottom: 24px;

	svg {
		vertical-align: top;

		path {
			fill: ${({ theme }) => theme.colors.gray.lighten40};
		}
	}

	${getStatusVariant}
`;
