import styled from '@emotion/styled';

import { ITheme } from '@agentero/styles/emotion';

import { FieldStatus } from '../../fieldStatus';
import { DragStatus } from '../DragStatus';

const dragStatusDefaultVariant = () => ``;

const dragStatusSuccessVariant = (theme: ITheme) => `
	border-color: ${theme.colors.primary.base};
	background: ${theme.colors.primary.lighten80};
`;

const dragStatusErrorVariant = (theme: ITheme) => `
	border-color: ${theme.colors.status.error.base};
	background: ${theme.colors.status.error.lighten80};
`;

const dragStatusVariants: { [key: number]: (theme: ITheme) => string } = {
	[DragStatus.None]: dragStatusDefaultVariant,
	[DragStatus.ValidFiles]: dragStatusSuccessVariant,
	[DragStatus.InvalidFiles]: dragStatusErrorVariant
};

type DroppableAreaProps = {
	dragStatus: DragStatus;
	status: FieldStatus;
};

type GetVariant = DroppableAreaProps & { theme: ITheme };

export const getDragStatusVariant = ({ dragStatus, theme }: GetVariant) => {
	const variant = dragStatusVariants[dragStatus];
	return variant(theme);
};

const statusDefaultVariant = () => ``;

const statusSuccessVariant = (theme: ITheme) => `
	border-color: ${theme.colors.primary.base};
`;

const statusInfoVariant = (theme: ITheme) => `
	border-color: ${theme.colors.status.info.base};
`;

const statusWarningVariant = (theme: ITheme) => `
	border-color: ${theme.colors.status.warning.base};
`;

const statusErrorVariant = (theme: ITheme) => `
	border-color: ${theme.colors.status.error.base};
`;

const statusVariants: { [key in FieldStatus]: (theme: ITheme) => string } = {
	[FieldStatus.None]: statusDefaultVariant,
	[FieldStatus.Success]: statusSuccessVariant,
	[FieldStatus.Info]: statusInfoVariant,
	[FieldStatus.Warning]: statusWarningVariant,
	[FieldStatus.Error]: statusErrorVariant
};

const getStatusVariant = ({ status, theme }: GetVariant) => {
	const variant = statusVariants[status];
	return variant(theme);
};

export const DroppableArea = styled.div<DroppableAreaProps>`
	position: relative;
	padding: 24px;
	border: dashed 1px ${({ theme }) => theme.colors.gray.lighten40};
	background: ${({ theme }) => theme.colors.gray.lighten95};
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	min-height: var(--min-height);

	${getStatusVariant}
	${getDragStatusVariant}
`;
