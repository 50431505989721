import styled from '@emotion/styled';

type DividerProps = {
	spacing?: number;
	label?: string;
};

const DividerContainer = styled.div<Omit<DividerProps, 'label'>>`
	display: flex;
	align-items: center;
	margin: ${({ theme, spacing = 16 }) =>
			spacing ? theme.sizes.getSize(spacing / 8) : theme.sizes.getSize(2)}
		0;
	color: ${({ theme }) => theme.colors.gray.lighten40};

	&:before,
	&:after {
		content: '';
		width: 50%;
		transform: translateY(50%);
	}

	&:before {
		border-block-start: 1px solid ${({ theme }) => theme.colors.gray.lighten90};
	}

	&:after {
		border-block-end: 1px solid ${({ theme }) => theme.colors.gray.lighten90};
	}
`;

const DividerLabel = styled.span`
	padding: 0 ${({ theme }) => theme.sizes.getSize(2)};
`;

export const Divider = ({ spacing, label }: DividerProps) => (
	<DividerContainer spacing={spacing} role="separator">
		{label && <DividerLabel>{label}</DividerLabel>}
	</DividerContainer>
);
