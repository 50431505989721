import { Children, FC, PropsWithChildren, ReactElement, cloneElement, isValidElement } from 'react';

import styled from '@emotion/styled';

import { Text } from './Typography';

export type EmptyStateProps = {
	size?: 'S' | 'M' | 'L';
	status?: 'success' | 'default';
};

type EmptyStateComponents = {
	Icon: FC<PropsWithChildren<EmptyStateProps>>;
	Title: FC<PropsWithChildren<EmptyStateProps>>;
	Body: FC<PropsWithChildren<EmptyStateProps>>;
	Actions: FC<PropsWithChildren<EmptyStateProps>>;
};

export const EmptyStateContainer = styled.div`
	--empty-state-max-width: 550px;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
`;

const EmptyStateIcon = styled.div<EmptyStateProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${({ size }) => (size === 'S' ? 56 : 80)}px;
	height: ${({ size }) => (size === 'S' ? 56 : 80)}px;
	margin-bottom: ${({ size }) => (size === 'S' ? 16 : 24)}px;
	border-radius: 50%;
	background: ${({ theme, status }) =>
		status === 'success' ? theme.colors.primary.lighten50 : theme.colors.gray.lighten90};

	svg {
		width: ${({ size }) => (size === 'S' ? 32 : 48)}px;
		height: ${({ size }) => (size === 'S' ? 32 : 48)}px;

		path {
			fill: ${({ theme, status }) =>
				status === 'success' ? theme.colors.primary.base : theme.colors.gray.lighten40};
		}
	}
`;

const EmptyStateTitle = styled.h2<EmptyStateProps>`
	width: 100%;
	${({ theme }) => theme.textSettings.M};
	${({ theme }) => theme.fontWeight.medium};

	${({ theme }) => theme.mediaqueries.M} {
		${({ theme, size }) => size === 'L' && theme.textSettings.XL};
	}
`;

const EmptyStateBody = styled.div<EmptyStateProps>`
	width: 100%;
	max-width: var(--empty-state-max-width);
	margin-top: ${({ size }) => (size !== 'L' ? 16 : 24)}px;
	${({ theme, size }) => (size !== 'L' ? theme.textSettings.S : theme.textSettings.default)};

	${Text} {
		${({ theme, size }) => (size !== 'L' ? theme.textSettings.S : theme.textSettings.default)};
	}

	${Text}:nth-child(2) {
		color: ${({ theme }) => theme.colors.gray.lighten40};
		${({ theme }) => theme.textSettings.S};
	}
`;

const EmptyStateActionsContainer = styled.div<EmptyStateProps>`
	display: flex;
	justify-content: center;
	width: 100%;
	max-width: var(--empty-state-max-width);
	margin: 0 -4px;
	margin-top: 24px;
	gap: 8px;
`;

const EmptyStateActions = ({ size, children }: PropsWithChildren<EmptyStateProps>) => (
	<EmptyStateActionsContainer size={size}>{children}</EmptyStateActionsContainer>
);

export const EmptyState: FC<PropsWithChildren<EmptyStateProps>> & EmptyStateComponents = ({
	size = 'L',
	status = 'default',
	children,
	...props
}) => (
	<EmptyStateContainer {...props}>
		{Children.map(children, child => {
			const type = (child as ReactElement)?.type;
			const error =
				type &&
				type !== EmptyStateIcon &&
				type !== EmptyStateTitle &&
				type !== EmptyStateBody &&
				type !== EmptyStateActions;

			if (error) {
				throw new Error(
					`Only EmptyState.Icon, EmptyState.Title, EmptyState.Body and EmptyState.Actions are allowed`
				);
			}

			if (!isValidElement<EmptyStateProps>(child)) {
				return child;
			}

			return cloneElement(child, {
				size,
				status
			});
		})}
	</EmptyStateContainer>
);

EmptyState.Icon = EmptyStateIcon;
EmptyState.Title = EmptyStateTitle;
EmptyState.Body = EmptyStateBody;
EmptyState.Actions = EmptyStateActions;
