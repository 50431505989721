import { forwardRef, useState } from 'react';

import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { Datalist } from '../Datalist';
import { Skeleton } from '../Skeleton';
import { DatalistItemList } from '../datalist/DatalistItemList';
import { DatalistItemListSkeleton } from '../datalist/DatalistItemListSkeleton';
import { InputProps } from './Input';
import { InputSearchText, Values } from './InputSearchText';

export type Option = {
	id: string;
	description: string;
};

export type InputSearchDataListProps = {
	options: Option[];
	onChange?: (value: string) => void;
	onItemChange?: (value: string) => void;
	onBlur?: () => void;
	isOptionsLoading?: boolean;
	inputValueMinLength?: number;
	disabled?: boolean;
	name?: string;
	id?: string;
	placeholder?: string;
	value?: string;
} & InputProps;

const Item = styled.div`
	padding: 4px;
	${({ theme }) => theme.textSettings.S};
`;

const ContainerNoResults = styled.div`
	padding: 4px 12px;
	${({ theme }) => theme.textSettings.S};
`;

const ItemSkeleton = styled.div`
	padding: 4px;
`;

export const DatalistSkeleton = () => (
	<DatalistItemListSkeleton>
		{({ index }) => (
			<ItemSkeleton>
				<Skeleton mt={4} mb={4} height={16} width={index % 2 === 0 ? '100%' : '70%'} />
			</ItemSkeleton>
		)}
	</DatalistItemListSkeleton>
);

export const InputSearchDataList = forwardRef<HTMLInputElement, InputSearchDataListProps>(
	(
		{
			options: fieldOptions,
			onChange,
			onItemChange,
			isOptionsLoading,
			inputValueMinLength = 2,
			placeholder,
			value = '',
			...inputProps
		}: InputSearchDataListProps,
		ref
	) => {
		const { t } = useTranslation('shared');
		const [options, setOptions] = useState(() =>
			!!value
				? fieldOptions.filter(filterValue =>
						filterValue.description.toLowerCase().includes(value.toString().toLowerCase())
				  )
				: fieldOptions
		);
		const [values, setValues] = useState<Values>(() => ({
			inputValue: fieldOptions
				? fieldOptions.find(({ id }) => value === id)?.description ?? ''
				: '',
			outputValue: value
		}));

		if (!!value && value !== values.outputValue) {
			const option = options.find(({ id }) => value === id);
			setValues({ inputValue: option?.description?.toString(), outputValue: value?.toString() });
		}

		const onSearchChange = (searchValue: string) => {
			if (searchValue) {
				setOptions(
					fieldOptions.filter(value =>
						value.description.toLowerCase().includes(searchValue.toLowerCase())
					)
				);
			} else {
				setOptions(fieldOptions);
			}
		};

		const onItemSelect = (value: Option) => {
			setValues(values => ({ ...values, inputValue: value.description }));
			onItemChange?.(value.id);
		};

		return (
			<Datalist
				inputValueLength={values.inputValue?.length ?? 0}
				inputValueMinLength={inputValueMinLength}
				input={props => (
					<InputSearchText
						{...props}
						{...inputProps}
						placeholder={placeholder}
						value={values.inputValue}
						onChange={value => {
							setValues(values => ({ ...values, inputValue: value }));
							onSearchChange(value);
							onChange?.(value);
							if (values.outputValue !== '') {
								onItemChange?.('');
							}

							props.onChange();
						}}
						onBlur={() => {
							props.onBlur();
							inputProps.onBlur?.();
						}}
						autoComplete="off"
						ref={ref}
					/>
				)}>
				{isOptionsLoading ? (
					<DatalistSkeleton />
				) : options.length > 0 ? (
					<DatalistItemList options={options} onItemSelect={onItemSelect}>
						{({ option }) => <Item>{option.description}</Item>}
					</DatalistItemList>
				) : (
					<ContainerNoResults>{t('noResults')}</ContainerNoResults>
				)}
			</Datalist>
		);
	}
);
