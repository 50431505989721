import React, { useContext } from 'react';

import { DatalistActionType } from './datalistReducer';

type DatalistContextValue = {
	focusedIndex: number;
	dispatch: (value: DatalistActionType) => void;
};

export const DatalistContext = React.createContext<DatalistContextValue>({
	focusedIndex: 0,
	dispatch: () => {}
});

export const useDatalistContext = () => useContext(DatalistContext);
