export type BwaTrainingQuestion = {
	question: string;
	destination: keyof BwaTrainingResponse;
	options: {
		label: string;
		value: string;
	}[];
};

export type BwaTrainingResponse = {
	q1: string;
	q2: string;
	q3: string;
	q4: string;
	q5: string;
};

export const questions: BwaTrainingQuestion[] = [
	{
		question: 'What LoBs does Bamboo write?',
		destination: 'q1',
		options: [
			{
				label: 'Home',
				value: 'home'
			},
			{
				label: 'Dwelling',
				value: 'dwelling'
			},
			{
				label: 'Home and Dwelling',
				value: 'home-and-dwelling'
			},
			{
				label: 'Auto',
				value: 'auto'
			}
		]
	},
	{
		question: "Will I have access to Bamboo's agent portal?",
		destination: 'q2',
		options: [
			{
				label: 'Yes',
				value: 'yes'
			},
			{
				label: 'No',
				value: 'no'
			}
		]
	},
	{
		question:
			'Who should I contact if I have a question about my bind request, commissions or policy?',
		destination: 'q3',
		options: [
			{
				label: 'Agentero',
				value: 'agentero'
			},
			{
				label: 'Bamboo',
				value: 'bamboo'
			},
			{
				label: 'Either on is fine',
				value: 'either'
			}
		]
	},
	{
		question: 'Who will pay my commissions?',
		destination: 'q4',
		options: [
			{
				label: 'Agentero',
				value: 'agentero'
			},
			{
				label: 'Bamboo',
				value: 'bamboo'
			},
			{
				label: 'There are no commissions, only referral fees',
				value: 'neither'
			}
		]
	},
	{
		question: 'Who does the servicing of the policy?',
		destination: 'q5',
		options: [
			{
				label: 'Agentero',
				value: 'agentero'
			},
			{
				label: 'Bamboo',
				value: 'bamboo'
			},
			{
				label: 'I, as an agent',
				value: 'me'
			}
		]
	}
];
