import { PropsWithChildren } from 'react';

import styled from '@emotion/styled';

import { Button } from '../Button';

interface IPageProps {
	isSelected?: boolean;
	disabled?: boolean;
	title: string;
	onPageChange: () => void;
}

const PageButton = styled(Button)<{ isSelected: boolean }>`
	color: ${props => props.theme.colors.gray.base};
	svg {
		circle,
		path {
			fill: ${props => props.theme.colors.gray.base};
		}
	}

	@media (hover: hover) and (pointer: fine) {
		&:focus,
		&:hover {
			color: ${props => props.theme.colors.gray.base};
			background: ${props => props.theme.colors.gray.lighten95Alpha};

			svg {
				path:last-of-type {
					fill: ${props => props.theme.colors.gray.base};
				}
			}
		}
	}

	&:visited {
		color: ${props => props.theme.colors.gray.base};
	}

	&:active {
		color: ${props => props.theme.colors.gray.base};
		background: ${props => props.theme.colors.gray.lighten90Alpha};
	}

	height: 32px;
	min-width: 32px;
	padding: 0 7px;
	display: flex;
	align-items: center;
	justify-content: center;

	${({ isSelected, theme }) =>
		isSelected
			? `background: ${theme.colors.gray.lighten80}; color: ${theme.colors.gray.base};`
			: ''}

	&:disabled {
		${({ isSelected, theme }) =>
			isSelected
				? `background: ${theme.colors.gray.lighten80}; color: ${theme.colors.gray.base}; opacity: 1;`
				: ''}
	}
`;

export const Page = ({
	isSelected = false,
	disabled = false,
	children,
	title,
	onPageChange
}: PropsWithChildren<IPageProps>) => (
	<li>
		<PageButton
			title={title}
			isSelected={isSelected}
			disabled={disabled}
			onClick={isSelected ? undefined : onPageChange}>
			{children}
		</PageButton>
	</li>
);
