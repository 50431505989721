import { ReactNode } from 'react';

import { styled } from '@agentero/styles/jsx';

import { Text } from '../../../Text';
import { removeAllDots } from '../formGroupUtils';
import { Label } from './Label';

type ReadonlyFieldProps = {
	name: string;
	label: string;
	value?: string;
	description?: ReactNode;
	optional?: boolean;
};

const ReadonlyFieldContainer = styled('div', {
	base: {
		marginBottom: '1rem'
	}
});

export const ReadonlyField = ({
	name,
	label,
	value,
	description,
	optional
}: ReadonlyFieldProps) => (
	<ReadonlyFieldContainer>
		<Label
			id={`${removeAllDots(name)}-term`}
			readonly
			info={description}
			optional={optional}
			aria-label={label}>
			{label}
		</Label>

		<Text as="dd" size="body.small" aria-labelledby={`${removeAllDots(name)}-term`}>
			{value}
		</Text>
	</ReadonlyFieldContainer>
);
