import styled from '@emotion/styled';

type RoundIconProps = {
	icon: React.ReactNode;
	grayFilter?: boolean;
	size?: number;
	tooltip?: string;
};

const IconContainer = styled.div<{
	size?: number;
	grayFilter?: boolean;
}>`
	---size: ${({ theme, size }) => (size ? `${size / 16}rem` : theme.sizes.getSize(5))};

	display: flex;
	align-items: center;
	justify-content: center;
	height: var(---size);
	width: var(---size);
	background-color: ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.gray.lighten20};
	border: 1px solid ${({ theme }) => theme.colors.gray.lighten80};
	border-radius: 50%;

	svg {
		width: var(---size);
		height: auto;

		${({ grayFilter }) => grayFilter && 'filter: grayscale(1) brightness(1.2);'}
	}
`;

export const RoundIcon = ({ icon, size, grayFilter }: RoundIconProps) => {
	return (
		<IconContainer size={size} grayFilter={grayFilter} role="img">
			{icon}
		</IconContainer>
	);
};
