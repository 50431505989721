import { createElement, useEffect } from 'react';

import styled from '@emotion/styled';

import { useDatalistContext } from './DatalistContext';
import { DatalistAction } from './datalistReducer';

type DatalistOption = {
	id: string | number;
};

type DatalistItemListProps<T extends DatalistOption> = {
	options: T[];
	children: React.ComponentType<DatalistItemListChildrenProps<T>>;
	onItemSelect: (value: T) => void;
};

type DatalistItemListChildrenProps<T extends DatalistOption> = {
	option: T;
	isFocused: boolean;
};

const DatalistItem = styled.li<{ isFocused: boolean }>`
	padding: 0 8px;
	background: ${({ isFocused, theme }) =>
		isFocused ? theme.colors.gray.lighten90 : 'transparent'};

	&:hover {
		background: ${({ theme }) => theme.colors.gray.lighten90};
		cursor: pointer;
	}
`;

const DatalistItemListContainer = styled.ul`
	max-height: 160px;
	overflow-y: auto;
`;

export const DatalistItemList = <T extends DatalistOption>({
	options,
	children,
	onItemSelect
}: DatalistItemListProps<T>) => {
	const { focusedIndex, dispatch } = useDatalistContext();

	useEffect(() => {
		const onKeyDown = (event: KeyboardEvent) => {
			if (event.code === 'ArrowDown') {
				const newfocusedIndex = focusedIndex >= options.length - 1 ? 0 : focusedIndex + 1;
				dispatch({ type: DatalistAction.SetListFocusIndex, payload: newfocusedIndex });
			}
			if (event.code === 'ArrowUp') {
				const newfocusedIndex = focusedIndex === 0 ? options.length - 1 : focusedIndex - 1;
				dispatch({ type: DatalistAction.SetListFocusIndex, payload: newfocusedIndex });
			}
			if (event.code === 'Enter') {
				event.preventDefault();
				onItemSelect(options[focusedIndex]);
				dispatch({
					type: DatalistAction.SelectOption
				});
			}
		};

		window.addEventListener('keydown', onKeyDown);

		return () => window.removeEventListener('keydown', onKeyDown);
	}, [focusedIndex, options]);

	const onOptionSelected = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, option: T) => {
		event.stopPropagation();

		onItemSelect(option);
		dispatch({
			type: DatalistAction.SelectOption
		});
	};

	const preventInputFocus = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		event.preventDefault();
		event.stopPropagation();
	};

	return (
		<DatalistItemListContainer role="listbox">
			{options.map((option, index) => (
				<DatalistItem
					role="option"
					key={option.id}
					isFocused={focusedIndex === index}
					onClick={event => onOptionSelected(event, option)}
					onMouseDown={preventInputFocus}>
					{createElement(children, { option, isFocused: focusedIndex === index })}
				</DatalistItem>
			))}
		</DatalistItemListContainer>
	);
};
