import { Children, ReactNode, cloneElement, isValidElement } from 'react';

export const renderChildrenByName = <T extends {}>(
	children: ReactNode,
	displayName: string,
	props?: Partial<T>
) => {
	return Children.map(
		children,
		child =>
			isValidElement(child) &&
			typeof child.type === 'function' &&
			'displayName' in child.type &&
			child.type.displayName === displayName &&
			cloneElement(child, props)
	);
};

export const renderChildrenByType = <T extends {}>(
	children: ReactNode,
	componentType: React.ComponentType,
	props?: Partial<T>
) => {
	return Children.map(children, child => {
		if (!isValidElement(child)) return null;
		const actualType =
			//@ts-expect-error
			child.type.$$typeof === Symbol.for('react.lazy')
				? //@ts-expect-error
				  child.type._init(child.type._payload)
				: child.type;
		if (actualType === componentType) {
			return cloneElement(child, props);
		}
		return null;
	});
};
