import { ColumnDef, Row, flexRender } from '@tanstack/react-table';
import { VirtualItem } from '@tanstack/react-virtual';

import { Table, TableRootProps } from '../Table';
import { DataTableEmptyBody } from './shared/DataTableBodyEmpty';

type VirtualizedDataTableBodyProps<TData, TValue> = {
	before: number;
	after: number;
	items: VirtualItem[];
	rows: Row<TData>[];
	columns: ColumnDef<TData, TValue>[];
	size?: NonNullable<TableRootProps>['size'];
};

export const VirtualizedDataTableBody = <TData, TValue = never>({
	before,
	after,
	items,
	rows,
	columns,
	size = 'md'
}: VirtualizedDataTableBodyProps<TData, TValue>) => (
	<Table.Body>
		{before > 0 && (
			<tr>
				<td style={{ height: before }} />
			</tr>
		)}
		{items.length ? (
			items.map(virtualRow => {
				const row = rows[virtualRow.index];
				return (
					<Table.Row
						key={row.id}
						style={{
							height: `${virtualRow.size}px`
						}}>
						{row.getVisibleCells().map(cell => (
							<Table.Cell key={cell.id} {...cell.getContext().cell.column.columnDef.meta}>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</Table.Cell>
						))}
					</Table.Row>
				);
			})
		) : (
			<DataTableEmptyBody size={size} colSpan={columns.length} />
		)}
		{after > 0 && (
			<tr>
				<td style={{ height: after }} />
			</tr>
		)}
	</Table.Body>
);
