import { Carrier, carrierConfigurations } from '../Carrier';

export enum RaterCarrierEnum {
	RaterCarrier,
	NonRaterCarrier
}

type RaterConfig = {
	icon: unknown;
};

export type RaterCarrier = RaterCarrierEnum.NonRaterCarrier | RaterConfig;

type RaterCarrierConfig = {
	isRaterCarrier: boolean;
} & Partial<RaterConfig>;

export const getCarrierRaterConfig = (carrier: Carrier): RaterCarrierConfig => {
	const configuration = carrierConfigurations[carrier];

	if (configuration.raterCarrier === RaterCarrierEnum.NonRaterCarrier) {
		return {
			isRaterCarrier: false
		};
	}

	return {
		isRaterCarrier: true,
		...configuration.raterCarrier
	};
};
