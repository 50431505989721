import { ChangeEvent, PropsWithChildren } from 'react';

import { getFieldError, getFieldErrorId, removeAllDots, useAgFormContext } from '@agentero/ui';
import { getInputStatusFromError } from '@agentero/utils';

import { Select } from '../Select';
import { FormGroup, FormGroupGenericProps } from './FormGroup';

export type SelectValue = {
	label: string;
	value: string | number;
};

type FormGroupSelectProps<T> = {
	placeholder?: string;
	defaultValue?: string;
	values: SelectValue[];
	onChange?: ((event: ChangeEvent<HTMLSelectElement>) => void) | undefined;
	disabled?: boolean;
} & FormGroupGenericProps<T>;

export const FormGroupSelect = <T extends {}>({
	placeholder,
	name,
	values,
	defaultValue,
	onChange,
	disabled,
	children,
	...formGroupProps
}: PropsWithChildren<FormGroupSelectProps<T>>) => {
	const {
		register,
		formState: { errors }
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	return (
		<FormGroup
			name={name}
			nestedChildren={children}
			getReadonlyValue={inputValue =>
				values.find(option => option.value === inputValue)?.label ?? '—'
			}
			{...formGroupProps}>
			<Select
				{...register(name, { onChange })}
				id={removeAllDots(name)}
				aria-invalid={!!fieldError?.message}
				aria-errormessage={getFieldErrorId(name)}
				status={getInputStatusFromError(fieldError?.message as string)}
				defaultValue={defaultValue ? defaultValue : placeholder ? '' : undefined}
				disabled={disabled}>
				{!defaultValue && placeholder && (
					<option value="" disabled>
						{placeholder}
					</option>
				)}
				{values.map(({ label, value }) => (
					<option key={value} value={value}>
						{label}
					</option>
				))}
			</Select>
		</FormGroup>
	);
};
