export enum DatalistAction {
	OnInputChange,
	SetIsPanelOpen,
	SetListFocusIndex,
	SelectOption
}

export type DatalistActionType =
	| {
			type: DatalistAction.OnInputChange;
	  }
	| {
			type: DatalistAction.SetIsPanelOpen;
			payload: boolean;
	  }
	| {
			type: DatalistAction.SetListFocusIndex;
			payload: number;
	  }
	| {
			type: DatalistAction.SelectOption;
	  };

export type DatalistState = {
	list: DatalistListState;
};

export type DatalistListState = {
	focusedIndex: number;
	isOpen: boolean;
};

type DatalistReducer = (state: DatalistState, action: DatalistActionType) => DatalistState;

export const datalistReducer: DatalistReducer = (state, action) => {
	switch (action.type) {
		case DatalistAction.OnInputChange:
			return {
				...state,
				list: { ...state.list, isOpen: true }
			};
		case DatalistAction.SetIsPanelOpen:
			return { ...state, list: { ...state.list, isOpen: action.payload } };
		case DatalistAction.SetListFocusIndex:
			return { ...state, list: { ...state.list, focusedIndex: action.payload } };
		case DatalistAction.SelectOption:
			return {
				...state,
				list: { isOpen: false, focusedIndex: 0 }
			};
	}
};
