import { forwardRef } from 'react';

import styled from '@emotion/styled';

import { ITheme } from '@agentero/styles/emotion';

import { FieldStatus } from './form/fieldStatus';

interface IInputRadioButton
	extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	label: string;
	isInline?: boolean;
	className?: string;
	status?: FieldStatus;
	containerWidth?: number;
}

interface IInputRadioButtonContainer {
	isInline?: boolean;
	className?: string;
	status?: FieldStatus;
}

export const defaultVariant = (theme: ITheme) => `
	border: solid 1px ${theme.colors.gray.lighten80};
`;
export const successVariant = (theme: ITheme) => `
	border: solid 1px ${theme.colors.primary.base};
`;
export const errorVariant = (theme: ITheme) => `
	border: solid 1px ${theme.colors.status.error.base};
`;
export const warningVariant = (theme: ITheme) => `
	border: solid 1px ${theme.colors.status.warning.base};
`;
export const infoVariant = (theme: ITheme) => `
	border: solid 1px ${theme.colors.status.info.base};
	background-color: ${theme.colors.status.info.lighten80};
`;

export const statusVariants: { [key in FieldStatus]: (theme: ITheme) => string } = {
	[FieldStatus.None]: defaultVariant,
	[FieldStatus.Success]: successVariant,
	[FieldStatus.Error]: errorVariant,
	[FieldStatus.Warning]: warningVariant,
	[FieldStatus.Info]: infoVariant
};

type GetStatusVariant = IInputRadioButtonContainer & { theme: ITheme };

export const getStatusVariant = ({ status = FieldStatus.None, theme }: GetStatusVariant) => {
	const variant = statusVariants[status];
	return variant(theme);
};

export const InputRadioButtonContainer = styled.label<IInputRadioButtonContainer>`
	position: relative;
	display: flex;
	align-items: center;
	flex: ${props => (props.isInline ? 'none' : 1)};
	margin: -1px;

	&:first-of-type {
		span {
			border-radius: 2px 0 0 2px;
		}
	}

	&:last-of-type {
		span {
			border-radius: 0 2px 2px 0;
		}
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	input[type='radio'] {
		+ span {
			position: relative;
			cursor: pointer;
			display: block;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			position: relative;
			color: ${props => props.theme.colors.gray.lighten40};
			background: ${props => props.theme.colors.white};
			border: solid 1px ${props => props.theme.colors.gray.lighten80};
			${props => props.theme.fontWeight.medium}
			${props => props.theme.textSettings.S}
			padding: 3px 15px;
			text-align: center;
			${getStatusVariant}
		}

		&:focus,
		&:hover {
			+ span {
				color: ${props => props.theme.colors.gray.base};
				background: ${props => props.theme.colors.gray.lighten90};
			}
		}

		&:focus {
			+ span {
				background: ${props => props.theme.colors.gray.lighten95};
			}
		}

		&:checked {
			+ span {
				z-index: 9;
				color: ${props => props.theme.colors.white};
				border-color: ${props => props.theme.colors.primary.base};
				background: ${props => props.theme.colors.primary.base};
			}
		}

		&:disabled {
			+ span {
				opacity: 0.5;
				cursor: not-allowed;
			}
		}
	}
`;

const InputRadioButtonRadio = styled.input`
	position: absolute;
	opacity: 0;
	pointer-events: none;
`;

const InputRadioButtonLabel = styled.span`
	display: flex;
	flex: 1;
	align-items: center;
`;

export const InputRadioButton = forwardRef<HTMLInputElement, IInputRadioButton>(
	({ label, isInline, className, status, ...inputProps }, ref) => {
		return (
			<InputRadioButtonContainer isInline={isInline} className={className} status={status}>
				<InputRadioButtonRadio
					{...inputProps}
					ref={ref}
					type="radio"
					aria-invalid={status === FieldStatus.Error}
				/>
				<InputRadioButtonLabel>{label}</InputRadioButtonLabel>
			</InputRadioButtonContainer>
		);
	}
);
