import { PropsWithChildren } from 'react';

import { getFieldError, getFieldErrorId, removeAllDots, useAgFormContext } from '@agentero/ui';
import { getInputStatusFromError } from '@agentero/utils';

import { InputDate } from '../InputDate';
import { FormGroup, FormGroupGenericProps } from './FormGroup';

type FormGroupInputDateProps<T> = FormGroupGenericProps<T> & {
	defaultValue?: string;
};

export const FormGroupInputDate = <T extends {}>({
	name,
	defaultValue,
	children,
	...formGroupProps
}: PropsWithChildren<FormGroupInputDateProps<T>>) => {
	const {
		register,
		formState: { errors }
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	return (
		<FormGroup name={name} nestedChildren={children} {...formGroupProps}>
			<InputDate
				id={removeAllDots(name)}
				{...register(name)}
				placeholder="MM/DD/YYYY"
				status={getInputStatusFromError(fieldError?.message as string)}
				aria-invalid={!!fieldError?.message}
				aria-errormessage={getFieldErrorId(name)}
				defaultValue={defaultValue}
			/>
		</FormGroup>
	);
};
