import { useEffect } from 'react';

import logger from 'logging/logger';

import { Role, isSuperAdmin } from '@agentero/models/agent';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { addLog } from 'packages/utilities/logger';

import { loadSnippet } from './shared';

declare const Appcues;

export const AppcuesIdentify = () => {
	const { data: agent } = useAgentResource();

	useEffect(() => {
		if (isSuperAdmin(agent.role)) return;

		loadSnippet('//fast.appcues.com/132544.js')
			.then(() => {
				// @ts-ignore
				window.AppcuesSettings = {
					enableURLDetection: true
				};

				const init = {
					'Agent ID': agent.id,
					Email: agent.email,
					'First name': agent.firstName,
					'Last name': agent.lastName,
					Role: roleMapping[agent.role],
					IsImpersonation: agent.isImpersonation,
					'Formstack Name': agent.agency.formStackName,
					'Agency ID': agent.agency.id,
					Environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
					QuotableLobList: agent.agency.quotableLobsList.map(it => it.toString()).toString(),
					QuotableLobEmtpy: agent.agency.quotableLobsList.length === 0 ? true : false
				};

				Appcues && Appcues.identify(agent.id, init);
				logger.info('Tracking Appcues identify: ' + agent.id);
			})
			.catch(error => addLog(error));
	}, []);

	return null;
};

const roleMapping: { [key: number]: string } = {
	[Role.Agent]: 'Agent',
	[Role.AgencyAdmin]: 'Agency admin',
	[Role.SuperAdmin]: 'Super admin'
};
