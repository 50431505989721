import React, { PropsWithChildren, ReactNode } from 'react';

import { Path } from 'react-hook-form';

import { getFieldError, getFieldErrorId, removeAllDots, useAgFormContext } from '@agentero/ui';
import { stringToText } from '@agentero/utils';

import { FormError } from '../FormError';
import { FormItem, NestedFormItem } from '../FormItem';
import { Label } from '../Label';
import { ReadonlyField } from './ReadonlyField';

type FormGroupProps<T> = {
	label: string;
	name: Path<T>;
	description?: ReactNode;
	optional?: boolean;
	nested?: boolean;
	nestedChildren?: ReactNode;
	asFieldset?: boolean;
	displayErrorMessage?: boolean;
	disabled?: boolean;
	getReadonlyValue?: (value: string | boolean | unknown) => string;
};

export type FormGroupGenericProps<T> = Omit<FormGroupProps<T>, 'nestedChildren'>;

const Legend = ({ children }: PropsWithChildren) => {
	return <legend>{children}</legend>;
};

export const FormGroup = <T extends {}>({
	name,
	label,
	description,
	optional,
	nested = false,
	children,
	nestedChildren,
	asFieldset = false,
	displayErrorMessage = true,
	getReadonlyValue
}: PropsWithChildren<FormGroupProps<T>>) => {
	const {
		getValues,
		formState: { errors },
		isReadonly
	} = useAgFormContext<T>();

	const Field = nested ? NestedFormItem : isReadonly ? 'div' : FormItem;

	const LegendInFieldset = asFieldset ? Legend : React.Fragment;

	return (
		<Field as={asFieldset && !isReadonly ? 'fieldset' : undefined}>
			{isReadonly ? (
				<>
					<ReadonlyField
						name={name}
						label={label}
						value={
							getReadonlyValue ? getReadonlyValue(getValues(name)) : stringToText(getValues(name))
						}
						description={description}
						optional={optional}
					/>
					{nestedChildren}
				</>
			) : (
				<>
					<LegendInFieldset>
						<Label htmlFor={removeAllDots(name)} optional={optional} info={description}>
							{label}
						</Label>
					</LegendInFieldset>
					{children}
					{displayErrorMessage && (
						<FormError
							id={getFieldErrorId(name)}
							error={getFieldError(errors, name)?.message as string}
						/>
					)}
					{nestedChildren}
				</>
			)}
		</Field>
	);
};
