import { styled } from '@agentero/styles/jsx';

export const InputSegment = styled('div', {
	base: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'center',
		borderStyle: 'solid',
		borderRadius: 'md',
		borderWidth: '1px',
		borderColor: 'neutral.80',
		paddingLeft: '0.5rem',
		overflow: 'hidden',
		'&[aria-disabled=true]': {
			cursor: 'default',
			backgroundColor: 'neutral.90',
			borderColor: 'neutral.80',
			color: 'neutral.50'
		},
		_focusWithin: {
			borderColor: 'brand',
			outlineOffset: '0.125rem',
			outlineStyle: 'solid',
			outlineWidth: '0.125rem',
			outlineColor: 'brand',

			'& button': {
				borderLeftColor: 'brand'
			}
		},
		'&:has([aria-invalid=true])': {
			borderColor: 'red',

			'& button': {
				borderLeftColor: 'red'
			}
		}
	},
	variants: {
		size: {
			sm: {
				height: '24px'
			},
			md: {
				height: '32px'
			},
			lg: {
				height: '40px'
			}
		}
	},
	defaultVariants: {
		size: 'md'
	}
});
