// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts,import_extension=none"
// @generated from file agentero/portal/quote.proto (package agentero.portal, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetContactQuoteDetailsRequest, GetQuoteDetailsRequest, GetQuoteDetailsResponse, GetQuoteProposalRequest, GetQuoteProposalResponse, SetQuoteSalesPitchRequest, SetQuoteSalesPitchResponse } from "./quote_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service agentero.portal.QuoteService
 */
export const QuoteService = {
  typeName: "agentero.portal.QuoteService",
  methods: {
    /**
     * @generated from rpc agentero.portal.QuoteService.GetQuoteDetails
     */
    getQuoteDetails: {
      name: "GetQuoteDetails",
      I: GetQuoteDetailsRequest,
      O: GetQuoteDetailsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc agentero.portal.QuoteService.GetContactQuoteDetails
     */
    getContactQuoteDetails: {
      name: "GetContactQuoteDetails",
      I: GetContactQuoteDetailsRequest,
      O: GetQuoteDetailsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc agentero.portal.QuoteService.SetQuoteSalesPitch
     */
    setQuoteSalesPitch: {
      name: "SetQuoteSalesPitch",
      I: SetQuoteSalesPitchRequest,
      O: SetQuoteSalesPitchResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc agentero.portal.QuoteService.GetQuoteProposal
     */
    getQuoteProposal: {
      name: "GetQuoteProposal",
      I: GetQuoteProposalRequest,
      O: GetQuoteProposalResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

