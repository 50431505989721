// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts,import_extension=none"
// @generated from file agentero/portal/compliance.proto (package agentero.portal, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { RequestRefreshLicenseInfoRequest, RunNIPRForAgencyRequest, RunNIPRForAgentRequest } from "./compliance_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service agentero.portal.ComplianceService
 */
export const ComplianceService = {
  typeName: "agentero.portal.ComplianceService",
  methods: {
    /**
     * @generated from rpc agentero.portal.ComplianceService.RequestRefreshLicenseInfo
     */
    requestRefreshLicenseInfo: {
      name: "RequestRefreshLicenseInfo",
      I: RequestRefreshLicenseInfoRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc agentero.portal.ComplianceService.RunNIPRForAgency
     */
    runNIPRForAgency: {
      name: "RunNIPRForAgency",
      I: RunNIPRForAgencyRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc agentero.portal.ComplianceService.RunNIPRForAgent
     */
    runNIPRForAgent: {
      name: "RunNIPRForAgent",
      I: RunNIPRForAgentRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

