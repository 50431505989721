import { forwardRef } from 'react';

import { NumericFormat } from 'react-number-format';

import { Input } from './Input';
import { FieldStatus } from './fieldStatus';

type InputNumberProps = {
	status?: FieldStatus;
	maxNumber?: number;
	thousandSeparator?: boolean;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
	(
		{
			status,
			placeholder,
			onChange,
			value,
			maxNumber,
			thousandSeparator = false,
			id,
			name,
			defaultValue,
			'aria-invalid': ariaInvalid,
			'aria-errormessage': ariaErrormessage
		},
		ref
	) => (
		<NumericFormat
			id={id}
			name={name}
			status={status}
			value={value as string | number}
			placeholder={placeholder}
			thousandSeparator={thousandSeparator}
			fixedDecimalScale={false}
			onChange={onChange}
			customInput={Input}
			allowNegative={false}
			isAllowed={values => {
				const { floatValue } = values;
				return maxNumber && floatValue ? floatValue <= maxNumber : true;
			}}
			getInputRef={ref}
			defaultValue={defaultValue as number | undefined}
			aria-invalid={ariaInvalid}
			aria-errormessage={ariaErrormessage}
		/>
	)
);
