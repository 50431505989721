import styled from '@emotion/styled';

export const Truncate = styled.div`
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const MultiLineTruncate = styled.div<{ clamp: number }>`
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: ${({ clamp }) => clamp};
`;
