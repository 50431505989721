import React from 'react';

import styled from '@emotion/styled';

import { IconKeyboardArrowLeft, IconKeyboardArrowRight } from '@agentero/icons';

import { Skeleton } from '../../Skeleton';

export const PagerContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	${({ theme }) => theme.mediaqueries.S} {
		flex-direction: row;
		justify-content: space-between;
	}
`;

const EntriesSkeleton = styled(Skeleton)`
	height: 16px;
	width: 160px;
	background: ${({ theme }) => theme.colors.gray.lighten80};
	margin-top: 8px;
	order: 2;

	${({ theme }) => theme.mediaqueries.S} {
		margin-top: 0;
		order: 1;
	}
`;

const PagesListSkeleton = styled.ul`
	display: flex;
	order: 1;

	${({ theme }) => theme.mediaqueries.S} {
		order: 2;
	}
`;

const PageButtonSkeleton = styled(Skeleton)<{ isSelected?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	min-width: 32px;
	padding: 0 7px;
	background: ${({ theme, isSelected }) =>
		isSelected ? theme.colors.gray.lighten80 : 'transparent'};

	svg path {
		fill: ${({ theme }) => theme.colors.gray.lighten80};
	}
`;

export const PagerSkeleton = () => (
	<PagerContainer>
		<EntriesSkeleton />
		<PagesListSkeleton>
			<PageButtonSkeleton>
				<IconKeyboardArrowLeft />
			</PageButtonSkeleton>
			<PageButtonSkeleton isSelected={true} />
			<PageButtonSkeleton />
			<PageButtonSkeleton />
			<PageButtonSkeleton />
			<PageButtonSkeleton />
			<PageButtonSkeleton />
			<PageButtonSkeleton />
			<PageButtonSkeleton>
				<IconKeyboardArrowRight />
			</PageButtonSkeleton>
		</PagesListSkeleton>
	</PagerContainer>
);
