import { ComponentType, PropsWithChildren } from 'react';

import styled from '@emotion/styled';

import { IconCheck } from '@agentero/icons';

type CheckListItemProps = {
	Icon?: ComponentType<React.SVGProps<SVGSVGElement>>;
	className?: string;
};

/**
 * @deprecated Use **'@agentero/styles/jsx'** *CheckList* component instead.
 */
export const CheckList = styled.ul<{ columns?: number }>`
	${({ theme }) => theme.mediaqueries.S} {
		${({ columns }) =>
			columns &&
			`column-count: ${columns};
			 column-gap: 16px;
		`}
	}
`;

const CheckListItemContainer = styled.li`
	&:not(:last-child) {
		margin-bottom: 16px;
	}
`;

const CheckListItemInner = styled.div`
	display: flex;
`;

const CheckListItemIcon = styled.div`
	flex: 0;
	min-width: 20px;

	svg {
		width: 100%;
		height: auto;
		vertical-align: top;
		fill: inherit;

		path {
			fill: inherit;
		}
	}
`;

const CheckListItemBody = styled.div`
	flex: 1;
	margin-left: 8px;
`;

/**
 * @deprecated Use **'@agentero/styles/jsx'** *CheckList.Item* component instead.
 */
export const CheckListItem = ({
	children,
	Icon = IconCheck,
	className
}: PropsWithChildren<CheckListItemProps>) => (
	<CheckListItemContainer className={className}>
		<CheckListItemInner>
			<CheckListItemIcon>
				<Icon />
			</CheckListItemIcon>
			<CheckListItemBody>{children}</CheckListItemBody>
		</CheckListItemInner>
	</CheckListItemContainer>
);
